/* eslint-disable func-names */
/* eslint-disable no-console */
/* eslint-disable no-continue */
/* eslint-disable */
// eslint-disable-next-line object-curly-newline
import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactDOMServer from 'react-dom/server';
import { getDay, getISOWeek } from 'date-fns';
import moment from 'moment';
import store from './store';
import authActions from '../Actions/auth.actions';
import { timeFilterMap } from '../Constants/analytics.constansts';
// import { notificationServices } from '../Services';

export function objectContainsProperty(obj, prop) {
	return Object.prototype.hasOwnProperty.call(obj, prop);
}
export function cloneDeep(object) {
	return JSON.parse(JSON.stringify(object));
}

export function waitForAPI(state, key, subKey = false) {
	if (!state) {
		return [];
	}
	if (objectContainsProperty(state, key)) {
		if (subKey) {
			return state[key][subKey];
		}
		return state[key];
	}
	return [];
}

export function isObject(object) {
	return object != null && typeof object === 'object';
}

export function isEmpty(obj) {
	if (!isObject(obj)) return true;
	if (obj.length === 0) return true;
	const hasProperty = Object.keys(obj).some((prop) => {
		return objectContainsProperty(obj, prop);
	});
	return !hasProperty && JSON.stringify(obj) === JSON.stringify({});
}

export const imageToBase64 = (image) => {
	return new Promise((resolve) => {
		if (typeof image === 'string') {
			resolve(image);
		}
		const reader = new FileReader();
		reader.readAsDataURL(image);
		reader.onloadend = () => {
			resolve(reader.result);
		};
	});
};

export const generateImageUrl = (file) => {
	if (file) {
		if (typeof file === 'string') {
			return file;
		}
		return URL.createObjectURL(file);
	}
	return '';
};

export const generateRandomKey = () => {
	return {
		key: Math.random(),
	};
};

export const generateRequiredProps = (required) => {
	// eslint-disable-next-line object-curly-newline
	return required ? { required } : {};
};

export const generateDisabledProps = (disabled) => {
	// eslint-disable-next-line object-curly-newline
	return disabled ? { disabled } : {};
};

export const generateCheckedProps = (checked) => {
	// eslint-disable-next-line object-curly-newline
	return checked ? { checked } : {};
};

export const generateImageUploadButton = ({ isDisabled, label }) => {
	if (isDisabled) {
		return <></>;
	}
	return label;
};

export const generateImageEditButton = ({ isEdit, button }) => {
	if (isEdit) {
		return button;
	}
	return <></>;
};

export function generateUniqueID() {
	const timestamp = Date.now(); // Get current timestamp in milliseconds
	const randomNum = Math.floor(Math.random() * 10000);

	const uniqueID = `${timestamp}${randomNum}`;
	return uniqueID;
}

export function deepEqual(object1, object2) {
	const keys1 = Object.keys(object1);
	const keys2 = Object.keys(object2);
	if (keys1.length !== keys2.length) {
		return false;
	}
	return keys1.reduce((acc, curr) => {
		const key = curr;
		const val1 = object1[key];
		const val2 = object2[key];
		const areObjects = isObject(val1) && isObject(val2);
		if (
			(areObjects && !deepEqual(val1, val2)) ||
			(!areObjects && val1 !== val2)
		) {
			return false;
		}
		return true;
	}, false);
}

/**
 *
 * @param {string} queryURL
 * @param {object} params
 * @returns {string} url with query params
 */
export function toQueryStringURL(queryURL, params) {
	const url = new URL(queryURL);
	Object.keys(params).forEach((key) => {
		return url.searchParams.append(key, params[key]);
	});
	return url;
}

/**
 *
 * @param {string} queryURL
 * @param {object} params
 * @returns {string} url with query params
 */
export function appendAsQueryString(queryURL, params) {
	let url = queryURL;
	Object.entries(params).forEach(([key, value]) => {
		if (!url?.includes('?')) {
			url = `${url}?${key}=${value ?? ''}`;
		} else {
			url = `${url}&${key}=${value ?? ''}`;
		}
	});
	return url;
}

/**
 *
 * @param {string} queryURL
 * @param {array} params
 * @returns {string} url with query params
 */
export function toPathStringURL(queryURL, pathAppend) {
	let url = queryURL;
	pathAppend.forEach((key) => {
		// eslint-disable-next-line prefer-template
		url += '/' + key;
	});
	return url;
}

export function convertedUnitValue(value, unit = 'gm') {
	if (unit === 'gm') {
		if (!(value < 1000)) {
			return `${Number(value / 1000).toFixed(2)}kg`;
		}
		// Check if the value has a decimal part
		if (value % 1 !== 0) {
			// Round the number to two decimal places
			return `${Number(value).toFixed(2)}gm`;
		}
		return `${Number(value)}gm`;
		// If it's an integer, return it as is
	}
	if (!(value < 1000)) {
		return `${Number(value / 1000).toFixed(2)}K`;
	}
	return `${Number(value)}`;
}

export const regExpHelper = {
	mobile: /^\d{10}$/,
	email: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
	OTP: /^\d{6}$/,
	hex: /^#([A-Fa-f0-9]{3}){1,2}$/,
};

export function validateForm(errors, withError = false) {
	let validationError = [true, ''];
	const isValid = !Object.keys(errors).some((error) => {
		validationError = [false, errors[error]];
		return errors[error].length > 0;
	});
	if (withError) {
		return validationError;
	}
	return isValid;
}

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
}

export function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions()
	);

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
	return windowDimensions;
}

function getScrollFromBottom() {
	const distanceFromBottom =
		document.documentElement.scrollHeight -
		(window.scrollY + window.innerHeight);
	return distanceFromBottom;
}

export function useWindowScrollFromBottom() {
	const [distanceFromBottom, setDistanceFromBottom] = useState(
		getScrollFromBottom()
	);
	useEffect(() => {
		function handleScroll() {
			setDistanceFromBottom(getScrollFromBottom());
		}
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);
	return {
		distanceFromBottom,
	};
}

export function disableInputIncrementWheel(event) {
	if (!event.target.matches('input[type=number]')) return;
	event.preventDefault();
}

export function disableInputIncrementKey(event) {
	if (!event.target.matches('input[type=number]')) return;
	if (event.keyCode === 38 || event.keyCode === 40) {
		event.preventDefault();
	}
}

export const on = (selector, eventType, childSelector, eventHandler) => {
	const elements = document.querySelectorAll(selector);
	elements.forEach((element) => {
		element.addEventListener(eventType, (eventOnElement) => {
			if (eventOnElement.target.matches(childSelector)) {
				eventHandler(eventOnElement);
			}
		});
	});
};

export function hexToRGB(hex = '#000000') {
	let temp;
	if (regExpHelper.hex.test(hex)) {
		temp = hex.substring(1).split('');
		if (temp.length === 3) {
			temp = temp.reduce((acc, curr) => {
				return [...acc, curr, curr];
			}, []);
		}
		temp = `0xc${temp.join('')}`;
		return `${[(temp >> 16) & 255, (temp >> 8) & 255, temp & 255].join(
			', '
		)}`;
	}
	throw new Error('Bad Hex');
}

export function inputHelper(e) {
	const fieldName = e?.target?.name;
	const fieldValue =
		e.target.type === 'checkbox' ? e?.target?.checked : e?.target?.value;
	const { dataset = {} } = e?.currentTarget ?? {};
	return {
		fieldName,
		fieldValue,
		dataset,
	};
}

export function getMonthNumber(month) {
	const MONTH_MAP = {
		Jan: 1,
		Feb: 2,
		Mar: 3,
		Apr: 4,
		May: 5,
		Jun: 6,
		Jul: 7,
		Aug: 8,
		Sep: 9,
		Oct: 10,
		Nov: 11,
		Dec: 12,
	};

	return MONTH_MAP?.[month] ?? null;
}

export function getMonthsWeek(year, weeks, month) {
	// get the first day of year
	const firstDayOfMonth = new Date(year, timeFilterMap.monthNumber[month], 1);
	// get year/ ISO week number of first day of the month
	const weekOfFirstDay = getISOWeek(firstDayOfMonth);
	// result is ISO week number of selected week - week of first day
	// + 1 to get 1 for first week
	return weeks - weekOfFirstDay + 1;
}
export function ordinalSuffixOf(i) {
	const j = i % 10;
	const k = i % 100;
	if (j === 1 && k !== 11) {
		return `${i}st`;
	}
	if (j === 2 && k !== 12) {
		return `${i}nd`;
	}
	if (j === 3 && k !== 13) {
		return `${i}rd`;
	}
	return `${i}th`;
}
export function handleResponse(response) {
	return response.text().then((text) => {
		const data = text && JSON.parse(text);
		if (data.status === 'Failed' || data.errorMessage) {
			let error =
				data.errorMessage || data.message || data.responseMessage;
			if (error === 'Auth_Invalid' || error === 'Unauthorized Access') {
				error = 'Your session has expired';
				store.dispatch(authActions.toggleLogout(true));
			}
			// if it's not a loading notification, display error message
			if (!store.getState().auth.isLoading) {
				store.dispatch(authActions.toggleLoading(false));
				// notificationServices.generateNotification({
				// 	type: 'error',
				// 	message: error,
				// });
			}
			return Promise.reject(error);
		}
		return data;
	});
}
export const classes = (...args) => {
	return args.join(' ');
};
export function getDayName(dayNumber) {
	const dayName = [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
	];
	return dayName[dayNumber];
}
export function convertTo24HourFormatTo12Hour(timeString) {
	const H = timeString;
	const h = H % 12 || 12;
	const ampm = H < 12 || H === 24 ? 'AM' : 'PM';
	return `${h} ${ampm}`;
}

export function cpkComparator(a, b, isAscending = true) {
	let factor = 1;
	if (!isAscending) factor = -1;
	if (a === 'Perfect process') {
		return 1 * factor;
	}
	if (b === 'Perfect process') {
		return -1 * factor;
	}
	if (a === 'Not defined') {
		return -1 * factor;
	}
	if (b === 'Not defined') {
		return 1 * factor;
	}
	return (a - b) * factor;
}

export function getDayNameFromDate(timeStamp) {
	const [dateString] = timeStamp.split(',');
	const [month, date, year] = dateString.split('/');
	const dayNumber = getDay(new Date(year, month - 1, date));
	return getDayName(dayNumber);
}

export function getDateFilterString(DATE_FILTER = {}) {
	return Object.keys(DATE_FILTER).reduce(
		(accSelectedDate, currSelectionType, idx) => {
			const isFirst = idx === 0;
			if (
				DATE_FILTER[currSelectionType] &&
				currSelectionType.toUpperCase() !== 'WEEK'
			) {
				if (!isFirst) {
					// eslint-disable-next-line no-param-reassign
					accSelectedDate += ', ';
				}
				// eslint-disable-next-line no-param-reassign
				accSelectedDate += `${currSelectionType.toUpperCase()}: ${
					DATE_FILTER[currSelectionType]
				}`;
			}
			return accSelectedDate;
		},
		''
	);
}

export function useClientRect() {
	const ref = useRef(null);

	const getClientRect = useCallback(() => {
		return ref?.current?.getBoundingClientRect?.();
	}, []);

	return [getClientRect, ref];
}

export function checkFormField(obj = {}, notMandatory = '') {
	const keyArr = Object.keys(obj);
	let emptyKey = '';
	for (let i = keyArr?.length - 1; i >= 0; i--) {
		if (obj[keyArr[i]] === '' && keyArr[i] !== notMandatory) {
			emptyKey = keyArr[i];
		}
	}
	return emptyKey;
}

export const weekDays = [
	{
		id: 0,
		label: 'Monday',
		key: 'M',
	},
	{
		id: 1,
		label: 'Tuesday',
		key: 'T',
	},
	{
		id: 2,
		label: 'Wednesday',
		key: 'W',
	},
	{
		id: 3,
		label: 'Thursday',
		key: 'T',
	},
	{
		id: 4,
		label: 'Friday',
		key: 'F',
	},
	{
		id: 5,
		label: 'Saturday',
		key: 'S',
	},
	{
		id: 6,
		label: 'Sunday',
		key: 'S',
	},
];

export const keyPressHandler = (keyPressed, listData, listIndex) => {
	let newListItem = null;
	if (keyPressed === 'ArrowRight' || keyPressed?.toLowerCase() === 'd') {
		if (listIndex >= 0 && listIndex < listData.length - 1) {
			newListItem = listData?.[listIndex + 1];
		} else {
			return false;
		}
	} else if (
		keyPressed === 'ArrowLeft' ||
		keyPressed?.toLowerCase() === 'a'
	) {
		if (listIndex > 0) {
			newListItem = listData?.[listIndex - 1];
		} else {
			return false;
		}
	}
	return newListItem;
};

export const handleExcelDownload = (header, fileData, fileName, wrapInQuotes = false) => {
	const downloadBtn = document.createElement('a');

	const csvData = [header, ...fileData].map((item) => {
		return item.map((e) => {
			return wrapInQuotes ? `"${e}"` : e;
		});
	});

	const csv = `${csvData
		.map((e) => {
			return e.join(',');
		})
		.join('\n ')}`;

	const encodedValue = new Blob([csv], {
		type: 'text/csv',
	});
	downloadBtn.href = window.URL.createObjectURL(encodedValue);
	downloadBtn.target = '_blank';
	downloadBtn.download = fileName ?? 'Excel';
	downloadBtn.click();
};

export function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1)?.toLowerCase();
}

export const unitTypes = {
	gram: {
		postConversionUnit: 'kg',
		unitDisplay: 'g',
		higherUnitDivisionFactor: 1000,
		isBaseUnit: true,
	},
	oz: {
		postConversionUnit: 'lbs',
		unitDisplay: 'oz',
		higherUnitDivisionFactor: 16,
		isBaseUnit: true,
	},
	count: {
		postConversionUnit: 'pc',
		unitDisplay: 'pc',
		higherUnitDivisionFactor: 1,
	},
	floz: {
		postConversionUnit: 'gal',
		unitDisplay: 'floz',
		higherUnitDivisionFactor: 128,
		isBaseUnit: true,
	},
	litre: {
		postConversionUnit: 'ml',
		unitDisplay: 'l',
		higherUnitDivisionFactor: 1 / 1000,
		isHigherUnit: true,
	},
	ltr: {
		postConversionUnit: 'ml',
		unitDisplay: 'ltr',
		higherUnitDivisionFactor: 1 / 1000,
		isHigherUnit: true,
	},
	ml: {
		postConversionUnit: 'ltr',
		unitDisplay: 'ml',
		higherUnitDivisionFactor: 1000,
		isBaseUnit: true,
	},
	kg: {
		postConversionUnit: 'g',
		unitDisplay: 'Kg',
		higherUnitDivisionFactor: 1 / 1000,
		isHigherUnit: true,
	},
	lbs: {
		postConversionUnit: 'oz',
		unitDisplay: 'lbs',
		higherUnitDivisionFactor: 1 / 16,
	},
	lb: {
		postConversionUnit: 'oz',
		unitDisplay: 'lbs',
		higherUnitDivisionFactor: 1 / 16,
		isHigherUnit: true,
	},
	gal: {
		postConversionUnit: 'floz',
		unitDisplay: 'gal',
		higherUnitDivisionFactor: 1 / 128,
		isHigherUnit: true,
	},
};

export function getPriceConvertedQuantity(price, unit = 'gram', invertValue = false) {
	let no = Number(price);
	// eslint-disable-next-line no-restricted-globals
	if (isNaN(no)) {
		if (price === undefined || price === null) {
			return 0;
		}
		return price;
	}

	no = Number(no);
	if (unit === 'count') {
		return no;
	}
	if (invertValue) {
		return Number.parseFloat(
			no / unitTypes?.[unit]?.higherUnitDivisionFactor
		).toFixed(unitTypes?.[unit]?.toFixedDivision ?? 2);
	}
	const newNo = Number.parseFloat(
		no * unitTypes?.[unit]?.higherUnitDivisionFactor
	).toFixed(unitTypes?.[unit]?.toFixedDivision ?? 2);
	return newNo;
}

export function getUnitConvertedQuantity(
	quantity,
	unit = 'gram',
	allowConvertBaseUnit = true,
	allowConvertHigherUnit = true
) {
	let qty = Number(quantity);
	// eslint-disable-next-line no-restricted-globals
	if (isNaN(qty)) {
		qty = 0;
	}
	if (!allowConvertBaseUnit && unitTypes?.[unit]?.isBaseUnit) {
		return qty;
	}
	if (!allowConvertHigherUnit && unitTypes?.[unit]?.isHigherUnit) {
		return qty;
	}
	if (qty >= unitTypes?.[unit]?.higherUnitDivisionFactor) {
		const newNo = Number.parseFloat(
			qty / unitTypes?.[unit]?.higherUnitDivisionFactor
		).toFixed(unitTypes?.[unit]?.toFixedDivision ?? 3);
		// if (newNo >= 1000 && !inThousands) {
		// 	return Number.parseFloat(newNo / 1000).toFixed(
		// 		unitTypes?.[unit]?.toFixedDivision ?? 1
		// 	);
		// }
		return newNo;
	}
	return qty.toFixed(3);
}

export function getUnitConvertedUnit(
	quantity,
	unit = 'gram'
	// inThousands = false
) {
	let qty = Number(quantity);
	// eslint-disable-next-line no-restricted-globals
	if (isNaN(qty)) {
		qty = 0;
	}
	if (qty === 0) {
		return unitTypes?.[unit]?.unitDisplay ?? '-';
	}
	if (qty >= unitTypes?.[unit]?.higherUnitDivisionFactor) {
		// const newNo = Number.parseFloat(
		// 	qty / unitTypes?.[unit]?.higherUnitDivisionFactor
		// ).toFixed(unitTypes?.[unit]?.toFixedDivision ?? 1);
		// if (newNo >= 1000 && !inThousands) {
		// 	// eslint-disable-next-line prefer-template
		// 	return 'K ' + unitTypes?.[unit]?.postConversionUnit;
		// }
		return unitTypes?.[unit]?.postConversionUnit;
	}
	return unitTypes?.[unit]?.unitDisplay;
}

export function convertWeightUnit(
	quantity,
	unit = 'gram',
	measurement = 'IND'
) {
	if (unit === null) {
		// Set a default value for unit if it's null or undefined
		// eslint-disable-next-line no-param-reassign
		unit = 'gram'; // You can set any default unit you prefer
	}
	// eslint-disable-next-line eqeqeq
	if (quantity == null || quantity == undefined) {
		return '-';
	}
	return (
		getUnitConvertedQuantity(quantity, unit, measurement) +
		getUnitConvertedUnit(quantity, unit, measurement)
	);
}

export function reverseUnitAndQtyNew(qty, unit) {
	if (!qty && qty !== 0) {
		return [0, unit];
	}

	let newQty = qty;
	let newUnit = unit;

	if (unit === 'kg') {
		newUnit = 'gram';
		newQty = Math.round(qty * 1000);
	}
	if (unit === 'ltr') {
		newUnit = 'ml';
		newQty = Math.round(qty * 1000);
	}
	if (unit === 'lb') {
		newUnit = 'oz';
		newQty = Math.round(qty * 16);
	}
	if (unit === 'gal') {
		newUnit = 'floz';
		newQty = Math.round(qty * 128);
	}

	return [newQty, newUnit];
}

export function getOurProductUnit(userUnit, raiseError = false) {
	let unit = '';
	let isHigherUnit = false;

	if (['gram', 'gm', 'g', 'Gram', 'Gm', 'G'].includes(userUnit)) {
		unit = 'gram';
		isHigherUnit = false;
	}
	if (['ml', 'mL', 'Milliliter', 'Ml', 'ML'].includes(userUnit)) {
		unit = 'ml';
		isHigherUnit = false;
	}
	if (['count', 'Count', 'C', 'pieces', 'piece'].includes(userUnit)) {
		unit = 'count';
		isHigherUnit = false;
	}
	if (['Kg', 'kg', 'KG', 'Killogram'].includes(userUnit)) {
		unit = 'kg';
		isHigherUnit = true;
	}
	if (['L', 'l', 'Litre', 'ltr'].includes(userUnit)) {
		unit = 'ltr';
		isHigherUnit = true;
	}
	if (['oz', 'Oz', 'OZ', 'Ounce', 'ounce'].includes(userUnit)) {
		unit = 'oz';
		isHigherUnit = false;
	}
	if (['floz', 'Floz', 'FLOZ', 'FlOz', 'Fluid Ounce', 'fluid ounce'].includes(userUnit)) {
		unit = 'floz';
		isHigherUnit = false;
	}
	if (['lbs', 'Lbs', 'LBS', 'Pound', 'pound'].includes(userUnit)) {
		unit = 'lbs';
		isHigherUnit = true;
	}
	if (['gl', 'gal', 'Gal', 'GAL', 'Gallon', 'gallon'].includes(userUnit)) {
		unit = 'gal';
		isHigherUnit = true;
	}

	if (unit === '') {
		if (raiseError) {
			throw new Error(`Invalid unit (${userUnit})`);
		} else {
			return [null, null];
		}
	}

	return [unit, isHigherUnit];
}

export function addCommas(number, formatType = 'IN', withMonetaryUnit = false) {
	// eslint-disable-next-line no-restricted-globals
	if (isNaN(Number(number))) return Number(0).toLocaleString(`en-${formatType}`);
	const isString = typeof number === 'string';
	const formattedNumber = new Intl.NumberFormat(`en-${formatType}`).format(
		isString ? number : number?.toFixed(2)
	);
	if (withMonetaryUnit) {
		return `${getMonetaryUnit(formatType)} ${formattedNumber}`;
	}
	return formattedNumber;
}

export function getMonetaryUnit(formatType = 'IN') {
	if (formatType === 'US') {
		return '$';
	}
	return '\u20B9';
}

export function getLast12Months() {
	const months = [];
	const currentMonth = moment();

	for (let i = 0; i < 12; i++) {
		const newMonth = currentMonth.clone().subtract(i, 'months');
		const monthYear = newMonth.format('MMM YY');
		months.push(monthYear);
	}

	return months;
}

export function getLastFourYears() {
	const currentYear = new Date().getFullYear();
	const lastThreeYears = [];

	for (let i = 0; i < 3; i++) {
		lastThreeYears.push(currentYear - i);
	}

	return lastThreeYears;
}

export function convertWeight(weight, isUS) {
	if (isUS) {
		// Convert grams to ounces (1 gram = 0.03527396 ounces)
		const ounces = weight * 0.03527396;
		if (ounces >= 16) {
			// Convert ounces to pounds (1 pound = 16 ounces)
			return {
				value: ounces / 16,
				unit: 'lbs',
			};
		}
		return {
			value: ounces,
			unit: 'oz',
		};
	}
	// Convert grams to kilograms (1 gram = 0.001 kilograms)
	const kilograms = weight * 0.001;
	if (kilograms >= 1) {
		return {
			value: kilograms,
			unit: 'kg',
		};
	}
	return {
		value: weight,
		unit: 'g',
	};
}

export function formatNumber(conversionType = 'IN', number) {
	const formatOptions = {
		IN: [
			{
				divisor: 10000000,
				unit: 'cr',
			},
			{
				divisor: 100000,
				unit: 'lac',
			},
		],
		US: [
			{
				divisor: 1000000000,
				unit: 'bn',
			},
			{
				divisor: 1000000,
				unit: 'mn',
			},
		],
	};

	const options = formatOptions[conversionType];

	if (!options) {
		return 'Invalid conversion type';
	}

	const formatted = options.find(({ divisor }) => {
		return number >= divisor;
	});
	const formatValue = number / formatted?.divisor || 1;
	return formatted
		? `${formatValue?.toFixed(2)} ${formatted.unit}`
		: addCommas(number, conversionType);
}

export const modes = {
	PRODUCTION: 'AI-Inventory-In',
	INWARD: 'AI-GRN',
	OUTWARD: 'AI-Inventory-Out',
	CMS: 'AI-Cook',
	WASTAGE: 'AI-Wastage',
	DISPATCH: 'AI-Dispatch',
	PRODUCTION_PLAN: 'AI-Plan-Production',
	AUDIT: 'AI-Audit',
};

export const options = {
	gram: [
		{
			displayName: 'Kg',
			value: 'kg',
		},
		{
			displayName: 'G',
			value: 'gram',
		},
	],
	g: [
		{
			displayName: 'Kg',
			value: 'kg',
		},
		{
			displayName: 'G',
			value: 'g',
		},
	],
	kg: [
		{
			displayName: 'Kg',
			value: 'kg',
		},
		{
			displayName: 'G',
			value: 'g',
		},
	],
	litre: [
		{
			displayName: 'L',
			value: 'L',
		},
		{
			displayName: 'mL',
			value: 'ml',
		},
	],
	ml: [
		{
			displayName: 'ltr',
			value: 'ltr',
		},
		{
			displayName: 'mL',
			value: 'ml',
		},
	],
	piece: [
		{
			displayName: 'Piece',
			value: 'piece',
		},
	],
	ltr: [
		{
			displayName: 'ltr',
			value: 'ltr',
		},
		{
			displayName: 'mL',
			value: 'ml',
		},
	],
	floz: [
		{
			displayName: 'Gal',
			value: 'gal',
		},
		{
			displayName: 'Fl oz',
			value: 'floz',
		},
	],
	gal: [
		{
			displayName: 'Gal',
			value: 'gal',
		},
		{
			displayName: 'Fl oz',
			value: 'floz',
		},
	],
	oz: [
		{
			displayName: 'Lb',
			value: 'lbs',
		},
		{
			displayName: 'Oz',
			value: 'oz',
		},
	],
	lbs: [
		{
			displayName: 'Lb',
			value: 'lbs',
		},
		{
			displayName: 'Oz',
			value: 'oz',
		},
	],
	lb: [
		{
			displayName: 'Lb',
			value: 'lb',
		},
		{
			displayName: 'Oz',
			value: 'oz',
		},
	],
};

const stateMap = {
	'01': 'Jammu and Kashmir',
	'02': 'Himachal Pradesh',
	'03': 'Punjab',
	'04': 'Chandigarh',
	'05': 'Uttarakhand',
	'06': 'Haryana',
	'07': 'Delhi',
	'08': 'Rajasthan',
	'09': 'Uttar Pradesh',
	10: 'Bihar',
	11: 'Sikkim',
	12: 'Arunachal Pradesh',
	13: 'Nagaland',
	14: 'Manipur',
	15: 'Mizoram',
	16: 'Tripura',
	17: 'Meghalaya',
	18: 'Assam',
	19: 'West Bengal',
	20: 'Jharkhand',
	21: 'Odisha',
	22: 'Chhattisgarh',
	23: 'Madhya Pradesh',
	24: 'Gujarat',
	25: 'Daman and Diu',
	26: 'Dadra and Nagar Haveli',
	27: 'Maharashtra',
	28: 'Andhra Pradesh',
	29: 'Karnataka',
	30: 'Goa',
	31: 'Lakshadweep',
	32: 'Kerala',
	33: 'Tamil Nadu',
	34: 'Puducherry',
	35: 'Andaman and Nicobar Islands',
	36: 'Telangana',
	37: 'Andhra Pradesh (New)',
	38: 'Telangana (New)',
	97: 'Other Territory',
	98: 'Other Territory',
	99: 'Other Territory',
};
export function getStateFromGSTIN(gstin) {
	const stateCode = gstin?.substr(0, 2);
	return stateMap[stateCode] || 'Unknown';
}
export function getStateCodeFromGSTIN(gstin) {
	return Number(gstin?.substr(0, 2));
}

export const getTaxFromStr = (tt) => {
	let num = 0;
	let taxStr = tt;
	if (!taxStr) return num;
	if (taxStr?.includes?.('%')) {
		taxStr = taxStr?.replace('%', '');
		if (!Number.isNaN(taxStr)) {
			num = Number(taxStr);
		}
	}
	return num;
};

export function downloadCSV(csv, filename) {
	// CSV file
	const csvFile = new Blob([csv], {
		type: 'text/csv',
	});

	// Download link
	const downloadLink = document.createElement('a');

	// File name
	downloadLink.download = filename;

	// Create a link to the file
	downloadLink.href = window.URL.createObjectURL(csvFile);

	// Hide download link
	downloadLink.style.display = 'none';

	// Add the link to DOM
	document.body.appendChild(downloadLink);

	// Click download link
	downloadLink.click();
}

export function downloadWithFileType(stringData, filename, fileType) {
	// CSV file
	const csvFile = new Blob([stringData], {
		type: fileType,
	});

	// Download link
	const downloadLink = document.createElement('a');

	// File name
	downloadLink.download = filename;

	// Create a link to the file
	downloadLink.href = window.URL.createObjectURL(csvFile);

	// Hide download link
	downloadLink.style.display = 'none';

	// Add the link to DOM
	document.body.appendChild(downloadLink);

	// Click download link
	downloadLink.click();
}

export const paymentModes = [
	'Bank Transfer',
	'Cash',
	'Card',
	'Cheque',
	'Gateway',
];

export function addDays(
	dateStr,
	noOfDays,
	dateFormat = 'YYYY-MM-DD HH:mm:ss',
	returnFormat = 'YYYY-MM-DD HH:mm:ss'
) {
	// Parse the input date string using Moment.js
	const date = moment(dateStr, dateFormat);
	// Add the specified number of days
	date.add(noOfDays, 'days');

	// Format the new date back to a string
	const newDateStr = date.format(returnFormat);

	return newDateStr;
}
function daysRemainingInCurrentMonth() {
	const today = new Date();
	const lastDayOfCurrentMonth = new Date(
		today.getFullYear(),
		today.getMonth() + 1,
		0
	);
	const timeDiff = lastDayOfCurrentMonth - today;
	return Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
}

function daysRemainingUntilEndOfNextMonth() {
	const today = new Date();
	const lastDayOfNextMonth = new Date(
		today.getFullYear(),
		today.getMonth() + 2,
		0
	);
	const timeDiff = lastDayOfNextMonth - today;
	return Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
}

export const paymentTermsList = [
	{
		value: 'Net 15',
		noOfDays: 15,
	},
	{
		value: 'Net 30',
		noOfDays: 30,
	},
	{
		value: 'Net 45',
		noOfDays: 45,
	},
	{
		value: 'Net 60',
		noOfDays: 60,
	},
	{
		value: 'Due end of the month',
		noOfDays: daysRemainingInCurrentMonth(),
	},
	{
		value: 'Due end of next month',
		noOfDays: daysRemainingUntilEndOfNextMonth(),
	},
	{
		value: 'Due on receipt',
		noOfDays: 0,
	},
];

export const findPaymentTerm = (paymentTerm) => {
	const currPaymentTerm = paymentTermsList?.find((ele) => {
		return ele?.value === paymentTerm;
	});
	return currPaymentTerm ?? {};
};

export function isDateWithinRange(date, range) {
	if (!range) return true;
	const fromDate = moment(range?.from).startOf('day');
	const toDate = moment(range?.to).startOf('day');
	const dateToCheck = moment(date).startOf('day');

	// Check if date is within range [fromDate, toDate]
	return (
		dateToCheck.isSameOrAfter(fromDate) &&
		dateToCheck.isSameOrBefore(toDate)
	);
}

export function isTimeLessThanCurrentTime(
	endTimeString,
	timeFormat = 'hh:mm A'
) {
	const currTime = moment();
	const endTime = moment(endTimeString, timeFormat);
	endTime.set({
		year: currTime.year(),
		month: currTime.month(),
		date: currTime.date(),
	});
	return endTime.isBefore(currTime);
}

export const billSubTypes = ['EXPENSE', 'UTILITIES', 'GRN', 'CAPEX'];

export function getRandomNeonDarkColor() {
	// Generate two random values between 0 and 100 for darkness
	const randomValue1 = Math.floor(Math.random() * 101);
	const randomValue2 = Math.floor(Math.random() * 101);

	// Set one channel to a high value between 150 and 255 for a neon effect
	const neonValue = Math.floor(Math.random() * 106) + 150;

	// Randomly decide which channel to assign the neon value
	const colorChannels = [
		[neonValue, randomValue1, randomValue2],
		[randomValue1, neonValue, randomValue2],
		[randomValue1, randomValue2, neonValue],
	];

	// Randomly pick one of the neon dark combinations
	const [r, g, b] = colorChannels[Math.floor(Math.random() * 3)];

	return `rgb(${r}, ${g}, ${b})`;
}

export const groupTypeFilterList = [
	{
		value: 'periodWise',
		label: 'Time',
		currentSelection: '',
		selection: {
			category: {
				value: null,
				label: 'Category',
			},
			subCategory: {
				value: null,
				label: 'Sub Category',
			},
		},
	},
	{
		value: 'productGroupWise',
		label: 'Product',
		currentSelection: 'brand',
		selection: {
			category: {
				value: null,
				label: 'Category',
			},
			subCategory: {
				value: null,
				label: 'Sub Category',
			},
		},
	},
	{
		value: 'geographyWise',
		label: 'Geography',
		currentSelection: 'brand',
		selection: {
			region: {
				value: null,
				label: 'Region',
			},
			city: {
				value: null,
				label: 'City',
			},
		},
	},
];

export const handleResponseStreamDownload = async (
	response,
	downloadFileName = 'test.csv'
) => {
	if (response.ok) {
		// let fileName = downloadFileName;
		// response?.headers?.forEach((ele) => { console.log(ele); });
		const blob = await response?.blob();
		const downloadUrl = window.URL.createObjectURL(blob);
		const hiddenElement = document.createElement('a');
		hiddenElement.href = downloadUrl;
		// if (getFileNameFromHeader) {
		// 	const contentDisposition = response?.headers?.get?.('content-disposition');
		// 	console.log(contentDisposition);
		// 	if (contentDisposition?.includes?.('filename=')) {
		// 		// eslint-disable-next-line prefer-destructuring
		// 		fileName = contentDisposition.split('filename=')[1];
		// 	}
		// }

		hiddenElement.download = downloadFileName;
		document.body.appendChild(hiddenElement);
		hiddenElement.click();
		window.URL.revokeObjectURL(downloadUrl);
		document.body.removeChild(hiddenElement);
	} else {
		return null;
	}
	return null;
};

export const higherUnits = {
	ml: {
		higherUnit: 'ltr',
		divideBy: 1000,
	},
	gram: {
		higherUnit: 'kg',
		divideBy: 1000,
	},
	floz: {
		higherUnit: 'gal',
		divideBy: 128,
	},
	oz: {
		higherUnit: 'lb',
		divideBy: 16,
	},
	count: {
		higherUnit: 'pc',
		divideBy: 1,
	},
};
export const convertToHigherUnit = (unit, value) => {
	return {
		unit: (higherUnits?.[unit]?.higherUnit ?? unit),
		value: value ? (value / higherUnits?.[unit]?.divideBy) : 0,
	};
};

export const skuTypesList = ['PRODUCT', 'INTERMEDIATE', 'INGREDIENT', 'PACKAGING', 'OTHER'];

export const skuTypesListWithId = [
	{
		id: 1,
		title: 'Product',
		displayName: 'Product',
	},
	{
		id: 2,
		title: 'Intermediate',
		displayName: 'Intermediate',
	},
	{
		id: 3,
		title: 'Ingredient',
		displayName: 'Ingredient',
	},
	{
		id: 4,
		title: 'Packaging',
		displayName: 'Packaging',
	},
	{
		id: 5,
		title: 'Other',
		displayName: 'Other',
	},
];

export const processTypes = [
	{
		id: 'STORAGE',
		name: 'STORAGE',
	},
	{
		id: 'OPERATION',
		name: 'OPERATION',
	},
];

export const renderComponentToHtml = (Component, props) => {
	const element = React.createElement(Component, props);
	const html = ReactDOMServer.renderToStaticMarkup(element);
	return html;
};

export const handleOrderStatus = (status) => {
	if (status === 'TRANSIT') {
		return 'IN TRANSIT';
	} if (status === 'DISPATCH') {
		return 'PENDING DISPATCH';
	} if (status === 'UNLOADING') {
		return 'UNLOADING STARTED';
	} if (status === 'VERIFICATION') {
		return 'PENDING VERIFICATION';
	}
	return status;
};

export const supplyChainFeilds = [
	{
		id: 1,
		name: 'SOURCING',
		value: ['WAREHOUSE_INGREDIENT', 'WAREHOUSE', 'WAREHOUSE_PRODUCT'],
	},
	{
		id: 2,
		name: 'PRODUCTION',
		value: 'BASE',
	},
	{
		id: 3,
		name: 'DISTRIBUTION',
		value: 'RETAIL',
	},
	{
		id: 4,
		name: 'TRANSIT',
		value: 'transit',
	},
];

export const getBase64FromFileBlob = (file, cb) => {
	const reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = function () {
		cb(reader.result);
	};
	reader.onerror = function (error) {
		console.log('Error: ', error);
	};
};

export function parseCSV(str) {
	const arr = [];
	let quote = false; // 'true' means we're inside a quoted field

	// Iterate over each character, keep track of current row and column (of the returned array)
	for (let row = 0, col = 0, c = 0; c < str.length; c++) {
		const cc = str[c]; const
			nc = str[c + 1]; // Current character, next character
		arr[row] = arr[row] || []; // Create a new row if necessary
		arr[row][col] = arr[row][col] || ''; // Create a new column (start with empty string) if necessary

		// If the current character is a quotation mark, and we're inside a
		// quoted field, and the next character is also a quotation mark,
		// add a quotation mark to the current column and skip the next character
		if (cc === '"' && quote && nc === '"') {
			arr[row][col] += cc;
			c++;
			continue;
		}

		// If it's just one quotation mark, begin/end quoted field
		if (cc === '"') { quote = !quote; continue; }

		// If it's a comma and we're not in a quoted field, move on to the next column
		if (cc === ',' && !quote) { ++col; continue; }

		// If it's a newline (CRLF) and we're not in a quoted field, skip the next character
		// and move on to the next row and move to column 0 of that new row
		if (cc === '\r' && nc === '\n' && !quote) {
			row++;
			col = 0;
			c++;
			continue;
		}

		// If it's a newline (LF or CR) and we're not in a quoted field,
		// move on to the next row and move to column 0 of that new row
		if (cc === '\n' && !quote) {
			row++;
			col = 0;
			continue;
		}
		if (cc === '\r' && !quote) {
			row++;
			col = 0;
			continue;
		}

		// Otherwise, append the current character to the current column
		arr[row][col] += cc;
	}
	return arr;
}

export const csvArrayToObject = (csvArray) => {
	const objects = [];
	const headers = csvArray[0];
	csvArray.forEach((item, index) => {
		if (index === 0) {
			return;
		}
		const object = {};
		item.forEach((e, i) => {
			object[headers[i]] = e;
		});
		objects.push(object);
	});
	return objects;
};

export const cellColor = (value) => {
	let number;
	if (typeof (value) === 'object') {
		number = (parseFloat(value?.fullfilledQty) / value?.orderQtyValue) * 100;
	} else {
		number = value;
	}
	const val = parseFloat(number);
	if (val === 0) {
		return '#000000';
	} if (val >= 95 && val <= 105) {
		return '#006400';
	}
	return '#8B0000';
};

export const calculateShelfLife = (mfgDate, expDate) => {
    if (!mfgDate || !expDate) return 0;
    const mfgTime = new Date(mfgDate).getTime();
    const expTime = new Date(expDate).getTime();
    const currentTime = new Date().getTime();

    if (isNaN(mfgTime) || isNaN(expTime)) {
        console.error('Invalid date format:', mfgDate, expDate);
        return 0;
    }

    if (currentTime >= expTime) return 0;

    const totalShelfLife = expTime - mfgTime;
    const remainingShelfLife = expTime - currentTime;

    const percentage = (remainingShelfLife / totalShelfLife) * 100;
    return Math.max(0, Math.round(percentage));
};

export  const formatDate = (date) => {
	const d = new Date(date);
	return `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`;
};