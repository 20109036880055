/* eslint-disable no-tabs */
import moment from 'moment';
// import React from 'react';
import { useMemo } from 'react';
import { ListTable } from '@/Components/ListTable';
import { convertWeightUnit } from '@/Helpers';

const TABLE_COLUMNS = [
	{
		title: 'SKU',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Activity Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'From Process',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'To Process',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Quantity',
		config: {
			type: 'DATA',
		},
	},
	// {
	// 	title: 'Stocked In (GRN)',
	// 	config: {
	// 		type: 'DATA',
	// 	},
	// },
	// {
	// 	title: 'Stocked In (Non-GRN)',
	// 	config: {
	// 		type: 'DATA',
	// 	},
	// },
	// {
	// 	title: 'Box Open (Stocked Out)',
	// 	config: {
	// 		type: 'DATA',
	// 	},
	// },
	// {
	// 	title: 'Box Open (Stocked In)',
	// 	config: {
	// 		type: 'DATA',
	// 	},
	// },
	// {
	// 	title: 'Audit Stocked Out',
	// 	config: {
	// 		type: 'DATA',
	// 	},
	// },
];

const COLMAP = {
	'Activity Date': 'stockDate',
	'From Process': 'fromProcess',
	'To Process': 'toProcess',
	Quantity: 'variantId',
	// 'Stocked In (GRN)': 'grnStockedIn',
	// 'Stocked In (Non-GRN)': 'nonGrnStockedIn',
	// 'Box Open (Stocked Out)': 'boxOpenedStockedOut',
	// 'Box Open (Stocked In)': 'boxOpenedStockedIn',
	// 'Audit Stocked Out': 'auditStockedOut',
};

const ProcessDayWiseTable = (props) => {
	const { data, itemDetails, allVariants } = props;

	const SPECIAL_ITEMS = {
		SKU: (id, itemObj) => {
			return (
				<div>
					{itemObj?.productName}
					{' '}
					(
					{itemObj?.variantName}
					)
				</div>
			);
		},
		Quantity: (variantId, itemObj) => {
			let unit = itemDetails?.unit ?? 'gram';
			if (!unit) {
				unit = 'oz';
			}
			return (
				<div className='flex items-center gap-1'>
					<p className=''>{convertWeightUnit(itemObj?.qty, unit)}</p>
				</div>
			);
		},
		'Activity Date': (stockDate) => {
			return (
				<div className='w-[150px]'>
					{moment(stockDate)?.format('YYYY-MM-DD')}
				</div>
			);
		},
	};

	const getRowStyles = (movementType) => {
		if (movementType === 'Stocked In') {
			return 'bg-[#00ff0d41]';
		}
		if (movementType === 'Stocked Out') {
			return 'bg-[#ff0c0c41]';
		}
		return '';
	};

	const filteredColumns = useMemo(() => {
		if (!allVariants) {
			return TABLE_COLUMNS.filter((ele) => {
				return ele?.title !== 'SKU';
			});
		}
		return TABLE_COLUMNS;
	}, [allVariants]);

	return (
		<div>
			<ListTable
				allowExport={false}
				allowCheckList={false}
				showBtnContainer={false}
				colMap={COLMAP}
				columns={filteredColumns}
				data={data?.map((ele) => {
					return {
						...ele,
						rowStyles: getRowStyles(ele?.movementType),
					};
				})}
				special={{
					...SPECIAL_ITEMS,
				}}
				fixedHeaderTop={0}
			/>
		</div>
	);
};

export default ProcessDayWiseTable;
