/* eslint-disable no-tabs */
/* eslint-disable no-unused-vars */
import { connect } from 'react-redux';
import { TextField } from '@mui/material';
import SelectPicker from 'rsuite/SelectPicker';
import {
	useCallback, useEffect, useMemo, useReducer, useState
} from 'react';
import Nav from 'rsuite/Nav';
import { Add, ContentPaste } from '@mui/icons-material';
import styles from './AddNewVendorModal.module.scss';
import { BaseModal } from '../BaseModal';
import { formActions, modalActions } from '../../../Actions';
import config from './addNewVendor.config';
import { notificationServices } from '../../../Services/Notification';
import { classes, inputHelper } from '../../../Helpers';
import allVendorServices from '../../../Services/Form Services/allvendor.services';
import { Label } from '@/Components/ui/label';
import { Input } from '@/Components/ui/input';
import { Button } from '@/Components/ui/button';
import { paymentTerms } from '@/Components/POGeneralDetails/POGeneralDetails';

const LabelledInput = (props) => {
	const {
		value, onChange, id, name, placeholder, labelText, mandatory,
	} =
		props;
	return (
		<div className='flex items-center gap-2'>
			<Label
				className={`${
					mandatory ? 'text-red-600' : ''
				} text-left min-w-[150px]`}
				htmlFor={id}
			>
				{labelText?.replace('*', '')}
				{mandatory && <span className='text-red-600'>*</span>}
			</Label>
			<Input
				id={id}
				name={name}
				value={value}
				onChange={onChange}
				placeholder={placeholder}
			/>
			{/* <TextField
				id={id}
				name={name}
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				sx={{
					'.MuiInputBase-root': {
						borderRadius: '12px',
					},
					'.MuiInputBase-input': {
						paddingTop: '6px',
						paddingBottom: '6px',
					},
				}}
			/> */}
		</div>
	);
};
const AddNewVendorModal = (props) => {
	const {
		show, data, toggleModal, saveVendor, fetchVendors, pageType, fetchVendorsData,
	} =
		props;
	const { vendorData, isEdit } = data ?? {};
	const isBuyer = data?.type === 'BUYER';
	const [state, dispatch] = useReducer(config.reducer, config.initialState);
	const [locationError, setLocationError] = useState([]);
	const [currentTab, setcurrentTab] = useState({});
	const [typeInfo, setTypeInfo] = useState([]);
	const {
		name: vendorName,
		locations,
		companyName,
		type: vendorType,
		clientVendorId,
		legalName,
		paymentTermCode,
	} = state ?? {};

	const currentLocation = useMemo(() => {
		return locations?.find((ele) => {
			return ele?.id === currentTab;
		});
	}, [locations, currentTab]);

	const setCurrentLocation = (locationState) => {
		let newCurrentLocationState = {
			...locationState,
		};
		if (typeof locationState === 'function') {
			newCurrentLocationState = locationState(currentLocation);
		}

		const newLocationsState = locations?.map((ele) => {
			if (ele?.id === newCurrentLocationState?.id) {
				return newCurrentLocationState;
			}
			return ele;
		});
		dispatch({
			type: 'SET_LOCATIONS',
			payload: newLocationsState,
		});
	};

	const handleClose = () => {
		toggleModal(false, {});
	};

	const fetchVendorType = async () => {
		try {
			const response = await allVendorServices.listVendorType();
			if (response?.statusCode === 200) {
				setTypeInfo(response?.data);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	useEffect(() => {
		fetchVendorType();
	}, []);

	useEffect(() => {
		if (show && vendorData) {
			dispatch({
				type: 'SET_DEFAULT',
				payload: {
					defaultVendorId: vendorData?.id,
					name: vendorData?.name,
					legalName: vendorData?.legalName,
					type: vendorData?.subTypeId,
					companyName: vendorData?.companyName,
					paymentTermCode: vendorData?.paymentTermCode,
					logoImage: vendorData?.logo,
					isExpenseAllowed: vendorData?.isExpenseAllowed,
					clientVendorId: vendorData?.clientVendorId,
					locations: (vendorData?.vendor_locations ?? [])?.map(
						(locationData) => {
							return {
								defaultLocationId: locationData?.id,
								id: locationData?.id,
								name: locationData?.name,
								gstin: locationData?.gstin,
								clientLocationId:
									locationData?.clientLocationId,
								locationEmail: locationData?.vendorEmail,
								locationPhone: locationData?.vendorPhone,
								billingAddress: {
									address1: locationData?.billingAddress1,
									address2: locationData?.billingAddress2,
									addressCity:
										locationData?.billingAddressCity,
									addressState:
										locationData?.billingAddressState,
									addressPinCode:
										locationData?.billingAddressPinCode,
								},
								shippingAddress: {
									address1: locationData?.shippingAddress1,
									address2: locationData?.shippingAddress2,
									addressCity:
										locationData?.shippingAddressCity,
									addressState:
										locationData?.shippingAddressState,
									addressPinCode:
										locationData?.shippingAddressPinCode,
								},
							};
						}
					),
				},
			});
		}
	}, [show, vendorData]);

	useEffect(() => {
		if (!show) {
			dispatch({
				type: 'INIT',
			});
			// fetchVendors({
			// 	entity: 'ALL_VENDORS',
			// 	req: {
			// 		params: {
			// 			id: '',
			// 		},
			// 	},
			// 	method: 'LIST',
			// 	type: pageType,
			// });
			if (data?.onLocationAddComplete) {
				data?.onLocationAddComplete();
			}
		}
	}, [show, data?.type]);

	// console.log('locations', locations);
	useEffect(() => {
		if (show) {
			if (vendorData?.vendor_locations?.length > 0) {
				// console.log('locations', locations);
				setcurrentTab(vendorData?.vendor_locations?.[0]?.id);
			} else {
				setcurrentTab(locations?.[0]?.id);
			}
		}
	}, [show, vendorData?.vendor_locations]);

	const handleVendorState = (e) => {
		const { name, value } = e.target;
		dispatch({
			type: `SET_${name}`,
			payload: value,
		});
	};

	const handlePaymentTermCode = (value) => {
		dispatch({
			type: 'SET_PAYMENT_TERM_CODE',
			payload: value,
		});
	};

	const handleIsExpenseAllowed = () => {
		dispatch({
			type: 'SET_IS_EXPENSE_ALLOWED',
			payload: !state?.isExpenseAllowed,
		});
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setCurrentLocation((prev) => {
			return {
				...prev,
				[name]: value,
			};
		});
	};

	// console.log('currentLocation', currentLocation);

	const handleAddressChange = (e, addressType) => {
		const { name, value } = e.target;
		const newAddressObj = {
			...currentLocation?.[addressType],
			[name]: value,
		};
		if (addressType === 'billingAddress') {
			setCurrentLocation((prev) => {
				return {
					...prev,
					billingAddress: newAddressObj,
				};
			});
		} else {
			setCurrentLocation((prev) => {
				return {
					...prev,
					shippingAddress: newAddressObj,
				};
			});
		}
	};

	const validateAddress = (tLocations) => {
		const check = (location, index, addressType) => {
			const {
				address1,
				address2,
				addressCity,
				addressState,
				addressPinCode,
			} = location || {};
			const emptyFields = [];
			if (address1?.length === 0) emptyFields.push(`Address 1 at location ${index + 1}`);
			if (address2?.length === 0) emptyFields.push(`Address 2 at location ${index + 1}`);
			if (addressCity?.length === 0) emptyFields.push(`City at location ${index + 1}`);
			if (addressState?.length === 0) emptyFields.push(`State at location ${index + 1}`);
			if (addressPinCode?.length === 0) emptyFields.push(`Pincode at location ${index + 1}`);

			if (emptyFields.length > 0) {
				notificationServices.generateNotification({
					type: 'error',
					message: `${addressType} fields : ${emptyFields.join(', ')}`,
				});
				return false;
			}
			return true;
		};

		const billingAddressValid = tLocations.every(
			(location, index) => check(location.billingAddress, index, 'billingAddress')
		);
		const shippingAddressValid = tLocations.every(
			(location, index) => check(location.shippingAddress, index, 'shippingAddress')
		);

		return billingAddressValid && shippingAddressValid;
	};

	const handleConfirm = (isEditing = false) => {
		const reg = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
		const deserializedData = config?.deserializer(state);
		if (!deserializedData?.name) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Vendor Name is mandatory',
			});
			return;
		}
		if (!deserializedData?.legalName) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Legal Name is mandatory',
			});
			return;
		}
		if (isBuyer && !deserializedData?.paymentTermCode) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Payment Term Code is mandatory',
			});
			return;
		}
		if (!deserializedData?.type && data?.type === 'VENDOR') {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Vendor type is mandatory',
			});
			return;
		}
		if (deserializedData?.locations?.length) {
			const isLocationNames = deserializedData?.locations?.some((ele) => {
				return ele?.name;
			});
			let isGstEmpty = false;
			const isGstCorrect = deserializedData?.locations?.some((ele) => {
				if (ele?.gstin && ele?.gstin !== '') {
					if (!reg.test(ele?.gstin)) {
						setLocationError((prev) => {
							return [...prev, ele?.id];
						});
					}
					return reg.test(ele?.gstin);
				}
				isGstEmpty = true;
				return true;
			});
			const hasEmptyClientLocationId = deserializedData?.locations?.some((ele) => {
				return !ele?.clientLocationId;
			});

			if (hasEmptyClientLocationId) {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Client Location ID is mandatory',
				});
				return;
			}
			if (data?.type === 'BUYER') {
				if (isGstEmpty) {
					notificationServices.generateNotification({
						type: 'error',
						message: 'GST Number is mandatory',
					});
					return;
				}
				if (!validateAddress(deserializedData?.locations)) {
					return;
				}
			}
			if (!isLocationNames) {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Vendor Location Name cannot be empty',
				});
				return;
			}
			if (!isGstCorrect) {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Please enter valid GST Number',
				});
				return;
			}
		}
		const req = {
			data: {
				...deserializedData,
				defaultLocation: deserializedData?.locations?.[0],
			},
		};
		saveVendor({
			entity: 'ALL_VENDORS',
			req,
			method: isEditing ? 'UPDATE' : 'CREATE',
			type: data?.type,
		});
		setTimeout(() => {
			fetchVendorsData();
		}, 2000);
		handleClose();
	};

	// const handleEditConfirm = () => {
	// 	const reg = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
	// 	const deserializedData = config?.deserializer(state);
	// 	if (!deserializedData?.name) {
	// 		notificationServices.generateNotification({
	// 			type: 'error',
	// 			message: 'Vendor Name is mandatory',
	// 		});
	// 		return;
	// 	}
	// 	if (!deserializedData?.legalName) {
	// 		notificationServices.generateNotification({
	// 			type: 'error',
	// 			message: 'Legal Name is mandatory',
	// 		});
	// 		return;
	// 	}
	// 	if (!deserializedData?.type) {
	// 		notificationServices.generateNotification({
	// 			type: 'error',
	// 			message: 'Vendor type is mandatory',
	// 		});
	// 		return;
	// 	}
	// 	if (!deserializedData?.locations?.length) {
	// 		notificationServices.generateNotification({
	// 			type: 'error',
	// 			message: 'Atleast 1 location is mandatory',
	// 		});
	// 		return;
	// 	}
	// 	// if (deserializedData?.gstin && !reg.test(deserializedData?.gstin)) {
	// 	// notificationServices.generateNotification({
	// 	// type: 'error',
	// 	// message: 'Please enter valid GST Number',
	// 	// });
	// 	// return;
	// 	// }
	// 	const req = {
	// 		data: {
	// 			...deserializedData,
	// 			defaultLocation: deserializedData?.locations?.[0],
	// 		},
	// 	};
	// 	saveVendor({
	// 		entity: 'ALL_VENDORS',
	// 		req,
	// 		method: 'UPDATE',
	// 		type: data?.type,
	// 	});
	// 	handleClose();
	// };

	const renderBtns = useCallback(() => {
		return (
			<div className='w-full flex items-center gap-2'>
				<Button
					className='flex-1'
					variant='outline'
					onClick={handleClose}
				>
					Cancel
				</Button>
				<Button
					className='flex-1 text-black bg-uy-color hover:bg-slate-300'
					color='primary'
					onClick={() => {
						// if (isEdit) {
						// // handleEditConfirm();
						// // return;
						// }
						handleConfirm(isEdit);
					}}
				>
					Confirm
				</Button>
			</div>
		);
	}, [state]);

	const handleCopyAddress = () => {
		setCurrentLocation((prev) => {
			return {
				...prev,
				shippingAddress: {
					...prev?.billingAddress,
				},
			};
		});
	};

	const addNewLocation = () => {
		const newLocation = {
			...config?.initialLocationState,
			id: new Date().getTime(),
			name: `Location ${locations?.length + 1}`,
		};
		const tempLocation = [...locations, newLocation];
		// if (tempLocation?.length > 1) {
		// 	notificationServices.generateNotification({
		// 		type: 'error',
		// 		message: 'More than 1 location is restricted currently',
		// 	});
		// } else {
		dispatch({
			type: 'SET_LOCATIONS',
			payload: tempLocation,
		});
		setcurrentTab(newLocation?.id);
		// }
	};

	const deleteLocation = (id) => {
		const tempLocation = locations?.filter((ele) => {
			return ele?.id !== id;
		});
		dispatch({
			type: 'SET_LOCATIONS',
			payload: tempLocation,
		});
	};

	const selectedType = useMemo(() => {
		const currItem = typeInfo?.find((ele) => {
			return ele?.value === vendorType;
		});
		return currItem;
	}, [typeInfo, vendorType]);

	return (
		<BaseModal
			open={show}
			heading={
				data?.heading ??
				(data?.type === 'VENDOR' ? 'Add New Vendor' : 'Add New Buyer')
			}
			handleClose={handleClose}
			renderBtns={renderBtns}
			width='60rem'
			height='40rem'
		>
			<div className={styles.root}>
				<LabelledInput
					mandatory
					id='vendorName'
					name='VENDOR_NAME'
					value={vendorName}
					onChange={handleVendorState}
					placeholder={
						data?.type === 'VENDOR'
							? 'Enter Vendor Name'
							: 'Enter Buyer Name'
					}
					labelText={
						data?.type === 'VENDOR' ? 'Vendor Name*' : 'Buyer Name*'
					}
				/>
				<LabelledInput
					mandatory
					id='legalName'
					name='LEGAL_NAME'
					value={legalName}
					onChange={handleVendorState}
					placeholder='Enter Legal Name'
					labelText='Legal Name*'
				/>
				<LabelledInput
					mandatory
					id='clientVendorId'
					name='CLIENT_VENDOR_ID'
					value={clientVendorId}
					onChange={handleVendorState}
					placeholder={
						data?.type === 'VENDOR'
							? 'Enter Client Vendor ID'
							: 'Enter Client Buyer ID'
					}
					labelText={
						data?.type === 'VENDOR'
							? 'Client Vendor ID*'
							: 'Client Buyer ID*'
					}
				/>
				{isBuyer && (
					<div className={classes(styles['label-container'])}>
						<Label
							className='text-red-600 min-w-[150px] text-left'
							htmlFor='deliverTo'
						>
							Select Payment Term Code*
						</Label>
						<SelectPicker
							data={paymentTerms?.map((ele) => {
								return {
									label: ele,
									value: ele,
								};
							})}
							searchable={false}
							style={{
								width: 300,
							}}
							menuStyle={{
								zIndex: 9999,
							}}
							cleanable={false}
							placeholder='Select Payment Term Code'
							onChange={(type) => {
								const ptValue = paymentTerms?.find((ele) => {
									return ele === type;
								});
								handlePaymentTermCode(ptValue);
							}}
							value={paymentTermCode}
							renderValue={(value, item) => {
								return (
									<div className='rsuite-select-picker-render-value'>
										{item?.label}
									</div>
								);
							}}
						/>
					</div>
				)}
				{data?.type === 'VENDOR' && (
					<div className={classes(styles['label-container'])}>
						<Label
							className='text-red-600 min-w-[150px] text-left'
							htmlFor='deliverTo'
						>
							Select Type*
						</Label>
						<div className={styles['deliver-to']}>
							<SelectPicker
								data={typeInfo}
								style={{
									width: 180,
								}}
								menuStyle={{
									zIndex: 9999,
								}}
								cleanable={false}
								groupBy='role'
								placeholder='Select type'
								onChange={(type) => {
									const vendorTypeValue = typeInfo?.find(
										(ele) => {
											return ele?.value === type;
										}
									);
									dispatch({
										type: 'SET_TYPE',
										payload: type,
									});
									if (
										vendorTypeValue?.role !== 'INDENT' &&
										state?.isExpenseAllowed
									) {
										dispatch({
											type: 'SET_IS_EXPENSE_ALLOWED',
											payload: false,
										});
									}
								}}
								renderMenuItem={(_, item) => {
									return (
										<div>
											<i className='rs-icon rs-icon-user' />
											{' '}
											{_}
										</div>
									);
								}}
								renderMenuGroup={(_, item) => {
									return (
										<div>
											<i className='rs-icon rs-icon-group' />
											{_}
										</div>
									);
								}}
								value={vendorType}
								renderValue={(value, item) => {
									return (
										<div className='rsuite-select-picker-render-value'>
											{item?.label}
										</div>
									);
								}}
							/>
							{selectedType?.role === 'INDENT' && (
								<div className={styles.is_expense}>
									<input
										id='isExpenseAllowed'
										type='checkbox'
										onChange={handleIsExpenseAllowed}
										checked={state?.isExpenseAllowed}
									/>
									<label htmlFor='isExpenseAllowed'>
										Allowed For Expenses?
									</label>
								</div>
							)}
						</div>
					</div>
				)}
				<LabelledInput
					id='companyName'
					name='COMPANY_NAME'
					value={companyName}
					onChange={handleVendorState}
					placeholder='Enter Company Name'
					labelText='Company Name'
				/>
				<div className=''>
					<Nav
						appearance='subtle'
						activeKey={currentTab}
						onSelect={(key) => {
							setcurrentTab(key);
						}}
						style={{
							// marginBottom: 25,
							width: '100%',
						}}
					>
						{locations?.map((ele) => {
							return (
								<Nav.Item
									eventKey={ele?.id}
									style={{
										color: '#000000',
									}}
								>
									{ele?.name}
								</Nav.Item>
							);
						})}
						{/* {locations?.length < 1 && ( */}
						{/* hide multiple location button */}
						{/* <Nav.Item
							onClick={addNewLocation}
							className={styles['add-btn']}
						>
							<Add className={styles.icon} />
							<p>Add New Location</p>
						</Nav.Item> */}
						{/* )} */}
					</Nav>
				</div>
				{currentLocation && (
					<div className={styles['location-details']}>
						<LabelledInput
							id='name'
							name='name'
							value={currentLocation?.name}
							onChange={handleChange}
							placeholder='Enter Location Name'
							labelText='Location Name*'
							mandatory
						/>
						<div className={styles.multi}>
							<LabelledInput
								mandatory={data?.type !== 'VENDOR'}
								id='gstin'
								name='gstin'
								value={currentLocation?.gstin}
								onChange={handleChange}
								placeholder='Enter GSTIN'
								labelText='GSTIN'
							/>
							<LabelledInput
								id='clientLocationId'
								mandatory
								name='clientLocationId'
								value={currentLocation?.clientLocationId}
								onChange={handleChange}
								placeholder='Enter Client Location ID'
								labelText='Client Location ID'
							/>
						</div>
						<div className={styles.multi}>
							<LabelledInput
								id='vendorEmail'
								name='locationEmail'
								value={currentLocation?.locationEmail}
								type='email'
								onChange={(e) => {
									handleChange(e);
									// eslint-disable-next-line no-useless-escape
									// const regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
								}}
								placeholder='Enter Location Email'
								labelText='Location Email'
							/>
							<LabelledInput
								id='vendorPhone'
								name='locationPhone'
								value={currentLocation?.locationPhone}
								onChange={(e) => {
									const regex = /^\d{0,10}$/;
									if (
										regex.test(
											Number(e?.target?.value.trim())
										)
									) {
										handleChange(e);
									}
								}}
								placeholder='Enter Location Phone'
								labelText='Location Phone'
							/>
						</div>
						<div className={styles['address-container']}>
							<div className={styles.billing}>
								<p className={styles.header}>Billing Address</p>
								<div className={styles['input-container']}>
									<LabelledInput
										id='billingAddress1'
										mandatory={data?.type === 'BUYER'}
										name='address1'
										value={
											currentLocation?.billingAddress
												?.address1
										}
										onChange={(e) => {
											handleAddressChange(
												e,
												'billingAddress'
											);
										}}
										placeholder='Enter Address Line 1'
										labelText='Address 1'
									/>
									<LabelledInput
										id='billingAddress2'
										name='address2'
										mandatory={data?.type === 'BUYER'}
										value={
											currentLocation?.billingAddress
												?.address2
										}
										onChange={(e) => {
											handleAddressChange(
												e,
												'billingAddress'
											);
										}}
										placeholder='Enter Address Line 2'
										labelText='Address 2'
									/>
									<LabelledInput
										id='addressCity'
										name='addressCity'
										mandatory={data?.type === 'BUYER'}
										value={
											currentLocation?.billingAddress
												?.addressCity
										}
										onChange={(e) => {
											handleAddressChange(
												e,
												'billingAddress'
											);
										}}
										placeholder='Enter Address City'
										labelText='City'
									/>
									<LabelledInput
										id='addressState'
										name='addressState'
										mandatory={data?.type === 'BUYER'}
										value={
											currentLocation?.billingAddress
												?.addressState
										}
										onChange={(e) => {
											handleAddressChange(
												e,
												'billingAddress'
											);
										}}
										placeholder='Enter State'
										labelText='State'
									/>
									<LabelledInput
										id='addressPincode'
										name='addressPinCode'
										mandatory={data?.type === 'BUYER'}
										value={
											currentLocation?.billingAddress
												?.addressPinCode
										}
										onChange={(e) => {
											const regex = new RegExp(
												'^[0-9]*$'
											);
											if (regex.test(e?.target?.value)) {
												handleAddressChange(
													e,
													'billingAddress'
												);
											}
										}}
										placeholder='Enter Pincode'
										labelText='Pincode'
									/>
								</div>
							</div>
							<div className={styles.shipping}>
								<div className={styles['header-container']}>
									<p className={styles.header}>
										Pickup Address
									</p>
									<div
										onClick={handleCopyAddress}
										className={styles['copy-add']}
									>
										<ContentPaste className={styles.icon} />
										<p>Copy Billing Address</p>
									</div>
								</div>
								<div className={styles['input-container']}>
									<LabelledInput
										id='billingAddress1'
										name='address1'
										mandatory={data?.type === 'BUYER'}
										value={
											currentLocation?.shippingAddress
												?.address1
										}
										onChange={(e) => {
											handleAddressChange(
												e,
												'shippingAddress'
											);
										}}
										placeholder='Enter Address Line 1'
										labelText='Address 1'
									/>
									<LabelledInput
										id='billingAddress2'
										name='address2'
										mandatory={data?.type === 'BUYER'}
										value={
											currentLocation?.shippingAddress
												?.address2
										}
										onChange={(e) => {
											handleAddressChange(
												e,
												'shippingAddress'
											);
										}}
										placeholder='Enter Address Line 2'
										labelText='Address 2'
									/>
									<LabelledInput
										id='addressCity'
										name='addressCity'
										mandatory={data?.type === 'BUYER'}
										value={
											currentLocation?.shippingAddress
												?.addressCity
										}
										onChange={(e) => {
											handleAddressChange(
												e,
												'shippingAddress'
											);
										}}
										placeholder='Enter Address City'
										labelText='City'
									/>
									<LabelledInput
										id='addressState'
										name='addressState'
										mandatory={data?.type === 'BUYER'}
										value={
											currentLocation?.shippingAddress
												?.addressState
										}
										onChange={(e) => {
											handleAddressChange(
												e,
												'shippingAddress'
											);
										}}
										placeholder='Enter State'
										labelText='State'
									/>
									<LabelledInput
										id='addressPincode'
										name='addressPinCode'
										mandatory={data?.type === 'BUYER'}
										value={
											currentLocation?.shippingAddress
												?.addressPinCode
										}
										onChange={(e) => {
											const regex = new RegExp(
												'^[0-9]*$'
											);
											if (regex.test(e?.target?.value)) {
												handleAddressChange(
													e,
													'shippingAddress'
												);
											}
										}}
										placeholder='Enter Pincode'
										labelText='Pincode'
									/>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.addVendorModal;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleAddVendorModal,
	saveVendor: formActions.gateway,
	fetchVendors: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewVendorModal);
