/* eslint-disable */
/* eslint-disable no-unused-vars */
import {
	useRef, useState, useEffect
} from 'react';
import { Button, Tooltip } from '@mui/material';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LeftBox from '../../Components/LabelGenerator/LabelGeneratorLeftBox/LeftBox';
import RightBox from '../../Components/LabelGenerator/LabelGeneratorRightBox/RightBox';
import labelComponents from './labelComponents';
import parameters from './parameters';
import './LabelGenerator.scss';
import labelGeneratorServices from '../../Services/labelGeneratorServices';
import { notificationServices } from '../../Services';
import { BackdropLoader } from '../../Components/Loaders';
import { generateTable } from '../../Components/LabelGenerator/LableGeneratorTable/Table';
import { urls } from '../../Constants';
import labelGeneratorConfig from '../../Components/LabelGenerator/LabelGenerator.config';

const LabelGenerator = () => {
	const history = useHistory();
	const [selectedToolComponent, setSelectedToolComponent] = useState({
		type: 'mainScreen',
	});

	// eslint-disable-next-line no-unused-vars
	const [createLabel, setCreateLabel] = useState(true);
	const [toolInUse, setToolInUse] = useState('mainScreen');
	const [editToolInUse, setEditToolInUse] = useState(null);
	const [rightScreen, setRightScreen] = useState(true);
	const [staticTextEntered, setStaticTextEntered] = useState(false);
	const [labelType, setLabelType] = useState('MACHINE');
	// const [staticText, setStaticText] = useState('Random...');
	const [staticTextBackButtonClicked, setStaticTextBackButtonClicked] =
		useState(true);
	// const [placeholder, setPlaceholder] = useState('Product Name');
	const [backButtonClicked, setBackButtonClicked] = useState(false);
	const [saveButtonClicked, setSaveButtonClicked] = useState(false);
	const [savedLabelsClicked, setSavedLabelsClicked] = useState(false);
	const ref = useRef(null);
	const [myComponentsClicked, setMyComponentsClicked] = useState(false);
	const [previewLabelClicked, setPreviewLabelClicked] = useState(false);
	const [labelComponentsState, setLabelComponentsState] =
		useState(labelComponents);
	const [savedLabels, setSavedLabels] = useState([]);
	const [currentLabelComponents, setCurrentLabelComponents] = useState([]);
	const [labelDetails, setLabelDetails] = useState({
		size: {
			width: 75,
			height: 25,
		},
		name: '',
		printCount: 1,
	});
	const [isLoading, setIsLoading] = useState(false);
	const [selectedComponent, setSelectedComponent] = useState(null);
	const [currentComponent, setCurrentComponent] = useState(null);
	const [placeholderTextEntered, setPlaceholderTextEntered] = useState(false);
	// const [imageURL, setImageURL] = useState(null);
	const [currentParameters, setCurrentParameters] = useState(parameters);
	const [printerIP, setPrinterIP] = useState('192.168.1.9');
	const [zplCodePreview, setZplCodePreview] = useState(null);
	const [zplCodePrint, setZplCodePrint] = useState(null);
	const [tableContent, setTableContent] = useState([
		[
			{
				Content: 'Heading1',
			},
			{
				Content: 'Heading2',
			},
		],
		[
			{
				type: 'fixed', content: '',
			},
			{
				type: 'fixed', content: '',
			},
		],
		[
			{
				type: 'fixed', content: '',
			},
			{
				type: 'fixed', content: '',
			},
		],
	]);
	// const [checkState, setCheckState] = useState();
	const location = useLocation();

	const queryParams = new URLSearchParams(location.search);
	const currLabel = JSON.parse(queryParams?.get('currLabel'));
	useEffect(() => {
		if (currLabel) {
			setLabelComponentsState(currLabel?.label);
			setLabelDetails({
				key: currLabel?.key,
				id: currLabel?.id,
				size: currLabel?.size,
				name: currLabel?.name,
				type: currLabel?.type,
				printCount: currLabel?.printCount ?? 1,
			});
			setLabelType(currLabel?.type);
		}
	}, []);

	const handleImageUpload = (event) => {
		const file = event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				// setImageURL(reader.result);
				setSelectedToolComponent({
					type: 'image',
					image: {
						imageURL: reader.result,
						imageDim: {
							width: '3rem',
							height: '3rem',
						},
					},
				});
			};
			reader.readAsDataURL(file);
		}
		setSavedLabelsClicked(false);
	};
	async function resizeImage(imageUrl, newWidth, newHeight) {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.onload = () => {
				const canvas = document.createElement('canvas');
				canvas.width = newWidth;
				canvas.height = newHeight;
				const ctx = canvas.getContext('2d');
				ctx.drawImage(img, 0, 0, newWidth, newHeight);
				resolve(canvas.toDataURL('image/png'));
			};
			img.onerror = (error) => {
				reject(new Error(error));
			};
			img.src = imageUrl;
		});
	}
	const base64ToBlob = (base64) => {
		const byteCharacters = atob(base64);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		return new Blob([byteArray], {
			type: 'image/png',
		});
	};

	useEffect(() => {
		if (tableContent && tableContent.length) {
			setSelectedToolComponent({
				...selectedToolComponent,
				[selectedToolComponent?.type]: {
					...selectedToolComponent[selectedToolComponent?.type],
					tableContent,
				},
			});
		}
	}, [tableContent]);

	const convertLabelToZPL = async (labelData) => {
		let zplPreview;
		let zplPrint;
		zplPreview = '^XA^LRY\n';
		zplPrint = `^XA^LRY^LH${
			55 * 8 - (labelDetails.size.width / 2) * 8 - 16
		},10\n`;
		const label = labelData?.[0];
		for (let index = 0; index < label.length; index++) {
			// const DPI = 203;
			// const mmToDots = (Pixcels) => Math.round(1 * Pixcels * (DPI / 25.5));
			// const moduleSizeInDots = 8;
			const dot =
				labelDetails.size.width / labelDetails.size.height / (203 / 8);
			const labelDot = (300 / 352) * dot;
			const rem = (352 / 22) * labelDot;
			const autoLen = 9 * labelDot;

			const component = label[index];
			// let len = 0;
			let height = 0;
			let width = 0;
			let par1 = {};
			let text = '';
			// var size = (352/(130*5))*labelDot;
			// const size = 352 / (130 * 5);
			let fontSize = 0;
			switch (component.type) {
				case 'staticText': {
					text = component.staticText.text;
					// len = text.length;
					height = component.staticText.height;
					width = component.staticText.width;
					fontSize = component.staticText.fontSize / 0.53;
					if (width === 'auto') {
						par1 = {
							width: autoLen,
							height: 17,
						};
					} else {
						height =
							Number(
								height.substring(0, height.length - 3) * 12.5
							) /
							(96 / 25.4);
						width =
							Number(
								width.substring(0, width.length - 3) * 12.5
							) /
							(96 / 25.4);
						par1 = {
							width: width * 8,
							height: height * 0.1,
						};
					}
					const staticcode = `^FO${component.position.x * 1.65},${
						component.position.y * 1.65
					}^A0,,${fontSize}^FB${par1.width},${
						par1.height
					},30,L,^FD${text}^FS\n`;
					zplPreview += staticcode;
					zplPrint += staticcode;
					if (component.staticText.highlight) {
						const highlighter =
						`^FO${component.position.x * 1.65},${component.position.y * 1.65}
						^GB${par1.width},${fontSize},${fontSize}^FS`;
						zplPreview += highlighter;
						zplPrint += highlighter;
					}
					// eslint-disable-next-line max-len
					break;
				}
				case 'text': {
					height = component.text.height;
					width = component.text.width;
					const lines = component?.text?.inputLines;
					text = currentParameters[component.text.placeHolderName];
					fontSize = component.text.fontSize / 0.53;
					// len = text.length;
					if (width === 'auto') {
						par1 = {
							width: autoLen,
							height: 17,
						};
					} else {
						height =
							Number(
								height.substring(0, height.length - 3) * 12.25
							) /
							(96 / 25.4);
						width =
							Number(
								width.substring(0, width.length - 3) * 12.25
							) /
							(96 / 25.4);
						par1 = {
							width: width * 8,
							height: height * 0.1,
						};
					}
					// if (component.text.placeHolderName === 'Manufacturing Date') {

					// }
					// if ((component.text.placeHolderName === 'Expiry Date' || component.text.placeHolderName === 'Manufacturing Date')) {
					// 	const vlue = moment(document.getElementById(id).value)?.format('DD MMM YY');
					// 	setCurrentParameters((prevParamas) => {
					// 		return {
					// 			...prevParamas,
					// 			[name]: vlue,
					// 		};
					// 	});
					// } else if (name === 'Manufacturing Timestamp' && isValidDate) {
					// 	const vlue = moment(document.getElementById(id).value)?.format('DD MMM YY HH:MM:SS A');
					// 	setCurrentParameters((prevParamas) => {
					// 		return {
					// 			...prevParamas,
					// 			[name]: vlue,
					// 		};
					// 	});
					// }
					const textcode = `^FO${component.position.x * 1.65},${
						component.position.y * 1.65
					}^A0,,${fontSize}^FB${par1.width},${lines},0,L,^FD{${
						component.text.placeHolderName
					}}^FS\n`;
					zplPreview += textcode;
					zplPrint += textcode;
					if (component.text.highlight) {
						const highlighter =
						`^FO${component.position.x * 1.65},${component.position.y * 1.65}
						^GB${par1.width},${fontSize * lines},${fontSize * lines}^FS`;
						zplPreview += highlighter;
						zplPrint += highlighter;
					}
					break;
				}
				case 'qrCode': {
					height = component.qrCode.height;
					width = component.qrCode.width;
					height =
						(Number(height.substring(0, height.length - 3)) *
							12.25) /
						(96 / 25.4);
					// const newHeight = mmToDots(height);
					width = Number(width.substring(0, width.length - 3)) * rem;
					par1 = {
						magnification: Math.round(height / 2.945),
					};
					const x = component.position.x * 1.6 + 3;
					const y = component.position.y * 1.4;
					const qrcode = `^FO${x},${y} ^BQN,2,${par1.magnification}^FDLA,{qrCode}^FS\n`;
					zplPreview += qrcode;
					zplPrint += qrcode;
					break;
				}
				case 'barCode': {
					// changeRequired
					height = component.barCode.height;
					width = component.barCode.width;

					height =
						(Number(height.substring(0, height.length - 3)) *
							12.5) /
						(96 / 25.4);
					width =
						(Number(width.substring(0, width.length - 3)) * 12.5) /
						(96 / 25.4);
					const barcode = `^BY${width / 16},2,${height * 8}^FO${
						component.position.x * 1.65
					},${component.position.y * 1.65}^BC,,N^FD{barCode}^FS\n`;
					zplPreview += barcode;
					zplPrint += barcode;
					break;
				}
				case 'image': {
					height = component.image.height;
					width = component.image.width;
					height =
						((Number(height.substring(0, height.length - 3)) *
							12.5) /
							(96 / 25.4)) *
						8;
					width =
						((Number(width.substring(0, width.length - 3)) * 12.5) /
							(96 / 25.4)) *
						8;
					// eslint-disable-next-line no-await-in-loop
					const resizedBase64Image = await resizeImage(
						component.image.imageURL,
						width,
						height
					);
					const base64Image = resizedBase64Image.split(',')[1];
					const blob = base64ToBlob(base64Image);
					const formData = new FormData();
					formData.append('file', blob);
					try {
						// eslint-disable-next-line no-await-in-loop
						const response = await fetch(
							'https://api.labelary.com/v1/graphics',
							{
								method: 'POST',
								body: formData,
							}
						);
						// eslint-disable-next-line no-await-in-loop
						const zplData = await response.text();
						const zplGraphics = zplData
							.split('^XA^FO0,0')[1]
							.slice(0, -3);
						const imgcode = `^FO${component.position.x * 1.64},${
							component.position.y * 1.64
						},${zplGraphics}\n`;
						zplPreview += imgcode;
					} catch (error) {
						notificationServices.generateNotification({
							type: 'error',
							message: 'Unknown image format...',
						});
						// console.error('Error:', error);
					}
					break;
				}
				case 'table': {
					fontSize = component?.table?.tableFontSize;
					fontSize = fontSize === undefined ? 10 : fontSize;
					const maxRowHeight = 2 * fontSize;
					const rowHeight = maxRowHeight;
					let rows = [];
					let currRow = [];
					tableContent[0].forEach((heading) => {
						currRow = [...currRow, heading.content];
					});
					rows = [...rows, currRow];
					tableContent.slice(1).forEach((row) => {
						currRow = [];
						row.forEach((cellContent) => {
							currRow =
								cellContent?.type === 'fixed'
									? [...currRow, cellContent.content]
									: [...currRow, `{${cellContent.content}}`];
						});
						rows = [...rows, currRow];
					});
					try {
						// eslint-disable-next-line no-await-in-loop
						const zplTable = await generateTable({
							rows,
							config: {
								columnRatios: component?.table?.columnRatio, // must add up to 1
								// eslint-disable-next-line max-len
								labelWidth:
									(Number(
										component?.table?.width.substring(
											0,
											component?.table?.width.length - 3
										)
									) *
										rem) /
									203, // inches
								labelHeight: 150 / 25.4, // inches
								labelPadding: 1, // dots
								borderWidth: 2, // dots
								fontSize, // dots
								maxRowHeight, // dots
								rowHeight, // dots
								cellPadding: 10, // dots
							},
						});
						const code = zplTable.split('^XA')[1].slice(0, -3);
						zplPrint += `^LH${
							140 * (75 / labelDetails.size.width) +
							component.position.x * labelDot
						},${component.position.y * labelDot + 20}${code}`;
						zplPreview += `^LH${component.position.x * labelDot},${
							component.position.y * labelDot
						}${code}`;
						zplPrint += code;
						zplPreview += code;
					} catch (error) {
						notificationServices.generateNotification({
							type: 'error',
							message: 'Error converting table',
						});
						// console.error('Error:', error);
					}
					break;
				}
				default:
					break;
			}
		}
		zplPreview += '^XZ'; // End of label format
		zplPrint += '^XZ'; // End of label format
		setZplCodePreview(zplPreview);
		setZplCodePrint(zplPrint);
		return {
			zplPrint,
			zplPreview,
		};
	};

	const [imageSrc, setImageSrc] = useState(null);
	const possiblePlaceholderArray =
		{
			MACHINE: [
				'Client Name',
				'Product Name',
				'Manufacturing Timestamp',
				'Manufacturing Date',
				'Expiry Date',
				'Reprint',
				'QC Number',
				'Container Weight',
				'QR Code',
				'Batch Number',
				'Weight',
				'Variant Name',
				'Selling Price',
				'Temperature',
				'Maximum Retail Price',
				'Unit Sale Price',
				'Carbs',
				'Fats',
				'Protein',
				'Calories',
				'Calcium',
				'Quantity'],
			APP: [
				'Client Name',
				'Product Name',
				'Expiry Date',
				'QR Code',
				'QC Number',
				'Quantity',
				'Process Name',
				'Inward Date',
			],
			ASSET: [
				'Client Name',
				'QR Code',
				'ID',
				'From Store',
				'PoID',
				'Asset Type',
				'Scheduled At',
			],
		};
	const placeholderArray = possiblePlaceholderArray?.[labelType];

	const formatZplCode = async (zplCode) => {
		let zpl = zplCode;
		const label = [labelComponentsState];
		const normalDatesLabel = ['Inward Date', 'Expiry Date', 'Manufacturing Date', 'Scheduled At'];
		label[0].forEach((prop) => {
			if (prop?.type === 'qrCode') {
				zpl = zpl.replace('{qrCode}', currentParameters?.qrCode);
			}
			if (prop?.type === 'text') {
				if (prop?.text?.placeHolderName === 'Manufacturing Timestamp') {
					zpl = zpl.replace(
						`{${prop?.text?.placeHolderName}}`,
						moment(
							currentParameters?.[prop?.text?.placeHolderName]
						)?.format('DD MMM YY hh:mm A')
					);
				} else if (normalDatesLabel?.includes(prop?.text?.placeHolderName) ) {
					zpl = zpl.replace(
						`{${prop?.text?.placeHolderName}}`,
						moment(
							currentParameters?.[prop?.text?.placeHolderName]
						)?.format('DD MMM YY')
					);
				} else if (prop?.text?.placeHolderName === 'QR Code') {
					let qr = '';
					if (typeof currentParameters.qrCode === 'number') {
						qr = currentParameters.qrCode.toString();
					}
					qr = currentParameters.qrCode;
					const formattedStr = qr.replace(/(\d{4})(?=\d)/g, '$1 ');
					zpl = zpl.replace(
						`{${prop?.text?.placeHolderName}}`,
						formattedStr
					);
				} else {
					zpl = zpl.replace(
						`{${prop?.text?.placeHolderName}}`,
						currentParameters?.[prop?.text?.placeHolderName]
					);
				}
			}
		});
		return zpl;
	};

	const printLabel = async () => {
		const data = await formatZplCode(zplCodePrint);
		const printIp = printerIP;
		// const url = `http://${printerIP}:9100`; // Adjust the IP address and port as needed
		const html = `<html>
					<head></head>
					<body>
					<div>Wait Prinitng in Progress............. </div>
					<script>
					fetch('http://${printIp}:9100',
						{
							method: 'POST',
							headers: {
								'Content-Type': 'text/plain',
								'Content-Length': '${data.length}',
							},
							body: \`${data}\`,
						}
					);
					</script>
					</body>
					</html>`;
		const link = `data:text/html;charset=utf8;base64,${btoa(html)}`;
		const newTab = window.open();
		// Ensure the new tab was successfully opened
		if (newTab) {
			newTab.document.write(
				`<iframe src="${link}" frameborder="0" style="border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;" allowfullscreen></iframe>`
			);
			setTimeout(() => {
				newTab.close();
			}, 100);
		}
	};

	const previewLabelZPL = async () => {
		// setPreviewLabelClicked(true);
		setIsLoading(true);
		try {
			const parameter = await formatZplCode(zplCodePreview);
			const res = await fetch(
				`https://api.labelary.com/v1/printers/8dpmm/labels/${
					labelDetails.size.width / 25.4
				}x${labelDetails.size.height / 25.4}/0/>label.png?${parameter}`,
				{
					method: 'GET',
				}
			);
			if (res.ok) {
				const blob = await res.blob();
				setImageSrc(URL.createObjectURL(blob));
			} else {
				setImageSrc(null);
				if (zplCodePreview === '^XA\n^XZ') {
					notificationServices.generateNotification({
						type: 'error',
						message: 'No Label generated',
					});
				} else {
					notificationServices.generateNotification({
						type: 'error',
						message:
							'Preview label is under maintenance, please try again.',
					});
				}
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message:
					'Preview label is under maintenance, please try again.',
			});
		} finally {
			setIsLoading(false);
		}
	};

	const refreshParameters = async () => {
		setIsLoading(true);
		try {
			await previewLabelZPL();
		} finally {
			setIsLoading(false);
		}
	};

	const previewLabel = async () => {
		setIsLoading(true);
		try {
			setRightScreen(false);
			setPreviewLabelClicked(true);
			// eslint-disable-next-line no-unused-vars
			const { zplPrint, zplPreview } = await convertLabelToZPL(
				[labelComponentsState],
				'preview'
			);
			document.getElementById('refresh-label').click();
		} finally {
			setIsLoading(false);
		}
	};

	const handleDrag = (id) => {
		let newComponent = '';
		setPreviewLabelClicked(false);
		setSelectedComponent(null);
		setEditToolInUse(null);
		setSavedLabelsClicked(false);
		setRightScreen(true);
		setStaticTextBackButtonClicked(false);
		// setPlaceholder("Product Name");
		if (id !== 'Static Text') {
			setStaticTextEntered(false);
		}
		if (id === 'Text') {
			newComponent = {
				type: id,
				[id]: {
					placeholder: placeholderArray[0],
					placeholderText: '',
					textFontSize: 16,
					textBoxDim: {
						width: '8rem',
						height: '2rem',
					},
					inputLines: 1,
				},
			};
		}
		if (id === 'Insert') {
			const fileInput = ref?.current;
			fileInput.onChange = (event) => {
				handleImageUpload(event);
			};
			fileInput.click();
		}
		if (id === 'Static Text') {
			newComponent = {
				type: id,
				[id]: {
					staticText: 'Static Text',
					staticTextFontSize: 16,
					staticTextBoxDim: {
						width: '8rem',
						height: '2rem',
					},
				},
			};
		}
		if (id === 'qrCode') {
			newComponent = {
				type: id,
				[id]: {
					qrCodeDim: {
						width: '2rem',
						height: '2rem',
					},
				},
			};
		}
		if (id === 'barCode') {
			// changeRequired
			newComponent = {
				type: id,
				[id]: {
					barCodeDim: {
						width: '5rem',
						height: '2rem',
					},
				},
			};
		}
		if (id === 'table') {
			newComponent = {
				type: id,
				[id]: {
					rows: 2,
					columns: 2,
					tableContent,
				},
			};
		}
		if (newComponent) {
			setSelectedToolComponent(newComponent);
		}
		setToolInUse(id);
		setBackButtonClicked(false);
		setSaveButtonClicked(false);
		setMyComponentsClicked(false);
	};

	const handleMyComponentsClickRenderScreen = () => {
		setPreviewLabelClicked(false);
		setSelectedComponent(null);
		setEditToolInUse(null);
		setSavedLabelsClicked(false);
		setRightScreen(false);
		setBackButtonClicked(false);
		setSaveButtonClicked(false);
		setMyComponentsClicked(true);
		setStaticTextBackButtonClicked(true);
		setPlaceholderTextEntered(false);
	};

	const handleBackButtonApp = () => {
		setPreviewLabelClicked(false);
		setSelectedComponent(null);
		setMyComponentsClicked(false);
		setStaticTextEntered(false);
		setSavedLabelsClicked(false);
		setStaticTextBackButtonClicked(true);
		setRightScreen(true);
		setToolInUse('mainScreen');
		setBackButtonClicked(true);
		setSaveButtonClicked(false);
		setPlaceholderTextEntered(false);
	};

	const handleBackButtonSavedLabelsScreen = () => {
		setPreviewLabelClicked(false);
		setSelectedComponent(null);
		setMyComponentsClicked(false);
		setStaticTextEntered(false);
		setSavedLabelsClicked(false);
		setStaticTextBackButtonClicked(true);
		setRightScreen(true);
		setToolInUse('mainScreen');
		setBackButtonClicked(true);
		setSaveButtonClicked(false);
		setPlaceholderTextEntered(false);
		setLabelDetails({
			key: Date.now(),
			id: Date.now(),
			size: {
				width: 75,
				height: 25,
			},
			name: '',
			printCount: 1,
		});
		setLabelComponentsState([]);
	};

	const handleSaveButtonApp = () => {
		setPreviewLabelClicked(false);
		setSelectedComponent(null);
		setEditToolInUse(null);
		setStaticTextBackButtonClicked(true);
		setSaveButtonClicked(true);
		setMyComponentsClicked(false);
		setStaticTextEntered(false);
		setStaticTextBackButtonClicked(true);
		setRightScreen(true);
		setToolInUse('mainScreen');
		setBackButtonClicked(false);
		setSaveButtonClicked(true);
		setPlaceholderTextEntered(false);
		setSavedLabelsClicked(false);
	};
	const staticTextEnter = (text) => {
		// eslint-disable-next-line no-restricted-globals
		if (!isNaN(text)) {
			setStaticTextEntered(false);
			notificationServices.generateNotification({
				type: 'error',
				message: 'Text cannot be empty!',
			});
		} else {
			setSelectedToolComponent({
				...selectedToolComponent,
				[selectedToolComponent?.type]: {
					...selectedToolComponent[selectedToolComponent?.type],
					staticText: text,
				},
			});
			setStaticTextEntered(true);
		}
		setSavedLabelsClicked(false);
	};
	const staticTextToolRenderRightScreenApp = (text) => {
		setMyComponentsClicked(false);
		setStaticTextBackButtonClicked(false);
		// eslint-disable-next-line no-restricted-globals
		if (!isNaN(text)) {
			setStaticTextEntered(false);
			notificationServices.generateNotification({
				type: 'error',
				message: 'Text cannot be empty!',
			});
		} else {
			setSelectedToolComponent({
				...selectedToolComponent,
				[selectedToolComponent?.type]: {
					...selectedToolComponent[selectedToolComponent?.type],
					staticText: text,
				},
			});
			setStaticTextEntered(true);
		}
		setSavedLabelsClicked(false);
	};

	const handlePlaceHolderChange = (name) => {
		setSelectedToolComponent({
			...selectedToolComponent,
			[selectedToolComponent?.type]: {
				...selectedToolComponent[selectedToolComponent?.type],
				placeholder: name,
				placeholderText: '',
			},
		});

		setBackButtonClicked(false);
		// setSaveButtonClicked(false);
	};

	const handleTextBoxSize = (Dim) => {
		setMyComponentsClicked(false);
		setSelectedToolComponent({
			...selectedToolComponent,
			[selectedToolComponent?.type]: {
				...selectedToolComponent[selectedToolComponent?.type],
				textBoxDim: Dim,
			},
		});
	};

	const handleStaticTextBoxSize = (Dim) => {
		setMyComponentsClicked(false);
		setSelectedToolComponent({
			...selectedToolComponent,
			[selectedToolComponent?.type]: {
				...selectedToolComponent[selectedToolComponent?.type],
				staticTextBoxDim: Dim,
			},
		});
	};

	const handleQRCodeSize = (Dim) => {
		setMyComponentsClicked(false);
		setSelectedToolComponent({
			...selectedToolComponent,
			[selectedToolComponent?.type]: {
				...selectedToolComponent[selectedToolComponent?.type],
				qrCodeDim: Dim,
			},
		});
	};

	const handleBarcodeSize = (Dim) => {
		setMyComponentsClicked(false);
		setSelectedToolComponent({
			...selectedToolComponent,
			[selectedToolComponent?.type]: {
				...selectedToolComponent[selectedToolComponent?.type],
				barCodeDim: Dim,
			},
		});
		// console.log('BarCode', Dim);
	};
	const handleImageSize = (Dim) => {
		// console.log('Size', Dim);
		setMyComponentsClicked(false);
		setSelectedToolComponent({
			...selectedToolComponent,
			[selectedToolComponent?.type]: {
				...selectedToolComponent[selectedToolComponent?.type],
				imageDim: Dim,
			},
		});
	};

	const handleTableDim = (Dim) => {
		setMyComponentsClicked(false);
		setSelectedToolComponent({
			...selectedToolComponent,
			[selectedToolComponent?.type]: {
				...selectedToolComponent[selectedToolComponent?.type],
				width: Dim?.width,
			},
		});
	};
	const handleSavedLabelsAPI = async () => {
		setIsLoading(true);
		try {
			const response = await labelGeneratorServices.getLabels();
			if (response?.statusCode === 200) {
				const result = response?.savedLabels;
				setSavedLabels(result?.filter((ele) => {
					return ele?.customerId;
				})?.map((ele) => {
					return {
						key: ele?.id,
						id: ele?.id,
						size: ele?.label?.size,
						name: ele?.labelName,
						label: ele?.label?.components,
						type: ele?.labelType,
						printCount: ele?.printCount,
					};
				}));
			}
			history.push(`${urls.EXTENSION}${urls.LABEL_GENERATOR_LIST}`);
			notificationServices.generateNotification({
				type: 'Success',
				message: 'Label Saved',
			});
		} catch (error) {
			// eslint-disable-next-line no-console
			notificationServices.generateNotification({
				type: 'error',
				message: 'Unable to save label, please connect with UY Team',
			});
		} finally {
			setIsLoading(false);
		}
	};

	const handleCreateLabelAPI = async (label) => {
		setIsLoading(true);
		try {
			const response = await labelGeneratorServices.createLabel(label);
			if (response?.statusCode === 200) {
				handleSavedLabelsAPI();
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			notificationServices.generateNotification({
				type: 'error',
				message: 'Error while creating label',
			});
		} finally {
			setIsLoading(false);
		}
	};

	const saveLabel = async () => {
		const { zplPrint } = await convertLabelToZPL(
			[labelComponentsState],
			'preview'
		);
		if (labelDetails?.name === '') {
			notificationServices?.generateNotification({
				type: 'error',
				message: 'Enter a Label Name:',
			});
		} else {
			const newLabel = {
				size: labelDetails?.size,
				name: labelDetails?.name,
				id: labelDetails?.id,
				label: labelComponentsState,
				zplCode: zplPrint,
				type: labelType,
				printCount: Number(labelDetails?.printCount ?? 1),
			};
			handleCreateLabelAPI(newLabel);
			// setSavedLabelsClicked(true);
			setSelectedComponent(null);
			// document.getElementById('saved-labels')?.click();
		}
	};

	const handleSavedLabelsClick = () => {
		handleSavedLabelsAPI();
		setSelectedComponent(null);
		setStaticTextBackButtonClicked(true);
		setSavedLabelsClicked(true);
		setToolInUse('mainScreen');
		setEditToolInUse(null);
		setRightScreen(false);
		setPlaceholderTextEntered(false);
		setPreviewLabelClicked(false);
	};
	let newComponent;
	const handleSelectedComponent = (currElement) => {
		if (currElement?.type === 'text') {
			setSelectedToolComponent({
				type: 'Text',
				Text: {
					placeholder: currElement?.text?.placeHolderName,
					placeholderText: currElement?.text?.placeholderText,
					textFontSize: currElement?.text?.fontSize,
					textBoxDim: {
						height: currElement.text.height,
						width: currElement.text.width,
					},
					inputLines: currElement?.text?.inputLines,
					highlight: currElement?.text?.highlight,
				},
			});
			// alert("Correct App")
		} else if (currElement?.type === 'staticText') {
			setSelectedToolComponent({
				type: 'Static Text',
				'Static Text': {
					staticText: currElement.staticText.text,
					staticTextFontSize: currElement?.staticText?.fontSize,
					staticTextBoxDim: {
						height: currElement.staticText.height,
						width: currElement.staticText.width,
					},
					highlight: currElement?.staticText?.highlight,
				},
			});
			if (currElement.staticText.text.length > 0) {
				setStaticTextEntered(true);
			} else {
				setStaticTextEntered(false);
			}
		} else if (currElement?.type === 'qrCode') {
			newComponent = {
				type: 'qrCode',
				qrCode: {
					qrCodeDim: {
						height: currElement.qrCode.height,
						width: currElement.qrCode.width,
					},
				},
			};
			setSelectedToolComponent(newComponent);
		} else if (currElement?.type === 'barCode') {
			newComponent = {
				type: 'barCode',
				barCode: {
					barCodeDim: {
						height: currElement.barCode.height,
						width: currElement.barCode.width,
					},
				},
			};
			setSelectedToolComponent(newComponent);
		} else if (currElement?.type === 'image') {
			newComponent = {
				type: 'image',
				image: {
					imageURL: currElement.image.imageURL,
					imageDim: {
						height: currElement.image.height,
						width: currElement.image.width,
					},
				},
			};
			setSelectedToolComponent(newComponent);
		} else if (currElement?.type === 'table') {
			newComponent = {
				type: 'table',
				table: {
					rows: currElement.table.tableRow,
					columns: currElement.table.tableColumn,
					tableContent: currElement.table.tableContent,
				},
			};
			setSelectedToolComponent(newComponent);
		}
		setPreviewLabelClicked(false);
	};

	const editComponent = (id) => {
		// alert("Hello")

		setToolInUse('mainScreen');
		let newId;
		if (id === 'text') {
			newId = 'Text';
			setStaticTextBackButtonClicked(true);
		} else if (id === 'qrCode') {
			newId = 'qrCode';
			setStaticTextBackButtonClicked(true);
		} else if (id === 'barCode') {
			newId = 'barCode';
			setStaticTextBackButtonClicked(true);
		} else if (id === 'staticText') {
			newId = 'Static Text';
			setStaticTextBackButtonClicked(false);
		} else if (id === 'image') {
			setStaticTextBackButtonClicked(true);
			newId = 'Insert';
		} else if (id === 'table') {
			setStaticTextBackButtonClicked(true);
			newId = 'table';
		}
		setEditToolInUse(newId);
	};

	const handleTextSize = (fontSize) => {
		setMyComponentsClicked(false);
		setSelectedToolComponent({
			...selectedToolComponent,
			[selectedToolComponent?.type]: {
				...selectedToolComponent[selectedToolComponent?.type],
				textFontSize: fontSize,
			},
		});
	};

	const handleHighlight = (status) => {
		setMyComponentsClicked(false);
		setSelectedToolComponent({
			...selectedToolComponent,
			[selectedToolComponent?.type]: {
				...selectedToolComponent[selectedToolComponent?.type],
				highlight: status,
			},
		});
	};

	const handleTableFontSize = (fontSize) => {
		setMyComponentsClicked(false);
		setSelectedToolComponent({
			...selectedToolComponent,
			[selectedToolComponent?.type]: {
				...selectedToolComponent[selectedToolComponent?.type],
				tableFontSize: fontSize,
			},
		});
	};

	const handleTableColumnRatio = (ratio) => {
		setMyComponentsClicked(false);
		setSelectedToolComponent({
			...selectedToolComponent,
			[selectedToolComponent?.type]: {
				...selectedToolComponent[selectedToolComponent?.type],
				columnRatio: ratio,
			},
		});
	};
	const handleStaticTextSize = (fontSize) => {
		setMyComponentsClicked(false);
		setSelectedToolComponent({
			...selectedToolComponent,
			[selectedToolComponent?.type]: {
				...selectedToolComponent[selectedToolComponent?.type],
				staticTextFontSize: fontSize,
			},
		});
	};

	const handlePlaceHolderTextChange = (name) => {
		setMyComponentsClicked(false);
		setSelectedToolComponent({
			...selectedToolComponent,
			[selectedToolComponent?.type]: {
				...selectedToolComponent[selectedToolComponent?.type],
				placeholderText: name,
			},
		});
		// alert(name);
	};

	const handleInputLines = (lines) => {
		setMyComponentsClicked(false);
		setSelectedToolComponent({
			...selectedToolComponent,
			[selectedToolComponent?.type]: {
				...selectedToolComponent[selectedToolComponent?.type],
				inputLines: lines,
			},
		});
	};

	const handleLabelType = (type) => {
		return labelGeneratorConfig?.namingOfMachineType?.[type];
	};

	return (
		<div className='label_generator_container'>
			<input
				ref={ref}
				type='file'
				accept='image/*'
				onChange={handleImageUpload}
				style={{
					display: 'none',
				}}
			/>

			<div
				className='label-generator-header'
				style={{
					paddingRight: '40px',
					paddingLeft: '10px',
				}}
			>
				<div className='home_btn'>
					<ArrowBackIcon
						style={{
							cursor: 'pointer',
						}}
						onClick={() => {
							history.push(
								`${urls.EXTENSION}${urls.LABEL_GENERATOR_LIST}`
							);
						}}
					/>
				</div>
				<div id='website-name'>Label Generator</div>
				{!savedLabelsClicked && (
					<>
						<Tooltip title={labelDetails?.name}>
							<div>
								Label Name:{' '}
								{labelDetails?.name?.length > 20
									? `${labelDetails?.name.substring(
											0,
											20
									  )}...`
									: labelDetails?.name}
							</div>
						</Tooltip>
						<div>
							label Size: {labelDetails?.size?.width}x
							{labelDetails?.size?.height}
						</div>
						<div>
							Label Type:{' '}
							{labelDetails?.type
								? handleLabelType(labelDetails?.type)
								: handleLabelType(labelType)}
						</div>
					</>
				)}
				{/* <button
					className='label-generator-button'
					type='button'
					id='saved-labels'
					onClick={handleSavedLabelsClick}
				>
					Saved Labels
				</button> */}
			</div>
			<BackdropLoader showProgress isLoading={isLoading} />
			<div className='boxes'>
				<div className='left-box'>
					<LeftBox
						tool={toolInUse}
						toolInUseRenderScreen={handleDrag}
						handleMyComponentsClickRenderScreen={
							handleMyComponentsClickRenderScreen
						}
						staticTextToolRenderRightScreenApp={
							staticTextToolRenderRightScreenApp
						}
						staticTextBackButtonClicked={
							staticTextBackButtonClicked
						}
						backButtonClicked={backButtonClicked}
						saveButtonClicked={saveButtonClicked}
						myComponentsClicked={myComponentsClicked}
						labelComponentsState={labelComponentsState}
						setLabelComponentsState={setLabelComponentsState}
						currentLabelComponents={currentLabelComponents}
						setCurrentLabelComponents={setCurrentLabelComponents}
						// imageURL={imageURL}
						savedLabelsClicked={savedLabelsClicked}
						savedLabels={savedLabels}
						setSavedLabels={setSavedLabels}
						setBackButtonClicked={setBackButtonClicked}
						labelDetails={labelDetails}
						setLabelDetails={setLabelDetails}
						printLabel={printLabel}
						previewLabel={previewLabel}
						previewLabelClicked={previewLabelClicked}
						editComponentTool={editComponent}
						setSaveButtonClicked={setSaveButtonClicked}
						handleSelectedComponent={handleSelectedComponent}
						selectedComponent={selectedComponent}
						setSelectedComponent={setSelectedComponent}
						currentComponent={currentComponent}
						setCurrentComponent={setCurrentComponent}
						editTool={editToolInUse}
						handleBackButtonApp={handleBackButtonApp}
						imageSrc={imageSrc}
						selectedToolComponent={selectedToolComponent}
						setIsLoading={setIsLoading}
						handleSaveButtonApp={handleSaveButtonApp}
						setLabelType={setLabelType}
					/>
				</div>
				<div className='right-box'>
					<RightBox
						tool={toolInUse}
						editTool={editToolInUse}
						setEditToolInUse={setEditToolInUse}
						rightScreenContent={rightScreen}
						handleBackButtonApp={handleBackButtonApp}
						handleSaveButtonApp={handleSaveButtonApp}
						staticTextEntered={staticTextEntered}
						handlePlaceHolderChange={handlePlaceHolderChange}
						handleText={handleTextBoxSize}
						handleTextSize={handleTextSize}
						handleHighlight={handleHighlight}
						// textFontSize={textFontSize}
						handleStaticTextSize={handleStaticTextSize}
						// staticTextFontSize={staticTextFontSize}
						handleStaticText={handleStaticTextBoxSize}
						handleQRCode={handleQRCodeSize}
						handleBarcode={handleBarcodeSize}
						handleImage={handleImageSize}
						saveLabel={saveLabel}
						labelDetails={labelDetails}
						setLabelDetails={setLabelDetails}
						placeholderTextEntered={placeholderTextEntered}
						printLabel={printLabel}
						previewLabel={previewLabel}
						refreshParameters={refreshParameters}
						previewLabelClicked={previewLabelClicked}
						selectedComponent={selectedComponent}
						setSelectedComponent={setSelectedComponent}
						labelComponentsState={labelComponentsState}
						setLabelComponentsState={setLabelComponentsState}
						handlePlaceHolderTextChange={
							handlePlaceHolderTextChange
						}
						placeholderArray={placeholderArray}
						// inputLines={inputLines}
						handleInputLines={handleInputLines}
						savedLabelsClicked={savedLabelsClicked}
						savedLabels={savedLabels}
						handleBackButtonSavedLabelsScreen={
							handleBackButtonSavedLabelsScreen
						}
						currentParameters={currentParameters}
						setCurrentParameters={setCurrentParameters}
						printerIP={printerIP}
						setPrinterIP={setPrinterIP}
						selectedToolComponent={selectedToolComponent}
						staticTextToolRenderRightScreenApp={
							staticTextToolRenderRightScreenApp
						}
						staticTextEnter={staticTextEnter}
						currentLabelComponents={currentLabelComponents}
						handleTableDim={handleTableDim}
						handleTableFontSize={handleTableFontSize}
						handleTableColumnRatio={handleTableColumnRatio}
						tableContent={tableContent}
						setTableContent={setTableContent}
						labelType={labelType}
						setLabelType={setLabelType}
					/>
				</div>
			</div>
		</div>
	);
};

export default LabelGenerator;
