/* eslint-disable no-tabs */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-one-expression-per-line */
import {
	Suspense, useEffect, useState
} from 'react';
import { connect } from 'react-redux';
import ImagesFinder from '@/Components/ImagesFinder/ImagesFinder';
import { newTabs } from './TabsData';
import { notificationServices } from '@/Services';
import aiPackaging from '@/Services/aiPackaging.services';
import { useAuth } from '@/Components/Auth/Login/auth';
import { handleResponseStreamDownload } from '@/Helpers';
import { BackdropLoader } from '@/Components/Loaders';
import drawerActions from '@/Actions/drawer.actions';
import ExportDrawer from './ExportDrawer';

const filters = [
	'fromPodFilter',
	'toPodFilter',
	'dateOnly',
];
const meals = [
	{
		id: 1,
		value: 'breakfast',
		header: 'BreakFast',
	},
	{
		id: 2,
		value: 'lunch',
		header: 'Lunch',
	},
	{
		id: 3,
		value: 'snacks',
		header: 'Snacks',
	},
	{
		id: 4,
		value: 'dinner',
		header: 'Dinner',
	},
];
function AiPackaging(props) {
	const { toggleExportDrawer } = props;
	const { appConfig = {} } = useAuth();
	const { combineCMSBatches } = appConfig?.configs ?? {};
	const [tabsData, setTabsData] = useState([]);
	const [active, setActive] = useState(null);
	const [data, setData] = useState([]);
	const [filterData, setFilterData] = useState({});
	const [loading, setIsLoading] = useState(false);
	const handleTabs = (tab) => {
		setActive(tab);
	};

	const fetchData = async (filter) => {
		setFilterData(filter);
		const date = filter?.dateOnly?.date;
		const formattedDate = date.toISOString().split('T')[0];
		const req = {
			data: {
				filters: {
					dateRangeFilter: {
						from: formattedDate,
						to: formattedDate,
					},
					fromStore: filter?.fromPodFilter,
					storeId: filter?.toPodFilter,
				},
			},
		};
		try {
			if (filter?.fromPodFilter === '' || filter?.fromPodFilter === null) {
				notificationServices?.generateNotification({
					type: 'error',
					message: 'Kitchen is Manadatory',
				});
				return;
			}
			const response = await aiPackaging?.getData(req);
			if (response?.statusCode === 200) {
				setData(response?.data);
			} else {
				notificationServices?.generateNotification({
					type: 'error',
					message: response?.message,
				});
			}
		} catch (error) {
			notificationServices?.generateNotification({
				type: 'error',
				message: error,
			});
		}
	};

	const handleExport = async () => {
		toggleExportDrawer(true);
	};

	useEffect(() => {
		if (tabsData.length > 0) {
			const tabsArray = newTabs(tabsData);
			setActive(tabsArray[0]);
		}
	}, [tabsData]);
	useEffect(() => {
		if (!data) return;
		const mealType = data?.map((ele, index) => {
			return ele?.mealType;
		});
		const tabData = meals.filter((ele) => mealType.includes(ele?.value));
		setTabsData(tabData);
	}, [data]);

	return (
		<>
			<BackdropLoader isLoading={setIsLoading} />
			<ExportDrawer />
			<ImagesFinder
				FILTERS={filters}
				onSubmit={fetchData}
				dateOnlyText='Select Date*'
				disableFutureDate
			/>
			{data?.length > 0 ? (
				<>
					<div className='inline-flex flex-wrap justify-center gap-2 m-[12px] rounded-md bg-[#FDE6AD] border border-[#00000033]'>
						{newTabs(tabsData)?.map((ele) => {
							const isActive = active?.id === ele?.id;
							return (
								<div
									className={`text-center px-4 py-2 text-base font-medium cursor-pointer min-w-max
										${isActive ? 'bg-[#ffb703] h-[33px] m-[2px]' : ''}`}
									onClick={() => handleTabs(ele)}
								>
									{ele?.header}
								</div>
							);
						})}
					</div>
					<Suspense fallback={<div>Loading ...</div>}>
						{active?.comp?.(
							{
								value: active.value,
								handleExport,
								data,
								filters: filterData,
							}
						)}
					</Suspense>
				</>
			) : (
				<div className='w-full text-center p-1  m-2 font-bold'>
					No Recipe Data Found
				</div>
			)}
		</>
	);
}
const mapStateToProps = (state) => {
	return {};
};
const mapDispatchToProps = {
	toggleExportDrawer: drawerActions?.toggleExportDrawer,
};
export default connect(mapStateToProps, mapDispatchToProps)(AiPackaging);
