/* eslint-disable*/
import { ArrowBack } from "@mui/icons-material";
import { urls } from "@/Constants";
import { PageNavigation } from "@/Components/Headers/PageNavigation";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function GrnHeader(props) {
    const { navigateQC, idx, listData, id, storeId, vendor, dateRangeFilter } = props;
    const history = useHistory();
    
    const handleBack = () => {
        history.push(`${urls.EXTENSION}${urls.GRN_LIST}?tabValue=grnLogs&storeId=${storeId}&vendor=${vendor}&dateRangeFilter=${dateRangeFilter}`);
    }

    return (
        <div className="flex justify-between items-center px-6 py-3 border-b border-gray-200 fixed left-0 right-0 top-16 bg-white z-[999] shadow-sm">
            <div className="flex items-center gap-2 relative left-[16rem]">
                <ArrowBack onClick={handleBack} className="cursor-pointer" />
                <p className="text-base font-medium">Back to GRN Logs</p>
            </div>
            <div>
                <PageNavigation
                    navigate={navigateQC}
                    prev={{
                        tooltip: 'Previous GRN',
                        show: idx > 0,
                        disabled: idx === 0,
                    }}
                    current={{
                        label: 'GRN Id ',
                        value: id,
                    }}
                    next={{
                        tooltip: 'Next GRN',
                        show: idx >= 0 && idx < listData.length - 1,
                        disabled: idx < 0 || idx === listData.length - 1,
                    }}
                />
            </div>
        </div>
    );
}

export default GrnHeader;

