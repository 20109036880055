// eslint-disable-next-line object-curly-newline
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { ErrorBoundary } from 'react-error-boundary';
import { Appbar } from '../Components/Appbar';
import Sidebar from '../Components/Sidebar/Sidebar';
import BrandRoutes from './Brand Routes/brand.routes';
import CookingRoutes from './Cooking Routes/cooking.routes';
import InventoryRoutes from './Inventory Routes/inventory.routes';
import MenuRoutes from './Menu Routes/menu.routes';
import OutletRoutes from './My Outlet Routes/outlet.routes';
import MyAccountRoutes from './MyAccount Routes/myaccount.routes';
import AIWorkflowRoutes from './AIWorkflow Routes/aiworkflow.routes';
import ProductRoutes from './Product Routes/product.routes';
import RefundRoutes from './Refunds Routes/refunds.routes';
import StoreRoutes from './My Outlet Routes/Store Routes/store.routes';
import AnalyticsRoutes from './Analytics Routes/analytics.routes';
import QualityRoutes from './Quality Routes/quality.routes';
import DefectRoutes from './Defect Routes/defect.routes';
// eslint-disable-next-line no-unused-vars
import { Dashboard } from '../Pages';
import VendorRoutes from './Vendor Routes/vendor.routes';
import UploadPurchaseOrder from '../Pages/Analytics/Inventory Analytics/UploadPurchaseOrder/UploadPurchaseOrder';
import AssetRoute from './AssetRoute/AssetRoute';
import InventoryDashboardRoute from './Inventory Dashboard/InventoryDashboardRoute';
import AI_DASHBOARD from './AI Dashboard/AIDashboardRoute';
import { TEMPAnnotationPage } from '../Pages/Annotation/TEMPAnnotationPage';
import { MySubscriptionsRoutes } from './MySubscriptionsRoutes';
import ErrorFallback from '../Components/Fallbacks/ErrorFallback/ErrorFallback';
import { ProcessFlowPage } from '../Pages/ProcessFlowPage';
import { TemperatureImagesPage } from '../Pages/Analytics/Temperature Analysis/TemperatureImagesPage';
import ProductProcessMap from '../Pages/ProductProcessMap/ProductProcessMap';
import VendorProductMap from '../Pages/VendorProductMap/VendorProductMap';
import ConfigurationPage from '../Pages/ConfigurationPage/ConfigurationPage';
import NewDashboard from '../Pages/NewDashboard/NewDashboard';
// import Homepage from '../Pages/Homepage/Homepage';
import ReportsRoutes from './ReportsRoutes/reports.routes';
import OrderTracking from '../Pages/OrderTracking/OrderTracking';
import SimilarProducts from '../Pages/SimilarProducts/SimilarProducts';
import PettyCashRoutes from './PettyCashRoutes/PettyCashRoutes';
import UtilitiesPageRoutes from './UtilitiesPageRoutes/UtilitiesPageRoutes';
import CreateClosingPage from '../Pages/ProcessClosing/CreateClosingPage/CreateClosingPage';
import ProcessClosingsPage from '../Pages/ProcessClosing/ProcessClosings';
import AllCapexRoutes from './Capex Routes/Capex Form Routes/capex.routes';
// eslint-disable-next-line no-unused-vars
import RegionsMapping from '../Pages/RegionsMapping/RegionsMapping';
import CreateVendorContractPage from '../Pages/VendorContract/CreateContract/CreateVendorContractPage';
import ListVendorContractPage from '../Pages/VendorContract/ListVendorContractPage';
import NotAccessiblePage from '../Pages/NotAccessable/NotAccessable';
import VendorOpeningRoutes from './VendorOpeningRoutes/VendorOpeningRoutes';
import ShiftCreate from '../Pages/ShiftManagement/ShiftManagmentCreate/ShiftCreate';
import ListShiftManagment from '../Pages/ShiftManagement/ListShiftManagment';
import LabelGeneratorRoutes from './LabelGenerator/labelGeneratorRoutes';
import DayEndReport from '@/Pages/Reports/DayEndReport/DayEndReport';
import ListForecasting from '../Pages/Forecasting/ListForecasting';
import { urls } from '@/Constants';
import LeftOverInventoryHistory from '@/Pages/ProcessClosing/History/leftOverInventoryHistory';
import ProcessConfig from '@/Pages/ProcessConfig/processConfig';
import GstRoutes from './GSTRoute/GstRoute';
import WastageInventory from '@/Pages/Wastage/WastageInventory';
import AiPackaging from '@/Pages/AIPACKING/AiPackaging';
import QualityLogs from '@/Pages/QualityLogs/qualityLogs';
import QualityDetails from '@/Pages/QualityLogs/qualityDetails';
import GRNDetailsPage from '@/Pages/GRNPage/grnDeatails';
import GRNsPage from '@/Pages/GRNPage/GRNsPage';

const AppRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<>
			<Sidebar />
			<div className='App-content'>
				<Appbar />
				<ErrorBoundary FallbackComponent={ErrorFallback}>
					<Switch>
						{/* <Route path={`${path}${urls.HOMEPAGE}`}>
							<Homepage />
						</Route> */}
						<Route path={`${path}${urls.NEW_DASHBOARD}`}>
							<NewDashboard />
						</Route>
						<Route exact path={`${path}${urls.DASHBOARD}`}>
							<Dashboard />
						</Route>
						<Route path={`${path}${urls.ASSET_DASHBOARD}`}>
							<InventoryDashboardRoute />
						</Route>
						<Route path={`${path}${urls.PROCESS_FLOW}`}>
							<ProcessFlowPage />
						</Route>
						<Route path={`${path}${urls.PRODUCT_PROCESS_MAP}`}>
							<ProductProcessMap />
						</Route>
						<Route path={`${path}${urls.AI_DASHBOARD}`}>
							{/* <AIWorkflowRoutes /> */}
							<AI_DASHBOARD />
						</Route>
						<Route path={`${path}${urls.PROCESS_CONFIG}`}>
							<ProcessConfig />
						</Route>
						<Route path={`${path}${urls.GST}`}>
							<GstRoutes />
						</Route>
						<Route path={`${path}${urls.UPLOAD_PO}`}>
							<UploadPurchaseOrder />
						</Route>
						<Route path={`${path}${urls.TEMPERATURE_IMAGES}`}>
							<TemperatureImagesPage />
						</Route>
						<Route path={`${path}${urls.PETTY_CASH}`}>
							<PettyCashRoutes />
						</Route>
						<Route path={`${path}${urls.UTILITIES_PAGE}`}>
							<UtilitiesPageRoutes />
						</Route>
						<Route path={`${path}${urls.MY_ACCOUNT}`}>
							<MyAccountRoutes />
						</Route>
						<Route path={`${path}${urls.MY_SUBSCRIPTIONS}`}>
							<MySubscriptionsRoutes />
						</Route>
						<Route path={`${path}${urls.AI_WORKFLOW}`}>
							<AIWorkflowRoutes />
						</Route>
						<Route path={`${path}${urls.ASSET}`}>
							<AssetRoute />
						</Route>
						<Route path={`${path}${urls.REFUNDS}`}>
							<RefundRoutes />
						</Route>
						<Route path={`${path}${urls.MY_OUTLETS}`}>
							<OutletRoutes />
						</Route>
						<Route path={`${path}${urls.FOOD_PRODUCTS}`}>
							<ProductRoutes />
						</Route>
						<Route path={`${path}${urls.BRANDS}`}>
							<BrandRoutes />
						</Route>
						<Route path={`${path}${urls.MENUS}`}>
							<MenuRoutes />
						</Route>
						<Route path={`${path}${urls.STORES}`}>
							<StoreRoutes />
						</Route>
						<Route path={`${path}${urls.ANALYTICS}`}>
							<AnalyticsRoutes />
						</Route>
						<Route path={`${path}${urls.INVENTORY}`}>
							<InventoryRoutes />
						</Route>
						<Route path={`${path}${urls.COOKING}`}>
							<CookingRoutes />
						</Route>
						<Route path={`${path}${urls.QUALITY}`}>
							<QualityRoutes />
						</Route>
						<Route path={`${path}${urls.DEFECTS}`}>
							<DefectRoutes />
						</Route>
						<Route path={`${path}${urls.VENDORS}`}>
							<VendorRoutes type='VENDOR' />
						</Route>
						<Route path={`${path}${urls.VENDOR_OPENING}`}>
							<VendorOpeningRoutes />
						</Route>
						<Route path={`${path}${urls.VENDOR_PRODUCT_MAPPING}`}>
							<VendorProductMap />
						</Route>
						<Route path={`${path}${urls.VENDOR_CONTRACT_CREATE}`}>
							<CreateVendorContractPage />
							{/* <ListVendorContractPage /> */}
						</Route>
						<Route path={`${path}${urls.VENDOR_CONTRACT}`}>
							{/* <CreateVendorContractPage /> */}
							<ListVendorContractPage type='VENDOR' />
						</Route>
						<Route path={`${path}${urls.BUYER}`}>
							<VendorRoutes type='BUYER' />
						</Route>
						<Route path={`${path}${urls.BUYER_CONTRACT_CREATE}`}>
							<CreateVendorContractPage type='BUYER' />
							{/* <ListVendorContractPage /> */}
						</Route>
						<Route path={`${path}${urls.BUYER_CONTRACT}`}>
							{/* <CreateVendorContractPage /> */}
							<ListVendorContractPage type='BUYER' />
						</Route>
						<Route path={`${path}${urls.TEMP_ANNOTATION}`}>
							<TEMPAnnotationPage />
						</Route>
						<Route path={`${path}${urls.CONFIGURATION_PAGE}`}>
							<ConfigurationPage />
						</Route>
						<Route path={`${path}${urls.CREATE_CLOSINGS}`}>
							<CreateClosingPage />
						</Route>
						<Route path={`${path}${urls.PROCESS_CLOSINGS}`}>
							<ProcessClosingsPage createPath={`${path}${urls.CREATE_CLOSINGS}`} />
						</Route>
						<Route path={`${path}${urls.LEFT_OVER_INVENTORY_HISTORY}`}>
							<LeftOverInventoryHistory />
						</Route>
						<Route path={`${path}${urls.SERVICES}`}>
							<AllCapexRoutes />
						</Route>
						<Route path={`${path}${urls.FORECASTING}`}>
							<ListForecasting />
						</Route>
						<Route path={`${path}${urls.REPORTS}`}>
							<ReportsRoutes />
						</Route>
						<Route path={`${path}${urls.ORDER_TRACKNG}`}>
							<OrderTracking />
						</Route>
						<Route path={`${path}${urls.SIMILAR_PRODUCT}`}>
							<SimilarProducts />
						</Route>
						<Route path={`${path}${urls.LABEL_GENERATOR}`}>
							<LabelGeneratorRoutes />
						</Route>
						<Route path={`${path}${urls.REGION_MAPPING}`}>
							<RegionsMapping />
						</Route>
						<Route path={`${path}${urls.NOT_ACCESSIBLE_PAGE}`}>
							<NotAccessiblePage />
						</Route>
						<Route exact path={`${path}${urls.SHIFT}`}>
							<ListShiftManagment />
						</Route>
						<Route exact path={`${path}${urls.SHIFT_CREATE}`}>
							<ShiftCreate />
						</Route>
						<Route path={`${path}${urls.DAY_END_REPORT}`}>
							<DayEndReport />
						</Route>
						<Route path={`${path}${urls.AI_PACKAGING}`}>
							<AiPackaging />
						</Route>
						<Route path={`${path}${urls.QUALITY_LOGS}`}>
							<QualityLogs />
						</Route>
						<Route path={`${path}${urls.QUALITY_DETAILS}`}>
							<QualityDetails />
						</Route>
						<Route path={`${path}${urls.GRN_DETAILS}`}>
							<GRNDetailsPage />
						</Route>
						<Route path={`${path}${urls.GRN_LIST}`}>
							<GRNsPage />
						</Route>
						<Route path={`${path}${urls.WASTAGE}`}>
							<WastageInventory />
						</Route>
						<Route path={`${path}/`}>
							<Redirect to={`${path}${urls.DASHBOARD}`} />
						</Route>
						<Route path={`${path}`}>
							<Redirect to={`${path}${urls.DASHBOARD}`} />
						</Route>
						<Route path='*'>
							<Redirect to={`${path}${urls.NOT_ACCESSIBLE_PAGE}`} />
						</Route>
					</Switch>
				</ErrorBoundary>
			</div>
		</>
	);
};

export default AppRoutes;
