/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import Chip from '@mui/material/Chip';
import DownloadIcon from '@mui/icons-material/Download';
import { useCallback, useState } from 'react';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {
	analyticsAction,
	formActions,
	modalActions,
} from '../../../../Actions';
import { ListTable } from '../../../../Components/ListTable';
import { POItemsModal } from '../../../../Components/Modals/POItemsModal';
import styles from './IndentOrderList.module.scss';
import { VanImageModal } from '../../../../Components/Modals/VanImageModal';
import assetServices from '../../../../Services/AssetServices/asset.services';
import { notificationServices } from '../../../../Services';
import inventoryAnalyticsServices from '../../../../Services/Analytics Services/inventory-analytics.services';
import { AlertModal } from '../../../../Components/Modals';
import IndentOrderListHeader from './IndentOrderListHeader/IndentOrderListHeader';

const TABLE_COLUMNS = [
	{
		title: 'Client Indent Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Delivery Date',
		config: {
			type: 'DATA',
		},
	},

	{
		title: 'Order Status',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'raised by',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Indent Store',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [];

const ACTION_COLUMNS = [
	{
		title: 'Download',
		config: {
			type: 'VIEW',
		},
	},
	{
		title: 'View',
		config: {
			type: 'VIEW',
		},
	},
	{
		title: 'Cancel Indent',
		config: {
			type: 'CANCEL',
		},
	},
];

const COLMAP = {
	'Client Indent Id': 'clientIndentId',
	'Delivery Date': 'scheduledAt',
	'raised by': 'storeName',
	'Indent Store': 'indentStoreName',
	'Order Status': 'orderStatus',
};

function IndentOrderList(props) {
	const { data, toggleModal, toggleAlert } = props;
	const [update, setUpdate] = useState(-1);

	const handleCancelIndent = (id, clientPoId) => {
		// eslint-disable-next-line no-console
		(async () => {
			const notificationId = notificationServices.generateNotification({
				type: 'loading',
				message: `Cancelling Indent Order ${clientPoId || ''}`,
			});
			try {
				const resp =
					await inventoryAnalyticsServices.changeIndentOrderStatus({
						params: {
							indentId: id,
							status: 'CANCEL',
						},
					});
				notificationServices.generateNotification({
					type: 'success',
					message: resp?.status,
					config: {
						id: notificationId,
					},
				});
				setUpdate((prev) => {
					return prev + 1;
				});
			} catch (error) {
				notificationServices.generateNotification({
					type: 'error',
					message: error,
					config: {
						id: notificationId,
					},
				});
			}
		})();
	};

	// const handleDownloadGRN = (downloadLink) => {
	// window.open(downloadLink);
	// };

	const handleViewClick = (item) => {
		toggleModal(true, {
			heading: `Indent Items of ${item?.clientIndentId ?? '-'}`,
			excludedColumns: [
				'Returned Quantity',
			],
			...item,
		});
	};

	const handleExcelDownload = useCallback((header, fileData, fileName) => {
		const commonColumns = [
			'Client Indent id',
			'Delivery Date',
			'Order status',
			'Raised by',
		];
		const downloadBtn = document.createElement('a');

		const csvData = [[...commonColumns, ...header], ...fileData];

		const csv = `${csvData
			.map((e) => {
				return e.join(',');
			})
			.join('\n ')}`;

		const encodedValue = new Blob([csv], {
			type: 'text/csv',
		});
		downloadBtn.href = window.URL.createObjectURL(encodedValue);
		downloadBtn.target = '_blank';
		downloadBtn.download = fileName;
		downloadBtn.click();
	}, []);

	const SPECIAL_ITEMS = {
		Download: (itemId) => {
			const item = data?.find((poItem) => {
				return poItem?.id === itemId;
			});
			const vendorIdPresent = !!item?.vendorId;
			const clientPOId = item?.clientPoId ?? item?.id;
			const deliveryDate = item?.scheduledAt;
			const orderStatus = item?.orderStatus;
			const raisedBy = item?.storeName;
			const fullFilledBy = item?.vendorName ?? item?.fromStoreName ?? '-';
			const CommonValues = [
				clientPOId,
				deliveryDate,
				orderStatus,
				raisedBy,
				fullFilledBy,
			];
			const extraHeaders = ['Product Name', 'unit', 'Ordered Quantity'];
			return (
				<div
					style={{
						width: '125px',
					}}
					className={styles?.downloadDiv}
				>
					{item?.grnURL && (
						<Chip
							size='small'
							label='GRN'
							onClick={() => {
								if (item?.grnURL) {
									window.open(item?.grnURL);
								}
							}}
							icon={<PictureAsPdfIcon />}
						/>
					)}
					<Chip
						size='small'
						label='Indent'
						onClick={() => {
							const extraValues = item?.items?.map((ele) => {
								return [
									...CommonValues,
									ele?.productName?.replaceAll(',', "'"),
									ele?.unit,
									ele?.qty,
								];
							});
							handleExcelDownload(
								extraHeaders,
								extraValues,
								`Indent- ${clientPOId}`
							);
						}}
						icon={<DownloadIcon />}
					/>
					{['FULFILLED', 'CLOSED'].includes(item?.orderStatus) && (
						<Chip
							size='small'
							label='GRN'
							onClick={() => {
								let extraHeadersGRN;
								if (vendorIdPresent) {
									extraHeadersGRN = [
										...extraHeaders,
										'FulFilled',
									];
								} else {
									extraHeadersGRN = [
										...extraHeaders,
										'Dispatched',
										'Received',
									];
								}
								const extraValues = item?.items?.map((ele) => {
									const temp = [
										...CommonValues,
										ele?.productName?.replaceAll(',', "'"),
										ele?.unit,
										ele?.qty,
										ele?.fulfilled,
									];
									if (vendorIdPresent) {
										return temp;
									}
									return [...temp, ele?.storeFulfilled];
								});
								handleExcelDownload(
									extraHeadersGRN,
									extraValues,
									`GRN- ${clientPOId}`
								);
							}}
							icon={<DownloadIcon />}
						/>
					)}
					{['VERIFICATION', 'TRANSIT', 'FULFILLED', 'CLOSED'].includes(
						item?.orderStatus
					) &&
						item?.fromStoreName && (
						<Chip
							size='small'
							label='Delivery Challan'
							onClick={() => {
								let extraHeadersDC;
								if (vendorIdPresent) {
									extraHeadersDC = [
										...extraHeaders,
										'FulFilled',
									];
								} else {
									extraHeadersDC = [
										...extraHeaders,
										'Dispatched',
									];
								}
								const extraValues = item?.items?.map(
									(ele) => {
										const temp = [
											...CommonValues,
											ele?.productName?.replaceAll(
												',',
												"'"
											),
											ele?.unit,
											ele?.qty,
											ele?.fulfilled,
										];
										return [...temp];
									}
								);
								handleExcelDownload(
									extraHeadersDC,
									extraValues,
									`DC- ${clientPOId}`
								);
							}}
							icon={<DownloadIcon />}
						/>
					)}
				</div>
			);
		},
		'vendor / Fulfilled by store': (itemId) => {
			const item = data?.find((poItem) => {
				return poItem?.id === itemId;
			});
			return (
				<div
					style={{
						width: '200px',
						textAlign: 'left',
					}}
				>
					{item?.vendorName ?? item?.fromStoreName ?? '-'}
				</div>
			);
		},
		'Cancel Indent': (itemId) => {
			const item = data?.find((poItem) => {
				return poItem?.id === itemId;
			});
			return item?.orderStatus === 'PENDING' ? (
				<div
					style={{
						width: '125px',
					}}
				>
					<Button
						variant='outlined'
						color='error'
						onClick={() => {
							toggleAlert(true, {
								heading: 'Cancel Indent',
								handleConfirm: () => {
									handleCancelIndent(
										itemId,
										item?.clientPoId
									);
									toggleAlert(false);
								},
								colorReverse: false,
								cancelText: 'No',
								confirmText: 'Yes',
								message: `Are you sure, Cancel ${item?.clientPoId || ''}`,
							});
						}}
					>
						Cancel Indent
					</Button>
				</div>
			) : (
				'-'
			);
		},
	};

	const handleEditSubmit = async (editedValues) => {
		try {
			const response = await assetServices.editAssetTemp({
				data: editedValues,
				params: {
					type: 'indent`',
				},
			});
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: response?.errorMessage ?? response?.message,
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error ?? error?.message,
			});
		}
	};

	return (
		<div className={styles.root}>
			<AlertModal />
			<POItemsModal />
			<VanImageModal handleEditSubmit={handleEditSubmit} />
			<div className={styles.header}>
				<IndentOrderListHeader updateState={update} />
			</div>
			<div
				style={{
					width: '100%',
					overflow: 'auto',
					height: 'calc(100vh - 13rem)',
				}}
			>
				<ListTable
					fixedHeaderTop={0}
					hasDateRange
					colMap={COLMAP}
					columns={TABLE_COLUMNS}
					data={data}
					actions={[...SPECIAL_COLUMNS, ...ACTION_COLUMNS]}
					special={SPECIAL_ITEMS}
					handleViewClick={handleViewClick}
				/>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	const stores = state?.form?.STORES?.data?.LIST;
	const { DATE_RANGE_FILTER: dateRangeFilter } = state.analytics;
	const { INDENT_LIST: data } = state?.analytics?.INVENTORY_ANALYTICS;
	return {
		data,
		stores,
		dateRangeFilter,
	};
};

const mapDispatchToProps = {
	fetchStoresList: formActions.gateway,
	setDateRangeFilter: analyticsAction.setDateRangeFilter,
	toggleModal: modalActions.togglePOItemsModal,
	toggleAlert: modalActions.toggleAlert,
	toggleVanImageModal: modalActions.toggleVanImageModal,

};

export default connect(mapStateToProps, mapDispatchToProps)(IndentOrderList);
