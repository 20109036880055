/* eslint-disable*/
import { get } from 'lodash';
import { ListTable } from '../ListTable';
import { urls } from '@/Constants';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

function GrnQcLogTable(props) {
	const { data, colMap, special, specialMappings, tableType, filters={}, location={} } = props;
	const history = useHistory();

	const getTable = (tableType) => {
		switch(tableType) {
			case 'qualityChecks':
				return <ListTable
					colMap={colMap}
					actions={special}
					special={specialMappings}
					data={data}
					allowExport={false}
					showBtnContainer={false}
					headerColor={true}
				/>
			case 'machineScans':
				return <ListTable
					colMap={colMap}
					actions={special}
					special={specialMappings}
					data={data.map((obj, idx) => {
						const itemId = get(obj, ['itemId']);
						const id = get(obj, ['id']);
						return {
							...obj,
							onClick: () => {
								history.push({
									pathname: `${urls.EXTENSION}${urls.PAST_QC}/${itemId}`,
									search: `id=${id}`,
									state: {
										from: history?.location?.pathname,
									},
								});
							},
							styleCss: {
								cursor: 'pointer',
							}
						}
					})}
					allowExport={false}
					showBtnContainer={false}
					headerColor={true}
				/>
			case 'aiMachineQcLogs':
				return <ListTable
					colMap={colMap}
					actions={special}
					special={specialMappings}
					data={data.map((obj, idx) => {
						const itemId = get(obj, ['itemId']);
						const id = get(obj, ['id']);
						return {
							...obj,
						onClick: () => {
							history.push({
								pathname: `${urls.EXTENSION}${urls.PAST_QC}/${itemId}`,
								search: `id=${id}`,
								state: {
									from: history?.location?.pathname,
								},
							});
						},
						styleCss: {
							cursor: 'pointer',
						}
					}
				})}
				allowExport={false}
				showBtnContainer={false}
			/>
			case 'grnQcLogs':
				return <ListTable
				colMap={colMap}
				actions={special}
				special={specialMappings}
				data={data.map((obj, idx) => {
					const id = get(obj, ['batchId']);
					return {
						...obj,
						onClick: () => {
							history.push({
								pathname: `${urls.EXTENSION}${urls.QUALITY_DETAILS}`,
								search: `id=${id}&storeId=${filters.storeId}&status=${filters.status}&dateRangeFilter=${JSON.stringify(filters.dateRangeFilter)}`,
							});
						},
						styleCss: {
							cursor: 'pointer',
						}
					}
				})}
				allowExport={false}
				showBtnContainer={false}
			/>
			
			default:
				return null;
		}
	}
	return getTable(tableType);
}
export default GrnQcLogTable;
