// import { useState } from 'react';
/* eslint-disable */
import { useAuth } from '@/Components/Auth/Login/auth';
import { classes, convertWeightUnit } from '../../../../../Helpers';
import styles from './CMSRecipeItemsList.module.scss';

function BuildItem(props) {
	const { data, onClick, isSelected, isCms, unit, recipe } = props;
	const { appConfig = {} } = useAuth();
	const { combineCMSBatches } = appConfig?.configs ?? {};
	const convertedConsumption = convertWeightUnit(
		data?.consumption,
		data?.unit,
		true
	);
	const convertedRequired = convertWeightUnit(
		data?.requiredQty,
		data?.unit,
		true
	);
	const convertedOrdered = convertWeightUnit(
		data?.ingTotalQty,
		data?.unit,
		true
	);

	return (
		<div
			onClick={onClick}
			className={classes(
				styles.item,
				data?.isConsumed ? styles.doneGreen : '',
				isSelected ? styles.selected : ''
			)}
		>
			<div className={styles.img}>
				<span className={styles.completedText}>
					{(isCms && combineCMSBatches) ? `${
						(data?.consumption >= 0
							? `${unit === 'count' ? recipe?.itemFulfilled + ' pc' : convertedConsumption}/`
							: '') + (unit === 'count' ? parseInt(convertedOrdered) + ' pc' : convertedOrdered)
					}` : `${
						(data?.consumption >= 0
							? `${convertedConsumption}/`
							: '') + convertedRequired
					}`}
				</span>
				<img
					style={{
						width: '100%',
						height: 'auto',
					}}
					src={data?.image}
					alt='prblm showing'
				/>
			</div>
			<div className={styles.name}>
				<span>{data?.name}</span>
			</div>
		</div>
	);
}

function CMSRecipeItemsList(props) {
	const { ingredients, onClick, currentRecipe, getPastQCDetails, isCms, unit, recipe } = props;
	// const [current, setCurrent] = useState(0);

	return (
		<div className={styles.root}>
			<div className={styles.list}>
				{ingredients?.map((data) => {
					return (
						<BuildItem
							onClick={() => {
								onClick(data);
								getPastQCDetails(data?.qcId);
							}}
							isSelected={currentRecipe?.id === data?.id}
							data={data}
							isCms={isCms}
							unit={unit}
							recipe={recipe}
						/>
					);
				})}
			</div>
		</div>
	);
}

export default CMSRecipeItemsList;
