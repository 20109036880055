/* eslint-disable */
function Info(props) {
    const {
        buttons,
        fieldMap,
        data,
        specialFields
    } = props;

    return (
        <div className='p-6 relative top-4'>
            <div className='flex gap-4 items-center justify-between align-center'>
                <div className='flex gap-5 items-center align-center'>
                    <h2 className='text-xl font-semibold'>Status :</h2>
                    <span className='text-gray-600'><strong>{data?.status}</strong> </span>
                </div>
                <div className='flex gap-2'>
                    {buttons.map((button, index) => (
                        <button key={index} 
                            className={`
                                flex items-center justify-center 
                                px-4 py-2 
                                text-sm font-medium 
                                border rounded-md 
                                transition-colors duration-200
                                ${button?.active 
                                  ? 'bg-yellow-500 text-black border-yellow-500 hover:bg-yellow-600' 
                                  : 'bg-white text-gray-700 border-gray-300 hover:bg-gray-100'
                                }
                            `}
                            onClick={button.handleClick}
                        >
                            {button.icon}
                            {button.label}
                        </button>
                    ))}
                </div>

            </div>

            <div className='grid grid-cols-4 gap-4 mt-4 text-gray-700'>
                {Object.entries(fieldMap).map(([key, value]) => {
                    const dataValue = data[value];
                    return (
                        <div >
                            <p className='text-sm font-medium'>{key}</p>
                            {specialFields[key] ? specialFields[key](key, dataValue) : <p className='font-semibold'>{dataValue}</p>}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default Info;


