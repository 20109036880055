/* eslint-disable no-tabs */
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import {
	useEffect,
	useReducer,
	useState,
	useMemo,
	useRef,
} from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';
import styles from './EWayBillCreatePage.module.scss';
import config from './EWayBillCreatePage.config';
// eslint-disable-next-line no-unused-vars
import { geoDataServices, notificationServices } from '../../Services';
import { formActions, modalActions } from '../../Actions';
import BackdropLoader from '../../Components/Loaders/BackdropLoader';
import {
	getStateFromGSTIN,
	useQuery,
} from '../../Helpers';
import { POItemSelection } from '../../Components/POItemSelection';
import inventoryAnalyticsServices from '../../Services/Analytics Services/inventory-analytics.services';
import { POTotalContainer } from '../../Components/POTotalContainer';
import { CreatePageHeader } from '../../Components/Headers';
import CreateFormpage from '../../Components/CreateFormpage/CreateFormpage';
// eslint-disable-next-line max-len
// import productProcessMapServices from '../../Services/Analytics Services/product-process-map.services';
import orderAnalyticsServices from '../../Services/Analytics Services/orderAnalytics.services';
import { AlertModal } from '@/Components/Modals';

function EWayBillCreatePage(props) {
	const {
		fetchStoresList,
		stores,
		fetchProducts,
		productsList,
		toggleAlert,
	} = props;
	const [state, dispatch] = useReducer(config.reducer, config.initialState);
	const changed = useRef(false);
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const params = useQuery();
	const poId = Number(params.get('poId'));
	const type = params.get('type');
	// eslint-disable-next-line no-unused-vars
	const [productWiseHSNCode, setProductWiseHSNCode] = useState({});
	// eslint-disable-next-line no-unused-vars
	const [cityKeyValueMap, setCityKeyValueMap] = useState({});
	const [orderItems, setOrderItems] = useState([]);

	useEffect(() => {
		fetchStoresList({
			entity: 'STORES',
			req: {
				params: {
					id: '',
					all: true,
				},
			},
			method: 'LIST',
		});
		fetchProducts({
			entity: 'ALL_PRODUCTS',
			req: {
				params: {
					id: '',
					variants: true,
				},
			},
			method: 'LIST',
		});
	}, [fetchStoresList, fetchProducts]);

	// useEffect(() => {
	// if (state?.toStore?.id) {
	// (async () => {
	// const req = {
	// data: {
	// store: {
	// id: state?.deliverTo?.id,
	// },
	// mappingTypes: [9],
	// categoryId: null,
	// products: null,
	// },
	// };
	// try {
	// const response =
	// await productProcessMapServices?.fetchProductWiseMappingData(
	// req
	// );
	// if (response?.responseCode === 'SS-001') {
	// const hsnCodeData = {};
	// response?.data?.productwiseMappings.forEach((ele) => {
	// hsnCodeData[ele?.productId] =
	// ele?.mappingTypeElementValue;
	// });
	// setProductWiseHSNCode(hsnCodeData);
	// return;
	// }
	// notificationServices.generateNotification({
	// type: 'error',
	// message: 'product wise mapping fetch error',
	// });
	// } catch (error) {
	// notificationServices.generateNotification({
	// type: 'error',
	// message: error?.message,
	// });
	// }
	// })();
	// }
	// }, [state?.toStore?.id]);

	useEffect(() => {
		(async () => {
			try {
				const response = await geoDataServices?.getGeoData();
				const cityKeyValueMapT = {};
				if (response?.statusCode === 200) {
					Object.entries(response?.data).forEach(([, value]) => {
						Object.entries(value?.city).forEach(([, cityValue]) => {
							cityKeyValueMapT[cityValue?.cityId] =
								cityValue?.cityName;
						});
					});
					setCityKeyValueMap(cityKeyValueMapT);
					return;
				}
				notificationServices.generateNotification({
					type: 'error',
					message: 'city store mapping error',
				});
			} catch (error) {
				notificationServices.generateNotification({
					type: 'error',
					message: error?.message,
				});
			}
		})();
	}, []);

	useEffect(() => {
		if (poId && stores?.length > 0) {
			(async () => {
				try {
					const response = await inventoryAnalyticsServices.fetchPo(
						Number(poId)
					);
					if (response?.statusCode === 200) {
						dispatch({
							type: 'SET_VALUE',
							payload: {
								orderAmount: response?.data?.items?.reduce(
									(acc, curr) => {
										return acc + curr?.finalValue;
									},
									0
								),
								toStore: stores?.find((ele) => {
									return ele?.id === response?.data?.storeId;
								}),
								fromStore: stores?.find((ele) => {
									return (
										ele?.id === response?.data?.fromStore
									);
								}),
								orderId: response?.data?.id,
								clientPOId: response?.data?.clientPoId,
								deliveryCharges:
									response?.data?.deliveryCharges ?? 0,
								packagingCharges:
									response?.data?.packagingCharges ?? 0,
								poData: response?.data,
								transporterName: response?.data?.transName,
								transporterId: response?.data?.transGSTNo,
								transDistance: response?.data?.transDistance,
								vehicleNo: response?.data?.transVehicleNo,
								url: response?.data?.jsonData?.ewayBill?.url,
								ewayBillno: response?.data?.jsonData?.ewayBill?.ewayBillNo,
								eInvoiceUrl: response?.data?.jsonData?.eInvoice?.eInvoiceUrl,
								transDocNumber: response?.data?.jsonData?.transDocNumber,
								transDocDate: response?.data?.jsonData?.transDocDate ? moment(response?.data?.jsonData?.transDocDate, 'DD/MM/YYYY').toDate() : null,
							},
						});
						setOrderItems(
							response?.data?.items
								?.filter((thisEle) => {
									if (thisEle?.fulfilled > 0) {
										return true;
									}
									return false;
								})
								?.map((ele) => {
									return {
										...ele,
										qty: ele?.fulfilled,
									};
								})
						);
					}
				} catch (error) {
					// eslint-disable-next-line no-console
					console.log(error);
				} finally {
					setLoading(false);
				}
			})();
		}
	}, [poId, stores]);

	const setItemsState = (items) => {
		dispatch({
			type: 'SET_VALUE',
			payload: {
				items,
			},
		});
	};

	const PRODUCTS = useMemo(() => {
		return productsList?.filter((product) => {
			return (
				product?.skuType === 'INTERMEDIATE' ||
				product?.skuType === 'PRODUCT' ||
				product?.skuType === 'INGREDIENT'
			);
		});
	}, [productsList]);

	const calculatedData = useMemo(() => {
		let subTotal = 0;
		let totalTax = 0;
		let totalAmount = 0;
		let taxValues = {};
		const totalQuantity = state?.items?.length;
		state?.items?.forEach((ele) => {
			if (ele?.productInputUnit === 'g' || ele?.productInputUnit === 'ml') {
				subTotal += Number.isNaN(ele?.selectedVariant?.price)
					? 0
					: Number(
						ele?.selectedVariant?.price * (ele?.quantity / 1000)
					);
				totalAmount +=
					ele?.selectedVariant?.price *
					(1 + ele?.tax / 100) *
					Number(ele?.quantity / 1000);
				totalTax +=
					((ele?.selectedVariant?.price * ele?.tax) / 100) *
					(Number(ele?.quantity) / 1000);
			} else if (ele?.productInputUnit === 'oz') {
				subTotal += Number.isNaN(ele?.selectedVariant?.price)
					? 0
					: Number(
						ele?.selectedVariant?.price * (ele?.quantity / 16)
					);
				totalAmount +=
					ele?.selectedVariant?.price *
					(1 + ele?.tax / 100) *
					Number(ele?.quantity / 16);
				totalTax +=
					((ele?.selectedVariant?.price * ele?.tax) / 100) *
					(Number(ele?.quantity) / 16);
			} else {
				subTotal += Number.isNaN(ele?.selectedVariant?.price)
					? 0
					: Number(ele?.selectedVariant?.price * ele?.quantity);
				totalAmount +=
					ele?.selectedVariant?.price *
					(1 + ele?.tax / 100) *
					Number(ele?.quantity);
				totalTax +=
					((ele?.selectedVariant?.price * ele?.tax) / 100) *
					Number(ele?.quantity);
			}
		});
		taxValues.SGST = totalTax / 2;
		taxValues.CGST = totalTax / 2;
		if (
			state?.fromStore?.id &&
			state?.toStore?.gstNumber &&
			state?.fromStore?.gstNumber
		) {
			const toStoreState = getStateFromGSTIN(state?.toStore?.gstNumber);
			const fromStoreState = getStateFromGSTIN(
				state?.fromStore?.gstNumber
			);
			if (fromStoreState !== toStoreState) {
				taxValues = {
					IGST: totalTax,
				};
			}
		}
		return {
			subTotal,
			totalQuantity,
			totalTax,
			totalAmount,
			taxValues,
		};
	}, [state?.items, state?.fromStore, state?.toStore]);

	// eslint-disable-next-line consistent-return
	const updateEwayBillonBackend = async (req) => {
		setLoading(true);
		try {
			const response = await orderAnalyticsServices.updateEwayBill(req);
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
				return response?.url;
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error?.message,
			});
			// eslint-disable-next-line no-console
			return false;
		} finally {
			setLoading(false);
		}
	};
	const handleReDownloadEwayBill = (url) => {
		const anchor = document.createElement('a');
		if (type === 'EWAYBILL') {
			anchor.href = `https://${url}`;
		} else {
			anchor.href = url;
		}
		anchor.download = type === 'EWAYBILL' ? 'EwayBill.pdf' : 'INVOICE.pdf';
		anchor.target = '_blank';
		anchor.click();
		history.goBack();
	};
	const handleGenerate = async () => {
		if (
			!state?.transporterName?.length ||
			state?.transporterName?.length === 0
		) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Transporter Name is mandatory ',
			});
			return;
		}
		const reg = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
		if (
			state?.fromStore?.type !== 'RETAIL' &&
			(!state?.transporterId?.length ||
			state?.transporterId?.length === 0 ||
			!reg.test(state?.transporterId))
		) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please enter valid GST Number',
			});
			return;
		}
		if (!state?.vehicleNo?.length || state?.vehicleNo?.length === 0) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Vehicle number is mandatory ',
			});
			return;
		}

		const ewayBillUrl = await updateEwayBillonBackend({
			params: {
				poId,
			},
			data: {
				transName: state?.transporterName,
				transGSTNo: state?.transporterId,
				transVehicleNo: state?.vehicleNo,
				transDistance: state?.transDistance,
				transDocNumber: state?.transDocNumber,
				transDocDate: state?.transDocDate ? moment(state?.transDocDate).format('DD/MM/YYYY') : undefined,
			},
		});
		if (ewayBillUrl) {
			dispatch({
				type: 'SET_VALUE',
				payload: {
					...state,
					url: ewayBillUrl,
				},
			});
			handleReDownloadEwayBill(ewayBillUrl);
		}
	};

	const cancelEwayBill = async (req) => {
		setLoading(true);
		try {
			const response = await orderAnalyticsServices.cancelEwayBill(req);
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
				return true;
			}

			notificationServices.generateNotification({
				type: 'error',
				message: response?.message,
			});
			return false;
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error?.message,
			});
			// eslint-disable-next-line no-console
			return false;
		} finally {
			setLoading(false);
		}
	};

	const handleCancel = async () => {
		toggleAlert(true, {
			handleConfirm: async (rejectionReason) => {
				const res = await cancelEwayBill({
					data: {
						poId,
						cancelRemark: rejectionReason,
					},
				});
				if (res) {
					dispatch({
						type: 'SET_VALUE',
						payload: {
							...state,
							url: null,
							ewayBillno: null,
						},
					});
				}
				toggleAlert(false, {});
			},
			heading: 'Reject',
			takeInput: true,
			confirmText: 'Cancel',
			height: 'fit-content',
			width: '27.5rem',
			message: 'Please Give reason to Cancel Eway Bill',
			inputLabel: 'Enter Reason',
			colorReverse: true,
			rendererType: 'EWAYBILL',
		});
	};
	const handleEInvoice = async (req) => {
		setLoading(true);
		try {
			const response = await orderAnalyticsServices.generateEInvoice(req);
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
				return response?.eInvoiceUrl;
			}
			return null;
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error?.message,
			});
			// eslint-disable-next-line no-console
			return null;
		} finally {
			setLoading(false);
		}
	};
	const handleGenerateEInvoice = async () => {
		const eInvoiceUrl = await handleEInvoice({
			data: {
				poId,
			},
		});
		if (eInvoiceUrl) {
			dispatch({
				type: 'SET_VALUE',
				payload: {
					...state,
					eInvoiceUrl,
				},
			});
			if (state?.poData?.jsonData?.ewayBill?.url) {
				handleReDownloadEwayBill(eInvoiceUrl);
			}
		}
	};

	// const cancelInvoice = async (req) => {
	// 	setLoading(true);
	// 	try {
	// 		const response = await orderAnalyticsServices.cancelEInvoice(req);
	// 		if (response?.statusCode === 200) {
	// 			notificationServices.generateNotification({
	// 				type: 'success',
	// 				message: response?.message,
	// 			});
	// 			return true;
	// 		}

	// 		notificationServices.generateNotification({
	// 			type: 'error',
	// 			message: response?.message,
	// 		});
	// 		return false;
	// 	} catch (error) {
	// 		notificationServices.generateNotification({
	// 			type: 'error',
	// 			message: error?.message,
	// 		});
	// 		// eslint-disable-next-line no-console
	// 		return false;
	// 	} finally {
	// 		setLoading(false);
	// 	}
	// };

	// const handleCancelEInvoice = async () => {
	// 	toggleAlert(true, {
	// 		handleConfirm: async (rejectionReason) => {
	// 			const res = await cancelInvoice({
	// 				data: {
	// 					poId,
	// 					cancelRemark: rejectionReason,
	// 				},
	// 			});
	// 			if (res) {
	// 				dispatch({
	// 					type: 'SET_VALUE',
	// 					payload: {
	// 						...state,
	// 						eInvoiceUrl: null,
	// 					},
	// 				});
	// 			}
	// 			toggleAlert(false, {});
	// 		},
	// 		heading: 'Reject',
	// 		takeInput: true,
	// 		confirmText: 'Cancel',
	// 		height: 'fit-content',
	// 		width: '27.5rem',
	// 		message: 'Please Give reason to Cancel Invoice',
	// 		inputLabel: 'Enter Reason',
	// 		colorReverse: true,
	// 		rendererType: 'INVOICE',
	// 	});
	// };
	const renderBtns = () => {
		return (
			<div className={styles.btns}>
				{ type === 'EWAYBILL' && (
					<>
						{ !state?.url && (
							<Button
								onClick={() => {
									handleGenerate();
								}}
								variant='contained'
								color='primary'
								type='button'
							>
								Generate Eway Bill
							</Button>
						)}
						{ state?.url && (
							<Button
								onClick={() => {
									handleCancel();
								}}
								variant='contained'
								color='error'
								type='button'
							>
								Cancel Eway Bill
							</Button>
						)}
					</>
				)}
				{ type === 'INVOICE' && (
					<>
						{ !state?.eInvoiceUrl && (
							<Button
								onClick={() => {
									handleGenerateEInvoice();
								}}
								variant='contained'
								color='primary'
								type='button'
							>
								Generate E Invoice
							</Button>
						)}
						{/* { state?.eInvoiceUrl && (
							<Button
								onClick={() => {
									handleCancelEInvoice();
								}}
								variant='contained'
								color='error'
								type='button'
							>
								Cancel E-Invoice
							</Button>
						)} */}
					</>
				)}
			</div>
		);
	};

	const handleBackClick = () => {
		history.goBack();
	};
	const handleInputChange = (e) => {
		changed.current = true;
		const { value = '', name: label = '' } = e.target;
		dispatch({
			type: 'SET_VALUE',
			payload: {
				[label]: value,
			},
		});
	};
	const formattedInputs = (list = []) => {
		return list?.map((ele) => {
			if (Array.isArray(ele)) {
				return formattedInputs(ele);
			}
			if (ele?.inputType === null || ele?.inputType === undefined) {
				if (ele?.id === 'transporterId') {
					const isMandatory = state?.fromStore?.type !== 'RETAIL';
					return {
						...ele,
						value: state?.[ele?.id],
						mandatory: isMandatory,
						onChange: handleInputChange,
					};
				}
				return {
					...ele,
					value: state?.[ele?.id],
					onChange: handleInputChange,
				};
			}
			if (ele?.id === 'fromStore') {
				return {
					...ele,
					value: state?.fromStore?.name,
					handleSelection: (fromStore) => {
						dispatch({
							type: 'SET_VALUE',
							payload: {
								fromStore,
							},
						});
					},
					optionsList: stores,
					handleKeyPress: () => {
						dispatch({
							type: 'SET_VALUE',
							payload: {
								fromStore: null,
							},
						});
					},
					handleRemove: () => {
						dispatch({
							type: 'SET_VALUE',
							payload: {
								fromStore: null,
							},
						});
					},
				};
			}
			if (ele?.id === 'toStore') {
				return {
					...ele,
					value: state?.toStore?.name,
					handleSelection: (toStore) => {
						dispatch({
							type: 'SET_VALUE',
							payload: {
								toStore,
							},
						});
					},
					optionsList: stores,
					handleKeyPress: () => {
						dispatch({
							type: 'SET_VALUE',
							payload: {
								toStore: null,
							},
						});
					},
					handleRemove: () => {
						dispatch({
							type: 'SET_VALUE',
							payload: {
								toStore: null,
							},
						});
					},
				};
			}
			if (ele?.id === 'docDate') {
				return {
					...ele,
					value: state?.docDate,
					onChange: (date) => {
						dispatch({
							type: 'SET_VALUE',
							payload: {
								docDate: date,
							},
						});
					},
				};
			}
			if (ele?.id === 'transDocDate') {
				return {
					...ele,
					value: state?.transDocDate,
					onChange: (date) => {
						dispatch({
							type: 'SET_VALUE',
							payload: {
								transDocDate: date,
							},
						});
					},
				};
			}
			return ele;
		});
	};

	return (
		<>
			<div className={styles.root}>
				<AlertModal type={type} />
				<BackdropLoader isLoading={loading} showProgress />
				<CreatePageHeader
					heading={type === 'EWAYBILL' ? 'E-Way BILL' : 'E-Invoice'}
					renderBtns={renderBtns}
					showBack
					handleBackClick={handleBackClick}
				/>
				<div className={styles.body}>
					<CreateFormpage
						inputsList={formattedInputs(config.inputsList?.filter((input) => { if (type === 'INVOICE') { return input.hideAddNew === true; } return true; }))}
					/>
				</div>
				<div className={styles['items-details-container']}>
					<POItemSelection
						state={state?.items}
						setState={setItemsState}
						defaultSelectedItems={orderItems}
						productsList={PRODUCTS}
						requiredFields={{
							fromStore: true,
							deliverTo: true,
							poId: true,
							date: true,
							expectedDeliveryDate: true,
							paymentTerms: false,
							generateStandardPO: false,
						}}
						disabled
					/>
					<POTotalContainer calculatedData={calculatedData} />
				</div>
			</div>
		</>
	);
}

const mapStateToProps = (state) => {
	const { userPermissions, navState } = state.navigation;
	const stores = state?.form?.STORES?.data?.LIST;
	const { LIST: processTypes } = state?.form?.PROCESS_TYPES?.data;
	const { LIST: productsList } = state.form.ALL_PRODUCTS.data;
	return {
		userPermissions,
		stores,
		processTypes,
		productsList,
		navState,
	};
};

const mapDispatchToProps = {
	fetchProducts: formActions.gateway,
	toggleListModal: modalActions.toggleListModal,
	fetchStoresList: formActions.gateway,
	fetchProcessTypesList: formActions.gateway,
	toggleAlert: modalActions.toggleAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(EWayBillCreatePage);
