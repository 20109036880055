import { Suspense, useState } from 'react';
import { subTabs } from './SubTabs';

function TabComp(props) {
	const { value, handleExport, data, filters } = props;
	const [active, setActive] = useState(subTabs[0]);
	const handleTabs = (tabsData) => {
		setActive(tabsData);
	};

	return (
		<>
			<div className='w-[1000px] flex  m-[12px] rounded-md bg-[#FDE6AD] '>
				{data.length > 0 && (subTabs?.map((ele) => {
					const isActive = active?.id === ele?.id;
					return (
						<div
							className={`text-center h-[37px] w-[250px] px-[7px] py-[8px]   text-base font-medium 
                                        cursor-pointer ${isActive ? 'bg-[#ffb703] h-[33px] m-[2px]' : 'border border-[#00000033]'}`}
							onClick={() => handleTabs(ele)}
						>
							{ele?.header}
						</div>
					);
				}))}
			</div>
			<Suspense fallback={<div>loading ...</div>}>
				{active?.comp?.({
					value,
					subValue: active.value,
					handleExport,
					data,
					filters,
				})}
			</Suspense>
		</>
	);
}
export default TabComp;
