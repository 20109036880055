export const SubTabsConfig = [
	{
		id: 1,
		value: 'orderQty',
		header: 'Qty',
	},
	{
		id: 2,
		value: 'fullfilmentPercentage',
		header: 'Fullfilment %',
	},
	{
		id: 3,
		value: 'containerCount',
		header: 'Cambro Count',
	},
	{
		id: 4,
		value: 'containerUtilization',
		header: 'Cambro Utilization',
	},
	{
		id: 5,
		value: 'fillTime',
		header: 'Fill Time',
	},
];
