import { ArrowBack, ShoppingBagOutlined } from '@mui/icons-material';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import styles from './CreatePurchaseOrderHeader.module.scss';
import { Button } from '@/Components/ui/button';

function CreatePurchaseOrderHeader(props) {
	const history = useHistory();
	const {
		showSave,
		handleSave,
		isEdit,
		disableSave,
		showBack = true,
		selectedOrderType,
		prevPath,
	} = props;
	const location = useLocation();
	return (
		<div className={styles.root}>
			<div className={styles.header}>
				{showBack && (
					<div
						onClick={() => {
							history.replace(prevPath, {
								prevPath: location?.pathname,
							});
						}}
						className='w-10 h-10 rounded-full hover:bg-slate-100 cursor-pointer flex justify-center items-center'
					>
						<ArrowBack />
					</div>
				)}
				<ShoppingBagOutlined className={styles.icon} />
				<p className={styles.heading}>
					{isEdit ? 'Edit' : 'New'}
					{' '}
					{selectedOrderType?.name ?? 'Order'}
				</p>
			</div>
			<div className={styles['actions-container']}>
				{showSave && (
					<Button
						className='primary-btn'
						onClick={handleSave}
						disabled={disableSave}
					>
						Save
					</Button>
				)}
			</div>
		</div>
	);
}

export default CreatePurchaseOrderHeader;
