/* eslint-disable */
/* eslint-disable no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import styles from './GRNsPage.module.scss';
import { BackdropLoader } from '../../Components/Loaders';
import ListTable from '../../Components/ListTable/ListTable';
import { formActions, modalActions } from '../../Actions';
import { notificationServices } from '../../Services';
import inventoryAnalyticsServices from '../../Services/Analytics Services/inventory-analytics.services';
import { POItemsModal } from '../../Components/Modals/POItemsModal';
import { useAuth } from '../../Components/Auth/Login/auth';
import { urls } from '../../Constants';
import userRolesAndPermissions from '@/Services/userRolesAndPermissions.services';
import authActions from '@/Actions/auth.actions';
import Tab from '@/Components/InfoTab/tab';
import SearchBar from '@/Components/SearchBar/SearchBar';
import Calender from '@/Components/Forms/Payment Settlements/Calender/Calender';
import { Chip, IconButton } from '@mui/material';
import { Button, CheckPicker, SelectPicker } from 'rsuite';
import { formatDate, useQuery, useSearch } from '@/Helpers';
import excel from '@/Images/excel.svg';
import { ArrowCircleRight } from '@mui/icons-material';
import GrnTable from './GrnTable';
import { grnQcLogService } from '@/Services/grnQcLog.services';

const SPECIAL_COLUMNS = [
	{
		title: 'Grn Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Vendor Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Grn Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'GRN Start Time',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Po Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Fullfilled Quantity',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'View Docs',
		config: {
			type: 'DATA',
		},
	},
];

const COLMAP = {
	GrnId: 'id',
	'Vendor Name': 'vendorName',
	'Grn Date': 'createdAt',
	'GRN Start Time': 'createdAt',
	'Po Id': 'clientPoId',
	'Fullfilled Quantity': 'id',
	'View Docs': 'id',
};


const aiMachineColMap = {
	'Store': 'storeName',
	'QC Time': 'QCTime',
	'Result': 'result',
	'Order ID': 'OrderId',
	'Product Name': 'ProductName',
	'Weight': 'Weight',
	'Count': 'count',
	'Temp': 'Temp',
};

const aiMachineSpecialColumns = [
	{
		title: 'Store',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'QC Time',
		config: {
			type: 'Data',
		},
	},
	{
		title: 'Order ID',
		config: {
			type: 'Data',
		},
	},
	{
		title: 'Product Name',
		config: {
			type: 'Data',
		},
	},
	{
		title: 'Weight',
		config: {
			type: 'Data',
		},
	},
	{
		title: 'Count',
		config: {
			type: 'Data',
		},
	},
	{
		title: 'Temp',
		config: {
			type: 'Data',
		},
	},
	{
		title: 'Result',
		config: {
			type: 'Data',
		},
	},
];
const aiMachineSpecialColumnsMappings = {
	'Store': (id, items) => {
		return <div>{items}</div>;
	},
	'QC Time': (id, items) => {
		return <div>{items}</div>;
	},
	'Order ID': (id, items) => {
		return <div>{items}</div>;
	},
	'Product Name': (id, items) => {
		return <div>{items}</div>;
	},
	'Weight': (id, items) => {
		return <div>{items}</div>;
	},
	'Count': (id, items) => {
		return <div>{items}</div>;
	},
	'Temp': (id, items) => {
		return <div>{items}</div>;
	},
	'Result': (id, items) => {
		return <div>{items}</div>;
	},
};

const tabList = [
	{
		value: 'grnLogs',
		label: 'GRN Logs',
	},
	{
		value: 'aiMachineLogs',
		label: 'AI Supervisor Scans',
	},
];

function GRNsPage({
	userPermissions,
	createPath,
	togglePOItemsModal,
	navState,
	fetchUserList,
	userList,
	stores,
	vendorsList,
	fetchStoresList,
	fetchAllVendors,
	searchQuery,
	searchBy,
}) {
	const history = useHistory();
	const { userConfig } = useAuth();
	const [isloading, setIsloading] = useState(false);
	const [grnsList, setGrnsList] = useState([]);
	const navRequisites = navState?.[urls.PURCHASE_ORDERS]?.requisites ?? [];
	const navSelectedStore = navRequisites?.find((ele) => {
		return ele?.type === 'store';
	});
	const [shouldAnimate, setShouldAnimate] = useState(true);
	const [isDataFetched, setIsDataFetched] = useState(false);
	const [store, setStore] = useState(null);
    const [vendors, setVendors] = useState([]);
    const [tabValue, setTabValue] = useState('grnLogs');
    const [dateRangeFilter, setDateRangeFilter] = useState({
        active: false,
        from: new Date(),
        to: new Date(),
    });
	const queryParams = useQuery();
	const PLACE_HOLDERS = {
		PRODUCT_NAME: 'Search by Product Name',
		QC_ID: 'Search by QC Id',
	};
	

	const FIELD_MAP = {
        PO_ID: 'clientPoId',
       	Vendor: 'vendorName',
        'GRN_ID': 'id',
    };
    
    const SEARCH_BY = {
        options: [
            {
                key: 'Vendor',
                title: 'Vendor',
                get: 'vendorName',
            },
            {
                key: 'GRN_ID',
                title: 'GRN Id',
                get: 'id',
            },
            {
                key: 'PO_ID',
                title: 'PO Id',
                get: 'clientPoId',
            },
        ],
    };

	useEffect(() => {
		if (shouldAnimate) {
			const st = setTimeout(() => {
				setShouldAnimate(false);
			}, 1500);
			return () => {
				clearTimeout(st);
			};
		}
		return () => {};
	}, [shouldAnimate]);

	useEffect(() => {
		setShouldAnimate(true);
		setIsDataFetched(false);
	}, [grnsList]);

	const setDate = (newDate) => {
		const { startDate: from, endDate: to } = newDate?.selection ?? {};
		
		// Set the new date range and then fetch data
		setDateRangeFilter({
			active: true,
			from,
			to,
		});
        setIsDataFetched(true);
	};

    const exportQCLogs = () => {
        console.log('exportQCLogs');
    }
	const {
		query,
		handleSearch,
		clearSearch,
		searchBy: searchField,
		handleSearchBy: setSearchField,
	} = useSearch(
		searchQuery,
		SEARCH_BY?.options?.findIndex((option) => {
			return option.key === searchBy;
		}) ?? 0
	);

	useEffect(() => {
        fetchStoresList({
            entity: 'STORES',
            req: {
                params: {
                    id: '',
                    type: '',
                },
            },
            method: 'LIST',
        });
		fetchAllVendors({
			entity: 'ALL_VENDORS',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	useEffect(() => {
        const searchParams = new URLSearchParams();
        if (tabValue) {
            searchParams.set('tabValue', tabValue);
        }
        if (store) {
            searchParams.set('storeId', store);
        }
        if (vendors) {
            searchParams.set('vendors', JSON.stringify(vendors));
        }
        if (dateRangeFilter) {
            searchParams.set('dateRangeFilter', JSON.stringify(dateRangeFilter));
        }

        const prevPath = history?.location?.state?.prevPath;
        const search = searchParams.toString();
        history.replace({ 
            search: search ? `?${search}` : "",
            state: { prevPath }
        });
    }, [tabValue, store, vendors, dateRangeFilter]);

	useEffect(() => {
		if (queryParams.has('clear')) {
			clearSearch();
			setSearchField(0);
		}
	}, [queryParams]);

	const dateRangeFilterArray = useMemo(() => {
		return [
			{
				startDate: dateRangeFilter.from,
				endDate: dateRangeFilter.to,
				key: 'selection',
			},
		];
	}, [dateRangeFilter]);

	const generateData = () => {
		const filteredData = grnsList.filter((obj) => {
			return (
				obj?.[FIELD_MAP?.[SEARCH_BY?.options?.[searchField]?.key]]
					?.toLowerCase()
					?.indexOf(query.trim().toLowerCase()) !== -1
			);
		});
		return filteredData;
	};

	const handleSearchBy = (index) => {
		setSearchField(index);
	};

	// // userAcess
	// useEffect(() => {
	// 	fetchUserList();
	// }, []);
	// // function page role of user access
	// const map = new Map(userList.map((item) => [item.value, item.label]));
	// const role = Object.keys(userConfig?.userRoles);
	// const userRoles = [];
	// role.forEach((ele) => {
	// 	userRoles.push(ele);
	// });

	// const pageRole = navState['/analytics/po']?.submenu['/analytics/list/grn']?.rolesMapped;
	// const findMatchingRole = userRoles.filter((ele) => pageRole.includes(Number(ele)));
	// const roleDetails = findMatchingRole.map((role) => {
	// 	const key = Number(role);
	// 	const name = map.get(key);
	// 	return {
	// 		role: key, name,
	// 	};
	// });

	// const isInternal = roleDetails.some((item) => item.name === 'Internal' || item.name === 'CXO Office');
	// const access = findMatchingRole.length > 0 ? userConfig?.userDetails?.level : false;
	// let permit;
	// if (access === false) {
	// 	permit = false;
	// } else {
	// 	permit = isInternal && access !== 1;
	// }
	const handleListFetch = async (newQueryParams) => {
		setIsloading(true);
		try {
			const responseData =
		await inventoryAnalyticsServices.fetchInvTransRecord({
			req: {
				data: {
					filters: {
						fromStoreId: navSelectedStore?.value?.id,
						vendors: newQueryParams?.vendors ?? vendors,
						storeId: newQueryParams?.storeId ?? store,
						dateRangeFilter : {  
							from: formatDate(newQueryParams?.dateRangeFilter?.from ?? dateRangeFilter.from),
							to: formatDate(newQueryParams?.dateRangeFilter?.to ?? dateRangeFilter.to),
						},
					},
					type: 'GRN',
				},
			},
		});
			if (responseData?.statusCode === 200) {
				setGrnsList(responseData?.data);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setIsloading(false);
		}
	};

	const handleAiMachineFetch = async (newQueryParams) => {
			const req = {
				data: {
					storeId: newQueryParams?.storeId ?? store,
					vendors: newQueryParams?.vendors ?? vendors,
					dateRangeFilter : {  
						from: formatDate(newQueryParams?.dateRangeFilter?.from ?? dateRangeFilter.from),
						to: formatDate(newQueryParams?.dateRangeFilter?.to ?? dateRangeFilter.to),
					},
					qcType: 'INWARD',
				},
			}
			try {
				const res = await grnQcLogService.fetchAiSupervisorScans(req);
				if(res?.statusCode === 200) {
					setGrnsList(res?.data);
					setIsDataFetched(true);
					notificationServices.generateNotification({
						type: 'success',
						message: 'Data fetched successfully',
					});
				}
			} catch (error) {
				notificationServices.generateNotification({
					type: 'error',
					message: error,
				});
			}
	};

	const handleOpenSummary = (item) => {
		togglePOItemsModal(true, {
			heading: `Items of GRN #${item?.id}`,
			...item,
			excludedColumns: [
				'Packaged',
				'Dispatched',
				'Returned Quantity',
				'Received',
				'Fulfilled',
				'From Process',
				'PO Id',
				'Returned',
				'Quantity',
				'Used For',
			],
		});
	};
	const handleOpenInvoice = (item) => {
		window.open(item?.uploadedDoc, '_blank');
	};
	const handleOpenBills = (item) => {
		window.open(`${urls.EXTENSION}${urls.BILLS}?grnId=${item?.id}`);
	};

	const SPECIAL_ITEMS = {
		'Grn Id': (id, item) => {
			return <div>{id}</div>;
		},
		'Vendor Name': (id, item) => {
			return <div>{item}</div>;
		},
		'Grn Date': (id, item) => {
			if (!item) return '-';
			try {
				const date = new Date(item);
				if (isNaN(date.getTime())) return '-'; // Check if date is valid
				return (
					<div>
						<p className='font-medium'>
							{date.toLocaleDateString()}
						</p>
					</div>
				);
			} catch (error) {
				return '-';
			}
		},
		'GRN Start Time': (id, item) => {
			if (!item) return '-';
			try {
				const date = new Date(item);
				if (isNaN(date.getTime())) return '-'; // Check if date is valid
				return (
					<div>
						<p className='font-medium '>
							{date.toLocaleTimeString()}
						</p>
					</div>
				);
			} catch (error) {
				return '-';
			}
		},
		'Po Id': (id, item) => {
			return <div>{item}</div>;
		},
		'Fullfilled Quantity': (id, item) => {
			const fullfilledQuantity = grnsList?.find((data) => data?.id === id)?.fullfilled || 0;
			const totalQuantity = grnsList?.find((data) => data?.id === id)?.totalQuantity || 0;
			const percentage = totalQuantity ? ((fullfilledQuantity / totalQuantity) * 100).toFixed(2) : 0;
			return (
				<div>
					<p className=' font-medium '>{percentage}%</p>
				</div>
			);
		},
		'View Docs': (id, item) => {
			const items = grnsList?.find((ele) => ele?.id === id);
			return (
				<div 
					className='flex flex-col gap-3 justify-center items-center' 
					onClick={(e) => e.stopPropagation()}
				>
					{items?.uploadedDoc &&
						<Chip
							size='small'
							className='w-[100px] p-[15px] font-bold'
							label='Invoice'
						onClick={(e) => {
							e.stopPropagation();
							handleOpenInvoice(items);
						}}
						/>
					}
						<Chip
							size='small' 
							className=' w-[100px] p-[15px] font-bold'
							label='Bills'
						onClick={(e) => {
							e.stopPropagation();
							handleOpenBills(items);
						}}
					/>
					<Chip
						size='small'
						className='w-[100px] p-[15px] font-bold'
						label='Summary'
						onClick={(e) => {
							e.stopPropagation();
							handleOpenSummary(items);
						}}
					/>
				</div>
			);
		},
	};

	const handleAddClick = () => {
		history.push(createPath);
	};

	const viewMoreOptionsList = {
		'View Items': (item, cb) => {
			togglePOItemsModal(true, {
				heading: `Items of GRN #${item?.id}`,
				...item,
				excludedColumns: [
					'Packaged',
					'Dispatched',
					'Returned Quantity',
					'Received',
					'Fulfilled',
					'From Process',
					'PO Id',
					'Returned',
					'Quantity',
					'Used For',
				],
			});
			cb();
		},
		'View Bills': (item, cb) => {
			window.open(`${urls.EXTENSION}${urls.BILLS}?grnId=${item?.id}`);
			cb();
		},
		'View GRN': (item, cb) => {
			if (!item?.grnURL) {
				notificationServices?.generateNotification({
					type: 'error',
					message: 'No GRN File Generated',
				});
			} else {
				window.open(item?.grnURL, '_blank');
			}
			cb();
		},
	};

	
    const handleExcelDownload = () => {
		alert('handleExcelDownload');
	};
  
	const handleTabChange = (value) => {
		setTabValue(value);
		if(value === 'aiMachineLogs') {
			handleAiMachineFetch();
		}else {
			handleListFetch();
		}
	};

	useEffect(() => {
        const newQueryParams = {};
        if (queryParams.size > 0) {
            queryParams.forEach((value, key) => {

                try {
                    switch(key) {
                        case 'tabValue':
                            setTabValue(value);
                            newQueryParams.tabValue = value;
                            break;
                        case 'storeId':
                            setStore(JSON.parse(value));
                            newQueryParams.storeId = JSON.parse(value);
                            break;
                        case 'vendor':
                            setVendors(JSON.parse(value));
                            newQueryParams.vendors = JSON.parse(value);
                            break;
                        case 'dateRangeFilter':
                            const parsedDate = JSON.parse(value);
                            setDateRangeFilter({
                                active: true,
                                from: new Date(parsedDate.from) || new Date(),
                                to: new Date(parsedDate.to) || new Date(),
                            });
                            newQueryParams.dateRangeFilter = parsedDate;
                            break;
                        default:
                            break;
                    }
                } catch (error) {
                    console.error(`Error parsing ${key}:`, error);
                }
            });
        }
        if (newQueryParams?.tabValue === 'grnLogs') {
            handleListFetch(newQueryParams);
        }else if (newQueryParams?.tabValue === 'aiMachineLogs') {
            handleAiMachineFetch(newQueryParams);
        } else {
            handleListFetch(newQueryParams);
        }
    }, []);

	return (
			<div className={styles.root}>
			  <BackdropLoader showProgress isLoading={isloading} />
			  <POItemsModal />
{/* 		  
			  {userPermissions.INTERNAL && (
				<div className="flex justify-end mb-4">
				  <Button 
					onClick={handleAddClick} 
					className="bg-yellow-500 hover:bg-yellow-600 text-black font-medium py-2 px-4 rounded-md transition-colors"
				  >
					Add GRN
				  </Button>
				</div>
			  )} */}
		  
			  <div className='space-y-4 p-2'>
				<div className="flex justify-between items-center">
				  <SearchBar
					value={query}
					placeholder={PLACE_HOLDERS[searchField]}
					handleInput={handleSearch}
					handleChange={handleSearchBy}
					searchBy={
					  SEARCH_BY != null
						? {
							...(SEARCH_BY ?? {}),
							current: searchField,
						  }
						: null
					}
					className="w-full max-w-md"
				  />
		  
				  <div className="flex items-center space-x-4">
					<span className="text-gray-700">
					  Total: {generateData().length}
					</span>
					
					<Calender 
					  value={dateRangeFilterArray} 
					  setValue={setDate} 
					  className="w-64"
					/>
					
					<div 
					  onClick={exportQCLogs} 
					  className="cursor-pointer hover:opacity-75 transition-opacity"
					>
					  <img src={excel} alt='excel' className="h-6 w-6" />
					</div>
					
					<IconButton 
					  type='submit'
					  className={`	
						${styles.btn} 
						${shouldAnimate ? styles?.animate : ''} 
						${isDataFetched ? styles?.disabled : ''}
					  `}
					  onClick={() => { 
						tabValue === 'grnLogs' 
						  ? handleListFetch() 
						  : handleAiMachineFetch()
					  }}
					>
					  <ArrowCircleRight />
					</IconButton>
				  </div>
				</div>
		  
				<div className="flex items-center space-x-4">
				  <SelectPicker 
					data={stores?.map((store) => ({ 
					  label: store.name, 
					  value: store.id, 
					}))}
					value={store}
					placeholder='Select Store'
					onChange={(value) => { 
					  setStore(value); 
					}}
					className="w-full max-w-xs"
				  />
				  
				  <CheckPicker 
					data={vendorsList?.map((vendor) => ({ 
					  label: vendor.name, 
					  value: vendor.id, 
					}))}
					value={vendors}
					placeholder='Select Vendor'
					onChange={(value) => { 
					  setVendors(value); 
					}}
					className="w-full max-w-xs"
				  />
				</div>
			  </div>
		  
			  <Tab 
				tabValue={tabValue}
				handleTabChange={handleTabChange}
				tabList={tabList} 
			  />

			{tabValue === 'grnLogs' && 
                <GrnTable
                    data={generateData()}
                    colMap={COLMAP}
                    special={SPECIAL_COLUMNS}
                    specialMappings={SPECIAL_ITEMS}
                    tableType='grnLogs'
					filters={{
                        storeId: store,
                        vendors: vendors,
                        dateRangeFilter : {
                            from: formatDate(dateRangeFilter.from),
                            to: formatDate(dateRangeFilter.to),
                        },
                    }}
                />
            }
            {tabValue === 'aiMachineLogs' && 
                <GrnTable
                    data={generateData()}
                    colMap={aiMachineColMap}
                    special={aiMachineSpecialColumns}
                    specialMappings={aiMachineSpecialColumnsMappings}
                    tableType='aiMachineLogs'
					filters={{
                        storeId: store,
                        vendors: vendors,
                        dateRangeFilter : {
                            from: formatDate(dateRangeFilter.from),
                            to: formatDate(dateRangeFilter.to),
                        },
                    }}
                />
            }
			</div>
		  );
}
const mapStateToProps = (state) => {
	const { userPermissions, navState } = state.navigation;
	const { userList } = state?.auth;
	const stores = state?.form?.STORES?.data?.LIST;
	const { LIST: vendorsList } = state.form.ALL_VENDORS.data;
	const { query: searchQuery, searchBy } =
		state.analytics.AI_PRODUCTION_ANALYSIS.SEARCH_DATA;
	return {
		userPermissions,
		navState,
		userList,
		stores,
		vendorsList,
		searchQuery,
		searchBy,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleListModal,
	togglePOItemsModal: modalActions.togglePOItemsModal,
	togglePaymentModal: modalActions.togglePaymentModal,
	fetchUserList: authActions?.fetchUserList,
	fetchStoresList: formActions.gateway,
	fetchAllVendors: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(GRNsPage);
