/* eslint-disable*/
import { Tabs, TabsList, TabsTrigger } from "../ui/tabs";

const Tab = (props) => {
    const {tabValue, handleTabChange, tabList} = props;
    return (
    <Tabs
    value={tabValue}
    onValueChange={(value) => handleTabChange(value)}
    defaultValue={tabValue}
    className='p-2 pb-0'
>
    <TabsList className='h-auto w-auto inline-flex'>
        {tabList.map((tab) => ( 
        <TabsTrigger
            value={tab.value}
             className='px-4 data-[state=active]:h-[2.5rem] data-[state=active]:bg-yellow-500 data-[state=active]:text-black data-[state=active]:m-1 data-[state=active]:rounded-md'
        >
            {tab.label}
        </TabsTrigger>
        ))}
        </TabsList>
    </Tabs>
    );
};

export default Tab;
