/* eslint-disable no-shadow */
/* eslint-disable no-empty */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-unused-vars */
/* eslint-disable*/
// import { Button } from '@mui/material';
import { connect } from 'react-redux';
import Chip from '@mui/material/Chip';
import DownloadIcon from '@mui/icons-material/Download';
import {
	useCallback, useEffect, useState
} from 'react';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import {
	analyticsAction, formActions, modalActions
} from '../../../Actions';
import { ListTable } from '../../../Components/ListTable';
import { POItemsModal } from '../../../Components/Modals/POItemsModal';
import styles from './FMSOrdersListPage.module.scss';
import { VanImageModal } from '../../../Components/Modals/VanImageModal';
import assetServices from '../../../Services/AssetServices/asset.services';
import { notificationServices } from '../../../Services';
import inventoryAnalyticsServices from '../../../Services/Analytics Services/inventory-analytics.services';
import { AlertModal } from '../../../Components/Modals';
import ListFinderHeader from '../../../Components/ListFinderHeader/ListFinderHeader';
import { urls } from '../../../Constants';
import { Button } from '@/Components/ui/button';
import { handleOrderStatus, handleResponseStreamDownload, useSearch } from '@/Helpers';
import analyticsServices from '@/Services/analytics.services';
import { useAuth } from '@/Components/Auth/Login/auth';
import userRolesAndPermissions from '@/Services/userRolesAndPermissions.services';
import authActions from '@/Actions/auth.actions';
import SearchBar from '@/Components/SearchBar/SearchBar';

const COLMAP = {
	'PO Id': 'id',
	'Production ID': 'productionPlan',
	'Production Plan Name': 'productionPlanDescription',
	'To Be Used On': 'toBeUsedOn',
	Status: 'orderStatus',
	'Deliver To Process': 'fromProcessName',
	'Deliver From': 'fromStoreName',
	'Deliver To': 'storeName',
	'Deliver From Process': 'toProcessName',
	'Order Name': 'clientPoId',
	'Order Type': 'subType',
	Shift: 'shiftName',
	// 'Quantity Required': 'quantityRequired',
	// 'Total Produced': 'totalProduced',
	'Avg. Hourly Scans': 'avgScans',
	'CREATED ON': 'createdAt',
	'SCHEDULED FOR': 'scheduledAt',
	'SCHEDULED END': 'scheduledEnd',
};

const TABLE_COLUMNS = [
	{
		title: 'PO Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Production ID',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Production Plan Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'To Be Used On',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Order Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Order Type',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Shift',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Status',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Deliver From Process',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Deliver From',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Deliver To Process',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Deliver To',
		config: {
			type: 'DATA',
		},
	},
	// {
	// title: 'Quantity Required',
	// config: {
	// type: 'DATA',
	// },
	// },
	// {
	// title: 'Total Produced',
	// config: {
	// type: 'DATA',
	// },
	// },
	{
		title: 'Avg. Hourly Scans',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'CREATED ON',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'SCHEDULED FOR',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'SCHEDULED END',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [];

const ACTION_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'VIEW',
		},
	},
	{
		title: 'Download',
		config: {
			type: 'VIEW',
		},
	},
	{
		title: 'ACTION',
		config: {
			type: 'CANCEL',
		},
	},
];

const FILTERS = ['processType', 'filteredStore', 'dateRange', 'productionPlan'];

const dateTypeArr = [
	{
		label: 'SCHEDULED FOR',
		value: 'scheduled',
	},
	{
		label: 'CREATED ON',
		value: 'ordered',
	},
	{
		label: 'TO BE USED ON',
		value: 'toBeUsedOn',
	},
];

const SEARCH_BY = {
	options: [
		{
			key: 'poId',
			title: 'PO Id',
		},
	],
};

function FMSOrdersListPage(props) {
	const {
		data, toggleModal, toggleAlert, fetchOrdersList, navState, userList, fetchUserList,
	} = props;
	const [update, setUpdate] = useState(-1);
	const history = useHistory();
	const location = useLocation();
	const [exportFilter, setExportFilter] = useState({});
	const searchQuery = '';
	const searchBy = 'poId';

	const { userConfig } = useAuth();
	// useEffect(() => {
	// 	fetchUserList();
	// }, []);

	// // function page role of user access
	// const map = new Map(userList.map((item) => [item.value, item.label]));
	// const role = Object.keys(userConfig?.userRoles);
	// const userRoles = [];
	// role.forEach((ele) => {
	// 	userRoles.push(ele);
	// });
	// const pageRole = navState['/analytics/fms/orders']?.submenu['/analytics/list/fms']?.rolesMapped;
	// const findMatchingRole = userRoles.filter((ele) => pageRole.includes(Number(ele)));
	// const roleDetails = findMatchingRole.map((role) => {
	// 	const key = Number(role);
	// 	const name = map.get(key);
	// 	return {
	// 		role: key, name,
	// 	};
	// });
	// const isInternal = roleDetails.some((item) => item.name === 'Internal' || item.name === 'CXO Office');
	// const access = findMatchingRole.length > 0 ? userConfig?.userDetails?.level : false;
	// let permit;
	// if (access === false) {
	// 	permit = false;
	// } else {
	// 	permit = isInternal && access !== 1;
	// }

	// end user Acces
	const handleReopenPo = (id, clientPoId) => {
		// eslint-disable-next-line no-console
		(async () => {
			const notificationId = notificationServices.generateNotification({
				type: 'loading',
				message: `Reopening Po ${clientPoId || ''}`,
			});
			try {
				const resp = await inventoryAnalyticsServices.changeOrderStatus(
					{
						params: {
							poId: id,
							status: 'REOPEN',
						},
					}
				);
				notificationServices.generateNotification({
					type: 'success',
					message: resp?.status,
					config: {
						id: notificationId,
					},
				});
				setUpdate((prev) => {
					return prev + 1;
				});
			} catch (error) {
				notificationServices.generateNotification({
					type: 'error',
					message: error,
					config: {
						id: notificationId,
					},
				});
			}
		})();
	};

	const handleCancelPo = (id, clientPoId) => {
		// eslint-disable-next-line no-console
		(async () => {
			const notificationId = notificationServices.generateNotification({
				type: 'loading',
				message: `Cancelling Po ${clientPoId || ''}`,
			});
			try {
				const resp = await inventoryAnalyticsServices.changeOrderStatus(
					{
						params: {
							poId: id,
							status: 'CANCEL',
						},
					}
				);
				notificationServices.generateNotification({
					type: 'success',
					message: resp?.status,
					config: {
						id: notificationId,
					},
				});
				setUpdate((prev) => {
					return prev + 1;
				});
			} catch (error) {
				notificationServices.generateNotification({
					type: 'error',
					message: error,
					config: {
						id: notificationId,
					},
				});
			}
		})();
	};

	const handleClosePO = async (id, clientPoId) => {
		const notificationId = notificationServices.generateNotification({
			type: 'loading',
			message: `Closing Po ${clientPoId || ''}`,
		});
		try {
			const resp = await inventoryAnalyticsServices.generateGrn({
				params: {
					poId: id,
				},
			});
			notificationServices.generateNotification({
				type: 'success',
				message: resp?.status,
				config: {
					id: notificationId,
				},
			});
			setUpdate((prev) => {
				return prev + 1;
			});
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error,
				config: {
					id: notificationId,
				},
			});
		}
	};

	const handleViewClick = (item) => {
		toggleModal(true, {
			heading: `Items ${
				item?.productionPlanDescription
					? `of ${item?.productionPlanDescription} - ${
							item?.toProcessName ?? ''
					  }`
					: ''
			}`,
			...item,
			excludedColumns: [
				'Packaged',
				'Dispatched',
				'Returned Quantity',
				'Received',
				'Returned',
				'Item Price',
				'Item Sub Total',
				'Tax Percentage',
				'Item Tax',
				'Tax value',
				'Item Amount',
				'Total Item Amount',
				'Discount %',
				'Used For',
			],
		});
	};

	const SPECIAL_ITEMS = {
		ACTION: (itemId) => {
			const item = data?.find((poItem) => {
				return poItem?.id === itemId;
			});
			if (item?.orderStatus === 'FULFILLED' || item?.orderStatus === 'CLOSED') {
				return (
					<div
						style={{
							width: '125px',
						}}
					>
						<Button
							variant='secondary'
							onClick={() => {
								toggleAlert(true, {
									heading: 'Re-Open',
									handleConfirm: () => {
										handleReopenPo(
											itemId,
											item?.clientPoId
										);
										toggleAlert(false);
									},
									colorReverse: true,
									cancelText: 'No',
									confirmText: 'Yes',
									message: `Are you sure you want to Re-Open ${
										item?.clientPoId || ''
									}`,
								});
							}}
						>
							RE-OPEN
						</Button>
					</div>
				);
			}
			return (
				item?.orderStatus === 'PENDING' && (
					<div
						style={{
							width: '125px',
							display: 'flex',
							flexDirection: 'column',
							gap: '.5rem',
						}}
					>
						<Button
							variant='secondary'
							onClick={() => {
								toggleAlert(true, {
									heading: 'Close',
									handleConfirm: () => {
										handleClosePO(itemId, item?.clientPoId);
										toggleAlert(false);
									},
									colorReverse: true,
									cancelText: 'No',
									confirmText: 'Yes',
									height: 'fit-content',
									message: `Are you sure you want to close Production Plan: ${
										item?.clientPoId || ''
									}?`,
								});
							}}
						>
							CLOSE
						</Button>
						<Button
							className='danger-btn'
							onClick={() => {
								toggleAlert(true, {
									heading: 'Cancel',
									handleConfirm: () => {
										handleCancelPo(
											itemId,
											item?.clientPoId
										);
										toggleAlert(false);
									},
									colorReverse: false,
									cancelText: 'No',
									confirmText: 'Yes',
									message: `Are you sure you want to Cancel ${
										item?.clientPoId || ''
									}`,
								});
							}}
						>
							Cancel
						</Button>
					</div>
				)
			);
		},
		Download: (itemId) => {
			const item = data?.find((poItem) => {
				return poItem?.id === itemId;
			});
			return (
				<div className={styles?.downloadDiv}>
					{item?.woDocUrl && (
						<Chip
							size='small'
							label='WO'
							onClick={() => {
								if (item?.woDocUrl) {
									window.open(item?.woDocUrl);
								}
							}}
							icon={<PictureAsPdfIcon />}
						/>
					)}
				</div>
			);
		},
	};

	const handleEditSubmit = async (editedValues) => {
		try {
			const response = await assetServices.editAssetTemp({
				data: editedValues,
				params: {
					type: 'PO',
				},
			});
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: response?.errorMessage ?? response?.message,
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error ?? error?.message,
			});
		}
	};

	const fetchFmsOrders = (filters) => {
		const req = {
			data: {
				filters: {
					dateRangeFilter:
						filters?.productionPlans?.length > 0
							? {}
							: {
								...filters?.dateRangeFilter,
								dateType: filters?.dateType,
							  },
					processType: filters?.processType,
					storeId: filters?.storeId,
					orderType: 'fms',
					productionPlanIds: filters?.productionPlans,
				},
			},
		};
		fetchOrdersList({
			entity: 'INVENTORY_ANALYTICS',
			method: 'FMS_ORDERS_LIST',
			req,
		});
	};

	const handleExcelDownload = useCallback((header, fileData, fileName) => {
		const commonColumns = [
			'PO ID',
			'ORDER TYPE',
			'PRODUCTION ID',
			'PRODUCTION PLAN NAME',
			'FROM PROCESS',
			'TO PROCESS',
			'STORE',
			'AVG. HOURLY SCANS',
			'CREATED ON',
			'SCHEDULED FOR',
			'SCHEDULED END',
		];
		const downloadBtn = document.createElement('a');

		const csvData = [[...commonColumns, ...header], ...fileData];

		const csv = `${csvData
			.map((e) => {
				return e.join(',');
			})
			.join('\n ')}`;

		const encodedValue = new Blob([csv], {
			type: 'text/csv',
		});
		downloadBtn.href = window.URL.createObjectURL(encodedValue);
		downloadBtn.target = '_blank';
		downloadBtn.download = fileName;
		downloadBtn.click();
	}, []);

	const exportItemsWiseDataOld = () => {
		const headers = [
			'PRODUCT NAME',
			'CATEGORY NAME',
			'VARIANT',
			'UNIT',
			'ORDERED QUANTITY',
			'FULFILLED',
			'FROM PROCESS',
		];
		const totalValues = [];
		data?.forEach((ele) => {
			ele?.items?.forEach((item) => {
				totalValues?.push([
					ele?.id ?? '-',
					ele?.subType,
					ele?.productionPlan,
					ele?.productionPlanDescription,
					ele?.fromProcessName ?? '-',
					ele?.toProcessName ?? '-',
					ele?.storeName,
					ele?.avgScans ?? '-',
					moment(ele?.createdAt).format('YYYY-MM-DD') ?? '-',
					ele?.scheduledAt,
					ele?.scheduledEnd,
					item?.productName?.replaceAll(',', "'"),
					item?.categoryName,
					item?.variantName,
					item?.unit,
					item?.qty,
					item?.fulfilled,
					item?.fromProcess ?? '-',
				]);
			});
		});
		handleExcelDownload(headers, totalValues, 'FMS_ORDER');
	};

	const exportItemsWiseData = async () => {
		const req = {
			data: {
				export: true,
				filters: {
					dateRangeFilter:
						exportFilter?.productionPlans?.length > 0
							? {}
							: {
								...exportFilter?.dateRangeFilter,
								dateType: exportFilter?.dateType,
							  },
					processType: exportFilter?.processType,
					storeId: exportFilter?.storeId,
					orderType: 'fms',
					productionPlanIds: exportFilter?.productionPlans,
				},
			},
		};
		try {
			const response = await analyticsServices.exportFmsOrders({
				req: {
					...req,
				},
			});
			handleResponseStreamDownload(
				response,
				'FMS_ORDERS.csv'
			);
		} catch (error) {

		}
	};

	return (
		<div className={styles.root}>
			<AlertModal />
			<POItemsModal />
			<VanImageModal handleEditSubmit={handleEditSubmit} />
			<div className={styles.header}>
				<ListFinderHeader
					dateTypeArr={dateTypeArr}
					FILTERS={FILTERS}
					headerOptions={{
						title: 'Factory Management Orders  ',
						generateButton:{
							title: 'Create Transfer order',
							onClick: () => {
								const redirectUrl = `${urls.EXTENSION}${
									urls.CREATE_PURCHASE_ORDER
								}?typeId=${5}`;
								history.push(redirectUrl, {
									prevPath: location?.pathname + location?.search,
								});
							},
						},
						generateButton2:{
							title: 'Create Work Order',
							onClick: () => {
								const redirectUrl = `${urls.EXTENSION}${
									urls.CREATE_PURCHASE_ORDER
								}?typeId=${6}`;
								history.push(redirectUrl, {
									prevPath: location?.pathname + location?.search,
								});
							},
							color: '#663399',
						}
					}}
					fetchOrders={fetchFmsOrders}
					allowFutureDate
					updateState={update}

					filterNames={{
						processType: 'Deliver from Process / Work Order For',
					}}
					setExportFilter={setExportFilter}
					orderText
				/>
			</div>
			<div
				style={{
					width: '100%',
					overflow: 'auto',
					height: 'calc(100vh - 13rem)',
				}}
			>
				<ListTable
					fixedHeaderTop={0}
					hasDateRange
					showTotal
					onExportClick={exportItemsWiseDataOld}
					colMap={COLMAP}
					columns={TABLE_COLUMNS}
					data={data?.map((ele) => {
						return {
							...ele,
							orderStatus: handleOrderStatus(ele?.orderStatus),
							createdAt: moment(ele?.createdAt).format(
								'YYYY-MM-DD'
							),
						};
					})}
					actions={[...SPECIAL_COLUMNS, ...ACTION_COLUMNS]}
					special={SPECIAL_ITEMS}
					handleViewClick={handleViewClick}
				/>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	const stores = state?.form?.STORES?.data?.LIST;
	const { navState, userPermissions } = state?.navigation;
	const { FMS_ORDERS_LIST: data } = state?.analytics?.INVENTORY_ANALYTICS;
	const { userList } = state?.auth;
	return {
		data,
		stores,
		navState,
		userPermissions,
		userList,
	};
};

const mapDispatchToProps = {
	fetchStoresList: formActions.gateway,
	setDateRangeFilter: analyticsAction.setDateRangeFilter,
	toggleModal: modalActions.togglePOItemsModal,
	toggleAlert: modalActions.toggleAlert,
	toggleVanImageModal: modalActions.toggleVanImageModal,
	fetchOrdersList: analyticsAction.gateway,
	fetchUserList: authActions?.fetchUserList,
};

export default connect(mapStateToProps, mapDispatchToProps)(FMSOrdersListPage);
