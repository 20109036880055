import { calculateShelfLife } from '@/Helpers';

/* eslint-disable*/
function QualityInfo(props) {
	const { details, handleSave, handleReject } = props;
	return (
		<div className='p-6'>
			<div className='flex gap-4 items-center justify-between align-center'>
                <div className='flex gap-5 items-center align-center'>
                    <h2 className='text-xl font-semibold'>
                        {details?.productName}
                    </h2>
				<span className='text-gray-600'>Sample Quantity: <strong>{details?.sampleQuantity ? `${details?.sampleQuantity} %` : '-'}</strong>{details?.sampleQuantity ? `(Quantity: ${details?.quantity ? `${details?.quantity} ${details?.unit}` : '-'})` : null}</span>
                </div>
                {details?.status === 'QC_APPROVAL_PENDING' && (
                    <div className='flex gap-2'>
                        <button className='bg-red-600   p-2 text-white px-4 py-2 rounded-md' onClick={handleReject}>Reject Qc</button>
                        <button className='bg-green-600 p-2 text-white px-4 py-2 rounded-md' onClick={handleSave}>Pass Qc</button>
                    </div>
                )}
			</div>

			<div className='grid grid-cols-4 gap-4 mt-4 text-gray-700'>
				<div>
					<p className='text-sm font-medium'>GRN ID</p>
					<p className='font-semibold'>{details?.grnId}</p>
				</div>
				<div>
					<p className='text-sm font-medium'>Vendor Name</p>
					<p className='font-semibold'>{details?.vendorName}</p>
				</div>
				<div>
					<p className='text-sm font-medium'>Store Name</p>
					<p className='font-semibold'>{details?.storeName}</p>
				</div>
                <div>
					<p className='text-sm font-medium'>Approval Status</p>
					<p className={`font-semibold text-lg ${details?.status === 'GRN' ? 'text-green-600' : details?.status === 'QC_APPROVAL_PENDING' ? 'text-orange-600' : details?.status === 'QC_PENDING' ? 'text-blue-600' : 'text-red-600'}`}>{details?.status === 'GRN' ?  'Pass' : details?.status === 'QC_APPROVAL_PENDING' ? 'Approval Pending' : details?.status === 'QC_PENDING' ? 'Pending' : 'Fail'}</p>
				</div>
				<div>
					<p className='text-sm font-medium'>PO ID</p>
					<p className='font-semibold'>{details?.clientPoId}</p>
				</div>
				<div>
					<p className='text-sm font-medium'>Batch Name</p>
					<p className='font-semibold'>{details?.batchName}</p>
				</div>
				<div>
					<p className='text-sm font-medium'>Completion Date</p>
					<p className='font-semibold'>{details?.completionDate}</p>
				</div>
                <div>
					<p className='text-sm font-medium'>Time Stamp</p>
					<p className='font-semibold'>{details?.timeStamp}</p>
				</div>
				<div>
					<p className='text-sm font-medium'>Shelf life at Inwarding</p>
					<p className='font-semibold'>{calculateShelfLife(details?.manufactureDate, details?.expiryDate)}%</p>
				</div>
				<div>
					<p className='text-sm font-medium'>Manufacturing Date</p>
					<p className='font-semibold'>{details?.manufactureDate}</p>
				</div>
				<div>
					<p className='text-sm font-medium'>Expiry Date</p>
					<p className='font-semibold'>{details?.expiryDate}</p>
				</div>
				<div>
					<p className='text-sm font-medium'>Quantity per unit</p>
					<p className='font-semibold'>{details?.quantityPerUnit}</p>
				</div>
			</div>
		</div>
	);
}

export default QualityInfo;