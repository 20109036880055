/* eslint-disable */
import { useEffect, useRef, useState } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	Paper,
	Button,
	Box,
	Typography,
    TableRow,
} from '@mui/material';
import { inputHelper, useQuery } from '@/Helpers';
import RowComponent from './rowComponent';
import { notificationServices } from '@/Services';
import masterMenuServices from '@/Services/ProductSpecificationParamServices/productMasterMenu.services';
import { AutoSizer, List } from 'react-virtualized';
import 'react-virtualized/styles.css';

function SpecificationParameters() {
	const [parameters, setParameters] = useState([]);
    const [list, setList] = useState([]);
    const [valueParam, setValueParam] = useState(false);
    const [focusRow, setFocusRow] = useState(null);

    const prevLength = useRef(0);

    const fetchList = async() => {
        try {
            const response = await masterMenuServices.productMasterMenuList();
            if(response?.statusCode === 200) {
                const data = response?.data;
                const newData = data.map(item => ({
                    ...item,
                    isEditing: false,
                    showSave: false,
                    showEdit: true,
                }));
                setParameters(newData);
                setList(newData.sort((a, b) => b.id - a.id));
            }
        } catch (error) {
            notificationServices?.generateNotification({
                type: 'error',
                message: 'Something went wrong',
            });
        }
    };

    useEffect(() => {
        fetchList();
		prevLength.current = parameters?.length;
	}, []);

    const addParametrs = () => {
		setParameters([{
			name: '',
			inputType: '',
			unit: '',
			imageRequired: false,
			rangeFlag: false,
			isEditing: true,
			showSave: true,
			showEdit: false
		}, ...parameters]);
	};

	const removeParameter = async(id) => {
		try{
            const req = {id};
            const response = await masterMenuServices.deleteProductMasterMenu(req);
            if(response?.statusCode === 200) {
                notificationServices?.generateNotification({
                    type: 'success',
                    message: 'Product Specification Parameter deleted successfully',
                });
                fetchList();
            }
        } catch (error) {
            notificationServices?.generateNotification({
                type: 'error',
                message: 'Unable to remove the item. Please try again later',
            });
        }
	};
	const cancleParameter = (index,id) => {
        if(prevLength.current === parameters?.length){
            setParameters(list.map(param => param.id !== id ? param : {
                ...param,
                isEditing: false,
                showSave: false,
                showEdit: true
            }));
        }else if(prevLength.current !== parameters?.length ){
            if(typeof index === 'number' && !id){
                const newParams = parameters.filter((_, i) => i !== index);
                setParameters(newParams);  
            }else if(typeof index === 'number' && id){
                setParameters(list.map(param => param.id !== id ? param : {
                    ...param,
                    isEditing: false,
                    showSave: false,
                    showEdit: true
                }));
        }
    }
	};

    const editParam = (id) => {
		const newParams = parameters.map(param => param.id === id ? {
			...param,
			isEditing: true,
			showSave: true,
			showEdit: false
		} : param);
        setParameters(newParams);
	};

	const saveParam = async(idx) => {
		const checkParam = parameters.find((param, index) => index === idx);
		if (!checkParam.name) {
        notificationServices?.generateNotification({
            type: 'error',
				message: 'Please enter the parameter name',
        });
        return;
       }
		if (!checkParam.inputType) {
        notificationServices?.generateNotification({
            type: 'error',
				message: 'Please select the type of parameter',
        });
        return;
       }
       if((checkParam.inputType === 'number'  || checkParam.inputType === 'decimal') && !checkParam.rangeFlag){
        notificationServices?.generateNotification({
            type: 'error',
            message: 'Please select the range of parameter',
        });
        return;
       }

		try {
            const req = {
                id: checkParam.id,
                name: checkParam.name,
                inputType: checkParam.inputType,
                unit: checkParam.unit,
                imageRequired: checkParam.imageRequired,
                rangeFlag: checkParam.inputType === 'number' || checkParam.inputType === 'decimal' ? checkParam.rangeFlag : false,
            }
            const response = checkParam.id ? await masterMenuServices.updateProductMasterMenu(req) 
                            : await masterMenuServices.addProductMasterMenu(req);
            if (response?.statusCode === 200) {
                notificationServices?.generateNotification({
                    type: 'success',
                    message: `Product Specification Parameter ${checkParam.id ? 'updated' : 'added'} successfully`,
                });
                fetchList();
            } else {
                notificationServices?.generateNotification({
                    type: 'error',
                    message: response?.errorMessage || 'Something went wrong',
                });
            }
        } catch (error) {
            notificationServices?.generateNotification({
                type: 'error',
                message: error || 'Something went wrong',
            });
        }
    }

	const handleInput = (e, index) => {
		const { fieldName, fieldValue, dataset } = inputHelper(e);
		setFocusRow(Number(dataset?.id || index));
		const newParams = parameters.map((param, idx) => idx === Number(dataset?.id || index) ? {
			...param,
			[fieldName]: fieldValue
		} : param);
            setParameters(newParams);
	};

	return (    
		<Box sx={{ 
			height: '100%',  // Changed from calc
			minHeight: '600px', // Add minimum height
			display: 'flex',
			flexDirection: 'column',
			overflow: 'hidden' // Prevent outer scrolling
		}}>
			<Box sx={{ 
				display: 'flex', 
				justifyContent: 'space-between', 
				alignItems: 'center', 
				p: 2,
				flexShrink: 0 // Prevent header from shrinking
			}}>
				<Typography variant='h6'>Quality Parameters List</Typography>
				<Button
					onClick={() => addParametrs()}
					sx={{
						backgroundColor: '#E2E8F0',
						'&:hover': { backgroundColor: '#CBD5E1' },
						color: 'black',
					}}
				>
					+ New Parameter
				</Button>
			</Box>
			<TableContainer 
				component={Paper} 
				sx={{ 
					flex: 1,
					display: 'flex',
					flexDirection: 'column',
					overflow: 'hidden', // Hide default scrollbar
					'& .MuiTable-root': {
						flexShrink: 0 // Prevent table header from shrinking
					}
				}}
			>
				<Table stickyHeader> {/* Make header sticky */}
					<TableHead>
						<TableRow>
							<TableCell 
								width="16.66%" 
								sx={{ 
									fontWeight: 'bold !important', 
									fontFamily: 'inherit', 
									textAlign: 'left',
									backgroundColor: '#fff' // Ensure header background
								}}
							>
								Parameter Name <span style={{color: 'red'}}>*</span>
							</TableCell>
							<TableCell width="16.66%" sx={{ fontWeight: 'bold !important', fontFamily: 'inherit', textAlign: 'left' }}>Input Type <span style={{color: 'red'}}>*</span></TableCell>
							<TableCell width="16.66%" sx={{ fontWeight: 'bold !important', fontFamily: 'inherit', textAlign: 'left' }}>Unit</TableCell>
							<TableCell width="16.66%" sx={{ fontWeight: 'bold !important', fontFamily: 'inherit', textAlign: 'left' }}>Image Required</TableCell>
							<TableCell width="16.66%" sx={{ fontWeight: 'bold !important', fontFamily: 'inherit', textAlign: 'left' }}>Range <span style={{color: 'red'}}>*</span></TableCell>
							<TableCell width="16.66%" sx={{ fontWeight: 'bold !important', fontFamily: 'inherit', textAlign: 'left' }}>Actions</TableCell>
						</TableRow>
					</TableHead>
				</Table>
				<div style={{ 
					flex: 1,
					position: 'relative', // For proper AutoSizer positioning
					'&::-webkit-scrollbar': {
						width: '8px',
						height: '8px'
					},
					'&::-webkit-scrollbar-track': {
						background: '#f1f1f1'
					},
					'&::-webkit-scrollbar-thumb': {
						background: '#888',
						borderRadius: '4px'
					},
					'&::-webkit-scrollbar-thumb:hover': {
						background: '#555'
					}
				}}> 
					<AutoSizer>
						{({ height, width }) => (
							<List
								height={height}
								width={width}
								rowCount={parameters.length}
								rowHeight={52}
								overscanRowCount={5} // Add to improve scroll performance
								rowRenderer={({ index, key, style }) => (
									<div 
										key={key} 
										style={{
											...style, 
											display: 'flex', 
											width: '100%',
											borderBottom: '1px solid rgba(224, 224, 224, 1)' // Add row separator
										}}
									>
										<RowComponent
											param={parameters[index]}
											index={index}
											editParam={editParam}
											saveParam={saveParam}
											handleInput={handleInput}
											removeParameter={removeParameter}
											cancleParameter={cancleParameter}
											valueParam={valueParam}
											setValueParam={setValueParam}
											focusRow={focusRow}
										/>
									</div>
								)}
							/>
						)}
					</AutoSizer>
				</div>
			</TableContainer>
		</Box>
	);
}

export default SpecificationParameters;
