/* eslint-disable no-const-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
import { connect } from 'react-redux';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { useState } from 'react';
import { modalActions } from '../../../Actions';
import { BaseModal } from '../BaseModal';
import styles from './AlertModal.module.scss';
import { notificationServices } from '../../../Services';
import { Button } from '@/Components/ui/button';
import { Input } from '@/Components/ui/input';
import { Label } from '@/Components/ui/label';

const AlertModal = (props) => {
	const { show, toggleAlert, data, type = 'alertModal', setCancle = () => {} } = props;
	const {
		rendererType = 'alertModal',
		nextLineMessage = false,
		extraData = {},
	} = data;
	const [inputState, setInputState] = useState('');
	const HEADING = `${data.heading}`;

	const handleClose = () => {
		toggleAlert(false);
		setInputState('');
		setCancle(true);
	};

	const handleConfirm = () => {
		if (data?.takeInput && !inputState) {
			notificationServices?.generateNotification({
				type: 'error',
				message: 'Please enter Mandatory Fields',
			});
			return;
		}
		if (data?.handleConfirm) {
			data?.handleConfirm(inputState);
			setInputState('');
			return;
		}
		props?.handleConfirm(data);
		setInputState('');
	};

	const buttons = () => {
		return (
			<div className='w-full flex gap-2'>
				<Button
					onClick={handleClose}
					variant='outline'
					className='flex-1'
				>
					{!data?.colorReverse && <ClearIcon />}
					{data?.cancelText ?? 'Cancel'}
				</Button>
				<Button
					onClick={handleConfirm}
					variant={!data?.colorReverse && 'destructive'}
					className={`flex-1 ${
						data?.colorReverse ? 'bg-uy-color' : ''
					}`}
					// color={data?.colorReverse ? 'error' : 'success'}
					disabled={data?.takeInput ? !inputState : false}
				>
					<CheckIcon />
					{data?.confirmText ?? 'Proceed'}
				</Button>
			</div>
		);
	};

	return (
		<BaseModal
			heading={HEADING || ''}
			height={data?.height ?? 'fit-content'}
			renderBtns={buttons}
			open={show && rendererType === type}
			handleClose={handleClose}
			width={data?.width ?? '24rem'}
		>
			<span
				style={{
					fontSize: '1rem',
					display: 'flex',
					flexDirection: 'column',
					gap: '0.5rem',
					padding: '0.5rem',
				}}
			>
				{data.message}
				{data?.message2 && (
					<span className='text-sm text-gray-500'>
						{data?.message2}
					</span>
				)}
			</span>
			{data?.takeInput && (
				<div className={styles['label-container']}>
					<Label className={styles.mandatory} htmlFor='input'>
						{data?.inputLabel}
						*
					</Label>
					<Input
						id='input'
						className='w-full'
						value={inputState}
						onChange={(e) => {
							setInputState(e?.target?.value);
						}}
					/>
				</div>
			)}
			{nextLineMessage && (
				<div className='w-full'>
					<div className='text-xs text-gray-500 flex flex-row justify-between px-4 py-2 bg-gray-100 rounded-md'>
						<div className='flex items-center gap-2'>
							<span className='text-sm font-medium'>Invoice Amount: </span>
							<span className='text-sm font-bold'>
								₹
								{extraData?.invoiceAmount}
							</span>
						</div>
						<div className='flex items-center gap-2'>
							<span className='text-sm font-medium'>Bill Amount: </span>
							<span className='text-sm font-bold'>
								₹
								{extraData?.billAmount}
							</span>
						</div>
					</div>
					<div className='text-[0.9rem] text-red-500'>
						Invoice Amount and Bill Amount are not matching, Credit/Debit
						note will be generated
					</div>
					<div className='flex items-center gap-1 justify-center'>
						<span className='text-xs flex-1'>
							Proceed with Bill Generation along with an Automated Credit/Debit Note Generation.
						</span>
					</div>
				</div>
			)}
			{data?.noteMessage && (
				<p
					style={{
						fontSize: '.95rem',
						color: 'red',
					}}
				>
					Note:
					{' '}
					{data?.noteMessage}
				</p>
			)}
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.alertModal;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleAlert: modalActions.toggleAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(AlertModal);
