/* eslint-disable */
/* eslint-disable operator-linebreak */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
/* eslint-disable object-curly-newline */
import { Add, ArrowCircleRight, Close } from '@mui/icons-material';
// eslint-disable-next-line object-curly-newline
import {
	Button,
	Checkbox,
	IconButton,
	MenuItem,
	Select,
	Tooltip,
} from '@mui/material';
// eslint-disable-next-line object-curly-newline
import { useReducer, useMemo, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
// eslint-disable-next-line object-curly-newline
import RangeSlider from 'rsuite/RangeSlider';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import DatePicker from 'rsuite/DatePicker';
import {
	formActions,
	analyticsAction,
	brandActions,
	navigationActions,
	modalActions,
} from '../../../../Actions';
import { classes, inputHelper, useQuery } from '../../../../Helpers';
import ExclusiveFilter from './GenericExclusiveFilter/GenericExclusiveFilter';
import { ListModal } from '../../../../Components/Modals';
import { Tag } from '../../../../Components/Tags';
import config from './config';
import styles from './GenericFilter.module.scss';
import { UYDateRange } from '../../../../Components/Forms/Payment Settlements/Calender/UYDateRange';
import { urls } from '@/Constants';
import { Label } from '@/Components/ui/label';

const identificationTypes = [
	{
		id: 1,
		type: 'AI',
	},
	{
		id: 2,
		type: 'Manual',
	},
	{
		id: 3,
		type: 'Both',
	},
];

const EMPTYVALUE = -999;

const defaultTimeTypeArr = [
	{
		label: 'Annotation Time',
		value: 'annotation',
	},
	{
		label: 'QC Time',
		value: 'qc',
	},
];

const typesList = [
	{
		name: 'QUALITY',
		value: 'QUALITY',
	},
	{
		name: 'IDENTITY',
		value: 'IDENTITY',
	},
];

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_BRAND':
			return {
				...state,
				brand: action.payload,
			};
		case 'SET_STORE':
			return {
				...state,
				store: action.payload,
			};
		case 'SET_PARENT_STORE':
			return {
				...state,
				parentStore: action.payload,
			};
		case 'SET_RAISED_BY_STORE':
			return {
				...state,
				raisedBy: action.payload,
			};
		case 'SET_PROCESS_TYPE':
			return {
				...state,
				processType: action.payload,
			};
		case 'SET_FEATURE_TYPE':
			return {
				...state,
				featureType: action.payload,
			};
		case 'SET_PAGE':
			return {
				...state,
				page: action.payload,
			};
		case 'SET_DATE':
			return {
				...state,
				date: action.payload,
			};
		case 'SET_DEFECTS':
			return {
				...state,
				defects: action.payload,
			};
		case 'SET_VENDORS':
			return {
				...state,
				vendors: action.payload,
			};
		case 'SET_IDENTIFICATION_TYPE':
			return {
				...state,
				identificationType: action.payload,
			};
		case 'SET_PRODUCT_CATEGORY':
			return {
				...state,
				productCategories: action.payload,
			};
		case 'SET_PRODUCTS':
			return {
				...state,
				products: action.payload,
			};
		case 'SET_TIME_TYPE':
			return {
				...state,
				timeType: action.payload,
			};
		case 'SET_DATE_RANGE':
			return {
				...state,
				dateRange: action.payload,
			};
		case 'SET_STATUS':
			return {
				...state,
				status: action.payload,
			};
		default:
			return state;
	}
};

const params = {
	STORE: 'storeId',
	BRAND: 'brand',
	VENDOR: 'vendor',
	PRODUCT: 'product',
	DEFECTS: 'defect',
	CATEGORY: 'productCategory',
	from: 'from',
	to: 'to',
	iType: 'itype',
	conf: 'conf',
};

const defaultStatusList = [
	{
		id: 'ALL',
		title: 'ALL',
	},
	{
		id: 'CANCELLED',
		title: 'CANCELLED',
	},
	{
		id: 'ABANDONED',
		title: 'ABANDONED',
	},
	{
		id: 'PENDING',
		title: 'PENDING',
	},
	{
		id: 'FULFILLED',
		title: 'CLOSED',
	},
];

const GenericFilter = (props) => {
	const [state, dispatch] = useReducer(reducer, config?.initialState);
	const [shouldAnimate, setShouldAnimate] = useState(true);

	const query = useQuery();
	const {
		store,
		parentStore,
		processType,
		raisedBy,
		brand,
		vendors,
		products,
		featureType,
		defects,
		identificationType,
		timeType,
		page: pageLimit,
		dateRange,
		productCategories,
		status,
		date,
	} = state;
	const {
		FILTERS,
		// hideProductSelection,
		toggleModal,
		productsList,
		fetchProducts,
		stores,
		filteredStores,
		onSubmit,
		fetchStoresList,
		brands,
		fetchBrands,
		changed = null,
		isInternal = true,
		productCategoriesList,
		fetchProductCategoriesList,
		vendorsList,
		fetchAllVendors,
		fetchDefects,
		defectsList,
		pageLabel,
		fetchProcessTypesList,
		processTypes,
		additionInfo = {},
		update = null,
		allStoreOption = true,
		storeTypes = '',
		setAutoStore = true,
		externalFilters = [],
		filterTypes,
		statusList = [],
		timeTypeArray,
		defaultState = {},
		displayNames = [],
	} = props;
	const reduxDispatch = useDispatch();
	const location = useLocation();
	const [sliderValue, setSliderValue] = useState([0.1, 1]);
	const currentPath = location?.pathname?.split(urls.EXTENSION);
	const navRequisites =
		reduxDispatch(
			navigationActions.getParentRequisites(currentPath?.[1])
		) ?? [];
	const navSelectedStore = navRequisites?.find((ele) => {
		return ele?.type === 'store';
	});
	const navStoreFilter = {
		id: navSelectedStore?.value?.id,
		type: 'billFrom',
		title: `By: ${navSelectedStore?.value?.name}`,
		removable: false,
	};
	const handleSubmit = (e) => {
		if (e) {
			e.preventDefault();
		}
		const req = config?.deserializer({
			store: store === EMPTYVALUE ? null : store,
			parentStore: parentStore === EMPTYVALUE ? null : parentStore,
			processTypes: processType === EMPTYVALUE ? null : processType,
			brand: brand === EMPTYVALUE ? null : brand,
			vendors,
			products,
			productCategories,
			identificationType,
			featureType,
			date,
			conf: {
				minConf: Math.min(sliderValue[0], sliderValue[1]),
				maxConf: Math.max(sliderValue[0], sliderValue[1]),
			},
			timeType,
			pageLimit,
			dateRange,
			status,
		});
		onSubmit(req);
	};
	const handleSubmitParams = (data) => {
		const req = config?.deserializer(
			{
				store: data?.store ?? (store === EMPTYVALUE ? null : store),
				parentStore:
					data?.parentStore ??
					(parentStore === EMPTYVALUE ? null : parentStore),
				processTypes:
					data?.processType ??
					(processType === EMPTYVALUE ? null : processType),
				brand: data?.brand ?? (brand === EMPTYVALUE ? null : brand),
				vendors: data?.vendors ?? vendors,
				products: data?.products ?? products,
				date: data?.date,
				productCategories: data?.productCategories ?? productCategories,
				identificationType:
					data?.identificationType ?? identificationType,
				featureType: data?.featureType ?? featureType,
				conf: data?.conf ?? {
					minConf: Math.min(sliderValue[0], sliderValue[1]),
					maxConf: Math.max(sliderValue[0], sliderValue[1]),
				},
				timeType: data?.timeType ?? timeType,
				pageLimit: data?.pageLimit ?? pageLimit,
				dateRange: data?.dateRange ?? dateRange,
				status: data?.status,
			},
			FILTERS,
			isInternal
		);
		onSubmit(req);
	};

	useEffect(() => {
		if (update !== null && update > 0) {
			handleSubmit();
		}
	}, [update]);

	useEffect(() => {
		if (shouldAnimate) {
			const st = setTimeout(() => {
				setShouldAnimate(false);
			}, 1500);
			return () => {
				clearTimeout(st);
			};
		}
		return () => {};
	}, [shouldAnimate]);

	const STORES = useMemo(() => {
		const tempStore = filteredStores?.map((tStore) => {
			return {
				id: tStore.id,
				title: tStore?.name ?? '',
				type: tStore?.type,
			};
		});
		if (allStoreOption) {
			tempStore?.unshift({
				id: EMPTYVALUE,
				title: 'ALL',
			});
		}
		return tempStore;
	}, [filteredStores]);

	useEffect(() => {
		if (changed != null) {
			const currentStore = STORES?.filter((ele) => {
				const types = filterTypes?.find((filterType) => {
					return filterType?.name === 'store';
				});
				if (!types?.restrictions) {
					return true;
				}
				return types?.restrictions?.split(',')?.includes(ele?.type);
			}).find((ele) => {
				return ele?.id === store;
			});
			if (!currentStore) {
				dispatch({
					type: 'SET_STORE',
					payload: null,
				});
			} else {
				setShouldAnimate(true);
			}
		}
	}, [changed, STORES]);

	useEffect(() => {
		setShouldAnimate(true);
	}, [state]);

	useEffect(() => {
		const objectKeys = Object.keys(defaultState);
		if (defaultState && objectKeys?.length) {
			objectKeys?.forEach((key) => {
				const defaultFilter = defaultState?.[key];
				if (key === 'STORE') {
					dispatch({
						type: 'SET_STORE',
						payload: defaultFilter,
					});
				}
				if (key === 'DATE_RANGE') {
					dispatch({
						type: 'SET_DATE_RANGE',
						payload: defaultFilter,
					});
				}
			});
		}
	}, [defaultState]);

	const addFilter = (filters, context) => {
		if (context.entity === 'PRODUCT') {
			dispatch({
				type: 'SET_PRODUCTS',
				payload: [...products, ...filters],
			});
		} else if (context.entity === 'VENDOR') {
			dispatch({
				type: 'SET_VENDORS',
				payload: [...vendors, ...filters],
			});
		} else if (context.entity === 'CATEGORY') {
			dispatch({
				type: 'SET_PRODUCT_CATEGORY',
				payload: [...productCategories, ...filters],
			});
		} else if (context.entity === 'DEFECT') {
			dispatch({
				type: 'SET_DEFECTS',
				payload: [...defects, ...filters],
			});
		}
	};

	const deleteFilter = (e) => {
		const { dataset } = inputHelper(e);

		const { id, entity } = dataset;
		if (entity === 'PRODUCT') {
			dispatch({
				type: 'SET_PRODUCTS',
				payload: products.filter((product) => {
					return product !== parseInt(id, 10);
				}),
			});
		} else if (entity === 'VENDOR') {
			dispatch({
				type: 'SET_VENDORS',
				payload: vendors.filter((vendor) => {
					return vendor !== parseInt(id, 10);
				}),
			});
		} else if (entity === 'CATEGORY') {
			dispatch({
				type: 'SET_PRODUCT_CATEGORY',
				payload: productCategories.filter((category) => {
					return category !== parseInt(id, 10);
				}),
			});
		} else if (entity === 'DEFECT') {
			dispatch({
				type: 'SET_DEFECTS',
				payload: defects.filter((defect) => {
					return defect !== parseInt(id, 10);
				}),
			});
		}
	};

	useEffect(() => {
		if (FILTERS?.includes('brand')) {
			fetchBrands({
				id: '',
				allData: true,
			});
		}
		if (
			FILTERS?.includes('store') ||
			FILTERS?.includes('raisedBy') ||
			FILTERS?.includes('parentStore')
		) {
			fetchStoresList({
				entity: 'FILTERED_STORES',
				req: {
					params: {
						id: '',
						type: storeTypes || '',
					},
				},
				method: 'LIST',
			});
		}
		if (FILTERS?.includes('processTypes')) {
			fetchProcessTypesList({
				entity: 'PROCESS_TYPES',
				req: {
					params: {
						id: '',
					},
				},
				method: 'LIST',
			});
		}
	}, [FILTERS, storeTypes]);

	const selectIdentificationType = (type) => {
		dispatch({
			type: 'SET_IDENTIFICATION_TYPE',
			payload: type,
		});
	};

	// eslint-disable-next-line no-unused-vars
	const selectStore = (id) => {
		dispatch({
			type: 'SET_STORE',
			payload: id,
		});
	};
	const selectParentStore = (id) => {
		dispatch({
			type: 'SET_PARENT_STORE',
			payload: id,
		});
	};
	const selectStatus = (statusEle) => {
		dispatch({
			type: 'SET_STATUS',
			payload: statusEle,
		});
	};
	const selectRaisedByStore = (id) => {
		dispatch({
			type: 'SET_RAISED_BY_STORE',
			payload: id,
		});
	};
	const selectProcessType = (id) => {
		dispatch({
			type: 'SET_PROCESS_TYPE',
			payload: id,
		});
	};
	const setPage = (page) => {
		dispatch({
			type: 'SET_PAGE',
			payload: page,
		});
	};
	const selectType = (e) => {
		const { fieldValue } = inputHelper(e);
		dispatch({
			type: 'SET_FEATURE_TYPE',
			payload: fieldValue,
		});
	};
	const selectBrand = (id) => {
		dispatch({
			type: 'SET_BRAND',
			payload: id,
		});
	};

	const brandsList = useMemo(() => {
		return [
			{
				id: EMPTYVALUE,
				title: 'ALL',
			},
			...Object.keys(brands).map((id) => {
				const currBrand = brands[id];
				return currBrand;
			}),
		];
	}, [brands]);

	useEffect(() => {
		dispatch({
			type: 'SET_BRAND',
			payload: brandsList[0]?.id,
		});
	}, [brandsList]);

	useEffect(() => {
		if (timeTypeArray) {
			dispatch({
				type: 'SET_TIME_TYPE',
				payload: timeTypeArray?.[0]?.value,
			});
		}
	}, [timeTypeArray]);

	useEffect(() => {
		if (brand != null && FILTERS?.includes('product')) {
			fetchProducts({
				entity: 'ALL_PRODUCTS',
				req: {
					params: {
						id: '',
						brandId: brand === EMPTYVALUE ? '' : brand,
					},
				},
				method: 'LIST',
			});
		}
		if (FILTERS?.includes('features')) {
			fetchDefects({
				entity: 'DEFECTS',
				req: {
					params: {
						brandId: brand === EMPTYVALUE ? '' : brand,
					},
				},
				method: 'LIST',
			});
		}
	}, [brand]);

	const VENDORS = useMemo(() => {
		return vendorsList?.map((vendor) => {
			return {
				id: vendor.id,
				title: vendor?.name ?? '',
			};
		});
	}, [vendorsList]);

	useEffect(() => {
		if (FILTERS?.includes('productCategory')) {
			fetchProductCategoriesList({
				entity: 'PRODUCT_CATEGORY',
				req: {
					params: {
						id: '',
					},
				},
				method: 'LIST',
			});
		}
		if (FILTERS?.includes('vendor')) {
			fetchAllVendors({
				entity: 'ALL_VENDORS',
				req: {
					params: {
						id: '',
					},
				},
				method: 'LIST',
			});
		}
	}, []);

	const PRODUCTS = useMemo(() => {
		return productsList.map((product) => {
			return {
				id: product.id,
				title: product?.productName ?? '',
			};
		});
	}, [productsList]);

	useEffect(() => {
		if (!query.has(params.STORE)) {
			if (setAutoStore) {
				dispatch({
					type: 'SET_STORE',
					payload: STORES?.[0]?.id,
				});
			}
		}
	}, [stores, STORES, query.has(params.STORE), setAutoStore]);

	const PRODUCT_CATEGORIES = useMemo(() => {
		return productCategoriesList
			?.filter((category) => {
				if (!brand || brand === EMPTYVALUE) return category;
				return category?.brandId === brand;
			})
			?.map((category) => {
				return {
					id: category.id,
					title: category?.productCategoryName ?? '',
				};
			});
	}, [productCategoriesList, brandsList]);

	const DEFECTS = useMemo(() => {
		return defectsList.map((defect) => {
			return {
				id: defect.id,
				title: defect?.label ?? '',
			};
		});
	}, [defectsList]);

	useEffect(() => {
		dispatch({
			type: 'SET_IDENTIFICATION_TYPE',
			payload: 'Both',
		});
	}, []);

	const dateRangeFilterArray = useMemo(() => {
		return [
			{
				startDate: dateRange.from,
				endDate: dateRange.to,
				key: 'selection',
			},
		];
	}, [dateRange]);

	const setDateRange = ({ active, from, to }) => {
		dispatch({
			type: 'SET_DATE_RANGE',
			payload: {
				active,
				from,
				to,
			},
		});
	};

	const setDate = (newDate) => {
		const { startDate: from, endDate: to } = newDate?.selection ?? {};

		setDateRange({
			active: true,
			from,
			to,
		});
	};

	const selectDateOnly = (newDate) => {
		dispatch({
			type: 'SET_DATE',
			payload: newDate,
		});
	};

	const selectTimeType = (e) => {
		const { fieldValue } = inputHelper(e);

		dispatch({
			type: 'SET_TIME_TYPE',
			payload: fieldValue,
		});
	};

	const PROCESS_TYPES = useMemo(() => {
		const types = (processTypes ?? [])
			?.filter((ele) => {
				return ele?.data?.totalMachines > 0;
			})
			?.map((ele) => {
				return {
					id: ele?.data?.nodeId,
					title: ele?.data?.heading,
				};
			});
		types.unshift({
			id: EMPTYVALUE,
			title: 'ALL',
		});
		return types;
	}, [processTypes]);

	useEffect(() => {
		dispatch({
			type: 'SET_PROCESS_TYPE',
			payload: PROCESS_TYPES?.[0]?.id,
		});
	}, [PROCESS_TYPES]);

	useEffect(() => {
		// if (stores.length > 0) {
		let sub = false;
		const queryBrand = query.get(params.BRAND);
		const queryStore = query.get(params.STORE) && [
			Number.parseInt(query.get(params.STORE), 10),
		];
		const from = query.get(params.from);
		const to = query.get(params.to);
		const confInput = Number(query.get(params.conf));
		let iType = query.get(params.iType);
		iType = iType?.replaceAll("'", '');
		const queryVendor = query.get(params.VENDOR) && [
			Number.parseInt(query.get(params.VENDOR), 10),
		];
		const queryProduct = query.get(params.PRODUCT) && [
			Number.parseInt(query.get(params.PRODUCT), 10),
		];
		const queryCategory = query.get(params.CATEGORY) && [
			Number.parseInt(query.get(params.CATEGORY), 10),
		];
		if (brandsList.length > 0) {
			if (query.has(params.BRAND)) {
				sub = true;
				dispatch({
					type: 'SET_BRAND',
					payload: queryBrand,
				});
			} else {
				dispatch({
					type: 'SET_BRAND',
					payload: null,
				});
			}
			if (query.has(params.VENDOR)) {
				sub = true;
				dispatch({
					type: 'SET_VENDORS',
					payload: queryVendor,
				});
			}
			if (query.has(params.STORE)) {
				sub = true;
				dispatch({
					type: 'SET_STORE',
					payload: queryStore?.[0],
				});
			}
			if (query.has(params.PRODUCT)) {
				sub = true;
				dispatch({
					type: 'SET_PRODUCTS',
					payload: queryProduct,
				});
			}
			if (query.has(params.CATEGORY)) {
				if (
					productCategoriesList.find((ele) => {
						return ele.id === queryCategory[0];
					})
				) {
					sub = true;
					dispatch({
						type: 'SET_PRODUCT_CATEGORY',
						payload: queryCategory,
					});
				}
			}
			if (iType) {
				sub = true;
				dispatch({
					type: 'SET_IDENTIFICATION_TYPE',
					payload: iType,
				});
			} else {
				dispatch({
					type: 'SET_IDENTIFICATION_TYPE',
					payload: 'Both',
				});
			}
			if (confInput) {
				sub = true;
				setSliderValue([confInput, 1]);
			} else {
				setSliderValue([0.1, 1]);
			}
			if (sub) {
				// console.log('runnning');
				dispatch({
					type: 'SET_TIME_TYPE',
					payload: 'qc',
				});
				dispatch({
					type: 'SET_DATE_RANGE',
					payload: {
						// ...dateRange,
						from: new Date(from),
						active: true,
						to: new Date(to),
					},
				});
				// console.log('dispactched..time');
				handleSubmitParams({
					store: queryStore?.[0] ?? null,
					brand: queryBrand ?? null,
					vendors: queryVendor ?? vendors,
					products: queryProduct ?? products,
					productCategories: queryCategory ?? productCategories,
					// identification: 'Both',
					identificationType: iType || 'Both',
					conf: {
						minConf: confInput ?? 0.1,
						maxConf: 1,
					},
					timeType: 'qc',
					pageLimit,
					dateRange: {
						from: from ? new Date(from) : new Date(),
						active: true,
						to: to ? new Date(to) : new Date(),
					},
				});
			}
		}
		// }
	}, [
		brandsList?.[0]?.id,
		query.get(params.BRAND),
		query.get(params.PRODUCT),
		query.get(params.STORE),
		query.get(params.VENDOR),
		query.get(params.iType),
		query.get(params.confInput),
	]);

	return (
		<form className={styles.root} onSubmit={handleSubmit}>
			<ListModal modalCallee='imagesFinder' handleConfirm={addFilter} />
			{(pageLabel || additionInfo?.showAdd) && (
				<div className={styles.header}>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						{pageLabel && (
							<p className={styles['page-label']}>{pageLabel}</p>
						)}
						{navStoreFilter?.id && (
							<div className={styles['selected-item']}>
								<Tooltip title={navStoreFilter?.title}>
									<p>
										{navStoreFilter?.title?.length > 18
											? // eslint-disable-next-line prefer-template
											  navStoreFilter?.title?.substring(
													0,
													18
											  ) + '...'
											: navStoreFilter?.title}
									</p>
								</Tooltip>
							</div>
						)}
						{externalFilters?.map((filterEle) => {
							return (
								<div className={styles['selected-item']}>
									<Tooltip title={filterEle?.title}>
										<p>
											{filterEle?.title?.length > 15
												? // eslint-disable-next-line prefer-template
												  filterEle?.title?.substring(
														0,
														15
												  ) + '...'
												: filterEle?.title}
										</p>
									</Tooltip>
									{filterEle?.removable && (
										<IconButton
											className={styles.remove}
											onClick={() => {
												filterEle?.handleRemove(
													filterEle
												);
											}}
										>
											<Close />
										</IconButton>
									)}
								</div>
							);
						})}
					</div>
					{additionInfo?.showAdd && (
						<button
							onClick={additionInfo?.handleAddClick}
							type='button'
							className={styles['add-btn']}
						>
							<Add />
							{`${additionInfo?.prefix ?? 'Add'} ${
								additionInfo?.addText
							}`}
						</button>
					)}
				</div>
			)}
			<div className={styles.form}>
				<div className={styles.filters}>
					<div className={styles['filters-exclusive']}>
						<div className={styles.left}>
							{FILTERS?.includes('parentStore') && (
								<div className='flex items-center gap-2'>
									<Label className='whitespace-nowrap text-[16px]'>{displayNames?.find((ele) => ele?.keys === 'parentStore')?.displayName} :</Label>
										<ExclusiveFilter
											list={STORES?.filter((ele) => {
										const types = filterTypes?.find(
											(filterType) => {
												return (
													filterType?.name ===
													'parentStore'
												);
											}
										);
										if (!types?.restrictions) {
											return true;
										}
										return types?.restrictions
											?.split(',')
											?.includes(ele?.type);
									})}
									increasedWidth={
										(FILTERS?.includes('parentStore') ||
											FILTERS?.includes(
												'dateRangeOnly'
											)) &&
										FILTERS?.length <= 2
									}
									selectedList={parentStore}
									allowNoSelection
									handleClick={selectParentStore}
									label='Parent Store'
								/>
								</div>
							)}
							{FILTERS?.includes('store') && (
								<div className='flex items-center gap-2'>
								<Label className='whitespace-nowrap text-[16px]'>{displayNames?.find((ele) => ele?.keys === 'store')?.displayName} :</Label>
								<ExclusiveFilter
									list={STORES?.filter((ele) => {
										const types = filterTypes?.find(
											(filterType) => {
												return (
													filterType?.name === 'store'
												);
											}
										);
										if (!types?.restrictions) {
											return true;
										}
										return types?.restrictions
											?.split(',')
											?.includes(ele?.type);
									})}
									increasedWidth={
										(FILTERS?.includes('store') ||
											FILTERS?.includes(
												'dateRangeOnly'
											)) &&
										FILTERS?.length <= 2
									}
									selectedList={store}
									allowNoSelection
									handleClick={selectStore}
									label='Select Store'
								/>
								</div>
							)}
							{FILTERS?.includes('processTypes') && (
								<div className='flex items-center gap-2'>
								<Label className='whitespace-nowrap text-[16px]'>{displayNames?.find((ele) => ele?.keys === 'processTypes')?.displayName} :</Label>
								<ExclusiveFilter
									list={PROCESS_TYPES}
									selectedList={processType}
									handleClick={selectProcessType}
									label='Select Process Type'
								/>
								</div>
							)}
							{FILTERS?.includes('raisedBy') && (
								<div className='flex items-center gap-2'>
								<Label className='whitespace-nowrap text-[16px]'>{displayNames?.find((ele) => ele?.keys === 'raisedBy')?.displayName} :</Label>
								<ExclusiveFilter
									list={STORES}
									selectedList={raisedBy}
									handleClick={selectRaisedByStore}
									label='Raised by'
								/>
								</div>
							)}
							{FILTERS?.includes('status') && (
								<div className='flex items-center gap-2'>
								<Label className='whitespace-nowrap text-[16px]'>{displayNames?.find((ele) => ele?.keys === 'status')?.displayName} :</Label>
								<ExclusiveFilter
									list={statusList ?? defaultStatusList}
									selectedList={status}
									handleClick={selectStatus}
									label='Select Status'
								/>
								</div>
							)}
							{FILTERS?.includes('brand') && (
								<div className='flex items-center gap-2'>
								<Label className='whitespace-nowrap text-[16px]'>{displayNames?.find((ele) => ele?.keys === 'brand')?.displayName} :</Label>
								<ExclusiveFilter
									list={brandsList?.map((ele) => {
										return {
											...ele,
											title: ele?.name ?? ele?.title,
										};
									})}
									selectedList={brand}
									handleClick={selectBrand}
									label='Select Brand'
									disabled={products?.length > 0}
								/>
								</div>
							)}
						</div>
						<div className={styles.right}>
							{FILTERS?.includes('productCategory') && (
								<div
									className={classes(
										styles.filter,
										styles.productCategory
									)}
								>
									<p className={styles['filter-heading']}>
										Product category
									</p>
									<div className={styles['filter-body']}>
										<Button
											variant='outlined'
											startIcon={<Add />}
											className={styles['add-button']}
											// disabled={brand == null}
											fullWidth
											onClick={() => {
												toggleModal(true, {
													callee: 'imagesFinder',
													entity: 'CATEGORY',
													heading:
														'Select Category(s)',
													list: PRODUCT_CATEGORIES,
													selected: productCategories,
												});
											}}
										>
											Category
										</Button>
										<div className={styles.tags}>
											{productCategories.map((id) => {
												const selectedCategory =
													PRODUCT_CATEGORIES?.find(
														(v) => {
															return id === v?.id;
														}
													);
												return (
													<Tag
														key={
															selectedCategory?.id
														}
														width='100%'
														text={
															selectedCategory?.title
														}
														handleDelete={
															deleteFilter
														}
														dataAttrs={{
															'data-id':
																selectedCategory?.id,
															'data-entity':
																'CATEGORY',
														}}
													/>
												);
											})}
											{productCategories?.length ===
												0 && (
												<span>
													All Categories selected
												</span>
											)}
										</div>
									</div>
								</div>
							)}
							{FILTERS?.includes('product') && (
								<div
									className={classes(
										styles.filter,
										styles.product
									)}
								>
									<p className={styles['filter-heading']}>
										Select Product(s)
									</p>
									<div className={styles['filter-body']}>
										<Button
											variant='outlined'
											startIcon={<Add />}
											className={styles['add-button']}
											// disabled={brand == null}
											fullWidth
											onClick={() => {
												toggleModal(true, {
													entity: 'PRODUCT',
													callee: 'imagesFinder',
													heading:
														'Select Product(s)',
													list: PRODUCTS,
													selected: products,
												});
											}}
										>
											Product
										</Button>
										<div className={styles.tags}>
											{products.map((id) => {
												const product = PRODUCTS.find(
													(d) => {
														return id === d.id;
													}
												);
												return (
													<Tag
														key={id}
														width='100%'
														text={product?.title}
														handleDelete={
															deleteFilter
														}
														dataAttrs={{
															'data-id': id,
															'data-entity':
																'PRODUCT',
														}}
													/>
												);
											})}
											{products?.length === 0 && (
												<span>
													All products selected
												</span>
											)}
										</div>
									</div>
								</div>
							)}
							{FILTERS?.includes('vendor') && (
								<div
									className={classes(
										styles.filter,
										styles.vendor
									)}
								>
									<p className={styles['filter-heading']}>
										Select Vendor(s)
									</p>
									<div className={styles['filter-body']}>
										<Button
											variant='outlined'
											startIcon={<Add />}
											className={styles['add-button']}
											// disabled={brand == null}
											fullWidth
											onClick={() => {
												toggleModal(true, {
													entity: 'VENDOR',
													callee: 'imagesFinder',
													heading:
														'Select Vendors(s)',
													list: VENDORS,
													selected: vendors,
												});
											}}
										>
											VENDOR
										</Button>
										<div className={styles.tags}>
											{vendors.map((id) => {
												const selectedVendor =
													VENDORS?.find((v) => {
														return id === v?.id;
													});
												return (
													<Tag
														key={selectedVendor?.id}
														width='100%'
														text={
															selectedVendor?.title
														}
														handleDelete={
															deleteFilter
														}
														dataAttrs={{
															'data-id':
																selectedVendor?.id,
															'data-entity':
																'VENDOR',
														}}
													/>
												);
											})}
											{vendors?.length === 0 && (
												<span>
													All Vendors selected
												</span>
											)}
										</div>
									</div>
								</div>
							)}
							{FILTERS?.includes('features') && (
								<div
									className={classes(
										styles.filter,
										styles.defect
									)}
								>
									<p className={styles['filter-heading']}>
										Select Feature(s)
									</p>
									<div className={styles['filter-body']}>
										<Button
											variant='outlined'
											startIcon={<Add />}
											className={styles['add-button']}
											// disabled={brand == null}
											fullWidth
											onClick={() => {
												toggleModal(true, {
													entity: 'DEFECT',
													callee: 'imagesFinder',
													heading: 'Select Defect(s)',
													list: DEFECTS,
													selected: defects,
												});
											}}
										>
											FEATURE
										</Button>
										<div className={styles.tags}>
											{defects.map((id) => {
												const defect = DEFECTS.find(
													(d) => {
														return id === d.id;
													}
												);
												return (
													<Tag
														key={id}
														text={defect?.title}
														handleDelete={
															deleteFilter
														}
														dataAttrs={{
															'data-id': id,
															'data-entity':
																'DEFECT',
														}}
													/>
												);
											})}
											{defects?.length === 0 && (
												<span>
													All Features selected
												</span>
											)}
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
					<div className={styles['filters-generic']}>
						<div className={styles['inner-generic']}>
							{FILTERS?.includes('featureType') && (
								<div
									className={classes(
										styles.filter,
										styles.product
									)}
								>
									<p>Feature Type:</p>
									<Select
										value={featureType ?? ''}
										className={
											styles['feature-type-select']
										}
										onChange={selectType}
									>
										{typesList?.map((type) => {
											return (
												<MenuItem
													key={type.name}
													value={type.value}
												>
													{type.name}
												</MenuItem>
											);
										})}
									</Select>
								</div>
							)}
							{FILTERS?.includes('pageLimit') && (
								<div
									className={classes(
										styles.filter,
										styles.product
									)}
								>
									<p>Limit:</p>
									<Select
										value={pageLimit ?? ''}
										className={styles['limit-select']}
										onChange={(e) => {
											setPage(e.target.value);
										}}
									>
										{[6, 25, 50, 100].map((page) => {
											return (
												<MenuItem
													key={page}
													value={page}
												>
													{page}
												</MenuItem>
											);
										})}
									</Select>
								</div>
							)}
							{FILTERS?.includes('identificationType') && (
								<div
									className={classes(
										styles.filter,
										styles.identification
									)}
								>
									<p>Identification Type:</p>
									<div className={styles.types}>
										{identificationTypes?.map((ele) => {
											const isSelected =
												ele?.type ===
												identificationType;
											return (
												<div
													key={ele?.id}
													onClick={() => {
														selectIdentificationType(
															ele?.type
														);
													}}
													className={classes(
														styles['types-type'],
														isSelected
															? styles.selected
															: ''
													)}
												>
													<p>{ele?.type}</p>
												</div>
											);
										})}
									</div>
								</div>
							)}
							{FILTERS?.includes('confidence') && isInternal && (
								<div>
									<p>Confidence Range</p>
									<p>
										{`[ ${Math.min(
											sliderValue[0],
											sliderValue[1]
										)} - ${Math.max(
											sliderValue[0],
											sliderValue[1]
										)} ]`}
									</p>
									<RangeSlider
										className={styles['defect-select']}
										progress
										style={{ marginTop: 16 }}
										value={sliderValue}
										min={0.01}
										max={1}
										step={0.01}
										onChange={(value) => {
											const start = value[0].toFixed(2);
											const end = value[1].toFixed(2);
											setSliderValue([start, end]);
										}}
									/>
								</div>
							)}
						</div>
						{FILTERS?.includes('dateRange') && (
							<div
								className={classes(
									styles['range-container'],
									dateRange.active ? styles.applied : ''
								)}
							>
								<Label className='whitespace-nowrap text-[16px]'>{displayNames?.find((ele) => ele?.keys === 'dateRange')?.displayName} :</Label>
								<Checkbox
									checked={dateRange?.active}
									onChange={() => {
										return setDateRange({
											...dateRange,
											active: !dateRange.active,
										});
									}}
									inputProps={{
										'aria-label': 'controlled',
									}}
								/>
								<Select
									value={timeType ?? ''}
									className={styles['time-select']}
									onChange={selectTimeType}
									disabled={!dateRange.active}
								>
									{(timeTypeArray ?? defaultTimeTypeArr).map(
										(type, idx) => {
											return (
												<MenuItem
													// eslint-disable-next-line react/no-array-index-key
													key={idx}
													value={type.value}
												>
													{type.label}
												</MenuItem>
											);
										}
									)}
								</Select>
								<UYDateRange
									disabled={!dateRange.active}
									dateRanges={dateRangeFilterArray}
									onDateRangeChange={setDate}
								/>
							</div>
						)}
						{FILTERS?.includes('dateRangeOnly') && (
							<div
								className={classes(
									styles['range-container'],
									FILTERS?.includes('dateRangeOnly')
										? styles.applied
										: ''
								)}
							>
								<Label className='whitespace-nowrap text-[16px]'>{displayNames?.find((ele) => ele?.keys === 'dateRangeOnly')?.displayName} :</Label>
								<UYDateRange
									disabled={
										!FILTERS?.includes('dateRangeOnly')
									}
									dateRanges={dateRangeFilterArray}
									onDateRangeChange={setDate}
								/>
							</div>
						)}
						{FILTERS?.includes('dateOnly') && (
							<div
								className={classes(
									styles['range-container'],
									FILTERS?.includes('dateOnly')
										? styles.applied
										: ''
								)}
							>
								<Label className='whitespace-nowrap text-[16px]'>{displayNames?.find((ele) => ele?.keys === 'dateOnly')?.displayName} :</Label>
								<DatePicker
									disabled={!FILTERS?.includes('dateOnly')}
									defaultValue={date}
									onChangeCalendarDate={selectDateOnly}
									oneTap
								/>
							</div>
						)}
					</div>
				</div>
				<div className={classes(styles.submit)}>
					<span>Find</span>
					<IconButton
						type='submit'
						className={classes(
							styles.btn,
							shouldAnimate ? styles?.animate : '',
							changed >= 0 ? styles?.disabled : ''
						)}
					>
						<ArrowCircleRight />
					</IconButton>
				</div>
			</div>
		</form>
	);
};

GenericFilter.defaultProps = {
	brands: [],
	products: [
		{
			label: 'Product D',
		},
	],
	onSubmit: () => {},
};

const mapStateToProps = (state) => {
	const stores = state?.form?.STORES?.data?.LIST;
	const filteredStores = state?.form?.FILTERED_STORES?.data?.LIST;
	const { brands } = state.brand;
	const { LIST: productsList } = state.form.ALL_PRODUCTS.data;
	const { DATE_RANGE_FILTER: dateRangeFilter } = state.analytics;
	const { LIST: defectsList } = state.form.DEFECTS.data;
	const { LIST: vendorsList } = state.form.ALL_VENDORS.data;
	const { LIST: productCategoriesList } = state.form.PRODUCT_CATEGORY.data;
	const { LIST: processTypes } = state?.form?.PROCESS_TYPES?.data;

	return {
		stores,
		filteredStores,
		brands,
		productsList,
		dateRangeFilter,
		defectsList,
		vendorsList,
		productCategoriesList,
		processTypes,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleListModal,
	fetchStoresList: formActions.gateway,
	fetchBrands: brandActions.fetchBrands,
	fetchProducts: formActions.gateway,
	setDateRangeFilter: analyticsAction.setDateRangeFilter,
	fetchProductCategoriesList: formActions.gateway,
	fetchAllVendors: formActions.gateway,
	fetchDefects: formActions.gateway,
	fetchProcessTypesList: formActions.gateway,
};

export default connect(mapStateToProps, mapDispatchToProps)(GenericFilter);
