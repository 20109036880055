/* eslint-disable */
import { CheckCheck, X } from "lucide-react";
import { useEffect, useState } from "react";
import { Radio, RadioGroup } from "rsuite";
const TableRowProduct = (props) => {
    const {
        columns,
        rowData,
        handleRemove,
        handleUpdate,
        isDisabled = false,
    } = props;
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);

    useEffect(() => {
        if(rowData){
            setMin(rowData?.min || 0);
            setMax(rowData?.max || 0);
        }
    }, []);

    const handleMinus = () => {
        setMin((prevMin) => {
            const newMin = Number(prevMin) - 1;
            handleUpdate(rowData?.id, "min", newMin);
            return newMin;
        });
    };
    
    const handlePlus = () => {
        setMin((prevMin) => {
            const newMin = Number(prevMin) + 1;
            handleUpdate(rowData?.id, "min", newMin);
            return newMin;
        });
    };

    const handleMinusMax = () => {
        setMax((prevMax) => {
            const newMax = Number(prevMax) - 1;
            handleUpdate(rowData?.id, "max", newMax);
            return newMax;
        });
    };
    
    const handlePlusMax = () => {
        setMax((prevMax) => {
            const newMax = Number(prevMax) + 1;
            handleUpdate(rowData?.id, "max", newMax);
            return newMax;
        });
    };

    const tableCell = {
        'Parameter Name': () => {
            return (
                <p>{rowData?.name}</p>
            )
        },
        'Input Type': () => {
            return (
                <p>{rowData?.inputType}</p> 
            )
        },
        'Unit': () => {
            return (
                <p>{rowData?.unit ? rowData?.unit : 'N/A'}</p>
            )
        },
        'Image Needed': () => {
            return (
               <div className='flex items-center justify-center'>
                {rowData && rowData.imageRequired ? <CheckCheck /> : <X />}
               </div>
            )
        },
        'Configuration': () => {
            return (
                <div>
                    {rowData?.rangeFlag && (
                        <div className='flex items-center bg-slate-100 p-[10px] gap-1'>
                            <div className="flex items-center gap-2">
                                <span>Min</span>
                                <div className='flex items-center w-40 h-10 p-2 bg-white rounded-md'>
                                    <button 
                                        className='w-[4rem] h-full flex items-center justify-center text-2xl hover:bg-gray-100'
                                        onClick={handleMinus}
                                        disabled={isDisabled}
                                    > 
                                        - 
                                    </button>
                                    <input
                                        type="number"
                                        className='w-[5rem] h-full text-center'
                                        value={rowData?.min}
                                        onChange={(e) => handleUpdate(rowData?.id, "min", e.target.value)}
                                        disabled={isDisabled}
                                    />
                                    <button
                                        className='w-[4rem] h-full flex items-center justify-center text-2xl hover:bg-gray-100'
                                        onClick={handlePlus}
                                        disabled={isDisabled}
                                    > 
                                        + 
                                    </button>
                                </div>
                            </div>
                            <div className="flex items-center gap-2">
                                <span>Max</span>
                                <div className='flex items-center w-40 h-10 bg-white rounded-md'>
                                    <button 
                                        className='w-[4rem] h-full flex items-center justify-center text-2xl hover:bg-gray-100'
                                        onClick={handleMinusMax}
                                        disabled={isDisabled}
                                    >
                                        -
                                    </button>
                                    <input
                                        type="number"
                                        className='w-[5rem] h-full text-center'
                                        value={rowData?.max}
                                        onChange={(e) => handleUpdate(rowData?.id, "max", e.target.value)}
                                        disabled={isDisabled}
                                    />
                                    <button
                                        className='w-[4rem] h-full flex items-center justify-center text-2xl hover:bg-gray-100'
                                        onClick={handlePlusMax}
                                        disabled={isDisabled}
                                    >
                                        +
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    {rowData?.inputType === 'boolean' && (
                        <div className="flex items-center justify-center gap-3 w-[20rem]">
                            <span className="text-sm">Select the right Value:</span>
                            <div className="flex items-center gap-2">
                            <Radio 
                                name='expectedValue'
                                checked={rowData?.expectedValue === '1' ? true : false}
                                onChange={() => handleUpdate(rowData?.id, 'expectedValue', '1')} 
                                disabled={isDisabled}
                                label='Yes'
                            >
                                Yes
                            </Radio>
                            <Radio 
                                name='expectedValue'
                                checked={rowData?.expectedValue === '0' ? true : false} 
                                onChange={() => handleUpdate(rowData?.id, 'expectedValue', '0')} 
                                disabled={isDisabled} 
                            >
                                No
                            </Radio>
                            </div>
                        </div>
                    )}
                </div>
        )
        },
        'Action': () => {
            return (
                <span className='text-red-500 cursor-pointer underline' onClick={() => handleRemove(rowData?.id)}>Remove</span>
            )
        },
    };
    return (
        <tr>
           {columns.map((coloumn) => (
            <td 
            style={{ width: coloumn?.width || '', textAlign: 'center' }} 
            className="text-center align-middle">
                {tableCell[coloumn?.name]()}
            </td>
           ))}  
        </tr>
    )
} 

export default TableRowProduct;
