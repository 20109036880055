/* eslint-disable */
import {
	Accordion, AccordionDetails, AccordionSummary, Typography
} from '@mui/material';
// import AdjustIcon from '@mui/icons-material/Adjust';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoneIcon from '@mui/icons-material/Done';
import Lottie from 'lottie-react';
import styles from './IngredientAccordion.module.css';
import { classes, convertWeightUnit } from '../../../../Helpers';
import correct from '../../../../Images/correct-lottie.json';
import wrong from '../../../../Images/wrong-lottie.json';
import MeasurementBox from '../../../../Components/Analytics/Quality/Measurement/MeasurementBox';
import { useAuth } from '../../../../Components/Auth/Login/auth';

const style = {
	width: 50,
	height: 50,
};

function IngredientAccordion(props) {
	const { appConfig } = useAuth();
	const { measurement, combineCMSBatches } = appConfig?.configs ?? {};
	const { key, ingredient, ingredientExpanded, setIngredient, isCms, itemFulfilled, unit } = props;
	const convertedConsumption = convertWeightUnit(ingredient?.consumption, ingredient?.unit, true);
	const convertedRequired = convertWeightUnit(
		ingredient?.requiredQty,
		ingredient?.unit,
		true
	);
	const convertedOrderd = convertWeightUnit(
		ingredient?.ingTotalQty,
		ingredient?.unit,
		true
	);
	return (
		<div key={key}>
			<Accordion
				expanded={ingredientExpanded === ingredient?.id}
				onChange={() => {
					setIngredient(ingredient);
				}}
				className={classes(
					styles.root,
					ingredient?.isConsumed ? styles.completed : ''
				)}
			>
				<AccordionSummary
					className={styles['accordion-heading']}
					expandIcon={<ExpandMoreIcon />}
					aria-controls='panel1a-content'
					id='panel1a-header'
				>
					<div className={styles.heading}>
						<Typography
							variant='subtitle2'
							className={
								ingredient?.isConsumed
									? styles.completedText
									: styles.incomplete
							}
						>
							{ingredient?.name}
						</Typography>
						{ingredient?.isConsumed && (
							<>
								<DoneIcon className={styles['icon-selected']} />
								{ingredient?.overridden && (
									<div className={styles.overriden}>
										<InfoOutlinedIcon
											style={{
												color: 'red',
												paddingLeft: '1px',
											}}
										/>
										<span
											style={{
												color: 'rgb(240,0,0)',
											}}
										>
											Overridden
										</span>
									</div>
								)}
							</>
						)}
					</div>
					<p className={styles['consumed-weight']}>
						{convertedConsumption ?? 0}
						{/* {ingredient?.unit} */}
					</p>
				</AccordionSummary>
				<AccordionDetails className={styles['accordion-details']}>
					<div className={styles['expanded-div']}>
						<div className={styles.ingredientImage}>
							<img
								className={styles.image}
								alt='ig'
								src={ingredient?.image}
							/>
							<div className={styles.imagePassFail}>
								{ingredient?.imagePass !== null &&
									ingredient?.isConsumed &&
									(ingredient?.imagePass ? (
										<div className={styles.info}>
											<Lottie
												style={style}
												animationData={correct}
												loop
											/>
											<span> Ingredient Matched </span>
										</div>
									) : (
										<div className={styles.info}>
											<Lottie
												style={style}
												animationData={wrong}
												loop
											/>
											<span>
												{' '}
												Ingredient Mis-Matched
												{' '}
											</span>
										</div>
									))}
							</div>
							{ingredient?.qcTemp !== null && (
								<div className={styles.measure}>
									<MeasurementBox
										// modeIngredient={true}
										autoMode
										type='temp'
										qcDone
										cms
										height='43px'
										measurements={{
											min: ingredient?.minTemp ?? '-',
											max: ingredient?.maxTemp ?? '-',
											title: 'Temperature',
											measurement: [
												{
													size: 'md',
													value: ingredient?.qcTemp,
													color: 'black',
												},
											],
											result:
												(ingredient?.tempPass ?? null) &&
												(ingredient?.tempPass
													? 'PASS'
													: 'FAIL'),
											unit: measurement ? '°F' : '℃',
											connected: true,
											textToShow: 'Current Temp',
										}}
									/>
								</div>
							)}
						</div>
						{(isCms && combineCMSBatches)
							? (
								<MeasurementBox
									modeIngredient
									unit={unit}
									cms
									autoMode
									type='weight'
									qcDone
									hideMin
									hideMax
									measurements={{
										title: 'Weight',
										measurement: [
											{
												size: 'lg',
												value: `${
											unit === 'count' ? itemFulfilled + ' pc' : convertedConsumption ?? '0'
										}/ ${unit === 'count' ? parseInt(convertedOrderd) + ' pc' : convertedOrderd} `,
												color: 'green',
											},
										],
										result:
								ingredient?.weightPass !== null &&
								(ingredient?.weightPass ? 'PASS' : 'FAIL'),
										// result:()?(ingredientData?.weightPass?"PASS":"FAIL"):null,
										unit: '',
										connected: true,
										textToShow: 'Packaged/Ordered',
									}}
								/>
							) : (
								<MeasurementBox
									modeIngredient
									cms
									autoMode
									type='weight'
									qcDone
									measurements={{
										min: convertWeightUnit(
											ingredient?.minWeight,
											ingredient?.unit,
											true
										),
										max: convertWeightUnit(
											ingredient?.maxWeight,
											ingredient?.unit,
											true
										),
										title: 'Weight',
										measurement: [
											{
												size: 'lg',
												value: `${
											convertedConsumption ?? '0'
										}/${convertedRequired} `,
												color: 'green',
											},
										],
										result:
									ingredient?.weightPass !== null &&
									(ingredient?.weightPass ? 'PASS' : 'FAIL'),
										// result:()?(ingredientData?.weightPass?"PASS":"FAIL"):null,
										unit: '',
										connected: true,
										textToShow: 'Consumed/Recommended',
									}}
								/>
							)}
					</div>
				</AccordionDetails>
			</Accordion>
		</div>
	);
}

export default IngredientAccordion;
