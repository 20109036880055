import DatePicker from 'rsuite/DatePicker';
import './DatePickerCustom.css';

function DatePickerCustom(props) {
	const {
		label,
		oneTap,
		style = {},
		format,
		selectDate,
		selectedDate,
		shouldDisableDate,
		defaultValue = null,
		size = 'md',
		cleanable,
		disabled,
		placement,
		disableFutureDate,
	} = props ?? {};

	return (
		<div className='datePicker'>
			{label && <label>{label}</label>}
			<DatePicker
				style={{
					...style,
				}}
				cleanable={cleanable}
				size={size}
				value={selectedDate}
				defaultValue={defaultValue}
				onChange={selectDate}
				oneTap={oneTap}
				format={format}
				disabled={disabled}
				shouldDisableDate={shouldDisableDate}
				placement={placement}
				disabledDate={disableFutureDate ? (date) => date >= new Date() : false}
			/>
		</div>
	);
}

export default DatePickerCustom;
