import {
	Accordion, AccordionDetails, AccordionSummary, Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './RecipeItems.module.css';
import ProcessAccordion from './ProcessAccordion/ProcessAccordion';

function RecipeItems(props) {
	const {
		stageData,
		stageExpanded,
		setStageExpanded,
		setCurrentRecipe,
		setIngredients,
		currentRecipe,
		getPastQCDetails,
		isCms,
		recipe,
		unit = '',
	} = props;
	const [processExpanded, setProcessExpanded] = useState('');
	useEffect(() => {
		setIngredients(stageData?.processes[0]?.ingredients);
	}, []);

	useEffect(() => {
		if (stageData?.processes?.length > 0) {
			setProcessExpanded(stageData.processes[0].id);
		}
	}, [stageData]);

	const handleSelectProcess = (process) => {
		setProcessExpanded(process?.id);
		setIngredients(process?.ingredients);
	};

	return (
		<div>
			<Accordion
				expanded={stageExpanded === stageData?.id}
				onChange={() => {
					setStageExpanded(stageData?.id);
				}}
				className={styles.root}
			>
				<AccordionSummary
					className={styles['accordion-heading']}
					expandIcon={<ExpandMoreIcon />}
					aria-controls='panel1a-content'
					id='panel1a-header'
				>
					<Typography className={styles.name}>
						{stageData?.name}
					</Typography>
				</AccordionSummary>
				<AccordionDetails className={styles?.['accordion-details']}>
					{stageData?.processes?.map((process) => {
						return (
							<ProcessAccordion
								key={process?.id}
								processData={process}
								processExpanded={processExpanded}
								setProcess={handleSelectProcess}
								setCurrentRecipe={setCurrentRecipe}
								currentRecipe={currentRecipe}
								getPastQCDetails={getPastQCDetails}
								isCms={isCms}
								recipe={recipe}
								unit={unit}
							/>
						);
					})}
				</AccordionDetails>
			</Accordion>
		</div>
	);
}

export default RecipeItems;
