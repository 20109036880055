/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable */
import _, { cloneDeep } from 'lodash';
// eslint-disable-next-line object-curly-newline
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { formActions } from '../../../Actions';
import { useAuth } from '../../../Components/Auth/Login/auth';
import ProductDetails from '../../../Components/Forms/Products/ProductDetails';
import { ViewPageHeader } from '../../../Components/Headers';
import {
	StyledTabs,
	StyledTab,
	TabPanel,
} from '../../../Components/StyledTabs';
import { history, isEmpty } from '../../../Helpers';
import productConfig from './allproduct.config';
import ProductVariantsTable from '@/Components/Forms/Products/ProductVariantsTable';
import ProductQualityConfig from '@/Components/Forms/Products/ProductQualityConfig';
import { FormSectionHeader } from '@/Components/Forms';
import { qualityConfig } from './quality.config';
import userRolesAndPermissions from '@/Services/userRolesAndPermissions.services';
import { notificationServices } from '@/Services';
import authActions from '@/Actions/auth.actions';
import { Label } from '@/Components/ui/label';
import QualityCheck from '@/Components/AddProductParameters/qualityCheck';
import AddParameterComp from '@/Components/AddProductParameters/addParameterComp';
import { Checkbox } from '@mui/material';
import masterMenuServices from '@/Services/ProductSpecificationParamServices/productMasterMenu.services';
import QualityConfig from '@/Components/AddProductParameters/qualityConfig';

const TABS = ['PRODUCT DETAILS'];

const ProductViewPage = (props) => {
	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs ?? {};
	const {
		fetchProduct,
		data,
		deleteProduct,
		breadcrumbs,
		subCategoriesList,
		fetchVariant,
		fetchSubCategories,
		variantData,
		qualityData,
		userPermissions,
		navState,
		userList,
		fetchUserList,
	} = props;

	const [productData, setProductData] = useState({});
	const [state, setState] = useState({
		activeTab: 0,
		fields: _.cloneDeep(productConfig.initialState.fields),
	});
	const [variant, setVariantData] = useState({
		fields: cloneDeep(productConfig.initialState?.fields?.variants),
	});
	const [newList, setNewList] = useState([]);
	const [qualityCheck, setQualityCheck] = useState({
		qualityCheckEnabled : false,
		categoryConfiguration : false,
		minSamplingQuantity: 0,
		weightCheck: false,
		temperatureCheck: {
			enabled : false,
			minTemp: 0,
			maxTemp: 0,
		},
		defectCheck: {
			enabled : false,
			areaThreshold: 0,
			countThreshold: 0,
			reject: 'both',
		},
		scanCount: 0,
	});
	const { id } = useRouteMatch().params;

	const { userConfig } = useAuth();
	// userAcess
	// useEffect(async () => {
	// 	fetchUserList();
	// }, []);
	// const map = new Map(userList.map((item) => [item.value, item.label]));
	// const role = Object.keys(userConfig?.userRoles);
	// const userRoles = [];
	// role.forEach((ele) => {
	// 	userRoles.push(ele);
	// });
	// const pageRole = navState['/products']?.submenu['/products/all']?.rolesMapped;
	// const findMatchingRole = userRoles.filter((ele) => pageRole.includes(Number(ele)));
	// const roleDetails = findMatchingRole.map((role) => {
	// 	const key = Number(role);
	// 	const name = map.get(key);
	// 	return {
	// 		role: key, name,
	// 	};
	// });
	// const isInternal = roleDetails.some((item) => item.name === 'Production' || item.name === 'Procurement' || item.name === 'Distribution And Supply Chain');
	// const access = findMatchingRole.length > 0 ? userConfig?.userDetails?.level : false;
	// let permit;
	// if (access === false) {
	// 	permit = false;
	// } else {
	// 	permit = isInternal && access !== 1;
	// }
	// end user Acces


	const fetchProductData = async () => {
		try{
		const req = {
			params: {
				productId: id,
			},
		};
		const response = await masterMenuServices.fetchProductData(req);
		if(response?.statusCode === 200){
			setProductData(response?.data);
		} else {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Error fetching product data',
			});
		}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error?.message,
			});
		}
	};
	useEffect(() => {
		fetchProductData();
		// fetchProduct({
		// 	entity: 'ALL_PRODUCTS',
		// 	req: {
		// 		params: {
		// 			id,
		// 		},
		// 	},
		// 	method: 'VIEW',
		// });

		// fetchVariant({
		// 	entity: 'VARIANTS',
		// 	req: {
		// 		params: {
		// 			id,
		// 		},
		// 	},
		// 	method: 'VIEW',
		// 	showNotification: true,
		// });
		// fetchProduct({
		// 	entity: 'PRODUCT_QUALITY_CONFIG',
		// 	req: {
		// 		params: {
		// 			id,
		// 			combo: true,
		// 		},
		// 	},
		// 	method: 'LIST',
		// });
	}, []);

	useEffect(() => {
		const newState = {};
		if(!isEmpty(productData)){
				setState(
					productConfig.serializer(state, {
					...productData,
					measurement,
					densityUnit: measurement === 'US' ? 'Lb' : 'Kg',
				}));
				setVariantData(productConfig?.variantSerilizer(productData));
				newState.qualityCheckEnabled  = productData?.qualityAnalysis?.qualityAnalysisEnabled;
				newState.categoryConfiguration = productData?.qualityAnalysis?.ifCategoryConfig;
				newState.minSamplingQuantity = productData?.qualityAnalysis?.minimumSamplingQuantityPercentage;
				newState.weightCheck = productData?.qualityAnalysis?.weightCheckRequired;
				newState.temperatureCheck = productData?.qualityAnalysis?.temperatureCheckRequired;
				newState.scanCount = productData?.qualityAnalysis?.maxScanCount;
				newState.defectCheck = {
					enabled: productData?.qualityAnalysis?.defectConfig?.enabled,
					areaThreshold: productData?.qualityAnalysis?.defectConfig?.areaThreshold || 0.1,
					countThreshold: productData?.qualityAnalysis?.defectConfig?.countThreshold || 1,
					reject: productData?.qualityAnalysis?.defectConfig?.reject,
				  };
				setQualityCheck(newState);
				setNewList(productData?.qualityAnalysis?.parameterChecks);
		}
		// if (!isEmpty(data)) {
		// 		setState(
		// 			productConfig.serializer(state, {
		// 			...data,
		// 			measurement,
		// 			densityUnit: measurement === 'US' ? 'Lb' : 'Kg',
		// 		})
		// 	);
		// }
		// if (!isEmpty(variantData)) {
		// 	setVariantData(productConfig?.variantSerilizer(variantData));
		// }
		// if (quality) {
		// 	setQualityFields(qualityConfig.serializer(state, quality));
		// }
	}, [productData, id]);

	useEffect(() => {
		if (state?.fields?.category) {
			fetchSubCategories({
				entity: 'PRODUCT_SUB_CATEGORY',
				req: {
					params: {
						categoryId: state?.fields?.category,
					},
				},
				method: 'LIST',
			});
		}
	}, [state?.fields?.category]);

	const { activeTab, fields } = state;

	const handleTabChange = (event, tab) => {
		setState({
			...state,
			activeTab: tab,
		});
	};

	const excludedFields = useMemo(() => {
		let exclude = {};
		const productUnit = state?.fields?.productUnits?.reduce(
			(finalUnit, currUnit) => {
				if (currUnit?.isApplied) {
					return currUnit?.id;
				}
				return finalUnit;
			}
		);

		if (
			productUnit === 'litre' ||
			productUnit === 'ml' ||
			productUnit === 'floz'
		) {
			exclude = {};
		} else {
			exclude.productDensity = true;
		}
		return exclude;
	}, [state?.fields?.productUnits]);

	const renderInfo = (idx) => {
		switch (idx) {
			case 0:
				return (
					<>
						<ProductDetails
							isDisabled
							fields={fields}
							exclude={excludedFields}
							subCategoriesList={subCategoriesList}
						/>
						{/* <ProductTags
							fields={fields}
							menuTags={productConfig.initialState.menuTags}
							productTags={productConfig.initialState.productTags}
							isDisabled
						/> */}
						<FormSectionHeader
							tooltip='description for the section'
							sectionName='Varients Details'
						>
							<ProductVariantsTable
								isDisabled
								measurement={measurement}
								unitprop={variant?.fields}
								variants={variant?.fields?.variants}
							/>
						</FormSectionHeader>

						<div className='flex gap-2 m-[1rem] mt-4 justify-between'>
						<div className='flex item-center'>
							<Label className='text-lg text-gray-600 font-medium'>Quality Analysis Inspection</Label>
						</div>
							{/* <div className='flex items-center gap-2'>
								<Checkbox
									checked={qualityCheck?.categoryConfiguration}
									onChange={() => setQualityCheck((prev) => ({...prev, categoryConfiguration : !prev.categoryConfiguration}))} 
									size="small"
									sx={{
										'& .MuiSvgIcon-root': {
										fontSize: '1.2rem',
										},
										'&.Mui-checked': {
										color: '#000000',
										},
									}}
									disabled
								/>
								<Label className='text-lg text-gray-600 font-medium'>Follow Category Configuration</Label>
							</div> */}
			            </div>
						<QualityConfig
							qualityCheck={qualityCheck}
							setQualityCheck={setQualityCheck}
							pageName='Product'
							isDisabled
							unit={state?.fields?.productUnits.find((unit) => unit.isApplied === true)?.id}
						/>
						<div className={`transition-all duration-700 ease-in-out transform origin-top ${
        		 		 	qualityCheck?.qualityCheckEnabled ? 'max-h-[500px] scale-y-100 opacity-100' : 'max-h-[0px] scale-y-0 opacity-0'
    					}`}>
							<QualityCheck
								qualityCheck={qualityCheck}
								setQualityCheck={setQualityCheck}
								pageName='Product'
								isDisabled
							/>
							<AddParameterComp
								selectedParameters={newList}
								setNewList={setNewList}
								qualityCheck={qualityCheck}
								isDisabled
								hideCategoryConfiguration
							/>
						</div>
						
						{/* <FormSectionHeader
							tooltip='description for the section'
							sectionName='Quality Config'
						>
							<ProductQualityConfig
								fields={qualityFields?.fields}
								isDisabled
							/>
						</FormSectionHeader> */}
					</>
				);
			default:
				return <></>;
		}
	};
	const { url } = useRouteMatch();

	const redirectToEdit = () => {
		const splitURL = url.split('/view');
		splitURL.splice(1, 0, '/edit');
		const newURL = splitURL.join('');
		history.push(newURL);
	};

	const handleDelete = () => {
		const splitURL = url.split('/view');
		deleteProduct({
			entity: 'ALL_PRODUCTS',
			req: {
				params: {
					id,
				},
			},
			method: 'DELETE',
		});
		history.push(splitURL[0]);
	};
	return (
		<div className='root'>
			<ViewPageHeader
				heading='View Product'
				handleEdit={redirectToEdit}
				handleDelete={handleDelete}
				breadcrumbs={[
					...breadcrumbs,
					{
						link: '',
						name: `View ${fields.name}`,
					},
				]}
				showBreadcrumbs
			/>
			<StyledTabs value={activeTab} onChange={handleTabChange}>
				{TABS.map((tab) => {
					return <StyledTab label={tab} />;
				})}
			</StyledTabs>
			{TABS.map((tab, idx) => {
				return (
					<TabPanel value={activeTab} index={idx}>
						{renderInfo(idx)}
					</TabPanel>
				);
			})}
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.ALL_PRODUCTS.data.VIEW;
	const variantData = state.form.VARIANTS.data.VIEW;
	const subCategoriesList = state.form.PRODUCT_SUB_CATEGORY.data.LIST;
	const qualityData = state.form.PRODUCT_QUALITY_CONFIG.data.LIST;
	const { userPermissions, navState } = state.navigation;
	const { userList } = state.auth;
	return {
		data,
		variantData,
		subCategoriesList,
		qualityData,
		userPermissions,
		navState,
		userList,
	};
};

const mapDispatchToProps = {
	fetchProduct: formActions.gateway,
	deleteProduct: formActions.gateway,
	fetchSubCategories: formActions.gateway,
	fetchVariant: formActions.gateway,
	fetchUserList: authActions?.fetchUserList,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductViewPage);
