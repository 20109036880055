/* eslint-disable  */
import { connect } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import drawerActions from '@/Actions/drawer.actions';
import BaseDrawer from '@/Components/Drawers/BaseDrawer/BaseDrawer';
import { Button } from '@/Components/ui/button';
import { SelectPicker } from 'rsuite';
import { UYDateRange } from '@/Components/Forms/Payment Settlements/Calender/UYDateRange';
import { formActions } from '@/Actions';
import { Label } from '@/Components/ui/label';
import aiPackaging from '@/Services/aiPackaging.services';
import { formatDate, handleResponseStreamDownload } from '@/Helpers';
import { notificationServices } from '@/Services';
import { BackdropLoader } from '@/Components/Loaders';

function ExportDrawer(props) {
	const { show, toggleDrawer, data, stores , fetchStoresList} = props;

	const [fromPod, setFromPod] = useState(null);
	const [toPod, setToPod] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [dateRangeFilter, setDateRangeFilter] = useState({
		active: true,
		from: new Date(),
		to: new Date(),
	});
	const handleClose = () => {
		setFromPod('');
		setToPod('');
		setDateRangeFilter({
			active: true,
			from: new Date(),
			to: new Date(),
		});
		toggleDrawer(false);
	};

	const handleOnExportClick = async () => {
		const fromDate = dateRangeFilter?.from;
		const toDate = dateRangeFilter?.to;
		const formattedFromDate = formatDate(fromDate);
		const formattedToDate = formatDate(toDate);
		if (formattedFromDate === '' || formattedToDate === '' || formattedFromDate === null || formattedToDate === null) {
			notificationServices?.generateNotification({
				type: 'error',
				message: 'Date is Manadatory',
			});
			return;
		}
		const req = {
			data: {
				filters: {
					dateRangeFilter: {
						from: formattedFromDate,
						to: formattedToDate,
					},
					fromStore: fromPod || null,
					storeId: toPod || null,
				},
			},
		};
		try {
			setIsLoading(true);
			const response = await aiPackaging?.aiPacakgingExport(req);
			handleResponseStreamDownload(response, 'Meal.csv');
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const renderBtns = () => {
		return (
			<div className='flex gap-2 mr-[6rem]'>
				<Button onClick={handleClose} variant='secondary'>
					Cancel
				</Button>
				<Button
					onClick={handleOnExportClick}
					className='bg-uy-color text-[#073763] hover:bg-[#ffb80377]'
				>
					Export
				</Button>
			</div>
		);
	};

	const setDate = (newDate) => {
		const { startDate: from, endDate: to } = newDate?.selection ?? {};

		setDateRangeFilter({
			active: true,
			from,
			to,
		});
	};

	const dateRangeFilterArray = useMemo(() => {
		return [
			{
				startDate: dateRangeFilter.from,
				endDate: dateRangeFilter.to,
				key: 'selection',
			},
		];
	}, [dateRangeFilter]);

	const FROMPODSTORES = useMemo(() => {
		const tempStore = stores?.filter((ele) => ele?.type === 'BASE').map((item) => {
			return {
				id: item.id,
				title: item?.name ?? '',
				type: item?.type,
			};
		});
		return tempStore;
	}, [stores]);

	const TOPODSTORES = useMemo(() => {
		const tempStore = stores?.filter((ele) => ele?.type === 'RETAIL').map((item) => {
			return {
				id: item.id,
				title: item?.name ?? '',
				type: item?.type,
			};
		})
		return tempStore;
	}, [stores]);

	useEffect(() => {
		fetchStoresList({
			entity: 'STORES',
			req: {
				params: {
					id: '',
					type: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	const selectFromPod = (value) => {
		setFromPod(value);
	};

	const selectToPod = (value) => {
		setToPod(value);
	};
	return (
		<BaseDrawer
			heading='Export Data'
			open={show}
			onClose={handleClose}
			renderBtns={renderBtns}
			size='md'
		>
			<BackdropLoader isLoading={isLoading} />
			<div className='flex flex-col gap-4'>
				<div className='flex items-center gap-4'>
					<Label className='w-[135px] text-[14px]'>Select Kitchen:</Label>
					<SelectPicker
						data={FROMPODSTORES?.map((ele) => {
							return {
								...ele,
								label: ele?.title,
								role: ele?.type,
								value: ele?.id,
							};
						})}
						style={{
							width: 224,
						}}
						groupBy='type'
						placeholder={'Select Kitchen '}
						onChange={selectFromPod}
						renderMenuItem={(_) => {
							return (
								<div>
									<i className='rs-icon rs-icon-user' /> {_}
								</div>
							);
						}}
						renderMenuGroup={(_) => {
							return (
								<div>
									<i className='rs-icon rs-icon-group' /> {_}
								</div>
							);
						}}
						cleanable={false}
						value={fromPod}
						renderValue={(_, item) => {
							return (
								<div className='rsuite-select-picker-render-value text-black'>
									<p>
										{'Kitchen:'} {item?.title}
									</p>
								</div>
							);
						}}
					/>
				</div>

				<div className='flex items-center gap-4'>
					<Label className='w-[135px] text-[14px]'>Select Pods:</Label>
					<SelectPicker
						data={TOPODSTORES?.map((ele) => {
							return {
								...ele,
								label: ele?.title,
								role: ele?.type,
								value: ele?.id,
							};
						})}
						style={{
							width: 224,
						}}
						groupBy='type'
						placeholder={'Select Pods'}
						onChange={selectToPod}
						renderMenuItem={(_) => {
							return (
								<div>
									<i className='rs-icon rs-icon-user' /> {_}
								</div>
							);
						}}
						renderMenuGroup={(_) => {
							return (
								<div>
									<i className='rs-icon rs-icon-group' /> {_}
								</div>
							);
						}}
						cleanable={false}
						value={toPod}
						renderValue={(_, item) => {
							return (
								<div className='rsuite-select-picker-render-value text-black'>
									<p>
										{'Pods:'} {item?.title}
									</p>
								</div>
							);
						}}
					/>
				</div>

				<div className='flex items-center gap-4'>
					<Label className='w-30 text-[14px] text-red-600'>Select Date Range:*</Label>
					<UYDateRange
						dateRanges={dateRangeFilterArray}
						onDateRangeChange={setDate}
						enableOnlyBackwardSelection={false}
					/>
				</div>
			</div>
			</BaseDrawer>
	);
}
const mapStateToProps = (state) => {
	const { show, data } = state?.drawer?.exportDrawer;
	const stores = state?.form?.STORES?.data?.LIST;
	return {
		show,
		data,
		stores,
	};
};

const mapDispatchToProps = {
	toggleDrawer: drawerActions.toggleExportDrawer,
	fetchStoresList: formActions.gateway,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ExportDrawer);
