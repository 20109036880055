/* eslint-disable*/
import { get } from 'lodash';
import { ListTable } from '@/Components/ListTable';
import { urls } from '@/Constants';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

function GrnTable(props) {
	const { data, colMap, special, specialMappings, tableType, filters={}, location={} } = props;
	const history = useHistory();

	const getTable = (tableType) => {
		switch(tableType) {
			case 'grnLogs':
				return <ListTable
					colMap={colMap}
					actions={special}
					special={specialMappings}
					data={data.map((obj) => ({
						...obj,
						onClick: (e, row) => {
							if (!e.target.closest('.flex.flex-col.gap-3')) {
								history.push({
									pathname: `${urls.EXTENSION}${urls.GRN_DETAILS}`,
									search: `id=${obj?.id}&storeId=${filters.storeId}&vendor=${filters.vendors}&dateRangeFilter=${JSON.stringify(filters.dateRangeFilter)}`,
								});
							}
						},
						styleCss: {
							cursor: 'pointer',
						}
					}))}
					allowExport={false}
					showBtnContainer={false}
					headerColor={true}
				/>
			
			case 'aiMachineLogs':
				return <ListTable
					colMap={colMap}
					actions={special}
					special={specialMappings}
					data={data.map((obj) => ({
						...obj,
						onClick: () => {
							history.push({
								pathname: `${urls.EXTENSION}${urls.PAST_QC}/${obj.itemId}`,
								search: `id=${obj.id}`,
								state: {
									from: history?.location?.pathname,
								},
							});
						},
						styleCss: {
							cursor: 'pointer',
						}
					}))}
					allowExport={false}
					showBtnContainer={false}
					headerColor={true}
				/>
                case 'orderedProducts':
                    return <ListTable
                        data={data}
                        colMap={colMap}
                        actions={special}
                        special={specialMappings}
                        allowExport={false}
					    showBtnContainer={false}
                    />
                case 'aiMachineGrnLogs':
                    return <ListTable
					colMap={colMap}
					actions={special}
					special={specialMappings}
					data={data.map((obj) => ({
						...obj,
						onClick: () => {
							history.push({
								pathname: `${urls.EXTENSION}${urls.PAST_QC}/${obj.itemId}`,
								search: `id=${obj.id}`,
								state: {
									from: history?.location?.pathname,
								},
							});
						},
						styleCss: {
							cursor: 'pointer',
						}
					}))}
					allowExport={false}
					showBtnContainer={false}
					headerColor={true}
                    />
			
			default:
				return null;
		}
	}

	return getTable(tableType);
}

export default GrnTable;

