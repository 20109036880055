import { IconButton, Tooltip } from '@mui/material';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { useState } from 'react';
import OpenInFull from '@mui/icons-material/OpenInFull';
import CloseFullscreen from '@mui/icons-material/CloseFullscreen';
import { modalActions, navigationActions } from '../../Actions';
import { classes, useWindowDimensions } from '../../Helpers/utils';
import HamburgerToCross from '../Icons/HamburgerToCross/HamburgerToCross';
import { AccountMenu } from '../Menus/AccountMenu';
import styles from './Appbar.module.scss';
import packageJson from '../../../package.json';
import { useAuth } from '../Auth/Login/auth';
import ChangePasswordModal from '../Modals/ChangePasswordModal/ChangePasswordModal';
import { notificationServices } from '../../Services/Notification';
import { authServices } from '../../Services';
import guide from '../../Images/guide.png';

const Appbar = (props) => {
	const { width } = useWindowDimensions();
	const { showSidebar, toggleSidebar, toggleChangePasswordModal, showInternalData } = props;
	const { appConfig, userConfig } = useAuth();
	const { consoleHelpUrl = '' } = appConfig?.configs ?? {};
	const location = useLocation();
	const isDashboard = location?.pathname === '/console2/dashboard';
	// eslint-disable-next-line no-unused-vars
	const [isFullscreen, setIsFullscreen] = useState(false);

	const handleChangePassword = async (state) => {
		const req = {
			data: {
				...state,
				userId: userConfig?.userDetails?.id,
			},
		};
		try {
			const response = await authServices?.changePassword(req);
			if (response?.statusCode === 200) {
				notificationServices?.generateNotification({
					type: 'success',
					message: response?.message,
				});
				toggleChangePasswordModal(false, {});
			} else {
				notificationServices?.generateNotification({
					type: 'error',
					message: response?.message,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	const handleFullscreen = () => {
		if (!document.fullscreenElement) {
			setIsFullscreen(true);
			document.documentElement.requestFullscreen();
		} else {
			setIsFullscreen(false);
			document.exitFullscreen();
		}
	};

	return (
		<>
			{isDashboard && (
				<div
					onClick={handleFullscreen}
					className={styles['hide-show-btn']}
				>
					{!isFullscreen ? (
						<OpenInFull fontSize='1.25rem' />
					) : (
						<CloseFullscreen fontSize='1.25rem' />
					)}
					{/* {visible ? 'Hide Navbar' : 'Hide Navbar'} */}
				</div>
			)}
			<div
				className={classes(
					styles.root,
					showInternalData ? styles.internal : ''
				)}
			>
				<ChangePasswordModal handleConfirm={handleChangePassword} />
				<div className={styles.left}>
					{width < 1200 && (
						<IconButton
							onClick={toggleSidebar}
							className={styles.hamburger}
							size='large'
						>
							<HamburgerToCross open={showSidebar} />
						</IconButton>
					)}
					{(appConfig?.logob64 || appConfig?.logo) && (
						<img
							className={styles['client-logo']}
							src={appConfig?.logob64 ?? appConfig?.logo}
							alt={appConfig?.companyName}
						/>
					)}
					<p className={styles.heading}>
						Console
						<span>
							v
							{packageJson?.version}
						</span>
					</p>
				</div>
				<div
					className={styles.right}
					style={{
						paddingRight: '7rem',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						gap: '8px',
					}}
				>
					<AccountMenu
						handleChangePassword={() => {
							toggleChangePasswordModal(true, {});
						}}
					/>
					{consoleHelpUrl && (
						<Tooltip title='Helper documentation for smooth uy console operation'>
							<div className='w-[40px] h-[40px] cursor-pointer relative top-[4px]'>
								<a href={consoleHelpUrl} target='_blank' rel='noopener noreferrer'>
									<img className='w-full h-full' src={guide} alt='consoleDocumentation' />
								</a>
							</div>
						</Tooltip>
					)}
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	const { showSidebar } = state;
	const { showInternalData } = state?.navigation;
	return {
		showSidebar,
		showInternalData,
	};
};

const mapDispatchToProps = {
	toggleSidebar: navigationActions.toggleSidebar,
	toggleChangePasswordModal: modalActions.toggleChangePasswordModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Appbar);
