/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable */
import { useEffect, useState, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
// import _ from 'lodash';
import { Button } from '@mui/material';
import { Upload } from 'lucide-react';
import styles from './MyAuditsPage.module.scss';
import { BackdropLoader } from '../../../Components/Loaders';
import ListTable from '../../../Components/ListTable/ListTable';
import { notificationServices } from '../../../Services';
import { classes, handleResponseStreamDownload } from '../../../Helpers';
import ImagesFinder from '../../../Components/ImagesFinder/ImagesFinder';
import auditServices from '../../../Services/audit-services';
import { AuditItemsModal } from '../../../Components/Modals/AuditItemsModal';
import AuditCreationModal from '../../../Components/Modals/AuditCreationModal/AuditCreationModal';
import UploadDataModal from '../../../Components/Modals/UploadDataModal/UploadDataModal';
import excel from '../../../Images/excel.svg';
import { formActions, modalActions } from '@/Actions';
import { useAuth } from '@/Components/Auth/Login/auth';
import userRolesAndPermissions from '@/Services/userRolesAndPermissions.services';
import authActions from '@/Actions/auth.actions';

const TABLE_COLUMNS = [
	{
		title: 'Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Type',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Comment',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Expected Stock',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Audit Count',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Wastage',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Dispatched',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Status',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Created At / Activated At',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Created By',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Last Action By',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [
	{
		title: 'Scheduled/Start',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Scanning Completed At',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Stock Adjustment At',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Store Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Bulk Action',
		config: {
			type: 'DATA',
		},
	},
];

const ACTIONS_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'VIEW',
		},
	},
	{
		title: 'Action',
		config: {
			type: 'CLOSE',
		},
	},
];

const COLMAP = {
	Id: 'id',
	Name: 'name',
	Type: 'subType',
	Comment: 'closeComment',
	'Created At / Activated At': 'id',
	Start: 'startDate',
	'Scanning Completed At': 'endDate',
	'Stock Adjustment At': 'stockAdjustmentAt',
	'Store Name': 'storeName',
	Status: 'id',
	'Expected Stock': 'auditStatus.total',
	'Audit Count': 'auditStatus.audited',
	Wastage: 'auditStatus.wastage',
	Dispatched: 'auditStatus.dispatched',
	'Created By': 'createdByName',
	'Last Action By': 'lastActionByName',
	'Bulk Action': 'id',
};

const FIND_FILTERS = ['filteredStore', 'dateRangeOnly', 'status'];

const statusList = [
	{
		id: 'COMPLETED',
		title: 'COMPLETED',
	},
	{
		id: 'ACTIVE',
		title: 'ACTIVE',
	},
	{
		id: 'SCHEDULED',
		title: 'SCHEDULED',
	},
	{
		id: 'CLOSED',
		title: 'SCANNING DONE',
	},
	{
		id: 'CANCELLED',
		title: 'CANCELLED',
	},
];

function MyAuditsPage(props) {
	const { userConfig } = useAuth();
	const {
		toggleModal,
		toggleAuditCreationModal,
		toggleAlert,
		fetchProducts,
		productsList,
		showUploadDataModal,
		payload,
		toggleUploadDataModal,
		navState,
		userList,
		fetchUserList,
	} = props;
	const [isLoading, setIsLoading] = useState(false);
	const [auditsList, setAuditList] = useState([]);
	const [filters, setFilters] = useState({
		store: null,
		dateRange: {
			from: moment(new Date())?.format('YYYY-MM-DD'),
			to: moment(new Date())?.format('YYYY-MM-DD'),
		},
	});
	const exportFilters = useRef();
	// const map = new Map(userList.map((item) => [item.value, item.label]));
	// const role = Object.keys(userConfig?.userRoles);
	// const userRoles = [];
	// role.forEach((ele) => {
	// 	userRoles.push(ele);
	// });
	// const pageRole = navState['/account']?.submenu['/account/my-audits']?.rolesMapped;
	// const findMatchingRole = userRoles.filter((ele) => pageRole.includes(Number(ele)));
	// const roleDetails = findMatchingRole.map((role) => {
	// 	const key = Number(role);
	// 	const name = map.get(key);
	// 	return {
	// 		role: key, name,
	// 	};
	// });
	// const isInternal = roleDetails.some((item) => item.name === 'Internal' || item.name === 'CXO Office');
	// const access = findMatchingRole.length > 0 ? userConfig?.userDetails?.level : false;
	// let permit;
	// if (access === false) {
	// 	permit = false;
	// } else {
	// 	permit = isInternal && access > 2;
	// }

	useEffect(() => {
		fetchProducts({
			entity: 'ALL_PRODUCTS',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		// fetchUserList();
	}, []);

	const fetchAudits = async (request) => {
		setFilters({
			store: request?.store,
			dateRange: {
				from: moment(request?.dateRange?.from)?.format('YYYY-MM-DD'),
				to: moment(request?.dateRange?.to)?.format('YYYY-MM-DD'),
			},
		});
		const req = {
			data: {
				filters: {
					storeId: request?.store,
					dateRangeFilter: {
						from: moment(request?.dateRange?.from)?.format(
							'YYYY-MM-DD'
						),
						to: moment(request?.dateRange?.to)?.format(
							'YYYY-MM-DD'
						),
					},
					status: request?.status,
				},
			},
		};
		try {
			setIsLoading(true);
			const response = await auditServices?.fetchAuditList(req);
			if (response?.statusCode === 200) {
				setAuditList(
					response?.data?.map((ele) => {
						return {
							...ele,
							startDate: ele?.createdAt
								? moment(ele?.createdAt)?.format(
										'YYYY-MM-DD hh:mm:ss A'
								  )
								: '-',
							endDate: ele?.closedAt
								? moment(ele?.closedAt)?.format(
										'YYYY-MM-DD hh:mm:ss A'
								  )
								: '-',
						};
					})
				);
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleViewClick = async (currItem, disabled) => {
		const req = {
			data: {
				auditId: currItem?.id,
			},
		};
		try {
			setIsLoading(true);
			const response = await auditServices?.fetchAuditItems(req);
			if (response?.statusCode === 200) {
				toggleModal(true, {
					items: response?.data?.report,
					auditId: currItem?.id,
					storeId: currItem?.storeId,
					isDisabled: disabled,
					hideButtons: disabled,
					showInitiatedAuditItems: currItem?.status === 'SCHEDULED',
				});
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};
	const handleScheduledViewClick = async (currItem) => {
		const req = {
			data: {
				auditId: currItem?.id,
			},
		};
		try {
			setIsLoading(true);
			const response = await auditServices?.fetchScheduledAuditItems(req);
			if (response?.statusCode === 200) {
				let selectedProducts = productsList;
				if (currItem?.productIds && currItem?.productIds?.length) {
					selectedProducts = currItem?.productIds?.map((ele) => {
						const product = productsList?.find((proItem) => {
							return proItem?.id === ele;
						});
						return product;
					});
				}
				toggleModal(true, {
					items: selectedProducts,
					categories: response?.data?.selectedCategories,
					skus: response?.data?.selectedSkus,
					auditId: currItem?.id,
					storeId: currItem?.storeId,
					storeName: currItem?.storeName,
					isDisabled: true,
					hideButtons: true,
					showGeneralDetails: true,
					showInitiatedAuditItems: currItem?.status === 'SCHEDULED',
				});
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	const onViewClick = (currItem) => {
		if (currItem?.status === 'SCHEDULED') {
			handleScheduledViewClick(currItem);
		} else {
			handleViewClick(currItem, true);
		}
	};

	const handleCancelAudit = async (currItem) => {
		const req = {
			data: {
				auditId: currItem?.id,
				storeId: currItem?.storeId,
				status: 'CANCELLED',
			},
		};
		try {
			setIsLoading(true);
			const response = await auditServices?.cancelAudit(req);
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
				fetchAudits(filters);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleExcelDownload = useCallback((header, fileData, fileName) => {
		const commonColumns = [];
		const downloadBtn = document.createElement('a');
		const csvData = [[...commonColumns, ...header], ...fileData];

		const csv = `${csvData
			.map((e) => {
				return e.join(',');
			})
			.join('\n ')}`;

		const encodedValue = new Blob([csv], {
			type: 'text/csv',
		});
		downloadBtn.href = window.URL.createObjectURL(encodedValue);
		downloadBtn.target = '_blank';
		downloadBtn.download = fileName;
		downloadBtn.click();
	}, []);

	const handleExportNonQrItems = async (data) => {
		const headers = [
			'Item Code',
			'UY Item Id',
			'Store Id',
			'Store Code',
			// 'Store Name',
			'Product Name',
			'Variant Name',
			'unit',
			'Current Stock',
		];
		const exportData = [];
		data?.items?.forEach((item) => {
			exportData.push([
				item?.clientVariantId,
				item?.variantId,
				data?.storeId,
				data?.clientStoreId,
				item?.productName,
				item?.variantName,
				item?.unit,
				item?.currentStock,
			]);
		});
		handleExcelDownload(
			headers,
			exportData,
			`Audit Report of Non QR Items for ${data?.storeName}`
		);
	};

	const handleExport = async (auditId) => {
		try {
			setIsLoading(true);
			const req = {
				data: {
					auditId,
				},
			};
			const response = await auditServices.fetchNonQrItems(req);
			if (response?.statusCode === 200) {
				handleExportNonQrItems(response?.auditReport);
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Some error occured while exporting data',
			});
		} finally {
			setIsLoading(false);
		}
	};

	const handleImport = async (auditId, uploadFile, storeId) => {
		try {
			setIsLoading(true);
			const req = {
				data: {
					auditId,
					updatedAudit: uploadFile?.file,
					storeId,
				},
			};
			const response = await auditServices.uploadNonQrItems(req);
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: response?.message,
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Some error occured while uploading data',
			});
		} finally {
			setIsLoading(false);
		}
	};

	const uploadAuditReport = (auditId, storeId) => {
		toggleUploadDataModal(true, {
			heading: 'Upload Audit Report of Non QR Items',
			auditId,
			storeId,
		});
	};

	const SPECIAL_ITEMS = {
		Type: (_, obj) => {
			return obj?.subType?.split('_')?.join(' ') ?? 'AUDIT';
		},
		'Created At / Activated At': (id) => {
			const currItem = auditsList?.find((ele) => {
				return ele?.id === id;
			});
			return moment(currItem?.createdAt).format('YYYY-MM-DD hh:mm A');
		},
		'Scheduled/Start': (id) => {
			const currItem = auditsList?.find((ele) => {
				return ele?.id === id;
			});
			if (!currItem?.scheduledAt && !currItem?.startDate) return '-';
			if (currItem?.scheduledAt) {
				return moment(new Date(currItem?.scheduledAt)).format(
					'YYYY-MM-DD hh:mm A'
				);
			}
			return currItem?.startDate;
		},
		'Scanning Completed At': (id) => {
			const currItem = auditsList?.find((ele) => {
				return ele?.id === id;
			});
			return (
				<div className={styles['edit-container']}>
					<p>{currItem?.endDate ?? '-'}</p>
				</div>
			);
		},
		'Stock Adjustment At': (id) => {
			const currItem = auditsList?.find((ele) => {
				return ele?.id === id;
			});
			return (
				<div className={styles['edit-container']}>
					<p>
						{currItem?.stockAdjustmentAt
							? moment(
									new Date(currItem?.stockAdjustmentAt)
							  ).format('YYYY-MM-DD hh:mm A')
							: '-'}
					</p>
				</div>
			);
		},
		Status: (id) => {
			const currItem = auditsList?.find((ele) => {
				return ele?.id === id;
			});
			if (currItem?.status === 'CLOSED') {
				return 'SCANNING DONE';
			}
			return currItem?.status ?? '-';
		},
		'Store Name': (id) => {
			const currItem = auditsList?.find((ele) => {
				return ele?.id === id;
			});
			return (
				<div
					style={{
						width: 125,
					}}
				>
					{currItem?.storeName}
				</div>
			);
		},
		'Bulk Action': (id) => {
			const currItem = auditsList?.find((ele) => {
				return ele?.id === id;
			});
			// const showImport = isInternal && userConfig?.userDetails?.level === 2;
			return (
				<div className='flex flex-col gap-2'>
					{(currItem?.status === 'CLOSED' ||
						currItem?.status === 'ACTIVE') && (
						<Button
							className='text-xs'
							variant='contained'
							color='primary'
							onClick={() => handleExport(currItem.id)}
						>
							<div className='flex items-start justify-start gap-1 text-sx'>
								<img
									className='size-4'
									src={excel}
									alt='excel'
								/>
								Export Non QR
							</div>
						</Button>
					)}
					{(currItem?.status === 'CLOSED' ||
						currItem?.status === 'ACTIVE') && (
						<Button
							className='text-xs'
							variant='contained'
							color='primary'
							onClick={() =>
								uploadAuditReport(currItem.id, currItem.storeId)
							}
						>
							<div className='flex items-center justify-start gap-1 text-sx'>
								<Upload className='size-4' />
								Import Non QR
							</div>
						</Button>
					)}
				</div>
			);
		},
		Action: (id) => {
			const currItem = auditsList?.find((ele) => {
				return ele?.id === id;
			});
			return (
				<div className={styles['action-btns']}>
					{currItem?.status === 'CLOSED' && (
						<Button
							variant='outlined'
							color='primary'
							onClick={() => {
								toggleAlert(true, {
									heading: 'Complete Audit',
									handleConfirm: () => {
										handleViewClick(
											currItem,
											currItem?.status !== 'CLOSED'
										);
										toggleAlert(false);
									},
									colorReverse: false,
									cancelText: 'No',
									confirmText: 'Yes',
									message: `Are you sure you to Complete ${
										currItem?.id || ''
									}?`,
								});
							}}
						>
							Complete
						</Button>
					)}
					{!currItem?.status?.includes('COMPLETED') &&
						currItem?.status !== 'CANCELLED' && (
							<Button
								variant='outlined'
								color='error'
								onClick={() => {
									toggleAlert(true, {
										heading: 'Cancel Audit',
										handleConfirm: () => {
											handleCancelAudit(currItem);
											toggleAlert(false);
										},
										colorReverse: false,
										cancelText: 'No',
										confirmText: 'Yes',
										message: `Are you sure you to Cancel ${
											currItem?.id || ''
										}?`,
									});
								}}
							>
								Cancel
							</Button>
						)}
				</div>
			);
		},
	};

	// eslint-disable-next-line func-names
	const rowColor = function (status) {
		switch (status) {
			case 'ACTIVE':
				return styles.active;
			case 'COMPLETED':
			case 'COMPLETED - Stocking Out':
				return styles.complete;
			case 'CANCELLED':
				return styles.cancel;
			case 'SCHEDULED':
				return styles.scheduled;
			case 'CLOSED':
				return styles.done;
			default:
				return null;
		}
	};

	const handleAdjustStock = async (auditId, storeId, adjustments) => {
		const req = {
			data: {
				auditId,
				storeId,
				adjustments,
			},
		};
		try {
			const response = await auditServices.closeAudit(req);
			if (response?.statusCode === 200) {
				fetchAudits(filters);
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message ?? 'Success',
				});
				toggleModal(false, {});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Some error occured while adjusting stock',
			});
		}
	};

	const handleAddClick = () => {
		toggleAuditCreationModal(true, {});
	};

	const handleConfirmCreation = async (auditCreationState) => {
		setIsLoading(true);
		const req = {
			data: {
				scheduled:
					auditCreationState?.scheduled !== 'NOW'
						? auditCreationState?.scheduled
						: null,
				storeIds: auditCreationState?.selectedStores,
				name: auditCreationState?.auditName,
				products: !auditCreationState?.isAllProduct
					? auditCreationState?.productsList
					: null,
				productSelection: auditCreationState?.productSelection,
				allProducts: auditCreationState?.isAllProduct,
			},
		};
		try {
			const response = await auditServices.createAudit(req);
			if (response?.statusCode === 200) {
				fetchAudits(filters);
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message ?? 'Success',
				});
				toggleModal(false, {});
				toggleAuditCreationModal(false, {});
			} else if (response?.statusCode === 300) {
				notificationServices.generateNotification({
					type: 'error',
					message: response?.errorMessage,
				});
				// fetchAudits({
				// store: storeId,
				// dateRange: {
				// from: moment(
				// new Date().setDate(new Date().getDate() - 10)
				// )?.format('YYYY-MM-DD'),
				// to: moment(new Date())?.format('YYYY-MM-DD'),
				// },
				// });
				toggleAuditCreationModal(false, {});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleAuditExits = (storeId) => {
		setFilters({
			storeId,
			dateRange: {
				from: moment(new Date())?.format('YYYY-MM-DD'),
				to: moment(
					new Date().setDate(new Date().getDate() - 10)
				)?.format('YYYY-MM-DD'),
			},
		});
	};
	const handleOnUpdate = (finderFilters) => {
		const dateRange = {
			startDate: moment(finderFilters?.dateRange?.from).format(
				'YYYY-MM-DD'
			),
			endDate: moment(finderFilters?.dateRange?.to).format('YYYY-MM-DD'),
		};
		const req = {
			data: {
				filters: {
					storeId: finderFilters?.store,
					dateRangeFilter: {
						from: moment(finderFilters?.dateRange?.from).format(
							'YYYY-MM-DD'
						),
						to: moment(finderFilters?.dateRange?.to).format(
							'YYYY-MM-DD'
						),
					},
					status: finderFilters?.status,
				},
			},
		};
		exportFilters.current = req.data.filters;
	};
	const handleExportData = async () => {
		const req = {
			data: exportFilters.current,
		};
		try {
			setIsLoading(true);
			const response = await auditServices?.auditExport(req);
			handleResponseStreamDownload(response, 'Audit.csv');
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: response.error,
			});
		} finally {
			setIsLoading(false);
		}
	};
	return (
		<div className={styles.root}>
			<AuditItemsModal handleConfirm={handleAdjustStock} />
			<UploadDataModal
				show={showUploadDataModal}
				heading={payload?.heading}
				storeId={payload?.storeId}
				auditId={payload?.auditId}
				handleImport={handleImport}
			/>
			<AuditCreationModal
				handleConfirm={handleConfirmCreation}
				onAuditExists={handleAuditExits}
			/>
			<BackdropLoader showProgress isLoading={isLoading} />
			<div className={styles?.header}>
				<ImagesFinder
					pageLabel='My Audits'
					FILTERS={FIND_FILTERS}
					onSubmit={fetchAudits}
					dateRangeOnlyText='Scheduled Or Created At'
					statusList={statusList}
					onUpdate={handleOnUpdate}
					defaultState={{
						STORE: filters.store ?? -999,
						DATE_RANGE: {
							active: true,
							from: new Date(filters?.dateRange?.from),
							to: new Date(filters?.dateRange?.to),
						},
					}}
					additionInfo={{
						showAdd: true,
						addText: 'Audit',
						prefix: 'Start',
						handleAddClick,
					}}
				/>
			</div>
			<div className='flex gap-3 w-full justify-end items-center float-right py-1'>
				<div className='flex items-center'>
					<span>Total: </span>
					<p>{auditsList?.length}</p>
				</div>
				<div
					onClick={() => {
						handleExportData();
					}}
					className=' w-[125px] text-center cursor-pointer flex items-center justify-center gap-1 z-10 bg-green-700 text-white rounded-xl py-1 px-2'
				>
					<img src={excel} alt='excel' className='w-6' />
					EXPORT
				</div>
			</div>

			<div className={styles['table-container']}>
				<ListTable
					colMap={COLMAP}
					columns={TABLE_COLUMNS}
					data={auditsList?.map((ele) => ({
						...ele,
						rowStyles: classes(
							styles.listItem,
							rowColor(ele?.status)
						),
					}))}
					actions={[...SPECIAL_COLUMNS, ...ACTIONS_COLUMNS]}
					special={SPECIAL_ITEMS}
					handleViewClick={(currItem) => {
						onViewClick(currItem);
					}}
					fixedHeaderTop='0'
					allowExport={false}
				/>
			</div>
		</div>
	);
}
const mapStateToProps = (state) => {
	const { userPermissions, navState } = state.navigation;
	const { LIST: productsList } = state.form.ALL_PRODUCTS.data;
	const { uploadDataModal } = state.modal;
	const showUploadDataModal = uploadDataModal?.show;
	const payload = uploadDataModal?.data;
	const { userList } = state?.auth;
	return {
		navState,
		userPermissions,
		productsList,
		showUploadDataModal,
		payload,
		userList,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleAuditItemsModal,
	toggleAuditCreationModal: modalActions.toggleAuditCreationModal,
	toggleAlert: modalActions.toggleAlert,
	fetchProducts: formActions.gateway,
	toggleUploadDataModal: modalActions.toggleUploadDataModal,
	fetchUserList: authActions?.fetchUserList,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAuditsPage);
