/* eslint-disable no-shadow */
/* eslint-disable */
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Add } from '@mui/icons-material';
// import { Button } from '@mui/material';
import Button from 'rsuite/Button';
import Accordion from 'rsuite/Accordion';
import Input from 'rsuite/Input';
import styles from './AllVendorsListPage.module.scss';
import { AddNewVendorModal } from '../../Components/Modals/AddNewVendorModal';
import AddressPanel from './AddressPanel';
import { formActions, modalActions } from '../../Actions';
import { AlertModal } from '../../Components/Modals';
import excel from '../../Images/excel.svg';
import allVendorServices from '@/Services/Form Services/allvendor.services';
import { handleResponseStreamDownload } from '@/Helpers';
import { useAuth } from '@/Components/Auth/Login/auth';
import { notificationServices } from '@/Services';
import authActions from '@/Actions/auth.actions';
import { BackdropLoader } from '@/Components/Loaders';

const AllVendorListPage = (props) => {
	const {
		fetchVendors,
		data,
		buyersData,
		deleteVendor,
		toggleAlert,
		toggleAddVendorModal,
		type,
		navState,
		fetchUserList,
		userList,
	} = props;
	const { appConfig, userConfig } = useAuth();
	const [activeVendor, setActiveVendor] = useState(null);
	const [searchQuery, setSearchQuery] = useState('');
	const [loading, setLoading] = useState(false);

	// USER START
	// userAcess
	// useEffect(async () => {
	// 	fetchUserList();
	// }, []);
	// // function page role of user access
	// function pageRoleMap(pageName) {
	// 	switch (pageName) {
	// 		case 'VENDOR':
	// 			return navState['/vendors']?.submenu['/vendors']?.rolesMapped;
	// 		default:
	// 			return navState['/buyers']?.submenu['/buyers']?.rolesMapped;
	// 	}
	// }

	// const map = new Map(userList.map((item) => [item.value, item.label]));
	// const role = Object.keys(userConfig?.userRoles);
	// const userRoles = [];
	// role.forEach((ele) => {
	// 	userRoles.push(ele);
	// });
	// const pageRole = pageRoleMap(type);
	// const findMatchingRole = userRoles.filter((ele) => pageRole.includes(Number(ele)));
	// const roleDetails = findMatchingRole.map((role) => {
	// 	const key = Number(role);
	// 	const name = map.get(key);
	// 	return {
	// 		role: key, name,
	// 	};
	// });
	// const isInternal = roleDetails.some((item) => item.name === 'Internal' || item.name === 'CXO Office');
	// const access = findMatchingRole.length > 0 ? userConfig?.userDetails?.level : false;
	// let permit;
	// if (access === false) {
	// 	permit = false;
	// } else {
	// 	permit = isInternal && access !== 1;
	// }
	// USER END

	const handleDelete = ({ vendor }) => {
		toggleAlert(true, {
			heading: 'Delete Vendor',
			height: '10rem',
			handleConfirm: () => {
				deleteVendor({
					entity: 'ALL_VENDORS',
					req: {
						params: {
							id: vendor?.id,
						},
					},
					method: 'DELETE',
				});
				toggleAlert(false);
			},
			colorReverse: true,
			cancelText: 'Cancel',
			confirmText: 'Delete',
			message: `Are you sure want to Delete Vendor - ${vendor?.name}?`,
		});
	};

	const fetchVendorsData = () => {
		fetchVendors({
			entity: type === 'VENDOR' ? 'ALL_VENDORS' : 'ALL_BUYERS',
			req: {
				params: {
					id: '',
				},
			},
			type,
			method: 'LIST',
		});
	};

	useEffect(() => {
		fetchVendorsData();
	}, [type]);
	const handleEditClick = (vendor) => {
		// eslint-disable-next-line no-console
		toggleAddVendorModal(true, {
			heading: type === 'VENDOR' ? 'Edit Vendor' : 'Edit Buyer',
			vendorData: vendor,
			isEdit: true,
			type,
		});
	};

	const vendorHeader = (vendor) => {
		return (
			<div className={styles.vendor_header}>
				<div className={styles.vendor_details}>
					<div className={styles.name_company}>
						<p className={styles.vendor_name}>{vendor?.name}</p>

						<p>{' (ID: ' + vendor?.id + ')'}</p>
						{vendor?.companyName && (
							<p>
								Company:
								{vendor?.companyName}
							</p>
						)}
						{vendor?.isExpenseAllowed && (
							<p className={styles.expense_allowed}>
								Allowed For Expenses
							</p>
						)}
					</div>
					<div className={styles.details}>
						<div className={styles.field}>
							<label className=''>{type === 'VENDOR' ? 'Vendor Type:' : 'Buyer Type'}</label>
							<p>{vendor?.vendorType}</p>
						</div>
						<div className={styles.field}>
							<label className=''>{type === 'VENDOR' ? 'Vendor Sub Type:' : 'Buyer Sub Type:'}</label>
							<p>{vendor?.subType}</p>
						</div>
					</div>
					{type === 'BUYER' && vendor?.paymentTermCode && (
						<div className={styles.details}>
							<div className={styles.field}>
								<label className=''>Payment Term Code</label>
								<p>{vendor?.paymentTermCode}</p>
							</div>
						</div>
					)}
				</div>
				<div className={styles.btns_container}>
					<Button
						onClick={() => {
							handleEditClick(vendor);
						}}
						appearance='ghost'
					>
						Edit
					</Button>
					<Button
						onClick={() => {
							handleDelete({
								vendor,
							});
						}}
						appearance='ghost'
						color='red'
					>
						Delete
					</Button>
				</div>
			</div>
		);
	};

	const handleExport = async (list) => {
		try {
			setLoading(true);
			let response;
			const name = type === 'VENDOR' ? 'VENDOR' : 'BUYER';
			if (type === 'VENDOR') {
				response = await allVendorServices.exportVendors({
					req: {
						data: list,
					},
				});
			} else {
				response = await allVendorServices.exportBuyers({
					req: {
						data: list,
					},
				});
			}
			if (response.status === 200) {
				handleResponseStreamDownload(
					response,
					`${name}_${appConfig?.companyName
						?.split(' ')
						?.join('_')
						?.toUpperCase()}.csv`
				);
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Some error occurred',
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error,
			});
		}finally{
			setLoading(false);
		}
	};

	return (
		<div className={styles.root}>
			<AddNewVendorModal fetchVendorsData={fetchVendorsData} pageType={type} />
			<BackdropLoader showProgress isLoading={loading} />
			<AlertModal />
			<div className={styles.header}>
				<div className={styles.header_search}>
					<p className={styles['header-title']}>
						{type === 'VENDOR' ? 'All Vendors' : 'All Buyers'}
					</p>
					<div className={styles.filters}>
						<Input
							value={searchQuery}
							onChange={(value) => {
								setSearchQuery(value);
							}}
							style={{
								width: 250,
								borderRadius: 25,
								border: '1px solid #000',
							}}
							placeholder={type === 'VENDOR' ? 'Search Vendor/Location Name' : 'Search Buyer/Location Name'}
						/>
					</div>
				</div>
				<button
					onClick={() => {
						toggleAddVendorModal(true, {
							type,
						});
					}}
					type='button'
					className={styles['add-btn']}
				>
					<Add />
					{type === 'VENDOR' ? 'Add Vendor' : 'Add Buyer'}
				</button>
			</div>
			<div className='flex justify-end w-full p-2'>
				<div
					onClick={() => {
						// eslint-disable-next-line no-undef
						handleExport(type === 'VENDOR' ? data : buyersData);
					}}
					className={styles.downloadData}
				>
					<img src={excel} alt='excel' />
					EXPORT
				</div>
			</div>
			<Accordion
				activeKey={activeVendor}
				bordered
				onSelect={setActiveVendor}
			>
				{(type === 'VENDOR' ? data : buyersData)
					.filter((vendor) => {
						return (
							vendor?.name
								?.toLowerCase()
								?.indexOf(
									searchQuery?.trim()?.toLowerCase()
								) !== -1 ||
							vendor?.vendor_locations?.some((location) => {
								return (
									location?.name
										?.toLowerCase()
										?.indexOf(
											searchQuery?.trim()?.toLowerCase()
										) !== -1
								);
							})
						);
					})
					.map((vendor) => {
						return (
							<Accordion.Panel
								header={vendorHeader(vendor)}
								eventKey={vendor?.id}
							>
								<div className={styles.accordion_body}>
									<div className={styles.locations}>
										{vendor?.vendor_locations?.map(
											(ele) => {
												return (
													<div
														className={
															styles.location
														}
													>
														<div
															className={
																styles.location_header
															}
														>
															<p
																className={
																	styles.location_name
																}
															>
																{ele?.name}
															</p>
															<p
																className={
																	styles.location_name
																}
															>
																GSTIN:
																{' '}
																{ele?.gstin}
															</p>
														</div>
														<div
															className={
																styles.location_detatils
															}
														>
															<div
																className={
																	styles.field
																}
															>
																<label>
																	Location
																	Email:
																</label>
																<p>
																	{
																		ele?.vendorEmail
																	}
																</p>
															</div>
															<div
																className={
																	styles.field
																}
															>
																<label>
																	Location
																	Phone:
																</label>
																<p>
																	{
																		ele?.vendorPhone
																	}
																</p>
															</div>
														</div>
														<AddressPanel
															addressDetails={{
																addressType:
																	'Billing Address',
																address1:
																	ele?.billingAddress1,
																address2:
																	ele?.billingAddress2,
																city: ele?.billingAddressCity,
																state: ele?.billingAddressState,
																pincode:
																	ele?.billingAddressPinCode,
															}}
														/>
														<AddressPanel
															addressDetails={{
																addressType:
																	'Pickup Address',
																address1:
																	ele?.shippingAddress1,
																address2:
																	ele?.shippingAddress2,
																city: ele?.shippingAddressCity,
																state: ele?.shippingAddressState,
																pincode:
																	ele?.shippingAddressPinCode,
															}}
														/>
													</div>
												);
											}
										)}
									</div>
								</div>
							</Accordion.Panel>
						);
					})}
			</Accordion>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.ALL_VENDORS.data.LIST;
	const buyersData = state.form.ALL_BUYERS.data.LIST;
	const { navState, userPermissions } = state.navigation;
	const { userList } = state.auth;
	return {
		data,
		buyersData,
		navState,
		userPermissions,
		userList,
	};
};

const mapDispatchToProps = {
	fetchVendors: formActions.gateway,
	deleteVendor: formActions.gateway,
	toggleAddVendorModal: modalActions.toggleAddVendorModal,
	toggleAlert: modalActions.toggleAlert,
	fetchUserList: authActions.fetchUserList,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllVendorListPage);
