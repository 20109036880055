/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable prefer-template */
import {
	Check,
	WarningRounded,
	FormatItalic,
	Edit,
	DeviceThermostat,
	Close,
	DoDisturbAlt,
	Report,
} from '@mui/icons-material';
// eslint-disable-next-line object-curly-newline
import { Badge, Button, IconButton, Tooltip } from '@mui/material';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';
import { formActions, modalActions } from '../../../../Actions';
import { modalTypes, urls } from '../../../../Constants';
import { classes } from '../../../../Helpers';
import invalidMeal from '../../../../Images/invalidMeal.svg';
import CMSRecipeItemsList from '../VideoFeed/CMSRecipeItemsList/CMSRecipeItemsList';
import compIden from '../../../../Images/compIden.png';
import crate from '../../../../Images/crate.png';
import styles from './FeedHUD.module.scss';
import GenericItemList from '../GenericItemList/GenericItemList';
import duplicateIcon from '../../../../Images/duplicateIcon.png';

const URL_TYPE = {
	QMS: 'QMS_ANNOTATION',
	IMS: 'IMS_ANNOTATION',
};

const FeedHUD = (props) => {
	const {
		result,
		// identifiedProducts,
		// orderId,
		// itemId,
		currentIndex,
		showAnnotation,
		showRetag,
		showValidate,
		showFirstTimeValidate,
		toggleRetagModal,
		scanId,
		identification,
		features,
		identity,
		validateSKU,
		type,
		setTempVisibility,
		tempVisibility,
		sensorValues,
		isDuplicate,
		hideAnnotation,
		ingredients,
		isCms,
		weightByComponents,
		currentRecipe,
		setCurrentRecipe,
		message,
		productsList,
		getPastQCDetails,
		isWastage,
		wastageReason,
		// fetchProducts,
		container,
		onDuplicacy = () => {},
		duplicateActionBy,
		unit,
		recipe,
	} = props;

	const { clientType } = JSON.parse(localStorage.getItem('appConfig'));

	const isIdentified = useMemo(() => {
		return (
			identification?.x != null &&
			identification?.y != null &&
			identification?.width != null &&
			identification?.height != null
		);
	}, [identification]);

	// useEffect(() => {
	// if (productsList?.length < 1) {
	// fetchProducts({
	// entity: 'ALL_PRODUCTS',
	// req: {
	// params: {
	// id: '',
	// },
	// },
	// method: 'LIST',
	// });
	// }
	// }, [productsList]);

	return (
		<div className={classes(styles.root, styles[result])}>
			{isCms ? (
				<div className={styles['ingredients-images']}>
					<CMSRecipeItemsList
						currentRecipe={currentRecipe}
						onClick={setCurrentRecipe}
						ingredients={ingredients}
						unit={unit}
						getPastQCDetails={getPastQCDetails}
						isCms={isCms}
						recipe={recipe}
					/>
				</div>
			) : (
				result && (
					<div className={classes(styles.qcDone, styles[result])}>
						<p>{message}</p>
					</div>
				)
			)}
			{weightByComponents && (
				<div className={styles.itemsBar}>
					<GenericItemList
						productsList={productsList}
						idKeyValueData={weightByComponents}
					/>
				</div>
			)}

			{isWastage &&
				(Array.isArray(wastageReason) && wastageReason?.length > 0 ? (
					<div className={styles?.wastageReasons}>
						<div className={styles?.text}> Wastage reasons: </div>
						{wastageReason?.map((reason) => {
							return (
								<Tooltip title={reason} arrow followCursor>
									<div className={styles.reason}>
										<div />
										{reason &&
											reason?.substring(0, 15) + '...'}
									</div>
								</Tooltip>
							);
						})}
					</div>
				) : (
					<div className={styles?.wastageReasons}>
						<div className={styles?.text}> Wastage reason: </div>
						<Tooltip title={wastageReason} arrow followCursor>
							<div className={styles.reason}>
								<div />
								{wastageReason &&
									wastageReason?.substring(0, 15) + '...'}
							</div>
						</Tooltip>
					</div>
				))}
			{isDuplicate && (
				<div className={styles.duplicate}>
					{/* <img src={invalidIcon} alt="invalid" /> */}
					<div className={styles.wrapper}>
						<div className={styles['text-wrapper']}>
							<img src={invalidMeal} alt='invalid' />
							<p>Potential duplicate</p>
						</div>
					</div>
				</div>
			)}
			{duplicateActionBy && (
				<div className={styles.marked_by}>
					<p>Marked {isDuplicate ? 'Duplicate' : 'Non-Duplicate'} by: {duplicateActionBy}</p>
				</div>
			)}
			{/* <div className={classes(styles.left, styles.stat)}>
				<span>
					{`${orderId != null ? `${orderId}/` : ''}
				${itemId ?? ''}`}
				</span>
				<span>{result}</span>
			</div> */}
			{result !== 'SKIPPED' && !hideAnnotation && (
				<>
					{(isIdentified || isWastage) && showAnnotation && (
						<div
							className={classes(
								styles.features,
								styles['action-wrapper']
							)}
						>
							<Link
								className={styles.link}
								to={`${urls.EXTENSION}${
									urls[URL_TYPE[type]]
								}/${currentIndex}?id=${scanId}&ctx=FEATURES&type=QUALITY${
									isCms
										? `&CMS=true&recipeId=${currentRecipe?.qcId}`
										: ''
								}`}
							>
								<Badge
									className={styles.action}
									badgeContent={
										features?.length ?? features ?? 0
									}
									color='error'
								>
									<Tooltip title='Features' arrow>
										<Button
											className={styles.action}
											variant='contained'
											color='primary'
										>
											<WarningRounded fontSize='large' />
										</Button>
									</Tooltip>
								</Badge>
							</Link>
						</div>
					)}
					{(clientType === 'SCHOOL' || showRetag) && (
						<div
							className={classes(
								styles.retag,
								styles['action-wrapper']
							)}
						>
							<Link
								className={styles.link}
								to={
									clientType === 'SCHOOL'
										? `${urls.EXTENSION}${
												urls[URL_TYPE[type]]
										  }/${currentIndex}?id=${scanId}&ctx=FEATURES&type=IDENTITY,COMPONENT${
												isCms
													? `&CMS=true&recipeId=${currentRecipe?.qcId}`
													: ''
										  }`
										: `${urls.EXTENSION}${
												urls[URL_TYPE[type]]
										  }/${currentIndex}?id=${scanId}&ctx=IDENTIFICATION${
												isCms
													? `&CMS=true&recipeId=${currentRecipe?.qcId}`
													: ''
										  }`
								}
							>
								<Tooltip title='Identification' arrow>
									<Button
										className={styles.action}
										variant='contained'
										color='primary'
									>
										<FormatItalic />
									</Button>
								</Tooltip>
							</Link>
							{isIdentified && (
								<span className={styles.status}>
									<Check />
									{identification?.ai != null &&
										(identification?.ai ? (
											<>
												<span
													className={styles.statusAi}
												>
													Ai
												</span>
											</>
										) : (
											<>
												<span
													className={styles.statusMn}
												>
													Mn
												</span>
											</>
										))}
								</span>
							)}
						</div>
					)}
					{(isIdentified || isWastage) && !showFirstTimeValidate && (
						<div
							className={classes(
								styles.retag,
								styles['action-wrapper']
							)}
						>
							<Link
								className={styles.link}
								to={`${urls.EXTENSION}${
									urls[URL_TYPE[type]]
								}/${currentIndex}?id=${scanId}&ctx=FEATURES&type=IDENTITY,COMPONENT${
									isCms
										? `&CMS=true&recipeId=${currentRecipe?.qcId}`
										: ''
								}`}
							>
								<Badge
									className={styles.action}
									badgeContent={identity ?? 0}
									color='success'
								>
									<Tooltip
										title='Component Identification'
										arrow
									>
										<Button
											className={styles.action}
											variant='contained'
											color='primary'
										>
											<img
												width={24}
												src={compIden}
												alt='comp'
											/>
										</Button>
									</Tooltip>
								</Badge>
							</Link>
						</div>
					)}
					{(isIdentified || isWastage) && !showFirstTimeValidate && (
						<div
							className={classes(
								styles.retag,
								styles['action-wrapper']
							)}
						>
							<Link
								className={styles.link}
								to={`${urls.EXTENSION}${
									urls[URL_TYPE[type]]
								}/${currentIndex}?id=${scanId}&ctx=CONTAINER&type=CRATE${
									isCms
										? `&CMS=true&recipeId=${currentRecipe?.qcId}`
										: ''
								}`}
							>
								<Tooltip title='Crate Identification' arrow>
									<Button
										className={styles.action}
										variant='contained'
										color='primary'
									>
										<img
											width={24}
											src={crate}
											alt='crate'
										/>
									</Button>
								</Tooltip>
							</Link>
						</div>
					)}
					{!features > 0 && showValidate && (
						<div
							className={classes(
								styles.validate,
								styles['action-wrapper']
							)}
						>
							<Tooltip title='Change SKU' arrow>
								<Button
									className={styles.action}
									variant='contained'
									color='primary'
									onClick={() => {
										toggleRetagModal(true);
									}}
								>
									<Edit />
								</Button>
							</Tooltip>
						</div>
					)}
					{sensorValues && sensorValues?.length > 0 && (
						<div
							className={classes(
								styles.validate,
								styles['action-wrapper']
							)}
						>
							<Tooltip
								title={
									tempVisibility
										? 'Hide Temperature'
										: 'Show Temperature'
								}
								arrow
							>
								<Button
									className={styles.action}
									variant='contained'
									color={
										tempVisibility ? 'success' : 'primary'
									}
									onClick={() => {
										setTempVisibility((prevState) => {
											return !prevState;
										});
									}}
								>
									<DeviceThermostat />
								</Button>
							</Tooltip>
						</div>
					)}
					<div className={styles.mark_unmark_wrapper}>
						<Tooltip
							title={
								isDuplicate
									? 'Mark Non-Duplicate'
									: 'Mark Duplicate'
							}
						>
							<button
								onClick={() => {
									onDuplicacy(!isDuplicate);
								}}
								type='button'
								className={styles.unmark_btn}
							>
								{/* <DoDisturbAlt /> */}
								<img
									className={styles.icon}
									src={duplicateIcon}
									alt=''
								/>
							</button>
						</Tooltip>
					</div>
					{clientType !== 'SCHOOL' && showFirstTimeValidate && (
						<div className={styles['first-time-validate']}>
							<span className={styles['validate-label']}>
								Is AI Identification Correct?
								<br />
							</span>
							<div className={styles['validate-actions']}>
								<Tooltip title='NO'>
									<IconButton
										className={classes(
											styles['validate-action'],
											styles['not-valid']
										)}
										onClick={() => {
											toggleRetagModal(true, {
												defaultSelect: -1,
											});
										}}
									>
										<Close />
									</IconButton>
								</Tooltip>
								<Tooltip title='YES'>
									<IconButton
										className={classes(
											styles['validate-action'],
											styles.valid
										)}
										onClick={() => {
											validateSKU();
										}}
									>
										<Check />
									</IconButton>
								</Tooltip>
							</div>
						</div>
					)}
					<div className={styles.footer}>
						{identification?.label && (
							<div className={styles.identification}>
								<span>{identification?.label}</span>
							</div>
						)}
						{container?.id && (
							<div className={styles.container}>
								<img
									className={styles.image}
									src={container?.image}
									alt={container?.name}
								/>
								<div className={styles['name-weight']}>
									<p>{container?.name}</p>
									<p>
										{container?.weight} {container?.unit}
									</p>
								</div>
							</div>
						)}
					</div>
				</>
			)}
		</div>
	);
};

FeedHUD.defaultProps = {
	result: '',
	orderId: null,
	itemId: null,
	currentIndex: 0,
	showAnnotation: false,
	showRetag: false,
	showValidate: false,
	showFirstTimeValidate: false,
	type: 'QMS',
	validateSKU: () => {},
};

const mapStateToProps = (state) => {
	const productsList = state.form.ALL_PRODUCTS.data.LIST;
	return {
		productsList,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchProducts: formActions.gateway,
		toggleRetagModal: (show, data = {}) => {
			dispatch(modalActions.toggleModal(modalTypes.RETAG, show, data));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedHUD);
