/* eslint-disable */
/* eslint-disable no-tabs */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import _, { cloneDeep, update } from 'lodash';
import { PRODUCT_CLASSES, PRODUCT_UNITS } from '../../../Constants/product.constants';
// eslint-disable-next-line object-curly-newline
import { getUnitConvertedQuantity, getUnitConvertedUnit, imageToBase64 } from '../../../Helpers';
import { notificationServices } from '../../../Services';

const getPriceUnit = (
	variant,
	measurementType,
	productUnit,
	priceKey = 'costPrice'
) => {
	let itemPrice = variant?.[priceKey];
	let itemUnit = '';
	if (productUnit === 'count') {
		itemUnit = 'piece';
		return [itemPrice, itemUnit];
	}
	if (productUnit === 'ltr') {
		itemUnit = 'ltr';
		return [itemPrice, itemUnit];
	}
	// if (productUnit === 'ml') {
	// itemUnit = 'ml';
	// return [itemPrice, itemUnit];
	// }

	if (productUnit === 'floz') {
		// if ((itemPrice * 128) > 1 && Number.isInteger(itemPrice * 128)) {
		if (itemPrice * 128 >= 1) {
			itemUnit = 'gal';
			itemPrice *= 128;
		} else {
			itemUnit = 'floz';
		}
		return [itemPrice, itemUnit];
	}
	if (productUnit === 'oz') {
		if (itemPrice * 16 > 1) {
			itemPrice *= 16;
			itemUnit = 'lbs';
		} else {
			itemUnit = 'oz';
		}
	}
	if (productUnit === 'gram') {
		if (itemPrice * 1000 > 1) {
			itemPrice *= 1000;
			itemUnit = 'kg';
		} else {
			itemUnit = 'g';
		}
	}
	if (productUnit === 'ml') {
		if (itemPrice * 1000 > 1) {
			itemPrice *= 1000;
			itemUnit = 'ltr';
		} else {
			itemUnit = 'ml';
		}
	}
	return [itemPrice, itemUnit];
};
const convertPrice = (price, unit) => {
	if (unit === 'lbs') {
		return price / 16;
	}
	if (unit === 'kg' || unit === 'ltr') {
		return price / 1000;
	}
	if (unit === 'gal') {
		return price / 128;
	}
	return price;
};

const getDatainHours = (value, unit = 'hrs') => {
	let val = null;
	if (unit === 'hrs') {
		val = value;
	}
	if (unit === 'days') {
		val = value * 24;
	}
	if (unit === 'months') {
		val = value * 30 * 24;
	}
	return val;
};
const getMinMaxUnit = (productUnit, weight, measurementType) => {
	let tempUnit = productUnit;
	if (productUnit === 'count') {
		if (measurementType === 'US') {
			tempUnit = 'oz';
		} else {
			tempUnit = 'gram';
		}
	}
	return getUnitConvertedUnit(weight, tempUnit, true);
};

const getProductUnit = (itemUnit, measurementType) => {
	if (itemUnit === 'count') {
		if (measurementType === 'US') {
			return 'oz';
		}
		return 'gram';
	}
	return itemUnit;
};
const productConfig = {
	initialState: {
		fields: {
			name: '',
			description: '',
			brand: null,
			category: null,
			subCategory: null,
			tax: 0,
			hsnCode: '',
			veg: true,
			image: null,
			density: null,
			densityUnit: '',
			productType: '',
			perUnit: '',
			expiry: '',
			expiryUnit: 'hrs',
			minTemp: '',
			maxTemp: '',
			productCode: '',
			productClass: PRODUCT_CLASSES.map((productClass) => {
				return {
					...productClass,
					isApplied: false,
				};
			}),
			productUnits: PRODUCT_UNITS?.map((productUnit) => {
				return {
					...productUnit,
					isApplied: false,
				};
			}),
			purchaseUnits: PRODUCT_UNITS?.map((productUnit) => {
				return {
					...productUnit,
					isApplied: false,
				};
			}),
			variants: [],
		},
	},
	mandatoryFields: {
		name: true,
		description: false,
		brand: true,
		category: true,
		subCategory: true,
		tax: true,
		veg: true,
		image: true,
		productClass: true,
		productUnit: true,
		purchaseUnits: false,
		expiry: true,
	},
	newVariant: {
		id: null,
		isEnabled: true,
		displayName: 'A',
		clientVariantId: null,
		description: null,
		pieces: null,
		containerWeight: null,
		containerUnit: null,
		minWeight: null,
		maxWeight: null,
		minUnit: null,
		maxUnit: null,
		servingQtyPercentage: null,
		serves: null,
		sellingPrice: null,
		transferPrice: null,
		costPrice: null,
		costPriceUnit: null,
		sellingPriceUnit: null,
		transferPriceUnit: null,
		isDefault: false,
		factorPurchaseUnit: 1,
		packSize: null,
		packSizeUnit: null,
	},
	serializer: (state, data) => {
		const defaultProduct = data.variants.find((variant) => {
			return variant.isDefault;
		});
		const disabledUnits =
			data?.measurement === 'US'
				? ['gram', 'litre', 'ml']
				: ['oz', 'floz'];
		return update(cloneDeep(state), 'fields', () => {
			const newState = {
				name: data.productName,
				description: data.productDescription,
				tax: data.tax,
				image: data.productImageUrl,
				productCode: data?.clientProductId,
				category: data.categoryId,
				subCategory: data.subCategoryId,
				brand: data.brandId,
				price: defaultProduct ? defaultProduct.price : null,
				density: data?.density,
				densityUnit: data?.densityUnit,
				perUnit: data?.unit === 'floz' ? 'Gal' : 'ltr',
				veg: data.isVeg,
				hsnCode: data?.hsnCode,
				expiry: data?.hoursToExpire,
				minTemp: data?.minTemp,
				maxTemp: data?.maxTemp,
				productType: data.productType,
				unit: data.productUnit,
				productUnits: PRODUCT_UNITS?.map((productUnit) => {
					return {
						...productUnit,
						isApplied: data?.unit === productUnit?.id,
					};
				})?.filter((ele) => {
					return !disabledUnits?.includes(ele?.id);
				}),
				purchaseUnits: PRODUCT_UNITS?.map((productUnit) => {
					return {
						...productUnit,
						isApplied: data?.purchaseUnit === productUnit?.id,
					};
				})?.filter((ele) => {
					return !disabledUnits?.includes(ele?.id);
				}),
				productClass: PRODUCT_CLASSES.map((productClass) => {
					return {
						...productClass,
						isApplied:
							productClass.name?.toLowerCase() ===
							data.skuType?.toLowerCase(),
					};
				}),
				variants: data?.variants,
			};
			return newState;
		});
	},
	variantSerilizer: (state) => {
		return _.update(_.cloneDeep(state), 'fields', () => {
			const newState = {
				productUnit: state?.unit,
				purchaseUnit: state?.purchaseUnit,
				skuTypeVariant: state?.skuType,
				variants: state?.variants.map((currVariant) => {
					const [costPrice, costPriceUnit] = getPriceUnit(
						currVariant,
						state?.measurement,
						(state?.purchaseUnit || state?.unit)
					);
					const [transferPrice, transferPriceUnit] = getPriceUnit(
						currVariant,
						state?.measurement,
						state?.unit,
						'transferPrice'
					);
					const [sellingPrice, sellingPriceUnit] = getPriceUnit(
						currVariant,
						state?.measurement,
						state?.unit,
						'sellingPrice'
					);
					const [packSize, packSizeUnit] = getPriceUnit(
						currVariant,
						state?.measurement,
						'count',
						'packSize',
					);
					let itemUnit = state?.unit;
					if (
						itemUnit === 'count' ||
										itemUnit === 'litre' ||
										itemUnit === 'ml' ||
										itemUnit === 'floz'
					) {
						if (state?.measurement === 'US') {
							itemUnit = 'oz';
						} else {
							itemUnit = 'g';
						}
					}
					return {
						...currVariant,
						minWeight: getUnitConvertedQuantity(
							currVariant?.minWeight,
							getProductUnit(state?.unit, state?.measurement),
							true
						),
						maxWeight: getUnitConvertedQuantity(
							currVariant?.maxWeight,
							getProductUnit(state?.unit, state?.measurement),
							true
						),
						minUnit: getMinMaxUnit(
							state?.unit,
							currVariant?.minWeight,
							state?.measurement
						),
						maxUnit: getMinMaxUnit(
							state?.unit,
							currVariant?.maxWeight,
							state?.measurement
						),
						containerWeight: getUnitConvertedQuantity(
							currVariant?.containerWeight,
							itemUnit,
							true
						),
						containerUnit: getUnitConvertedUnit(
							currVariant?.containerWeight,
							itemUnit,
							true
						),
						costPrice,
						costPriceUnit,
						transferPrice,
						transferPriceUnit,
						sellingPrice,
						sellingPriceUnit,
						packSize,
						packSizeUnit,
					};
				}),
			};
			return newState;
		});
	},
	deserializer: (state) => {
		const clonedState = cloneDeep(state);
		const req = {};
		// let productDensity = getUnitConvertedQuantity(
		// clonedState.fields.density,
		// clonedState.fields.densityUnit,
		// true
		// );
		const selectedPurchaseUnit = clonedState?.fields?.purchaseUnits?.reduce(
			(finalUnit, currUnit) => {
				if (currUnit?.isApplied) {
					return currUnit?.id;
				}
				return finalUnit;
			},
			''
		);
		const weightChecks = ['lbs', 'kg', 'gal', 'ltr'];

		req.data = {
			productName: clonedState.fields.name,
			productDescription: clonedState.fields.description,
			brandId: clonedState.fields.brand,
			clientProductId: clonedState.fields.productCode,
			productImageUrl: clonedState.fields.image,
			isVeg: clonedState.fields.veg,
			density: clonedState.fields.density,
			categoryId: clonedState.fields.category,
			subCategoryId: clonedState.fields.subCategory,
			tax: clonedState.fields.tax?.toString(),
			hsnCode: clonedState.fields.hsnCode,
			hoursToExpire: getDatainHours(clonedState?.fields?.expiry, clonedState?.fields?.expiryUnit),
			// minTemp: Number(clonedState?.fields?.minTemp),
			// maxTemp: Number(clonedState?.fields?.maxTemp),
			menuTags: 'Healthy Menu',
			tags: '',
			cost: 0.012,
			unit: clonedState?.fields?.productUnits?.reduce(
				(finalUnit, currUnit) => {
					if (currUnit?.isApplied) {
						return currUnit?.id;
					}
					return finalUnit;
				},
				''
			),
			purchaseUnit: selectedPurchaseUnit || null,
			skuType: clonedState.fields.productClass.reduce(
				(finalType, currType) => {
					if (currType.isApplied) {
						return currType.name.toUpperCase();
					}
					return finalType;
				},
				''
			),
		};

		return imageToBase64(req.data.productImageUrl).then((base64Image) => {
			req.data.productImageUrl = base64Image;
			return req;
		});
	},
	variantDeserlizer: (state) => {
		const skuType = state?.fields?.skuType;
		const processedVariants = state.fields.variants.map((currVariant, idx) => {
			let tempVariant = {};
			const weightChecks = ['lbs', 'kg', 'gal', 'ltr'];
			tempVariant = {
				...tempVariant,
				...currVariant,
				id: currVariant?.id,
				minWeight: weightChecks.includes(currVariant?.minUnit)
					? getUnitConvertedQuantity(
						currVariant?.minWeight,
						currVariant?.minUnit,
						true
					)
					: currVariant?.minWeight,
				maxWeight: weightChecks.includes(currVariant?.maxUnit)
					? getUnitConvertedQuantity(
						currVariant?.maxWeight,
						currVariant?.minUnit,
						true
					)
					: currVariant?.maxWeight,
				containerWeight: weightChecks.includes(currVariant?.containerUnit)
					? String(getUnitConvertedQuantity(
						currVariant?.containerWeight,
						currVariant?.containerUnit,
						true
					))
					: currVariant?.containerWeight,
			};
			tempVariant.size = `size${idx + 1}`;
			tempVariant.costPrice = convertPrice(
				tempVariant?.costPrice,
				tempVariant?.costPriceUnit
			)?.toString();
			if (skuType !== 'INGREDIENT') {
				tempVariant.transferPrice = convertPrice(
					tempVariant?.transferPrice,
					tempVariant?.transferPriceUnit
				)?.toString();
				tempVariant.sellingPrice = convertPrice(
					tempVariant?.sellingPrice,
					tempVariant?.sellingPriceUnit
				)?.toString();
			} else {
				tempVariant.transferPrice = null;
				tempVariant.sellingPrice = null;
			}
			return tempVariant;
		});
		return processedVariants; // Return only the processed variants
	},
	validator: (state, variant = [], isEdit = false) => {
		// eslint-disable-next-line object-curly-newline
		const {
			image,
			tax,
			brand,
			density,
			category,
			subCategory,
			productClass,
			productUnits,
			expiry,
			purchaseUnits,
			hsnCode,
			productCode,
		} = state.fields;
		const variants = isEdit ? variant.fields.variants : state.fields.variants;

		// check if image is uploaded or not
		if (!image) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please upload an image for the product',
			});
			return false;
		}

		const unit = productUnits?.reduce((finalUnit, currUnit) => {
			if (currUnit?.isApplied) {
				return currUnit?.id;
			}
			return finalUnit;
		}, '');

		if (unit === 'ml' || unit === 'floz' || unit === 'litre') {
			// eslint-disable-next-line eqeqeq
			if (!density || density <= 0) {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Density mandatory for liquid items',
				});
				return false;
			}
		}
		const purchaseUnit = purchaseUnits?.reduce((finalUnit, currUnit) => {
			if (currUnit?.isApplied) {
				return currUnit?.id;
			}
			return finalUnit;
		}, null);
		if (purchaseUnit && purchaseUnit === unit) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Product Unit and Purchase Unit cannot be same.',
			});
			return false;
		}
		// check if tax is within 0-100
		if (tax > 100 || tax < 0) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Product tax should be within 0-100',
			});
			return false;
		}

		// if (!hsnCode) {
		// 	const isIntermediate = productClass?.some(
		// 		(type) => type.name === 'Intermediate' && type.isApplied === true
		// 	);
		// 	if (!isIntermediate) {
		// 		notificationServices.generateNotification({
		// 			type: 'error',
		// 			message: 'HSN Code is Mandatory',
		// 		});
		// 		return false;
		// 	}
		// }
		if (hsnCode && (hsnCode.length !== 4 && hsnCode.length !== 6 && hsnCode.length !== 8)) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'HSN Code length must be either 4 or 6 or 8 digits.',
			});
			return false;
		}
		// check if brand is selected or not
		if (brand == null || brand === '') {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please select a valid Brand',
			});
			return false;
		}

		// check if category is selected or not
		if (category == null || category === '') {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please select a valid Category',
			});
			return false;
		}
		// check if sub category is selected or not
		if (subCategory == null || subCategory === '') {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please select a valid Sub Category',
			});
			return false;
		}
		// check if product class is selected or not
		const noProductClass = productClass.every((productClassObject) => {
			return !productClassObject.isApplied;
		});
		if (noProductClass) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please select a product type',
			});
			return false;
		}
		if (expiry === '' || expiry === null) {
			notificationServices?.generateNotification({
				type: 'error',
				message: 'Expires In is mandatory',
			});
			return false;
		}
		// check if product class is selected or not
		const noProductUnitSelected = productUnits.every(
			(productUnitObject) => {
				return !productUnitObject.isApplied;
			}
		);
		if (noProductUnitSelected) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Please select product unit',
			});
			return false;
		}
		if (variants.length === 0) {
			notificationServices?.generateNotification({
				type: 'error',
				message: 'At least one variant is required for the product',
			});
			return false;
		}
		if (variants) {
			const emptyValue = variants.some((variant) => variant?.servingQtyPercentage === '' || variant?.servingQtyPercentage === null);
			const emptyMinMaxWeight = variants.some((variant) => variant?.minWeight === '' || variant?.minWeight === null || variant?.maxWeight === '' || variant?.maxWeight === null);
			const displayNames = variants.map((variant) => variant.displayName?.trim().toLowerCase());
			const duplicateName = new Set(displayNames).size !== displayNames.length;
			if (duplicateName) {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Display Name in variants cannot be same',
				});
				return false;
			}
			if (emptyValue) {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Serving Qty(%) is Mandatory in Varaints',
				});
				return false;
			}
			if (emptyMinMaxWeight) {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Min and Max Weight is Mandatory in Varaints',
				});
				return false;
			}
		}
		if(variants){
			const defaultVariant = variants.every((value) => value?.isDefault === false);
			if(defaultVariant){
				notificationServices.generateNotification({
					type: 'error',
					message: 'Please Select Only One Variant as Default',
				});
				return false;
			}
		}
		return true;
	},
};

export default productConfig;
