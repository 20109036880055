/* eslint-disable */
import { Smartphone } from "lucide-react";
import { Button } from "../ui/button";
import { Label } from "../ui/label";
import TableComponent from "./tableComponent";
import { Checkbox } from "@mui/material";

function AddParameterComp(props) {
    const {
        handleOpenModal = () => {},
        selectedParameters,
        handleRemove = () => {}, 
        setNewList = () => {},
        isDisabled = false,
        qualityCheck = {},
        setQualityCheck = () => {},
        handleCategoryConfiguration = () => {},
        hideCategoryConfiguration = false,
    } = props;
    const columns = [
        {
            name: 'Parameter Name',
            icon: false,
            width: '20%',
        },
        {
            name: 'Input Type',
            icon: false,
            width: '20%',
        },
        {
            name: 'Unit',
            icon: false,
            width: '20%',
        },
        {
            name:'Image Needed',
            icon: false,
            width: '20%',
        },
        {
            name: 'Configuration',
            icon: false,
            width: '20%',
        },
        {
            name: 'Action',
            icon: false,
            width: '20%',
        }
    ];

	return (
        <div>
		<div className="flex justify-between items-center gap-4 p-2 w-[98%] m-[1rem]">
                <Label className="text-[16px] flex items-center gap-2"><Smartphone /> Quality Parameters</Label>
                <div className='flex items-center gap-2'>
                {hideCategoryConfiguration && (
				<>
                <Checkbox
                    checked={qualityCheck?.categoryConfiguration}
                    onChange={(e) => handleCategoryConfiguration(e)}
                            size="small"
                            sx={{
                                '& .MuiSvgIcon-root': {
                                    fontSize: '1.2rem',
                                },
                                '&.Mui-checked': {
                                    color: '#000000',
                                },
                            }}
                            disabled={isDisabled} />
                        <Label className='text-lg text-gray-600 font-medium'>Follow Category Configuration</Label>
                    </>
                )}
                <div className={` ${
				    !qualityCheck?.categoryConfiguration ? "bg-white" : "bg-white-200 opacity-50 pointer-events-none"
			    }`}>
                {!isDisabled && (
                    <Button onClick={handleOpenModal} className="bg-gray-200 hover:bg-gray-300 text-black px-4 py-2 rounded-md">
                        + Add Quality Parameter
                    </Button>
                )}
                </div>
				</div>
		</div>
        <div
			className={` ${
				!qualityCheck?.categoryConfiguration ? "bg-white" : "bg-white-200 opacity-50 pointer-events-none"
			}`}>
            <TableComponent
                columns={columns}
                data={selectedParameters}
                handleRemove={handleRemove}
                setNewList={setNewList}
                isDisabled={isDisabled}
            />
        </div>
        </div>
	);
}

export default AddParameterComp;
