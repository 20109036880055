/* eslint-disable no-tabs */
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	useEffect, useMemo, useState
} from 'react';
import styles from './ProcessAccordion.module.css';
import IngredientAccordion from '../IngredientAccordion/IngredientAccordion';
import { classes, convertWeightUnit } from '../../../../Helpers';
import { useAuth } from '@/Components/Auth/Login/auth';

function ProcessAccordion(props) {
	const {
		key,
		processData,
		processExpanded,
		setProcess,
		setCurrentRecipe,
		currentRecipe,
		getPastQCDetails,
		isCms,
		recipe,
		unit,
	} = props;
	const convertedFullfilled = convertWeightUnit(
		recipe?.itemFulfilled,
		recipe?.unit,
		true
	);
	const convertedQty = convertWeightUnit(
		recipe?.itemFulfilled,
		recipe?.unit,
		true
	);
	const [ingredientExpanded, setIngredientExpanded] = useState(
		processData?.ingredients[0]?.id ?? false
	);
	const { appConfig } = useAuth();
	const { combineCMSBatches } = appConfig?.configs ?? {};
	useEffect(() => {
		setCurrentRecipe({
			...processData?.ingredients[0],
			validated: processData?.ingredients[0]?.validation,
		});
		getPastQCDetails(processData?.ingredients[0]?.qcId);
	}, [processData]);

	useEffect(() => {
		setIngredientExpanded(currentRecipe?.id);
	}, [currentRecipe]);

	const accordionClasses = useMemo(() => {
		if (processData?.startedAt && !processData?.isCompleted) {
			return styles.progress;
		}
		if (processExpanded && processData?.isCompleted) {
			return styles.completed;
		}
		if (processExpanded && !processData?.isCompleted) {
			return styles.selected;
		}
		return '';
	}, [processData]);

	const handleCurrentIngredient = (ingredient) => {
		setIngredientExpanded(ingredient?.id);
		setCurrentRecipe({
			...ingredient,
			validated: ingredient?.validation,
		});
		if (ingredient?.qcId) {
			getPastQCDetails(ingredient?.qcId);
		}
	};
	return (
		<div key={key}>
			<Accordion
				expanded={processExpanded === processData?.id}
				onChange={() => {
					setProcess(processData);
				}}
				className={classes(styles.root, accordionClasses)}
			>
				<AccordionSummary
					className={styles['accordion-heading']}
					expandIcon={<ExpandMoreIcon />}
					aria-controls='panel1a-content'
					id='panel1a-header'
				>
					<Typography className={styles.name}>
						{processData?.name}
					</Typography>
				</AccordionSummary>
				{isCms && combineCMSBatches && unit !== 'count' && (
					<div
						className='flex flex-col gap-1  text-white text-center m-[1px] font-semibold rounded-sm'
					>
						<p className='text-xl'>{`${convertedFullfilled} / ${convertedQty} ` || '-'}</p>
						<p>Item Fulfilled/Item Quantity </p>
					</div>
				)}
				<AccordionDetails className={styles?.['accordion-details']}>
					{processData?.ingredients?.map((ingredient, idx) => {
						return (
							<IngredientAccordion
								key={ingredient?.id}
								ingredient={ingredient}
								isExpanded={idx === 0 && ingredient?.id}
								ingredientExpanded={ingredientExpanded}
								setIngredient={handleCurrentIngredient}
								isCms={isCms}
								recipe={recipe}
								unit={unit}
								itemFulfilled={recipe?.itemFulfilled}
							/>
						);
					})}
				</AccordionDetails>
			</Accordion>
		</div>
	);
}

export default ProcessAccordion;
