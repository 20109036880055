/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { useState } from 'react';

function useSearch(combineCMSBatches = false, name = '', initQuery, initSearchBy = 0) {
	const searchValue = (combineCMSBatches && name) ? name : (combineCMSBatches && !name) ? initQuery : initQuery;
	const value = searchValue;
	const [query, setQuery] = useState(value ?? '');
	const [searchBy, setSearchBy] = useState(initSearchBy ?? '');

	const handleSearch = (queryString) => {
		setQuery(queryString);
	};

	const clearSearch = () => {
		setQuery('');
	};

	const handleSearchBy = (newField) => {
		setSearchBy(newField);
	};

	return {
		query,
		handleSearch,
		clearSearch,
		searchBy,
		handleSearchBy,
	};
}
export default useSearch;
