/* eslint-disable */
/* eslint-disable indent */
/* eslint-disable no-tabs */
/* eslint-disable object-curly-newline */
/* eslint-disable no-unreachable */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-mixed-spaces-and-tabs */
import {
	useCallback,
	useEffect,
	useMemo,
	useReducer,
	useRef,
	useState,
} from 'react';
import { connect } from 'react-redux';
import { MenuItem, TextField } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import _ from 'lodash';
import { formActions, modalActions } from '../../../Actions';
import assetActions from '../../../Actions/asset.actions';
import { AddNewVendorModal } from '../../../Components/Modals/AddNewVendorModal';
import { POGeneralDetails } from '../../../Components/POGeneralDetails';
import { POTotalContainer } from '../../../Components/POTotalContainer';
import { notificationServices } from '../../../Services';
import inventoryAnalyticsServices from '../../../Services/Analytics Services/inventory-analytics.services';
import CreatePurchaseOrderHeader from './CreatePurchaseOrderHeader/CreatePurchaseOrderHeader';
import config from './CreatePurchaseOrder.config';
import styles from './CreatePurchaseOrder.module.scss';
import { GenerateStandardPo } from '../../../Components/Modals/GenerateStandardPo';
import { orderTypes, urls } from '../../../Constants';
import { useAuth } from '../../../Components/Auth/Login/auth';
import { getStateFromGSTIN, inputHelper, useQuery } from '../../../Helpers';
import productProcessMapServices from '../../../Services/Analytics Services/product-process-map.services';
import assetServices from '../../../Services/AssetServices/asset.services';
import allVendorServices from '../../../Services/Form Services/allvendor.services';
import { BackdropLoader } from '../../../Components/Loaders';
import ErrorModal from '../../../Components/Modals/ErroModal/ErrorModal';
import shiftServices from '../../../Services/shift.service';
import { POItemSelection } from '@/Components/POItemSelection';
import { AssetListing } from '@/Pages/Assets';
import { FileUploaderModal } from '@/Components/Modals/FileUploaderModal';
import { AlertModal } from '@/Components/Modals';

const taxPercentage = 18;

function CreatePurchaseOrder(props) {
	const {
		vendors,
		productsList,
		storesList,
		filteredStoresList,
		fetchProducts,
		// navState,
		fetchVendors,
		buyers,
		fetchStoresList,
		toggleAddVendorModal,
		toggleGenerateStandardPOModal,
		fetchProcessTypesList,
		processTypes,
		assetList,
		toggleFileUploaderModal,
		fetchAssetList,
		toggleModal,
	} = props;
	const query = useQuery();
	const history = useHistory();
	const location = useLocation();
	const { appConfig = {} } = useAuth();
	const {
		dynamicPrice = false,
		P2PTransfer = false,
	} = appConfig?.configs ?? {};
	const orderTypeIdQuery = query.get('typeId');
	const queryToStore = query.get('toStore');
	const queryByStore = query.get('fromStore');
	const queryStoreTypes = query.get('storeTypes');
	const queryUserOrderType = query.get('userOrderType');
	const poId = query.get('poId');
	const exclude = query.get('exclude');

	const { userConfig } = useAuth();
	const { parentStore, storeId } = userConfig?.store ?? {};

	const [defaultPoItems, setDefaultPoItems] = useState([]);
	const [productWiseMappingData, setProductWiseMappingData] = useState([]);
	const [internalProductionProductIds, setInternalProductionProductIds] =
		useState([]);
	const [selectedOrderType, setSelectedOrderType] = useState({});
	const [state, dispatch] = useReducer(config?.reducer, config?.initialState);
	const [skuStockInfo, setSkuStockInfo] = useState([]);
	const [sendingStoreSkuPriceInfo, setSendingStoreSkuPriceInfo] = useState(
		{}
	);
	const [restrictedProducts, setRestrictedProducts] = useState([]);
	const [rowError, setRowError] = useState({});
	const [asset, setAsset] = useState([]);
	const itemComponentRef = useRef(null);
	const [servicesList, setServices] = useState([]);
	const [shiftsList, setShiftsList] = useState([]);
	// eslint-disable-next-line no-unused-vars
	const [parentGroupedAssets, setParentGroupedAssets] = useState({});
	const [disableTerms, setDisableTerms] = useState(false);
	const [vendorPrices, setVendorPrices] = useState({});
	// eslint-disable-next-line no-unused-vars
	const [isLoading, setIsLoading] = useState(false);
	const [doc, setDoc] = useState();
	const [dispatchDelta, setDispatchDelta] = useState();
	const [vendorFlag, setVendorFlag] = useState(false);

	const uploadInvoiceCick = () => {
		toggleFileUploaderModal(true, {
			heading: 'Upload Document',
			selectedFile: '',
			confirmText: 'Upload',
		});
	};

	const handleDocUpload = (fileData) => {
		setDoc(fileData);
		toggleFileUploaderModal(false);
	};

	const setPOItems = (newState) => {
		dispatch({
			type: 'SET_PO_ITEMS',
			payload: newState,
		});
	};

	useEffect(() => {
		if (selectedOrderType?.id === 1) {
			const diffInDays = moment(state?.expectedDeliveryDate).startOf('day').diff(
				moment(state?.date).startOf('day'),
				"days"
			  );
			if (diffInDays > 365) {
				toggleModal(true, {
					heading:'',
					message:'The expected delivery date is more than  1 Year from the order date. This will be considered a long-term Purchase Order',
					message2:'Do you wish to continue ?',
					confirmText: 'Yes',
					cancelText: 'No',
					handleConfirm: () => {
						setVendorFlag(true);
						toggleModal(false);
					},
				});
			}
		}
	}, [state?.expectedDeliveryDate, state?.date]);

	const updateManualRateUpdatedVariantIds = (idToAppend) => {
		dispatch({
			type: 'SET_MANUAL_RATE_UPDATED',
			payload: idToAppend,
		});
	};

	const setPOItemsObject = useCallback((newState) => {
		dispatch({
			type: 'SET_PO_ITEMS_OBJECT',
			payload: newState,
		});
	}, []);

	const setState = (type, value) => {
		if (type) {
			dispatch({
				type,
				payload: value,
			});
		}
	};

	useEffect(() => {
		setDispatchDelta(state?.fromStore?.dispatchedDaysDelta);
	}, [state]);

	useEffect(() => {
		if (
			queryToStore &&
			(queryToStore === 'undefined' || queryToStore === '-999')
		) {
			query.delete('toStore');
			history.replace({
				search: query.toString(),
				state: {
					prevPath: history?.location?.state?.prevPath,
				},
			});
		}
		if (
			queryByStore &&
			(queryByStore === 'undefined' || queryByStore === '-999')
		) {
			query.delete('fromStore');
			history.replace({
				search: query.toString(),
				state: {
					prevPath: history?.location?.state?.prevPath,
				},
			});
		}
		if (queryStoreTypes && queryStoreTypes === 'undefined') {
			query.delete('storeTypes');
			history.replace({
				search: query.toString(),
				state: {
					prevPath: history?.location?.state?.prevPath,
				},
			});
		}
	}, [queryToStore, queryByStore, queryStoreTypes]);

	const fetchShifts = useCallback(async () => {
		try {
			setIsLoading(true);
			const response = await shiftServices.listProcessShifts({
				data: {
					date: moment(state?.date).format('YYYY-MM-DD'),
					processIds:
						state?.transferOrderType !== 'DISPATCH'
							? [state?.fromProcess?.id]
							: [state?.toProcess?.id],
					store: state?.store?.id,
				},
			});
			if (response?.statusCode === 200) {
				setShiftsList(response?.data);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	}, [
		state?.store?.id,
		state?.date,
		state?.toProcess?.id,
		state?.fromProcess?.id,
		state?.transferOrderType,
	]);
	useEffect(() => {
		if (
			(selectedOrderType?.id === 5 || selectedOrderType?.id === 6) &&
			state?.toProcess?.id &&
			state?.date &&
			state?.store?.id &&
			(state?.transferOrderType || state?.workOrderType)
		) {
			fetchShifts();
		}
	}, [
		selectedOrderType.id,
		state?.store?.id,
		state?.date,
		state?.toProcess?.id,
		fetchShifts,
		state?.fromProcess?.id,
		state?.transferOrderType,
		state?.workOrderType,
	]);
	useEffect(() => {
		if (
			poId &&
			(orderTypeIdQuery === '1' || orderTypeIdQuery === '8') &&
			vendors?.length > 0 &&
			storesList?.length > 0
		) {
			(async () => {
				try {
					const response = await inventoryAnalyticsServices.fetchPo(
						Number(poId)
					);
					if (response?.statusCode === 200) {
						let existingPoId = response?.data?.clientPoId;
						if (response?.data?.clientPoId?.includes('PO-')) {
							const splittedId =
								response?.data?.clientPoId?.split('PO-');
							existingPoId = splittedId?.[splittedId?.length - 1];
						}
						const existingExpectedDelivery = moment(
							response?.data?.scheduledAt,
							'DD/MM/YYYY'
						)?.toDate();
						dispatch({
							type: 'SET_VALUE',
							payload: {
								vendor: vendors?.find((ele) => {
									return ele?.id === response?.data?.vendorId;
								}),
								orderAmount: response?.data?.items?.reduce(
									(acc, curr) => {
										return acc + curr?.finalValue;
									},
									0
								),
								deliverTo: storesList?.find((ele) => {
									return ele?.id === response?.data?.storeId;
								}),
								fromStore: storesList?.find((ele) => {
									return (
										ele?.id === response?.data?.fromStore
									);
								}),
								purchaseOrderId: existingPoId,
								orderId: response?.data?.id,
								clientPOId: existingPoId,
								rejectionReason:
									response?.data?.jsonData?.rejectionReason,
								paymentTerm:
									response?.data?.jsonData?.paymentTerm,
								additionalCharges: response?.data?.jsonData
									?.additionalCharges ?? [
									{
										type: 'Additional Charges',
										price: 0,
										taxPercentage: 18,
									},
								],
								deliveryCharges:
									response?.data?.deliveryCharges ?? 0,
								packagingCharges:
									response?.data?.packagingCharges ?? 0,
								date: new Date(response?.data?.createdAt),
								expectedDeliveryDate: existingExpectedDelivery,
							},
						});
						setDefaultPoItems(response?.data?.items);
					}
				} catch (error) {
					// eslint-disable-next-line no-console
					console.log(error);
				}
			})();
		}
	}, [poId, vendors?.length, storesList?.length, productsList?.length]);

	useEffect(() => {
		// eslint-disable-next-line no-restricted-globals
		if (orderTypeIdQuery && !isNaN(Number(orderTypeIdQuery))) {
			const orderType = orderTypes?.find((ele) => {
				return ele?.id === Number(orderTypeIdQuery);
			});
			setSelectedOrderType(orderType);
			fetchPOId();
		}
	}, [orderTypeIdQuery]);

	useEffect(() => {
		// eslint-disable-next-line no-restricted-globals
		if (
			(state?.deliverTo?.id || state?.store?.id) &&
			selectedOrderType?.id !== 4
		) {
			(async () => {
				const response = await inventoryAnalyticsServices.skuStock({
					params: {
						type: 'stock',
						storeId: state?.store?.id ?? state?.deliverTo?.id,
					},
				});
				const variantCurrentStockQtyMap = {};
				Object.entries(response?.data)?.forEach(([, value]) => {
					value?.currentStock?.forEach((ele) => {
						variantCurrentStockQtyMap[ele?.variantId] = ele?.rawQty;
					});
				});
				const stockInfo = [
					{
						name: 'Available',
						bgColor: '#abc3',
						color: 'black',
						showInDropDown: true,
						columnIndexToShowIn: 0,
						values: variantCurrentStockQtyMap,
					},
				];
				setSkuStockInfo(stockInfo);
			})();
		}
	}, [state?.deliverTo?.id, state?.store?.id]);

	useEffect(() => {
		// eslint-disable-next-line no-restricted-globals
		if (
			state?.fromStore?.id &&
			((dynamicPrice && state?.fromStore?.id) ||
				['WAREHOUSE_INGREDIENT'].includes(state?.fromStore?.type))
		) {
			(async () => {
				const response = await inventoryAnalyticsServices.skuStock({
					params: {
						type: 'stock',
						storeId: state?.fromStore?.id,
					},
				});
				const variantCurrentStockQtyMap = {};
				Object.entries(response?.data)?.forEach(([, value]) => {
					value?.currentStock?.forEach((ele) => {
						variantCurrentStockQtyMap[ele?.variantId] = ele;
					});
				});
				setSendingStoreSkuPriceInfo(variantCurrentStockQtyMap);
			})();
		}
	}, [dynamicPrice, state?.fromStore?.id, selectedOrderType?.id]);

	const getVarPrice = useCallback(
		(product, variant) => {
			if (
				dynamicPrice ||
				['WAREHOUSE_INGREDIENT'].includes(state?.fromStore?.type)
			) {
				let price =
					sendingStoreSkuPriceInfo?.[variant?.id]?.recipePrice;
				if (product?.unit === 'gram' || product?.unit === 'ml') {
					if (!price) {
						price = variant?.price || variant?.transferPrice;
					} else {
						price /= 1000;
					}
					return price;
				}
				if (!price) {
					return variant?.price || variant?.transferPrice;
				}
				return price;
			}
			return variant?.transferPrice ?? variant?.price;
		},
		[
			sendingStoreSkuPriceInfo,
			dynamicPrice,
			selectedOrderType?.id,
			state?.fromStore?.type,
		]
	);

	const PRODUCTS = useMemo(() => {
		let prList;
		const tempProductsList = productsList?.filter((ele) => {
			if (
				restrictedProducts?.length > 0 &&
				restrictedProducts?.includes(ele?.id)
			) {
				return false;
			}
			if (
				selectedOrderType?.id === 6 &&
				!ele?.isCookingEnabled &&
				state?.workOrderType === 'CMS'
			) {
				return false;
			}
			return ele?.isEnabled !== false;
		});

		if (
			state?.fromStore?.type === 'RETAIL' &&
			// state?.deliverTo?.type === 'RETAIL' &&
			selectedOrderType?.id === 2
		) {
			prList = tempProductsList?.map((product) => {
				return {
					...product,
					originalUnit: product?.unit,
					unit: product?.unit,
					variants: product?.variants
						?.filter((vr) => {
							return vr?.isDisabled !== true;
						})
						?.map((vr) => {
							return {
								...vr,
								price:
									selectedOrderType?.id === 2
										? vr?.transferPrice
										: vr.price,
							};
						}),
				};
			});
		} else {
			prList = tempProductsList?.filter((product) => {
				if (internalProductionProductIds?.includes(product.id)) {
					return false;
				}
				return selectedOrderType?.skuTypes?.includes(product?.skuType);
			});
			prList = prList?.map((ele) => {
				return {
					...ele,
					originalUnit: ele?.unit,
					unit:
						selectedOrderType?.id === 1
							? ele?.purchaseUnit || ele?.unit
							: ele?.unit,
					variants: ele?.variants
						?.filter((vr) => {
							return vr?.isDisabled !== true;
						})
						?.map((vr) => {
							let price = vr.price;
							if (selectedOrderType?.id !== 1) {
								price = getVarPrice(ele, vr);
							}
							return {
								...vr,
								price,
							};
						}),
				};
			});
		}

		return prList || [];
	}, [
		productsList,
		selectedOrderType?.skuTypes,
		selectedOrderType?.id,
		internalProductionProductIds,
		state?.fromStore?.type,
		state?.deliverTo?.type,
		state?.vendor?.id,
		sendingStoreSkuPriceInfo,
		getVarPrice,
		restrictedProducts,
		state?.workOrderType,
	]);
	const SKU_STOCK_INFO = useMemo(() => {
		if (PRODUCTS?.length > 0 && skuStockInfo?.length > 0) {
			const newObj = {};
			const productVarMap = {};
			PRODUCTS.forEach((prd) => {
				prd?.variants?.forEach((vr) => {
					productVarMap[vr.id] = vr;
				});
			});
			Object.entries(skuStockInfo?.[0]?.values).forEach(
				([key, amount]) => {
					if (productVarMap?.[key]?.factorPurchaseUnit) {
						newObj[key] = Math.round(
							amount /
								(productVarMap?.[key]?.factorPurchaseUnit || 1)
						);
					} else {
						newObj[key] = 0;
					}
				}
			);
			return [
				{
					...skuStockInfo[0],
					values: newObj,
				},
			];
		}
		return [];
	}, [PRODUCTS, skuStockInfo]);

	useEffect(() => {
		fetchProducts({
			entity: 'ALL_PRODUCTS',
			req: {
				params: {
					id: '',
					variants: true,
				},
			},
			method: 'LIST',
		});
		fetchStoresList({
			entity: 'STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		fetchStoresList({
			entity: 'FILTERED_STORES',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	useEffect(() => {
		fetchVendors({
			entity: orderTypeIdQuery === '8' ? 'ALL_BUYERS' : 'ALL_VENDORS',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
			type: orderTypeIdQuery === '8' ? 'BUYER' : 'VENDOR',
		});
	}, [orderTypeIdQuery]);

	useEffect(() => {
		if (state?.deliverTo?.id || state?.store?.id) {
			fetchProcessTypesList({
				entity: 'PROCESS_TYPES',
				req: {
					params: {
						id: '',
						storeId: state?.store?.id ?? state?.deliverTo?.id,
					},
				},
				method: 'LIST',
			});
		}
	}, [state?.deliverTo?.id, state?.store?.id]);

	useEffect(() => {
		if (['1', '4', '5', '6', '7', '8'].includes(orderTypeIdQuery)) return;
		if (state?.fromStore?.id) {
			const wareOrderType = orderTypes?.[2];
			const baseOrderType = orderTypes?.[1];
			switch (state?.fromStore?.type) {
				case 'WAREHOUSE_INGREDIENT':
					setSelectedOrderType(wareOrderType);
					break;
				default:
					setSelectedOrderType(baseOrderType);
					break;
			}
		}
	}, [state?.fromStore?.id, orderTypeIdQuery]);
	// eslint-disable-next-line no-unused-vars
	const handleVendorChangePrice = (poItem, itemPrice) => {
		// const itemPrice = mappedData?.[poItem?.id] ?? poItem?.selectedVariant?.price;
		const productQtyValue = poItem?.quantity;
		const productInputUnit = poItem?.productInputUnit;
		const subTotal = config.getSubTotal(
			itemPrice,
			0,
			productQtyValue,
			productInputUnit
		);
		const totalTax = config.getTotalTax(
			itemPrice,
			0,
			poItem?.tax,
			productQtyValue,
			productInputUnit
		);
		const totalDiscount = config.getTotalDiscount(
			itemPrice,
			0,
			productQtyValue,
			productInputUnit
		);
		const newItem = {
			...poItem,
			totalPriceIncTax: config.getTotalPrice(subTotal, totalTax),
			totalDiscount,
			disabledPrice: true,
		};
		newItem.selectedVariant.price = itemPrice;
		return newItem;
	};

	const fetchVendorProductPricing = useCallback(
		async (data) => {
			try {
				const response =
					await allVendorServices.VendorContractProductsPricing(data);
				if (response.statusCode === 200) {
					const priceData = response?.data?.prices;
					const paymentTerm = response?.data?.contract?.paymentTerm;
					if (paymentTerm) {
						setDisableTerms(true);
					} else {
						setDisableTerms(false);
					}
					// if (!priceData) {
					// 	const newState = getLatestPoItems?.map((poItem) => {
					// 		if (poItem?.disabledPrice) {
					// 			const variantSelected = poItem?.variants?.find(
					// 				(variant) => {
					// 					return (
					// 						variant?.id ===
					// 						poItem?.selectedVariant?.id
					// 					);
					// 				}
					// 			);
					// 			let itemPrice = variantSelected?.price;
					// 			if (
					// 				poItem?.unit === 'gram' ||
					// 				poItem?.unit === 'ml'
					// 			) {
					// 				itemPrice *= 1000;
					// 			}
					// 			if (poItem?.unit === 'oz') {
					// 				itemPrice *= 16;
					// 			}
					// 			if (poItem?.unit === 'floz') {
					// 				itemPrice *= 128;
					// 			}
					// 			const newItem = handleVendorChangePrice(
					// 				poItem,
					// 				itemPrice
					// 			);
					// 			const newItemDisabled = {
					// 				...newItem,
					// 				disabledPrice: false,
					// 			};
					// 			return newItemDisabled;
					// 		}
					// 		return poItem;
					// 	});
					// 	setState('SET_PO_ITEMS', newState);
					// }
					dispatch({
						type: 'SET_PAYMENT_TERM',
						payload: paymentTerm,
					});
					const mappedData = {};
					priceData.forEach((item) => {
						mappedData[item?.variantId] = item?.price;
					});
					setVendorPrices(mappedData);
					// const newState = getLatestPoItems?.map((poItem) => {
					// 	console.log('====', poItem);
					// 	if (poItem?.id in mappedData) {
					// 		let itemPrice =
					// 			mappedData?.[poItem?.id] ??
					// 			poItem?.selectedVariant?.price;
					// 		if (poItem?.unit === 'gram' || poItem?.unit === 'ml') {
					// 			itemPrice *= 1000;
					// 		}
					// 		if (poItem?.unit === 'oz') {
					// 			itemPrice *= 16;
					// 		}
					// 		if (poItem?.unit === 'floz') {
					// 			itemPrice *= 128;
					// 		}
					// 		return handleVendorChangePrice(poItem, itemPrice);
					// 	}
					// 	if (poItem?.disabledPrice) {
					// 		const variantSelected = poItem?.variants?.find(
					// 			(variant) => {
					// 				return (
					// 					variant?.id === poItem?.selectedVariant?.id
					// 				);
					// 			}
					// 		);
					// 		let itemPrice = variantSelected?.price;
					// 		if (poItem?.unit === 'gram' || poItem?.unit === 'ml') {
					// 			itemPrice *= 1000;
					// 		}
					// 		if (poItem?.unit === 'oz') {
					// 			itemPrice *= 16;
					// 		}
					// 		if (poItem?.unit === 'floz') {
					// 			itemPrice *= 128;
					// 		}
					// 		const newItem = handleVendorChangePrice(
					// 			poItem,
					// 			itemPrice
					// 		);
					// 		const newItemDisabled = {
					// 			...newItem,
					// 			disabledPrice: false,
					// 		};
					// 		return newItemDisabled;
					// 	}
					// 	return poItem;
					// });
					// setState('SET_PO_ITEMS', newState);
					notificationServices.generateNotification({
						type: 'success',
						message:
							response?.message ??
							'Vendor product pricing fetched successfully',
					});
				} else {
					setDisableTerms(false);
					setVendorPrices({});
				}
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log(error);
			}
		},
		[state?.poItems]
	);

	useEffect(() => {
		if (
			(orderTypeIdQuery === '1' &&
				state?.vendor?.id &&
				state?.deliverTo?.id) ||
			(orderTypeIdQuery === '8' &&
				state?.vendor?.id &&
				state?.fromStore?.id)
		) {
			if (orderTypeIdQuery === '1') {
				fetchVendorProductPricing({
					vendorId: state?.vendor?.id,
					storeId: state?.deliverTo?.id,
				});
			} else {
				fetchVendorProductPricing({
					vendorId: state?.vendor?.id,
					storeId: state?.fromStore?.id,
				});
			}
		}
	}, [
		orderTypeIdQuery,
		state?.vendor?.id,
		state?.deliverTo?.id,
		state?.fromStore?.id,
	]);
	// useEffect(() => {
	// 	if (
	// 		orderTypeIdQuery === '8' &&
	// 		state?.vendor?.id &&
	// 		state?.fromStore?.id
	// 	) {
	// 		fetchVendorProductPricing({
	// 			vendorId: state?.vendor?.id,
	// 			storeId: state?.fromStore?.id,
	// 		});
	// 	}
	// }, [orderTypeIdQuery, state?.vendor?.id, state?.fromStore?.id]);

	async function fetchPOId() {
		if (poId) return;
		try {
			const response = await inventoryAnalyticsServices.fetchPOId();
			if (response?.statusCode === 200) {
				setState('SET_PURCHASE_ORDER', response?.data);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	}

	useEffect(() => {
		if (state?.deliverTo?.id || state?.fromStore?.id) {
			(async () => {
				const req = {
					data: {
						store: {
							id: state?.deliverTo?.id ?? state?.fromStore?.id,
						},
						mappingTypes: [6],
						categoryId: null,
						products: null,
					},
				};
				try {
					const response =
						await productProcessMapServices?.fetchProductWiseMappingData(
							req
						);
					if (response?.responseCode === 'SS-001') {
						notificationServices.generateNotification({
							type: 'success',
							message: response?.responseMessage,
						});
						setProductWiseMappingData(
							response?.data?.productwiseMappings
						);
					}
				} catch (error) {
					notificationServices.generateNotification({
						type: 'error',
						message: error?.message,
					});
				}
			})();
		}
	}, [state?.deliverTo?.id, state?.fromStore?.id]);

	useEffect(() => {
		if (selectedOrderType?.id === 2) {
			(async () => {
				const req = {
					data: {
						store: {
							id: null,
						},
						mappingTypes: [12],
						categoryId: null,
						products: null,
					},
				};
				try {
					const response =
						await productProcessMapServices?.fetchProductWiseMappingData(
							req
						);
					if (response?.responseCode === 'SS-001') {
						notificationServices.generateNotification({
							type: 'success',
							message: response?.responseMessage,
						});
						const productIds = [];
						response?.data?.productwiseMappings?.forEach((ele) => {
							if (ele?.mappingTypeElementValue === '1') {
								productIds.push(ele?.productId);
							}
						});
						setInternalProductionProductIds(productIds);
					}
				} catch (error) {
					notificationServices.generateNotification({
						type: 'error',
						message: error?.message,
					});
				}
			})();
		}
	}, [selectedOrderType?.id]);

	useEffect(() => {
		if (
			selectedOrderType?.id !== 7 &&
			(state?.deliverTo?.id || state?.storeId?.id)
		) {
			(async () => {
				const req = {
					data: {
						store: {
							id: state?.deliverTo?.id ?? state?.storeId?.id,
						},
						mappingTypes: [11],
						categoryId: null,
						products: null,
					},
				};
				try {
					const response =
						await productProcessMapServices?.fetchProductWiseMappingData(
							req
						);
					if (response?.responseCode === 'SS-001') {
						notificationServices.generateNotification({
							type: 'success',
							message: response?.responseMessage,
						});
						const productIds = [];
						response?.data?.productwiseMappings?.forEach((ele) => {
							if (ele?.mappingTypeElementValue === '1') {
								productIds.push(ele?.productId);
							}
						});
						setRestrictedProducts(productIds);
					}
				} catch (error) {
					notificationServices.generateNotification({
						type: 'error',
						message: error?.message,
					});
				}
			})();
		}
	}, [state?.deliverTo?.id, selectedOrderType?.id]);

	useEffect(() => {
		if (selectedOrderType?.id === 2 || selectedOrderType?.id === 3) {
			const store = storesList?.find((ele) => {
				return ele?.id === (Number(queryByStore) ?? parentStore);
			});
			if (store) {
				setState('SET_FROM_STORE', store);
			}
		}
		const deliverToStore = storesList?.find((ele) => {
			return ele?.id === (Number(queryToStore) ?? storeId);
		});
		if (deliverToStore) {
			setState('SET_DELIVER_TO', deliverToStore);
			// setState('SET_PROCESS', {});
		}
	}, [
		selectedOrderType?.id,
		storesList,
		parentStore,
		storeId,
		queryToStore,
		queryByStore,
	]);

	const fetchAssets = () => {
		fetchAssetList({
			req: {},
		});
	};

	const fetchServices = () => {
		// eslint-disable-next-line no-unused-vars
		const resp = assetServices.listCapexService().then((result) => {
			setServices(result.data);
		});
	};

	useEffect(() => {
		if (selectedOrderType?.id === 4 || selectedOrderType?.id === 7) {
			fetchAssets();
			if (selectedOrderType?.id !== 7) {
				fetchServices();
			}
		}
	}, [selectedOrderType?.id]);

	useEffect(() => {
		if (selectedOrderType?.id === 4 || selectedOrderType?.id === 7) {
			const updatedAssetList = assetList?.map((itm) => {
				return {
					...itm,
					productName: itm.name,
					discountPercentage: 0,
					unit: 'count',
					productImageUrl: itm?.image,
					tax: itm?.taxPercentage,
					variants: [
						{
							id: itm?.id,
							displayName: 'ASSET',
							price: 1,
						},
					],
				};
			});
			let serviceList = [];
			if (selectedOrderType?.id !== 7) {
				serviceList = servicesList?.map((itm) => {
					return {
						...itm,
						productName: itm.name,
						discountPercentage: 0,
						unit: 'count',
						tax: 18,
						variants: [
							{
								// eslint-disable-next-line prefer-template
								id: 's' + itm?.id,
								displayName: 'SERVICE',
								price: 1,
							},
						],
					};
				});
			}
			const concatList = [...updatedAssetList, ...serviceList];
			setAsset(concatList);
		}
	}, [selectedOrderType?.id, assetList, servicesList]);

	const taxApplied = useMemo(() => {
		const taxAppliedState = {
			isApplied: true,
			reason: '',
		};
		if (
			state?.vendor?.id &&
			state?.vendorLocation?.id &&
			!state?.vendorLocation?.gstin
		) {
			taxAppliedState.isApplied = true;
			taxAppliedState.reason = `GST will be split between CGST and SGST Due to Vendor (${state?.vendor?.name})'s GSTIN Absence`;
		}
		if (state?.deliverTo?.id && !state?.deliverTo?.gstNumber) {
			taxAppliedState.isApplied = false;
			taxAppliedState.reason = `GST Cannot Be Claimed Due to Deliver to Store (${state?.deliverTo?.name}) Unregistered GSTIN`;
		}
		if (state?.fromStore?.id && !state?.fromStore?.gstNumber) {
			taxAppliedState.isApplied = false;
			taxAppliedState.reason = `GST Cannot Be Claimed Due to Purchase From Store (${state?.fromStore?.name}) Unregistered GSTIN`;
		}
		return taxAppliedState;
	}, [
		state?.vendor,
		state?.vendorLocation?.id,
		state?.fromStore,
		state?.deliverTo,
	]);

	const calculatedData = useMemo(() => {
		let subTotal = 0;
		let totalTax = 0;
		let totalAmount = 0;
		let totalDiscount = 0;
		let taxValues = {};
		const totalQuantity = state?.poItems?.length;
		state?.poItems?.forEach((ele) => {
			// if (ele?.unit === 'gram') {
			subTotal += ele?.subTotal;
			totalAmount += ele?.totalPriceIncTax;
			totalTax += ele?.totalTax;
			totalDiscount += ele?.totalDiscount;
		});
		const pc = Number(state?.packagingCharges ?? 0);
		const dc = Number(state?.deliveryCharges ?? 0);
		totalTax += ((pc + dc) * taxPercentage) / 100;
		totalAmount += (pc + dc) * (1 + taxPercentage / 100);
		if (state?.additionalCharges?.length) {
			state?.additionalCharges?.forEach((ele) => {
				totalTax += Number(ele?.price * ele?.taxPercentage) / 100;
				totalAmount +=
					Number(ele?.price) * Number(1 + ele?.taxPercentage / 100);
			});
		}
		if (taxApplied?.isApplied) {
			taxValues.SGST = totalTax / 2;
			taxValues.CGST = totalTax / 2;
			if (state?.vendor?.id) {
				if (state?.vendor?.gstin && state?.deliverTo?.gstNumber) {
					const vendorState = getStateFromGSTIN(state?.vendor?.gstin);
					const storeState = getStateFromGSTIN(
						state?.deliverTo?.gstNumber
					);
					if (
						vendorState !== storeState &&
						vendorState !== 'Unknown'
					) {
						taxValues = {
							IGST: totalTax,
						};
					}
				}
			} else {
				const fromStoreState = getStateFromGSTIN(
					state?.fromStore?.gstNumber
				);
				const toStoreState = getStateFromGSTIN(
					state?.deliverTo?.gstNumber
				);
				if (fromStoreState !== toStoreState) {
					taxValues = {
						IGST: totalTax,
					};
				}
			}
		}
		return {
			subTotal,
			totalQuantity,
			totalTax,
			totalAmount,
			taxValues,
			totalDiscount,
		};
	}, [
		state?.poItems,
		state?.vendor?.id,
		state?.deliverTo?.gstNumber,
		state?.packagingCharges,
		state?.deliveryCharges,
		state?.additionalCharges,
	]);
	const isRowError = useCallback(() => {
		const varMap = {};
		state?.poItems?.forEach((ele) => {
			varMap[ele?.selectedVariant?.id] = ele;
		});
		let result = false;
		Object?.entries(rowError)?.forEach(([eleKey, ele]) => {
			Object?.keys(ele ?? {})?.forEach((innerEleKey) => {
				if (ele?.[innerEleKey] === true) {
					const element = varMap[eleKey];
					const str = `for variantId: ${eleKey}, Name: ${element?.productName} (${element?.selectedVariant?.displayName})`;
					if (innerEleKey === 'rate') {
						notificationServices.generateNotification({
							type: 'error',
							message: `Please enter valid Rate ${str}`,
						});
					}
					if (innerEleKey === 'quantity') {
						notificationServices.generateNotification({
							type: 'error',
							message: `Please enter valid Quantity ${str}`,
						});
					}
					result = true;
				}
			});
		});
		return result;
	}, [rowError]);

	const handleSave = async () => {
		// if (selectedOrderType?.id !== 7) {
		if (isRowError()) {
			return;
		}
		// }
		const [validationError, focusIndex] = config?.validator(
			state,
			orderTypeIdQuery ?? selectedOrderType?.id
		);
		if (validationError?.length > 0) {
			validationError?.forEach((err) => {
				notificationServices.generateNotification({
					type: 'error',
					message: err,
				});
			});
			if (focusIndex !== -1) {
				const children = itemComponentRef?.current?.children;
				if (children && children.length > 0) {
					children[focusIndex].scrollIntoView({
						behavior: 'smooth',
						block: 'center',
					});
				}
			}
			return;
		}
		const currentDate = moment().startOf('day')?.toDate();
		if (currentDate > state?.expectedDeliveryDate) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Past Delivery Date not allowed',
			});
			return;
		}
		// console.log('parentGroupedAssets', parentGroupedAssets);
		// console.log('state', state);
		// if (selectedOrderType?.id === 7) return;
		const deserializedData = config?.deserialize(
			state,
			selectedOrderType?.id,
			parentGroupedAssets
		);
		const req = {
			data: {
				...deserializedData,
				taxValues: calculatedData?.taxValues,
				type: selectedOrderType?.enum,
				taxOnDelivery: taxPercentage,
				taxOnPackaging: taxPercentage,
				nextFlowLink: `${window?.location?.origin}${urls.EXTENSION}${urls.VENDOR_ORDER_LIST}`,
			},
		};
		setIsLoading(true);
		try {
			if (selectedOrderType?.id === 8) {
				req.data.store = state?.fromStore?.id;
				req.data.uploadedDoc = doc;
			}
			if (selectedOrderType?.id === 1) {
				req.data.flag = vendorFlag;
			}
			const response = await inventoryAnalyticsServices?.addVendorPO(req);
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
				dispatch({
					type: 'INIT',
				});
				fetchPOId();
				if (orderTypeIdQuery) {
					history.replace(history?.location?.state?.prevPath, {
						prevPath: location?.pathname,
					});
				}
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};
	const handleEditSave = async () => {
		const validationError = config?.validator(
			state,
			selectedOrderType?.id ?? orderTypeIdQuery
		);
		if (validationError?.[0]?.length > 0) {
			validationError?.[0]?.forEach((err) => {
				notificationServices.generateNotification({
					type: 'error',
					message: err,
				});
			});
			return;
		}
		const currentDate = moment().startOf('day')?.toDate();
		if (currentDate > state?.expectedDeliveryDate) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Past Delivery Date not allowed',
			});
			return;
		}
		const deserializedData = config?.deserialize(state);
		const req = {
			params: {
				poId,
			},
			data: {
				...deserializedData,
				taxValues: calculatedData?.taxValues,
				type: selectedOrderType?.enum,
				nextFlowLink: `${window?.location?.origin}${urls.EXTENSION}${urls.VENDOR_ORDER_LIST}`,
			},
		};
		try {
			if (selectedOrderType?.id === 1) {
				req.data.flag = vendorFlag;
			}
			const response = await inventoryAnalyticsServices?.editVendorPO(
				req
			);
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
				dispatch({
					type: 'INIT',
				});
				if (orderTypeIdQuery) {
					history.goBack();
				}
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	const handleGenerateStandardPO = async () => {
		const req = {
			params: {
				store: state?.deliverTo?.id,
				fromStore: state?.fromStore?.id,
			},
		};
		try {
			const response = await inventoryAnalyticsServices.fetchStandardPO(
				req
			);
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
				toggleGenerateStandardPOModal(true, {
					standardPOData: response?.data,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log('Error', error);
		}
	};

	const handleSelectOrderType = (e) => {
		const orderType = orderTypes?.find((ele) => {
			return ele?.id === Number(e?.target?.value);
		});
		setSelectedOrderType(orderType);
		dispatch({
			type: 'INIT',
			payload: {},
		});
		fetchPOId();
		// dispatch({
		// type: 'SET_FROM_STORE',
		// payload: {},
		// });
		// setPOItems([]);
	};

	// const STORES_LIST = useMemo(() => {
	// 	if (selectedOrderType?.enum === 'PURCHASEORDER') {
	// 		return storesList;
	// 	}
	// 	return storesList;
	// }, [selectedOrderType?.enum, storesList]);

	const handleAdditionalCharges = (idx, e) => {
		// console.log('=============', idx, e);
		const { fieldName, fieldValue } = inputHelper(e);
		const newAdditionalCharge = state?.additionalCharges?.map(
			(ele, index) => {
				if (index === idx) {
					return {
						...ele,
						[fieldName]: fieldValue,
					};
				}
				return ele;
			}
		);
		dispatch({
			type: 'SET_VALUE',
			payload: {
				additionalCharges: newAdditionalCharge,
			},
		});
	};
	const addAdditonalCharges = () => {
		const newAdditionalCharge = [...state?.additionalCharges];
		newAdditionalCharge?.push({
			// eslint-disable-next-line prefer-template
			type: 'Charges ' + newAdditionalCharge?.length,
			taxPercentage: null,
			price: null,
		});
		dispatch({
			type: 'SET_VALUE',
			payload: {
				additionalCharges: newAdditionalCharge,
			},
		});
	};

	const handleRemoveAdditionalCharge = (idx) => {
		const newAdditionalCharge = [...state?.additionalCharges];
		newAdditionalCharge?.splice(idx, 1);
		dispatch({
			type: 'SET_VALUE',
			payload: {
				additionalCharges: newAdditionalCharge,
			},
		});
	};

	const DEFAULT_ITEMS_STATE = useMemo(() => {
		return defaultPoItems?.map((poItem) => {
			// console.log('====', poItem);
			if (poItem?.variantId in vendorPrices) {
				let variantVendorPrice = vendorPrices?.[poItem?.variantId];
				const higherUnits = ['kg', 'ltr', 'lb', 'gal'];
				if (higherUnits?.includes(poItem?.unit)) {
					if (['kg', 'ltr']?.includes(poItem?.unit)) {
						variantVendorPrice *= 1000;
					}
					if (poItem?.unit === 'lb') {
						variantVendorPrice *= 16;
					}
					if (poItem?.unit === 'gal') {
						variantVendorPrice *= 128;
					}
				}
				return {
					...poItem,
					variantVendorPrice,
					disabledPrice: true,
				};
			}
			return {
				...poItem,
				disabledPrice: false,
			};

			// if (poItem?.disabledPrice) {
			// 	const newItemDisabled = {
			// 		...poItem,
			// 		disabledPrice: false,
			// 	};
			// 	return newItemDisabled;
			// }
		});
	}, [defaultPoItems, vendorPrices]);

	// filtercolumns logic here
	const filterColumns = useMemo(() => {
		if ([5, 6]?.includes(selectedOrderType?.id)) {
			return [
				'EXPECTED LEFT OVER',
				'TAX %',
				'Total Discount',
				'Discount%',
				'RATE',

				'QTY-INFO',
				'Total Amount',
				'Sub total',
				'Total Tax',
			];
		}
		if (selectedOrderType?.id === 4 || selectedOrderType?.id === 7) {
			return [
				'EXPECTED LEFT OVER',
				'Total Discount',
				'Discount%',
				'UNIT',
			];
		}
		return undefined;
	}, [selectedOrderType?.id]);

	const onAssetGroupSelect = useCallback(
		(callback, groupKey, delta, newValue) => {
			setPOItemsObject(newValue);
			callback();
		},
		[setPOItemsObject]
	);

	return (
		<div className={styles.root}>
			<AlertModal />
			<FileUploaderModal handleConfirm={handleDocUpload} />
			<BackdropLoader isLoading={isLoading} showProgress />
			<AddNewVendorModal />
			<GenerateStandardPo
				poState={state?.poItems}
				getVarPrice={getVarPrice}
				setPoState={setPOItems}
			/>
			<ErrorModal />
			<CreatePurchaseOrderHeader
				showSave
				selectedOrderType={selectedOrderType}
				setSelectedOrderType={setSelectedOrderType}
				handleSave={poId ? handleEditSave : handleSave}
				isEdit={!!poId}
				disableSave={
					selectedOrderType?.id === 7
						? _.isEmpty(state?.poItemsObject)
						: !state?.poItems.length
				}
				prevPath={history?.location?.state?.prevPath}
			/>
			<div className={styles['order-type']}>
				<label>Order Type:</label>
				{orderTypeIdQuery ? (
					<p>{selectedOrderType?.name}</p>
				) : (
					<div className={styles['selector-container']}>
						<TextField
							id='orderType'
							select
							value={selectedOrderType?.id ?? -1}
							onChange={handleSelectOrderType}
							label='Select Order Type'
							sx={{
								width: 300,
								'.MuiInputBase-root': {
									borderRadius: '4px',
								},
								'.MuiInputBase-input': {
									paddingTop: '6px',
									paddingBottom: '6px',
								},
							}}
						>
							{orderTypes
								?.filter((ele) => {
									if (exclude) {
										return ele?.id !== Number(exclude);
									}
									return true;
								})
								?.map((orderType) => {
									return (
										<MenuItem
											key={orderType?.id}
											value={orderType?.id}
										>
											{orderType?.name}
										</MenuItem>
									);
								})}
						</TextField>
						{!selectedOrderType?.id && (
							<p className={styles['warning-text']}>
								Select Order Type First
							</p>
						)}
					</div>
				)}
			</div>
			{selectedOrderType?.id && (
				<div className={styles.body}>
					<POGeneralDetails
						dispatchDelta={dispatchDelta}
						state={state}
						disableMsg={
							state?.poItems?.length > 0 &&
							selectedOrderType?.id !== 5
								? 'Please clear items to edit fields again'
								: false
						}
						shiftsList={shiftsList}
						vendors={orderTypeIdQuery === '8' ? buyers : vendors}
						filteredStoresList={filteredStoresList}
						setState={setState}
						P2PTransfer={P2PTransfer}
						requiredFields={selectedOrderType?.fields}
						toggleAddVendorModal={toggleAddVendorModal}
						processList={processTypes}
						isDeliverToDisabled={!!queryToStore}
						queryUserOrderType={queryUserOrderType}
						queryStoreTypes={queryStoreTypes}
						orderType={selectedOrderType?.id}
						mandatoryFields={{
							...(selectedOrderType?.id === 5 && {
								fromProcess: true,
								toProcess: true,
							}),
							vendorLocation:
								!!state?.vendor?.vendor_locations?.length,
						}}
						disbalePaymentTerm={disableTerms}
						isEdit={!!poId}
						type={orderTypeIdQuery === '8' ? 'BUYER' : 'VENDOR'}
						uploadInvoiceCick={uploadInvoiceCick}
						doc={doc}
					/>
					{selectedOrderType?.id !== 7 ? (
						<>
							<POItemSelection
								dispatchDelta={dispatchDelta}
								state={state?.poItems}
								setState={setPOItems}
								fromStore={state?.fromStore}
								deliverTo={state?.deliverTo}
								skuStockInfo={
									selectedOrderType.id === 4 ||
									selectedOrderType.id === 7
										? []
										: SKU_STOCK_INFO
								}
								taxApplied={taxApplied}
								productsList={
									selectedOrderType.id === 4 ||
									selectedOrderType.id === 7
										? asset
										: PRODUCTS
								}
								productWiseMappingData={productWiseMappingData}
								requiredFields={selectedOrderType?.fields}
								typeId={selectedOrderType?.id}
								updateManualRateUpdatedVariantIds={updateManualRateUpdatedVariantIds}
								handleGenerateStandardPO={
									handleGenerateStandardPO
								}
								rowError={rowError}
								ratePerString={
									selectedOrderType.id === 2 ? 'Transfer' : ''
								}
								ref={itemComponentRef}
								setRowError={setRowError}
								defaultSelectedItems={
									poId ? DEFAULT_ITEMS_STATE : null
								}
								disabled={
									![5, 6]?.includes(selectedOrderType?.id)
										? (!state?.vendor?.id &&
												!state?.fromStore?.id) ||
										  (selectedOrderType?.id !== 8 &&
												!state?.deliverTo?.id) ||
										  !state?.purchaseOrderId ||
										  !state?.date ||
										  !state?.expectedDeliveryDate
										: !state?.toProcess?.id ||
										  !state?.date ||
										  !state?.store?.id ||
										  !state?.selectedShift
								}
								qtyRegex={
									selectedOrderType?.id === 4 ||
									selectedOrderType?.id === 7
										? /^\+?(0|[1-9]\d*)?$/
										: null
								}
								filterColumns={filterColumns}
								vendorPrices={vendorPrices}
							/>
							{![5, 6]?.includes(selectedOrderType?.id) && (
								<POTotalContainer
									calculatedData={calculatedData}
									packagingCharges={state?.packagingCharges}
									deliveryCharges={state?.deliveryCharges}
									handleChange={setState}
									showCharges
									taxPercentage={taxPercentage}
									disableCharges={false}
									additionalCharges={state?.additionalCharges}
									handleAdditionalCharges={
										handleAdditionalCharges
									}
									addAdditonalCharges={addAdditonalCharges}
									handleRemoveAdditionalCharge={
										handleRemoveAdditionalCharge
									}
									showAdditionalCharge={
										selectedOrderType?.id === 1 ||
										selectedOrderType?.id === 4 ||
										selectedOrderType?.id === 7
									}
								/>
							)}
						</>
					) : (
						<>
							<div className='w-full '>
								<AssetListing
									heightSubtraction={200}
									selectedStoreId={state?.fromStore?.id}
									avoidTrackingChildAssets
									onAssetGroupSelect={onAssetGroupSelect}
									setParentGroupedAssets={
										setParentGroupedAssets
									}
								/>
							</div>
						</>
					)}
				</div>
			)}
		</div>
	);
}

const mapStateToProps = (state) => {
	const { LIST: productsList } = state.form.ALL_PRODUCTS.data;
	const { LIST: vendors } = state.form.ALL_VENDORS.data;
	const { LIST: buyers } = state.form.ALL_BUYERS.data;
	const { LIST: storesList } = state?.form?.STORES?.data;
	const { LIST: filteredStoresList } = state?.form?.FILTERED_STORES?.data;
	const { navState } = state?.navigation;
	const { LIST: processTypes } = state?.form?.PROCESS_TYPES?.data;
	const assetList = state.assets.list;

	return {
		productsList,
		vendors,
		buyers,
		storesList,
		filteredStoresList,
		navState,
		processTypes,
		assetList,
	};
};

const mapDispatchToProps = {
	fetchProducts: formActions.gateway,
	fetchVendors: formActions.gateway,
	fetchStoresList: formActions.gateway,
	toggleAddVendorModal: modalActions.toggleAddVendorModal,
	toggleGenerateStandardPOModal: modalActions.toggleGenerateStandardPOModal,
	fetchProcessTypesList: formActions.gateway,
	fetchAssetList: assetActions.fetchAssetList,
	toggleFileUploaderModal: modalActions.toggleFileUploaderModal,
	toggleModal: modalActions.toggleAlert,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CreatePurchaseOrder);
