/* eslint-disable*/
import QcLogsHeader from '@/Components/QcTable/qcLogsHeader';
import { inputHelper, keyPressHandler, useQuery } from '@/Helpers';
import QualityInfo from './qualityInfo';
import { Tabs, TabsList, TabsTrigger } from '@/Components/ui/tabs';
import { useEffect, useState } from 'react';
import GrnQcLogTable from '@/Components/QcTable/grnQcLogTable';
import { grnQcLogService } from '@/Services/grnQcLog.services';
import { notificationServices } from '@/Services';
import { useHistory } from 'react-router-dom';
import { urls } from '@/Constants';
import useKeyPress from '@/Helpers/useKeyPress';
import { connect } from 'react-redux';
import { modalActions } from '@/Actions';
import { ImageModal } from '@/Components/Modals/ImageModal';
import { EditIcon } from 'lucide-react';
import QcApprovalModal from '@/Components/Modals/QcApprovalFlow/qcApprovalModal';


function QualityDetails(props) {
	const { toggleImageModal, toggleQualityApprovalModal } = props;
	const query = useQuery();
	const id = query.get('id');
	const storeId = query.get('storeId');
	const status = query.get('status');
	const dateRangeFilter = query.get('dateRangeFilter');
	const [tabValue, setTabValue] = useState('qualityChecks');
	const [data, setData] = useState([]);
	const [details, setDetails] = useState({});
	const [listData, setListData] = useState([]);
	const history = useHistory();
	const [idx, setIdx] = useState(-1);
	const keyPressed = useKeyPress();
	const [valueRecorded, setValueRecorded] = useState({});


	const fetchGrnQcLog = async () => {
		const req = {
			data: {},
		}  
		try {
			const res = await grnQcLogService.fetchGrnQcLog(req);
            if(res?.statusCode === 200) {
                setListData(res?.data);
            }else{
                notificationServices.generateNotification({
                    type: 'error',
                    message: res?.message,
                });
            }
		} catch (error) {
			console.log(error, 'error');
		}
	};
	const parameterColMap = {
		'Parameter': 'name',
		'Input Mode': 'inputType',
		'Unit': 'unit',
		'Min Value': 'min',
		'Max Value': 'max',
		'Value Recorded': 'values',
		'Status': 'status',
		'Capture Image': 'imagesUrl',
	};

	const parameterSpecialColumns = [
		{
			title: 'Parameter',
			config: {
				type: 'Data',
			},
		},
		{
			title: 'Input Mode',
			config: {
				type: 'Data',
			},
		},
		{
			title: 'Unit',
			config: {
				type: 'Data',
			},
		},
		{
			title: 'Min Value',
			config: {
				type: 'Data',
			},
		},
		{
			title: 'Max Value',
			config: {
				type: 'Data',
			},
		},
		{
			title: 'Value Recorded',
			config: {
				type: 'Data',
			},
		},
		{
			title: 'Status',
			config: {
				type: 'Data',
			},
		},
		{
			title: 'Capture Image',
			config: {
				type: 'Data',
			},
		},
		
	];
	
	const handleInputChange = (e) => {
		e.preventDefault();
		const { dataset, fieldName, fieldValue} = inputHelper(e);
		setValueRecorded((prev) => {
			return {
				...prev,
				[dataset.id]: {
					...prev[dataset.id],
					[fieldName]: fieldValue,
				},
			}
		});
	};
	const parameterSpecialColumnsMappings = {
		'Parameter': (id, items) => {
			return <p className='font-semibold text-gray-700'>{items}</p> || 'N/A';
		},
		'Input Mode': (id, items) => {
			return <p className='font-semibold text-gray-700'>{items}</p> || 'N/A';
		},	
		'Unit': (id, items) => {
			return <p className='font-semibold text-gray-700'>{items}</p> || 'N/A';
		},
		'Min Value': (id, items) => {
			return <p className='font-semibold text-gray-700'>{items}</p> || 'N/A';
		},
		'Max Value': (id, items) => {
			return <p className='font-semibold text-gray-700'>{items}</p> || 'N/A';
		},
		'Value Recorded': (id, items) => {
			const newData = data?.find((item) => item?.id === id);
			return (
				<>
					{details?.status === 'QC_APPROVAL_PENDING' && (newData?.qualityParameterId !== 1 || newData?.qualityParameterId !== 2) ? (
						<div className='flex  gap-2 justify-center items-center '>
						<input
							value={valueRecorded?.[newData?.id]?.value}
							onChange={handleInputChange}
							name="value"
							data-id={newData?.id}
							className='w-[100px] border border-gray-300 rounded-md p-2'
						/>
						<EditIcon className='w-4 h-4 text-gray-700' />
						</div>
					): (
						<p className='font-semibold text-gray-700'>{newData?.value} {newData?.value && newData?.value2 ? ' , ' : ''} {newData?.value2}</p>
					)}
				</>
			);
		},
		'Status': (id, items) => {
			return items ? <p className={`font-semibold text-lg ${items === 'GRN' ? 'text-green-600' : 'text-red-600'}`}>{items === 'GRN' ?  'Pass' : 'Fail'}</p> : 'N/A'; 
		},
		'Capture Image': (id, items) => {
			return items?.length > 0 ? <button className='bg-blue-500 text-white px-2 py-1 rounded-md'
			 onClick={() => toggleImageModal(true, {
				 heading: 'Captured Images',
				 image: items,
				 width: '80%',
				 height: '80%',
			 })}>View Images</button> : 'N/A';
		},
	};
	// scans col Map
	const scansColMap = {
		'Store': 'storeName',
		'QC time': 'QCTime',
		'Order Id': 'OrderId',
		'Weight': 'Weight',
		'Count': 'count',
		'Temp': 'Temp',
	};

	const scansSpecialColumns = [
		{
			title: 'Store',
			config: {
				type: 'Data',
			},
		},
		{
			title: 'QC time',
			config: {
				type: 'Data',
			},
		},
		{
			title: 'Order Id',
			config: {
				type: 'Data',
			},
		},
		{
			title: 'Weight',	
			config: {
				type: 'Data',
			},
		},
		{	
			title: 'Count',
			config: {
				type: 'Data',
			},
		},
		{	
			title: 'Temp',
			config: {
				type: 'Data',
			},
		},
	];

	const scansSpecialColumnsMappings = {
		'Store': (id, items) => {
			return items;
		},
		'QC time': (id, items) => {
			return items;
		},
		'Order Id': (id, items) => {
			return items;
		},
		'Weight': (id, items) => {
			return items;
		},
		'Count': (id, items) => {
			return items;
		},
		'Temp': (id, items) => {
			return items;
		},
	};


	useEffect(() => {
		const newQueryParams = {};
		if (query.get('tabValue')) {
			setTabValue(query.get('tabValue'));
			newQueryParams.tabValue = query.get('tabValue');
		}
		if (newQueryParams?.tabValue === 'machineScans') {
			fetchQcBatchId();
			setTabValue('machineScans');
			fetchQcBatchIdScans(newQueryParams);
		}else {
			fetchQcBatchId(newQueryParams);
		}
	}, [id]);

	useEffect(() => {
		const qcData = listData?.[idx] ?? null;
		if (
			(keyPressed === 'ArrowLeft' || keyPressed === 'ArrowRight') &&
			qcData !== null
		) {
			const newListItem = keyPressHandler(
				keyPressed,
				listData,
				idx
			);
			if (newListItem) {
				history.push({
					pathname: `${urls.EXTENSION}${urls.QUALITY_DETAILS}`,
					search: `id=${newListItem?.batchId}&storeId=${storeId}&status=${status}&dateRangeFilter=${dateRangeFilter}`,
					state: {
						from: history?.location?.state?.from,
					},
				});
			}
		}
	}, [keyPressed]);


	const fetchQcBatchId = async () => {
		const req = {
			data: {
				batchId: Number(id),
			},
		};
		try {
			const res = await grnQcLogService.fetchQcBatchId(req);
			if(res?.statusCode === 200) {
				setDetails((prev) => {
					return {
						grnId: res?.data?.grnId,
						vendorName: res?.data?.vendorName,
						storeName: res?.data?.storeName,
						status: res?.data?.status,
						batchId: res?.data?.batchId,
						productName: res?.data?.productName,
						clientPoId: res?.data?.clientPoId,
						expiryDate: res?.data?.expiryDate,
						manufactureDate: res?.data?.manufacturedDate,
						quantityPerUnit: res?.data?.qtyPerPack,
						batchName: res?.data?.batchName,
						sampleQuantity: res?.data?.minimumSamplingQuantityPercentage,
						completionDate: res?.data?.completionDate ? res?.data?.completionDate.split(" ")[0] : '',
						timeStamp: res?.data?.completionDate ? res?.data?.completionDate.split(" ")[1] : '',
						quantity: res?.data?.qcQty,
						unit: res?.data?.unit,
					}
				});
				setData(res?.data?.qualityChecks);
				const value = res?.data?.qualityChecks?.reduce((acc, item) => {
					return {
					  ...acc,
					  [item?.id]: {
						value: item?.value,
						value2: item?.value2,
					  }
					};
				  }, {});
				setValueRecorded(value);
				notificationServices.generateNotification({
					type: 'success',
					message: 'Data fetched successfully',
				});
			}else{
				notificationServices.generateNotification({
					type: 'error',
					message: res?.message,
				});
			}
		} catch (error) {
			console.log(error);
		}
	};

	const fetchQcBatchIdScans = async () => {
		const req = {
			data: {
				batchId: Number(id),
				qcType: 'INWARD_QC',
			},
		};
		try {
			const res = await grnQcLogService.fetchAiSupervisorScans(req);
			if(res?.statusCode === 200) {
				setData(res?.data);
			}
		} catch (error) {
            notificationServices.generateNotification({
                type: 'error',
                message: error,
            });
        }
	}

	const navigateQC = (e) => {
		const { dataset } = inputHelper(e);
		let newListItem = null;

		if (dataset.type === 'next') {
			newListItem = listData?.[idx + 1];
		} else {
			newListItem = listData?.[idx - 1];
		}

		if (newListItem) {
			history.push({
				pathname: `${urls.EXTENSION}${urls.QUALITY_DETAILS}`,
				search: `id=${newListItem?.batchId}&storeId=${storeId}&status=${status}&dateRangeFilter=${dateRangeFilter}`,
			});
		}
	};

	useEffect(() => {
		fetchGrnQcLog();
	}, []);

	useEffect(() => {
		const searchParams = new URLSearchParams();
		searchParams.set('tabValue', tabValue);
		searchParams.set('id', id);
		searchParams.set('storeId', storeId);
		searchParams.set('status', status);
		searchParams.set('dateRangeFilter', dateRangeFilter);
		const search = searchParams.toString();
		history.replace({ search: search ? `?${search}` : "" });
	}, [tabValue]);


	useEffect(() => {
		if (listData?.length > 0) {
			const currentIndex = listData.findIndex(
				(item) => item.batchId === Number(id)
			);
			setIdx(currentIndex);
		}
	}, [id, listData]);

	const handleSave = async (qcId = null) => {
		const req = {
			data: {
				type: ['UPDATE_BATCH_STATUS', 'UPDATE_BATCH_CHECKLIST_VALUES'],
				batchId: Number(id),
				status: 'GRN',
				fieldValues: valueRecorded,
				qcNumber: qcId,
			},
		};
		try {
			const res = await grnQcLogService.updateQcValueRecorded(req);
			if(res?.statusCode === 200) {
				notificationServices.generateNotification({
				type: 'success',
				message: 'Data saved successfully',
			});
			setValueRecorded({});
			fetchQcBatchId();
		}else{
			notificationServices.generateNotification({
				type: 'error',
				message: res?.message,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}
	const handleReject = async () => {
		const req = {
			data: {
				type: ['UPDATE_BATCH_STATUS', 'UPDATE_BATCH_CHECKLIST_VALUES'],
				batchId: Number(id),
				status: 'QC_REJECTED',
				fieldValues: valueRecorded,
			},
		};
		try {
			const res = await grnQcLogService.updateQcValueRecorded(req);
			if(res?.statusCode === 200) {
				notificationServices.generateNotification({
				type: 'success',
				message: 'QC rejected successfully',
			});
			setValueRecorded({});
			fetchQcBatchId();
		}else{
			notificationServices.generateNotification({
				type: 'error',
				message: res?.message,
				});
			}
		} catch (error) {
			console.log(error);
		}
	};
	
	const handleOpenModal = () => {
		toggleQualityApprovalModal(true, {
			storeName: details?.storeName,
			vendorName: details?.vendorName,
			handleConfirm: handleSave,
		});
	}
	return(
		<>
		<ImageModal />
		<QcApprovalModal />
		<div className='flex flex-col min-h-screen relative'>
			<QcLogsHeader 
				navigateQC={navigateQC}
				idx={idx}
				listData={listData}
				id={id}
				storeId={storeId}
				status={status}
				dateRangeFilter={dateRangeFilter}
			/>
			<div className="mt-16">
				<QualityInfo
					details={details}
					handleSave={listData?.[idx]?.qcNumber ? () => handleSave() : handleOpenModal}
					handleReject={handleReject}
				/>
				<div className='flex flex-col px-6 pb-0'>
					<Tabs
						value={tabValue}
						onValueChange={(value) => {
							setTabValue(value);
							if(value === 'qualityChecks') {
								fetchQcBatchId();
							}else{
								fetchQcBatchIdScans();
							}
						}}
						defaultValue={tabValue}
					>
						<TabsList className='grid grid-cols-2 p-0 h-12 w-[35%]'>
							<TabsTrigger 
								value='qualityChecks' 
								className='rounded-t-md data-[state=active]:h-12 data-[state=active]:bg-yellow-200 data-[state=active]:text-black data-[state=active]:border-b data-[state=active]:border-black data-[state=inactive]:bg-gray-100'
							>
								Quality Checks
							</TabsTrigger>
							<TabsTrigger 
								value='machineScans' 
								className='rounded-t-md data-[state=active]:h-12 data-[state=active]:bg-yellow-200 data-[state=active]:text-black data-[state=active]:border-b data-[state=active]:border-black data-[state=inactive]:bg-gray-100'
							>
								AI Supervisor Qc scans
							</TabsTrigger>
						</TabsList>
					</Tabs>
					<div>
						{tabValue === 'qualityChecks' && 
							<GrnQcLogTable
								data={data}
								colMap={parameterColMap}
								special={parameterSpecialColumns}
								specialMappings={parameterSpecialColumnsMappings}
								tableType='qualityChecks'
							/>
						}
						{tabValue === 'machineScans' && 
							<GrnQcLogTable
								data={data}
								colMap={scansColMap}
								special={scansSpecialColumns}
								specialMappings={scansSpecialColumnsMappings}
								tableType='machineScans'
							/>
						}
					</div>
				</div>
			</div>
		</div>
	</>
	)
}
const mapStateToProps = (state) => {
	return {};
};
const mapDispatchToProps = {
	toggleImageModal: modalActions.toggleImageModal,
	toggleQualityApprovalModal: modalActions.toggleQualityApprovalModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(QualityDetails);