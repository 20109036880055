/* eslint-disable no-shadow */
/* eslint-disable object-curly-newline */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
/* eslint-disable */
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import { Download, Visibility } from '@mui/icons-material';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import ImagesFinder from '../../Components/ImagesFinder/ImagesFinder';
import { ListTable } from '../../Components/ListTable';
import styles from './UtilitiesPage.module.scss';
import { urls } from '../../Constants';
import financialTransactionsServices from '../../Services/Analytics Services/financial-transactions.services';
import inventoryAnalyticsServices from '../../Services/Analytics Services/inventory-analytics.services';
import { notificationServices } from '../../Services';
import { formActions, modalActions } from '../../Actions';
import { POItemsModal } from '../../Components/Modals/POItemsModal';
import { BackdropLoader } from '../../Components/Loaders';
import { handleExcelDownload } from '../../Helpers';
import useSearchFilters from '../../Helpers/useSearchFilters';
import userRolesAndPermissions from '@/Services/userRolesAndPermissions.services';
import { useAuth } from '@/Components/Auth/Login/auth';
import authActions from '@/Actions/auth.actions';

const statusList = [
	{
		id: 'CANCELLED',
		title: 'CANCELLED',
	},
	{
		id: 'PAID',
		title: 'PAID',
	},
	{
		id: 'UNPAID',
		title: 'UNPAID',
	},
	{
		id: 'PARTIALLY_PAID',
		title: 'PARTIALLY PAID',
	},
	{
		id: 'APPROVAL_PENDING_MANAGER',
		title: 'APPROVAL PENDING MANAGER',
	},
	{
		id: 'APPROVAL_PENDING_FINANCE',
		title: 'APPROVAL PENDING FINANCE',
	},
];

const timeTypeArr = [
	{
		label: 'Bill Date',
		value: 'transactionDate',
	},
	{
		label: 'Due Date',
		value: 'dueDate',
	},
];

const TABLE_COLUMNS = [
	{
		title: 'ID',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Vendor',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Store',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Status',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Payment By',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Created By',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Created At',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Client Utility ID',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Bill Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Due Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Category',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Period From',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Period To',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'L1 Action By',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'L2 Action By',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Amount Before tax',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Tax slab',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Tax amount',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Total Amount',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Reference doc',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'VIEW',
		},
	},
	{
		title: 'Actions',
		config: {
			type: 'DATA',
		},
	},
];

const COLMAP = {
	ID: 'id',
	Vendor: 'vendorName',
	Store: 'storeName',
	'Client Utility ID': 'clientTransactionId',
	Status: 'id',
	'Bill Date': 'transactionDate',
	'Due Date': 'dueDate',
	'Created By': 'initiatedByUserDisplayName',
	'Created At': 'createdAt',
	Category: 'category',
	'Period From': 'extraData',
	'Period To': 'extraData',
	'L1 Action By': 'l1ActionByUserDisplayName',
	'L2 Action By': 'l2ActionByUserDisplayName',
	'Tax slab': 'id',
	'Tax amount': 'id',
	'Amount Before tax': 'id',
	'Total Amount': 'id',
	'Reference doc': 'uploadedDoc',
};

const searchQueries = [
	{
		id: 'transactionId',
		type: 'billId',
		generateTitle: (exlusiveKey) => {
			return `Id: ${exlusiveKey}`;
		},
		removable: true,
		exclusiveKey: 'clientTransactionId',
		dependentKey: '',
	},
];

const UtilitiesPage = (props) => {
	const {
		togglePOItemsModal,
		navState,
		toggleAlert,
		stores,
		vendorsList,
		fetchAllVendors,
		fetchStoresList,
		userPermissions,
		userList,
		fetchUserList,
	} = props;
	const history = useHistory();
	const { userConfig } = useAuth();
	const directLevel = userConfig?.userDetails?.level;
	const [utilitiesList, setUtilitiesList] = useState([]);
	const [update, setUpdate] = useState(-1);
	const [isLoading, setIsLoading] = useState(false);

	const { searchFilters } = useSearchFilters({
		searchQueries,
		callBackFn: () => {
			setUpdate((prev) => {
				return prev + 1;
			});
		},
	});

	// userAcess
	// useEffect(async () => {
	// 	fetchUserList();
	// }, []);
	// // function page role of user access
	// const map = new Map(userList.map((item) => [item.value, item.label]));
	// const role = Object.keys(userConfig?.userRoles);
	// const userRoles = [];
	// role.forEach((ele) => {
	// 	userRoles.push(ele);
	// });

	// const pageRole = navState['/finance/management']?.submenu['/financial/utilities']?.rolesMapped;
	// const findMatchingRole = userRoles.filter((ele) => pageRole.includes(Number(ele)));
	// const roleDetails = findMatchingRole.map((role) => {
	// 	const key = Number(role);
	// 	const name = map.get(key);
	// 	return {
	// 		role: key, name,
	// 	};
	// });
	// const isInternal = roleDetails.some((item) => item.name === 'Internal' || item.name === 'CXO Office' || item.name === 'Finance');
	// const billApproval = roleDetails.some((ele) => ele?.name === 'Finance');
	// const cxoApproval = roleDetails.some((ele) => ele?.name === 'Finance');
	// const access = findMatchingRole.length > 0 ? userConfig?.userDetails?.level : false;
	// let permit;
	// if (access === false) {
	// 	permit = false;
	// } else {
	// 	permit = isInternal && access !== 1;
	// }
	// end user access
	useEffect(() => {
		fetchAllVendors({
			entity: 'ALL_VENDORS',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		fetchStoresList({
			entity: 'STORES',
			req: {
				params: {
					id: '',
					type: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	const storeIdMap = useMemo(() => {
		const map = {};
		stores?.forEach((ele) => {
			map[ele?.id] = ele;
		});
		return map;
	}, [stores]);

	const vendorIdMap = useMemo(() => {
		const map = {};
		vendorsList?.forEach((ele) => {
			map[ele?.id] = ele;
		});
		return map;
	}, [vendorsList]);

	const handleStatus = async (data, rejectionReason, type) => {
		let status = '';
		if (data?.transactionStatus === 'APPROVAL_PENDING_MANAGER') {
			status = 'APPROVAL_PENDING_FINANCE';
		}
		if (data?.transactionStatus === 'APPROVAL_PENDING_FINANCE') {
			if (data?.extraData?.isPaid) {
				status = 'PAID';
			} else {
				status = 'UNPAID';
			}
		}
		if (type === 'rejection') {
			status = 'REJECTED';
		}
		if (type === 'cancelation') {
			status = 'CANCELLED';
		}
		try {
			const responseData =
				await inventoryAnalyticsServices.updateInventoryTransaction({
					params: {
						transactionId: data?.id,
					},
					data: {
						status,
						type: 'BILL',
						nextFlowLink: `${window?.location?.origin}${urls.EXTENSION}${urls.UTILITIES_PAGE}?clientUtilityId=${data?.clientTransactionId}`,
						...(type === 'rejection' && {
							rejectionReason,
						}),
					},
				});
			if (responseData?.statusCode === 200) {
				setUpdate((prev) => {
					return prev + 1;
				});
				notificationServices?.generateNotification({
					type: 'success',
					message: responseData?.message,
				});
				togglePOItemsModal(false, {});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	const getTaxFromStr = (tt) => {
		let num = 0;
		let taxStr = tt;
		if (!taxStr) return num;
		if (taxStr?.includes?.('%')) {
			taxStr = taxStr?.replace('%', '');
			if (!Number.isNaN(taxStr)) {
				num = Number(taxStr);
			}
		}
		return num;
	};

	const specialItems = {
		'Tax slab': (id) => {
			const item = utilitiesList?.find((ele) => {
				return ele?.id === id;
			});
			const taxStr = item?.extraData?.taxSlab;
			if (!taxStr) {
				return '-';
			}
			return taxStr;
		},
		'Tax amount': (id, showRs = true) => {
			const item = utilitiesList?.find((ele) => {
				return ele?.id === id;
			});
			const taxStr = item?.extraData?.taxSlab;
			const tax = getTaxFromStr(taxStr);
			const beforeTax = item?.finalTransactionAmount / (1 + tax / 100);
			const taxA = item.finalTransactionAmount - beforeTax;
			return `${showRs ? '\u20B9' : ''}${taxA?.toFixed(2)}`;
		},
		'Amount Before tax': (id, showRs = true) => {
			const item = utilitiesList?.find((ele) => {
				return ele?.id === id;
			});
			const taxStr = item?.extraData?.taxSlab;
			const tax = getTaxFromStr(taxStr);
			const beforeTax = item?.finalTransactionAmount / (1 + tax / 100);
			return `${showRs ? '\u20B9' : ''}${beforeTax?.toFixed(2)}`;
		},
		'Total Amount': (id) => {
			const item = utilitiesList?.find((ele) => {
				return ele?.id === id;
			});
			return `\u20B9${item.finalTransactionAmount}`;
		},
		Status: (id) => {
			const item = utilitiesList?.find((ele) => {
				return ele?.id === id;
			});
			if (item?.transactionStatus === 'REJECTED') {
				return (
					<div className={styles.rejected}>
						<p>{item?.transactionStatus?.split('_')?.join(' ')}</p>
						<p>
							{item?.extraData?.rejectionReason
								? `Reason: (${item?.extraData?.rejectionReason})`
								: ''}
						</p>
					</div>
				);
			}
			return item?.transactionStatus?.split('_')?.join(' ');
		},
		'Payment By': (_, item) => {
			return item?.extraData?.isPaid ? 'Submitter' : 'Finance';
		},
		'Bill Date': (date) => {
			return moment(date)?.format('DD-MM-YYYY');
		},
		'Due Date': (date) => {
			return moment(date)?.format('DD-MM-YYYY');
		},
		'Created At': (date) => {
			return moment(date)?.format('DD-MM-YYYY');
		},
		'Period From': (extraData) => {
			return extraData?.periodFrom;
		},
		'Period To': (extraData) => {
			return extraData?.periodTo;
		},
		'Reference doc': (uploadedDoc) => {
			return (
				<div
					className={styles.reference}
					onClick={() => {
						if (uploadedDoc) {
							window.open(uploadedDoc, '_blank');
						} else {
							notificationServices.generateNotification({
								type: 'error',
								message: 'No Reference Uploaded',
							});
						}
					}}
				>
					<Download className={styles.icon} />
				</div>
			);
		},
		Actions: (id) => {
			const item = utilitiesList?.find((ele) => {
				return ele?.id === id;
			});
			return (
				<div className={styles.btns}>
					{(item?.transactionStatus === 'APPROVAL_PENDING_MANAGER') &&
					[3, 4].includes(directLevel) ? (
						<Button
							variant='contained'
							color='primary'
							onClick={() => {
								handleApproveClick(item);
								// handleApproval('APPROVAL_PENDING_MANAGER', id);
							}}
							fullWidth
						>
							Manager Approval
						</Button>
					) : null}
					{(item?.transactionStatus === 'APPROVAL_PENDING_FINANCE') &&
					[5].includes(directLevel) ? (
						<Button
							variant='contained'
							color='primary'
							onClick={() => {
								handleApproveClick(item);
								// handleApproval('APPROVAL_PENDING_FINANCE', id);
							}}
							fullWidth
						>
							Finance Approval
						</Button>
					) : null}
					{item?.transactionStatus !== 'APPROVAL_PENDING_FINANCE' &&
					item?.transactionStatus !== 'CANCELLED' &&
					item?.transactionStatus !== 'REJECTED' ? (
						<Button
							variant='outlined'
							color='error'
							onClick={() => {
								toggleAlert(true, {
									heading: `Cancel Utility Bill - ${item?.clientTransactionId}`,
									handleConfirm: () => {
										handleStatus(item, id, 'cancelation');
										toggleAlert(false);
									},
									colorReverse: false,
									cancelText: 'No',
									confirmText: 'Yes',
									message: `Are you sure you want to Cancel Entry: ${
										item?.clientTransactionId || ''
									}?`,
									width: '28rem',
								});
							}}
							fullWidth
						>
							Cancel
						</Button>
					) : (
						'-'
					)}
				</div>
			);
		},
	};

	function handleApproveClick(item) {
		togglePOItemsModal(true, {
			heading: `Utility Bill #${item?.clientInvoiceId ?? item?.id}`,
			...item,
			isApprove: true,
			advanceBill: false,
			approvalText: 'I have checked the Utility Bill.',
			showTotal: false,
			showPdf: true,
			showPoDetails: true,
			width: '90%',
			height: '90%',
			txnHeader: {
				show: item?.extraData?.isPaid,
				heading: 'Transaction Already PAID',
				helper: 'Post Approval, a payment record will be generated',
			},
			transactionData: {
				'Client Utility ID': item?.clientTransactionId,
				'Amount Before Tax': specialItems['Amount Before tax']?.(
					item.id,
					true
				),
				'Tax Slab': specialItems['Tax slab']?.(item.id, true),
				'Tax Amount': specialItems['Tax amount']?.(item.id, true),
				'Utility Amount': `₹${item?.finalTransactionAmount}`,
				Vendor: item?.vendorName,
				Store: item?.storeName,
				'Utility Bill Date': moment(item?.transactionDate)?.format(
					'DD-MM-YYYY'
				),
				'Utility Due Date': moment(item?.dueDate)?.format('DD-MM-YYYY'),
				'Utility Period From': moment(
					item?.extraData?.periodFrom,
					'M/D/YYYY'
				)?.format('DD-MM-YYYY'),
				'Utility Period To': moment(
					item?.extraData?.periodTo,
					'M/D/YYYY'
				)?.format('DD-MM-YYYY'),
				'Utility Category': item?.category,
				'Payment Term': item?.extraData?.paymentTerm,
				'Bill Number': item?.extraData?.billNumber,
				Status: item?.transactionStatus,
			},
			hideItems: true,
			excludedColumns: ['Used For'],
		});
	}

	const onExportClick = () => {
		const headers = TABLE_COLUMNS?.map((ele) => {
			return ele?.title;
		});
		headers.splice(5, 0, 'Rejection Reason');
		headers.splice(16, 1);
		headers.splice(16, 0, ...['IGST amount', 'CGST amount', 'SGST amount']);
		// for adding at last index
		headers.splice(999, 0, ...['store gst', 'vendor gst']);
		const totalValues = [];
		utilitiesList?.forEach((bill) => {
			if (bill?.transactionStatus !== 'CANCELLED') {
				totalValues?.push([
					bill?.id,
					bill?.vendorName,
					bill?.storeName,
					bill?.transactionStatus,
					(bill?.extraData?.isPaid ? 'Submitter' : 'Finance') ?? '-',
					bill?.extraData?.rejectionReason ?? '-',
					bill?.initiatedByUserDisplayName ?? '-',
					moment(bill?.createdAt).format('YYYY-MM-DD') ?? '-',
					bill?.clientTransactionId,
					bill?.transactionDate,
					bill?.dueDate,
					bill?.category,
					bill?.extraData?.periodFrom,
					bill?.extraData?.periodTo,
					bill?.l1ActionUserDisplayName,
					bill?.l2ActionUserDisplayName,
					specialItems['Amount Before tax']?.(bill.id, false),
					specialItems['Tax slab']?.(bill.id),
					!bill?.extraData?.isSameState
						? specialItems['Tax amount']?.(bill.id, false)
						: 0,
					bill?.extraData?.isSameState
						? (
								Number(
									specialItems['Tax amount']?.(bill.id, false)
								) / 2
						  ).toFixed(2)
						: 0,
					bill?.extraData?.isSameState
						? (
								Number(
									specialItems['Tax amount']?.(bill.id, false)
								) / 2
						  ).toFixed(2)
						: 0,
					bill?.finalTransactionAmount,
					bill?.uploadedDoc,
					storeIdMap?.[bill?.storeId]?.gstNumber ?? '-',
					vendorIdMap?.[bill?.vendorId]?.gstin ?? '-',
				]);
			}
		});
		handleExcelDownload(headers, totalValues, 'Utilities Bills');
	};
	const fetchPettyCashList = async (req) => {
		setIsLoading(true);
		const searchFilterPayload = {};
		searchFilters?.forEach((ele) => {
			if (ele?.value) {
				searchFilterPayload[ele?.id] = ele?.value;
			}
		});
		const filters = {
			subTypes: ['UTILITIES'],
			storeId: null,
			vendors: req?.vendors,
			status: req?.status,
			// dateRangeFilter: {
			// from: moment(req?.dateRange?.from).format('YYYY-MM-DD'),
			// to: moment(req?.dateRange?.to).format('YYYY-MM-DD'),
			// },
		};
		if (req?.dateRange?.active) {
			filters.dateRangeFilter = {
				timeType: req?.timeType,
				from: moment(req?.dateRange?.from).format('YYYY-MM-DD'),
				to: moment(req?.dateRange?.to).format('YYYY-MM-DD'),
			};
		}
		try {
			const response =
				await inventoryAnalyticsServices.fetchInvTransRecord({
					req: {
						data: {
							type: 'BILL',
							filters,
							...searchFilterPayload,
						},
					},
				});
			if (response?.statusCode === 200) {
				// setSelectedStore(store);
				setUtilitiesList(response?.data);
				// setLatestVersion(responseData?.data?.latestVersion);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log('Error', error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleAddClick = () => {
		const redirectUrl = `${urls.EXTENSION}${urls.UTILITIES_PAGE}/add`;
		history.push(redirectUrl);
	};

	const handleViewClick = async (item) => {
		try {
			const response = await inventoryAnalyticsServices.fetchInvTransDoc({
				req: {
					params: {
						transactionId: item?.id,
					},
				},
			});
			if (response?.statusCode === 200) {
				togglePOItemsModal(true, {
					heading: `Utility Bill #${
						item?.clientInvoiceId ?? item?.id
					}`,
					...item,
					isApprove: false,
					advanceBill: false,
					approvalText: 'I have checked the Utility Bill.',
					showTotal: false,
					showPdf: true,
					showPoDetails: true,
					width: '90%',
					height: '90%',
					previewFile: response?.data?.base64,
					transactionData: {
						'Client Utility ID': item?.clientTransactionId,
						'Utility Amount': item?.finalTransactionAmount,
						Vendor: item?.vendorName,
						Store: item?.storeName,
						'Utility Bill Date': moment(
							item?.transactionDate
						)?.format('DD-MM-YYYY'),
						'Utility Due Date': moment(item?.dueDate)?.format(
							'DD-MM-YYYY'
						),
						'Utility Period From': moment(
							item?.extraData?.periodFrom,
							'M/D/YYYY'
						)?.format('DD-MM-YYYY'),
						'Utility Period To': moment(
							item?.extraData?.periodTo,
							'M/D/YYYY'
						)?.format('DD-MM-YYYY'),
						'Utility Category': item?.category,
						'Payment Term': item?.extraData?.paymentTerm,
						'Bill Number': item?.extraData?.billNumber,
						Status: item?.transactionStatus,
					},
					hideItems: true,
					excludedColumns: ['Used For'],
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	const getRowStyles = (status) => {
		return styles?.[status];
	};

	return (
		<div className={styles.root}>
			<BackdropLoader isLoading={isLoading} showProgress />
			<POItemsModal
				handleConfirm={handleStatus}
				handleReject={(data, rejectionReason) => {
					handleStatus(data, rejectionReason, 'rejection');
				}}
			/>
			<ImagesFinder
				pageLabel='Utilities'
				FILTERS={['dateRange', 'vendor', 'timeType', 'status']}
				timeTypeArray={timeTypeArr}
				statusList={statusList}
				onSubmit={fetchPettyCashList}
				additionInfo={{
					showAdd: true,
					addText: 'Utility',
					handleAddClick,
				}}
				filterLabels={{
					dateRangeOnly: 'Bill Date Range',
				}}
				update={update}
			/>
			<div className={styles.list}>
				<ListTable
					colMap={COLMAP}
					columns={TABLE_COLUMNS}
					data={utilitiesList?.map((ele) => {
						return {
							...ele,
							rowStyles: getRowStyles(ele?.transactionStatus),
						};
					})}
					actions={[...SPECIAL_COLUMNS]}
					special={specialItems}
					handleViewClick={handleViewClick}
					onExportClick={onExportClick}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { navState, userPermissions } = state.navigation;
	const stores = state?.form?.STORES?.data?.LIST;
	const { LIST: vendorsList } = state.form.ALL_VENDORS.data;
	const { userList } = state?.auth;

	return {
		stores,
		vendorsList,
		navState,
		userPermissions,
		userList,
	};
};

const mapDispatchToProps = {
	togglePOItemsModal: modalActions.togglePOItemsModal,
	toggleAlert: modalActions.toggleAlert,
	fetchAllVendors: formActions.gateway,
	fetchStoresList: formActions.gateway,
	fetchUserList: authActions?.fetchUserList,
};
export default connect(mapStateToProps, mapDispatchToProps)(UtilitiesPage);
