/* eslint-disable*/
import { authHeader, handleResponse } from '@/Helpers';
import endpoints from '@/Constants/endpoints';

function fetchGrnQcLog(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			...authHeader(),
		},
        body: JSON.stringify(req?.data),
	};
	return fetch(endpoints.GRN_QC_LOG, requestOptions).then(handleResponse);
}

function fetchQcBatchId(req) {
    const requestOptions = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...authHeader(),
        },
        body: JSON.stringify(req?.data),
    };
    return fetch(endpoints.GRN_QC_LOG_BATCH_ID, requestOptions).then(handleResponse);
}
// fetch Ai supervisor Scans
function fetchAiSupervisorScans(req) {
    const requestOptions = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...authHeader(),
        },
        body: JSON.stringify(req?.data),
    };
    return fetch(endpoints.QUALITY_ANALYTICS_LIST, requestOptions).then(handleResponse);
}

function fetchQcBatchIdScans(req) {
    const requestOptions = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...authHeader(),
        },
        body: JSON.stringify(req?.data),
    };
    return fetch(endpoints.GRN_QC_LOG_BATCH_ID_SCANS, requestOptions).then(handleResponse);
}
function updateQcValueRecorded (req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			...authHeader(),
		},
        body: JSON.stringify(req?.data),
    };
    return fetch(endpoints.UPDATE_QC_VALUE_RECORDED, requestOptions).then(handleResponse);
}
export const grnQcLogService = {
	fetchGrnQcLog,
	fetchQcBatchId,
	fetchQcBatchIdScans,
    fetchAiSupervisorScans,
    updateQcValueRecorded,
};

