/* eslint-disable */
import { connect } from 'react-redux';
import {
	// useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import moment from 'moment';
import drawerActions from '../../../Actions/drawer.actions';
import BaseDrawer from '../BaseDrawer/BaseDrawer';
import { useAuth } from '@/Components/Auth/Login/auth';
import { Label } from '@/Components/ui/label';
import { UYDateRange } from '@/Components/Forms/Payment Settlements/Calender/UYDateRange';
import {
	Tabs, TabsList, TabsTrigger
} from '@/Components/ui/tabs';
import inventoryAnalyticsServices from '@/Services/Analytics Services/inventory-analytics.services';
import ScanWiseTable from './ScanWiseTable';
import DayWiseTable from './DayWiseTable';
import { BackdropLoader } from '@/Components/Loaders';
import ProcessDayWiseTable from './ProcessDayWiseTable';
import ProcessScanWiseTable from './ProcessScanWiseTable';

const StockHistoryDrawer = (props) => {
	// eslint-disable-next-line no-unused-vars
	const { show, data, toggleDrawer, stores } = props;
	const { selectedStore = null } = data;
	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs ?? {};
	const [loading, setLoading] = useState(false);

	const [skuTrail, setSkuTrail] = useState([]);
	// eslint-disable-next-line no-unused-vars
	const [dataType, setDataType] = useState('scan_wise');
	const [dateRange, setDateRange] = useState({
		from: new Date(),
		to: new Date(),
		active: false,
	});



	const onClose = () => {
		toggleDrawer(false, {});
	};
	const fetchScanWiseTrail = async (filters) => {
		setLoading(true);
		try {
			const response = await inventoryAnalyticsServices.scanWiseSkuTrail({
				data: {
					variantId: data?.itemDetails?.variantId,
					...(data?.type === 'PROCESS_REPORT' && {
						processId: data?.processId,
					}),
					...(data?.type !== 'PROCESS_REPORT' && {
						storeId: data?.itemDetails?.storeId,
					}),
					dateRangeFilter: {
						from: moment(filters?.dateRange?.from).format(
							'YYYY-MM-DD'
						),
						to: moment(filters?.dateRange?.to).format('YYYY-MM-DD'),
					},
				},
			}, data?.type === 'PROCESS_REPORT' ? 'PROCESS_REPORT' : null);
			if (response?.responseCode === 'SS-001') {
				setSkuTrail(response?.data);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log('erorrr', error);
		} finally {
			setLoading(false);
		}
	};
	const fetchDayWiseTrail = async (filters) => {
		setLoading(true);
		try {
			const response = await inventoryAnalyticsServices.dayWiseSkuTrail({
				data: {
					...(!data?.allVariants && data?.itemDetails?.variantId && {
						variantId: data?.itemDetails?.variantId,
					}),
					...(data?.type === 'PROCESS_REPORT' && {
						processId: data?.processId,
					}),
					...(data?.type !== 'PROCESS_REPORT' && {
						storeId: data?.itemDetails?.storeId,
					}),
					dateRangeFilter: {
						from: moment(filters?.dateRange?.from).format(
							'YYYY-MM-DD'
						),
						to: moment(filters?.dateRange?.to).format('YYYY-MM-DD'),
					},
				},
			}, data?.type === 'PROCESS_REPORT' ? 'PROCESS_REPORT' : null);
			if (response?.responseCode === 'SS-001') {
				setSkuTrail(response?.data);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log('erorrr', error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		setSkuTrail([]);
		setDataType('scan_wise');
	}, [show]);

	useEffect(() => {
		let updatedAt = new Date();
		if (data?.itemDetails?.lastUpdatedAt) {
			updatedAt = new Date(data?.itemDetails?.lastUpdatedAt);
		}
		if (data?.allVariants && data?.type === 'PROCESS_REPORT') {
			const dateRangeTemp = {
				from: moment(data?.lastUpdatedAt).startOf('day').subtract(10, 'days').toDate(),
				to: moment(data?.lastUpdatedAt).startOf('day').toDate(),
			};
			setDateRange({
				...dateRangeTemp,
				active: true,
			});
			// if (dataType === 'scan_wise') {
			// 	fetchScanWiseTrail({
			// 		dateRange: dateRangeTemp,
			// 		processId: data?.processId,
			// 	});
			// }
			// if (dataType === 'day_wise') {
			// 	fetchDayWiseTrail({
			// 		dateRange: dateRangeTemp,
			// 		processId: data?.processId,
			// 	});
			// }
			return;
		}
		if (show && data?.itemDetails?.skuType === 'PRODUCT') {
			setDateRange({
				from: moment(updatedAt).startOf('day').subtract(6, 'days').toDate(),
				to: moment(updatedAt).startOf('day').toDate(),
				active: true,
			});
			// if (dataType === 'scan_wise') {
			// 	fetchScanWiseTrail({
			// 		dateRange: {
			// 			from: moment(updatedAt)
			// 				.startOf('day')
			// 				.subtract(6, 'days')
			// 				.toDate(),
			// 			to: moment(updatedAt).startOf('day').toDate(),
			// 		},
			// 	});
			// }
			// if (dataType === 'day_wise') {
			// 	fetchDayWiseTrail({
			// 		dateRange: {
			// 			from: moment(updatedAt)
			// 				.startOf('day')
			// 				.subtract(6, 'days')
			// 				.toDate(),
			// 			to: moment(updatedAt).startOf('day').toDate(),
			// 		},
			// 	});
			// }
		} else if (show && ['INGREDIENT', 'PACKAGING', 'OTHER']?.includes(data?.itemDetails?.skuType)) {
			setDateRange({
				from: moment(updatedAt)
					.startOf('day')
					.subtract(1, 'month')
					.toDate(),
				to: moment(updatedAt).startOf('day').toDate(),
				active: true,
			});
			// if (dataType === 'scan_wise') {
			// 	fetchScanWiseTrail({
			// 		dateRange: {
			// 			from: moment(updatedAt)
			// 				.startOf('day')
			// 				.subtract(6, 'days')
			// 				.toDate(),
			// 			to: moment(updatedAt).startOf('day').toDate(),
			// 		},
			// 	});
			// }
			// if (dataType === 'day_wise') {
			// 	fetchDayWiseTrail({
			// 		dateRange: {
			// 			from: moment(updatedAt)
			// 				.startOf('day')
			// 				.subtract(6, 'days')
			// 				.toDate(),
			// 			to: moment(updatedAt).startOf('day').toDate(),
			// 		},
			// 	});
			// }
		}
	}, [show, data?.itemDetails?.skuType]);

	const dateRangeFilterArray = useMemo(() => {
		return [
			{
				startDate: dateRange.from,
				endDate: dateRange.to,
				key: 'selection',
			},
		];
	}, [dateRange]);

	const setDate = (newDate) => {
		const { startDate: from, endDate: to } = newDate?.selection ?? {};

		setDateRange({
			active: true,
			from,
			to,
		});
		if (dataType === 'scan_wise') {
			fetchScanWiseTrail({
				dateRange: {
					from,
					to,
				},
			});
		}
		if (dataType === 'day_wise') {
			fetchDayWiseTrail({
				dateRange: {
					from,
					to,
				},
			});
		}
	};
	return (
		<BaseDrawer
			style={{
				width: '80%',
			}}
			header={data?.heading}
			open={show}
			onClose={onClose}
			size='full'
		>
			<BackdropLoader isLoading={loading} showProgress />
			<div className='w-full flex justify-between items-center px-2 py-4'>
				<div className='flex flex-col gap-1'>
					<Tabs
						value={dataType}
						onValueChange={(value) => {
							setDataType(value);
							setSkuTrail([]);
							if (value === 'scan_wise') {
								fetchScanWiseTrail({
									dateRange: {
										from: dateRange?.from,
										to: dateRange?.to,
									},
								});
							}
							if (value === 'day_wise') {
								fetchDayWiseTrail({
									dateRange: {
										from: dateRange?.from,
										to: dateRange?.to,
									},
								});
							}
						}}
						defaultValue={dataType}
					>
						<TabsList className=''>
							<TabsTrigger
								className='data-[state=active]:bg-uy-color'
								value='scan_wise'
							>
								Scan-Wise
							</TabsTrigger>
							<TabsTrigger
								className='data-[state=active]:bg-uy-color'
								value='day_wise'
							>
								Day-Wise
							</TabsTrigger>
						</TabsList>
					</Tabs>
				</div>
				{
					selectedStore?.title && (
						<div className='text-lg font-bold tracking-wide text-gray-800 bg-gray-100 px-4 py-2 rounded-lg shadow-sm'>
							{selectedStore?.title}
						</div>
					)
				}
				<div className='flex gap-2'>
					<div className='flex flex-col gap-1'>
						<Label>Select Stock In Range</Label>
						<UYDateRange
							disabled={false}
							dateRanges={dateRangeFilterArray}
							onDateRangeChange={setDate}
							enableOnlyBackwardSelection
							MAX={32}
						/>
					</div>
				</div>
			</div>
			<div className='w-full overflow-auto !text-xs'>
				{
					data?.type === 'PROCESS_REPORT' ? (
						<>
							{dataType === 'scan_wise' && (
								<ProcessScanWiseTable
									allVariants={data?.allVariants}
									itemDetails={data?.itemDetails}
									data={skuTrail}
									measurement={measurement}
								/>
							)}
							{dataType === 'day_wise' && (
								<ProcessDayWiseTable
									allVariants={data?.allVariants}
									itemDetails={data?.itemDetails}
									data={skuTrail}
									measurement={measurement}
								/>
							)}
						</>
					) : (
						<>
							{dataType === 'scan_wise' && (
								<ScanWiseTable
									itemDetails={data?.itemDetails}
									data={skuTrail}
									measurement={measurement}
								/>
							)}
							{dataType === 'day_wise' && (
								<DayWiseTable
									itemDetails={data?.itemDetails}
									data={skuTrail}
									measurement={measurement}
								/>
							)}
						</>
					)
				}
			</div>
		</BaseDrawer>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state?.drawer?.stockHistoryDrawer;
	const stores = state?.form?.STORES?.data?.LIST;
	return {
		show,
		data,
		stores,
	};
};

const mapDispatchToProps = {
	toggleDrawer: drawerActions.toggleStockHistoryDrawer,
};

export default connect(mapStateToProps, mapDispatchToProps)(StockHistoryDrawer);
