const config = {
	initialState: {
		store: -999,
		fromPod: null,
		toPod: -999,
		processType: null,
		group: null,
		region: null,
		productExclusive: null,
		brand: -999,
		raisedBy: null,
		status: [],
		products: [],
		vendors: [],
		defects: [],
		imagesType: null,
		productCategories: [],
		identificationType: 'AI',
		timeType: 'annotation',
		featureType: 'QUALITY',
		page: 6,
		dateRange: {
			active: true,
			from: new Date(),
			to: new Date(),
		},
		dateOnly: {
			active: false,
			date: new Date(),
		},
		conf: {
			minConf: 0.5,
			maxConf: 1,
		},
		customExclusiveFilters: {},
	},
	deserializer: (
		filters,
		INCLUDED_FILTERS,
		isInternal,
		DEFAULT_FILTERS_VALUE = []
	) => {
		const req = {};
		if (INCLUDED_FILTERS?.includes('fromPodFilter') || DEFAULT_FILTERS_VALUE?.includes('fromPodFilter')) {
			req.fromPodFilter = filters?.fromPod;
		}
		if (INCLUDED_FILTERS?.includes('toPodFilter') || DEFAULT_FILTERS_VALUE?.includes('fromPodFilter')) {
			req.toPodFilter = filters?.toPod;
		}
		if (
			INCLUDED_FILTERS?.includes('store') ||
			DEFAULT_FILTERS_VALUE?.includes('store') ||
			DEFAULT_FILTERS_VALUE?.includes('filteredStore') ||
			INCLUDED_FILTERS?.includes('filteredStore')
		) {
			req.store = filters?.store;
		}
		if (
			INCLUDED_FILTERS?.includes('group') ||
			DEFAULT_FILTERS_VALUE?.includes('group')
		) {
			req.group = filters?.group;
		}
		if (
			INCLUDED_FILTERS?.includes('regions') ||
			DEFAULT_FILTERS_VALUE?.includes('regions')
		) {
			req.region = filters?.region;
		}
		if (
			INCLUDED_FILTERS?.includes('productExclusive') ||
			DEFAULT_FILTERS_VALUE?.includes('productExclusive')
		) {
			req.productExclusive = filters?.productExclusive;
		}
		if (
			INCLUDED_FILTERS?.includes('brand') ||
			DEFAULT_FILTERS_VALUE?.includes('brand')
		) {
			req.brand = filters?.brand;
		}
		if (
			INCLUDED_FILTERS?.includes('raisedBy') ||
			DEFAULT_FILTERS_VALUE?.includes('raisedBy')
		) {
			req.raisedBy = filters?.raisedBy;
		}
		if (
			INCLUDED_FILTERS?.includes('status') ||
			DEFAULT_FILTERS_VALUE?.includes('status')
		) {
			req.status = filters?.status;
		}
		if (
			INCLUDED_FILTERS?.includes('vendor') ||
			DEFAULT_FILTERS_VALUE?.includes('vendor')
		) {
			req.vendors = filters?.vendors;
		}
		if (
			INCLUDED_FILTERS?.includes('buyer') ||
			DEFAULT_FILTERS_VALUE?.includes('buyer')
		) {
			req.buyer = filters?.vendors;
		}
		if (
			INCLUDED_FILTERS?.includes('product') ||
			DEFAULT_FILTERS_VALUE?.includes('product')
		) {
			req.products = filters?.products;
		}
		if (
			INCLUDED_FILTERS?.includes('imagesType') ||
			DEFAULT_FILTERS_VALUE?.includes('imagesType')
		) {
			req.imagesType = filters?.imagesType;
		}
		if (
			INCLUDED_FILTERS?.includes('productCategory') ||
			DEFAULT_FILTERS_VALUE?.includes('productCategory')
		) {
			req.productCategories = filters?.productCategories;
		}
		if (
			INCLUDED_FILTERS?.includes('identificationType') ||
			DEFAULT_FILTERS_VALUE?.includes('identificationType')
		) {
			req.identification = filters?.identificationType;
		}
		if (
			(isInternal && INCLUDED_FILTERS?.includes('confidence')) ||
			DEFAULT_FILTERS_VALUE?.includes('confidence')
		) {
			req.conf = filters?.conf;
		}
		if (
			(isInternal && INCLUDED_FILTERS?.includes('timeType')) ||
			DEFAULT_FILTERS_VALUE?.includes('timeType')
		) {
			req.timeType = filters?.timeType;
		}
		if (
			INCLUDED_FILTERS?.includes('egaPercentage') ||
			DEFAULT_FILTERS_VALUE?.includes('egaPercentage')
		) {
			req.egaPercentage = filters?.egaPercentage;
		}
		if (
			INCLUDED_FILTERS?.includes('pageLimit') ||
			DEFAULT_FILTERS_VALUE?.includes('pageLimit')
		) {
			req.page = filters?.pageLimit;
		}
		if (
			INCLUDED_FILTERS?.includes('dateRange') ||
			DEFAULT_FILTERS_VALUE?.includes('dateRange')
		) {
			req.dateRange = filters?.dateRange;
		}
		if (
			INCLUDED_FILTERS?.includes('dateRangeWithType') ||
			DEFAULT_FILTERS_VALUE?.includes('dateRangeWithType')
		) {
			req.dateRange = filters?.dateRange;
		}
		if (
			INCLUDED_FILTERS?.includes('dateOnly') ||
			DEFAULT_FILTERS_VALUE?.includes('dateOnly')
		) {
			req.dateOnly = filters?.dateOnly;
		}
		if (
			INCLUDED_FILTERS?.includes('features') ||
			DEFAULT_FILTERS_VALUE?.includes('features')
		) {
			req.labels = filters?.defects;
		}
		if (
			INCLUDED_FILTERS?.includes('featureType') ||
			DEFAULT_FILTERS_VALUE?.includes('featureType')
		) {
			req.featureType = filters?.featureType;
		}
		if (
			INCLUDED_FILTERS?.includes('processTypes') ||
			DEFAULT_FILTERS_VALUE?.includes('processTypes')
		) {
			req.processTypes =
				filters?.processTypes !== null ? [filters?.processTypes] : null;
		}
		if (
			INCLUDED_FILTERS?.includes('dateRangeOnly') ||
			DEFAULT_FILTERS_VALUE?.includes('dateRangeOnly')
		) {
			req.dateRange = filters?.dateRange;
		}
		if (
			INCLUDED_FILTERS?.includes('customExclusiveFilters') ||
			DEFAULT_FILTERS_VALUE?.includes('customExclusiveFilters')
		) {
			req.customExclusiveFilters = filters?.customExclusiveFilters;
		}
		if (
			INCLUDED_FILTERS?.includes('status') ||
			DEFAULT_FILTERS_VALUE?.includes('status')
		) {
			if (filters.status === 'ALL') {
				req.status = null;
			} else {
				req.status = filters?.status;
			}
		}
		return req;
	},
};

export default config;
