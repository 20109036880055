/* eslint-disable */
/* eslint-disable no-tabs */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import Chip from '@mui/material/Chip';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import moment from 'moment';
import { useHistory } from 'react-router';
import { EditIcon } from 'lucide-react';
import SelectPicker from 'rsuite/SelectPicker';
import { analyticsAction, formActions, modalActions } from '../../../Actions';
import { ListTable } from '../../../Components/ListTable';
import { POItemsModal } from '../../../Components/Modals/POItemsModal';
import styles from './VendorOrdersListPage.module.scss';
import { VanImageModal } from '../../../Components/Modals/VanImageModal';
import assetServices from '../../../Services/AssetServices/asset.services';
import { notificationServices } from '../../../Services';
import inventoryAnalyticsServices from '../../../Services/Analytics Services/inventory-analytics.services';
import { AlertModal } from '../../../Components/Modals';
import ListFinderHeader from '../../../Components/ListFinderHeader/ListFinderHeader';
import { VendorOrderGrnsModal } from '../../../Components/Modals/VendorOrderGrns';
import { FileUploaderModal } from '../../../Components/Modals/FileUploaderModal';
import ImagesFinder from '../../../Components/ImagesFinder/ImagesFinder';
import {
	addCommas,
	classes, handleOrderStatus, handleResponseStreamDownload,
	useSearch
} from '../../../Helpers';
import { urls } from '../../../Constants';
import ExportFiltersDrawer from '@/Components/Drawers/ExportFiltersDrawer/ExportFiltersDrawer';
import drawerActions from '@/Actions/drawer.actions';
import orderAnalyticsServices from '@/Services/Analytics Services/orderAnalytics.services';
import DebitCreditListDrawer from '@/Components/Drawers/DebitCreditListDrawer/DebitCreditListDrawer';
import { Button as SButton } from '@/Components/ui/button';
import { Label } from '@/Components/ui/label';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/Components/ui/select';
import AssetTransitModal from '@/Components/Modals/AssetTransitModal/AssetTransitModal';
import { useAuth } from '@/Components/Auth/Login/auth';
import userRolesAndPermissions from '@/Services/userRolesAndPermissions.services';
import authActions from '@/Actions/auth.actions';
import DatePickerModal from '../Inventory Analytics/PurchaseOrderList/DatePickerModal';
import { BackdropLoader } from '@/Components/Loaders';
import SearchBar from '@/Components/SearchBar/SearchBar';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
// import { Button as CnButton } from '@/Components/ui/button';

const TABLE_COLUMNS = [
	{
		title: 'PO Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Client PO Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Dispatch By',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Vendor',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Buyer',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Deliver From',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Deliver To',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Order Status',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Vendor Challan ID',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Buyer Challan ID',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'CREATED BY',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Last Action BY',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [
	{
		title: 'Order Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Expected Receive date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Order Amount',
		config: {
			type: 'DATA',
		},
	},
];

const ACTION_COLUMNS = [
	{
		title: 'Download',
		config: {
			type: 'VIEW',
		},
	},
	{
		title: 'View More',
		config: {
			type: 'VIEW',
		},
	},
	{
		title: 'Actions',
		config: {
			type: 'CANCEL',
		},
	},
	{
		title: 'MORE',
		config: {
			type: 'MORE',
		},
	},
];

const COLMAP = {
	'PO Id': 'id',
	'Client PO Id': 'clientPoId',
	'Order Date': 'createdAt',
	'Dispatch By': 'scheduledAt',
	'Delivery Time': 'deliveryTime',
	'Deliver From': 'fromStoreName',
	'Deliver To': 'storeName',
	BASE: 'fromStoreName',
	'Expected Receive date': 'id',
	'Van Temp': 'receivingTemp',
	'Transit Van Image': 'receivingTempImage',
	'Order Status': 'id',
	'Vendor Challan ID': 'vendorChallanId',
	Vendor: 'vendorName',
	Buyer: 'vendorName',
	'Buyer Challan ID': 'buyerChallanId',
	'CREATED BY': 'initiatedByName',
	'Last Action BY': 'l1ActionByName',
};

const filterOrderStatus = [
	'CANCELLED',
	'PENDING',
	'FULFILLED',
	'APPROVAL_PENDING',
	'APPROVAL_REJECTED',
	'ABANDONED',
	'DISPATCH',
	'TRANSIT',
	'UNLOADING',
	'VERIFICATION',
];
const vendorOrderStatus = [
	'CANCELLED',
	'PENDING',
	'FULFILLED',
	'APPROVAL_PENDING',
	'APPROVAL_REJECTED',
	'ABANDONED',
];

const FILTERS = ['fulfilledBy', 'raisedBy', 'vendor', 'status', 'dateRange'];

const dateTypeArr = [
	{
		label: 'Order Date',
		value: 'ordered',
	},
	{
		label: 'Scheduled For',
		value: 'scheduled',
	},
	{
		label: 'GRN Date',
		value: 'grn',
	},
	{
		label: 'Invoice Date',
		value: 'invoiced',
	},
	{
		label: 'Receive Date',
		value: 'received',
	},
];

const FIND_FILTERS = [
	'vendor',
	'buyer',
	'status',
	'dateRangeWithType',
	'timeType',
	'store',
];

const vendorOrdersExportTypes = [
	{
		label: 'Invoice-Wise Purchase Register',
		value: 'invoiceWise',
	},
	{
		label: 'Item-Wise Purchase Register',
		value: 'grnItemWise',
	},
	{
		label: 'Item-Wise PO Fulfillment',
		value: 'poItemWise',
	},
];
const internalOrdersExportTypes = [
	{
		label: 'Invoice-Item wise Sales Register',
		value: 'invoiceItemWiseSales',
	},
	{
		label: 'Item-Wise GRN Fullfilment',
		value: 'itemWiseGrn',
	},
	{
		label: 'Item-Wise Sales Register',
		value: 'itemWiseSales',
	},
];

function VendorOrdersListPage(props) {
	const {
		data,
		type,
		toggleModal,
		// toggleFileUploaderModal,
		toggleAlert,
		fetchOrdersList,
		toggleVendorOrderGrnsModal,
		dateRangeFilter,
		navState,
		userPermissions,
		toggleVanImageModal,
		vendors,
		fetchVendors,
		resetInventoryAnalyticsState,
		toggleExportFiltersDrawer,
		categoriesList,
		subCategoriesList,
		fetchSubCategories,
		toggleDebitCreditListDrawer,
		toggleAssetTransit,
		userList,
		fetchUserList,
	} = props;
	const isInternalOrders = type === 'internalPoList';
	const isBuyer = type === 'BUYER';
	const searchQuery = '';
	const searchBy = 'clientPoId';
	const history = useHistory();
	const location = useLocation();
	const [update, setUpdate] = useState(-1);
	const [grnStatus, setGrnStatus] = useState('ALL');
	const [loading, setLoading] = useState(false);
	const exportFilters = useRef({});
	const [datePickerModalState, setDatePickerModalState] = useState({
		show: false,
		heading: '',
		currentPo: null,
	});
	const [filters, setFilters] = useState({
		storeId: null,
		dateRange: {
			startDate: new Date(),
			endDate: new Date(),
		},
	});
	const SEARCH_BY = {
		options: [
			{
				key: 'clientPoId',
				title: 'Client Po Id',
			},
			{
				key: 'APPROVAL_PENDING',
				title: 'Approval Pending',
			},
		],
	};
	const { userConfig } = useAuth();
	const directLevel = userConfig?.userDetails?.level;
	// console.log(userConfig, 'userConfig');
	// useEffect(() => {
	// 	fetchUserList();
	// }, [])
	// // function page role of user access
	// function pageRoleMap(pageName) {
	// 	switch (pageName) {
	// 		case 'internalPoList':
	// 			return navState['/analytics/internal/po']?.submenu['/analytics/internal/po/list']?.rolesMapped;
	// 		case 'BUYER':
	// 			return navState['/analytics/external']?.submenu['/analytics/list/buyer/orders']?.rolesMapped;
	// 		default:
	// 			return navState['/analytics/po']?.submenu['/analytics/list/vendor/orders']?.rolesMapped;
	// 	}
	// }

	const map = new Map(userList.map((item) => [item.value, item.label]));
	const role = Object.keys(userConfig?.userRoles);
	const userRoles = [];
	role.forEach((ele) => {
		userRoles.push(ele);
	});
	const pageRole =
		navState['/analytics/internal/po']?.submenu[
			'/analytics/internal/po/list'
		]?.rolesMapped;
	const findMatchingRole = userRoles.filter((ele) =>
		pageRole.includes(Number(ele))
	);
	const roleDetails = findMatchingRole.map((role) => {
		const key = Number(role);
		const name = map.get(key);
		return { role: key, name };
	});

	const isInternal = roleDetails.some(
		(item) => item.name === 'Internal' || item.name === 'CXO Office'
	);
	// console.log(roleDetails, 'roleDetails');
	// const vendorApproval =  roleDetails.some((ele) =>  ele?.name === 'Procurement');
	// const name = roleDetails.some((ele) => ele.name === 'CXO Office');
	const access =
		findMatchingRole.length > 0 ? userConfig?.userDetails?.level : false;
	let permit;
	if (directLevel == 3 || directLevel == 4 || isInternal) {
		permit = true;
	} else {
		permit = false;
		// permit = isInternal || (access === 3 || access === 4);
	}
	// if (access === false) {
	// 	permit = false;
	// } else {
	// 	permit = isInternal && (access === 3 || access === 4);
	// }
	// end user Acces

	const navRequisites =
		navState?.[
			isInternalOrders
				? urls.INTERNAL_PURCHASE_ORDERS
				: urls.PURCHASE_ORDERS
		]?.requisites ?? [];
	const navSelectedStore = navRequisites?.find((ele) => {
		return ele?.type === 'store';
	});

	useEffect(() => {
		exportFilters.current = {
			...exportFilters.current,
			storeId:
				navSelectedStore?.value?.id === -999
					? null
					: navSelectedStore?.value?.id,
		};
	}, [navSelectedStore?.value?.id]);

	useEffect(() => {
		fetchVendors({
			entity: 'ALL_VENDORS',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		return () => {
			resetInventoryAnalyticsState({
				entity: 'INVENTORY_ANALYTICS',
				method: 'VENDOR_ORDERS_LIST',
				data: [],
			});
		};
	}, []);

	const handleCancelPo = (id, clientPoId) => {
		// eslint-disable-next-line no-console
		(async () => {
			const notificationId = notificationServices.generateNotification({
				type: 'loading',
				message: `Cancelling Po ${clientPoId || ''}`,
			});
			try {
				const resp = await inventoryAnalyticsServices.changeOrderStatus(
					{
						params: {
							poId: id,
							status: 'CANCEL',
						},
					}
				);
				notificationServices.generateNotification({
					type: 'success',
					message: resp?.status,
					config: {
						id: notificationId,
					},
				});
				setUpdate((prev) => {
					return prev + 1;
				});
			} catch (error) {
				notificationServices.generateNotification({
					type: 'error',
					message: error,
					config: {
						id: notificationId,
					},
				});
			}
		})();
	};

	// const handleDownloadGRN = (downloadLink) => {
	// window.open(downloadLink);
	// };

	const handleViewClick = (item) => {
		const vendorDetails = vendors?.find((ele) => {
			return ele?.id === item?.vendorId;
		});
		const buyersObj = {
			'Deliver From Store': item?.fromStoreName,
			'Deliver From Store GSTIN': item?.fromStoreGstin ?? '',
			Buyer: item?.vendorName,
			'Buyer GSTIN': vendorDetails?.gstin ?? '',
			'Buyer Address': `${vendorDetails?.shippingAddressCity ?? ''}${
				vendorDetails?.shippingAddressState ?? ''
			}, ${vendorDetails?.shippingAddressPinCode ?? ''}`,
		};
		const vendorsObj = {
			'Deliver To Store': item?.storeName,
			'Deliver To Store GSTIN': item?.storeGstin ?? '',
			Vendor: item?.vendorName,
			'Vendor GSTIN': vendorDetails?.gstin ?? '',
			'Vendor Address': `${vendorDetails?.shippingAddressCity ?? ''}${
				vendorDetails?.shippingAddressState ?? ''
			}, ${vendorDetails?.shippingAddressPinCode ?? ''}`,
		};
		const internalObj = {
			'Deliver From Store': item?.fromStoreName,
			'Deliver To Store': item?.storeName,
			'Deliver To Store GSTIN': item?.storeGstin ?? '',
			'Deliver From Store GSTIN': item?.fromStoreGstin ?? '',
			'from Store Address': `${vendorDetails?.shippingAddressCity ?? ''}${
				vendorDetails?.shippingAddressState ?? ''
			}, ${vendorDetails?.shippingAddressPinCode ?? ''}`,
		};
		toggleModal(true, {
			heading: `PO Items ${
				item?.clientPoId ? `of ${item?.clientPoId}` : ''
			}`,
			...item,
			showPdf: item?.poURL,
			showTotal: true,
			showPoDetails: true,
			width: '80%',
			height: '95%',
			transactionData: {
				...(isBuyer && buyersObj),
				...(!isBuyer && !isInternalOrders && vendorsObj),
				...(isInternalOrders && internalObj),
				'Order ID': item?.clientPoId,
				'Payment Term': item?.jsonData?.paymentTerm,
				'Order Date': moment(item?.createdAt).format('YYYY-MM-DD'),
				'Dispatch By': moment(item?.scheduledAt).format('YYYY-MM-DD'),
			},
			excludedColumns: isInternalOrders
				? [
						// 'Packaged',
						'From Process',
						'PO Id',
						'Quantity',
						'Returned Quantity',
						'Returned',
						'Fulfilled',
				  ]
				: [
						'Packaged',
						'Dispatched',
						'Returned Quantity',
						'Received',
						'From Process',
						'PO Id',
						'Quantity',
				  ],
		});
	};

	const handleExcelDownload = useCallback((header, fileData, fileName) => {
		const commonColumns = [
			'PO ID',
			'Client PO ID',
			'PO Scheduled Date',
			'PO Order Date',
			'PO Order status',
			'PO Raised by',
			'Vendor',
		];
		const downloadBtn = document.createElement('a');
		const csvData = [[...commonColumns, ...header], ...fileData];

		const csv = `${csvData
			.map((e) => {
				return e.join(',');
			})
			.join('\n ')}`;

		const encodedValue = new Blob([csv], {
			type: 'text/csv',
		});
		downloadBtn.href = window.URL.createObjectURL(encodedValue);
		downloadBtn.target = '_blank';
		downloadBtn.download = fileName;
		downloadBtn.click();
	}, []);

	const fetchPOGrns = async (orderId) => {
		const req = {
			data: {
				filters: {
					orderId,
				},
				type: 'GRN',
				transactionItems: true,
				children: true,
			},
		};
		const notificationId = notificationServices.generateNotification({
			type: 'loading',
			message: 'loading...',
		});
		try {
			const response =
				await inventoryAnalyticsServices.fetchInvTransRecord({
					req,
				});
			// eslint-disable-next-line no-empty
			if (response?.statusCode === 200) {
				toggleVendorOrderGrnsModal(true, {
					heading: `GRNs of PO: #${orderId}`,
					grnList: response?.data,
				});
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
					config: {
						id: notificationId,
					},
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
			notificationServices.generateNotification({
				type: 'error',
				message: error?.message,
				config: {
					id: notificationId,
				},
			});
		}
	};

	const showPOBills = (orderId, clientPoId) => {
		history.push(
			`${urls.EXTENSION}${urls.BILLS}?poId=${orderId}&clientPoId=${clientPoId}`
		);
	};

	const viewMoreOptionsList = {
		'View Items': (item, cb) => {
			handleViewClick(item);
			cb();
		},
		...(!isBuyer && {
			'View GRNs': (item, cb) => {
				fetchPOGrns(item?.id);
				cb();
			},
		}),
		...(!isBuyer && {
			'View Bills': (item, cb) => {
				showPOBills(item?.id, item?.clientPoId);
				cb();
			},
		}),
		// 'View Credits': (item, cb) => {
		// 	history.push(
		// 		`${urls.EXTENSION}${urls.CREDITS}?poId=${item?.id}&clientPoId=${item?.clientPoId}`
		// 	);
		// 	cb();
		// },
		...(!isBuyer && {
			'View Debit/Credit Note': (item, cb) => {
				cb();
				setTimeout(() => {
					toggleDebitCreditListDrawer(true, {
						poId: item?.id,
						heading: `Debit/Credit Notes of ${
							item?.clientPoId ?? item?.id
						}`,
					});
				}, 100);
			},
		}),
	};

	const internalViewMoreOptions = {
		'View Items': (item, cb) => {
			handleViewClick(item);
			cb();
		},
		'View Debit/Credit Note': (item, cb) => {
			cb();
			setTimeout(() => {
				toggleDebitCreditListDrawer(true, {
					poId: item?.id,
					heading: `Debit/Credit Notes of ${
						item?.clientPoId ?? item?.id
					}`,
				});
			}, 100);
		},
		'View Assets': async (item, cb) => {
			try {
				const res = await assetServices?.fetchAssetTransitFlow({
					req: {
						params: {
							id: Number(item?.id),
						},
					},
				});
				if (res?.statusCode === 200) {
					const response = res?.data;
					if (response.length > 0) {
						toggleAssetTransit(true, {
							heading: `PoId : ${item?.id}`,
							data: response,
						});
					} else {
						notificationServices?.generateNotification({
							type: 'warning',
							message: 'No assets are packaged yet in this PO',
						});
					}
				}
			} catch (error) {
				notificationServices.generateNotification({
					type: 'error',
					message: error,
				});
			}
		},
	};
	const vendorOrder = (data) => {
		const status = data.map((ele) => {
			if (ele === 'FULFILLED') {
				return {
					id: ele,
					title: 'CLOSED',
				};
			}
			return {
				id: ele,
				title: ele,
			};
		});
		return status;
	};
	const renderDownloadField = (item) => {
		// const sendStoreGstin = item?.fromStoreGstin;
		// const receiveStoreGstin = item?.storeGstin;

		return (
			<div
				style={{
					width: '125px',
				}}
				className={styles?.downloadDiv}
			>
				{item?.poURL && (
					<Chip
						size='small'
						label='PO'
						onClick={() => {
							if (item?.poURL) {
								window.open(item?.poURL);
							}
						}}
						icon={<PictureAsPdfIcon />}
					/>
				)}
				<br />
				{item?.deliveryChallanURL && (
					<Chip
						size='small'
						label='DC'
						onClick={() => {
							if (item?.deliveryChallanURL) {
								window.open(item?.deliveryChallanURL);
							}
						}}
						icon={<PictureAsPdfIcon />}
					/>
				)}
				<br />
				{item?.invoiceURL && item?.jsonData?.ewayBill?.url && (
					<Chip
						size='small'
						label='Invoice'
						onClick={() => {
							if (item?.invoiceURL) {
								window.open(item?.invoiceURL);
							}
						}}
						icon={<PictureAsPdfIcon />}
					/>
				)}
				{item?.grnURL && (
					<>
						<Chip
							size='small'
							label='GRN'
							onClick={() => {
								if (item?.grnURL) {
									window.open(item?.grnURL);
								}
							}}
							icon={<PictureAsPdfIcon />}
						/>
					</>
				)}
				{item?.jsonData?.ewayBill?.url ? (
					<>
						<div
							style={{
								display: 'flex',
								position: 'relative',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<Chip
								size='small'
								label='Eway Bill'
								onClick={() => {
									if (item?.jsonData?.ewayBill?.url) {
										const anchor =
											document.createElement('a');
										anchor.href = `https://${item?.jsonData?.ewayBill?.url}`;
										anchor.download = 'EwayBill.pdf';
										anchor.target = 'blank';
										anchor.click();
									}
								}}
								icon={<PictureAsPdfIcon />}
							/>
						</div>
					</>
				) : (
					item?.orderStatus === 'TRANSIT' && (
						<span
							style={{
								cursor: 'pointer',
								color: '#1976d2',
								textDecoration: 'underline',
								fontSize: '12px',
								display: 'inline-block',
								marginLeft: '8px',
								whiteSpace: 'nowrap',
							}}
							onClick={() => {
								const urlType = 'EWAYBILL';
								const url = `${urls.EXTENSION}${urls.CREATE_EWAY_BILL}?poId=${item?.id}&type=${urlType}`;
								history.push(url);
							}}
						>
							Create E-way Bill
						</span>
					)
				)}
				{item?.jsonData?.eInvoice?.eInvoiceUrl ? (
					<>
						{item?.jsonData?.ewayBill?.url ? (
							<Chip
								size='small'
								label='E-Invoice'
								onClick={() => {
									if (item?.jsonData?.eInvoice?.eInvoiceUrl) {
										const anchor =
											document.createElement('a');
										anchor.href =
											item?.jsonData?.eInvoice?.eInvoiceUrl;
										anchor.download = 'E-Invoice.pdf';
										anchor.target = 'blank';
										anchor.click();
									}
								}}
								icon={<PictureAsPdfIcon />}
							/>
						) : (
							<span
								style={{
									color: '#a00',
									fontSize: '10px',
									textAlign: 'center',
									display: 'inline-block',
									// marginLeft: '8px',
									width: '100%',
								}}
							>
								*Generate E-way Bill to download Invoices
							</span>
						)}
					</>
				) : (
					!['PENDING', 'DISPATCH', 'CANCELLED', 'ABANDONED'].includes(
						item?.orderStatus
					) && (
						<span
							style={{
								cursor: 'pointer',
								color: '#1976d2',
								textDecoration: 'underline',
								fontSize: '12px',
								display: 'inline-block',
								marginLeft: '8px',
								whiteSpace: 'nowrap',
							}}
							onClick={() => {
								const urlType = 'INVOICE';
								const url = `${urls.EXTENSION}${urls.CREATE_EWAY_BILL}?poId=${item?.id}&type=${urlType}`;
								history.push(url);
							}}
						>
							Create E-Invoice
						</span>
					)
				)}
			</div>
		);
	};
	const handleDatePickerModalSetDate = useCallback(async (date, item) => {
		if (
			moment(item?.scheduledAt, 'YYYY-MM-DD HH:mm:ss').format(
				'YYYY-MM-DD'
			) === moment(date).format('YYYY-MM-DD')
		) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Date is already same',
			});
			return;
		}
		const notificationId = notificationServices.generateNotification({
			type: 'loading',
			message: 'Updating Date',
		});
		try {
			const response = await inventoryAnalyticsServices?.editVendorPO({
				params: {
					poId: item?.id,
				},
				data: {
					scheduled: moment(date).format('MM/DD/YYYY'),
				},
			});
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
					config: {
						id: notificationId,
					},
				});
				setFilters((prev) => {
					return {
						...prev,
						blink: !prev?.blink,
					};
				});
				setTimeout(() => {
					notificationServices.generateNotification({
						type: 'wave',
						message: 'Press find again to get latest updates',
						config: {
							id: notificationId,
						},
					});
				}, 1500);
				setDatePickerModalState({
					show: false,
					heading: '',
					currentPo: null,
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error,
				config: {
					id: notificationId,
				},
			});
		}
	}, []);
	const handleDatePickerModalOpen = useCallback((item) => {
		setDatePickerModalState({
			show: true,
			heading: `Expected Receive date for ${item?.clientPoId}`,
			currentPo: item,
		});
	}, []);
	const SPECIAL_ITEMS = {
		'Order Status': (itemId) => {
			const item = data?.find((poItem) => {
				return poItem?.id === itemId;
			});
			if (item?.orderStatus === 'APPROVAL_REJECTED') {
				return (
					<div className={styles.rejected}>
						<p>
							{item?.orderStatus?.split('_')?.join(' ')}
							{item?.jsonData?.rejectionReason
								? ` (Reason: ${item?.jsonData?.rejectionReason})`
								: ''}
						</p>
					</div>
				);
			}
			return handleOrderStatus(item?.orderStatus);
		},
		'Van Temp': (itemId) => {
			const item = data?.find((poItem) => {
				return poItem?.id === itemId;
			});
			return item?.receivingTemp ?? '-';
		},
		'Last Action BY': (lastActionBy, item) => {
			const isAutoApproved = item?.jsonData?.autoApproveResult?.autoApproveStatus;
			return (
				<div className="flex flex-col items-center gap-0.5">
					{isAutoApproved && (
						<div className='text-yellow-600 text-xs'>
							AUTO APPROVED
						</div>
					)}
					<div className="text-md">
						{lastActionBy}
					</div>
				</div>
			);
		},
		'Expected Receive date': (itemId) => {
			const item = data?.find((poItem) => {
				return poItem?.id === itemId;
			});
			const status = [
				'CANCELLED',
				'CLOSED',
				'ABANDONED',
				'UNLOADING',
				'VERIFICATION',
			];
			if (permit && !status.includes(item?.orderStatus)) {
				return (
					<div
						className='flex w-36 items-center justify-between border border-gray-200 p-2 cursor-pointer hover:bg-gray-50 rounded-md transition-colors'
						onClick={() => {
							handleDatePickerModalOpen(item);
						}}
					>
						<span className='text-gray-700'>
							{item?.scheduledAt?.split(' ')[0] ?? '-'}
						</span>
						<EditIcon className='text-gray-500 hover:text-gray-700' />
					</div>
				);
			} else {
				return (
					<span className='text-gray-700 block w-[7rem]'>
						{item?.scheduledAt?.split(' ')[0] ?? '-'}
					</span>
				);
			}
		},
		'Transit Van Image': (id, src) => {
			const item = data?.find((ele) => {
				return ele?.id === id;
			});
			return (
				<div
					style={{
						width: '5rem',
						display: 'flex',
						cursor: 'pointer',
						alignContent: 'center',
						justifyContent: 'space-evenly',
						justifyItems: 'center',
					}}
				>
					{item?.receivingTempImage ? (
						// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
						<img
							onClick={() => {
								toggleVanImageModal(true, {
									heading: `${item?.id ?? '-'} at ${
										item?.scheduledAt
									}`,
									image: item?.receivingTempImage,
									vanTemp: item?.receivingTemp,
									id: item?.id,
									// image: 'https://placehold.co/600x400',
									// height: 400,
								});
							}}
							width={100}
							height={50}
							// src='https://placehold.co/600x400'
							src={item?.receivingTempImage}
							alt={src}
						/>
					) : (
						<>-</>
					)}
				</div>
			);
		},
		'Order Amount': (itemId) => {
			const item = data?.find((poItem) => {
				return poItem?.id === itemId;
			});
			return `₹${
				item?.finalValue >= 0 ? addCommas(item?.finalValue) : '-'
			}`;
		},
		Download: (itemId) => {
			const item = data?.find((poItem) => {
				return poItem?.id === itemId;
			});
			if (isBuyer) {
				return renderDownloadField(item);
			}
			const vendorIdPresent = !!item?.vendorId;
			const clientPOId = item?.clientPoId ?? '-';
			const deliveryDate = item?.scheduledAt;
			const orderDate = moment(item?.createdAt).format('YYYY-MM-DD');
			const orderStatus = item?.orderStatus;
			const raisedBy = item?.storeName;
			const fullFilledBy = item?.vendorName ?? '-';
			const CommonValues = [
				item?.id,
				clientPOId,
				deliveryDate,
				orderDate,
				orderStatus,
				raisedBy,
				fullFilledBy,
			];
			const extraHeaders = ['Product Name', 'unit', 'Ordered Quantity'];
			return (
				<div className={styles?.downloadDiv}>
					{item?.poURL && (
						<Chip
							size='small'
							label='PO'
							onClick={() => {
								if (item?.poURL) {
									window.open(item?.poURL);
								}
							}}
							icon={<PictureAsPdfIcon />}
						/>
					)}
					{isInternalOrders && item?.invoiceURL && (
						<Chip
							size='small'
							label='Invoice'
							onClick={() => {
								if (item?.invoiceURL) {
									window.open(item?.invoiceURL);
								}
							}}
							icon={<PictureAsPdfIcon />}
						/>
					)}
					{item?.grnURL && (
						<Chip
							size='small'
							label='GRN'
							onClick={() => {
								if (item?.grnURL) {
									window.open(item?.grnURL);
								}
							}}
							icon={<PictureAsPdfIcon />}
						/>
					)}
				</div>
			);
		},
		Vendor: (vendorName) => {
			return (
				<div
					style={{
						width: '100px',
						textAlign: 'left',
					}}
				>
					{vendorName ?? '-'}
				</div>
			);
		},
		'Order Date': (itemId) => {
			const item = data?.find((poItem) => {
				return poItem?.id === itemId;
			});
			return (
				<div
					style={{
						width: '100px',
						textAlign: 'left',
					}}
				>
					{moment(item?.createdAt)?.format('YYYY-MM-DD') ?? '-'}
				</div>
			);
		},
		Actions: (itemId) => {
			const item = data?.find((poItem) => {
				return poItem?.id === itemId;
			});
			const vendorDetails = vendors?.find((ele) => {
				return ele?.id === item?.vendorId;
			});
			const buyersObj = {
				'Deliver From Store': item?.fromStore,
				Buyer: item?.vendorName,
			};
			const vendorsObj = {
				'Deliver To Store': item?.storeName,
				'Vendor GSTIN': vendorDetails?.gstin ?? '',
				Vendor: item?.vendorName,
				'Vendor Address': `${vendorDetails?.shippingAddressCity ?? ''}${
					vendorDetails?.shippingAddressState ?? ''
				}, ${vendorDetails?.shippingAddressPinCode ?? ''}`,
			};
			const isInternalObj = {
				'Deliver From Store': item?.vendorName,
				'Deliver To Store GSTIN': item?.storeGstin ?? '',
				'From Store Address': `${
					vendorDetails?.shippingAddressCity ?? ''
				}${vendorDetails?.shippingAddressState ?? ''}, ${
					vendorDetails?.shippingAddressPinCode ?? ''
				}`,
			};
			return (
				<div className={styles.btns}>
					{item?.orderStatus === 'APPROVAL_PENDING' &&
						permit &&
						item?.orderStatus !== 'APPROVAL_REJECTED' && (
							<SButton
								onClick={() => {
									toggleModal(true, {
										heading: `${
											isBuyer
												? 'Items of B2B Sales Order'
												: 'Items of Purchase Order'
										} #${item?.clientPoId ?? item?.id}`,
										...item,
										isApprove: true,
										approvalText: isBuyer
											? 'I have checked all the items of B2B SO'
											: 'I have checked all the items of PO',
										showTotal: true,
										showPdf: true,
										showPoDetails: true,
										transactionData: {
											'Order ID': item?.clientPoId,
											'Deliver To Store': item?.storeName,
											...(isBuyer && buyersObj),
											...(!isBuyer &&
												!isInternalOrders &&
												vendorsObj),
											...(isInternalOrders &&
												isInternalObj),
											'Payment Term':
												item?.jsonData?.paymentTerm,
											'Order Date': moment(
												item?.createdAt
											).format('YYYY-MM-DD'),
											'Dispatch By': moment(
												item?.scheduledAt
											).format('YYYY-MM-DD'),
										},
										width: '90%',
										height: '90%',
										excludedColumns: [
											'Packaged',
											'Dispatched',
											'Returned Quantity',
											'Received',
											'Fulfilled',
											'From Process',
											'PO Id',
											'Returned',
											'Quantity',
										],
									});
								}}
								className='primary-btn w-full'
							>
								Review
							</SButton>
						)}
					{(item?.orderStatus === 'APPROVAL_PENDING' ||
						item?.orderStatus === 'APPROVAL_REJECTED') && (
						<SButton
							onClick={() => {
								history.push(
									`${urls.EXTENSION}${
										urls.CREATE_PURCHASE_ORDER
									}?typeId=${isBuyer ? 8 : 1}&${
										isBuyer ? 'fromStore' : 'toStore'
									}=${
										isBuyer
											? item?.fromStore
											: navSelectedStore?.value?.id
									}&poId=${itemId}`, 
										{
											prevPath: location?.pathname + location?.search,
										}
									);
									}}
									variant='outline'
									className='border-2 border-blue-600 w-full bg-transparent'
								>
									Edit
								</SButton>
							)}
							{item?.orderStatus === 'PENDING' ||
							item?.orderStatus === 'APPROVAL_PENDING' ? (
								<div
										style={{
											width: '125px',
										}}
									>
										<SButton
										variant='outline'
										className='w-full border-red-600 text-red-600 bg-transparent'
										onClick={() => {
												toggleAlert(true, {
													heading: 'Cancel PO?',
													handleConfirm: () => {
														handleCancelPo(
															itemId,
															item?.clientPoId
														);
														toggleAlert(false);
													},
													colorReverse: false,
													cancelText: 'No',
													confirmText: 'Yes',
													message: `Are you sure you want to Cancel ${
												item?.clientPoId || ''
											}?`,
												});
											}}
										fullWidth
									>
											Cancel PO
									</SButton>
									</div>
								) : (
									'-'
								)}
				</div>
			);
		},
	};

	const handleEditSubmit = async (editedValues) => {
		try {
			const response = await assetServices.editAssetTemp({
				data: editedValues,
				params: {
					type: 'PO',
				},
			});
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: response?.errorMessage ?? response?.message,
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error ?? error?.message,
			});
		}
	};

	const exportItemsWiseData = () => {
		const headers = [
			'GRN ID',
			'GRN Start Time',
			'GRN End Time',
			'Product Name',
			'Variant',
			'Unit',
			'Product Category',
			'PO Ordered Quantity',
			'PO Fulfilled Quantity',
			'GRN Fulfilled Quantity',
			'Item Price',
			'Item Tax Percentage',
			'Total Item Tax',
			'Total Item Price',
		];
		const totalValues = [];
		data?.forEach((ele) => {
			if (ele?.grn?.length > 0) {
				ele?.grn?.forEach((grnEle) => {
					const startTime = grnEle?.createdAt
						? moment(grnEle?.createdAt)?.format('HH:MM A')
						: '';
					const endTime = grnEle?.closedAt
						? moment(grnEle?.closedAt)?.format('HH:MM A')
						: '';
					if (grnEle?.items?.length) {
						grnEle?.items?.forEach((item) => {
							totalValues?.push([
								ele?.id,
								ele?.clientPoId ?? '-',
								ele?.scheduledAt,
								moment(ele?.createdAt).format('YYYY-MM-DD'),
								ele?.orderStatus,
								ele?.storeName,
								ele?.vendorName,
								grnEle?.id,
								startTime,
								endTime,
								item?.productName?.replaceAll(',', "'"),
								item?.variantName,
								item?.unit,
								item?.categoryName ?? '-',
								item?.qty,
								'-',
								item?.fulfilled,
								item?.productCost,
								item?.taxPercentage,
								item?.productTaxValue,
								item?.totalValue,
							]);
						});
					} else {
						totalValues?.push([
							ele?.id,
							ele?.clientPoId ?? '-',
							ele?.scheduledAt,
							moment(ele?.createdAt).format('YYYY-MM-DD'),
							ele?.orderStatus,
							ele?.storeName,
							ele?.vendorName,
							grnEle?.id,
							startTime,
							endTime,
							'-',
							'-',
							'-',
							'-',
							'-',
							'-',
							'-',
							'-',
							'-',
							'-',
						]);
					}
				});
			} else {
				ele?.items?.forEach((poItem) => {
					totalValues?.push([
						ele?.id,
						ele?.clientPoId ?? '-',
						ele?.scheduledAt,
						moment(ele?.createdAt).format('YYYY-MM-DD'),
						ele?.orderStatus,
						ele?.storeName,
						ele?.vendorName,
						'-',
						'-',
						'-',
						poItem?.productName?.replaceAll(',', "'"),
						poItem?.variantName,
						poItem?.unit,
						poItem?.categoryName ?? '-',
						poItem?.qty,
						poItem?.fulfilled,
						'-',
						poItem?.productCost,
						poItem?.taxPercentage,
						poItem?.productTaxValue,
						poItem?.totalValue,
					]);
				});
			}
		});
		handleExcelDownload(
			headers,
			totalValues,
			`Vendor_PO_${moment(dateRangeFilter?.from)?.format(
				'YYYY-MM-DD'
			)}_${moment(dateRangeFilter?.to)?.format('YYYY-MM-DD')}`
		);
	};

	const fetchVendorsOrders = (filters) => {
		const filterDateRangeFilter = {
			dateType: filters?.timeType,
			from: moment(filters?.dateRange?.from).format('YYYY-MM-DD'),
			to: moment(filters?.dateRange?.to).format('YYYY-MM-DD'),
		};
		const vendorOrderFilters = {
			fromStoreId: filters?.store,
			status: filters?.status,
			storeId:
				navSelectedStore?.value?.id === -999
					? null
					: navSelectedStore?.value?.id,
			vendors: isBuyer ? filters?.buyer : filters?.vendors,
			orderType: isInternalOrders
				? 'sales/purchase'
				: isBuyer
				? 'buyers'
				: 'vendors',
			userOrderType: isInternalOrders
				? 'purchase'
				: isBuyer
				? 'sales'
				: null,
			dateRangeFilter: filterDateRangeFilter,
			grnStatus,
		};
		exportFilters.current = vendorOrderFilters;
		// setExportFilters(vendorOrderFilters);
		const req = {
			data: {
				filters: vendorOrderFilters,
			},
		};
		fetchOrdersList({
			entity: 'INVENTORY_ANALYTICS',
			method: 'VENDOR_ORDERS_LIST',
			req,
		});
	};

	const moreOptionsList = {
		'Generate Performa Invoice': (po, cb) => {
			if (po?.orderStatus === 'APPROVAL_PENDING') {
				notificationServices.generateNotification({
					type: 'error',
					message: 'PO is not approved',
				});
				return null;
			}
			if (
				po?.orderStatus === 'FULFILLED' ||
				po?.orderStatus === 'CLOSED'
			) {
				notificationServices.generateNotification({
					type: 'error',
					message: 'PO is already Fulfilled',
				});
				return null;
			}
			const url = `${urls.EXTENSION}${urls.BILLS}/add?poId=${po?.id}&isAdvance=true`;
			history.push(url);
			cb();
			return null;
		},
	};

	const handleApprovePo = async (item) => {
		// setIsloading(true);
		try {
			const responseData =
				await inventoryAnalyticsServices.changeOrderStatus({
					params: {
						poId: item?.id,
						status: 'APPROVE',
					},
				});
			if (responseData?.statusCode === 200) {
				setUpdate((prev) => {
					return prev + 1;
				});
				toggleModal(false, {});
				notificationServices?.generateNotification({
					type: 'success',
					message: responseData?.message,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
		// finally {
		// setIsloading(false);
		// }
	};
	const handleRejectPo = async (item, rejectionReason) => {
		// setIsloading(true);
		try {
			const responseData =
				await inventoryAnalyticsServices.changeOrderStatus({
					params: {
						poId: item?.id,
						status: 'REJECT',
						rejectionReason,
					},
				});
			if (responseData?.statusCode === 200) {
				setUpdate((prev) => {
					return prev + 1;
				});
				toggleModal(false, {});
				toggleAlert(false);

				notificationServices?.generateNotification({
					type: 'success',
					message: responseData?.message,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};
	// const handleSearchBy = (index) => {
	// 	setSearchField(index);
	// };
	// const handleOrderSearch = (filters) => {
	// 	const searchKey =
	// 		SEARCH_BY?.options?.[searchField !== -1 ? searchField : 0]?.key;
	// 	const vendorOrderFilters = {
	// 		fromStoreId: filters?.store,
	// 		status: filters?.status,
	// 		storeId:
	// 			navSelectedStore?.value?.id === -999
	// 				? null
	// 				: navSelectedStore?.value?.id,
	// 		vendors: isBuyer ? filters?.buyer : filters?.vendors,
	// 		orderType: isInternalOrders
	// 			? 'sales/purchase'
	// 			: isBuyer
	// 				? 'buyers'
	// 				: 'vendors',
	// 		userOrderType: isInternalOrders ? 'purchase' : isBuyer ? 'sales' : null,
	// 		serachKey: {searchKey, value: query} 
	// 	};
	// 	exportFilters.current = vendorOrderFilters;
	// 	const req = {
	// 		data: {
	// 			filters: vendorOrderFilters,
	// 		},
	// 	};
	// 	fetchOrdersList({
	// 		entity: 'INVENTORY_ANALYTICS',
	// 		method: 'VENDOR_ORDERS_LIST',
	// 		req,
	// 	});
	// };
	// const {
	// 	query,
	// 	handleSearch,
	// 	clearSearch,
	// 	searchBy: searchField,

	// 	handleSearchBy: setSearchField,
	// } = useSearch(
	// 	searchQuery,
	// 	SEARCH_BY?.options?.findIndex((option) => {
	// 		return option.key === searchBy;
	// 	}) ?? 0
	// );
	const footerComponent = () => {
		return (
			<div className={styles.footer} >
				{!isInternalOrders && !isBuyer && (
					<div className={styles.filter}>
						<Label>GRN Status:</Label>
						<Select
						onValueChange={(value) => {
							setGrnStatus(value);
						}}
						value={grnStatus}
					>
						<SelectTrigger className='w-[200px]'>
							<SelectValue placeholder='Theme' />
						</SelectTrigger>
						<SelectContent>
							{[
								'ALL',
								'GRN_UNFULFILLED',
								'GRN_FULFILLED_WITHOUT_BILL',
								'GRN_FULFILLED_WITH_BILL',
							]?.map((ele) => {
								return (
									<SelectItem value={ele}>
										{ele?.split('_')?.join(' ')}
									</SelectItem>
								);
							})}
						</SelectContent>
					</Select>
				</div>
				)}
				{/* {((!isInternalOrders || type === 'internalPoList') || isBuyer) && (
				<div className={`${(!isInternalOrders || type === 'internalPoList') || isBuyer ? 'ml-auto' : ''} `}>
					<SearchBar 
						value={query} 
						handleInput={handleSearch}
						handleChange={handleSearchBy}
						// onChange={onChange}
						searchBy={
							SEARCH_BY != null
								? {
									...(SEARCH_BY ?? {}),
									current:
											searchField === -1
												? 0
												: searchField,
								}
								: null
						}
						className={styles.search}
						showBtn
						handleOrderSearch={handleOrderSearch}
					/>
				</div>
				)} */}
			</div>
		);
	};

	const handleExport = async (filters) => {
		try {
			setLoading(true);
			const response =
				await orderAnalyticsServices.exportGRNWiseOrderHistory({
					data: {
						filters,
					},
				});
			if (response.status === 200) {
				const exportType = [
					...internalOrdersExportTypes,
					...vendorOrdersExportTypes,
				]?.find((ele) => {
					return ele?.value === filters?.exportType;
				});
				const exportTypeValue = exportType?.label
					?.split(' ')
					?.join('_')
					?.toUpperCase(); 
				const buyerExportTypeValue = isBuyer ? exportTypeValue === 'INVOICE-WISE_PURCHASE_REGISTER' ? 'INVOICE-WISE_SALES_REGISTER' : exportTypeValue === 'ITEM-WISE_PURCHASE_REGISTER' ? 'ITEM-WISE_SALES_REGISTER' : exportTypeValue : exportTypeValue;
				let exportFileName = `VENDOR_${exportTypeValue}_ORDERS_${filters?.dateRangeFilter?.from}_${filters?.dateRangeFilter?.to}.csv`;
				if (isInternalOrders) {
					exportFileName = `INTERNAL_PURCHASE_ORDERS_${filters?.dateRangeFilter?.from}_${filters?.dateRangeFilter?.to}.csv`;
				} else if (isBuyer) {
					exportFileName = `BUYER_${buyerExportTypeValue}_ORDERS_${filters?.dateRangeFilter?.from}_${filters?.dateRangeFilter?.to}.csv`;
				}
				handleResponseStreamDownload(response, exportFileName);
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Some error occurred',
				});
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error,
			});
		} finally {
			setLoading(false);
		}
	};

	const DATE_TYPES = useMemo(() => {
		return dateTypeArr?.filter((ele) => {
			if (isInternalOrders) {
				return ele?.value !== 'grn';
			}
			if (isBuyer) {
				return !(ele?.value === 'received' || ele?.value === 'grn');
			}
			return !(ele?.value === 'invoiced' || ele?.value === 'received');
		});
	}, [isInternalOrders, isBuyer]);

	const openExportDrawer = () => {
		const exportTypeDefaultValue = isInternalOrders
			? internalOrdersExportTypes?.[1]?.value
			: vendorOrdersExportTypes?.[1]?.value;
		let heading = 'Export Internal Purchase Orders';
		if (!isInternalOrders && type !== 'BUYER') {
			heading = 'Export Vendor Orders';
		} else if (type === 'BUYER') {
			heading = 'Export Buyer Orders';
		}
		toggleExportFiltersDrawer(true, {
			heading,
			// note: 'Filters are disabled for now, they will be LIVE Soon!',
			onConfirm: (args) => {
				const { filtersState } = args ?? {};
				handleExport({
					...exportFilters.current,
					dateRangeFilter: {
						dateType: filtersState?.dateRangeInput,
						from: moment(filtersState?.dateRange?.from).format(
							'YYYY-MM-DD'
						),
						to: moment(filtersState?.dateRange?.to).format(
							'YYYY-MM-DD'
						),
					},
					categories: filtersState?.categories,
					subCategories: filtersState?.subCategories,
					exportType: filtersState?.exportType,
				});
			},
			filterData: [
				{
					type: 'tabSelect',
					dependentOn: -1,
					compData: isInternalOrders
						? internalOrdersExportTypes
						: vendorOrdersExportTypes,
					selectBy: 'value',
					label: 'Export Type',
					selectedValueKey: 'exportType',
					dateFormat: null,
					defaultState: exportTypeDefaultValue,
					disabled: false,
				},
				{
					type: 'singleSelect',
					dependentOn: -1,
					compData: categoriesList?.map((ele) => {
						return {
							id: ele?.id,
							value: ele?.id,
							title: ele?.productCategoryName,
							label: ele?.productCategoryName,
						};
					}),
					selectBy: 'id',
					label: 'Category',
					selectedValueKey: 'categories',
					dateFormat: null,
					disabled: true,
					defaultState: null,
				},
				{
					type: 'multiSelect',
					dependentOn: 1,
					compData: [],
					selectBy: 'id',
					label: 'Sub-Categories',
					selectedValueKey: 'subCategories',
					dateFormat: null,
					defaultState: [],
					disabled: true,
					dataPropKey: 'subCategories',
					onDependencyUpdate: (dependencyState) => {
						if (dependencyState) {
							fetchSubCategories({
								entity: 'PRODUCT_SUB_CATEGORY',
								req: {
									params: {
										categoryId: dependencyState,
									},
								},
								method: 'LIST',
							});
						}
					},
				},
				{
					type: 'dateRange',
					dependentOn: -1,
					compData: [],
					selectBy: '',
					label: 'Date Range',
					selectedValueKey: 'dateRange',
					dateFormat: null,
					withInput: true,
					disabled: false,
					dataPropKey: '',
					defaultState: {
						from: new Date(
							exportFilters?.current?.dateRangeFilter?.from
						),
						to: new Date(
							exportFilters?.current?.dateRangeFilter?.to
						),
					},
				},
				{
					type: 'dateRangeInput',
					dependentOn: -1,
					compData: DATE_TYPES,
					selectBy: '',
					disabled: false,
					label: 'Date Type',
					selectedValueKey: 'dateRangeInput',
					dateFormat: null,
					defaultState:
						exportFilters?.current?.dateRangeFilter?.dateType,
				},
			],
		});
	};

	const handleOnUpdate = (filters) => {
		const filterDateRangeFilter = {
			dateType: filters?.timeType,
			from: moment(filters?.dateRange?.from).format('YYYY-MM-DD'),
			to: moment(filters?.dateRange?.to).format('YYYY-MM-DD'),
		};
		// console.log('filters', filters);
		const vendorOrderFilters = {
			fromStoreId: filters?.store,
			status: filters?.status,
			storeId:
				navSelectedStore?.value?.id === -999
					? null
					: navSelectedStore?.value?.id,
			vendors: isBuyer ? filters?.buyer : filters?.vendors,
			orderType: isInternalOrders
				? 'sales/purchase'
				: isBuyer
				? 'buyers'
				: 'vendors',
			userOrderType: isInternalOrders
				? 'purchase'
				: isBuyer
				? 'sales'
				: null,
			dateRangeFilter: filterDateRangeFilter,
			grnStatus,
		};
		exportFilters.current = vendorOrderFilters;
		// setExportFilters(vendorOrderFilters);
	};
	// search Bar
	return (
		<div className={styles.root}>
			<BackdropLoader showProgress isLoading={loading} />
			<AlertModal />
			<DatePickerModal
				show={datePickerModalState?.show}
				heading={datePickerModalState?.heading}
				restrictDateType='before'
				currentPo={datePickerModalState?.currentPo}
				onClose={() => {
					setDatePickerModalState({ show: false, heading: '' });
				}}
				onSubmit={handleDatePickerModalSetDate}
			/>
			<POItemsModal
				handleConfirm={handleApprovePo}
				handleReject={handleRejectPo}
			/>
			<VendorOrderGrnsModal />
			<AssetTransitModal />
			<VanImageModal handleEditSubmit={handleEditSubmit} />
			<DebitCreditListDrawer />
			<ExportFiltersDrawer
				subCategories={subCategoriesList?.map((ele) => {
					return {
						id: ele?.id,
						value: ele?.id,
						title: ele?.categoryName,
						label: ele?.categoryName,
					};
				})}
			/>
			<div className={styles.header}>
				<ImagesFinder
					pageLabel={
						isInternalOrders
							? 'Internal Purchase Orders'
							: isBuyer
							? 'Buyer Order'
							: 'Vendor Orders'
					}
					FILTERS={FIND_FILTERS?.filter((ele) => {
						if (isInternalOrders) {
							return ele !== 'vendor' && ele !== 'buyer';
						}
						if (isBuyer) {
							return ele !== 'vendor';
						}
						return ele !== 'store' && ele !== 'buyer';
					})}
					onSubmit={fetchVendorsOrders}
					update={update}
					timeTypeArray={DATE_TYPES}
					statusList={(type === 'BUYER'|| type === 'internalPoList') ? filterOrderStatus?.map((ele) => {
						if (isBuyer && (ele === 'APPROVAL_PENDING' || ele === 'APPROVAL_REJECTED')) {
							return null;
						}
						if (ele === 'FULFILLED') {
							return {
								id: ele,
								title: 'CLOSED',
							};
						}
						if (ele === 'TRANSIT') {
							return {
								id: ele,
								title: 'IN TRANSIT',
							};
						}
						if (ele === 'DISPATCH') {
							return {
								id: ele,
								title: 'PENDING DISPATCH',
							};
						}
						if (ele === 'UNLOADING') {
							return {
								id: ele,
								title: 'UNLOADING STARTED',
							};
						}
						if (ele === 'VERIFICATION') {
							return {
								id: ele,
								title: 'PENDING VERIFICATION',
							};
						}
						return {
							id: ele,
							title: ele,
						};
					}): vendorOrder(vendorOrderStatus)}
					onUpdate={handleOnUpdate}
					footerComponent={footerComponent}
					preventDateRangeCheckbox
					additionInfo={{
						showAdd: true,
						addText: 'Order',
						prefix: 'Create',
						handleAddClick: () => {
							let typeId = 1;
							if (isBuyer) {
								typeId = 8;
							}
							if (isInternalOrders) {
								if (navSelectedStore?.value?.type === 'BASE') {
									typeId = 3;
								} else {
									typeId = 2;
								}
							}
							const storeSelectionText = isBuyer ? '' : 'toStore';
							const redirectUrl =
								navSelectedStore?.value?.id !== -999
									? `${urls.EXTENSION}${urls.CREATE_PURCHASE_ORDER}?userOrderType=purchase&typeId=${typeId}&${storeSelectionText}=${navSelectedStore?.value?.id}`
									: `${urls.EXTENSION}${
											urls.CREATE_PURCHASE_ORDER
									  }${
											isInternalOrders
												? '?exclude=1'
												: `?typeId=${typeId}`
									}&userOrderType=purchase`;
							history.push(redirectUrl, {
								prevPath: location?.pathname + location?.search,
							});
						},
					}}
					filterLabels={{
						store: 'Deliver From',
					}}
					// externalFilters={externalFilters}
					orderText
				/>
			</div>
			<div
				className={styles?.table}
				style={{
					width: '100%',
				}}
			>
				<ListTable
					hasDateRange
					allowChangeColumn
					colMap={COLMAP}
					fixedHeaderTop='0'
					columns={TABLE_COLUMNS?.filter((ele) => {
						if (isBuyer) {
							return (
								ele?.title !== 'Vendor Challan ID' &&
								ele?.title !== 'Vendor' &&
								ele?.title !== 'Deliver To'
							);
						}
						if (!isInternalOrders) {
							return ele?.title !== 'Deliver From';
						}
						return (
							ele?.title !== 'Buyer' &&
							ele?.title !== 'Vendor Challan ID' &&
							ele?.title !== 'Vendor' &&
							ele?.title !== 'CREATED BY' &&
							ele?.title !== 'Last Action BY'
						);
					})}
					data={data?.map((ele) => {
						return {
							...ele,
							rowStyles: classes(
								styles.row,
								styles?.[ele?.orderStatus]
							),
						};
					})}
					actions={[...SPECIAL_COLUMNS, ...ACTION_COLUMNS]?.filter(
						(ele) => {
							if (!isInternalOrders)
								return (
									ele?.title !== 'View' &&
									ele?.title !== 'Expected Receive date'
								);
							return ele?.title !== 'MORE';
						}
					)}
					special={SPECIAL_ITEMS}
					handleViewClick={handleViewClick}
					// onExportClick={handleExport}
					onExportClick={openExportDrawer}
					moreOptionsList={moreOptionsList}
					viewMoreOptionsList={
						isInternalOrders
							? internalViewMoreOptions
							: viewMoreOptionsList
					}
				/>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	const { navState, userPermissions } = state?.navigation;
	const { VENDOR_ORDERS_LIST: data } = state?.analytics?.INVENTORY_ANALYTICS;
	const { DATE_RANGE_FILTER: dateRangeFilter } = state.analytics;
	const { LIST: vendors } = state.form.ALL_VENDORS.data;
	const categoriesList = state.form.PRODUCT_CATEGORY.data.LIST;
	const subCategoriesList = state.form.PRODUCT_SUB_CATEGORY.data.LIST;
	const { userList } = state?.auth;
	return {
		data,
		dateRangeFilter,
		navState,
		userPermissions,
		vendors,
		categoriesList,
		subCategoriesList,
		userList,
	};
};

const mapDispatchToProps = {
	setDateRangeFilter: analyticsAction.setDateRangeFilter,
	toggleModal: modalActions.togglePOItemsModal,
	toggleVendorOrderGrnsModal: modalActions.toggleVendorOrderGrnsModal,
	toggleAlert: modalActions.toggleAlert,
	toggleVanImageModal: modalActions.toggleVanImageModal,
	// toggleFileUploaderModal: modalActions.toggleFileUploaderModal,
	toggleTransactionsModal: modalActions.toggleTransactionsModal,
	fetchOrdersList: analyticsAction.gateway,
	fetchVendors: formActions.gateway,
	resetInventoryAnalyticsState: analyticsAction.resetInventoryAnalyticsState,
	toggleExportFiltersDrawer: drawerActions.toggleExportFiltersDrawer,
	fetchSubCategories: formActions.gateway,
	toggleDebitCreditListDrawer: drawerActions.toggleDebitCreditListDrawer,
	toggleAssetTransit: modalActions.toggleAssetTransit,
	fetchUserList: authActions?.fetchUserList,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(VendorOrdersListPage);
