import { modalConstants, modalTypes } from '../Constants';

const newTypes = Object.keys(modalTypes).reduce(
	(accModalType, currModalType) => {
		// eslint-disable-next-line no-param-reassign
		accModalType[currModalType] = {
			show: false,
			data: {},
		};
		return accModalType;
	},
	{}
);

const initialState = {
	...newTypes,
	products: {
		show: false,
		data: {},
	},
	alertModal: {
		show: false,
		data: {},
	},
	skus: {
		show: false,
		data: {},
	},
	address: {
		show: false,
		data: {},
	},
	applicable_stores: {
		show: false,
		data: {},
	},
	applicable_users: {
		show: false,
		data: {},
	},
	applicable_product_categories: {
		show: false,
		data: {},
	},
	applicable_products: {
		show: false,
		data: {},
	},
	nonapplicable_products: {
		show: false,
		data: {},
	},
	nonapplicable_product_categories: {
		show: false,
		data: {},
	},
	pincodes: {
		show: false,
		data: {},
	},
	livemenu: {
		show: false,
		data: {},
	},
	menuList: {
		show: false,
		data: {},
	},
	geography: {
		show: false,
		data: {},
	},
	filter: {
		show: false,
		data: {},
	},
	brand: {
		show: false,
		data: {},
	},
	imageCrop: {
		show: false,
		data: {},
	},
	exportProcess: {
		show: false,
		data: {},
	},
	specialMenu: {
		show: false,
		data: {},
	},
	imageModal: {
		show: false,
		data: {},
	},
	productProcessMapCreatorModal: {
		show: false,
		data: {},
	},
	altAlertModal: {
		show: false,
		data: {},
	},
	defectModal: {
		show: false,
		data: {},
	},
	listModal: {
		show: false,
		data: {},
	},
	poItemsModal: {
		show: false,
		data: {},
	},
	stockListModal: {
		show: false,
		data: {},
	},
	orderItemsModal: {
		show: false,
		data: {},
	},
	workOrderItemsModal: {
		show: false,
		data: {},
	},
	editChildModal: {
		show: false,
		data: {},
	},
	containerModal: {
		show: false,
		data: {},
	},
	vanImageModal: {
		show: false,
		data: {},
	},
	reportEmailsModal: {
		show: false,
		data: {},
	},
	ordersItemsModal: {
		show: false,
		data: {},
	},
	processModal: {
		show: false,
		data: {},
	},
	productionPlanModal: {
		show: false,
		data: {},
	},
	generateStandardPoModal: {
		show: false,
		data: {},
	},
	addVendorModal: {
		show: false,
		data: {},
	},
	vendorProductMapModal: {
		show: false,
		data: {},
	},
	vendorOrderGrnsModal: {
		show: false,
		data: {},
	},
	auditItemsModal: {
		show: false,
		data: {},
	},
	importModal: {
		show: false,
		data: {},
	},
	productWiseMappingModal: {
		show: false,
		data: {},
	},
	addLicenseModal: {
		show: false,
		data: {},
	},
	changePasswordModal: {
		show: false,
		data: {},
	},
	fileUploaderModal: {
		show: false,
		data: {},
	},
	paymentModal: {
		show: false,
		data: {},
	},
	navRequisitesModal: {
		show: false,
		data: {},
	},
	transactionsModal: {
		show: false,
		data: {},
	},
	auditCreationModal: {
		show: false,
		data: {},
	},
	searchModal: {
		show: false,
		data: {},
	},
	itemsModal: {
		show: false,
		data: {},
	},
	bulkPaymentModal: {
		show: false,
		data: {},
	},
	addGroupModal: {
		show: false,
		data: {},
	},
	regionMenuModal: {
		show: false,
		data: {},
	},
	dynamicTableModal: {
		show: false,
		data: {},
	},
	shiftTableModal: {
		show: false,
		data: {},
	},
	errorModal: {
		show: false,
		data: {},
	},
	paymentDetailsModal: {
		show: false,
		data: {},
	},
	leftOverItemsModal: {
		show: false,
		data: {},
	},
	addWastageReasonModal: {
		show: false,
		data: {},
	},
	editNodeModal: {
		show: false,
		data: {},
	},
	addAssetCategoryModal: {
		show: false,
		data: {},
	},
	showCoreSpecificationModal: {
		show: false,
		data: {},
	},
	uploadDataModal: {
		show: false,
		data: {},
	},
	assetTransit: {
		show: false,
		data: {},
	},
	grnModal: {
		show: false,
		data: {},
	},
	productParametersModal: {
		show: false,
		data: {},
	},
	qualityApprovalModal: {
		show: false,
		data: {},
	},
	addQualityParameterModal: {
		show: false,
		data: {},
	},
};

function modal(state = initialState, action) {
	switch (action.type) {
		case modalConstants.SHOW_PRODUCTS:
			return {
				...state,
				products: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_PRODUCTS:
			return {
				...state,
				products: {
					show: false,
					data: action.payload,
				},
			};
		case modalConstants.SHOW_ALERT:
			return {
				...state,
				alertModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.SHOW_SKUS:
			return {
				...state,
				skus: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_ALERT:
			return {
				...state,
				alertModal: {
					show: false,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_SKUS:
			return {
				...state,
				skus: {
					show: false,
					data: action.payload,
				},
			};
		case modalConstants.SHOW_FILTER:
			return {
				...state,
				filter: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_FILTER:
			return {
				...state,
				filter: {
					show: false,
					data: action.payload,
				},
			};
		case modalConstants.SHOW_ADDRESS:
			return {
				...state,
				address: {
					show: true,
					data: action.payload,
				},
			};

		case modalConstants.HIDE_ADDRESS:
			return {
				...state,
				address: {
					show: false,
					data: action.payload,
				},
			};
		case modalConstants.SHOW_ADD_APPLICABLE_USERS:
			return {
				...state,
				applicable_users: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.SHOW_ADD_PINCODE:
			return {
				...state,
				pincodes: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_ADD_APPLICABLE_USERS:
			return {
				...state,
				applicable_users: {
					show: false,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_ADD_PINCODE:
			return {
				...state,
				pincodes: {
					show: false,
					data: action.payload,
				},
			};
		case modalConstants.SHOW_LIVE_MENU:
			return {
				...state,
				livemenu: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.SHOW_BRAND_FILTER:
			return {
				...state,
				brand: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_LIVE_MENU:
			return {
				...state,
				livemenu: {
					show: false,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_BRAND_FILTER:
			return {
				...state,
				brand: {
					show: false,
					data: action.payload,
				},
			};
		case modalConstants.SHOW_MENULIST:
			return {
				...state,
				menuList: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.SHOW_GEOGRAPHY_FILTER:
			return {
				...state,
				geography: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_MENULIST:
			return {
				...state,
				menuList: {
					show: false,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_GEOGRAPHY_FILTER:
			return {
				...state,
				geography: {
					show: false,
					data: action.payload,
				},
			};
		case modalConstants.SHOW_IMAGE_CROP:
			return {
				...state,
				imageCrop: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_IMAGE_CROP:
			return {
				...state,
				imageCrop: {
					show: false,
					data: action.payload,
				},
			};
		case modalConstants.SHOW_ADD_APPLICABLE_STORES:
			return {
				...state,
				applicable_stores: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.SHOW_SPECIAL_MENU:
			return {
				...state,
				specialMenu: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_ADD_APPLICABLE_STORES:
			return {
				...state,
				applicable_stores: {
					show: false,
					data: action.payload,
				},
			};
		case modalConstants.SHOW_ADD_APPLICABLE_PRODUCT_CATEGORIES:
			return {
				...state,
				applicable_product_categories: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_ADD_APPLICABLE_PRODUCT_CATEGORIES:
			return {
				...state,
				applicable_product_categories: {
					show: false,
					data: action.payload,
				},
			};
		case modalConstants.SHOW_ADD_NONAPPLICABLE_PRODUCT_CATEGORIES:
			return {
				...state,
				nonapplicable_product_categories: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_ADD_NONAPPLICABLE_PRODUCT_CATEGORIES:
			return {
				...state,
				nonapplicable_product_categories: {
					show: false,
					data: action.payload,
				},
			};
		case modalConstants.SHOW_ADD_APPLICABLE_PRODUCTS:
			return {
				...state,
				applicable_products: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_ADD_APPLICABLE_PRODUCTS:
			return {
				...state,
				applicable_products: {
					show: false,
					data: action.payload,
				},
			};
		case modalConstants.SHOW_ADD_NONAPPLICABLE_PRODUCTS:
			return {
				...state,
				nonapplicable_products: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_ADD_NONAPPLICABLE_PRODUCTS:
			return {
				...state,
				nonapplicable_products: {
					show: false,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_SPECIAL_MENU:
			return {
				...state,
				specialMenu: {
					show: false,
					data: action.payload,
				},
			};
		case modalConstants.SHOW_IMAGE_MODAL:
			return {
				...state,
				imageModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_IMAGE_MODAL:
			return {
				...state,
				imageModal: {
					show: false,
					data: action.payload,
				},
			};
		case modalConstants.SHOW_MAP_CREATOR_MODAL:
			return {
				...state,
				productProcessMapCreatorModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_MAP_CREATOR_MODAL:
			return {
				...state,
				productProcessMapCreatorModal: {
					show: false,
					data: action.payload,
				},
			};
		case modalConstants.SHOW_VAN_IMAGE_MODAL:
			return {
				...state,
				vanImageModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_VAN_IMAGE_MODAL:
			return {
				...state,
				vanImageModal: {
					show: false,
					data: action.payload,
				},
			};
		case modalConstants.SHOW_ALT_ALERT:
			return {
				...state,
				altAlertModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_ALT_ALERT:
			return {
				...state,
				altAlertModal: {
					show: false,
					data: action.payload,
				},
			};
		case modalConstants.SHOW_LIST_MODAL:
			return {
				...state,
				listModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_LIST_MODAL:
			return {
				...state,
				listModal: {
					show: false,
					data: action.payload,
				},
			};
		case modalConstants.SHOW_PO_ITEMS_MODAL:
			return {
				...state,
				poItemsModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_PO_ITEMS_MODAL:
			return {
				...state,
				poItemsModal: {
					show: false,
					data: action.payload,
				},
			};
		case modalConstants.SHOW_STOCK_LIST_MODAL:
			return {
				...state,
				stockListModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_STOCK_LIST_MODAL:
			return {
				...state,
				stockListModal: {
					show: false,
					data: action.payload,
				},
			};
		case modalConstants.SHOW_ORDER_ITEMS_MODAL:
			return {
				...state,
				orderItemsModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_ORDER_ITEMS_MODAL:
			return {
				...state,
				orderItemsModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_WORK_ORDER_ITEMS_MODAL:
			return {
				...state,
				workOrderItemsModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_WORK_ORDER_ITEMS_MODAL:
			return {
				...state,
				workOrderItemsModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_EDIT_CHILD_MODAL:
			return {
				...state,
				editChildModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_EDIT_CHILD_MODAL:
			return {
				...state,
				editChildModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_CONTAINER_MODAL:
			return {
				...state,
				containerModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_CONTAINER_MODAL:
			return {
				...state,
				containerModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_REPORTS_EMAILS_MODAL:
			return {
				...state,
				reportEmailsModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_REPORTS_EMAILS_MODAL:
			return {
				...state,
				reportEmailsModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_ORDERS_ITEMS_MODAL:
			return {
				...state,
				ordersItemsModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_ORDERS_ITEMS_MODAL:
			return {
				...state,
				ordersItemsModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_PROCESS_MODAL:
			return {
				...state,
				processModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_PROCESS_MODAL:
			return {
				...state,
				processModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_PRODUCTION_PLAN_MODAL:
			return {
				...state,
				productionPlanModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_PRODUCTION_PLAN_MODAL:
			return {
				...state,
				productionPlanModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_GENERATE_STANDARD_PO_MODAL:
			return {
				...state,
				generateStandardPoModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_GENERATE_STANDARD_PO_MODAL:
			return {
				...state,
				generateStandardPoModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_ADD_NEW_VENDOR_MODAL:
			return {
				...state,
				addVendorModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_ADD_NEW_VENDOR_MODAL:
			return {
				...state,
				addVendorModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_VENDOR_PRODUCT_MAP_MODAL:
			return {
				...state,
				vendorProductMapModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_VENDOR_PRODUCT_MAP_MODAL:
			return {
				...state,
				vendorProductMapModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_VENDOR_ORDER_GRNS_MODAL:
			return {
				...state,
				vendorOrderGrnsModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_VENDOR_ORDER_GRNS_MODAL:
			return {
				...state,
				vendorOrderGrnsModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_AUDIT_ITEMS_MODAL:
			return {
				...state,
				auditItemsModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_AUDIT_ITEMS_MODAL:
			return {
				...state,
				auditItemsModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_IMPORT_MODAL:
			return {
				...state,
				importModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_IMPORT_MODAL:
			return {
				...state,
				importModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_PRODUCTWISE_MAPPING_MODAL:
			return {
				...state,
				productWiseMappingModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_PRODUCTWISE_MAPPING_MODAL:
			return {
				...state,
				productWiseMappingModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_ADD_LICENSE_MODAL:
			return {
				...state,
				addLicenseModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_ADD_LICENSE_MODAL:
			return {
				...state,
				addLicenseModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_CHANGE_PASSWORD_MODAL:
			return {
				...state,
				changePasswordModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_CHANGE_PASSWORD_MODAL:
			return {
				...state,
				changePasswordModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_FILE_UPLOADER_MODAL:
			return {
				...state,
				fileUploaderModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_FILE_UPLOADER_MODAL:
			return {
				...state,
				fileUploaderModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_PAYMENT_MODAL:
			return {
				...state,
				paymentModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_PAYMENT_MODAL:
			return {
				...state,
				paymentModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_NAV_REQUISITES_MODAL:
			return {
				...state,
				navRequisitesModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_NAV_REQUISITES_MODAL:
			return {
				...state,
				navRequisitesModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_TRANSACTIONS_MODAL:
			return {
				...state,
				transactionsModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_TRANSACTIONS_MODAL:
			return {
				...state,
				transactionsModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_AUDIT_CREATION_MODAL:
			return {
				...state,
				auditCreationModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_AUDIT_CREATION_MODAL:
			return {
				...state,
				auditCreationModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_SEARCH_MODAL:
			return {
				...state,
				searchModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_SEARCH_MODAL:
			return {
				...state,
				searchModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_ITEMS_MODAL:
			return {
				...state,
				itemsModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_ITEMS_MODAL:
			return {
				...state,
				itemsModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_BULK_PAYMENT_MODAL:
			return {
				...state,
				bulkPaymentModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_BULK_PAYMENT_MODAL:
			return {
				...state,
				bulkPaymentModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_GROUP_MODAL:
			return {
				...state,
				addGroupModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_GROUP_MODAL:
			return {
				...state,
				addGroupModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_REGION_MENU_MODAL:
			return {
				...state,
				regionMenuModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_REGION_MENU_MODAL:
			return {
				...state,
				regionMenuModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_DYNAMIC_TABLE_MODAL:
			return {
				...state,
				dynamicTableModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.SHOW_SHIFT_TABLE_MODAL:
			return {
				...state,
				shiftTableModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_DYNAMIC_TABLE_MODAL:
			return {
				...state,
				dynamicTableModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.HIDE_SHIFT_TABLE_MODAL:
			return {
				...state,
				shiftTableModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_ERROR_MODAL:
			return {
				...state,
				errorModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_ERROR_MODAL:
			return {
				...state,
				errorModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_PAYMENT_DETAILS_MODAL:
			return {
				...state,
				paymentDetailsModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_PAYMENT_DETAILS_MODAL:
			return {
				...state,
				paymentDetailsModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_LEFT_OVER_ITEMS_MODAL:
			return {
				...state,
				leftOverItemsModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_LEFT_OVER_ITEMS_MODAL:
			return {
				...state,
				leftOverItemsModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_ADD_WASTAGE_REASON_MODAL:
			return {
				...state,
				addWastageReasonModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_ADD_WASTAGE_REASON_MODAL:
			return {
				...state,
				addWastageReasonModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.TOGGLE_MODAL:
			return {
				...state,
				...action.payload,
			};
		case modalConstants.SHOW_EDIT_NODE_MODAL:
			return {
				...state,
				editNodeModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_EDIT_NODE_MODAL:
			return {
				...state,
				editNodeModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_ADD_ASSET_CATEGORY_MODAL:
			return {
				...state,
				addAssetCategoryModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_ADD_ASSET_CATEGORY_MODAL:
			return {
				...state,
				addAssetCategoryModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_SPECIFICATION_MODAL:
			return {
				...state,
				showCoreSpecificationModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_SPECIFICATION_MODAL:
			return {
				...state,
				showCoreSpecificationModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_UPLOAD_DATA_MODAL:
			return {
				...state,
				uploadDataModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_UPLOAD_DATA_MODAL:
			return {
				...state,
				uploadDataModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_ASSET_TRANSIT:
			return {
				...state,
				assetTransit: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_ASSET_TRANSIT:
			return {
				...state,
				assetTransit: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_GRN_MODAL:
			return {
				...state,
				grnModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_GRN_MODAL:
			return {
				...state,
				grnModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_PRODUCT_PARAMETERS_MODAL:
			return {
				...state,
				productParametersModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_PRODUCT_PARAMETERS_MODAL:
			return {
				...state,
				productParametersModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_QUALITY_APPROVAL_MODAL:
			return {
				...state,
				qualityApprovalModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_QUALITY_APPROVAL_MODAL:
			return {
				...state,
				qualityApprovalModal: {
					show: false,
					data: {},
				},
			};
		case modalConstants.SHOW_ADD_QUALITY_PARAMETER_MODAL:
			return {
				...state,
				addQualityParameterModal: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_ADD_QUALITY_PARAMETER_MODAL:
			return {
				...state,
				addQualityParameterModal: {
					show: false,
					data: {},
				},
			};
		default:
			return state;
	}
}

export default modal;
