import { cloneDeep } from 'lodash';
import { notificationServices } from '@/Services';

const initialState = {
	userName: '',
	legalName: '',
	password: '',
	gstNumber: '',
	email: '',
	phoneNumber: '',
	billingAddress: '',
	city: {},
	state: '',
	pincode: '',
	billingAddress2: '',
};
const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_VALUE':
			return {
				...state,
				...action.payload,
			};

		default:
			return state;
	}
};
const config = {
	initialState,
	reducer,
	validator: (state) => {
		if (!state?.legalName) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'legal name mandatory',
			});
			return false;
		}
		// if (!state?.password) {
		// notificationServices.generateNotification({
		// type: 'error',
		// message: 'Password mandatory',
		// });
		// return false;
		// }
		if (!state?.gstNumber) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'gstNumber mandatory',
			});
			return false;
		}
		if (!state?.billingAddress) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'billing Address mandatory',
			});
			return false;
		}
		if (!state?.billingAddress2) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'billing Address2 mandatory',
			});
			return false;
		}
		if (!state?.city) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'city mandatory',
			});
			return false;
		}
		if (!state?.pincode) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'pincode mandatory',
			});
			return false;
		}
		if (!state?.state) {
			notificationServices.generateNotification({
				type: 'error',
				message: 'pincode mandatory',
			});
			return false;
		}
		return true;
	},
	deserilizer: (state) => {
		const cloneState = cloneDeep(state);
		const newState = {
			...cloneState,
			cityId: cloneState?.city?.id,
			city: cloneState?.city?.name,
		};
		return newState;
	},
};

export default config;
