/* eslint-disable */
import { useState, useEffect } from 'react';
import Info from '@/Components/InfoTab/Info';
import Tab from '@/Components/InfoTab/tab';
import { inputHelper, keyPressHandler, useQuery } from '@/Helpers';
import { useHistory } from 'react-router-dom';
import inventoryAnalyticsServices from '@/Services/Analytics Services/inventory-analytics.services';
import GrnHeader from './grnHeader';
import useKeyPress from '@/Helpers/useKeyPress';
import { urls } from '@/Constants';
import { BackdropLoader } from '@/Components/Loaders';
import { notificationServices } from '@/Services';
import GrnTable from './GrnTable';
import { Chip } from '@mui/material';
import { grnQcLogService } from '@/Services/grnQcLog.services';


const COLMAP = {
   'Variant Name': 'id',
   'Unit': 'unit',
   'PO Quantity': 'poQty',
   'GRN Quantity': 'qty',
   'Fullfiled Quantity': 'fulfilled',
   'Batch Fullfiled': 'id',
   'Qc Passed': 'id',
   'View QC Logs': 'id',
};

const SPECIAL_COLUMNS = [
    {
        title: 'Variant Name',
        config: {
            type: 'DATA',
        },
    },
    {   
        title: 'Unit',
        config: {
            type: 'DATA',
        },
    },
    {
        title: 'PO Quantity',
        config: {
            type: 'DATA',
        },
    },
    {
        title: 'GRN Quantity',
        config: {
            type: 'DATA',
        },
    },
    {
        title: 'Fullfiled Quantity',
        config: {
            type: 'DATA',
        },
    },
    {
        title: 'Batch Fullfiled',
        config: {
            type: 'DATA',
        },
    },
    {
        title: 'Qc Passed',
        config: {
            type: 'DATA',
        },
    },
    {
        title: 'View QC Logs',
        config: {
            type: 'DATA',
        },
    },
];

function GRNDetailsPage() {
	const query = useQuery();
	const history = useHistory();
	const id = query?.get('id');
    const storeId = query?.get('storeId');
    const vendor = query?.get('vendor');
    const dateRangeFilter = query?.get('dateRangeFilter');
	const [listData, setListData] = useState([]);
	const [idx, setIdx] = useState(-1);
	const keyPressed = useKeyPress();
	const [grnDetails, setGrnDetails] = useState({
		id: '',
		status: '',
		clientPoId: '',
		vendorName: '',
		storeName: '',
		fullfilledQuantity: 0,
		grnDate: '',
		startTime: '',
		endTime: '',
		duration: '',
	});
	const [grnData, setGrnData] = useState([]);
	const [isLoading, setIsloading] = useState(false);
	const buttons = [];

	const [tabValue, setTabValue] = useState('orderedProducts');

	const fetchGrnData = async () => {
		setIsloading(true);
		try {
			const responseData =
				await inventoryAnalyticsServices.fetchInvTransRecord({
					req: {
						data: {
							type: 'GRN',
						},
					},
				});
			if (responseData?.statusCode === 200) {
				setListData(responseData?.data);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setIsloading(false);
		}
	};

	useEffect(() => {
		if (!id) {
			history.push(`${urls.EXTENSION}${urls.GRN_LIST}`);
			return;
		}
		fetchGrnData();
	}, []);

	useEffect(() => {
		if (listData?.length > 0) {
			const currentIndex = listData.findIndex(
				(item) => item.id === Number(id)
			);
			setIdx(currentIndex);
		}
	}, [id, listData]);

    const handleAiMachineFetch = async (id) => {
        const req = {
            data: {
                grnId: id,
                qcType: 'INWARD',
            },
        }
        try {
            const res = await grnQcLogService.fetchAiSupervisorScans(req);
            if(res?.statusCode === 200) {
                setGrnData(res?.data);
                setIsDataFetched(true);
                notificationServices.generateNotification({
                    type: 'success',
                    message: 'Data fetched successfully',
                });
            }
        } catch (error) {
            notificationServices.generateNotification({
                type: 'error',
                message: error,
            });
        }
    }

	const fetchGrnDetails = async (grnId) => {
		if (!grnId) return;
		
		setIsloading(true);
		try {
			const responseData = await inventoryAnalyticsServices.fetchInvTransRecord({
				req: {
					data: {
						grnId: grnId,
						type: 'GRN',
					},
				},
			});
			if (responseData?.statusCode === 200 && responseData?.data?.length > 0) {
				const item = responseData?.data;
                const grnItem = item[0];
				setGrnDetails(() => {
					return {
						id: grnItem?.id || '',
						status: grnItem?.transactionStatus || '',
						clientPoId: grnItem?.clientPoId || '',
						storeName: grnItem?.storeName || '',
						vendorName: grnItem?.vendorName || '',
						fullfilledQuantity: grnItem?.id || 0,
						grnDate: grnItem?.createdAt || '',
						startTime: grnItem?.createdAt || '',
						endTime: grnItem?.closedAt || '',
						duration: grnItem?.id || '',
                        fullfilledQuantity: grnItem?.items?.reduce((acc, curr) => acc + Number(curr.fulfilled), 0),
			            totalQuantity: grnItem?.items?.reduce((acc, curr) => acc + Number(curr.qty), 0),
					};
				});
				setGrnData(grnItem?.items || []);
			}
		} catch (error) {
			console.error(error);
			notificationServices.generateNotification({
				type: 'error',
				message: 'Error fetching GRN details'
			});
		} finally {
			setIsloading(false);
		}
	};

	useEffect(() => {
		setTabValue('orderedProducts');
		if (id) {
			fetchGrnDetails(id);
		}
	}, [id]);

	const tabList = [
		{
			value: 'orderedProducts',
			label: 'Ordered Products',
		},
		{
			value: 'aiMachineGrnLogs',
			label: 'AI Supervisor Scans',
		},
	];

	const handleTabChange = (value) => {
		setTabValue(value);
		if(value === 'orderedProducts') {
			fetchGrnDetails(id);
		}else if(value === 'aiMachineGrnLogs') {
			handleAiMachineFetch(id);
		}
	};

	useEffect(() => {
		const qcData = listData?.[idx] ?? null;
		if (
			(keyPressed === 'ArrowLeft' || keyPressed === 'ArrowRight') &&
			qcData !== null
		) {
			const newListItem = keyPressHandler(
				keyPressed,
				listData,
				idx
			);
			if (newListItem) {
				history.push({
					pathname: `${urls.EXTENSION}${urls.GRN_DETAILS}`,
					search: `id=${newListItem?.id}&storeId=${storeId}&vendor=${vendor}&dateRangeFilter=${dateRangeFilter}`,
					state: {
						from: history?.location?.state?.from,
					},
				});
			}
		}
	}, [keyPressed]);

	const navigateQC = (e) => {
		const { dataset } = inputHelper(e);
		let newListItem = null;

		if (dataset.type === 'next') {
			newListItem = listData?.[idx + 1];
		} else {
			newListItem = listData?.[idx - 1];
		}

		if (newListItem) {
			history.push({
				pathname: `${urls.EXTENSION}${urls.GRN_DETAILS}`,
				search: `id=${newListItem?.id}&storeId=${storeId}&vendor=${vendor}&dateRangeFilter=${dateRangeFilter}`,
			});
		}
	};

	// Mappings feilds 
	const fieldMap = {
		'PO Id': 'clientPoId',
		'Vendor Name': 'vendorName',
		'Store Name': 'storeName',
		'Fullfilled Quantity': 'fullfilledQuantity',
		'GRN Date': 'grnDate',
		'Start Time': 'startTime',
		'End Time': 'endTime',
		'Duration': 'duration',
	};

	const specialFields = {
		'Fullfilled Quantity': (id, item) => {
            if(grnData?.length === 0) return '-';
			const percentage = (grnDetails.fullfilledQuantity / grnDetails.totalQuantity) * 100;
			return(
				<div>
					<p className='font-medium'>{percentage.toFixed(2)}%</p>
				</div>
			);
		},
		'GRN Date': (id, item) => {
			if (!item) return '-';
			try {
				const date = new Date(item);
				return(
					<div>
						<p className='font-medium'>{date.toLocaleDateString()}</p>
					</div>
				);
			} catch (error) {
				return '-';
			}
		},
		'Start Time': (id, item) => {
			if (!item) return '-';
			try {
				const date = new Date(item);
				return(
					<div>
						<p className='font-medium'>{date.toLocaleTimeString()}</p>
					</div>
				);
			} catch (error) {
				return '-';
			}
		},
		'End Time': (id, item) => {
			if (!item) return '-';
			try {
				const date = new Date(item);
				return(
					<div>
						<p className='font-medium'>{date.toLocaleTimeString()}</p>
					</div>
				);
			} catch (error) {
				return '-';
			}
		},
		'Duration': (id, item) => {
				const startDate = new Date(grnDetails.startTime);
				const endDate = new Date(grnDetails.endTime);
				const diffInMs = Number(endDate) - Number(startDate);
				if (isNaN(diffInMs)) return '-';

				const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
				const hours = Math.floor((diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
				const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));

				let durationText = '';
				if (days > 0) durationText += `${days}d `;
				if (hours > 0) durationText += `${hours}h `;
				if (minutes > 0) durationText += `${minutes}m`;
				
				return(
					<div>
						<p className='font-medium'>
							{durationText || '0m'}
						</p>
					</div>
				);
		},
	};

    const SPECIAL_ITEMS = {
        'Variant Name': (id, item) => {
            const displayName = grnData?.find((data) => data?.id === id);
            return (
                <div>
                    <p className='font-medium'>{displayName?.productName}({displayName?.variantName})</p>
                </div>
            );
        },
        'Unit': (id, item) => {
            return (
                <div>
                    <p className='font-medium'>{item}</p>
                </div>
            );
        },
        'PO Quantity': (id, item) => {
            return (
                <div>
                    <p className='font-medium'>{item ?? '-'}</p>
                </div>
            );
        },
        'GRN Quantity': (id, item) => {
            return (
                <div>
                    <p className='font-medium'>{item ?? '-'}</p>
                </div>
            );
        },
        'Fullfiled Quantity': (id, item) => {
            return (
                <div>
                    <p className='font-medium'>{item}</p>
                </div>
            );
        },
        'Batch Fullfiled': (id, item) => {
            try {
                const foundItem = grnData?.find((data) => data?.id === id);
                if (!foundItem || !foundItem.batches) {
                    return (
                        <div>
                            <p className='font-medium'>0/0</p>
                        </div>
                    );
                }
                
                const totalBatches = foundItem.batches.length;
                const passedBatches = foundItem.batches.filter(batch => batch?.status === 'GRN').length;
                
                return (
                    <div>
                        <p className='font-medium'>{passedBatches}/{totalBatches}</p>
                    </div>
                );
            } catch (error) {
                return (
                    <div>
                        <p className='font-medium'>0/0</p>
                    </div>
                );
            }
        },
        'Qc Passed': (id, item) => {
            try {
                const foundItem = grnData?.find((data) => data?.id === id);
                if (!foundItem || !foundItem.batches) {
                    return (
                        <div>
                            <p className='font-medium'>0/0</p>
                        </div>
                    );
                }
                
                const totalBatches = foundItem.batches.length;
                const passedBatches = foundItem.batches.filter(batch => batch?.status === 'GRN').length;
                
                return (
                    <div>
                        <p className='font-medium'>{passedBatches}/{totalBatches}</p>
                    </div>
                );
            } catch (error) {
                return (
                    <div>
                        <p className='font-medium'>0/0</p>
                    </div>
                );
            }
        },
        'View QC Logs': (id, item) => {
            const productId = grnData?.find((data) => data?.id === id)?.productId;
            return (
                <div>
                    <Chip
                        size='small' 
                        className=' w-[150px] p-[15px] font-bold'
                        label='View QC Logs'
                        onClick={() => {
                            history.push(`${urls.EXTENSION}${urls.QUALITY_LOGS}?tabValue=grnQcLogs&productId=${productId}&grnId=${grnDetails?.id}`);
                        }}
                    />
                </div>
            );
        },
    };
    // feilds
    const aiMachineColMap = {
        'Store': 'storeName',
        'QC Time': 'QCTime',
        'Result': 'result',
        'Order ID': 'OrderId',
        'Product Name': 'ProductName',
        'Weight': 'Weight',
        'Count': 'count',
        'Temp': 'Temp',
    };
    
    const aiMachineSpecialColumns = [
        {
            title: 'Store',
            config: {
                type: 'DATA',
            },
        },
        {
            title: 'QC Time',
            config: {
                type: 'Data',
            },
        },
        {
            title: 'Order ID',
            config: {
                type: 'Data',
            },
        },
        {
            title: 'Product Name',
            config: {
                type: 'Data',
            },
        },
        {
            title: 'Weight',
            config: {
                type: 'Data',
            },
        },
        {
            title: 'Count',
            config: {
                type: 'Data',
            },
        },
        {
            title: 'Temp',
            config: {
                type: 'Data',
            },
        },
        {
            title: 'Result',
            config: {
                type: 'Data',
            },
        },
    ];
    const aiMachineSpecialColumnsMappings = {
        'Store': (id, items) => {
            return <div>{items}</div>;
        },
        'QC Time': (id, items) => {
            return <div>{items}</div>;
        },
        'Order ID': (id, items) => {
            return <div>{items}</div>;
        },
        'Product Name': (id, items) => {
            return <div>{items}</div>;
        },
        'Weight': (id, items) => {
            return <div>{items}</div>;
        },
        'Count': (id, items) => {
            return <div>{items}</div>;
        },
        'Temp': (id, items) => {
            return <div>{items}</div>;
        },
        'Result': (id, items) => {
            return <div>{items}</div>;
        },
    };

	useEffect(() => {
		const searchParams = new URLSearchParams();
		searchParams.set('tabValue', tabValue);
		searchParams.set('id', id);
		searchParams.set('storeId', storeId);
		searchParams.set('vendor', vendor);
		searchParams.set('dateRangeFilter', dateRangeFilter);
		const search = searchParams.toString();
		history.replace({ search: search ? `?${search}` : "" });
	}, [tabValue]);

    useEffect(() => {
		const newQueryParams = {};
		if (query.get('tabValue')) {
			setTabValue(query.get('tabValue'));
			newQueryParams.tabValue = query.get('tabValue');
		}
		if (newQueryParams?.tabValue === 'aiMachineGrnLogs') {
			handleAiMachineFetch(id);
			setTabValue('aiMachineGrnLogs');
		}else {
			fetchGrnDetails(id);
		}
	}, [id]);

	return (
		<div className="p-4">
			<BackdropLoader showProgress isLoading={isLoading} />
			<GrnHeader 
				navigateQC={navigateQC} 
				idx={idx} 
				listData={listData} 
				id={id}
                storeId={storeId}
                vendor={vendor}
                dateRangeFilter={dateRangeFilter}
			/>
			{!isLoading && (
				<div className='relative top-[3rem]'>
					<Info 
						buttons={buttons}
						fieldMap={fieldMap}
						data={grnDetails}
						specialFields={specialFields}
					/>
					<Tab
						tabList={tabList}
						tabValue={tabValue}
						handleTabChange={handleTabChange}
					/>
                   {tabValue === 'orderedProducts' && 
                    <GrnTable
                        data={grnData}
                        colMap={COLMAP}
                        special={SPECIAL_COLUMNS}
                        specialMappings={SPECIAL_ITEMS}
                        tableType='orderedProducts'
                    />
                    }
                {tabValue === 'aiMachineGrnLogs' && 
                    <GrnTable
                        data={grnData}
                        colMap={aiMachineColMap}
                        special={aiMachineSpecialColumns}
                        specialMappings={aiMachineSpecialColumnsMappings}
                        tableType='aiMachineGrnLogs'
                    />
            }
				</div>
			)}
		</div>
	);
}

export default GRNDetailsPage;
