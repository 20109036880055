/* eslint-disable no-tabs */
import { connect } from 'react-redux';
import { useEffect } from 'react';
// import { ListTable } from '../../';
import {
	addCommas,
	classes,
	convertToHigherUnit,
	// higherUnits,
	useQuery,
} from '../../Helpers';
import styles from './BOMAnalysisListPage.module.scss';
import analyticsAction from '../../Actions/analytics.actions';
import { useAuth } from '../../Components/Auth/Login/auth';
import { ListTable } from '../../Components/ListTable';
// import { ArrangePages } from '../../../Components/Arrange Pages';

const TABLE_COLUMNS = [
	{
		title: 'Product Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'SKU Code',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'UY Variant ID',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Unit',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Category',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Sub Category',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'SKU Type',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Store',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Rate per Kg/Pc/L',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Ideal Rate per Kg/Pc/L',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Left Over T-1 Qty',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Issued Qty',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Left Over Qty (T)',
		config: {
			type: 'DATA',
		},
	},
	{
		// title: 'Material QTY (Actual)',
		title: 'Net Issued Qty',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Net Issued Cost',
		config: {
			type: 'DATA',
		},
	},
	{
		// title: 'Ideal Quantity',
		title: 'Food Cost Ideal Qty',
		config: {
			type: 'DATA',
		},
	},
	{
		// title: 'Ideal Cost',
		title: 'Ideal Food Cost',
		config: {
			type: 'DATA',
		},
	},
	{
		// Material QTY (Est.)
		title: 'Measured Inventory Consumed Qty',
		config: {
			type: 'DATA',
		},
	},
	{
		// title: 'Material QTY (Actual)',
		title: 'Gross Produced Qty',
		config: {
			type: 'DATA',
		},
	},
	{
		// Material QTY (Est.)
		title: 'Net Produced Qty',
		config: {
			type: 'DATA',
		},
	},
	{
		// Material COst (Est.)
		title: 'Measured Inventory Consumed Cost',
		config: {
			type: 'DATA',
		},
	},
	// {
	// 	// Material COst (Est.)
	// 	title: 'Net Produced Cost',
	// 	config: {
	// 		type: 'DATA',
	// 	},
	// },
	{
		title: 'Net Consumed Qty',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Net Consumed Cost',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Overfill / Underfill Qty',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Overfill / Underfill Cost',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Production Loss QTY (Est.)',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Production Loss (Est.)',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Excess Qty',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Excess Cost',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Wastage Qty',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Wastage Cost',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Total Cost',
		config: {
			type: 'DATA',
		},
	},
];

const COLMAP = {
	'Product Name': 'productName',
	Unit: 'unit',
	Category: 'productCategoryName',
	'Sub Category': 'productSubcategoryName',
	'SKU Type': 'skuType',
	Store: 'storeName',
	'Rate per Kg/Pc/L': 'idealRate',
	'Ideal Rate per Kg/Pc/L': 'None',
	'Total Cost': 'bom_ingredient',
	'Issued Qty': 'issuedQty',
	'Gross Produced Qty': 'originalQty',
	'Net Issued Qty': 'netIssuedQty',
	'Net Issued Cost': 'netIssuedCost',
	'Left Over Qty (T)': 'leftOverQty',
	'Left Over T-1 Qty': 'leftOverTM1Qty',
	'Food Cost Ideal Qty': 'idealQty',
	'Ideal Food Cost': 'idealInvConsumed',
	'Measured Inventory Consumed Qty': 'qty',
	'Net Produced Qty': 'qty',
	'Measured Inventory Consumed Cost': 'invConsumed',
	'Net Produced Cost': 'recipeCost',
	'Overfill / Underfill Qty': 'None',
	'Overfill / Underfill Cost': 'None',
	'Production Loss QTY (Est.)': 'productionLoss',
	'Production Loss (Est.)': 'productionLossCost',
	'Net Consumed Qty': 'None',
	'Net Consumed Cost': 'None',
	'Excess Qty': 'None',
	'Excess Cost': 'None',
	'Wastage Qty': 'None',
	'Wastage Cost': 'None',
	// 'Cost Category': 'costCategory',
};

const getRowStyles = (item) => {
	switch (item?.result) {
		default:
			return styles.normal;
	}
};

// function setAttr(text) {
// return <span className={styles.text}>{text}</span>;
// }

const BOMAnalysisListPage = (props) => {
	// const history = useHistory();
	const {
		data,
		setAnalyticsType,
		ANALYTICS_TYPE,
		analysisFilters,
		setAnalysisDashboardFilters,
	} = props;
	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs;
	const queryParams = useQuery();
	const isProduct = analysisFilters?.analysisType === 'BOM';
	// eslint-disable-next-line no-unused-vars
	const selectedFilterType = queryParams.get('filterType');
	// const { appConfig = {} } = useAuth();
	// const { measurement } = appConfig?.configs ?? {};
	// const listCount = data.length - 1;
	useEffect(() => {
		setAnalysisDashboardFilters(
			{
				...analysisFilters,
				analysisType: 'BOM_INGREDIENT',
			},
			false
		);
		setAnalyticsType(ANALYTICS_TYPE, {});
	}, []);

	const SPECIAL_ITEMS = {
		'Product Name': (_, obj) => {
			// eslint-disable-next-line prefer-template
			return (obj?.productName + (obj?.variantName ? ' (' + obj?.variantName + ')' : ''));
		},
		'SKU Code': (_, obj) => {
			return obj?.clientVariantId;
		},
		'UY Variant ID': (_, obj) => {
			return obj?.variantId;
		},
		Unit: (unitValue) => {
			return convertToHigherUnit(unitValue)?.unit;
		},
		'Rate per Kg/Pc/L': (value, obj) => {
			if (
				obj?.qty === null ||
				obj?.qty === undefined
			) {
				return '-';
			}
			if (isProduct) {
				return addCommas(obj?.productRate, measurement, true);
			}
			return addCommas(value, measurement, true);
		},
		'Ideal Rate per Kg/Pc/L': (value, obj) => {
			// if (
			// 	obj?.qty === null ||
			// 	obj?.qty === undefined
			// ) {
			// 	return '-';
			// }
			return addCommas(obj?.idealRate, measurement, true);
			// if (isProduct) {
			// eslint-disable-next-line no-mixed-operators
			// return addCommas(
			// 	(obj?.recipeCostIdeal *
			// 		obj?.priceFactor *
			// 		higherUnits?.[obj?.unit]?.divideBy) /
			// 		obj.qty,
			// 	measurement,
			// 	true
			// );
			// }
			// return '-';
		},
		'Left Over T-1 Qty': (leftOverTM1Qty, obj) => {
			// eslint-disable-next-line max-len
			return convertToHigherUnit(
				obj?.unit,
				leftOverTM1Qty ?? 0
			)?.value?.toFixed(2);
		},
		'Issued Qty': (issuedQty, obj) => {
			// eslint-disable-next-line max-len
			return convertToHigherUnit(
				obj?.unit,
				issuedQty ?? 0
			)?.value?.toFixed(2);
		},
		'Left Over Qty (T)': (leftOverTQty, obj) => {
			// eslint-disable-next-line max-len
			return convertToHigherUnit(
				obj?.unit,
				leftOverTQty ?? 0
			)?.value?.toFixed(2);
		},
		'Gross Produced Qty': (qty, obj) => {
			// eslint-disable-next-line max-len
			return convertToHigherUnit(
				obj?.unit,
				qty ?? 0
			)?.value;
		},
		'Net Issued Qty': (netIssuedQty, obj) => {
			// eslint-disable-next-line max-len
			return convertToHigherUnit(
				obj?.unit,
				netIssuedQty ?? 0
			)?.value?.toFixed(2);
		},
		'Net Issued Cost': (value) => {
			return addCommas(value, measurement, true);
		},
		'Food Cost Ideal Qty': (idealQty, obj) => {
			// eslint-disable-next-line max-len
			return convertToHigherUnit(
				obj?.unit,
				idealQty ?? 0
			)?.value?.toFixed(2);
		},
		'Ideal Food Cost': (value, obj) => {
			if (isProduct) {
				if (obj?.bomRecipeCostIdeal < 0) {
					return addCommas(0, measurement);
				}
				return addCommas(obj?.bomRecipeCostIdeal, measurement, true);
			}
			return addCommas(value, measurement, true);
		},
		'Measured Inventory Consumed Qty': (value, obj) => {
			return convertToHigherUnit(obj?.unit, value ?? 0)?.value?.toFixed(
				2
			);
		},
		'Net Produced Qty': (value, obj) => {
			if (value < 0) {
				return addCommas(0, measurement);
			}
			if (obj?.unit === 'count') {
				return addCommas(value, measurement);
			}
			return convertToHigherUnit(obj?.unit, value ?? 0)?.value?.toFixed(
				2
			);
		},
		'Measured Inventory Consumed Cost': (value) => {
			return addCommas(value, measurement, true);
		},
		// 'Net Produced Cost': (value, obj) => {
		// 	if (value < 0) {
		// 		return addCommas(0, measurement);
		// 	}
		// 	if (isProduct) {
		// 		return addCommas(obj?.bomMeasuredCost, measurement, true);
		// 	}
		// 	return addCommas(value, measurement, true);
		// },
		'Net Consumed Qty': (_, obj) => {
			const value = obj?.originalQty - obj?.qty;
			if (value < 0) {
				return addCommas(0, measurement);
			}
			return convertToHigherUnit(obj?.unit, value)?.value?.toFixed(2);
		},
		'Net Consumed Cost': (_, obj) => {
			const value = (obj?.bomMeasuredCost * (obj?.originalQty - obj?.qty)) / (obj?.qty || 0);
			if (value < 0) {
				return addCommas(0, measurement);
			}
			return addCommas(value, measurement, true);
		},
		'Overfill / Underfill Qty': (_, obj) => {
			// eslint-disable-next-line max-len
			return convertToHigherUnit(
				obj?.unit,
				(obj.qty ?? 0) - (obj.idealQty ?? 0)
			)?.value?.toFixed(2);
		},
		'Overfill / Underfill Cost': (_, obj) => {
			if (isProduct) {
				return addCommas(
					obj.underOverfillCost,
					measurement,
					true
				);
			}
			return addCommas(
				obj.invConsumed - obj.idealInvConsumed,
				measurement,
				true
			);
		},
		'Wastage Qty': (_, obj) => {
			return convertToHigherUnit(obj?.unit, obj.wasteQty)?.value ?? 0;
		},
		'Wastage Cost': (_, obj) => {
			return addCommas(obj.wasteCost ?? 0, measurement, true);
		},
		'Excess Qty': (_, obj) => {
			let val = obj.qty - obj.netIssuedQty;
			if (val < 0) val = 0;
			return convertToHigherUnit(obj?.unit, val)?.value?.toFixed(2);
		},
		'Production Loss QTY (Est.)': (lossQty, obj) => {
			if (lossQty < 0) {
				return 0;
			}
			return convertToHigherUnit(obj?.unit, lossQty)?.value?.toFixed(2);
		},
		'Excess Cost': (_, obj) => {
			let val = obj.invConsumed - obj.netIssuedCost;
			if (val < 0) val = 0;
			return addCommas(val, measurement, true);
		},
		'Production Loss (Est.)': (value) => {
			if (value < 0) return 0;
			return addCommas(value, measurement, true);
		},
		'Total Cost': (value, obj) => {
			if (isProduct) {
				return addCommas(
					obj?.bom,
					measurement,
					true
				);
			}
			return addCommas(value, measurement, true);
		},
		'Inventory Issued Cost': (value) => {
			return addCommas(value, measurement, true);
		},
		Wastage: (value) => {
			return addCommas(value, measurement, true);
		},
	};

	const exludedColumns = [
		// 'Left Over T-1 Qty',
		'Issued Qty',
		// 'Left Over Qty (T)',
		'Net Issued Qty',
		'Net Issued Cost',
		'Food Cost Ideal Qty',
		'Production Loss QTY (Est.)',
		'Excess Qty',
		'Excess Cost',
		'Overfill / Underfill Qty',
		'Measured Inventory Consumed Qty',
		'Measured Inventory Consumed Cost',
	];

	return (
		<div className={classes(styles.root, 'list-page-root')}>
			<ListTable
				fixedHeaderTop={0}
				hasDateRange
				showTotal
				colMap={COLMAP}
				special={SPECIAL_ITEMS}
				data={data?.map((obj) => {
					const rowStyles = getRowStyles(obj);
					// const itemId = get(obj, COLMAP['Item Id']);
					// const qc = get(obj, COLMAP.qc);
					return {
						...obj,
						rowStyles,
					};
				})}
				columns={[...TABLE_COLUMNS]?.filter((ele) => {
					if (!isProduct) {
						return ![
							// 'Net Produced Cost',
							'Gross Produced Qty', 'Net Produced Qty', 'Ideal Rate per Kg/Pc/L', 'Net Consumed Qty', 'Net Consumed Cost']?.includes(
							ele?.title
						);
					}
					return !exludedColumns?.includes(ele?.title);
				})}
				actions={[]}
			/>
			{/* <ArrangePages
				listCount={61}
				ANALYTICS_TYPE={ANALYTICS_TYPE}
				setAnalyticsType={setAnalyticsType}
			/> */}
		</div>
	);
};

const mapStateToProps = (state) => {
	const { ANALYSIS_DASHBOARD_FILTERS } = state.analytics;
	return {
		analysisFilters: ANALYSIS_DASHBOARD_FILTERS,
	};
};
const mapDispatchToProps = {
	setAnalyticsType: analyticsAction.setAnalyticsType,
	setAnalysisDashboardFilters: analyticsAction.setAnalysisDashboardFilters,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(BOMAnalysisListPage);
