import { endpoints } from '@/Constants';
import { authHeader, handleResponse } from '@/Helpers';

const aiPackaging = {
	getData,
	aiPacakgingExport,
};
function getData(req = {}) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	return fetch(endpoints?.AI_PACKGING, requestOptions).then(handleResponse);
}
function aiPacakgingExport(req = {}) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};
	return fetch(endpoints?.AI_PACKGING_Export, requestOptions);
}
export default aiPackaging;
