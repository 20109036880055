/* eslint-disable */
import { useState } from 'react';
import { connect } from "react-redux";
import { RefreshCcw, X } from 'lucide-react';
import BaseModal from "../BaseModal/BaseModal";
import { modalActions } from "@/Actions";

function QcApprovalModal(props) {
    const { show, data, toggleQualityApprovalModal } = props;
    const { storeName, vendorName} = data ?? {};
    const [qcId, setQcId] = useState(null);
    
    const handleClose = () => {
        setQcId(null);
        toggleQualityApprovalModal(false);
    };

    const handleApprove = () => {
        if(data?.handleConfirm) {
            data?.handleConfirm(qcId);
        }
        handleClose();
    }

    const handleAutoGenerate = () => {
        const storeCode = storeName?.slice(0, 3).toUpperCase() || 'STR';
        const vendorCode = vendorName?.slice(0, 3).toUpperCase() || 'VEN';

      const date = new Date();
      const dateStr =
        date.getDate().toString().padStart(2, '0') +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        date.getFullYear();
 
      // Generate random 4 digits
      const randomDigits = Math.floor(Math.random() * 9000 + 1000);

      const newQcId = `${storeCode}-${vendorCode}-${dateStr}-${randomDigits}`;
      setQcId(newQcId);
    };

    const renderBtns = () => {
        return (
            <div className="flex w-full justify-end ">
                <button 
                    onClick={handleClose} 
                    className="w-1/2 px-4 py-2 border border-gray-300 rounded-md text-gray-600 hover:bg-gray-100"
                >
                    Cancel
                </button>
                <button 
                    onClick={handleApprove} 
                    className="w-1/2 px-4 py-2 bg-yellow-500 text-black rounded-md hover:bg-yellow-600 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                    Approve QC
                </button>
            </div>
        )
    }
    return (
        <BaseModal
            open={show}
            handleClose={handleClose}
            renderBtns={renderBtns}
            height={data?.height ?? 'fit-content'}
            width='400px'
        >
            <div className='p-6 space-y-4'>
                <div className='h-auto w-auto flex justify-between items-start gap-4'>
                    <h2 className="text-xl font-semibold">Enter QC ID to complete.</h2>
                    <div
                        onClick={handleAutoGenerate} 
                        className="flex justify-center items-center gap-2 text-blue-600 bg-blue-200 hover:bg-slate-200 rounded-md p-1"
                    >
                       <RefreshCcw className='w-4 h-4' />  Auto Generate
                    </div>
                </div>
                
                <input 
                    type="text" 
                    value={qcId}
                    onChange={(e) => setQcId(e.target.value)}
                    placeholder="Enter QC ID"
                    className="w-full border border-gray-300 rounded-md p-2"
                />
            </div>
        </BaseModal>
    );
}   

const mapStateToProps = (state) => {
    const { qualityApprovalModal = { show: false, data: {} } } = state.modal;
    return {
        show: qualityApprovalModal.show,
        data: qualityApprovalModal.data,
    };
};

const mapDispatchToProps = {
    toggleQualityApprovalModal: modalActions.toggleQualityApprovalModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(QcApprovalModal);
