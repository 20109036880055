/* eslint-disable no-tabs */
// import { cloneDeep } from 'lodash';
import { cloneDeep } from 'lodash';
import moment from 'moment';

const inputsList = [
	{
		value: null,
		handleSelection: () => {},
		id: 'fromStore',
		name: 'fromStore',
		inputLabel: 'Select Store',
		labelText: 'Delivering Store',
		mandatory: false,
		disabled: true,
		optionsList: [],
		inputType: 'options_search',
		hideAddNew: true,
	},
	{
		value: null,
		handleSelection: () => {},
		id: 'toStore',
		name: 'toStore',
		inputLabel: 'Select store',
		labelText: 'Receiving store',
		mandatory: false,
		disabled: true,
		optionsList: [],
		inputType: 'options_search',
		hideAddNew: true,
	},
	// {
	// 	value: null,
	// 	onChange: () => {},
	// 	id: 'userGstin',
	// 	name: 'userGstin',
	// 	inputLabel: 'User GSTIN',
	// 	labelText: 'User GSTIN',
	// 	mandatory: true,
	// },
	// [
	// 	{
	// 		value: null,
	// 		onChange: () => {},
	// 		id: 'supplyType',
	// 		name: 'supplyType',
	// 		inputLabel: 'supply Type',
	// 		labelText: 'supply Type',
	// 		mandatory: true,
	// 	},
	// 	{
	// 		value: null,
	// 		onChange: () => {},
	// 		id: 'subSupplyType',
	// 		name: 'subSupplyType',
	// 		inputLabel: 'sub supply Type',
	// 		labelText: 'sub supply Type',
	// 		mandatory: true,
	// 	},
	// ],

	// {
	// 	value: null,
	// 	onChange: () => {},
	// 	id: 'docType',
	// 	name: 'docType',
	// 	inputLabel: 'Document Type',
	// 	labelText: 'Document Type',
	// 	mandatory: true,
	// },
	// [
	// 	{
	// 		value: null,
	// 		onChange: () => {},
	// 		id: 'docNo',
	// 		name: 'docNo',
	// 		inputLabel: 'Document No',
	// 		labelText: 'Document No',
	// 		mandatory: true,
	// 	},
	// 	{
	// 		value: null,
	// 		onChange: () => {},
	// 		id: 'docDate',
	// 		name: 'docDate',
	// 		inputLabel: 'Document date',
	// 		labelText: 'Document date',
	// 		type: 'dateSelector',
	// 		mandatory: true,
	// 	},
	// ],
	// [
	// 	{
	// 		value: null,
	// 		onChange: () => {},
	// 		id: 'transType',
	// 		name: 'transType',
	// 		inputLabel: 'Transport Type',
	// 		labelText: 'Transport Type',
	// 		mandatory: true,
	// 	},
	// 	{
	// 		value: null,
	// 		onChange: () => {},
	// 		id: 'transMode',
	// 		name: 'transMode',
	// 		inputLabel: 'Transport Mode',
	// 		labelText: 'Transport Mode',
	// 		mandatory: true,
	// 	},
	// ],
	[
		{
			value: null,
			onChange: () => {},
			id: 'transporterName',
			name: 'transporterName',
			inputLabel: 'Transporter Name',
			labelText: 'Transporter Name',
			mandatory: true,
		},
		{
			value: null,
			onChange: () => {},
			id: 'transporterId',
			name: 'transporterId',
			inputLabel: 'Transporter GSTIN',
			labelText: 'Transporter GSTIN',
			mandatory: true,
		},
	],
	{
		value: null,
		onChange: () => {},
		id: 'transDistance',
		name: 'transDistance',
		inputLabel: 'Distance',
		prefix: 'Km',
		labelText: 'Distance',
		type: 'number',
		mandatory: true,
	},
	{
		value: null,
		onChange: () => {},
		id: 'vehicleNo',
		name: 'vehicleNo',
		inputLabel: 'Vehicle No',
		labelText: 'Vehicle No',
		mandatory: true,
	},
	{
		value: null,
		onChange: () => {},
		id: 'transDocNumber',
		name: 'transDocNumber',
		inputLabel: 'Transporter Doc No',
		labelText: 'Transporter Doc No',
		mandatory: false,
	},
	{
		value: null,
		onChange: () => {},
		id: 'transDocDate',
		name: 'transDocDate',
		inputLabel: 'Transporter Doc Date',
		labelText: 'Transporter Doc Date',
		inputType: 'dateSelector',
		mandatory: false,
	},
	// {
	// 	value: null,
	// 	onChange: () => {},
	// 	id: 'vehicleType',
	// 	name: 'vehicleType',
	// 	inputLabel: 'vehicle Type',
	// 	labelText: 'vehicle Type',
	// 	mandatory: true,
	// },
];

const initialState = {
	userGstin: null,
	supplyType: null,
	subSupplyType: 1,
	docType: null,
	docNo: null,
	docDate: null,
	transType: 1,
	fromGstin: null,
	fromTrdName: null,
	fromAddr1: null,
	fromAddr2: null,
	fromPlace: null,
	fromPincode: null,
	fromStateCode: null,
	actualFromStateCode: null,
	toGstin: null,
	toTrdName: null,
	toAddr1: null,
	toAddr2: null,
	toPlace: null,
	toPincode: null,
	toStateCode: null,
	actualToStateCode: null,
	totalValue: null,
	cgstValue: null,
	sgstValue: null,
	igstValue: null,
	cessValue: null,
	totInvValue: null,
	transMode: null,
	transDistance: 0,
	transporterName: null,
	transporterId: null,
	transDocNumber: null,
	transDocDate: null,
	vehicleNo: null,
	vehicleType: null,
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_VALUE':
			return {
				...state,
				...action.payload,
			};
		case 'INIT':
			return {
				...initialState,
			};
		default:
			return state;
	}
};

const config = {
	inputsList,
	initialState,
	reducer,
	serializer: (data) => {
		return data;
		// const newState = {
		// machineName: data?.machine_name,
		// macAddress: data?.mac,
		// store: data?.storeId,
		// process: data?.processId,
		// manufacturedOn: data?.manufactured_on
		// ? new Date(data?.manufactured_on)
		// : null,
		// installedOn: data?.installed_on
		// ? new Date(data?.installed_on)
		// : null,
		// expiresOn: data?.warranty_expires_on
		// ? new Date(data?.warranty_expires_on)
		// : null,
		// serialNo: data?.serial_number,
		// modelNo: data?.model_number,
		// };
		// return newState;
	},
	deserializer: (state) => {
		const clonedState = cloneDeep(state);
		const { ...remaining } = clonedState;
		const newState = {
			transactionDate: moment(remaining?.creditingDate)?.format(
				'M/D/YYYY'
			),
			dueDate: moment(remaining?.dueDate)?.format('M/D/YYYY'),
			storeId: remaining?.salesReturnId
				? remaining?.fromStore?.id
				: remaining?.store?.id,
			fromStore: remaining?.salesReturnId
				? remaining?.store?.id
				: remaining?.fromStore?.id,
			parentId: remaining?.billId ?? remaining?.salesReturnId,
			clientTransactionId: `CN-${remaining?.creditId}`,
			orderId: remaining?.orderId,
			vendorId: remaining?.vendor?.id,
			vendorName: remaining?.vendor?.name,
			items: remaining?.items?.map((ele) => {
				return {
					productId: ele?.productId,
					variantId: ele?.selectedVariant?.id,
					qty: Number(ele?.quantity),
					price: Number(ele?.selectedVariant?.price),
					tax: ele?.tax,
					subTotal: ele?.subTotal,
					totalTax: ele?.totalTax,
					finalAmount: ele?.totalPriceIncTax,
				};
			}),
		};
		return newState;
	},
};

export default config;
