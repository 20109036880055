/* eslint-disable*/
import { connect } from 'react-redux';
import { useState } from 'react';
import { modalActions } from '../../../Actions';
import { BaseModal } from '../BaseModal';
import { Button } from '@/Components/ui/button';
import { Input } from '@/Components/ui/input';
import { Select, Switch, MenuItem } from '@mui/material';
import { Calculator, Calendar, ListTodo, Text } from 'lucide-react';
import { notificationServices } from '@/Services';
import masterMenuServices from '@/Services/ProductSpecificationParamServices/productMasterMenu.services';

const AddParameterModal = (props) => {
    const { show, toggleModal, data = {}, settrueFetchProductParameters } = props;
    const [parameters, setParameters] = useState({
        name: '',
        inputType: '',
        unit: '',
        imageRequired: false,
        rangeFlag: false,
    });

    const handleClose = () => {
        setParameters({
            name: '',
            inputType: '',
            unit: '',
            imageRequired: false,
            rangeFlag: false,
        });
        toggleModal(false);
    };

    const handleInput = (e) => {
        const { name, value, checked } = e.target;
        if (name === 'imageRequired' || name === 'rangeFlag') {
            setParameters({
                ...parameters,
                [name]: checked
            });
        } else {
            setParameters({
                ...parameters,
                [name]: value
            });
        }
    };

    const handleSave = async () => {
        if (!parameters.name) {
            notificationServices?.generateNotification({
                type: 'error',
                message: 'Please enter the parameter name',
            });
            return;
        }
        if (!parameters.inputType) {
            notificationServices?.generateNotification({
                type: 'error',
                message: 'Please select the type of parameter',
            });
            return;
        }
        if ((parameters.inputType === 'number' || parameters.inputType === 'decimal') && !parameters.rangeFlag) {
            notificationServices?.generateNotification({
                type: 'error',
                message: 'Please select the range of parameter',
            });
            return;
        }

        try {
            const response = await masterMenuServices.addProductMasterMenu(parameters);
            if (response?.statusCode === 200) {
                notificationServices?.generateNotification({
                    type: 'success',
                    message: 'Product Specification Parameter added successfully',
                });
                settrueFetchProductParameters(true);
                handleClose();
            } else {
                notificationServices?.generateNotification({
                    type: 'error',
                    message: response?.errorMessage || 'Something went wrong',
                });
            }
        } catch (error) {
            notificationServices?.generateNotification({
                type: 'error',
                message: error || 'Something went wrong',
            });
        }
    };

    const renderBtns = () => {
        return (
            <div className='w-full flex justify-center gap-2'>
                <Button className='flex-1' variant='secondary' onClick={handleClose}>Cancel</Button>
                <Button 
                    className='flex-1 primary-btn' 
                    onClick={handleSave}
                >
                    Save
                </Button>
            </div>
        );
    };

    return (
        <BaseModal
            open={show}
            handleClose={handleClose}
            heading='Add Quality Parameter'
            renderBtns={renderBtns}
            height='400px'
        >
                <div className='space-y-4 p-2'>
                    <div className='flex items-center'>
                        <label className='w-1/3 text-left'>
                            Parameter Name <span className='text-red-500'>*</span>
                        </label>
                        <Input
                            value={parameters.name}
                            onChange={handleInput}
                            name='name'
                            placeholder='Enter parameter name'
                            className='w-2/3'
                            fullWidth
                        />
                    </div>

                    <div className='flex items-center'>
                        <label className='w-1/3 text-left'>
                            Input Type <span className='text-red-500'>*</span>
                        </label>
                        <Select
                            value={parameters.inputType}
                            onChange={handleInput}
                            name='inputType'
                            className='w-2/3 h-10 border-none'
                            fullWidth
                        >
                            <MenuItem value='text'>Text</MenuItem>
                            <MenuItem value='number'>Number</MenuItem>
                            <MenuItem value='date'>Date</MenuItem>
                            <MenuItem value='boolean'>Boolean</MenuItem>
                            <MenuItem value='decimal'>Decimal</MenuItem>
                        </Select>
                    </div>

                    <div className='flex items-center'>
                        <label className='w-1/3 text-left'>Unit</label>
                        <Input
                            value={parameters.unit}
                            onChange={handleInput}
                            name='unit'
                            placeholder='Enter unit'
                            className='w-2/3'
                            fullWidth
                        />
                    </div>

                    <div className='flex items-center'>
                        <label className='w-1/3 text-left'>Image Required</label>
                        <div className='w-2/3 flex justify-start'>
                            <Switch
                                checked={parameters.imageRequired}
                                onChange={handleInput}
                                name='imageRequired'
                                size='small'
                                sx={{
                                    '& .MuiSwitch-switchBase.Mui-checked': {
                                        color: '#000000',
                                    },
                                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                        backgroundColor: '#000000',
                                    }
                                }}
                            />
                        </div>
                    </div>

                    {(parameters.inputType === 'number' || parameters.inputType === 'decimal') && (
                        <div className='flex items-center'>
                            <label className='w-1/3 text-left'>
                                Range <span className='text-red-500'>*</span>
                            </label>
                            <div className='w-2/3 flex justify-start'>
                                <Switch
                                    checked={parameters.rangeFlag}
                                    onChange={handleInput}
                                    name='rangeFlag'
                                    size='small'
                                    sx={{
                                        '& .MuiSwitch-switchBase.Mui-checked': {
                                            color: '#000000',
                                        },
                                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                            backgroundColor: '#000000',
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
        </BaseModal>
    );
};

const mapStateToProps = (state) => {
    const { show, data } = state.modal.addQualityParameterModal;
    return {
        show,
        data,
    };
};

const mapDispatchToProps = {
    toggleModal: modalActions.toggleAddQualityParameterModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddParameterModal);
