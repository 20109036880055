// eslint-disable-next-line object-curly-newline
import { Route, Switch, useRouteMatch } from 'react-router';
import { urls } from '../../Constants';
import AllProductRoutes from './Product Form Routes/productform.routes';
import ComboRoutes from './Combo Routes/combo.routes';
import ProductCategoryRoutes from './ProductCategory Routes/product.category.routes';
import CustomizationRoutes from './Customization Routes/customization.routes';
import VariantsRoutes from './Variant Routes/variant.routes';
import SubscriptionRoutes from './Subscription Routes/subscription.routes';
import NutritionalRoutes from './Nutritional Routes/nutritional.routes';
import VariantsThresholdRoutes from './Variant Threshold Routes/variantThreshold.routes';
import ProductSubCategoryRoutes from './ProductSubCategory Routes/product.subCategory.routes';
import SpecificationParameters from '@/Pages/Food Products/SpecParameters/specificationParameters';

const getSubURL = (entity) => {
	const baseEntity = urls.FOOD_PRODUCTS;
	return entity.slice(baseEntity.length);
};

const SEARCH_BY = {
	options: [
		{
			key: 'NAME',
			title: 'Name',
			get: 'productName',
		},
		{
			key: 'BRAND',
			title: 'Brand',
			get: 'brandName',
		},
	],
};

const ProductRoutes = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route path={`${path}${getSubURL(urls.COMBOS)}`}>
				<ComboRoutes searchBy={SEARCH_BY} />
			</Route>
			<Route path={`${path}${getSubURL(urls.PRODUCT_CATEGORY)}`}>
				<ProductCategoryRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.PRODUCT_SUB_CATEGORY)}`}>
				<ProductSubCategoryRoutes />
			</Route>
			<Route path={`${path}${getSubURL(urls.CUSTOMIZATIONS)}`}>
				<CustomizationRoutes searchBy={SEARCH_BY} />
			</Route>
			<Route path={`${path}${getSubURL(urls.PRODUCT_SPECIFICATION_PARAMETRS)}`}>
				<SpecificationParameters />
			</Route>
			<Route path={`${path}${getSubURL(urls.NUTRITIONAL_INFO)}`}>
				<NutritionalRoutes searchBy={SEARCH_BY} />
			</Route>
			<Route path={`${path}${getSubURL(urls.ALL_PRODUCTS)}`}>
				<AllProductRoutes searchBy={SEARCH_BY} />
			</Route>
			<Route path={`${path}${getSubURL(urls.VARIANTS)}`}>
				<VariantsRoutes
					searchBy={{
						options: [
							{
								key: 'NAME',
								title: 'Name',
								get: 'productName',
							},
							{
								key: 'CLIENT_VARIANT_ID',
								title: 'Client Variant Id',
								get: 'variants.clientVariantId',
							},
						],
					}}
				/>
			</Route>
			<Route path={`${path}${getSubURL(urls.VARIANTS_THRESHOLD)}`}>
				<VariantsThresholdRoutes searchBy={SEARCH_BY} />
			</Route>
			<Route path={`${path}${getSubURL(urls.SUBSCRIPTION)}`}>
				<SubscriptionRoutes searchBy={SEARCH_BY} />
			</Route>
		</Switch>
	);
};
export default ProductRoutes;
