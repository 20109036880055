/* eslint-disable */
import { getDate, getWeekOfMonth } from 'date-fns';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { analyticsConstants } from '../Constants';
import { timeFilterMap } from '../Constants/analytics.constansts';
import analyticsTypeConstant from '../Constants/analyticsType.contants';
import {
	getMonthNumber, handleResponseStreamDownload, skuTypesList
} from '../Helpers';
import { notificationServices } from '../Services';
import analyticsServices from '../Services/analytics.services';
import dashboardAnalyticsService from '../Services/DashbaordAnalyticsServices/dashboardAnalytics.services';
import invAnalyticsServices from '@/Services/Analytics Services/invAnalytics.services';
import auditAnalyticsServices from '@/Services/Analytics Services/auditAnalytics.services';
import transitAnalyticsServices from '@/Services/Analytics Services/transitAnalytics.services';
import commonAnalyticsServices from '@/Services/Analytics Services/common-analytics.services';
import cookingAnalyticsServices from '@/Services/Analytics Services/cookingAnalytics.services';

const getQcType = (analyticsType) => {
	if (analyticsType === analyticsTypeConstant?.AI_OUTWARDING_ANALYSIS_LIST_DATA) {
		return 'OUTWARD';
	}
	if (analyticsType === analyticsTypeConstant?.AI_DISPATCH_ANALYSIS_LIST_DATA) {
		return 'DISPATCH';
	}
	if (analyticsType === analyticsTypeConstant?.AI_PRODUCTION_ANALYSIS_LIST_DATA) {
		return 'PRODUCTION';
	}
	if (analyticsType === analyticsTypeConstant?.AI_GRN_ANALYSIS_LIST_DATA) {
		return 'INWARD';
	}
	if (analyticsType === analyticsTypeConstant?.AI_LEFT_OVER_INVENTORY_ANALYSIS_LIST_DATA) {
		return 'LEFT_OVER';
	}
	return '';
};

const analyticsAction = {
	gateway,
	setGeoData,
	setBrandFilterData,
	setTimeFilterData,
	setDateRangeFilter,
	setPodFilter,
	setKitchenFilter,
	// setMealName,
	setMealType,
	setSingleDateFilter,
	setStoreFilter,
	setProcessTypeFilter,
	setSkuTypeFilter,
	setFromStoreFilter,
	fetchData,
	applyDateFilter,
	setAnalyticsType,
	resetFilters,
	applyFilters,
	fetchDataRange,
	setSelectedListId,
	setQCSearchResults,
	setCogsSearchResults,
	setBomSearchResults,
	updateQCList,
	setIMSSearchResults,
	updateIMSList,
	updateWastageImagesList,
	updateDefectImagesList,
	updateProductImagesList,
	updateOutwardingImagesList,
	updateWastageComponentsList,
	resetDefectImages,
	resetProductImages,
	resetOutwardingImages,
	resetImages,
	updateImagesList,
	resetWastageImages,
	resetCookingImages,
	resetWastageComponents,
	resetContainerImages,
	updateContainerImagesList,
	resetDigitTemperatureImages,
	updateTemperatureDigitImagesList,
	resetTemperatureImages,
	updateTemperatureImagesList,
	resetInventoryAnalyticsState,
	setTypeFilter,
	setAnalysisDashboardFilters,
	exportInvAnalytics,
	exportVarianceAnalytics,
	exportTransitAnalytics,
	exportAiProductionAnalytics,
	exportAiGrnAnalytics,
	exportAiCookingAnalytics,
	exportAiQcHistoryAnalytics,
	exportAiLeftOverInventoryAnalytics,
	// filterStockInventory,
};
function gateway({ entity, req, method = null, batch = [] }) {
	return (dispatch, getState) => {
		const { showInternalData } = getState()?.navigation;
		const notificationId = notificationServices.generateNotification({
			type: 'loading',
			message: 'loading...',
		});
		dispatch(request(req));
		let batchedCalls = [];
		if (batch.length === 0) {
			batch.push({
				entity,
				req: {
					...req,
					...(req?.data && {
						data: {
							...req?.data,
							showInternalData,
						},
					}),
				},
				method,
			});
			batchedCalls.push(
				analyticsServices.gateway({
					entity,
					req: {
						...req,
						...(req?.data && {
							data: {
								...req?.data,
								showInternalData,
							},
						}),
					},
					method,
				})
			);
		} else {
			batchedCalls = batch.map((apiReq) => {
				return analyticsServices.gateway(apiReq);
			});
		}
		Promise.allSettled(batchedCalls).then((values) => {
			values.forEach((response, idx) => {
				try {
					const clonedState = cloneDeep(getState().analytics);
					const { entity: currEntity, method: currMethod } =
						batch[idx];
					if (response.status === 'rejected') {
						throw response.reason;
					}

					const { value: res } = response;
					const {
						message = null,
						data,
						responseMessage = null,
						status = null,
					} = res;
					if (currMethod) {
						let sideEffectState = {};
						if (
							currEntity === 'QUALITY_ANALYTICS' &&
							currMethod === 'LIST_DATA'
						) {
							sideEffectState = {
								SEARCH_DATA: {
									list: data,
									query: '',
									searchBy: 'ORDER_ID',
								},
							};
						} else if (
							currEntity === 'INVENTORY_ANALYTICS' &&
							currMethod === 'LIST_DATA'
						) {
							sideEffectState = {
								SEARCH_DATA: {
									list: data,
									query: '',
									searchBy: 'QR_CODE',
								},
							};
						} else if (
							currEntity === 'COOKING_ANALYTICS' &&
							currMethod === 'LIST_DATA'
						) {
							sideEffectState = {
								SEARCH_DATA: {
									list: data,
									query: '',
									searchBy: 'PRODUCT_NAME',
								},
							};
						} else if (
							currEntity === 'ORDER_HISTORY' &&
							currMethod === 'LIST_DATA'
						) {
							sideEffectState = {
								SEARCH_DATA: {
									list: data,
									query: '',
									searchBy: 'ORDER_ID',
								},
							};
						} else if (
							currEntity === 'WASTAGE_HISTORY' &&
							currMethod === 'LIST_DATA'
						) {
							sideEffectState = {
								SEARCH_DATA: {
									list: data,
									query: '',
									searchBy: 'ORDER_ID',
								},
							};
						} else if (
							currEntity === 'SUBSCRIPTIONS_HISTORY' &&
							currMethod === 'LIST_DATA'
						) {
							sideEffectState = {
								SEARCH_DATA: {
									list: data,
									query: '',
									searchBy: 'ORDER_ID',
								},
							};
						} else if (
							currEntity === 'AI_OUTWARDING_ANALYSIS' &&
							currMethod === 'LIST_DATA'
						) {
							sideEffectState = {
								SEARCH_DATA: {
									list: data,
									query: '',
									searchBy: 'ORDER_ID',
								},
							};
						} else if (
							currEntity === 'AI_GRN_ANALYSIS' &&
							currMethod === 'LIST_DATA'
						) {
							sideEffectState = {
								SEARCH_DATA: {
									list: data,
									query: '',
									searchBy: 'ORDER_ID',
								},
							};
						}
						dispatch(
							success({
								[currEntity]: {
									...clonedState[currEntity],
									[currMethod]: data,
									...sideEffectState,
								},
							})
						);
					} else {
						dispatch(
							success({
								[currEntity]: data,
							})
						);
					}
					notificationServices.generateNotification({
						type: 'success',
						message: message ?? responseMessage ?? status,
						config: {
							id: notificationId,
						},
					});
				} catch (error) {
					notificationServices.generateNotification({
						type: 'error',
						message: error?.message ?? error,
						config: {
							id: notificationId,
						},
					});
					dispatch(failure({}));
				}
			});
		});
	};
}
function request(payload) {
	return {
		type: analyticsConstants.FETCH_ANALYTICS_REQUEST,
		payload,
	};
}
function success(payload) {
	return {
		type: analyticsConstants.FETCH_ANALYTICS_SUCCESS,
		payload,
	};
}
function failure(payload) {
	return {
		type: analyticsConstants.FETCH_ANALYTICS_FAILURE,
		payload,
	};
}
function setGeoData(payload) {
	return {
		type: analyticsConstants.SET_GEO_FILTER_DATA,
		payload,
	};
}
function setBrandFilterData(payload) {
	return {
		type: analyticsConstants.SET_BRAND_FILTER_DATA,
		payload,
	};
}
function setStoreFilter(payload) {
	return {
		type: analyticsConstants.SET_STORE_FILTER,
		payload,
	};
}
function setProcessTypeFilter(payload) {
	return {
		type: analyticsConstants.SET_PROCESS_TYPE_FILTER,
		payload,
	};
}
function setSkuTypeFilter(payload) {
	return {
		type: analyticsConstants.SET_SKU_TYPE_FILTER,
		payload,
	};
}
function setFromStoreFilter(payload) {
	return {
		type: analyticsConstants.SET_FROM_STORE_FILTER,
		payload,
	};
}
function setTimeFilterData(payload) {
	return {
		type: analyticsConstants.SET_TIME_FILTER_DATA,
		payload,
	};
}
function setTypeFilter(payload) {
	return {
		type: analyticsConstants.SET_GROUP_TYPE_FILTER,
		payload,
	};
}

function setDateRangeFilter(payload, entity = null, method = null) {
	return (dispatch) => {
		dispatch({
			type: analyticsConstants.SET_DATE_RANGE_FILTER,
			payload,
		});
		if (entity) {
			dispatch(fetchDataRange(entity, method));
		}
	};
}
function setSingleDateFilter(payload, entity = null, method = null) {
	return (dispatch) => {
		dispatch({
			type: analyticsConstants.SET_SINGLE_DATE_FILTER,
			payload,
		});
		if (entity) {
			dispatch(fetchData(entity, method));
		}
	};
}
function setPodFilter(payload, entity = null, method = null) {
	return (dispatch) => {
		dispatch({
			type: analyticsConstants.SET_POD_FILTER,
			payload,
		});
		if (entity) {
			dispatch(fetchData(entity, method));
		}
	};
}
function setKitchenFilter(payload, entity = null, method = null) {
	return (dispatch) => {
		dispatch({
			type: analyticsConstants.SET_KITCHEN_FILTER,
			payload,
		});
		if (entity) {
			dispatch(fetchData(entity, method));
		}
	};
}
function setMealType(payload, entity = null, method = null) {
	return (dispatch) => {
		dispatch({
			type: analyticsConstants.SET_MEAL_NAME,
			payload,
		});
		if (entity) {
			dispatch(fetchData(entity, method));
		}
	};
}
export function deserializeFilters(filters) {
	const {
		DATE_FILTER = {},
		GEO_FILTER = {},
		BRAND_FILTER = {},
		STORE_FILTER = {},
		FROM_STORE_FILTER = {},
		FAILURE_ANALYSIS_FILTER = {},
		TIME_FILTER = '',
		STATISTICAL_FILTER = {},
		DATE_RANGE_FILTER = {},
		PRODUCT_FILTER = [],
		UNDETECTED_PRODUCTS_FILTER = {},
		// MEAL_TYPE_FILTER = {},
		LIST_DATA_FILTER = {},
		DUPLICATE_DATA_FILTER = {},
		VALIDATED_DATA_FILTER = {},
		IDENTIFIED_DATA_FILTER = {},
		FEATURES_DATA_FILTER = {},
		PRODUCT_COUNT_FILTER = {},
		PROCESS_TYPE_FILTER = {},
		SKU_TYPE_FILTER = {},
		ANALYSIS_DASHBOARD_FILTERS = {},
		GROUP_TYPE_FILTER = {},
		POD_FILTER = {},
		KITCHEN_FILTER = {},
		MEAL_TYPE = {},
	} = filters;

	// TIME FILTER
	const timeFilter = TIME_FILTER;
	let dateFilter = {};
	if (DATE_FILTER?.active) {
		dateFilter = DATE_FILTER.selection ?? {};
		if (Object.keys(dateFilter).length > 0) {
			dateFilter.month = getMonthNumber(dateFilter.month);
			let currentSelection = null;
			if (dateFilter.hour != null) {
				currentSelection = 'hour';
			} else if (dateFilter.day != null) {
				currentSelection = 'day';
			} else if (dateFilter.month != null) {
				currentSelection = 'month';
			} else if (dateFilter.year != null) {
				currentSelection = 'year';
			}
			dateFilter.currentSelection = currentSelection;
		}
	}

	// GEO FILTER
	const geoFilter = GEO_FILTER.store;
	const productFilter = PRODUCT_FILTER;
	const brandFilter = BRAND_FILTER.selectedList;
	const failureAnalysis = {};

	// FAILURE ANALYSIS
	if (Object.keys(FAILURE_ANALYSIS_FILTER).length > 1) {
		if (
			FAILURE_ANALYSIS_FILTER.WEIGHT['over-weight'] ||
			FAILURE_ANALYSIS_FILTER.WEIGHT['under-weight']
		) {
			failureAnalysis.weight = {
				key: 'weight',
				over: FAILURE_ANALYSIS_FILTER.WEIGHT['over-weight'],
				under: FAILURE_ANALYSIS_FILTER.WEIGHT['under-weight'],
			};
		}
		if (
			FAILURE_ANALYSIS_FILTER.TEMPERATURE['over-temperature'] ||
			FAILURE_ANALYSIS_FILTER.TEMPERATURE['under-temperature']
		) {
			failureAnalysis.temp = {
				key: 'temp',
				over: FAILURE_ANALYSIS_FILTER.TEMPERATURE['over-temperature'],
				under: FAILURE_ANALYSIS_FILTER.TEMPERATURE['under-temperature'],
			};
		}
		if (FAILURE_ANALYSIS_FILTER.DEFECT.applied) {
			failureAnalysis.labels = FAILURE_ANALYSIS_FILTER.DEFECT.defects;
		}
	}
	const statisticalFilter = {};
	if (Object.keys(STATISTICAL_FILTER).length > 1) {
		if (STATISTICAL_FILTER.selected) {
			statisticalFilter.filter = STATISTICAL_FILTER.filter;
			statisticalFilter.products = STATISTICAL_FILTER.selectedProductIds;
		}
	}
	const dateRangeFilter = {};
	if (DATE_RANGE_FILTER.active) {
		dateRangeFilter.from = moment(DATE_RANGE_FILTER.from).format(
			'YYYY-MM-DD'
		);
		if (DATE_RANGE_FILTER.to != null) {
			dateRangeFilter.to = moment(DATE_RANGE_FILTER.to).format(
				'YYYY-MM-DD'
			);
		} else {
			dateRangeFilter.to = moment(new Date()).format('YYYY-MM-DD');
		}
	}

	let undetectedProductsFilter = [];
	if (UNDETECTED_PRODUCTS_FILTER.applied) {
		undetectedProductsFilter = UNDETECTED_PRODUCTS_FILTER.selected;
	}

	// const mealType = [];
	// if (MEAL_TYPE_FILTER.applied) {
	// 	MEAL_TYPE_FILTER.list.forEach((meal) => {
	// 		if (MEAL_TYPE_FILTER.selected?.includes(meal?.id)) {
	// 			mealType.push(meal.title);
	// 		}
	// 	});
	// }
	let storeId = null;
	if (POD_FILTER.applied) {
		if (Number(POD_FILTER.value) === -999) {
			storeId = null;
		} else {
			storeId = Number(POD_FILTER.value);
		}
	}
	let fromStore = null;
	if (KITCHEN_FILTER.applied) {
		if (Number(KITCHEN_FILTER.value) === -999) {
			fromStore = null;
		} else {
			fromStore = Number(KITCHEN_FILTER.value);
		}
	}
	let validate = '';
	if (VALIDATED_DATA_FILTER.applied) {
		if (VALIDATED_DATA_FILTER.value === 'All') {
			validate = null;
		} else {
			validate = VALIDATED_DATA_FILTER.value;
		}
	}
	let mealType = null;
	if (MEAL_TYPE.applied) {
		if (MEAL_TYPE.value === null) {
			mealType = null;
		} else {
			mealType = MEAL_TYPE.value;
		}
	}
	let duplicate = '';
	if (DUPLICATE_DATA_FILTER.applied) {
		if (DUPLICATE_DATA_FILTER.value === 'All') {
			duplicate = null;
		} else {
			duplicate = DUPLICATE_DATA_FILTER.value;
		}
	}
	let identified = '';
	if (IDENTIFIED_DATA_FILTER.applied) {
		if (IDENTIFIED_DATA_FILTER.value === 'All') {
			identified = null;
		} else {
			identified = IDENTIFIED_DATA_FILTER.value;
		}
	}
	let features = '';
	if (FEATURES_DATA_FILTER.applied) {
		if (FEATURES_DATA_FILTER.value === 'All') {
			features = null;
		} else {
			features = FEATURES_DATA_FILTER.value;
		}
	}
	let productCount = [];
	if (PRODUCT_COUNT_FILTER.applied) {
		productCount = PRODUCT_COUNT_FILTER.count;
	}
	let processTypes = [];
	if (PROCESS_TYPE_FILTER.active) {
		processTypes = PROCESS_TYPE_FILTER.selectedProcessTypes;
	}
	let skuTypes = [];
	if (SKU_TYPE_FILTER.active) {
		skuTypes = SKU_TYPE_FILTER?.selectedSkuTypes?.map((id) => {
			return skuTypesList?.[id - 1];
		});
	}
	let grouping = {};
	if (GROUP_TYPE_FILTER.active) {
		grouping = GROUP_TYPE_FILTER;
		grouping.selection = Object.keys(GROUP_TYPE_FILTER.selection).reduce((acc, eleKey) => {
			const eleValue = GROUP_TYPE_FILTER.selection[eleKey];
			if (eleKey !== 'brand') {
				acc[eleKey] = eleValue?.value;
			}
			return acc;
		}, {});
	}
	// eslint-disable-next-line prefer-const
	let storeFilter = STORE_FILTER.selectedStores;
	const fromStoreFilter = FROM_STORE_FILTER?.selectedStores;

	return {
		geoFilter,
		dateFilter,
		brandFilter,
		storeFilter,
		failureAnalysis,
		timeFilter,
		statisticalFilter,
		dateRangeFilter,
		productFilter,
		undetectedProductsFilter,
		// mealType,
		listDataFilter: LIST_DATA_FILTER,
		validate,
		identified,
		features,
		productCount,
		fromStoreFilter,
		processTypes,
		skuTypes,
		duplicate,
		grouping,
		storeId,
		fromStore,
		mealType,
		...ANALYSIS_DASHBOARD_FILTERS,
	};
}

function fetchQualityAnalytics(analyticsType) {
	return async (dispatch, getState) => {
		try {
			const {
				GEO_FILTER,
				BRAND_FILTER,
				DATE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				GROUP_TYPE_FILTER,
				DATE_RANGE_FILTER,
			} = cloneDeep(getState().analytics);
			const filters = deserializeFilters({
				GEO_FILTER,
				BRAND_FILTER,
				DATE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				GROUP_TYPE_FILTER,
				DATE_RANGE_FILTER,
			});
			const req = {
				data: {
					...filters,
				},
			};

			dispatch(
				gateway({
					entity: 'QUALITY_ANALYTICS',
					req,
					method: analyticsType?.split('QUALITY_ANALYTICS_')?.[1],
				})
			);
		} catch (error) {
			// console.log(error);
		}
	};
}
function fetchCogsAnalytics(analyticsType) {
	return async (dispatch, getState) => {
		try {
			const {
				GEO_FILTER,
				BRAND_FILTER,
				DATE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				ANALYSIS_DASHBOARD_FILTERS,
				GROUP_TYPE_FILTER,
				DATE_RANGE_FILTER,
			} = cloneDeep(getState().analytics);
			const filters = deserializeFilters({
				GEO_FILTER,
				BRAND_FILTER,
				DATE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				ANALYSIS_DASHBOARD_FILTERS,
				GROUP_TYPE_FILTER,
				DATE_RANGE_FILTER,
			});
			const req = {
				data: {
					...filters,
					analysisType: 'COGS',
				},
			};

			dispatch(
				gateway({
					entity: 'COGS_ANALYTICS',
					req,
					method: analyticsType?.split('COGS_ANALYTICS_')?.[1],
				})
			);
		} catch (error) {
			// console.log(error);
		}
	};
}
function fetchCommonAnalytics(analyticsType) {
	return async (dispatch, getState) => {
		try {
			const {
				GEO_FILTER,
				BRAND_FILTER,
				DATE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				ANALYSIS_DASHBOARD_FILTERS,
				GROUP_TYPE_FILTER,
				DATE_RANGE_FILTER,
			} = cloneDeep(getState().analytics);
			const filters = deserializeFilters({
				GEO_FILTER,
				BRAND_FILTER,
				DATE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				ANALYSIS_DASHBOARD_FILTERS,
				GROUP_TYPE_FILTER,
				DATE_RANGE_FILTER,
			});
			const req = {
				data: {
					...filters,
				},
			};

			dispatch(
				gateway({
					entity: 'COMMON_ANALYTICS',
					req,
					method: analyticsType?.split('COMMON_ANALYTICS_')?.[1],
				})
			);
		} catch (error) {
			// console.log(error);
		}
	};
}
function fetchBomAnalytics(analyticsType) {
	return async (dispatch, getState) => {
		try {
			const {
				GEO_FILTER,
				BRAND_FILTER,
				DATE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				GROUP_TYPE_FILTER,
				DATE_RANGE_FILTER,
				ANALYSIS_DASHBOARD_FILTERS,
			} = cloneDeep(getState().analytics);
			const filters = deserializeFilters({
				GEO_FILTER,
				BRAND_FILTER,
				DATE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				GROUP_TYPE_FILTER,
				DATE_RANGE_FILTER,
			});
			const req = {
				data: {
					...filters,
				},
			};

			dispatch(
				gateway({
					entity: 'BOM_ANALYTICS',
					req: {
						...req,
						data: {
							...(req?.data ?? {}),
							analysisType: ANALYSIS_DASHBOARD_FILTERS?.analysisType ?? 'BOM',
						},
					},
					method: analyticsType?.split('BOM_ANALYTICS_')?.[1],
				})
			);
		} catch (error) {
			// console.log(error);
		}
	};
}
function fetchInvAnalytics(analyticsType) {
	return async (dispatch, getState) => {
		try {
			const {
				GEO_FILTER,
				BRAND_FILTER,
				DATE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				GROUP_TYPE_FILTER,
				ANALYSIS_DASHBOARD_FILTERS,
				DATE_RANGE_FILTER,
			} = cloneDeep(getState().analytics);
			const filters = deserializeFilters({
				GEO_FILTER,
				BRAND_FILTER,
				DATE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				GROUP_TYPE_FILTER,
				DATE_RANGE_FILTER,
			});
			const req = {
				data: {
					...filters,
				},
			};

			dispatch(
				gateway({
					entity: 'INV_ANALYTICS',
					req: {
						...req,
						data: {
							...(req?.data ?? {}),
							filterType: 'INV',
							analysisType: 'inventory',
							...ANALYSIS_DASHBOARD_FILTERS,
						},
					},
					method: analyticsType?.split('INV_ANALYTICS_')?.[1],
				})
			);
		} catch (error) {
			// console.log(error);
		}
	};
}
function fetchForecastingAnalytics(analyticsType) {
	return async (dispatch, getState) => {
		try {
			const {
				GEO_FILTER,
				BRAND_FILTER,
				DATE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				GROUP_TYPE_FILTER,
				DATE_RANGE_FILTER,
			} = cloneDeep(getState().analytics);
			const filters = deserializeFilters({
				GEO_FILTER,
				BRAND_FILTER,
				DATE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				GROUP_TYPE_FILTER,
				DATE_RANGE_FILTER,
			});
			const req = {
				data: {
					...filters,
				},
			};

			dispatch(
				gateway({
					entity: 'FORECASTING_ANALYTICS',
					req: {
						...req,
						data: {
							...(req?.data ?? {}),
							filterType: 'FORECASTING',
						},
					},
					method: analyticsType?.split('FORECASTING_ANALYTICS_')?.[1],
				})
			);
		} catch (error) {
			// console.log(error);
		}
	};
}
function exportInvAnalytics() {
	return async (dispatch, getState) => {
		try {
			const {
				GEO_FILTER,
				BRAND_FILTER,
				DATE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				GROUP_TYPE_FILTER,
				DATE_RANGE_FILTER,
				ANALYSIS_DASHBOARD_FILTERS,
			} = cloneDeep(getState().analytics);
			const filters = deserializeFilters({
				GEO_FILTER,
				BRAND_FILTER,
				DATE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				GROUP_TYPE_FILTER,
				DATE_RANGE_FILTER,
			});
			const req = {
				data: {
					...filters,
					...ANALYSIS_DASHBOARD_FILTERS,
				},
			};
			const notificationId = notificationServices.generateNotification({
				type: 'loading',
				message: 'loading...',
			});
			const response = await invAnalyticsServices.exportInvAnalysisList(req);
			if (response.status === 200) {
				handleResponseStreamDownload(
					response,
					'INVENTORY_ANALYSIS.csv'
				);
				notificationServices.generateNotification({
					type: 'success',
					message: 'Exported successfully',
					config: {
						id: notificationId,
					},
				});
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Some error occurred',
				});
				notificationServices.generateNotification({
					type: 'error',
					message: 'Some error occurred',
					config: {
						id: notificationId,
					},
				});
			}
		} catch (error) {
			// console.log(error);
		}
	};
}

function exportAiProductionAnalytics() {
	return async (dispatch, getState) => {
		try {
			const {
				GEO_FILTER,
				BRAND_FILTER,
				DATE_RANGE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				FROM_STORE_FILTER,
				PROCESS_TYPE_FILTER,
				SKU_TYPE_FILTER,
				UNDETECTED_PRODUCTS_FILTER,
				VALIDATED_DATA_FILTER,
				IDENTIFIED_DATA_FILTER,
				FEATURES_DATA_FILTER,
				DUPLICATE_DATA_FILTER,
			} = cloneDeep(getState().analytics);
			const filters = deserializeFilters({
				GEO_FILTER,
				BRAND_FILTER,
				DATE_RANGE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				FROM_STORE_FILTER,
				PROCESS_TYPE_FILTER,
				SKU_TYPE_FILTER,
				UNDETECTED_PRODUCTS_FILTER,
				VALIDATED_DATA_FILTER,
				IDENTIFIED_DATA_FILTER,
				FEATURES_DATA_FILTER,
				DUPLICATE_DATA_FILTER,
			});
			const req = {
				data: {
					...filters,
				},
			};
			req.data.export = true;
			req.data.qcType = 'PRODUCTION';
			const response = await commonAnalyticsServices.exportAnalyticsList({
				req: {
					...req,
				},
			});
			const notificationId = notificationServices.generateNotification({
				type: 'loading',
				message: 'loading...',
			});
			if (response.status === 200) {
				handleResponseStreamDownload(
					response,
					'PRODUCTION_HISTORY.csv'
				);
				notificationServices.generateNotification({
					type: 'success',
					message: 'Exported successfully',
					config: {
						id: notificationId,
					},
				});
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Some error occurred',
				});
				notificationServices.generateNotification({
					type: 'error',
					message: 'Some error occurred',
					config: {
						id: notificationId,
					},
				});
			}
		} catch (error) {
			// console.log(error);
		}
	};
}

function exportAiLeftOverInventoryAnalytics() {
	return async (dispatch, getState) => {
		try {
			const {
				GEO_FILTER,
				BRAND_FILTER,
				DATE_RANGE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				FROM_STORE_FILTER,
				PROCESS_TYPE_FILTER,
				SKU_TYPE_FILTER,
				UNDETECTED_PRODUCTS_FILTER,
				VALIDATED_DATA_FILTER,
				IDENTIFIED_DATA_FILTER,
				FEATURES_DATA_FILTER,
				DUPLICATE_DATA_FILTER,
			} = cloneDeep(getState().analytics);
			const filters = deserializeFilters({
				GEO_FILTER,
				BRAND_FILTER,
				DATE_RANGE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				FROM_STORE_FILTER,
				PROCESS_TYPE_FILTER,
				SKU_TYPE_FILTER,
				UNDETECTED_PRODUCTS_FILTER,
				VALIDATED_DATA_FILTER,
				IDENTIFIED_DATA_FILTER,
				FEATURES_DATA_FILTER,
				DUPLICATE_DATA_FILTER,
			});
			const req = {
				data: {
					...filters,
				},
			};
			const notificationId = notificationServices.generateNotification({
				type: 'loading',
				message: 'loading...',
			});
			req.data.export = true;
			req.data.qcType = 'LEFT_OVER';
			const response = await commonAnalyticsServices.exportAnalyticsList({
				req: {
					...req,
				},
			});
			if (response.status === 200) {
				handleResponseStreamDownload(
					response,
					'LEFT_OVER_INVENTORY_HISTORY_SCANS.csv'
				);
				notificationServices.generateNotification({
					type: 'success',
					message: 'Exported successfully',
					config: {
						id: notificationId,
					},
				});
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Some error occurred',
				});
				notificationServices.generateNotification({
					type: 'error',
					message: 'Some error occurred',
					config: {
						id: notificationId,
					},
				});
			}
		} catch (error) {
			// console.log(error);
		}
	};
}

function exportAiQcHistoryAnalytics() {
	return async (dispatch, getState) => {
		try {
			const {
				GEO_FILTER,
				BRAND_FILTER,
				DATE_RANGE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				FROM_STORE_FILTER,
				PROCESS_TYPE_FILTER,
				SKU_TYPE_FILTER,
				UNDETECTED_PRODUCTS_FILTER,
				VALIDATED_DATA_FILTER,
				IDENTIFIED_DATA_FILTER,
				FEATURES_DATA_FILTER,
				DUPLICATE_DATA_FILTER,
			} = cloneDeep(getState().analytics);
			const filters = deserializeFilters({
				GEO_FILTER,
				BRAND_FILTER,
				DATE_RANGE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				FROM_STORE_FILTER,
				PROCESS_TYPE_FILTER,
				SKU_TYPE_FILTER,
				UNDETECTED_PRODUCTS_FILTER,
				VALIDATED_DATA_FILTER,
				IDENTIFIED_DATA_FILTER,
				FEATURES_DATA_FILTER,
				DUPLICATE_DATA_FILTER,
			});
			const req = {
				data: {
					...filters,
				},
			};
			const notificationId = notificationServices.generateNotification({
				type: 'loading',
				message: 'loading...',
			});
			req.data.export = true;
			req.data.qcType = '';
			const response = await commonAnalyticsServices.exportAnalyticsList({
				req: {
					...req,
				},
			});
			if (response.status === 200) {
				handleResponseStreamDownload(
					response,
					'Quality_HISTORY.csv'
				);
				notificationServices.generateNotification({
					type: 'success',
					message: 'Exported successfully',
					config: {
						id: notificationId,
					},
				});
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Some error occurred',
				});
				notificationServices.generateNotification({
					type: 'error',
					message: 'Some error occurred',
					config: {
						id: notificationId,
					},
				});
			}
		} catch (error) {
			// console.log(error);
		}
	};
}
function exportAiGrnAnalytics() {
	return async (dispatch, getState) => {
		try {
			const {
				GEO_FILTER,
				BRAND_FILTER,
				DATE_RANGE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				FROM_STORE_FILTER,
				PROCESS_TYPE_FILTER,
				SKU_TYPE_FILTER,
				UNDETECTED_PRODUCTS_FILTER,
				VALIDATED_DATA_FILTER,
				IDENTIFIED_DATA_FILTER,
				FEATURES_DATA_FILTER,
				DUPLICATE_DATA_FILTER,
			} = cloneDeep(getState().analytics);
			const filters = deserializeFilters({
				GEO_FILTER,
				BRAND_FILTER,
				DATE_RANGE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				FROM_STORE_FILTER,
				PROCESS_TYPE_FILTER,
				SKU_TYPE_FILTER,
				UNDETECTED_PRODUCTS_FILTER,
				VALIDATED_DATA_FILTER,
				IDENTIFIED_DATA_FILTER,
				FEATURES_DATA_FILTER,
				DUPLICATE_DATA_FILTER,
			});
			const req = {
				data: {
					...filters,
				},
			};
			const notificationId = notificationServices.generateNotification({
				type: 'loading',
				message: 'loading...',
			});
			req.data.export = true;
			req.data.qcType = 'INWARD';
			const response = await commonAnalyticsServices.exportAnalyticsList({
				req: {
					...req,
				},
			});
			if (response.status === 200) {
				handleResponseStreamDownload(
					response,
					'AI_GRN_HISTORY.csv'
				);
				notificationServices.generateNotification({
					type: 'success',
					message: 'Exported successfully',
					config: {
						id: notificationId,
					},
				});
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Some error occurred',
				});
				notificationServices.generateNotification({
					type: 'error',
					message: 'Some error occurred',
					config: {
						id: notificationId,
					},
				});
			}
		} catch (error) {
			// console.log(error);
		}
	};
}

function exportAiCookingAnalytics() {
	return async (dispatch, getState) => {
		try {
			const {
				GEO_FILTER,
				BRAND_FILTER,
				DATE_RANGE_FILTER,
				POD_FILTER,
				KITCHEN_FILTER,
				MEAL_TYPE,
				// FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				PROCESS_TYPE_FILTER,
				SKU_TYPE_FILTER,
			} = cloneDeep(getState().analytics);
			const filters = deserializeFilters({
				GEO_FILTER,
				BRAND_FILTER,
				DATE_RANGE_FILTER,
				POD_FILTER,
				KITCHEN_FILTER,
				MEAL_TYPE,
				// FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				PROCESS_TYPE_FILTER,
				SKU_TYPE_FILTER,
			});
			const req = {
				data: {
					...filters,
				},
			};
			const notificationId = notificationServices.generateNotification({
				type: 'loading',
				message: 'loading...',
			});
			const response = await cookingAnalyticsServices.exportCookingList({
				req: {
					...req,
				},
			});
			if (response.status === 200) {
				handleResponseStreamDownload(
					response,
					'COOKING_HISTORY.csv'
				);
				notificationServices.generateNotification({
					type: 'success',
					message: 'Exported successfully',
					config: {
						id: notificationId,
					},
				});
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Some error occurred',
				});
				notificationServices.generateNotification({
					type: 'error',
					message: 'Some error occurred',
					config: {
						id: notificationId,
					},
				});
			}
		} catch (error) {
			// console.log(error);
		}
	};
}

function exportVarianceAnalytics() {
	return async (dispatch, getState) => {
		try {
			const {
				GEO_FILTER,
				BRAND_FILTER,
				DATE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				GROUP_TYPE_FILTER,
				DATE_RANGE_FILTER,
				ANALYSIS_DASHBOARD_FILTERS,
			} = cloneDeep(getState().analytics);
			const filters = deserializeFilters({
				GEO_FILTER,
				BRAND_FILTER,
				DATE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				GROUP_TYPE_FILTER,
				DATE_RANGE_FILTER,
			});
			const req = {
				data: {
					...filters,
					...ANALYSIS_DASHBOARD_FILTERS,
				},
			};
			const notificationId = notificationServices.generateNotification({
				type: 'loading',
				message: 'loading...',
			});
			const response = await auditAnalyticsServices.fetchAuditAnalysisExport({
				req,
			});
			if (response.status === 200) {
				handleResponseStreamDownload(
					response,
					'VARIANCE_ANALYSIS.csv'
				);
				notificationServices.generateNotification({
					type: 'success',
					message: 'Exported successfully',
					config: {
						id: notificationId,
					},
				});
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Some error occurred',
					config: {
						id: notificationId,
					},
				});
			}
		} catch (error) {
			// console.log(error);
		}
	};
}
function exportTransitAnalytics() {
	return async (dispatch, getState) => {
		try {
			const {
				GEO_FILTER,
				BRAND_FILTER,
				DATE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				GROUP_TYPE_FILTER,
				DATE_RANGE_FILTER,
				ANALYSIS_DASHBOARD_FILTERS,
			} = cloneDeep(getState().analytics);
			const filters = deserializeFilters({
				GEO_FILTER,
				BRAND_FILTER,
				DATE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				GROUP_TYPE_FILTER,
				DATE_RANGE_FILTER,
			});
			const req = {
				data: {
					...filters,
					...ANALYSIS_DASHBOARD_FILTERS,
				},
			};
			const notificationId = notificationServices.generateNotification({
				type: 'loading',
				message: 'loading...',
			});
			const response = await transitAnalyticsServices.fetchTransitAnalysisExport({
				req,
			});
			if (response.status === 200) {
				handleResponseStreamDownload(
					response,
					'TRANSIT_LOSS_ANALYSIS.csv'
				);
				notificationServices.generateNotification({
					type: 'success',
					message: 'Exported successfully',
					config: {
						id: notificationId,
					},
				});
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Some error occurred',
				});
				notificationServices.generateNotification({
					type: 'error',
					message: 'Some error occurred',
					config: {
						id: notificationId,
					},
				});
			}
		} catch (error) {
			// console.log(error);
		}
	};
}
function fetchAuditAnalytics(analyticsType) {
	return async (dispatch, getState) => {
		try {
			const {
				GEO_FILTER,
				BRAND_FILTER,
				DATE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				GROUP_TYPE_FILTER,
				ANALYSIS_DASHBOARD_FILTERS,
				DATE_RANGE_FILTER,
			} = cloneDeep(getState().analytics);
			const filters = deserializeFilters({
				GEO_FILTER,
				BRAND_FILTER,
				DATE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				GROUP_TYPE_FILTER,
				DATE_RANGE_FILTER,
			});
			const req = {
				data: {
					...filters,
				},
			};

			dispatch(
				gateway({
					entity: 'AUDIT_ANALYTICS',
					req: {
						...req,
						data: {
							...(req?.data ?? {}),
							filterType: 'INV',
							analysisPhase: ANALYSIS_DASHBOARD_FILTERS?.analysisPhase,
							analysisType: 'audit',
						},
					},
					method: analyticsType?.split('AUDIT_ANALYTICS_')?.[1],
				})
			);
		} catch (error) {
			// console.log(error);
		}
	};
}
function fetchTransitAnalytics(analyticsType) {
	return async (dispatch, getState) => {
		try {
			const {
				GEO_FILTER,
				BRAND_FILTER,
				DATE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				GROUP_TYPE_FILTER,
				ANALYSIS_DASHBOARD_FILTERS,
				DATE_RANGE_FILTER,
			} = cloneDeep(getState().analytics);
			const filters = deserializeFilters({
				GEO_FILTER,
				BRAND_FILTER,
				DATE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				GROUP_TYPE_FILTER,
				DATE_RANGE_FILTER,
			});
			const req = {
				data: {
					...filters,
				},
			};

			dispatch(
				gateway({
					entity: 'TRANSIT_ANALYTICS',
					req: {
						...req,
						data: {
							...(req?.data ?? {}),
							filterType: 'INV',
							analysisPhase: ANALYSIS_DASHBOARD_FILTERS?.analysisPhase,
							analysisType: 'transit',
						},
					},
					method: analyticsType?.split('TRANSIT_ANALYTICS_')?.[1],
				})
			);
		} catch (error) {
			// console.log(error);
		}
	};
}
// function fetchGenericAnalyticsData(analyticsType) {
// return async (dispatch, getState) => {
// try {
// const {
// GEO_FILTER,
// BRAND_FILTER,
// DATE_FILTER,
// FAILURE_ANALYSIS_FILTER,
// PRODUCT_FILTER,
// STORE_FILTER,
// } = cloneDeep(getState().analytics);
// const filters = deserializeFilters({
// GEO_FILTER,
// BRAND_FILTER,
// DATE_FILTER,
// FAILURE_ANALYSIS_FILTER,
// PRODUCT_FILTER,
// STORE_FILTER,
// });
// const req = {
// data: {
// ...filters,
// },
// };
// dispatch(
// gateway({
// entity: 'GENERIC_ANALYTICS',
// req: {
// ...req,
// data: {
// ...(req?.data ?? {}),
// filterType: 'BOM',
// },
// },
// method: analyticsType?.split('GENERIC_ANALYTICS')?.[1],
// })
// );
// } catch (error) {
// // console.log(error);
// }
// };
// }

function fetchInventoryAnalytics(analyticsType) {
	return async (dispatch, getState) => {
		try {
			const {
				GEO_FILTER,
				BRAND_FILTER,
				DATE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
			} = cloneDeep(getState().analytics);
			const filters = deserializeFilters({
				GEO_FILTER,
				BRAND_FILTER,
				DATE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
			});
			const req = {
				data: {
					...filters,
				},
			};
			dispatch(
				gateway({
					entity: 'INVENTORY_ANALYTICS',
					req,
					method: analyticsType?.split('INVENTORY_ANALYTICS_')?.[1],
				})
			);
		} catch (error) {
			// console.log(error);
		}
	};
}

function fetchStoreAnalysis() {
	return async (dispatch, getState) => {
		try {
			const { GEO_FILTER, BRAND_FILTER, TIME_FILTER } = cloneDeep(
				getState().analytics
			);
			const filters = deserializeFilters({
				GEO_FILTER,
				BRAND_FILTER,
				TIME_FILTER,
			});
			const req = {
				data: {
					...filters,
				},
			};
			dispatch(
				gateway({
					entity: 'STORE_ANALYSIS',
					req,
				})
			);
		} catch (error) {
			// console.log();
		}
	};
}
function fetchQualityStatistical() {
	return async (dispatch, getState) => {
		try {
			const {
				GEO_FILTER,
				BRAND_FILTER,
				DATE_FILTER,
				STATISTICAL_FILTER,
			} = cloneDeep(getState().analytics);
			const filters = deserializeFilters({
				GEO_FILTER,
				BRAND_FILTER,
				DATE_FILTER,
				STATISTICAL_FILTER,
			});
			const req = {
				data: {
					...filters,
				},
			};
			dispatch(
				gateway({
					entity: 'QC_STATISTICAL_ANALYSIS',
					req,
				})
			);
		} catch (error) {
			// console.log(error);
		}
	};
}

function fetchQCHistory({ params = {} }) {
	return async (dispatch, getState) => {
		try {
			const {
				DATE_RANGE_FILTER,
				BRAND_FILTER,
				UNDETECTED_PRODUCTS_FILTER,
				MEAL_TYPE_FILTER,
				VALIDATED_DATA_FILTER,
				IDENTIFIED_DATA_FILTER,
				FEATURES_DATA_FILTER,
				PRODUCT_COUNT_FILTER,
				STORE_FILTER,
				PROCESS_TYPE_FILTER,
				SKU_TYPE_FILTER,
				DUPLICATE_DATA_FILTER,
			} = cloneDeep(getState().analytics);
			const filters = deserializeFilters({
				DATE_RANGE_FILTER,
				BRAND_FILTER,
				STORE_FILTER,
				PROCESS_TYPE_FILTER,
				SKU_TYPE_FILTER,
				UNDETECTED_PRODUCTS_FILTER,
				MEAL_TYPE_FILTER,
				VALIDATED_DATA_FILTER,
				IDENTIFIED_DATA_FILTER,
				FEATURES_DATA_FILTER,
				PRODUCT_COUNT_FILTER,
				DUPLICATE_DATA_FILTER,
			});
			const req = {
				data: {
					...filters,
				},
				params,
			};
			dispatch(
				gateway({
					entity: 'QUALITY_ANALYTICS',
					req,
					method: 'LIST_DATA',
				})
			);
		} catch (error) {
			// console.log(error);
		}
	};
}

function fetchCogsHistory({ params = {} }) {
	return async (dispatch, getState) => {
		try {
			const {
				DATE_RANGE_FILTER,
				BRAND_FILTER,
				UNDETECTED_PRODUCTS_FILTER,
				MEAL_TYPE_FILTER,
				VALIDATED_DATA_FILTER,
				IDENTIFIED_DATA_FILTER,
				FEATURES_DATA_FILTER,
				PRODUCT_COUNT_FILTER,
				STORE_FILTER,
				PROCESS_TYPE_FILTER,
				SKU_TYPE_FILTER,
				DUPLICATE_DATA_FILTER,
				ANALYSIS_DASHBOARD_FILTERS,
			} = cloneDeep(getState().analytics);
			const filters = deserializeFilters({
				DATE_RANGE_FILTER,
				BRAND_FILTER,
				STORE_FILTER,
				PROCESS_TYPE_FILTER,
				SKU_TYPE_FILTER,
				UNDETECTED_PRODUCTS_FILTER,
				MEAL_TYPE_FILTER,
				VALIDATED_DATA_FILTER,
				IDENTIFIED_DATA_FILTER,
				FEATURES_DATA_FILTER,
				PRODUCT_COUNT_FILTER,
				DUPLICATE_DATA_FILTER,
				ANALYSIS_DASHBOARD_FILTERS,
			});
			const req = {
				data: {
					...filters,
				},
				params,
			};
			dispatch(
				gateway({
					entity: 'COGS_ANALYTICS',
					req,
					method: 'LIST_DATA',
				})
			);
		} catch (error) {
			// console.log(error);
		}
	};
}
function fetchCommonAnalysisHistory({ params = {} }) {
	return async (dispatch, getState) => {
		try {
			const {
				DATE_RANGE_FILTER,
				BRAND_FILTER,
				UNDETECTED_PRODUCTS_FILTER,
				MEAL_TYPE_FILTER,
				VALIDATED_DATA_FILTER,
				IDENTIFIED_DATA_FILTER,
				FEATURES_DATA_FILTER,
				PRODUCT_COUNT_FILTER,
				STORE_FILTER,
				PROCESS_TYPE_FILTER,
				SKU_TYPE_FILTER,
				DUPLICATE_DATA_FILTER,
				ANALYSIS_DASHBOARD_FILTERS,
			} = cloneDeep(getState().analytics);
			const filters = deserializeFilters({
				DATE_RANGE_FILTER,
				BRAND_FILTER,
				STORE_FILTER,
				PROCESS_TYPE_FILTER,
				SKU_TYPE_FILTER,
				UNDETECTED_PRODUCTS_FILTER,
				MEAL_TYPE_FILTER,
				VALIDATED_DATA_FILTER,
				IDENTIFIED_DATA_FILTER,
				FEATURES_DATA_FILTER,
				PRODUCT_COUNT_FILTER,
				DUPLICATE_DATA_FILTER,
				ANALYSIS_DASHBOARD_FILTERS,
			});
			const req = {
				data: {
					...filters,
				},
				params,
			};
			dispatch(
				gateway({
					entity: 'COMMON_ANALYTICS',
					req,
					method: 'LIST_DATA',
				})
			);
		} catch (error) {
			// console.log(error);
		}
	};
}
function fetchBomHistory({ params = {} }) {
	return async (dispatch, getState) => {
		try {
			const {
				DATE_RANGE_FILTER,
				BRAND_FILTER,
				UNDETECTED_PRODUCTS_FILTER,
				MEAL_TYPE_FILTER,
				VALIDATED_DATA_FILTER,
				IDENTIFIED_DATA_FILTER,
				FEATURES_DATA_FILTER,
				PRODUCT_COUNT_FILTER,
				STORE_FILTER,
				PROCESS_TYPE_FILTER,
				SKU_TYPE_FILTER,
				DUPLICATE_DATA_FILTER,
				ANALYSIS_DASHBOARD_FILTERS,
			} = cloneDeep(getState().analytics);
			const filters = deserializeFilters({
				DATE_RANGE_FILTER,
				BRAND_FILTER,
				STORE_FILTER,
				PROCESS_TYPE_FILTER,
				SKU_TYPE_FILTER,
				UNDETECTED_PRODUCTS_FILTER,
				MEAL_TYPE_FILTER,
				VALIDATED_DATA_FILTER,
				IDENTIFIED_DATA_FILTER,
				FEATURES_DATA_FILTER,
				PRODUCT_COUNT_FILTER,
				DUPLICATE_DATA_FILTER,
			});
			const req = {
				data: {
					...filters,
				},
				params,
			};
			dispatch(
				gateway({
					entity: 'BOM_ANALYTICS',
					req: {
						...req,
						data: {
							...(req?.data ?? {}),
							analysisType: ANALYSIS_DASHBOARD_FILTERS?.analysisType ?? 'BOM_INGREDIENT',
						},
					},
					method: 'LIST_DATA',
				})
			);
		} catch (error) {
			// console.log(error);
		}
	};
}
function fetchAuditHistory({ params = {} }) {
	return async (dispatch, getState) => {
		try {
			const {
				DATE_RANGE_FILTER,
				BRAND_FILTER,
				UNDETECTED_PRODUCTS_FILTER,
				MEAL_TYPE_FILTER,
				VALIDATED_DATA_FILTER,
				IDENTIFIED_DATA_FILTER,
				FEATURES_DATA_FILTER,
				PRODUCT_COUNT_FILTER,
				STORE_FILTER,
				PROCESS_TYPE_FILTER,
				SKU_TYPE_FILTER,
				DUPLICATE_DATA_FILTER,
				// ANALYSIS_DASHBOARD_FILTERS,
			} = cloneDeep(getState().analytics);
			const filters = deserializeFilters({
				DATE_RANGE_FILTER,
				BRAND_FILTER,
				STORE_FILTER,
				PROCESS_TYPE_FILTER,
				SKU_TYPE_FILTER,
				UNDETECTED_PRODUCTS_FILTER,
				MEAL_TYPE_FILTER,
				VALIDATED_DATA_FILTER,
				IDENTIFIED_DATA_FILTER,
				FEATURES_DATA_FILTER,
				PRODUCT_COUNT_FILTER,
				DUPLICATE_DATA_FILTER,
			});
			const req = {
				data: {
					...filters,
				},
				params,
			};
			dispatch(
				gateway({
					entity: 'AUDIT_ANALYTICS',
					req: {
						...req,
						data: {
							...(req?.data ?? {}),
							analysisType: 'audit',
						},
					},
					method: 'LIST_DATA',
				})
			);
		} catch (error) {
			// console.log(error);
		}
	};
}
function fetchTransitHistory({ params = {} }) {
	return async (dispatch, getState) => {
		try {
			const {
				DATE_RANGE_FILTER,
				BRAND_FILTER,
				UNDETECTED_PRODUCTS_FILTER,
				MEAL_TYPE_FILTER,
				VALIDATED_DATA_FILTER,
				IDENTIFIED_DATA_FILTER,
				FEATURES_DATA_FILTER,
				PRODUCT_COUNT_FILTER,
				STORE_FILTER,
				PROCESS_TYPE_FILTER,
				SKU_TYPE_FILTER,
				DUPLICATE_DATA_FILTER,
				// ANALYSIS_DASHBOARD_FILTERS,
			} = cloneDeep(getState().analytics);
			const filters = deserializeFilters({
				DATE_RANGE_FILTER,
				BRAND_FILTER,
				STORE_FILTER,
				PROCESS_TYPE_FILTER,
				SKU_TYPE_FILTER,
				UNDETECTED_PRODUCTS_FILTER,
				MEAL_TYPE_FILTER,
				VALIDATED_DATA_FILTER,
				IDENTIFIED_DATA_FILTER,
				FEATURES_DATA_FILTER,
				PRODUCT_COUNT_FILTER,
				DUPLICATE_DATA_FILTER,
			});
			const req = {
				data: {
					...filters,
				},
				params,
			};
			dispatch(
				gateway({
					entity: 'TRANSIT_ANALYTICS',
					req: {
						...req,
						data: {
							...(req?.data ?? {}),
							analysisType: 'transit',
						},
					},
					method: 'LIST_DATA',
				})
			);
		} catch (error) {
			// console.log(error);
		}
	};
}

function fetchInvHistory({ params = {} }) {
	return async (dispatch, getState) => {
		try {
			const {
				DATE_RANGE_FILTER,
				BRAND_FILTER,
				UNDETECTED_PRODUCTS_FILTER,
				MEAL_TYPE_FILTER,
				VALIDATED_DATA_FILTER,
				IDENTIFIED_DATA_FILTER,
				FEATURES_DATA_FILTER,
				PRODUCT_COUNT_FILTER,
				STORE_FILTER,
				PROCESS_TYPE_FILTER,
				SKU_TYPE_FILTER,
				DUPLICATE_DATA_FILTER,
				ANALYSIS_DASHBOARD_FILTERS,
			} = cloneDeep(getState().analytics);
			const filters = deserializeFilters({
				DATE_RANGE_FILTER,
				BRAND_FILTER,
				STORE_FILTER,
				PROCESS_TYPE_FILTER,
				SKU_TYPE_FILTER,
				UNDETECTED_PRODUCTS_FILTER,
				MEAL_TYPE_FILTER,
				VALIDATED_DATA_FILTER,
				IDENTIFIED_DATA_FILTER,
				FEATURES_DATA_FILTER,
				PRODUCT_COUNT_FILTER,
				DUPLICATE_DATA_FILTER,
			});
			const req = {
				data: {
					...filters,
				},
				params,
			};
			dispatch(
				gateway({
					entity: 'INV_ANALYTICS',
					req: {
						...req,
						data: {
							...(req?.data ?? {}),
							filterType: 'INV',
							analysisType: 'inventory',
							analysisSubType: ANALYSIS_DASHBOARD_FILTERS?.analysisSubType,
						},
					},
					method: 'LIST_DATA',
				})
			);
		} catch (error) {
			// console.log(error);
		}
	};
}
function fetchForecastingHistory({ params = {} }) {
	return async (dispatch, getState) => {
		try {
			const {
				DATE_RANGE_FILTER,
				BRAND_FILTER,
				UNDETECTED_PRODUCTS_FILTER,
				MEAL_TYPE_FILTER,
				VALIDATED_DATA_FILTER,
				IDENTIFIED_DATA_FILTER,
				FEATURES_DATA_FILTER,
				PRODUCT_COUNT_FILTER,
				STORE_FILTER,
				PROCESS_TYPE_FILTER,
				SKU_TYPE_FILTER,
				DUPLICATE_DATA_FILTER,
				ANALYSIS_DASHBOARD_FILTERS,
			} = cloneDeep(getState().analytics);
			const filters = deserializeFilters({
				DATE_RANGE_FILTER,
				BRAND_FILTER,
				STORE_FILTER,
				PROCESS_TYPE_FILTER,
				SKU_TYPE_FILTER,
				UNDETECTED_PRODUCTS_FILTER,
				MEAL_TYPE_FILTER,
				VALIDATED_DATA_FILTER,
				IDENTIFIED_DATA_FILTER,
				FEATURES_DATA_FILTER,
				PRODUCT_COUNT_FILTER,
				DUPLICATE_DATA_FILTER,
			});
			const req = {
				data: {
					...filters,
				},
				params,
			};
			dispatch(
				gateway({
					entity: 'FORECASTING_ANALYTICS',
					req: {
						...req,
						data: {
							...(req?.data ?? {}),
							filterType: 'FORECASTING',
							analysisType: 'inventory',
							analysisSubType: ANALYSIS_DASHBOARD_FILTERS?.analysisSubType,
						},
					},
					method: 'LIST_DATA',
				})
			);
		} catch (error) {
			// console.log(error);
		}
	};
}

// const limit = 10;

function fetchCookingHistory(analyticsType, { params = {} }) {
	return async (dispatch, getState) => {
		try {
			const {
				GEO_FILTER,
				BRAND_FILTER,
				DATE_RANGE_FILTER,
				POD_FILTER,
				KITCHEN_FILTER,
				MEAL_TYPE,
				// FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				PROCESS_TYPE_FILTER,
				SKU_TYPE_FILTER,
			} = cloneDeep(getState().analytics);
			const filters = deserializeFilters({
				GEO_FILTER,
				BRAND_FILTER,
				DATE_RANGE_FILTER,
				POD_FILTER,
				KITCHEN_FILTER,
				MEAL_TYPE,
				// FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				PROCESS_TYPE_FILTER,
				SKU_TYPE_FILTER,
			});
			const req = {
				data: {
					...filters,
				},
				params: {
					...params,
				},
			};
			dispatch(
				gateway({
					entity: 'COOKING_ANALYTICS',
					req,
					method: analyticsType?.split('COOKING_HISTORY_')?.[1],
				})
			);
		} catch (error) {
			// console.log(error);
		}
	};
}

function fetchOrderHistory(analyticsType, { data = {}, params = {} }) {
	return async (dispatch) => {
		try {
			const req = {
				data: {
					...data,
				},
				params: {
					...params,
				},
			};
			dispatch(
				gateway({
					entity: 'ORDER_HISTORY',
					req,
					method: analyticsType?.split('ORDER_HISTORY_')?.[1],
				})
			);
		} catch (error) {
			// console.log(error);
		}
	};
}
function fetchWastageHistory(analyticsType) {
	return async (dispatch, getState) => {
		try {
			const {
				GEO_FILTER,
				BRAND_FILTER,
				DATE_RANGE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				FROM_STORE_FILTER,
				PROCESS_TYPE_FILTER,
				SKU_TYPE_FILTER,
			} = cloneDeep(getState().analytics);
			const filters = deserializeFilters({
				GEO_FILTER,
				BRAND_FILTER,
				DATE_RANGE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				FROM_STORE_FILTER,
				PROCESS_TYPE_FILTER,
				SKU_TYPE_FILTER,
			});
			const req = {
				data: {
					...filters,
					qcType: 'WASTAGE',
				},
			};

			dispatch(
				gateway({
					entity: 'WASTAGE_HISTORY',
					req,
					method: analyticsType?.split('WASTAGE_HISTORY_')?.[1],
				})
			);
		} catch (error) {
			// console.log(error);
		}
	};
}

// eslint-disable-next-line no-unused-vars
function fetchCommonHistory(entity, analyticsType, historyReq) {
	return async (dispatch, getState) => {
		try {
			const {
				GEO_FILTER,
				BRAND_FILTER,
				DATE_RANGE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				FROM_STORE_FILTER,
				PROCESS_TYPE_FILTER,
				SKU_TYPE_FILTER,
				UNDETECTED_PRODUCTS_FILTER,
				VALIDATED_DATA_FILTER,
				IDENTIFIED_DATA_FILTER,
				FEATURES_DATA_FILTER,
				DUPLICATE_DATA_FILTER,
			} = cloneDeep(getState().analytics);
			const filters = deserializeFilters({
				GEO_FILTER,
				BRAND_FILTER,
				DATE_RANGE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				FROM_STORE_FILTER,
				PROCESS_TYPE_FILTER,
				SKU_TYPE_FILTER,
				UNDETECTED_PRODUCTS_FILTER,
				VALIDATED_DATA_FILTER,
				IDENTIFIED_DATA_FILTER,
				FEATURES_DATA_FILTER,
				DUPLICATE_DATA_FILTER,
			});
			const req = {
				data: {
					...filters,
					...historyReq?.data,
					qcType: getQcType(analyticsType),
				},
			};

			dispatch(
				gateway({
					entity,
					req,
					// eslint-disable-next-line prefer-template
					method: analyticsType?.split(entity + '_')?.[1],
				})
			);
		} catch (error) {
			// console.log(error);
		}
	};
}

function fetchSubscriptionsHistory(analyticsType, reqObj) {
	return async (dispatch, getState) => {
		try {
			const {
				GEO_FILTER,
				BRAND_FILTER,
				DATE_RANGE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				FROM_STORE_FILTER,
			} = cloneDeep(getState().analytics);
			const filters = deserializeFilters({
				GEO_FILTER,
				BRAND_FILTER,
				DATE_RANGE_FILTER,
				FAILURE_ANALYSIS_FILTER,
				PRODUCT_FILTER,
				STORE_FILTER,
				FROM_STORE_FILTER,
			});
			const req = {
				...reqObj,
				data: {
					...filters,
				},
			};

			dispatch(
				gateway({
					entity: 'SUBSCRIPTIONS_HISTORY',
					req,
					method: analyticsType?.split('SUBSCRIPTIONS_HISTORY_')?.[1],
				})
			);
		} catch (error) {
			// console.log(error);
		}
	};
}

function fetchDashboardAnalytics() {
	return async (dispatch, getState) => {
		try {
			const { DATE_FILTER, DATE_RANGE_FILTER, STORE_FILTER } = cloneDeep(
				getState().analytics
			);
			const filters = deserializeFilters({
				DATE_FILTER,
				DATE_RANGE_FILTER,
				STORE_FILTER,
			});
			const req = {
				data: {
					...filters,
				},
			};
			dispatch({
				type: analyticsConstants.FETCH_DASHBOARD_REQUEST,
			});

			const response =
				await dashboardAnalyticsService.fetchDashboardAnalytics(req);

			dispatch({
				type: analyticsConstants.SET_DASHBOARD_ANALYTICS,
				payload: {
					isLoading: false,
					data: response?.data,
				},
			});
		} catch (error) {
			dispatch({
				type: analyticsConstants.FETCH_DASHBOARD_FAILURE,
			});
			// console.log(error);
		}
	};
}
function fetchAiDashboardAnalytics() {
	return async (dispatch, getState) => {
		try {
			const { DATE_FILTER, DATE_RANGE_FILTER, STORE_FILTER } = cloneDeep(
				getState().analytics
			);
			const filters = deserializeFilters({
				DATE_FILTER,
				DATE_RANGE_FILTER,
				STORE_FILTER,
			});
			const req = {
				data: {
					...filters,
				},
			};
			dispatch({
				type: analyticsConstants.FETCH_AI_DASHBOARD_REQUEST,
			});

			const response =
				await dashboardAnalyticsService.fetchAiDashboardAnalytics(req);

			dispatch({
				type: analyticsConstants.SET_AI_DASHBOARD_ANALYTICS,
				payload: {
					isLoading: false,
					data: response?.data,
				},
			});
		} catch (error) {
			dispatch({
				type: analyticsConstants.FETCH_AI_DASHBOARD_FAILURE,
			});
		}
	};
}

function fetchAIWorkflowAnalytics() {
	return async (dispatch, getState) => {
		try {
			const { DATE_FILTER, DATE_RANGE_FILTER, STORE_FILTER } = cloneDeep(
				getState().analytics
			);
			const filters = deserializeFilters({
				DATE_FILTER,
				DATE_RANGE_FILTER,
				STORE_FILTER,
			});
			const req = {
				data: {
					...filters,
				},
			};
			dispatch({
				type: analyticsConstants.FETCH_DASHBOARD_REQUEST,
			});

			const response =
				await dashboardAnalyticsService.fetchAIWorkflowAnalytics(req);

			dispatch({
				type: analyticsConstants.SET_DASHBOARD_ANALYTICS,
				payload: {
					isLoading: false,
					data: response?.data,
				},
			});
		} catch (error) {
			dispatch({
				type: analyticsConstants.FETCH_DASHBOARD_FAILURE,
			});
			// console.log(error);
		}
	};
}

function fetchData(req = {}) {
	return (dispatch, getState) => {
		const { analyticsType } = cloneDeep(getState().analytics);
		switch (analyticsType) {
			case analyticsTypeConstant.DASHBOARD_ANALYTICS:
				dispatch(fetchDashboardAnalytics());
				break;
			case analyticsTypeConstant.AI_DASHBOARD_ANALYTICS:
				dispatch(fetchAiDashboardAnalytics());
				break;
			case analyticsTypeConstant.AI_WORKFLOW_ANALYTICS:
				dispatch(fetchAIWorkflowAnalytics());
				break;
			case analyticsTypeConstant.QUALITY_ANALYTICS_CHART_DATA:
			case analyticsTypeConstant.QUALITY_ANALYTICS_LIST_DATA:
				dispatch(fetchQualityAnalytics(analyticsType, req));
				break;
			case analyticsTypeConstant.COGS_ANALYTICS_CHART_DATA:
			case analyticsTypeConstant.COGS_ANALYTICS_LIST_DATA:
				dispatch(fetchCogsAnalytics(analyticsType, req));
				break;
			case analyticsTypeConstant.COMMON_ANALYTICS_CHART_DATA:
			case analyticsTypeConstant.COMMON_ANALYTICS_LIST_DATA:
				dispatch(fetchCommonAnalytics(analyticsType, req));
				break;
			case analyticsTypeConstant.COGS_HISTORY_LIST_DATA:
				dispatch(fetchCogsHistory(req));
				break;
			case analyticsTypeConstant.COMMON_HISTORY_LIST_DATA:
				dispatch(fetchCommonAnalysisHistory(req));
				break;
			case analyticsTypeConstant.BOM_ANALYTICS_CHART_DATA:
			case analyticsTypeConstant.BOM_ANALYTICS_LIST_DATA:
				dispatch(fetchBomAnalytics(analyticsType, req));
				break;
			case analyticsTypeConstant.INV_ANALYTICS_CHART_DATA:
			case analyticsTypeConstant.INV_ANALYTICS_LIST_DATA:
				dispatch(fetchInvAnalytics(analyticsType, req));
				break;
			case analyticsTypeConstant.FORECASTING_ANALYTICS_CHART_DATA:
			case analyticsTypeConstant.FORECASTING_ANALYTICS_LIST_DATA:
				dispatch(fetchForecastingAnalytics(analyticsType, req));
				break;
			case analyticsTypeConstant.FORECASTING_HISTORY_LIST_DATA:
				dispatch(fetchForecastingHistory(req));
				break;
			case analyticsTypeConstant.AUDIT_ANALYTICS_CHART_DATA:
			case analyticsTypeConstant.AUDIT_ANALYTICS_LIST_DATA:
				dispatch(fetchAuditAnalytics(analyticsType, req));
				break;
			case analyticsTypeConstant.TRANSIT_ANALYTICS_CHART_DATA:
			case analyticsTypeConstant.TRANSIT_ANALYTICS_LIST_DATA:
				dispatch(fetchTransitAnalytics(analyticsType, req));
				break;
			case analyticsTypeConstant.BOM_HISTORY_LIST_DATA:
				dispatch(fetchBomHistory(req));
				break;
			case analyticsTypeConstant.AUDIT_HISTORY_LIST_DATA:
				dispatch(fetchAuditHistory(req));
				break;
			case analyticsTypeConstant.TRANSIT_HISTORY_LIST_DATA:
				dispatch(fetchTransitHistory(req));
				break;
			case analyticsTypeConstant.INV_HISTORY_LIST_DATA:
				dispatch(fetchInvHistory(req));
				break;
			case analyticsTypeConstant.INVENTORY_ANALYTICS_CHART_DATA:
			case analyticsTypeConstant.INVENTORY_ANALYTICS_LIST_DATA:
				dispatch(fetchInventoryAnalytics(analyticsType));
				break;
			case analyticsTypeConstant.STORE_ANALYTICS:
				dispatch(fetchStoreAnalysis());
				break;
			case analyticsTypeConstant.QUALITY_STATISTICAL_ANALYTICS:
				dispatch(fetchQualityStatistical());
				break;
			case analyticsTypeConstant.QUALITY_HISTORY_LIST_DATA:
				dispatch(fetchQCHistory(req));
				break;
			case analyticsTypeConstant.COOKING_HISTORY_LIST_DATA:
				dispatch(fetchCookingHistory(analyticsType, req));
				break;
			case analyticsTypeConstant.ORDER_HISTORY_LIST_DATA:
				dispatch(fetchOrderHistory(analyticsType, req));
				break;
			case analyticsTypeConstant.WASTAGE_HISTORY_LIST_DATA:
				dispatch(fetchWastageHistory(analyticsType, req));
				break;
			case analyticsTypeConstant.AI_OUTWARDING_ANALYSIS_LIST_DATA:
			case analyticsTypeConstant.AI_PRODUCTION_ANALYSIS_LIST_DATA:
			case analyticsTypeConstant.AI_DISPATCH_ANALYSIS_LIST_DATA:
			case analyticsTypeConstant.AI_LEFT_OVER_INVENTORY_ANALYSIS_LIST_DATA:
				dispatch(
					fetchCommonHistory(
						analyticsType?.split('_LIST_DATA')[0],
						analyticsType,
						req
					)
				);
				break;
			case analyticsTypeConstant.AI_GRN_ANALYSIS_LIST_DATA:
				dispatch(
					fetchCommonHistory('AI_GRN_ANALYSIS', analyticsType, req)
				);
				break;
			case analyticsTypeConstant.SUBSCRIPTIONS_HISTORY_LIST_DATA:
				dispatch(fetchSubscriptionsHistory(analyticsType, req));
				break;
			default:
				break;
		}
	};
}
function fetchDataRange(entity, method, req = {}, type) {
	return (dispatch, getState) => {
		const clonedState = cloneDeep(getState().analytics);
		switch (method) {
			case 'STOCK_IN_LIST':
				dispatch(
					gateway({
						entity,
						req: {
							...req,
							data: {
								filters: {
									dateRangeFilter: {
										from: moment(
											clonedState.DATE_RANGE_FILTER.from
										).format('YYYY-MM-DD'),
										to: moment(
											clonedState.DATE_RANGE_FILTER.to
										).format('YYYY-MM-DD'),
									},
									storeFilter:
										clonedState?.STORE_FILTER
											?.selectedStores,
									// type: clonedState.IMS_STOCK_FILTER.value,
								},
							},
						},
						method,
					})
				);
				break;
			case 'STOCK_OUT_LIST':
				dispatch(
					gateway({
						entity,
						req: {
							...req,
							data: {
								filters: {
									dateRangeFilter: {
										from: moment(
											clonedState.DATE_RANGE_FILTER.from
										).format('YYYY-MM-DD'),
										to: moment(
											clonedState.DATE_RANGE_FILTER.to
										).format('YYYY-MM-DD'),
									},
								},
							},
						},
						method,
					})
				);
				break;
			case 'SKU_STOCK':
				if (type === 'PROCESS_REPORT') {
					const storeId = clonedState.STORE_FILTER?.selectedStores?.[0];
					const processId = clonedState.PROCESS_TYPE_FILTER?.selectedProcessTypes?.[0];
					const filterValue = clonedState.IMS_STOCK_FILTER.value;
					if (storeId && processId && filterValue) {
						dispatch(
							gateway({
								entity,
								req: {
									params: {
										type: filterValue,
										storeId,
										processId,
									},
								},
								method,
							})
						);
					}
				} else {
					if (!clonedState.STORE_FILTER?.selectedStores?.[0]) return;
					dispatch(
						gateway({
							entity,
							req: {
								params: {
									type: clonedState.IMS_STOCK_FILTER.value,
									storeId:
									clonedState.STORE_FILTER
										?.selectedStores?.[0] ?? '',
								},
							},
							method,
						})
					);
				}
				break;
			case 'TOP_PRODUCTS':
			case 'TOP_DEFECTS':
				dispatch(
					gateway({
						entity,
						req: {
							...req,
							data: {
								filters: {
									dateRangeFilter: {
										from: moment(
											clonedState.DATE_RANGE_FILTER.from
										).format('YYYY-MM-DD'),
										to: moment(
											clonedState.DATE_RANGE_FILTER.to
										).format('YYYY-MM-DD'),
									},
								},
							},
						},
						method,
					})
				);
				break;
			default:
				break;
		}
	};
}
function setAnalyticsType(type, req = {}, fetchDataAfterSetting = true) {
	return (dispatch) => {
		dispatch({
			type: analyticsConstants.SET_ANALYTICS_TYPE,
			payload: type,
		});
		if (fetchDataAfterSetting) {
			dispatch(fetchData(req));
		}
	};
}
function applyDateFilter(dataFilter, fetchDataAfterApplying = true) {
	return (dispatch) => {
		dispatch({
			type: analyticsConstants.SET_DATE_FILTER,
			payload: dataFilter,
		});
		if (fetchDataAfterApplying) {
			dispatch(fetchData());
		}
	};
}

// function applyMealTypeFilter(mealTypeFilter, fetchDataAfterApplying = true) {
// 	return (dispatch) => {
// 		dispatch({
// 			type: analyticsConstants.SET_MEAL_TYPE_FILTER,
// 			payload: mealTypeFilter,
// 		});
// 		if (fetchDataAfterApplying) {
// 			dispatch(fetchData());
// 		}
// 	};
// }

function applyListDataFilter(listDataFilter, fetchDataAfterApplying = true) {
	return (dispatch) => {
		dispatch({
			type: analyticsConstants.SET_LIST_DATA_FILTER,
			payload: listDataFilter,
		});
		if (fetchDataAfterApplying) {
			dispatch(fetchData());
		}
	};
}
function applyDuplicateDataFilter(duplicateDataFilter, fetchDataAfterApplying = true) {
	return (dispatch) => {
		dispatch({
			type: analyticsConstants.SET_DUPLICATE_DATA_FILTER,
			payload: duplicateDataFilter,
		});
		if (fetchDataAfterApplying) {
			dispatch(fetchData());
		}
	};
}
function applyValidateDataFilter(
	validatedDataFilter,
	fetchDataAfterApplying = true
) {
	return (dispatch) => {
		dispatch({
			type: analyticsConstants.SET_VALIDATED_DATA_FILTER,
			payload: validatedDataFilter,
		});
		if (fetchDataAfterApplying) {
			dispatch(fetchData());
		}
	};
}
function applyIdentifiedDataFilter(
	identifiedDataFilter,
	fetchDataAfterApplying = true
) {
	return (dispatch) => {
		dispatch({
			type: analyticsConstants.SET_IDENTIFIED_DATA_FILTER,
			payload: identifiedDataFilter,
		});
		if (fetchDataAfterApplying) {
			dispatch(fetchData());
		}
	};
}
function applyFeaturesDataFilter(
	featuresDataFilter,
	fetchDataAfterApplying = true
) {
	return (dispatch) => {
		dispatch({
			type: analyticsConstants.SET_FEATURES_DATA_FILTER,
			payload: featuresDataFilter,
		});
		if (fetchDataAfterApplying) {
			dispatch(fetchData());
		}
	};
}
function applyProductCountFilter(
	productCountFilter,
	fetchDataAfterApplying = true
) {
	return (dispatch) => {
		dispatch({
			type: analyticsConstants.SET_PRODUCT_COUNT_FILTER,
			payload: productCountFilter,
		});
		if (fetchDataAfterApplying) {
			dispatch(fetchData());
		}
	};
}

function applyTimeFilter(timeFilter, fetchDataAfterApplying = true) {
	return (dispatch) => {
		dispatch({
			type: analyticsConstants.SET_TIME_FILTER_DATA,
			payload: timeFilter,
		});
		if (fetchDataAfterApplying) {
			dispatch(fetchData());
		}
	};
}
function applyGeoFilter(geoFilter, fetchDataAfterApplying = true) {
	return (dispatch) => {
		dispatch({
			type: analyticsConstants.SET_GEO_FILTER_DATA,
			payload: geoFilter,
		});
		if (fetchDataAfterApplying) {
			dispatch(fetchData());
		}
	};
}

function applyIMSStockFilter(filterValue, fetchDataAfterApplying = true) {
	return (dispatch) => {
		dispatch({
			type: analyticsConstants.SET_IMS_STOCK_FILTER,
			payload: filterValue,
		});
		if (fetchDataAfterApplying) {
			dispatch(fetchData());
		}
	};
}

function applyBrandFilter(brandFilter, fetchDataAfterApplying = true) {
	return (dispatch, getState) => {
		const statisticalFilter = getState().analytics.STATISTICAL_FILTER;

		if (brandFilter?.selectedList?.length === 0) {
			if (statisticalFilter.selected === true) {
				dispatch(resetFilters(['STATISTICAL_FILTER'], false));
			}
		}

		dispatch({
			type: analyticsConstants.SET_BRAND_FILTER_DATA,
			payload: brandFilter,
		});
		if (fetchDataAfterApplying) {
			dispatch(fetchData());
		}
	};
}

function applyStoreFilter(storeFilter, fetchDataAfterApplying = true) {
	return (dispatch) => {
		dispatch({
			type: analyticsConstants.SET_STORE_FILTER,
			payload: storeFilter,
		});
		if (fetchDataAfterApplying) {
			dispatch(fetchData());
		}
	};
}
function applyProcessTypeFilter(
	processTypeFilter,
	fetchDataAfterApplying = true
) {
	return (dispatch) => {
		dispatch({
			type: analyticsConstants.SET_PROCESS_TYPE_FILTER,
			payload: processTypeFilter,
		});
		if (fetchDataAfterApplying) {
			dispatch(fetchData());
		}
	};
}
function applySkuTypeFilter(
	skuTypeFilter,
	fetchDataAfterApplying = true
) {
	return (dispatch) => {
		dispatch({
			type: analyticsConstants.SET_SKU_TYPE_FILTER,
			payload: skuTypeFilter,
		});
		if (fetchDataAfterApplying) {
			dispatch(fetchData());
		}
	};
}
function applyFromStoreFilter(fromStoreFilter, fetchDataAfterApplying = true) {
	return (dispatch) => {
		dispatch({
			type: analyticsConstants.SET_FROM_STORE_FILTER,
			payload: fromStoreFilter,
		});
		if (fetchDataAfterApplying) {
			dispatch(fetchData());
		}
	};
}

function applyFailureAnalysis(
	failureAnalysisfilter,
	fetchDataAfterApplying = false
) {
	return (dispatch) => {
		dispatch({
			type: analyticsConstants.SET_FAILURE_ANALYSIS_FILTER,
			payload: failureAnalysisfilter,
		});
		if (fetchDataAfterApplying) {
			dispatch(fetchData());
		}
	};
}

function applyStatisticalFilter(
	statisticalFilter,
	fetchDataAfterApplying = true
) {
	return (dispatch) => {
		if (statisticalFilter?.selected === true) {
			// get the first brand and apply it
			const brand = statisticalFilter?.selectedBrands?.[0];
			// set analytics type as statistical
			dispatch(
				setAnalyticsType(
					analyticsTypeConstant.QUALITY_STATISTICAL_ANALYTICS,
					{},
					false
				)
			);
			dispatch(
				applyBrandFilter(
					{
						selectedList: [brand],
					},
					false
				)
			);
		} else {
			dispatch(
				setAnalyticsType(
					analyticsTypeConstant.QUALITY_ANALYTICS_CHART_DATA,
					{},
					false
				)
			);
		}
		dispatch({
			type: analyticsConstants.SET_STATISTICAL_FILTER,
			payload: statisticalFilter,
		});
		if (fetchDataAfterApplying) {
			dispatch(fetchData());
		}
	};
}
function applyUndetectedProductFilter(
	undetectedProductsFilter,
	fetchDataAfterApplying = true
) {
	return (dispatch) => {
		dispatch({
			type: analyticsConstants.SET_UNDETECTED_PRODUCTS_FILTER,
			payload: undetectedProductsFilter,
		});
		if (fetchDataAfterApplying) {
			dispatch(fetchData());
		}
	};
}

function applyProductFilter(productFilter, fetchDataAfterApplying = true) {
	return (dispatch) => {
		dispatch({
			type: analyticsConstants.SET_PRODUCT_FILTER,
			payload: productFilter,
		});
		if (fetchDataAfterApplying) {
			dispatch(fetchData());
		}
	};
}

function resetValues(obj) {
	return (dispatch) => {
		dispatch({
			type: analyticsConstants.FETCH_ANALYTICS_SUCCESS,
			payload: obj,
		});
	};
}
function resetFilters(filters, fetchDataAfterResetting = true) {
	return (dispatch) => {
		filters.forEach((filter) => {
			let filterValue = {
				selection: {},
			};
			switch (filter) {
				case 'TIME_FILTER':
					filterValue = {
						text: getWeekOfMonth(new Date(), {
							weekStartsOn: 1,
						}),
						active: true,
						type: 'week',
						selection: {
							year: new Date().getFullYear(),
							month: timeFilterMap.month[
								new Date().getUTCMonth()
							],
							day: null,
							hour: null,
							week: getWeekOfMonth(new Date(), {
								weekStartsOn: 1,
							}),
						},
					};
					dispatch(applyTimeFilter(filterValue, false));
					break;
				case 'DATE_FILTER':
					filterValue = {
						text: getDate(new Date()),
						active: true,
						type: 'day',
						selection: {
							year: new Date().getFullYear(),
							month: timeFilterMap.month[
								new Date().getUTCMonth()
							],
							day: getDate(new Date()),
							hour: null,
							week: getWeekOfMonth(new Date(), {
								weekStartsOn: 1,
							}),
						},
					};
					dispatch(applyDateFilter(filterValue, false));
					break;
				case 'GEO_FILTER':
					filterValue = {
						state: [],
						city: [],
						store: [],
					};
					dispatch(applyGeoFilter(filterValue, false));
					break;
				case 'BRAND_FILTER':
					filterValue = {
						selectedList: [],
					};
					dispatch(applyBrandFilter(filterValue, false));
					break;
				case 'STORE_FILTER':
					filterValue = {
						active: false,
						selectedStores: [],
					};
					dispatch(applyStoreFilter(filterValue, false));
					break;
				case 'PROCESS_TYPE_FILTER':
					filterValue = {
						active: false,
						selectedProcessTypes: [],
					};
					dispatch(applyProcessTypeFilter(filterValue, false));
					break;
				case 'SKU_TYPE_FILTER':
					filterValue = {
						active: false,
						selectedSkuTypes: [],
					};
					dispatch(applySkuTypeFilter(filterValue, false));
					break;
				case 'FROM_STORE_FILTER':
					filterValue = {
						active: false,
						selectedStores: [],
					};
					dispatch(applyFromStoreFilter(filterValue, false));
					break;
				case 'FAILURE_ANALYSIS_FILTER':
					filterValue = {
						WEIGHT: {
							'over-weight': false,
							'under-weight': false,
						},
						TEMPERATURE: {
							'over-temperature': false,
							'under-temperature': false,
						},
						DEFECT: {
							applied: false,
							defects: [],
						},
					};
					dispatch(applyFailureAnalysis(filterValue, false));
					break;
				case 'STATISTICAL_FILTER':
					filterValue = {
						selectedBrands: [],
						selectedProductIds: [],
						selected: false,
						filter: 'Weight',
					};
					dispatch(applyStatisticalFilter(filterValue, false));
					break;
				case 'INVENTORY_ANALYTICS':
					filterValue = {
						INVENTORY_ANALYTICS: {
							STOCK_IN_LIST: {
								data: [],
							},
							STOCK_OUT_LIST: {
								data: [],
							},
						},
					};
					dispatch(resetValues(filterValue));
					break;
				case 'QUALITY_ANALYTICS':
					filterValue = {
						QUALITY_ANALYTICS: {
							CHART_DATA: {
								brands: {},
								statsData: {},
								chartData: {
									labels: [],
									passed: [],
									failed: [],
								},
							},
							LIST_DATA: {
								listData: [],
							},
						},
					};
					dispatch(resetValues(filterValue));
					break;
				case 'STORE_ANALYSIS':
					filterValue = {
						STORE_ANALYSIS: {
							data: {
								metrics: {},
								graphData: {
									labels: [],
									storeCogs: [],
									storeMargin: [],
									storeRevenue: [],
								},
								ordersData: [],
								pieData: {
									labels: [],
									data: [],
								},
								popularGraphData: {
									day: {
										data: [],
										labels: [],
									},
									time: {
										data: [],
										labels: [],
									},
								},
							},
						},
					};
					dispatch(resetValues(filterValue));
					break;

				case 'PRODUCT_FILTER':
					dispatch(
						resetValues({
							PRODUCT_FILTER: [],
						})
					);
					break;
				default:
					break;
			}
		});
		if (fetchDataAfterResetting) {
			dispatch(fetchData());
		}
	};
}

/**
 *
 * @param {object} filters containing filter names as keys, and new values as values
 * @param {boolean} fetchDataAfterApplying whether to fetch data after applying filters
 */
function applyFilters(filters, fetchDataAfterApplying = true, req = {}) {
	return (dispatch) => {
		Object.keys(filters).forEach((filter) => {
			const filterValue = filters[filter];
			switch (filter) {
				case 'TIME_FILTER':
					dispatch(applyTimeFilter(filterValue, false));
					break;
				case 'DATE_FILTER':
					dispatch(
						applyDateFilter(
							{
								...filterValue,
							},
							false
						)
					);
					break;
				case 'GEO_FILTER':
					dispatch(
						applyGeoFilter(
							{
								...filterValue,
							},
							false
						)
					);
					break;
				case 'BRAND_FILTER':
					dispatch(
						applyBrandFilter(
							{
								...JSON.parse(JSON.stringify(filterValue)),
							},
							false
						)
					);
					break;
				case 'STORE_FILTER':
					dispatch(
						applyStoreFilter(
							{
								...filterValue,
							},
							false
						)
					);
					break;
				case 'PROCESS_TYPE_FILTER':
					dispatch(
						applyProcessTypeFilter(
							{
								...filterValue,
							},
							false
						)
					);
					break;
				case 'SKU_TYPE_FILTER':
					dispatch(
						applySkuTypeFilter(
							{
								...filterValue,
							},
							false
						)
					);
					break;
				case 'FROM_STORE_FILTER':
					dispatch(
						applyFromStoreFilter(
							{
								...filterValue,
							},
							false
						)
					);
					break;
				case 'FAILURE_ANALYSIS_FILTER':
					dispatch(
						applyFailureAnalysis(
							{
								...filterValue,
							},
							false
						)
					);
					break;

				case 'PRODUCT_FILTER':
					dispatch(applyProductFilter([...filterValue], false));
					break;

				case 'STATISTICAL_FILTER':
					dispatch(
						applyStatisticalFilter(
							{
								...filterValue,
							},
							false
						)
					);
					break;
				case 'UNDETECTED_PRODUCTS_FILTER':
					dispatch(
						applyUndetectedProductFilter(
							{
								...filterValue,
							},
							false
						)
					);
					break;
				// case 'MEAL_TYPE_FILTER':
				// 	dispatch(
				// 		applyMealTypeFilter(
				// 			{
				// 				...filterValue,
				// 			},
				// 			false
				// 		)
				// 	);
				// 	break;
				case 'LIST_DATA_FILTER':
					dispatch(
						applyListDataFilter(
							{
								...filterValue,
							},
							false
						)
					);
					break;
				case 'DUPLICATE_DATA_FILTER':
					dispatch(
						applyDuplicateDataFilter(
							{
								...filterValue,
							},
							false
						)
					);
					break;
				case 'VALIDATED_DATA_FILTER':
					dispatch(
						applyValidateDataFilter(
							{
								...filterValue,
							},
							false
						)
					);
					break;
				case 'IDENTIFIED_DATA_FILTER':
					dispatch(
						applyIdentifiedDataFilter(
							{
								...filterValue,
							},
							false
						)
					);
					break;
				case 'FEATURES_DATA_FILTER':
					dispatch(
						applyFeaturesDataFilter(
							{
								...filterValue,
							},
							false
						)
					);
					break;
				case 'PRODUCT_COUNT_FILTER':
					dispatch(
						applyProductCountFilter(
							{
								...filterValue,
							},
							false
						)
					);
					break;
				case 'IMS_STOCK_FILTER':
					dispatch(
						applyIMSStockFilter(
							{
								...filterValue,
							},
							false
						)
					);
					break;
				case 'GROUP_TYPE_FILTER':
					dispatch(
						setTypeFilter(
							{
								...filterValue,
							}
						)
					);
					break;

				default:
					break;
			}
		});

		if (fetchDataAfterApplying) {
			dispatch(fetchData(req));
		}
	};
}

function setSelectedListId(id) {
	return (disptach) => {
		disptach({
			type: analyticsConstants.SET_SELECTED_LIST_ID,
			payload: {
				id,
			},
		});
	};
}

function setQCSearchResults(query, data, searchBy) {
	return {
		type: analyticsConstants.SET_QC_SEARCH_RESULT,
		payload: {
			list: data,
			query,
			searchBy,
		},
	};
}
function setCogsSearchResults(query, data, searchBy) {
	return {
		type: analyticsConstants.SET_COGS_SEARCH_RESULT,
		payload: {
			list: data,
			query,
			searchBy,
		},
	};
}
function setBomSearchResults(query, data, searchBy) {
	return {
		type: analyticsConstants.SET_BOM_SEARCH_RESULT,
		payload: {
			list: data,
			query,
			searchBy,
		},
	};
}

function updateQCList(listIndex, data, comingId = '') {
	return (dispatch, getState) => {
		const clonedSearchData = cloneDeep(
			getState().analytics.QUALITY_ANALYTICS.SEARCH_DATA
		);
		if (data?.type === 'CMS') {
			const updatedData = {
				...data,
				id: Number(comingId),
				currentRecipeId: data?.id,
			};
			data = updatedData;
		}
		clonedSearchData?.list?.splice(listIndex, 1, data);
		const { query, list, searchBy } = clonedSearchData;
		dispatch(setQCSearchResults(query, list, searchBy));
	};
}
function setIMSSearchResults(query, data, searchBy) {
	return {
		type: analyticsConstants.SET_IMS_SEARCH_RESULT,
		payload: {
			list: data,
			query,
			searchBy,
		},
	};
}

// function filterStockInventory(key, callback) {
// return (dispatch, getState) => {
// const clonedData = cloneDeep(
// getState()?.analytics?.INVENTORY_ANALYTICS?.[key]
// );
// let filteredData = {};
// Object.keys(clonedData)?.forEach((eleKey) => {
// const item = callback(eleKey, clonedData[eleKey]);
// filteredData = {
// ...filteredData,
// ...item,
// };
// });
// console.log('filteredData', filteredData);
// dispatch({
// type: analyticsConstants.FETCH_ANALYTICS_SUCCESS,
// payload: {
// INVENTORY_ANALYTICS: {
// ...getState()?.analytics?.INVENTORY_ANALYTICS,
// [key]: filteredData,
// },
// },
// });
// };
// }

function updateIMSList(listIndex, data) {
	return (dispatch, getState) => {
		const clonedSearchData = cloneDeep(
			getState().analytics.INVENTORY_ANALYTICS.SEARCH_DATA
		);
		clonedSearchData?.list?.splice(listIndex, 1, data);
		const { query, list, searchBy } = clonedSearchData;
		dispatch(setIMSSearchResults(query, list, searchBy));
	};
}

function updateDefectImagesList(index, data) {
	return (dispatch, getState) => {
		const clonedData = cloneDeep(
			getState().analytics.DEFECT_ANALYSIS.FIND_DEFECT_IMAGES
		);
		const childLen = clonedData?.[index]?.Annotation.length;
		clonedData?.[index]?.Annotation?.splice(childLen, 0, ...data);
		dispatch({
			type: analyticsConstants.SET_DEFECT_IMAGES,
			payload: clonedData,
		});
	};
}

function updateContainerImagesList(index, data) {
	return (dispatch, getState) => {
		const clonedData = cloneDeep(
			getState().analytics.CONTAINER_ANALYSIS.FIND_CONTAINER_IMAGES
		);
		const childLen = clonedData?.[index]?.Annotation.length;
		clonedData?.[index]?.Annotation?.splice(childLen, 0, ...data);
		dispatch({
			type: analyticsConstants.SET_CONTAINER_IMAGES,
			payload: clonedData,
		});
	};
}
function updateTemperatureDigitImagesList(index, data) {
	return (dispatch, getState) => {
		const clonedData = cloneDeep(
			getState().analytics.TEMPERATURE_ANALYSIS
				.FIND_DIGIT_TEMPERATURE_IMAGES
		);
		const childLen = clonedData?.[index]?.Annotation.length;
		clonedData?.[index]?.Annotation?.splice(childLen, 0, ...data);
		dispatch({
			type: analyticsConstants.SET_DIGIT_TEMPERATURE_IMAGES,
			payload: clonedData,
		});
	};
}
function updateTemperatureImagesList(index, data) {
	return (dispatch, getState) => {
		const clonedData = cloneDeep(
			getState().analytics.TEMPERATURE_ANALYSIS.FIND_TEMPERATURE_IMAGES
		);
		const childLen = clonedData?.[index]?.Annotation.length;
		clonedData?.[index]?.Annotation?.splice(childLen, 0, ...data);
		dispatch({
			type: analyticsConstants.SET_TEMPERATURE_IMAGES,
			payload: clonedData,
		});
	};
}

function resetDefectImages() {
	return (dispatch) => {
		dispatch({
			type: analyticsConstants.RESET_DEFECT_ANALYSIS,
			payload: {
				FIND_DEFECT_IMAGES: [],
				FIND_DEFECT_IMAGES_BY_ID: [],
			},
		});
	};
}
function resetContainerImages() {
	return (dispatch) => {
		dispatch({
			type: analyticsConstants.RESET_CONTAINER_ANALYSIS,
			payload: {
				FIND_CONTAINER_IMAGES: [],
				FIND_CONTAINER_IMAGES_BY_ID: [],
			},
		});
	};
}
function resetDigitTemperatureImages() {
	return (dispatch) => {
		dispatch({
			type: analyticsConstants.RESET_DIGIT_TEMPERATURE_ANALYSIS,
			payload: {
				FIND_DIGIT_TEMPERATURE_IMAGES: [],
				FIND_DIGIT_TEMPERATURE_IMAGES_BY_ID: [],
			},
		});
	};
}
function resetTemperatureImages() {
	return (dispatch) => {
		dispatch({
			type: analyticsConstants.RESET_TEMPERATURE_ANALYSIS,
			payload: {
				FIND_TEMPERATURE_IMAGES: [],
				FIND_TEMPERATURE_IMAGES_BY_ID: [],
			},
		});
	};
}
function resetOutwardingImages() {
	return (dispatch) => {
		dispatch({
			type: analyticsConstants.RESET_OUTWARDING_ANALYSIS,
			payload: {
				FIND_OUTWARDING_IMAGES: [],
				FIND_OUTWARDING_IMAGES_BY_ID: [],
			},
		});
	};
}

function resetImages(type) {
	return (dispatch) => {
		dispatch({
			type,
			payload: {
				FIND_IMAGES: [],
				FIND_IMAGES_BY_ID: [],
			},
		});
	};
}

function resetInventoryAnalyticsState({ entity, method, data }) {
	return (dispatch, getState) => {
		const clonedState = cloneDeep(getState().analytics);
		dispatch({
			type: analyticsConstants.UPDATE_ANALYTICS,
			payload: {
				[entity]: {
					...clonedState[entity],
					[method]: data,
				},
			},
		});
	};
}

function resetWastageImages() {
	return (dispatch) => {
		dispatch({
			type: analyticsConstants.RESET_WASTAGE_ANALYSIS,
			payload: {
				FIND_WASTAGE_IMAGES: [],
				FIND_WASTAGE_IMAGES_BY_ID: [],
			},
		});
	};
}
function resetWastageComponents() {
	return (dispatch) => {
		dispatch({
			type: analyticsConstants.RESET_WASTAGE_COMPONENTS,
			payload: {
				FIND_WASTAGE_COMPONENTS: [],
				FIND_WASTAGE_COMPONENTS_BY_ID: [],
			},
		});
	};
}

function resetCookingImages() {
	return (dispatch) => {
		dispatch({
			type: analyticsConstants.RESET_COOKING_ANALYSIS,
			payload: {
				FIND_COOKING_IMAGES: [],
				FIND_COOKING_IMAGES_BY_ID: [],
			},
		});
	};
}

function updateProductImagesList(index, data, typeRefresh = false) {
	return (dispatch, getState) => {
		const clonedData = cloneDeep(
			getState().analytics.AI_PRODUCTION_ANALYSIS.FIND_IMAGES
		);
		const childLen = clonedData?.[index]?.Annotation.length;
		if (typeRefresh) {
			clonedData[index].Annotation = data;
		} else {
			clonedData?.[index]?.Annotation?.splice(childLen, 0, ...data);
		}
		dispatch({
			type: analyticsConstants.SET_PRODUCT_IMAGES,
			payload: clonedData,
		});
	};
}
function updateWastageImagesList(index, data) {
	return (dispatch, getState) => {
		const clonedData = cloneDeep(
			getState().analytics.WASTAGE_ANALYSIS.FIND_WASTAGE_IMAGES
		);
		const childLen = clonedData?.[index]?.Annotation.length;
		clonedData?.[index]?.Annotation?.splice(childLen, 0, ...data);
		dispatch({
			type: analyticsConstants.SET_WASTAGE_IMAGES,
			payload: clonedData,
		});
	};
}
function updateWastageComponentsList(index, data) {
	return (dispatch, getState) => {
		const clonedData = cloneDeep(
			getState().analytics.WASTAGE_COMPONENTS.FIND_WASTAGE_COMPONENTS
		);
		const childLen = clonedData?.[index]?.Annotation.length;
		clonedData?.[index]?.Annotation?.splice(childLen, 0, ...data);
		dispatch({
			type: analyticsConstants.SET_WASTAGE_COMPONENTS,
			payload: clonedData,
		});
	};
}
function updateOutwardingImagesList(index, data) {
	return (dispatch, getState) => {
		const clonedData = cloneDeep(
			getState().analytics.OUTWARDING_ANALYSIS.FIND_OUTWARDING_IMAGES
		);
		const childLen = clonedData?.[index]?.Annotation.length;
		clonedData?.[index]?.Annotation?.splice(childLen, 0, ...data);
		dispatch({
			type: analyticsConstants.SET_OUTWARDING_IMAGES,
			payload: clonedData,
		});
	};
}

function updateImagesList(index, data, dataType, constant) {
	return (dispatch, getState) => {
		const clonedData = cloneDeep(
			getState().analytics?.[dataType].FIND_IMAGES
		);
		const childLen = clonedData?.[index]?.Annotation.length;
		clonedData?.[index]?.Annotation?.splice(childLen, 0, ...data);
		dispatch({
			type: analyticsConstants?.[constant],
			payload: clonedData,
		});
	};
}

function resetProductImages() {
	return (dispatch) => {
		dispatch({
			type: analyticsConstants.RESET_PRODUCT_ANALYSIS,
			payload: {
				FIND_IMAGES: [],
				FIND_IMAGES_BY_ID: [],
				LIST_DATA: [],
				SEARCH_DATA: {
					list: [],
					query: '',
					searchBy: 'Order_ID',
				},
			},
		});
	};
}

function setAnalysisDashboardFilters(filterState, fetchDataAfterApplying = true) {
	return (dispatch) => {
		dispatch({
			type: analyticsConstants.SET_ANALYSIS_DASHBOARD_FILTERS,
			payload: filterState,
		});

		if (fetchDataAfterApplying) {
			dispatch(fetchData());
		}
	};
}

export default analyticsAction;
