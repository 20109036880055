/* eslint-disable */
import { connect } from 'react-redux';
import { modalActions } from '@/Actions';

function DragDropImage(props) {
    const { handleImageUpload, name } = props;

    return (
        <>
            <div className='flex flex-col items-center justify-center w-full h-full border-2 border-dashed border-gray-300 rounded-lg'>
            <div className='text-center'>
                <p className='text-sm text-black'>Drag and drop image</p>
                <p className='text-sm text-black mb-2'>Or</p>
                <label htmlFor='displayImg' className='px-4 py-2 text-sm text-black bg-yellow-500 rounded hover:bg-yellow-600 cursor-pointer'>
                    Upload Image
                </label>
                <input
                    id='displayImg'
                    type='file'
                    name={name}
                    accept='image/jpg, image/png, image/jpeg, image/webp'
                    className='hidden'
                    onChange={(e) => handleImageUpload(e)} />
            </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {
    toggleImageCrop: modalActions.toggleImageCrop,
};

export default connect(mapStateToProps, mapDispatchToProps)(DragDropImage);
