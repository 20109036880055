/* eslint-disable*/
import { connect } from 'react-redux';
import _ from 'lodash';
import { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { formActions } from '../../../Actions';
import { GeneralDetails } from '../../../Components/Forms/ProductCategory/GeneralDetails';
import { ViewPageHeader } from '../../../Components/Headers';
import {
	StyledTab,
	StyledTabs,
	TabPanel,
} from '../../../Components/StyledTabs';
import {
	generateDisabledProps, history, isEmpty
} from '../../../Helpers';
/* eslint-disable linebreak-style */

import ProductsConfig from './ProductCategory.config';
import { useAuth } from '@/Components/Auth/Login/auth';
import authActions from '@/Actions/auth.actions';
import { Label } from '@/Components/ui/label';
import { Switch } from '@mui/material';
import AddParameterComp from '@/Components/AddProductParameters/addParameterComp';
import QualityCheck from '@/Components/AddProductParameters/qualityCheck';
import QualityConfig from '@/Components/AddProductParameters/qualityConfig';

const TABS = ['GENERAL DETAILS'];

const ProductViewPage = (props) => {
	const {
		fetchProductCategory,
		data,
		deleteProductCategory,
		breadcrumbs,
		navState,
		fetchUserList,
		userList,
	} = props;

	const [state, setState] = useState({
		activeTab: 0,
		fields: _.cloneDeep(ProductsConfig.initialState.fields),
	});
	const [newList, setNewList] = useState([]);
	const [qualityCheck, setQualityCheck] = useState({
		qualityCheckEnabled : true,
		categoryConfiguration : false,
		minSamplingQuantity: 0,
		weightCheck: false,
		temperatureCheck: {
			enabled : false,
			minTemp: 0,
			maxTemp: 0,
		},
		defectCheck: {
			enabled : false,
			areaThreshold: 0,
			countThreshold: 0,
			reject: 'both',
		},
	});
	const { userConfig } = useAuth();

	// userAcess
	// useEffect(async () => {
	// 	fetchUserList();
	// }, []);
	// // function page role of user access

	// const map = new Map(userList.map((item) => [item.value, item.label]));
	// const role = Object.keys(userConfig?.userRoles);
	// const userRoles = [];
	// role.forEach((ele) => {
	// 	userRoles.push(ele);
	// });
	// const pageRole = navState['/products']?.submenu?.['/products/category']?.rolesMapped;
	// const findMatchingRole = userRoles.filter((ele) => pageRole.includes(Number(ele)));
	// const roleDetails = findMatchingRole.map((role) => {
	// 	const key = Number(role);
	// 	const name = map.get(key);
	// 	return {
	// 		role: key, name,
	// 	};
	// });
	// const isInternal = roleDetails.some((item) => item.name === 'Internal' || item.name === 'CXO Office' || item.name === 'Production' || item.name === 'Procurement' || item.name === 'Distribution And Supply Chain');
	// const access = findMatchingRole.length > 0 ? userConfig?.userDetails?.level : false;
	// let permit;
	// if (access === false) {
	// 	permit = false;
	// } else {
	// 	permit = isInternal && access !== 1;
	// }
	// USER END
	const ProductCategoryId = useRouteMatch().params.id;
	useEffect(() => {
		fetchProductCategory({
			entity: 'PRODUCT_CATEGORY',
			req: {
				params: {
					id: ProductCategoryId,
				},
			},
			method: 'VIEW',
		});
	}, []);

	useEffect(() => {
		const newState = {}
		if (!isEmpty(data)) {
			setState(ProductsConfig.serializer(state, data));
			newState.qualityCheckEnabled = data?.QualityAnalysisEnabled;
			newState.minSamplingQuantity = data?.minimumSamplingQuantityPercentage;
			setQualityCheck(newState);
			setNewList(data?.qualityParameters);
		}
	}, [data]);

	const handleTabChange = (event, tab) => {
		setState({
			...state,
			activeTab: tab,
		});
	};
	const { activeTab, fields } = state;

	const renderInfo = (idx) => {
		switch (idx) {
			case 0:
				return (
				<div className='flex flex-col gap-2 w-full'>
					<GeneralDetails isDisabled fields={fields} />
					<div className='flex items-center gap-2 m-[1rem] mt-4 w-full'>
					<Label className='text-lg text-gray-600 font-medium'>Quality Analysis Inspection</Label>
					</div>
						<QualityConfig
							qualityCheck={qualityCheck}
							setQualityCheck={setQualityCheck}
							isDisabled
							hideFeilds
						/>
						{/* <QualityCheck
							qualityCheck={qualityCheck}
							setQualityCheck={setQualityCheck}
							isDisabled
						/> */}
						<div className={`transition-all duration-700 ease-in-out transform origin-top ${
        		 		 	qualityCheck?.qualityCheckEnabled ? 'max-h-[500px] scale-y-100 opacity-100' : 'max-h-[0px] scale-y-0 opacity-0'
    					}`}>
						<AddParameterComp
							selectedParameters={newList}
							setNewList={setNewList}
							isDisabled
						/>
						</div>
					</div>
				);
			default:
				return <></>;
		}
	};

	const { url } = useRouteMatch();

	const redirectToEdit = () => {
		const splitURL = url.split('/view');
		splitURL.splice(1, 0, '/edit');
		const newURL = splitURL.join('');
		history.push(newURL);
	};
	const handleDelete = () => {
		const splitURL = url.split('/view');
		deleteProductCategory({
			entity: 'PRODUCT_CATEGORY',
			req: {
				params: {
					id: ProductCategoryId,
				},
			},
			method: 'DELETE',
		});
		history.push(splitURL[0]);
	};
	return (
		<div className='root'>
			<ViewPageHeader
				heading='View Product Category'
				handleEdit={redirectToEdit}
				handleDelete={handleDelete}
				breadcrumbs={[
					...breadcrumbs,
					{
						link: '',
						name: `View ${state.fields.name}`,
					},
				]}
				showBreadcrumbs
			/>
			<StyledTabs value={activeTab} onChange={handleTabChange}>
				{TABS.map((tab) => {
					return <StyledTab label={tab} />;
				})}
			</StyledTabs>
			{TABS.map((tab, idx) => {
				return (
					<TabPanel value={activeTab} index={idx}>
						<fieldset
							{...generateDisabledProps(true)}
							className='form-fieldset row'
							style={{
								alignItems: 'flex-start',
							}}
						>
							{renderInfo(idx)}
						</fieldset>
					</TabPanel>
				);
			})}
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.PRODUCT_CATEGORY.data.VIEW;
	const { navState, userPermissions } = state.navigation;
	const { userList } = state?.auth;
	return {
		data,
		navState,
		userPermissions,
		userList,
	};
};

const mapDispatchToProps = {
	fetchProductCategory: formActions.gateway,
	deleteProductCategory: formActions.gateway,
	fetchUserList: authActions?.fetchUserList,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductViewPage);
