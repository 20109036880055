/* eslint-disable no-unused-vars */
import DateRangePicker from 'rsuite/DateRangePicker';
import { useMemo } from 'react';

const { allowedMaxDays, afterToday, combine } = DateRangePicker;

function DateRangePickerRsuit(props) {
	const {
		MAX,
		dateRanges,
		disabled,
		enableOnlyBackwardSelection = true,
		onDateRangeChange,
		menuStyles = {},
		placement = 'bottomEnd',
	} = props;
	const valueChanged = (startAndEndDateArray) => {
		onDateRangeChange({
			selection: {
				startDate: startAndEndDateArray[0],
				endDate: startAndEndDateArray[1],
			},
		});
		// onDateRangeChange(startAndEndDateArray);
	};
	const typeDisable = useMemo(() => {
		if (MAX && enableOnlyBackwardSelection) {
			return combine(afterToday(), allowedMaxDays(MAX));
		}
		if (MAX) return allowedMaxDays(MAX);
		if (enableOnlyBackwardSelection) return afterToday();
		return null;
	}, []);
	// const val=[]
	return (
		<DateRangePicker
			isoWeek
			disabled={!!disabled}
			ranges={[]}
			placement={placement}
			format='dd-MM-yyyy'
			placeholder='Start Date  --  End Date'
			value={[dateRanges[0].startDate, dateRanges[0].endDate]}
			shouldDisableDate={typeDisable}
			onOk={valueChanged}
			showWeekNumbers
			style={{
				width: 250,
				zIndex: 999,
			}}
			menuStyle={menuStyles}
		/>
	);
}
DateRangePickerRsuit.defaultProps = {
	dateRanges: [
		{
			startDate: new Date(),
			endDate: new Date(),
			key: 'selection',
		},
	],
	onDateRangeChange: () => {},
};

// export default connect(mapStateToProps, mapDispatchToProps)(DateRangePickerRsuit);
export default DateRangePickerRsuit;
