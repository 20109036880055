/* eslint-disable no-unneeded-ternary */
/* eslint-disable */
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Button } from '@mui/material';
import Toggle from 'rsuite/Toggle';
import excel from 'src/Images/excel.svg';
import styles from './MyUsersPage.module.scss';
// import ListTable from '../../../../Components/ListTable/ListTable';
import { BackdropLoader } from '../../../Components/Loaders';
import ListTable from '../../../Components/ListTable/ListTable';
import ImageModal from '../../../Components/Modals/ImageModal/ImageModal';
import { modalActions } from '../../../Actions';
import { notificationServices } from '../../../Services';
import ImagesFinder from '../../../Components/ImagesFinder/ImagesFinder';
import userRolesAndPermissions from '../../../Services/userRolesAndPermissions.services';
import { handleResponseStreamDownload } from '@/Helpers';
import authActions from '@/Actions/auth.actions';
import { useAuth } from '@/Components/Auth/Login/auth';

const TABLE_COLUMNS = [
	{
		title: 'ID',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Username',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Display Name',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'App Store',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Email',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [
	{
		title: 'Edit User',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Active',
		config: {
			type: 'DATA',
		},
	},
];

const COLMAP = {
	ID: 'id',
	Username: 'username',
	'Display Name': 'displayName',
	'App Store': 'storeName',
	Email: 'email',
};

function MyUsersPermissionsPage({ createPath, userPermissions, navState, userList, fetchUserList }) {
	const history = useHistory();
	const [isloading, setIsloading] = useState(false);
	const [usersData, setUsersData] = useState([]);
	const [selectedStore, setSelectedStore] = useState(null);
	const { userConfig } = useAuth();

	const handleListFetch = async () => {
		setIsloading(true);
		try {
			const response = await userRolesAndPermissions.getUsersList();
			if (response?.statusCode === 200) {
				setSelectedStore(selectedStore);
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
				setUsersData(response?.data);
			}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message:
					'Unable to fetch users list, please connect with UY Team',
			});
		} finally {
			setIsloading(false);
		}
	};

	useEffect(() => {
		handleListFetch();
		// fetchUserList();
	}, []);

	// const map = new Map(userList.map((item) => [item.value, item.label]));
	// const role = Object.keys(userConfig?.userRoles);
	// const userRoles = [];
	// role.forEach((ele) => {
	// 	userRoles.push(ele);
	// });
	// const pageRole = navState['/account']?.submenu['/account/access/user']?.rolesMapped;
	// const findMatchingRole = userRoles.filter((ele) => pageRole.includes(Number(ele)));
	// const roleDetails = findMatchingRole.map((role) => {
	// 	const key = Number(role);
	// 	const name = map.get(key);
	// 	return {
	// 		role: key, name,
	// 	};
	// });
	// const isInternal = roleDetails.some((item) => item.name === 'Internal' || item.name === 'CXO Office');
	// const access = findMatchingRole.length > 0 ? userConfig?.userDetails?.level : false;
	// let permit;
	// if (access === false) {
	// 	permit = false;
	// } else {
	// 	permit = isInternal && access !== 1;
	// }

	const handleAddClick = () => {
		history.push(createPath);
	};

	const handleClick = (userId) => {
		history.push(`${createPath}?isEdit=true&id=${userId}`);
	};

	const handleIsActive = async (id, isActive) => {
		setIsloading(true);
		try {
			const response =
				await userRolesAndPermissions.changeUserActiveStatus({
					data: {
						userId: id,
						status: isActive,
					},
				});
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
				handleListFetch();
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log('error', error);
		} finally {
			setIsloading(false);
		}
	};

	const SPECIAL_ITEMS = {
		'Edit User': (id) => {
			const user = usersData?.find((ele) => {
				return ele?.id === id;
			});
			return (
				<div className={styles.btn_container}>
					<Button
						fullWidth
						variant='outlined'
						color='primary'
						onClick={() => {
							handleClick(user?.id);
						}}
					>
						EDIT
					</Button>
				</div>
			);
		},
		Active: (id) => {
			const user = usersData?.find((ele) => {
				return ele?.id === id;
			});
			return (
				<div key={id} className={styles.btn_container}>
					<Toggle
						checked={user?.isActive}
						size='md'
						onChange={() => {
							handleIsActive(id, !user?.isActive);
						}}
					/>
				</div>
			);
		},
	};

	const handleExport = async (data) => {
		try {
			setIsloading(true);
			const res = await userRolesAndPermissions.getUserExport({
				req: {
					data,
				},
			});
			handleResponseStreamDownload(res, 'Users List.csv');
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error.message || 'Unknown error occurred',
			});
		}finally{
			setIsloading(false);
		}
	};

	return (
		<div className={styles.root}>
			{/* <ListModal handleConfirm={iAmClicked} singleSelectable /> */}
			<BackdropLoader showProgress isLoading={isloading} />
			<ImageModal />
			<div className={styles.header}>
				<ImagesFinder
					pageLabel='My Users'
					onSubmit={handleListFetch}
					additionInfo={{
						showAdd: userPermissions?.INTERNAL,
						// showAdd: false,
						addText: 'User',
						handleAddClick,
					}}
				/>
			</div>
			<div onClick={() => handleExport(usersData)} className='w-[8.5rem] h-[2.5rem] text-center cursor-pointer flex items-center justify-center gap-[0.5rem] z-10 bg-green-700 rounded-md absolute right-[6px]'>
				<img src={excel} alt='excel' className='w-6 scale-[1.5rem]' />
				<span className='text-white text-[13px]'>EXPORT</span>
			</div>

			<div className={styles.list}>
				<ListTable
					colMap={COLMAP}
					columns={TABLE_COLUMNS}
					data={usersData}
					actions={[...SPECIAL_COLUMNS]}
					special={SPECIAL_ITEMS}
					allowExport={false}
				/>
			</div>
		</div>
	);
}
const mapStateToProps = (state) => {
	const { navState, userPermissions } = state.navigation;
	const { userList } = state.auth;
	return {
		userPermissions,
		navState,
		userList,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleListModal,
	fetchUserList: authActions.fetchUserList,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MyUsersPermissionsPage);
