/* eslint-disable no-shadow */
/* eslint-disable no-tabs */
/* eslint-disable*/
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import {
	useCallback, useEffect, useState
} from 'react';
import {
	analyticsAction, formActions, modalActions
} from '@/Actions';
import drawerActions from '@/Actions/drawer.actions';
import { ListTable } from '@/Components/ListTable';
import ImagesFinder from '@/Components/ImagesFinder/ImagesFinder';
import { urls } from '@/Constants';
import { classes, handleOrderStatus } from '@/Helpers';
import styles from './StoreAssetOrdersList.module.scss';
import { Button as SButton } from '@/Components/ui/button';
import { notificationServices } from '@/Services';
import inventoryAnalyticsServices from '@/Services/Analytics Services/inventory-analytics.services';
import { POItemsModal } from '@/Components/Modals/POItemsModal';
import { getGroupedAssetObj } from '@/Components/AssetSelector/assetSelector.config';
import GroupAssetCartModal from '@/Components/AssetSelector/GroupAssetCartModal';
import { useAuth } from '@/Components/Auth/Login/auth';
import userRolesAndPermissions from '@/Services/userRolesAndPermissions.services';
import authActions from '@/Actions/auth.actions';

const TABLE_COLUMNS = [
	{
		title: 'Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Client PO ID',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Order Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Invoice Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Receive Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Dispatch By',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Order Status',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Deliver To',
		config: {
			type: 'DATA',
		},
	},
];
const SPECIAL_COLUMNS = [
	{
		title: 'Deliver From',
		config: {
			type: 'DATA',
		},
	},
	// {
	// title: 'Van Temp',
	// config: {
	// type: 'DATA',
	// },
	// },
	// {
	// title: 'Transit Van Image',
	// config: {
	// type: 'DATA',
	// },
	// },
];
const ACTION_COLUMNS = [
	{
		title: 'View More',
		config: {
			type: 'VIEW',
		},
	},
	{
		title: 'Action',
		config: {
			type: 'CANCEL',
		},
	},
];

const orderStatus = [
	'PENDING',
	'DISPATCH',
	'TRANSIT',
	'UNLOADING',
	'VERIFICATION',
	'FULFILLED',
	'CANCELLED',
	'ABANDONED',
];

const FIND_FILTERS = ['store', 'dateRangeWithType', 'status', 'timeType'];
const dateTypeArr = [
	{
		label: 'Dispatch By',
		value: 'scheduled',
	},
	{
		label: 'Order Date',
		value: 'ordered',
	},
	{
		label: 'Invoice Date',
		value: 'invoiced',
	},
	{
		label: 'Receive Date',
		value: 'received',
	},
];

const COLMAP = {
	Id: 'id',
	'Client PO ID': 'clientPoId',
	'Order Date': 'createdAt',
	'Invoice Date': 'invoicedAt',
	'Receive Date': 'receivedAt',
	'Dispatch By': 'scheduledAt',
	'Deliver To': 'storeName',
	BASE: 'fromStoreName',
	'Van Temp': 'receivingTemp',
	'Transit Van Image': 'receivingTempImage',
	// 'Transit Van Temp': 'receivingTemp',
	'Order Status': 'orderStatus',
	Vendor: 'vendorName',
};

const userPermissions = {
	MANAGER: true,
};
const SEARCH_BY = {
	options: [
		{
			key: 'clientPoId',
			title: 'Client Po Id',
		},
		{
			key: 'APPROVAL_PENDING',
			title: 'Approval Pending',
		},
	],
};
function StoreAssetOrdersList(props) {
	const {
		data,
		fetchPurchaseOrderList,
		toggleAlert,
		handlePoStatus,
		navState,
		toggleModal,
		userPermissions,
		userList,
		fetchUserList,
	} = props;
	const history = useHistory();
	const searchQuery = '';
	const searchBy = 'clientPoId';
	const [update, setUpdate] = useState(-1);
	const location = useLocation();
	const navRequisites = navState?.[urls.SALES_ORDERS]?.requisites ?? [];
	const selectedNavStore = navRequisites?.find((ele) => {
		return ele?.type === 'store';
	});

	const { userConfig } = useAuth();
	// userAcess
	// useEffect(() => {
	// 	fetchUserList();
	// }, [])
	// // function page role of user access
	// const map = new Map(userList.map((item) => [item.value, item.label]));
	// const role = Object.keys(userConfig?.userRoles);
	// const userRoles = [];
	// role.forEach((ele) => {
	// 	userRoles.push(ele);
	// });

	// const pageRole = navState['/analytics/po']?.submenu['/analytics/list/capex/vendor/orders']?.rolesMapped;
	// const findMatchingRole = userRoles.filter((ele) => pageRole.includes(Number(ele)));
	// const roleDetails = findMatchingRole.map((role) => {
	// 	const key = Number(role);
	// 	const name = map.get(key);
	// 	return {
	// 		role: key, name,
	// 	};
	// });
	// const isInternal = roleDetails.some((item) => item.name === 'Internal' || item.name === 'CXO Office' || item?.name === 'Procurement');
	// const vendorApproval = roleDetails.some((ele) => ele?.name === 'Procurement');
	// const name = roleDetails.some((ele) => ele?.name === 'CXO Office');
	// const access = findMatchingRole.length > 0 ? userConfig?.userDetails?.level : false;
	// let permit;
	// if (access === false) {
	// 	permit = false;
	// } else {
	// 	permit = isInternal &&  access !== 1;
	// }

	// end user Acces
	const [filters, setFilters] = useState({
		storeId: null,
		dateRange: {
			startDate: new Date(),
			endDate: new Date(),
		},
	});
	const handleFetchPurchaseOrderList = (finderFilters) => {
		const dateRange = {
			startDate: moment(finderFilters?.dateRange?.from).format(
				'YYYY-MM-DD'
			),
			endDate: moment(finderFilters?.dateRange?.to).format('YYYY-MM-DD'),
			dateType: finderFilters?.timeType,
		};
		setFilters((prev) => {
			return {
				...prev,
				dateRange,
				fulfilledBy: finderFilters?.store,
			};
		});
		const req = {
			data: {
				filters: {
					storeId: finderFilters?.store,
					fromStoreId:
						selectedNavStore?.value?.id === -999
							? null
							: selectedNavStore?.value?.id,
					dateRangeFilter: {
						from: moment(finderFilters?.dateRange?.from).format(
							'YYYY-MM-DD'
						),
						to: moment(finderFilters?.dateRange?.to).format(
							'YYYY-MM-DD'
						),
						dateType: dateRange.dateType,
					},
					status: finderFilters?.status,
					orderType: 'internalStoreAssetOrder',
				},
			},
		};
		fetchPurchaseOrderList({
			entity: 'INVENTORY_ANALYTICS',
			method: 'PO_LIST',
			req,
		});
	};

	const renderDownloadField = (item) => {
		return <div>{item?.id}</div>;
	};

	const handleApprovePo = async (item) => {
		// setIsloading(true);
		try {
			const responseData =
				await inventoryAnalyticsServices.changeOrderStatus({
					params: {
						poId: item?.id,
						status: 'APPROVE',
					},
				});
			if (responseData?.statusCode === 200) {
				setUpdate((prev) => {
					return prev + 1;
				});
				toggleModal(false, {});
				notificationServices?.generateNotification({
					type: 'success',
					message: responseData?.message,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
		// finally {
		// setIsloading(false);
		// }
	};
	const handleRejectPo = async (item, rejectionReason) => {
		// setIsloading(true);
		try {
			const responseData =
				await inventoryAnalyticsServices.changeOrderStatus({
					params: {
						poId: item?.id,
						status: 'REJECT',
						rejectionReason,
					},
				});
			if (responseData?.statusCode === 200) {
				setUpdate((prev) => {
					return prev + 1;
				});
				toggleModal(false, {});
				toggleAlert(false);

				notificationServices?.generateNotification({
					type: 'success',
					message: responseData?.message,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	const handleCancelPo = (id, clientPoId) => {
		// eslint-disable-next-line no-console
		(async () => {
			const notificationId = notificationServices.generateNotification({
				type: 'loading',
				message: `Cancelling Po ${clientPoId || ''}`,
			});
			try {
				const resp = await inventoryAnalyticsServices.changeOrderStatus(
					{
						params: {
							poId: id,
							status: 'CANCEL',
						},
					}
				);
				notificationServices.generateNotification({
					type: 'success',
					message: resp?.status,
					config: {
						id: notificationId,
					},
				});
				setUpdate((prev) => {
					return prev + 1;
				});
			} catch (error) {
				notificationServices.generateNotification({
					type: 'error',
					message: error,
					config: {
						id: notificationId,
					},
				});
			}
		})();
	};

	const showReviewableItems = (po, reviewable = false) => {
		const items = po?.items;
		const tCartItems = {};
		const tGroupedAssetObjs = items.reduce((acc, item) => {
			const groupedAssetObj = getGroupedAssetObj(item, 'PO_ITEM');
			acc[groupedAssetObj.groupBySpecsKey] = groupedAssetObj;
			tCartItems[groupedAssetObj.groupBySpecsKey] = item.qty;
			return acc;
		}, {});
		const isInternalObj = {
			'Deliver From Store': po?.vendorName,
			'Deliver To Store GSTIN': po?.storeGstin ?? '',
		};
		const assetGroupingSummaryData = {
			open: true,
			cartItems: tCartItems,
			groupedAssets: tGroupedAssetObjs,
			extraData: {
				heading: `Items of Order #${po?.clientPoId ?? po?.id}`,
				gridCss: '!grid-cols-2 !w-full',
				containerCss: 'h-[70%]',
			},
		};
		toggleModal(true, {
			heading: `Items of Internal Store Asset Order #${po?.clientPoId ?? po?.id}`,
			...po,
			approvalText: 'I have checked all the items of Internal Store Asset Order',
			showTotal: false,
			isApprove: reviewable,
			showPdf: true,
			showPoDetails: true,
			hideItems: true,
			assetGroupingSummaryData,
			transactionData: {
				'Order ID': po?.clientPoId,
				'Deliver To Store': po?.storeName,
				...isInternalObj,
				'Payment Term':
									po?.jsonData?.paymentTerm,
				'Order Date': moment(
					po?.createdAt
				).format('YYYY-MM-DD'),
				'Dispatch By': moment(
					po?.scheduledAt
				).format('YYYY-MM-DD'),
			},
			width: '90%',
			height: '90%',
		});
	};

	const SPECIAL_ITEMS = {
		Download: (itemId) => {
			const item = data?.find((poItem) => {
				return poItem?.id === itemId;
			});
			return renderDownloadField(item);
		},
		'Deliver From': (itemId) => {
			const item = data?.find((poItem) => {
				return poItem?.id === itemId;
			});
			return (
				<div
					style={{
						width: '200px',
						textAlign: 'left',
					}}
				>
					{item?.fromStoreName ?? '-'}
				</div>
			);
		},
		Action: (itemId) => {
			const item = data?.find((poItem) => {
				return poItem?.id === itemId;
			});
			const isInternalObj = {
				'Deliver From Store': item?.vendorName,
				'Deliver To Store GSTIN': item?.storeGstin ?? '',
			};
			return (
				<div className={styles.btns}>
					{(item?.orderStatus === 'APPROVAL_PENDING' &&
						item?.orderStatus !== 'APPROVAL_REJECTED' &&
						userPermissions.MANAGER) ? (
								<SButton
								onClick={() => {
									showReviewableItems(item, true);
								}}
								className='primary-btn w-full'
							>
								Review
							</SButton>
						) : null}
					{(item?.orderStatus === 'PENDING' || item?.orderStatus === 'APPROVAL_PENDING') ? (
						<div
							style={{
								width: '125px',
							}}
						>
							<SButton
								variant='outline'
								className='w-full border-red-600 text-red-600 bg-transparent'
								onClick={() => {
									toggleAlert(true, {
										heading: 'Cancel PO?',
										handleConfirm: () => {
											handleCancelPo(
												itemId,
												item?.clientPoId
											);
											toggleAlert(false);
										},
										colorReverse: false,
										cancelText: 'No',
										confirmText: 'Yes',
										message: `Are you sure you want to Cancel ${
											item?.clientPoId || ''
										}?`,
									});
								}}
								fullWidth
							>
								Cancel PO
							</SButton>
						</div>
					) : (
						'-'
					)}
				</div>
			);
		},
	};

	const viewMoreOptionsList = {
		'View Items': (po, cb) => {
			showReviewableItems(po, false);
			cb();
		},
	};


	return (
		<div className={styles.root}>
			<POItemsModal
				handleConfirm={handleApprovePo}
				handleReject={handleRejectPo}
			/>
			<div className={styles.header}>
				<ImagesFinder
					pageLabel='Asset Transfer Order'
					FILTERS={FIND_FILTERS}
					update={update}
					statusList={orderStatus?.map((ele) => {
						if (ele === 'FULFILLED') {
							return {
								id: ele,
								title: 'CLOSED',
							};
						}
						if (ele === 'TRANSIT') {
							return {
								id: ele,
								title: 'IN TRANSIT',
							};
						}
						if (ele === 'DISPATCH') {
							return {
								id: ele,
								title: 'PENDING DISPATCH',
							};
						}
						if (ele === 'UNLOADING') {
							return {
								id: ele,
								title: 'UNLOADING STARTED',
							};
						}
						if (ele === 'VERIFICATION') {
							return {
								id: ele,
								title: 'PENDING VERIFICATION',
							};
						}
						return {
							id: ele,
							title: ele,
						};
					})}
					onSubmit={handleFetchPurchaseOrderList}
					timeTypeArray={dateTypeArr}
					additionInfo={{
						showAdd: true,
						addText: 'Internal Asset Order',
						prefix: 'Create',
						handleAddClick: () => {
							const url =
								selectedNavStore?.value?.id !== -999
									? `${urls.EXTENSION}${urls.CREATE_PURCHASE_ORDER}?userOrderType=purchase&typeId=7&toStore=${selectedNavStore?.value?.id}`
									: `${urls.EXTENSION}${urls.CREATE_PURCHASE_ORDER}?userOrderType=purchase&typeId=7`;
							history.push(url, {
								prevPath: location?.pathname + location?.search,
							});
						},
					}}
					filterLabels={{
						store: 'Deliver To',
					}}
					orderText
				/>
			</div>

			<div
				className={styles?.table}
				style={{
					width: '100%',
				}}
			>
				<ListTable
					data={data?.map((ele) => {
						return {
							...ele,
							orderStatus: handleOrderStatus(ele?.orderStatus),
							rowStyles: classes(
								styles.row,
								styles?.[ele?.orderStatus]
							),
						};
					})}
					colMap={COLMAP}
					columns={TABLE_COLUMNS}
					actions={[...SPECIAL_COLUMNS, ...ACTION_COLUMNS]}
					special={SPECIAL_ITEMS}
					viewMoreOptionsList={viewMoreOptionsList}
				/>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	const { navState, userPermissions } = state.navigation;
	const stores = state?.form?.STORES?.data?.LIST;
	const { DATE_RANGE_FILTER: dateRangeFilter } = state.analytics;
	const { PO_LIST: data } = state?.analytics?.INVENTORY_ANALYTICS;
	const categoriesList = state.form.PRODUCT_CATEGORY.data.LIST;
	const subCategoriesList = state.form.PRODUCT_SUB_CATEGORY.data.LIST;
	const { userList } = state?.auth;
	return {
		data,
		stores,
		navState,
		dateRangeFilter,
		categoriesList,
		subCategoriesList,
		userPermissions,
		userList,
	};
};

const mapDispatchToProps = {
	fetchStoresList: formActions.gateway,
	setDateRangeFilter: analyticsAction.setDateRangeFilter,
	toggleModal: modalActions.togglePOItemsModal,
	toggleAlert: modalActions.toggleAlert,
	toggleVanImageModal: modalActions.toggleVanImageModal,
	toggleFileUploaderModal: modalActions.toggleFileUploaderModal,
	toggleProductionPlanModal: modalActions.toggleProductionPlanModal,
	fetchPurchaseOrderList: analyticsAction.gateway,
	fetchSubCategories: formActions.gateway,
	toggleExportFiltersDrawer: drawerActions.toggleExportFiltersDrawer,
	toggleDebitCreditListDrawer: drawerActions.toggleDebitCreditListDrawer,
	fetchUserList: authActions?.fetchUserList,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StoreAssetOrdersList);
