/* eslint-disable */
import { useState } from "react";
function MinMaxInput(props) {
    const {value, handleMinMaxChange, name, id, disabled = false, notNegative = false } = props;
    const [number, setNumber] = useState(0);
    const handleMinus = () => {
        if(notNegative){
            if(number > 0 ){
                setNumber(number - 1);
                handleMinMaxChange(name, number);
            }else {
                setNumber(0);
                handleMinMaxChange(name, 0);
            }
        }else{
            setNumber(number - 1);
            handleMinMaxChange(name, number);
        }
    };
    const handlePlus = () => {
        if(notNegative){
            if(number < 100){
                setNumber(number + 1);
                handleMinMaxChange(name, number);
            }else{
                setNumber(100);
                handleMinMaxChange(name, 100);
            }
        }else{
            setNumber(number + 1);
            handleMinMaxChange(name, number);
        }
    };  
    return (
        <div className='flex items-center w-40 h-10 border border-gray-200 rounded-md'>
            <button
                disabled={disabled}
                className='w-[4rem] h-full flex items-center justify-center text-2xl hover:bg-gray-100'
                onClick={handleMinus}
            >
                -
            </button> 
            <input
                type="number"
                disabled={disabled}
                className='w-[5rem] h-full text-center'
                value={value}
                onChange={(e) => handleMinMaxChange(name, Number(e.target.value))}
                name={name}
                id={id}
            />
            <button
                disabled={disabled}
                className='w-[4rem] h-full flex items-center justify-center text-2xl hover:bg-gray-100'
                onClick={handlePlus}
            >
                +
            </button>
        </div>
    );
}

export default MinMaxInput;
