/* eslint-disable*/
import {IconButton } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { ArrowCircleRight } from '@mui/icons-material';
import styles from '@/Pages/Analytics/ProductAnalysis/AiProductionHistory/AiProductionHistory.module.scss';
import excel from '@/Images/excel.svg';
import SearchBar from '@/Components/SearchBar/SearchBar';
import { formActions } from '@/Actions';
import { classes, formatDate, useQuery, useSearch } from '@/Helpers';
import Calender from '@/Components/Forms/Payment Settlements/Calender/Calender';
import GrnQcLogTable from '@/Components/QcTable/grnQcLogTable';
import { SelectPicker } from 'rsuite';
import { Tabs, TabsList, TabsTrigger } from '@/Components/ui/tabs';
import { grnQcLogService } from '@/Services/grnQcLog.services';
import { notificationServices } from '@/Services';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
// import Paginator from '../../../../Components/Paginator/Paginator';

const PLACE_HOLDERS = {
	PRODUCT_NAME: 'Search by Product Name',
	QC_ID: 'Search by QC Id',
};


const statusOptions = [
	{
		label: 'Pass',
		value: 'GRN',
	},
	{
		label: 'Fail',
		value: 'QC_REJECTED',
	},
    {
        label: 'Approval Pending',
        value: 'QC_APPROVAL_PENDING',
    }
];

const QualityLogs = (props) => {
	const {
		stores,
		fetchStoresList,
		searchQuery,
		searchBy,
	} = props;

	const [shouldAnimate, setShouldAnimate] = useState(true);
	const [isDataFetched, setIsDataFetched] = useState(false);
    const [storeFilter, setStoreFilter] = useState(null);
    const [statusFilter, setStatusFilter] = useState(null);
    const [data, setData] = useState([]);
    const [tabValue, setTabValue] = useState('grnQcLogs');
    const [dateRangeFilter, setDateRangeFilter] = useState({
        active: false,
        from: new Date(),
        to: new Date(),
    });

    const history = useHistory();
    const location = useLocation();
    const queryParams = useQuery();

    const FIELD_MAP = {
        PO_ID: 'clientPoId',
        PRODUCT_NAME: tabValue === 'grnQcLogs' ? 'productName' : 'ProductName',
        ORDER_ID: 'OrderId',
        // BRAND_NAME: 'brandName',
    };
    
    const SEARCH_BY = {
        options: [
            {
                key: 'PRODUCT_NAME',
                title: 'Product Name',
                get: tabValue === 'grnQcLogs' ? 'productName' : 'ProductName',
            },
            {
                key: 'PO_ID',
                title: 'PO Id',
                get: 'clientPoId',
            },
            {
                key: 'ORDER_ID',
                title: 'Order ID',
                get: 'OrderId',
            },
        ],
    };
    const grnQcLogColMap = {
		'PO Id': 'clientPoId',
		'Product Name': 'productName',
		'Qc Date': 'qcDate',
		Vendor: 'vendor',
		'Batch Quantity': 'batchQuantity',
		'Mfd.Date': 'manufactureDate',
		Status: 'status',
	};
	const grnQcLogSpecialColumns = [
		{
			title: 'PO Id',
			config: {
				type: 'DATA',
			},
		},
		{
			title: 'Product Name',
			config: {
				type: 'Data',
			},
		},
		{
			title: 'Qc Date',
			config: {
				type: 'Data',
			},
		},
		{
			title: 'Vendor',
			config: {
				type: 'Data',
			},
		},
		{
			title: 'Batch Quantity',
			config: {
				type: 'Data',
			},
		},
		{
			title: 'Mfd.Date',
			config: {
				type: 'Data',
			},
		},
		{
			title: 'Status',
			config: {
				type: 'Data',
			},
		},
	];

    const getGrnStatus = (items) => {
        switch(items) {
            case 'GRN':
                return 'Pass';
            case 'QC_REJECTED':
                return 'Fail';
            case 'QC_APPROVAL_PENDING':
                return 'Approval Pending';
            default:
                return 'Pending';
        }
    };

	const grnQcLogSpecialColumnsMappings = {
		'PO Id': (id, items) => {
			return <div>{items}</div>;
		},
		'Product Name': (id, items) => {
			return <div>{items}</div>;
		},
		'Qc Date': (id, items) => {
			return <div>{formatDate(items)}</div>;
		},
		Vendor: (id, items) => {
			return <div>{items}</div>;
		},
		'Batch Quantity': (id, items) => {
			return <div>{items}</div>;
		},
		'Mfd.Date': (id, items) => {
			return <div>{items}</div>;
		},
		Status: (id, items) => {
			return <div 
				className={`w-[10rem] h-[2rem] text-[14px] relative left-[5rem] flex items-center justify-center rounded-[4px] ${
					items === 'GRN' ? 'bg-[#E8F5E9] text-[#2E7D32]' : items === 'QC_APPROVAL_PENDING' ? 'bg-orange-100 text-orange-600' : 'bg-[#FFEBEE] text-[#C62828]'
				}`}
			>
                {getGrnStatus(items)}
			</div>
		},
	};

    const aiMachineQcLogColMap = {
		'Store': 'storeName',
        'QC Time': 'QCTime',
        'Order ID': 'OrderId',
		'Product Name': 'ProductName',
		'Weight': 'Weight',
		'Count': 'count',
		'Temp': 'Temp',
	};

	const aiMachineQcLogSpecialColumns = [
		{
			title: 'Store',
			config: {
				type: 'DATA',
			},
		},
		{
			title: 'QC Time',
			config: {
				type: 'Data',
			},
		},
		{
			title: 'Order ID',
			config: {
				type: 'Data',
			},
		},
		{
			title: 'Product Name',
			config: {
				type: 'Data',
			},
		},
		{
			title: 'Weight',
			config: {
				type: 'Data',
			},
		},
		{
			title: 'Count',
			config: {
				type: 'Data',
			},
		},
		{
			title: 'Temp',
			config: {
				type: 'Data',
			},
		},
	];
	const aiMachineQcLogSpecialColumnsMappings = {
		'Store': (id, items) => {
			return <div>{items}</div>;
		},
		'QC Time': (id, items) => {
			return <div>{items}</div>;
		},
		'Order ID': (id, items) => {
			return <div>{items}</div>;
		},
		'Product Name': (id, items) => {
			return <div>{items}</div>;
		},
		'Weight': (id, items) => {
			return <div>{items}</div>;
		},
		'Count': (id, items) => {
			return <div>{items}</div>;
		},
		'Temp': (id, items) => {
			return <div>{items}</div>;
		},
	};

    useEffect(() => {
		if (shouldAnimate) {
			const st = setTimeout(() => {
				setShouldAnimate(false);
			}, 1500);
			return () => {
				clearTimeout(st);
			};
		}
		return () => {};
	}, [shouldAnimate]);

	useEffect(() => {
		setShouldAnimate(true);
		setIsDataFetched(false);
	}, [data]);


    const fetchGrnQcLog = async (newQueryParams) => {
		const req = {
			data: {
				storeId: newQueryParams?.storeId ?? storeFilter,
				status: newQueryParams?.status ?? statusFilter,
                dateRangeFilter : {
                    from: formatDate(newQueryParams?.dateRangeFilter?.from ?? dateRangeFilter.from),
                    to: formatDate(newQueryParams?.dateRangeFilter?.to ?? dateRangeFilter.to),
                },
				productId: newQueryParams?.productId ?? null,
				grnId: newQueryParams?.grnId ?? null,
			},
		}  
		try {
			const res = await grnQcLogService.fetchGrnQcLog(req);
            if(res?.statusCode === 200) {
                setData(res?.data);
                setIsDataFetched(true);
                notificationServices.generateNotification({
                    type: 'success',
                    message: 'Data fetched successfully',
                });
            }else{
                notificationServices.generateNotification({
                    type: 'error',
                    message: res?.message || 'An error occurred',
                });
            }
		} catch (error) {
			console.error('Error fetching GRN QC log:', error);
		}
	};

	// console.log('1', data);
	const setDate = (newDate) => {
		const { startDate: from, endDate: to } = newDate?.selection ?? {};
		
		// Set the new date range and then fetch data
		setDateRangeFilter({
			active: true,
			from,
			to,
		});
        setIsDataFetched(true);
	};

    const exportQCLogs = () => {
        console.log('exportQCLogs');
    }
	const {
		query,
		handleSearch,
		clearSearch,
		searchBy: searchField,
		handleSearchBy: setSearchField,
	} = useSearch(
		searchQuery,
		SEARCH_BY?.options?.findIndex((option) => {
			return option.key === searchBy;
		}) ?? 0
	);

	useEffect(() => {
        fetchStoresList({
            entity: 'STORES',
            req: {
                params: {
                    id: '',
                    type: '',
                },
            },
            method: 'LIST',
        });
	}, []);

	useEffect(() => {
		if (queryParams.has('clear')) {
			clearSearch();
			setSearchField(0);
		}
	}, [queryParams]);

    useEffect(() => {
        const searchParams = new URLSearchParams();
        if (tabValue) {
            searchParams.set('tabValue', tabValue);
        }
        if (storeFilter) {
            searchParams.set('storeId', storeFilter);
        }
        if (statusFilter) {
            searchParams.set('status', statusFilter);
        }
        if (dateRangeFilter) {
            searchParams.set('dateRangeFilter', JSON.stringify(dateRangeFilter));
        }

        const prevPath = history?.location?.state?.prevPath;
        const search = searchParams.toString();
        history.replace({ 
            search: search ? `?${search}` : "",
            state: { prevPath }
        });
    }, [tabValue, storeFilter, statusFilter, dateRangeFilter]);

    // Handle initial load and URL params
    useEffect(() => {
        const newQueryParams = {};
        if (queryParams.size > 0) {
            queryParams.forEach((value, key) => {
                try {
                    switch(key) {
                        case 'tabValue':
                            setTabValue(value);
                            newQueryParams.tabValue = value;
                            break;
                        case 'storeId':
                            setStoreFilter(JSON.parse(value));
                            newQueryParams.storeId = JSON.parse(value);
                            break;
                        case 'status':
                            setStatusFilter(JSON.parse(value));
                            newQueryParams.status = JSON.parse(value);
                            break;
                        case 'dateRangeFilter':
                            const parsedDate = JSON.parse(value);
                            setDateRangeFilter({
                                active: true,
                                from: new Date(parsedDate.from) || new Date(),
                                to: new Date(parsedDate.to) || new Date(),
                            });
                            newQueryParams.dateRangeFilter = parsedDate;
                            break;
						case 'productId':
							newQueryParams.productId = value;
							break;
						case 'grnId':
							newQueryParams.grnId = value;
							break;
                        default:
                            break;
                    }
                } catch (error) {
                    console.error(`Error parsing ${key}:`, error);
                }
            });
        }
        if (newQueryParams?.tabValue === 'grnQcLogs') {
            fetchGrnQcLog(newQueryParams);
        }else if (newQueryParams?.tabValue === 'aiMachineQcLogs') {
            fetchAiMachineQcLog(newQueryParams);
        } else {
            fetchGrnQcLog(newQueryParams);
        }
    }, []);

	const dateRangeFilterArray = useMemo(() => {
		return [
			{
				startDate: dateRangeFilter.from,
				endDate: dateRangeFilter.to,
				key: 'selection',
			},
		];
	}, [dateRangeFilter]);

	const generateData = () => {
		const filteredData = data.filter((obj) => {
			return (
				obj?.[FIELD_MAP?.[SEARCH_BY?.options?.[searchField]?.key]]
					?.toLowerCase()
					?.indexOf(query.trim().toLowerCase()) !== -1
			);
		});
		return filteredData;
	};

	const handleSearchBy = (index) => {
		setSearchField(index);
	};

    const fetchAiMachineQcLog = async (newQueryParams) => {
        const req = {
            data: {
                storeId: newQueryParams?.storeId ?? storeFilter,
                status: newQueryParams?.status ?? statusFilter,
                dateRangeFilter : {  
                    from: formatDate(newQueryParams?.dateRangeFilter?.from ?? dateRangeFilter.from),
                    to: formatDate(newQueryParams?.dateRangeFilter?.to ?? dateRangeFilter.to),
                },
                qcType: 'INWARD_QC',
            },
        }
        try {
            const res = await grnQcLogService.fetchAiSupervisorScans(req);
            if(res?.statusCode === 200) {
                setData(res?.data);
                setIsDataFetched(true);
                notificationServices.generateNotification({
                    type: 'success',
                    message: 'Data fetched successfully',
                });
            }
        } catch (error) {
            notificationServices.generateNotification({
                type: 'error',
                message: error,
            });
        }
    }

	// Add a useEffect to handle date filter changes;
    
	return (
		<div className={styles.root}>
			<div className='flex flex-col p-2'>
				<div className='flex items-center justify-between w-full'>
					<div>
						<SearchBar
							value={query}
							placeholder={PLACE_HOLDERS[searchField]}
							handleInput={handleSearch}
							handleChange={handleSearchBy}
							searchBy={
								SEARCH_BY != null
									? {
										...(SEARCH_BY ?? {}),
										current: searchField,
									}
									: null
							}
							className={styles.search}
						/>
					</div>
					{/* second div */}
					<div className='flex items-center gap-2'>
						Total:{' '}{generateData().length}
						<Calender value={dateRangeFilterArray} setValue={setDate} />
						<div
							onClick={exportQCLogs}
							className={styles.downloadData}
						>
							<img src={excel} alt='excel' />
						</div>
						<IconButton
							type='submit'
							className={classes(
								styles.btn,
								shouldAnimate ? styles?.animate : '',
								isDataFetched ? styles?.disabled : ''
							)}
							onClick={() => {
								if(tabValue === 'grnQcLogs') {
									fetchGrnQcLog();
								}else{
									fetchAiMachineQcLog();
								}
							}}
						>
							<ArrowCircleRight />
						</IconButton>
					</div>
				</div>
				<div className='flex items-center gap-3'>
					<SelectPicker
						data={stores?.map((store) => ({
							label: store.name,
							value: store.id,
						}))}
                        value={storeFilter}
						placeholder='Select Store'
						onChange={(value) => {
							setStoreFilter(value);
						}}
						style={{width: 300}}
					/>
					<SelectPicker
						data={statusOptions.map((status) => ({
							label: status.label,
							value: status.value,
						}))}
                        value={statusFilter}
						placeholder='Select Status'
						onChange={(value) => {
							setStatusFilter(value);
						}}
						style={{width: 300}}
					/>
				</div>
			</div>
            <div className='flex flex-col'>
            <Tabs
                value={tabValue}
                onValueChange={(value) => {
                    setTabValue(value);
                    if (value === 'grnQcLogs') {
                        fetchGrnQcLog();
                    } else {
                        fetchAiMachineQcLog();
                    }
                }}
                defaultValue={tabValue}
                className='p-2 pb-0'
            >
                <TabsList className='h-auto w-auto inline-flex'>
                    <TabsTrigger
                        value='grnQcLogs'
                         className='px-4 data-[state=active]:h-[2.5rem] data-[state=active]:bg-yellow-500 data-[state=active]:text-black data-[state=active]:m-1 data-[state=active]:rounded-md'
                    >
                        GRN QC Logs
                    </TabsTrigger>
                    <TabsTrigger
                        value='aiMachineQcLogs'
                        className='px-4 data-[state=active]:h-[2.5rem] data-[state=active]:bg-yellow-500 data-[state=active]:text-black data-[state=active]:m-1 data-[state=active]:rounded-md'
                    >
                        AI Supervisor Scans
                    </TabsTrigger>
                </TabsList>
            </Tabs>
            {tabValue === 'grnQcLogs' && 
                <GrnQcLogTable
                    data={generateData()}
                    colMap={grnQcLogColMap}
                    special={grnQcLogSpecialColumns}
                    specialMappings={grnQcLogSpecialColumnsMappings}
                    tableType='grnQcLogs'
                    location={location}
                    filters={{
                        storeId: storeFilter,
                        status: statusFilter,
                        dateRangeFilter : {
                            from: formatDate(dateRangeFilter.from),
                            to: formatDate(dateRangeFilter.to),
                        },
                    }}
                />
            }
            {tabValue === 'aiMachineQcLogs' && 
                <GrnQcLogTable
                    data={generateData()}
                    colMap={aiMachineQcLogColMap}
                    special={aiMachineQcLogSpecialColumns}
                    specialMappings={aiMachineQcLogSpecialColumnsMappings}
                    tableType='aiMachineQcLogs'
                    filters={{
                        storeId: storeFilter,
                        status: statusFilter,
                        dateRangeFilter : {
                            from: formatDate(dateRangeFilter.from),
                            to: formatDate(dateRangeFilter.to),
                        },
                    }}
                />
            }
            </div>
			{/* // <Paginator /> */}
		</div>
	);
}

QualityLogs.defaultProps = {};

const mapStateToProps = (state) => {
    const stores = state?.form?.STORES?.data?.LIST;
	const { query: searchQuery, searchBy } =
		state.analytics.AI_PRODUCTION_ANALYSIS.SEARCH_DATA;
	return {
        stores,
		searchQuery,
		searchBy,
	};
};

const mapDispatchToProps = {
    fetchStoresList: formActions.gateway,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(QualityLogs);
