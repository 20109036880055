/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-tabs */
// eslint-disable-next-line object-curly-newline
// eslint-disable-next-line object-curly-newline
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
	Badge, Button, IconButton
} from '@mui/material';
import { ArrowCircleRight, Tune } from '@mui/icons-material';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { SelectPicker } from 'rsuite';
import { useHistory } from 'react-router';
import analyticsAction from '../../../Actions/analytics.actions';
import SearchBar from '../../../Components/SearchBar/SearchBar';
import analyticsTypeConstant from '../../../Constants/analyticsType.contants';
// eslint-disable-next-line object-curly-newline
import { classes, useQuery, useSearch } from '../../../Helpers';
import styles from './CookingHistory.module.scss';
import CookingAnalysisListPage from './CookingAnalysisListPage';
import Calender from '../../../Components/Forms/Payment Settlements/Calender/Calender';
import { formActions, modalActions } from '../../../Actions';
import { ListModal } from '../../../Components/Modals';
import FilterModal from '../../../Components/Filter Modal/FilterModal';
import excel from '../../../Images/excel.svg';
import { useAuth } from '@/Components/Auth/Login/auth';
import { urls } from '@/Constants';

const ANALYTICS_TYPE = analyticsTypeConstant.COOKING_HISTORY_LIST_DATA;
const PLACE_HOLDERS = {
	PRODUCT_NAME: 'Search by Product Name',
	ORDER_ID: 'Search by Order Id',
	ITEM_ID: 'Search by Item Id',
	// BRAND_NAME: 'Search by Brand Name',
};

const FIELD_MAP = {
	PRODUCT_NAME: 'productName',
	ORDER_ID: 'orderId',
	ITEM_ID: 'itemId',
	// VALIDATED: 'validation',
	// IDENTIFIED: 'identification',
	// FEATURES: 'annotated',
	// BRAND_NAME: 'brandName',
};

const SEARCH_BY = {
	options: [
		{
			key: 'PRODUCT_NAME',
			title: 'Product Name',
			get: 'recipeName',
		},
		{
			key: 'ORDER_ID',
			title: 'Order ID',
			get: 'orderId',
		},
		{
			key: 'ITEM_ID',
			title: 'Item ID',
			get: 'itemId',
		},
		// {
		// key: 'BRAND_NAME',
		// title: 'Brand Name',
		// get: 'brandName',
		// },
	],
};

const FILTERS = [
	'PROCESS_TYPE_FILTER',
	'SKU_TYPE_FILTER',
];

const CookingHistoryListData = (props) => {
	const {
		dateRangeFilter,
		data,
		setDateRangeFilter,
		fetchData,
		setQCSearchResults,
		searchQuery,
		searchBy,
		brandData,
		userPermissions,
		gateway,
		toggleFilterModal,
		resetInventoryAnalyticsState,
		productsList,
		exportAiCookingAnalytics,
		setAnalyticsType,
		stores,
		allStoreOption = true,
		fetchStoresList,
		podFilter,
		setPodFilter,
		setKitchenFilter,
		kitchenFilter,
		mealName,
		setMealName,
	} = props;

	// const { page, limit } = usePagination({
	// 	customLimit: 10,
	// });
	const [shouldAnimate, setShouldAnimate] = useState(true);
	const [isDataFetched, setIsDataFetched] = useState(false);
	const { appConfig = {} } = useAuth();
	const { combineCMSBatches } = appConfig?.configs ?? {};
	const EMPTYVALUE = -999;
	const useQueryParams = () => {
		const { search } = useLocation();
		return new URLSearchParams(search);
	};
	const history = useHistory();
	const params = useQueryParams();
	const name = params.get('name');
	const mealType = params.get('mealType');
	const subMealValue = params.get('subMealValue');
	const date = params.get('date');
	const formStore = params.get('formStore');
	const toPodFilter = params.get('toPodFilter');
	const podId = params.get('podId');
	const flag = params.get('flag');

	useEffect(() => {
		if (combineCMSBatches) {
			fetchStoresList({
				entity: 'STORES',
				req: {
					params: {
						id: '',
						type: '',
					},
				},
				method: 'LIST',
			});
		}
	}, [combineCMSBatches]);
	useEffect(() => {
		// setDateRangeFilter({
		// 	active: true,
		// });
	}, []);
	const PodList = useMemo(() => {
		const tempStore = stores?.filter((ele) => ele?.type === 'RETAIL').map((item) => {
			return {
				id: item.id,
				title: item?.name ?? '',
				type: item?.type,
			};
		});
		if (allStoreOption) {
			tempStore?.unshift({
				id: EMPTYVALUE,
				title: 'ALL',
				type: 'ALL',
			});
		}
		return tempStore;
	}, [stores]);

	const KitchenList = useMemo(() => {
		const tempStore = stores?.filter((ele) => ele?.type === 'BASE').map((item) => {
			return {
				id: item.id,
				title: item?.name ?? '',
				type: item?.type,
			};
		});
		if (allStoreOption) {
			tempStore?.unshift({
				id: EMPTYVALUE,
				title: 'ALL',
				type: 'ALL',
			});
		}
		return tempStore;
	}, [stores]);

	useEffect(() => {
		if (shouldAnimate) {
			const st = setTimeout(() => {
				setShouldAnimate(false);
			}, 1500);
			return () => {
				clearTimeout(st);
			};
		}
		return () => {};
	}, [shouldAnimate]);

	useEffect(() => {
		setShouldAnimate(true);
		setIsDataFetched(false);
	}, [data]);

	const setDate = (newDate) => {
		const { startDate: from, endDate: to } = newDate?.selection ?? {};

		setDateRangeFilter({
			active: true,
			from,
			to,
		});
		setShouldAnimate(true);
	};

	const getData = () => {
		resetInventoryAnalyticsState({
			entity: 'COOKING_ANALYTICS', method: 'LIST_DATA', data: [],
		});
		const { from, to } = dateRangeFilter;
		if (from != null && to != null) {
			setAnalyticsType(ANALYTICS_TYPE);
			setIsDataFetched(true);
		}
	};

	const queryParams = useQuery();
	const {
		query,
		handleSearch,
		clearSearch,
		searchBy: searchField,
		handleSearchBy: setSearchField,
	} = useSearch(
		combineCMSBatches,
		name,
		searchQuery,
		SEARCH_BY?.options?.findIndex((option) => {
			return option.key === searchBy;
		}) ?? 0
	);
	useEffect(() => {
		const urlParams = new URLSearchParams();
		if (query) urlParams.set('name', query);
		if (podFilter?.applied) urlParams.set('podId', podFilter?.value);
		if (mealName?.applied) urlParams.set('mealType', mealName?.value);
		if (dateRangeFilter?.applied) urlParams.set('date', dateRangeFilter?.to, dateRangeFilter?.from);
		if (kitchenFilter?.applied) urlParams.set('formStore', kitchenFilter?.value);
		if (podFilter?.applied) urlParams.set('toPodFilter', podFilter?.value);
		const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
		window.history.replaceState(null, '', newUrl);
	}, [query, podFilter, mealName, dateRangeFilter, kitchenFilter]);

	useEffect(() => {
		if (brandData.length === 0) {
			gateway({
				entity: 'BRANDS',
				req: {
					params: {
						id: '',
					},
				},
				method: 'LIST',
			});
		}
	}, []);

	useEffect(() => {
		if (queryParams.has('clear')) {
			clearSearch();
			setSearchField(0);
			setQCSearchResults('', data, 'ORDER_ID');
		}
	}, [queryParams]);

	const dateRangeFilterArray = useMemo(() => {
		return [
			{
				startDate: dateRangeFilter.from,
				endDate: dateRangeFilter.to,
				key: 'selection',
			},
		];
	}, [dateRangeFilter]);

	useEffect(() => {
		if (combineCMSBatches && flag) {
			setDateRangeFilter({
				active: true,
				from: date ? new Date(date?.split('GMT')[0]) : new Date(),
				to: date ? new Date(date?.split('GMT')[0]) : new Date(),
			});
			setKitchenFilter({
				applied: true,
				value: formStore || -999,
			});
			if (mealType?.length > 0) {
				setMealName({
					applied: true,
					value: mealType || null,
				});
			}
			if ((podId || (name && podId)) && (podId !== null && podId !== 'null')) {
				setPodFilter({
					applied: true,
					value: podId,
				});
			} else if (name && (toPodFilter !== null && toPodFilter !== 'null')) {
				setPodFilter({
					applied: true,
					value: toPodFilter,
				});
			} else {
				setPodFilter({
					applied: true,
					value: -999,
				});
			}
		}
	}, [combineCMSBatches, date, podId, formStore, flag, mealType]);

	const unbindStates = (toQcPage = false) => {
		clearSearch();
		setSearchField(0);
		if (!toQcPage) {
			resetInventoryAnalyticsState({
				entity: 'COOKING_ANALYTICS', method: 'LIST_DATA', data: [],
			});
		}
		setDateRangeFilter({
			active: true,
			from: new Date(),
			to: new Date(),
		});
		setPodFilter({
			applied: false,
			value: -999,
		});
		setKitchenFilter({
			applied: false,
			value: -999,
		});
		setMealName({
			applied: false,
			value: null,
		});
	};

	useEffect(() => {
		const unbind = history.listen((loc, action) => {
			if (toString(action) === 'POP') {
				unbindStates();
			} else if (!loc?.pathname?.includes(urls?.PAST_QC)) {
				unbindStates(true);
			}
		});
		// return () => {
		// 	unbind();
		// };
	}, []);

	const selectPods = (id) => {
		return (
			setPodFilter({
				applied: true,
				value: id,
			})
		);
	};
	const selectKitchen = (id) => {
		return (
			setKitchenFilter({
				applied: true,
				value: id,
			})
		);
	};
	const dataGenerator = () => {
		const filteredData =
			data &&
			data
				?.map((ele) => {
					return {
						...ele,
						productName:
							productsList?.[ele?.productId]?.productName ?? '-',
					};
				})
				?.filter((obj) => {
					return (
						obj?.[
							FIELD_MAP?.[SEARCH_BY?.options?.[searchField]?.key]
						]
							?.toString()
							?.toLowerCase()
							?.indexOf(query.trim().toLowerCase()) !== -1
					);
				});
		setQCSearchResults(query, filteredData, SEARCH_BY?.options?.[searchField]?.key);
		return filteredData;
	};

	const handleSearchBy = (index) => {
		setSearchField(index);
	};

	return (
		<div className={styles.root}>
			<ListModal selectionBehaviour='SHOW' />
			<FilterModal
				type='quality'
				filters={FILTERS}
				clear={queryParams.has('clear')}
			/>
			<div
				className={styles.header}
				style={{
					display: 'flex',
					gap: '8px',
				}}
			>
				<div className='flex gap-3 w-full'>
					<div
						className={styles.filters}
						style={{
							width: '50%',
						}}
					>
						<SearchBar
							value={query}
							placeholder={PLACE_HOLDERS[searchField]}
							handleInput={handleSearch}
							handleChange={handleSearchBy}
							searchBy={
								SEARCH_BY != null
									? {
										...(SEARCH_BY ?? {}),
										current: searchField,
									}
									: null
							}
							className={styles.search}
						/>
						<Badge
							className={styles['brand-filter']}
							color='primary'
						>
							<Button
								variant='outlined'
								onClick={() => {
									toggleFilterModal(true, {
										heading: 'Filter',
									});
								}}
							>
								Filters
								<Tune fontSize='medium' />
							</Button>
						</Badge>
					</div>
					<div className={styles['date-selection']}>
						<p>Prepration Date: </p>
						<Calender
							value={dateRangeFilterArray}
							setValue={setDate}
							allowFutureDates
						/>
					</div>
					<div className='flex items-center gap-3 mr-2'>
						<div
							onClick={exportAiCookingAnalytics}
							className={styles.downloadData}
						>
							<img src={excel} alt='excel' />
						</div>
						<IconButton
							className={classes(
								styles.btn,
								shouldAnimate ? styles?.animate : '',
								isDataFetched ? styles?.disabled : ''
							)}
							onClick={getData}
						>
							<ArrowCircleRight />
						</IconButton>
					</div>
				</div>
				{/* kitchen filter start */}
				<div className='flex w-full gap-2 items-center'>
					<SelectPicker
						data={KitchenList?.map((ele) => {
							return {
								...ele,
								label: ele?.title,
								role: ele?.type,
								value: ele?.id,
							};
						})}
						style={{
							width: 224,
						}}
						groupBy='type'
						placeholder='Select Store'
						onChange={selectKitchen}
						renderMenuItem={(_) => {
							return (
								<div>
									<i className='rs-icon rs-icon-user' />
									{' '}
									{_}
								</div>
							);
						}}
						renderMenuGroup={(_) => {
							return (
								<div>
									<i className='rs-icon rs-icon-group' />
									{_}
								</div>
							);
						}}
						cleanable={false}
						value={Number(kitchenFilter?.value)}
						renderValue={(_, item) => {
							return (
								<div
									className={`${styles.selected_store} rsuite-select-picker-render-value`}
								>
									<p>
										Kitchen:
										{' '}
										{item?.title}
									</p>
								</div>
							);
						}}
					/>
					{/* kitchen filter end */}
					{/* pod filter start */}
					{combineCMSBatches && (
						<>
							<SelectPicker
								data={PodList?.map((ele) => {
									return {
										...ele,
										label: ele?.title,
										role: ele?.type,
										value: ele?.id,
									};
								})}
								style={{
									width: 224,
								}}
								groupBy='type'
								placeholder='Select Store'
								onChange={selectPods}
								renderMenuItem={(_) => {
									return (
										<div>
											<i className='rs-icon rs-icon-user' />
											{' '}
											{_}
										</div>
									);
								}}
								renderMenuGroup={(_) => {
									return (
										<div>
											<i className='rs-icon rs-icon-group' />
											{_}
										</div>
									);
								}}
								cleanable={false}
								value={Number(podFilter?.value)}
								renderValue={(_, item) => {
									return (
										<div
											className={`${styles.selected_store} rsuite-select-picker-render-value`}
										>
											<p>
												Pods:
												{' '}
												{item?.title}
											</p>
										</div>
									);
								}}
							/>
							<div className='flex gap-2'>
								<span>Meal Type : </span>
								<span>{mealType}</span>
							</div>
							<div className='mr-7 flex gap-2 items-center'>
								<span className='bg-[#66c24d] w-3 h-3 rounded-full' />
								<p> Batch Excecuted</p>
							</div>
						</>
					)}
				</div>
			</div>
			<CookingAnalysisListPage
				userPermissions={userPermissions}
				data={dataGenerator()}
				dateFilter={dateRangeFilter}
				kitchenFilter={kitchenFilter}
				podFilter={podFilter}
				mealName={mealName}
				config={combineCMSBatches}
				flag={flag}
				fetchDataAfterSetting
			/>
		</div>
	);
};

CookingHistoryListData.defaultProps = {};

const mapStateToProps = (state) => {
	const {
		DATE_RANGE_FILTER: dateRangeFilter, BRAND_FILTER: brandFilter,
		POD_FILTER: podFilter, KITCHEN_FILTER: kitchenFilter,
		MEAL_TYPE: mealName,
	} = state.analytics;
	const { LIST_DATA: data } = state.analytics?.COOKING_ANALYTICS;
	const stores = state?.form?.STORES?.data?.LIST;
	// const { count } = data??{count:};
	const { userPermissions } = state.navigation;
	const brandData = state.form.BRANDS.data?.LIST;
	const productsList = state.product.products;
	const searchQuery = state.analytics.COOKING_ANALYTICS.SEARCH_DATA?.query;
	const searchBy = state.analytics.COOKING_ANALYTICS.SEARCH_DATA?.searchBy;
	return {
		dateRangeFilter,
		searchQuery,
		data: data?.recipes,
		// count,
		productsList,
		searchBy,
		brandData,
		userPermissions,
		brandFilter,
		stores,
		podFilter,
		kitchenFilter,
		mealName,
	};
};

const mapDispatchToProps = {
	setDateRangeFilter: analyticsAction.setDateRangeFilter,
	setPodFilter: analyticsAction.setPodFilter,
	setKitchenFilter: analyticsAction.setKitchenFilter,
	setMealName: analyticsAction.setMealType,
	setQCSearchResults: analyticsAction.setQCSearchResults,
	fetchData: analyticsAction.fetchData,
	toggleModal: modalActions.toggleListModal,
	toggleFilterModal: modalActions.toggleFilterModal,
	applyFilters: analyticsAction.applyFilters,
	gateway: formActions.gateway,
	fetchStoresList: formActions.gateway,
	resetInventoryAnalyticsState: analyticsAction.resetInventoryAnalyticsState,
	setAnalyticsType: analyticsAction.setAnalyticsType,
	exportAiCookingAnalytics: analyticsAction.exportAiCookingAnalytics,
};
export default connect(mapStateToProps, mapDispatchToProps)(CookingHistoryListData);
