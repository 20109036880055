/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable */
import moment from 'moment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect, useState } from 'react';
import styles from './BillsListing.module.scss';
import { addCommas, classes } from '../../Helpers';
import { ListTable } from '../ListTable';
import { urls } from '../../Constants';
import { Button } from '../ui/button';
import { useAuth } from '../Auth/Login/auth';
import userRolesAndPermissions from '@/Services/userRolesAndPermissions.services';
import { notificationServices } from '@/Services';
import authActions from '@/Actions/auth.actions';
import { connect } from 'react-redux';

const TABLE_COLUMNS = [
	{
		title: 'id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Vendor',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Bill Type',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Billing Store',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Order id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'GRN Id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Client Bill. id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Created By',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Created At',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Bill date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Due Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Bill Amount',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Paid Amount',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Status',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'L1 Action By',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'L2 Action By',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [
	{
		title: 'Bill Actions',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'View More',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'MORE',
		config: {
			type: 'DATA',
		},
	},
];

const COLMAP = {
	id: 'id',
	'Bill Type': 'id',
	Vendor: 'vendorName',
	Store: 'fromStoreName',
	'Billing Store': 'storeName',
	'Order id': 'clientOrderId',
	'GRN Id': 'parentRowId',
	'Created By': 'initiatedByUserDisplayName',
	'Created At': 'createdAt',
	'Client Bill. id': 'clientTransactionId',
	'Due Date': 'dueDate',
	'Bill date': 'transactionDate',
	'Bill Amount': 'finalTransactionAmount',
	'Order Amount': 'orderAmount',
	'Paid Amount': 'currentAmount',
	'L1 Action By': 'l1ActionByUserDisplayName',
	'L2 Action By': 'l2ActionByUserDisplayName',
	// 'Payment Made': '',
	Status: 'id',
};

const BillsListing = (props) => {
	const {
		BILLS,
		vendors,
		userPermissions,
		// togglePaymentModal,
		toggleFileUploaderModal,
		togglePOItemsModal,
		toggleAlert,
		handleCancelBill,
		selectedBills,
		setSelectedBills,
		moreOptionsList,
		viewMoreOptionsList,
		handleBulkPayment,
		pushPoInformation,
		excludedColumns = [],
		externalColumns = [],
		externalSpecialItems = {},
		hideExport = false,
		allowCheckList = false,
		showTotal = true,
		showBtnContainer = true,
		navState,
		userList,
		fetchUserList,
	} = props;
	const history = useHistory();
	const { appConfig = {}, userConfig } = useAuth();

	// userAcess
	// useEffect(() => {
	// 	fetchUserList();
	// }, []);
	// // function page role of user access
	// const map = new Map(userList.map((item) => [item.value, item.label]));
	// const role = Object.keys(userConfig?.userRoles);
	// const userRoles = [];
	// role.forEach((ele) => {
	// 	userRoles.push(ele);
	// });
	// const pageRole = navState['/finance/management']?.submenu['/analytics/po/bill']?.rolesMapped;
	// const findMatchingRole = userRoles.filter((ele) => pageRole.includes(Number(ele)));
	// const roleDetails = findMatchingRole.map((role) => {
	// 	const key = Number(role);
	// 	const name = map.get(key);
	// 	return {
	// 		role: key, name,
	// 	};
	// });
	// const isInternal = roleDetails.some((item) => item.name === 'Internal' || item.name === 'CXO Office' || item?.name === 'Finance');
	// const cxoApproval = roleDetails.some((ele) => ele?.name === 'CXO Office');
	// const billApproval = roleDetails.some((ele) => ele?.name === 'Finance');
	// const access = findMatchingRole.length > 0 ? userConfig?.userDetails?.level : false;
	// let permit;
	// if (access === false) {
	// 	permit = false;
	// } else {
	// 	permit = isInternal && access !== 1;
	// }
	// end user Acces

	const grnBillApproval = appConfig?.grnBillApproval;
	const SPECIAL_ITEMS = {
		'Bill date': (date) => {
			return moment(date)?.format('DD-MM-YYYY');
		},
		'Due Date': (date) => {
			return moment(date)?.format('DD-MM-YYYY');
		},
		'Created At': (date) => {
			return moment(date)?.format('DD-MM-YYYY');
		},
		'Bill Type': (id) => {
			const item = BILLS?.find((ele) => {
				return ele?.id === id;
			});
			const type = item?.subType;
			return (
				<div className={classes(styles.type, styles?.[type])}>
					{type}
				</div>
			);
		},
		'Bill Amount': (amount) => {
			return '\u20B9' + addCommas(amount);
		},
		'Paid Amount': (amount) => {
			return `₹${addCommas(amount)}`;
		},
		Status: (transactionId) => {
			const item = BILLS?.find((ele) => {
				return ele?.id === transactionId;
			});
			if (item?.transactionStatus === 'REJECTED') {
				return (
					<div>
						<p>
							{item?.transactionStatus}
							{item?.description
								? ` (Reason: ${item?.description})`
								: ''}
						</p>
						<p className={styles.reupload}>Re-Upload Invoice</p>
					</div>
				);
			}
			return item?.transactionStatus?.split('_')?.join(' ');
		},
		'Action by': (transactionId) => {
			const item = BILLS?.find((ele) => {
				return ele?.id === transactionId;
			});
			if (item?.transactionStatus === 'APPROVAL_REJECTED') {
				return (
					<div>
						<p
							style={{
								color: '#0006',
								fontSize: '.75rem',
							}}
						>
							Rejected by
						</p>
						<p>{item?.l1ActionByUserDisplayName}</p>
					</div>
				);
			}

			if (item?.l2ActionByUserDisplayName) {
				return (
					<div>
						<p
							style={{
								color: '#0006',
								fontSize: '.75rem',
							}}
						>
							Approved By
						</p>
						<p>{item?.l2ActionByUserDisplayName}</p>
					</div>
				);
			}
			return (
				<div>
					<p
						style={{
							color: '#0006',
							fontSize: '.75rem',
						}}
					>
						Created By
					</p>
					<p>{item?.l1ActionByUserDisplayName}</p>
					<p />
				</div>
			);
		},
		'Bill Actions': (id) => {
			const item = BILLS?.find((ele) => {
				return ele?.id === id;
			});
			const vendorDetails = vendors?.find((ele) => {
				return ele?.id === item?.vendorId;
			});
			const isTransactionCancelled =
				item?.transactionStatus === 'CANCELLED';
			const isTransactionUnPaid = item?.transactionStatus === 'UNPAID';
			const isTransactionRejected =
				item?.transactionStatus === 'REJECTED';
			// eslint-disable-next-line no-unused-vars
			const isTransactionPaid = item?.transactionStatus === 'PAID';
			// const isAdvanceBill = !item?.parentRowId;

			if (isTransactionCancelled) {
				return '-';
			}

			// if (item?.isApproved && isTransactionPaid) {
			// return 'Paid';
			// }
			return (
				<div className='flex flex-col gap-1 min-w-[150px]'>
					{item?.isApproved &&
						isTransactionUnPaid &&
						!userPermissions.FINANCE && <p>Not paid</p>}
					{item?.isApproved && isTransactionPaid && <p>Paid</p>}
					{/* {item?.isApproved &&
						!isTransactionPaid &&
						userPermissions.FINANCE && (
						<Button
							onClick={() => {
								togglePaymentModal(true, {
									heading: `Make Payment of Bill #${id} ${
											!item?.parentRowId
												? '(Advance Bill)'
												: ''
										}`,
									// totalAmount: item['Bill Amount'],
									type: 'paymentMade',
									storeId: item?.storeId,
									advanceBill: !item?.parentRowId,
									totalAmount:
											item?.finalTransactionAmount -
											item?.currentAmount,
									parentId: item?.id,
									vendorId: item?.vendorId,
									orderId: item?.orderId,
									isAdvance: !item?.parentRowId,
								});
							}}
							variant='contained'
							color='primary'
							fullWidth
						>
							Pay
						</Button>
					)} */}
					{isTransactionRejected && (
						<Button
							onClick={() => {
								toggleFileUploaderModal(true, {
									heading: 'Upload Invoice',
									selectedFile: '',
									confirmText: 'Upload',
									id,
								});
							}}
							variant='outline'
							className='w-full'
						>
							Re-Upload Invoice
						</Button>
					)}
					{!isTransactionRejected && ['APPROVAL_PENDING_FINANCE']?.includes(item?.transactionStatus) &&
						userPermissions.FINANCE &&
						!!grnBillApproval ? (
							<Button
								onClick={() => {
									
									pushPoInformation(item, vendorDetails);
								}}
								className='primary-btn w-full'
							>
								Finance Approval 
							</Button>
						) : !isTransactionRejected && ['APPROVAL_PENDING_MANAGER']?.includes(item?.transactionStatus) &&
							userPermissions.FINANCE &&
					!!grnBillApproval ? (
						<Button
								onClick={() => {
									pushPoInformation(item, vendorDetails);
								}}
								className='primary-btn w-full'
							>
								Manager Approval
							</Button>
						): null}
					{((isTransactionRejected || isTransactionCancelled) || item?.transactionStatus === 'APPROVAL_PENDING_MANAGER' || (!grnBillApproval && item?.transactionStatus === 'APPROVAL_PENDING_FINANCE')) && (
						<Button
							onClick={() => {
								const url = `${urls.EXTENSION}${urls.BILLS}/add?billId=${id}&isEdit=true&orderType=${item?.subType}`;
								history.push(url);
							}}
							variant='outline'
						>
							Edit
						</Button>
					)}
					{!userPermissions.MANAGER && (
						<p>Not approved</p>
					)}
					{['APPROVAL_PENDING_MANAGER', 'REJECTED'].includes(
						item?.transactionStatus
					) && (
						<Button
							onClick={() => {
								toggleAlert(true, {
									heading: `Cancel Bill ${item?.clientTransactionId}`,
									handleConfirm: () => {
										handleCancelBill(item);
										toggleAlert(false);
									},
									colorReverse: false,
									cancelText: 'No',
									confirmText: 'Yes',
									message: `Are you sure you want to Cancel Payment: ${
										item?.clientTransactionId || ''
									}?`,
									width: '28rem',
								});
							}}
							className='danger-btn w-full'
						>
							Cancel
						</Button>
					)}
				</div>
			);
		},
	};

	return (
		<div className={styles.root}>
			<ListTable
				showBtnContainer={showBtnContainer}
				allowExport={!hideExport}
				allowCheckList={allowCheckList}
				selectedRows={selectedBills}
				setSelectedRow={setSelectedBills}
				colMap={COLMAP}
				columns={TABLE_COLUMNS?.filter((ele) => {
					return !excludedColumns?.includes(ele?.title);
				})}
				data={BILLS?.map((ele) => {
					return {
						...ele,
						rowStyles: classes(
							styles.row,
							styles?.[ele?.transactionStatus]
						),
					};
				})}
				showTotal={showTotal}
				renderBtns={() => {
					return (
						<div>
							{!!selectedBills?.length && (
								<Button
									onClick={handleBulkPayment}
									variant='contained'
									color='primary'
								>
									Bulk payment
								</Button>
							)}
						</div>
					);
				}}
				// data={dummyData}
				actions={[...SPECIAL_COLUMNS, ...externalColumns]?.filter(
					(ele) => {
						return !excludedColumns?.includes(ele?.title);
					}
				)}
				special={{
					...SPECIAL_ITEMS,
					...externalSpecialItems,
				}}
				moreOptionsList={moreOptionsList}
				viewMoreOptionsList={viewMoreOptionsList}
			/>
		</div>
	);
};


const mapStateToProps = (state) => {
	const { userList } = state.auth;
	return {
		userList,
	};
};
const mapDispatchToProps = {
	fetchUserList: authActions?.fetchUserList,
};

export default connect(mapStateToProps, mapDispatchToProps)(BillsListing);
