/* eslint-disable */
import { Switch } from "@mui/material";
import { Label } from "../ui/label";
import MinMaxInput from "./minMaxInput";
import { Col, InputNumber, Row, Slider } from "rsuite";
import { useState } from "react";
import { Input } from "../ui/input";

function QualityConfig(props) {
	const { 
        qualityCheck,
        setQualityCheck,
        isDisabled= false,
        pageName='',
        disableFields= false,
        hideFeilds= false,
        unit= '' 
    } = props;

    const handleMinMaxChange = (name, value) => {
        if(value > 100) {
            setQualityCheck((prevQualityCheck) => ({
                ...prevQualityCheck,
                [name]: 100,
            }));
        }else {
            setQualityCheck((prevQualityCheck) => ({
                ...prevQualityCheck,
                [name]: value,
            }));
        }
    };
    
	return (
        <div className='flex gap-2 items-center p-2 w-[98%] m-[1rem] border-t border-gray-400'>
            <div className='flex items-center gap-2'>
                <Label className='text-gray-500 whitespace-nowrap'>Enable Quality Analysis Configuration for this {pageName}</Label>
                <Switch
                    disabled={isDisabled}
                    checked={qualityCheck?.qualityCheckEnabled}
                    onChange={(e) => setQualityCheck((prev) => ({...prev, qualityCheckEnabled : e.target.checked}))}
                    size="small"
                    sx={{
                        '& .MuiSwitch-switchBase.Mui-checked': {
                            color: '#000000',
                        },
                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                            backgroundColor: '#000000',
                        }
                    }}
                />
            </div>
            {hideFeilds ? null : (
            <div className={`${disableFields ? 'opacity-50 pointer-events-none' : ''}`}>
                <div className='flex items-center gap-4'>
                    <Label className='text-gray-500 whitespace-nowrap'>Minimum Sampling Quantity (%)</Label>
                    <Slider
                        progress
                        className='w-full [&_.rs-slider-progress-bar]:!bg-black 
                                [&_.rs-slider-handle]:!bg-black 
                                [&_.rs-slider-handle]:!border-black 
                                [&_.rs-slider-handle]:!ring-0 
                                [&_.rs-slider-handle]:!shadow-none'
                        value={qualityCheck?.minSamplingQuantity}
                        onChange={(value) => handleMinMaxChange('minSamplingQuantity', value)}
                        min={1}
                        max={100}
                        disabled={true}
                    />
                    <InputNumber
                        min={1}
                        max={100}
                        value={qualityCheck?.minSamplingQuantity}
                        onChange={(value) => handleMinMaxChange('minSamplingQuantity', value)}
                        disabled={true}
                        // className='w-[80px]'
                    />
                    {unit === 'count' && (
                        <>
                        <Label className='text-gray-500 whitespace-nowrap'>With Max Scan Count:</Label><Input
                                value={qualityCheck?.scanCount}
                                disabled={true}
                                className='w-[80px]' />
                        </>
                    )}
                    {/* <Label className='text-gray-500 whitespace-nowrap'>Acceptable value range is 1 to 100</Label> */}
                </div>
            </div>
        )}
    </div>
    );
}

export default QualityConfig;

