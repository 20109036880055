import TabComp from './TabComp';

export const newTabs = (tabData) => {
	return tabData.map((tab) => ({
		...tab,
		comp: (props) => {
			return <TabComp {...props} />;
		},
	}));
};
