/* eslint-disable */
import { Button, Select, Switch, TableCell, Typography, MenuItem, TableRow } from '@mui/material';
import { Input } from '@/Components/ui/input';
import React, { useEffect } from 'react';
import { Calculator, Calendar, ListTodo, Text } from 'lucide-react';

const RowComponent = React.memo((props) => {
	const {
		param,
		editParam,
		saveParam,
		handleInput,
		removeParameter,
        cancleParameter,
        valueParam,
        setValueParam,
        focusRow,
		index,
	} = props;

	const type  = [
		{
			name: 'Number',
			value: 'number',
			icon: <Calculator />
		},
		{
			name: 'Text',
			value: 'text',
			icon: <Text />
		},
		{
			name: 'Date',
			value: 'date',
			icon: <Calendar/>
		},
		{
			name: 'Boolean',
			value: 'boolean',
			icon: <ListTodo />
		},
		{
			name: 'Decimal',
			value: 'decimal',
			icon: <Calculator/>
		},
		
	];

	useEffect(() => {
        const nonEmptyValue = Object.values(param).every((value) => value === null && value === undefined && value === '');
        setValueParam(nonEmptyValue);
    }, [param]);
	const foundType = type.find((item) => item.value === param.inputType);

	return (
		<div style={{ display: 'flex', width: '100%', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
			<div style={{ 
				width: '16.66%', 
				padding: '16px', 
				textAlign: 'left', 
				backgroundColor: focusRow === index ? '#F1F5F9' : 'white',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'flex-start'
			}}>
				{param?.isEditing || valueParam ? (
					<Input
						value={param?.name}
						onChange={(e) => handleInput(e)}
						data-id={index}
						name='name'
					/>
				) : (
					<Typography>{param?.name}</Typography>
				)}
			</div>
			<div style={{ 
				width: '16.66%', 
				padding: '16px', 
				textAlign: 'left', 
				backgroundColor: focusRow === index ? '#F1F5F9' : 'white',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'flex-start'
			}}>
				{param?.isEditing || valueParam ? (
					<Select
						value={param?.inputType}
						onChange={(e) => handleInput(e, index)}
						name='inputType'
						sx={{ minWidth: 120, height: 30 }}
					>
						<MenuItem value='text'>Text</MenuItem>
						<MenuItem value='number'>Number</MenuItem>
						<MenuItem value='date'>Date</MenuItem>
						<MenuItem value='boolean'>Boolean</MenuItem>
						<MenuItem value='decimal'>Decimal</MenuItem>
					</Select>
				) : (
					<Typography style={{display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'flex-start'}}> {foundType?.icon} {foundType?.name}</Typography>
				)}
			</div>
			<div style={{ 
				width: '16.66%', 
				padding: '16px', 
				textAlign: 'left', 
				backgroundColor: focusRow === index ? '#F1F5F9' : 'white',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'flex-start'
			}}>
				{param?.isEditing || valueParam ? (
					<Input
						value={param?.unit}
						onChange={(e) => handleInput(e)}
						data-id={index}
						name='unit'
					/>
				) : (
					<Typography>{param?.unit || '-'}</Typography>
				)}
			</div>
			<div style={{ 
				width: '16.66%', 
				padding: '16px', 
				textAlign: 'left', 
				backgroundColor: focusRow === index ? '#F1F5F9' : 'white',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'flex-start'
			}}>
				{param?.isEditing || valueParam ? (
					<Switch
						checked={param?.imageRequired}
						onChange={(e) => handleInput(e)}
						inputProps={{ 'data-id': index}}
						name='imageRequired'
						sx={{
							'& .MuiSwitch-switchBase.Mui-checked': {
								color: '#000000',
							},
							'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
								backgroundColor: '#000000',
							}
						}}
					/>
				) : (
					<Switch
						checked={param?.imageRequired}
						disabled
						sx={{
							'& .MuiSwitch-switchBase.Mui-checked': {
								color: '#000000'
							},
							'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
								backgroundColor: '#000000'
							}
						}}
					/>
				)}
			</div>
				{param.inputType === 'number' || param.inputType === 'decimal' ? (
				<div style={{ 
					width: '16.66%', 
					padding: '16px', 
					textAlign: 'left', 
					backgroundColor: focusRow === index ? '#F1F5F9' : 'white',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-start'
				}}>
						{param?.isEditing || valueParam ? (
							<Switch
								checked={param?.rangeFlag}
								onChange={(e) => handleInput(e)}
								inputProps={{ 'data-id': index }}
								name='rangeFlag'
								disabled={param?.rangeFlag}
								sx={{
									'& .MuiSwitch-switchBase.Mui-checked': {
										color: '#000000',
									},
									'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
										backgroundColor: '#000000',
									}
								}}
							/>
						) : (
							<Switch
								checked={param?.rangeFlag}
								disabled
								sx={{
									'& .MuiSwitch-switchBase.Mui-checked': {
										color: '#000000',
									},
									'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
										backgroundColor: '#000000',
									}
								}}
							/>
						)}
				</div>
				) : (
				<div style={{ 
					width: '16.66%', 
					padding: '16px', 
					textAlign: 'left', 
					backgroundColor: focusRow === index ? '#F1F5F9' : 'white',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-start'
				}}>
						<Typography>No Range Required</Typography>
				</div>
				)}
			<div style={{ 
				width: '16.66%', 
				padding: '16px', 
				textAlign: 'left', 
				backgroundColor: focusRow === index ? '#F1F5F9' : 'white',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'flex-start'
			}}>
				{param?.showSave && !param?.showEdit ? (
					<Button
						onClick={() => saveParam(index)}
						color='primary'
						size='small'
						sx={{
							ml: 1,
							textDecoration: 'underline',
							color: '#0000FF',
							fontWeight: 'bold',
						}}
					>
						Save
					</Button>
				) : (
					<Button
						onClick={() => editParam(param?.id)}
						color='primary'
						size='small'
					    sx={{
						    ml: 1,
						    textDecoration: 'underline',
						    color: '#0F172A',
						    fontWeight: 'bold',
					    }}
				>
						Edit
					</Button>
				)}
				<Button
					onClick={param?.showSave ? () => cancleParameter(index, param?.id) : () => removeParameter(param?.id)}
					color='error'
					size='small'
					sx={{
						ml: 1,
						textDecoration: 'underline',
						fontWeight: 'bold',
					}}
				>
					{param?.showSave ? 'Cancel' : 'Remove'}
				</Button>
			</div>
		</div>
	);
});

export default RowComponent;
