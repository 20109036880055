/* eslint-disable*/
import _, { cloneDeep } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { connect } from 'react-redux';
import { Button, Checkbox, Switch } from '@mui/material';
import ProductDetails from '../../../Components/Forms/Products/ProductDetails';
import { CreatePageHeader } from '../../../Components/Headers';
import productConfig from './allproduct.config';
import { FormSectionHeader } from '../../../Components/Forms';
import { getUnitConvertedUnit, inputHelper, isEmpty } from '../../../Helpers';
import { urls } from '../../../Constants';
import { formActions, modalActions } from '../../../Actions';
import { useAuth } from '../../../Components/Auth/Login/auth';
import productSubCategoryServcies from '@/Services/Form Services/productSubCategoryServcies';
import ProductVariantsTable from '@/Components/Forms/Products/ProductVariantsTable';
import { notificationServices, productServices } from '@/Services';
import { BackdropLoader } from '@/Components/Loaders';
import QualityCheck from '@/Components/AddProductParameters/qualityCheck';
import AddParameterComp from '@/Components/AddProductParameters/addParameterComp';
import ProductParametersModal from '@/Components/Modals/ProductParametersModal/productParametersModal';
import { Label } from '@/Components/ui/label';
import { ImageCropModal } from '@/Components/Modals/ImageCropModal';
import masterMenuServices from '@/Services/ProductSpecificationParamServices/productMasterMenu.services';
import QualityConfig from '@/Components/AddProductParameters/qualityConfig';
import { AlertModal } from '@/Components/Modals';
import productCategoryServcies from '@/Services/Form Services/productCategoryServcies';
import AddParameterModal from '@/Components/Modals/ProductParametersModal/AddParameterModal';

const ProductCreatePage = (props) => {
	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs ?? {};
	// eslint-disable-next-line object-curly-newline

	const {
		isEdit,
		toggleAlert,
		saveProduct,
		fetchProduct,
		saveVariant,
		fetchVariant,
		data,
		breadcrumbs,
		brands,
		variantData,
		qualityData,
		limit,
		toggleImageCrop,
		toggleProductParametersModal,
		toggleAddQualityParameterModal,
	} = props;
	const history = useHistory();
	const firstRender = useRef(true);
	const [loading, setLoading] = useState(false);
	const [selectedParameters, setSelectedParameters] = useState([]);
	const [newList, setNewList] = useState([]);
	const [cancle, setCancle] = useState(false);
	const hasRun = useRef(false);
	const [showError, setShowError] = useState(false);
	const [trueFetchProductParameters, settrueFetchProductParameters] = useState(false);
	const [qualityCheck, setQualityCheck] = useState({
		qualityCheckEnabled : true,
		categoryConfiguration : false,
		minSamplingQuantity: 5,
		weightCheck: true,
		temperatureCheck: {
			enabled : true,
			minTemp: 0,
			maxTemp: 0,
		},
		defectCheck: {
			enabled : false,
			areaThreshold: 0,
			countThreshold: 0,
			reject: 'both',
		},
		scanCount: 5,
		lastActiveCheck: null,
	});
	
    useEffect(() => {
        setNewList((prevList) => {
            const mergedList = [...prevList, ...selectedParameters];
            const uniqueList = mergedList.filter((item, index, self) =>
                index === self.findIndex((t) => t.id === item.id)
            )
            return uniqueList;
        });
    }, [selectedParameters]);

	useEffect(() => {
		if(firstRender.current){
			firstRender.current = false;
			return;
		}
		const activeChecks = [];
		if(qualityCheck?.weightCheck){
			activeChecks.push('weightCheck');
		}
		if(qualityCheck?.temperatureCheck?.enabled){
			activeChecks.push('temperatureCheck');
		}
		if(qualityCheck?.defectCheck?.enabled){
			activeChecks.push('defectCheck');
		}

		// Only enforce single check requirement if newList is empty
		if (newList.length === 0 && activeChecks.length === 0 && showError) {
			// If all checks are disabled, re-enable the last active check
			const lastCheck = qualityCheck?.lastActiveCheck || 'weightCheck';
			notificationServices?.generateNotification({
				type: 'error',
				message: 'Atleast One Check Is Required',
			});
			switch (lastCheck) {
				case 'weightCheck':
					setQualityCheck(prev => ({...prev, weightCheck: true}));
					break;
				case 'temperatureCheck':
					setQualityCheck(prev => ({
						...prev, 
						temperatureCheck: {
							...prev.temperatureCheck,
							enabled: true
						}
					}));
					break;
				case 'defectCheck':
					setQualityCheck(prev => ({
						...prev, 
						defectCheck: {
							...prev.defectCheck,
							enabled: true
						}
					}));
					break;
			}
		} else if (activeChecks.length === 1) {
			// Store the currently active check
			setQualityCheck(prev => ({
				...prev, 
				lastActiveCheck: activeChecks[0]
			}));
		}
	}, [qualityCheck?.weightCheck, qualityCheck?.temperatureCheck?.enabled, qualityCheck?.defectCheck?.enabled, newList, firstRender.current]);

	const disable = true;
	const disabledUnits =
		measurement === 'US' ? ['gram', 'litre', 'ml'] : ['oz', 'floz'];
	const [state, setState] = useState({
		...productConfig.initialState,
		fields: {
			...productConfig.initialState?.fields,
			densityUnit: measurement === 'US' ? 'Lb' : 'Kg',
			productUnits:
				productConfig.initialState?.fields?.productUnits?.filter(
					(ele) => {
						return !disabledUnits?.includes(ele?.id);
					}
				),
			purchaseUnits:
				productConfig.initialState?.fields?.productUnits?.filter(
					(ele) => {
						return !disabledUnits?.includes(ele?.id);
					}
				),
		},
	});
	const [productData, setProductData] = useState({});
	const [numberOfSubmissions, setNumberOfSubmissions] = useState(0);
	const [disableUnit, setDisableUnit] = useState(false);
	const [subCategoriesList, setSubCategoriesList] = useState([]);
	// variants
	const [variant, setVariantData] = useState({
		fields: cloneDeep(productConfig.initialState?.fields?.variants),
	});

	const { id } = useParams();

	const unit = state?.fields?.productUnits?.find(
		(ele) => ele?.isApplied === true
	)?.id;
	const pUnit = state?.fields?.purchaseUnits?.find(
		(ele) => ele?.isApplied === true
	)?.id;

	const fetchProductData = async () => {
		try{
		const req = {
						params: {
				productId: id,
			},
		};
		const response = await masterMenuServices.fetchProductData(req);
		if(response?.statusCode === 200){
			setProductData(response?.data);
		} else {
			notificationServices.generateNotification({
				type: 'error',
				message: 'Error fetching product data',
			});
		}
		} catch (error) {
			notificationServices.generateNotification({
				type: 'error',
				message: error?.message,
			});
		}
	};

	useEffect(() => {
		if (isEdit) {
			fetchProductData();
		// 	if (!isEmpty(data) && data?.id === id) {
		// 		setState(
		// 			productConfig.serializer(state, {
		// 				...data,
		// 				measurement,
		// 				densityUnit: measurement === 'US' ? 'Lb' : 'Kg',
		// 				variants: variantData?.variants,
		// 			})
		// 		);
		// 	} else {
		// 		fetchProduct({
		// 			entity: 'ALL_PRODUCTS',
		// 			req: {
		// 				params: {
		// 					id,
		// 				},
		// 			},
		// 			method: 'VIEW',
		// 		});
		// 	}
		// 	fetchVariant({
		// 		entity: 'VARIANTS',
		// 		req: {
		// 			params: {
		// 				id,
		// 			},
		// 		},
		// 		method: 'VIEW',
		// 		showNotification: true,
		// 	});
		// 	fetchProduct({
		// 		entity: 'PRODUCT_QUALITY_CONFIG',
		// 		req: {
		// 			params: {
		// 				id,
		// 				combo: true,
		// 			},
		// 		},
		// 		method: 'LIST',
		// 	});
		}
	}, []);

	useEffect(() => {
    	if (!isEdit && state?.fields?.category && !hasRun.current) {
        hasRun.current = true;
        setQualityCheck((prev) => ({ ...prev, categoryConfiguration: true }));
        fetchQualityConfiguration(state?.fields?.category);
    	}
	}, [state?.fields?.category]);

	const fetchSubCategories = async () => {
		try {
			const response =
				await productSubCategoryServcies.listProductSubCategory({
					req: {
						params: {
							categoryId: state?.fields?.category,
						},
					},
				});
			if (response?.statusCode === 200) {
				setSubCategoriesList(response?.data);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	// call api
	const fetchQualityConfiguration = async (categoryId) => {
		try {
			const req = {
				params : {
					id: categoryId,
			},
		}
		const response = await productCategoryServcies?.viewProductCategory({req});
		if(response?.statusCode === 200){
			if(response?.data?.qualityParameters.length === 0){
				notificationServices.generateNotification({
					type: 'error',
					message: 'No quality parameters found for this category',
				});
				setCancle(true);
				toggleAlert(false);
			}else{
			setNewList(response?.data?.qualityParameters);
			toggleAlert(false);
			}
		}else{
			notificationServices.generateNotification({
				type: 'error',
				message: 'Qualtiy configuration not found for this category',
			});
		}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}finally{
			toggleAlert(false);
		}
	};

	useEffect(() => {
		if (state?.fields?.category) {
			fetchSubCategories();
		}
	}, [state?.fields?.category]);

	const handleCategoryConfiguration = (e) => {
		setQualityCheck((prev) => ({ ...prev, categoryConfiguration: e.target.checked }))
		if(e.target.checked === true){
			toggleAlert(true, {
				width: '30rem',
				heading: '',
				message: 'Assigning category configuration to this sku will remove all the current quality setting.',
				message2: 'Do you wish to continue?',
				handleConfirm: () => {
					fetchQualityConfiguration(state?.fields?.category);
				},
			});
		}
	};

	useEffect(() => {
		if(cancle){
			setQualityCheck((prev) => ({...prev, categoryConfiguration : false}));
		}
	}, [cancle]);

	useEffect(() => {
		if (isEdit) {
			const newState = {};
			if(!isEmpty(productData)){
				setState(
					productConfig.serializer(state, {
					...productData,
						measurement,
						densityUnit: measurement === 'US' ? 'Lb' : 'Kg',
				}));
				setVariantData(productConfig?.variantSerilizer(productData));
				newState.qualityCheckEnabled  = productData?.qualityAnalysis?.qualityAnalysisEnabled;
				newState.categoryConfiguration = productData?.qualityAnalysis?.ifCategoryConfig;
				newState.minSamplingQuantity = productData?.qualityAnalysis?.minimumSamplingQuantityPercentage;
				newState.weightCheck = productData?.qualityAnalysis?.weightCheckRequired;
				newState.temperatureCheck = productData?.qualityAnalysis?.temperatureCheckRequired;
				newState.defectCheck = {
					enabled: productData?.qualityAnalysis?.defectConfig?.enabled,
					areaThreshold: productData?.qualityAnalysis?.defectConfig?.areaThreshold || 0.1,
					countThreshold: productData?.qualityAnalysis?.defectConfig?.countThreshold || 1,
					reject: productData?.qualityAnalysis?.defectConfig?.reject,
				  };
				newState.scanCount = productData?.qualityAnalysis?.scanCount;
				setQualityCheck(newState);
				setNewList(productData?.qualityAnalysis?.parameterChecks);
			}
		}
	}, [productData, id]);

	// useEffect(() => {
	// 	if (state?.fields?.category) {
	// 		fetchSubCategories({
	// 			entity: 'PRODUCT_SUB_CATEGORY',
	// 			req: {
	// 				params: {
	// 					categoryId: state?.fields?.category,
	// 				},
	// 			},
	// 			method: 'LIST',
	// 		});
	// 	}
	// }, [state?.fields?.category]);

	const handleFileInput = (e) => {
		const { fieldName } = inputHelper(e);
		const file = e.target.files[0];
		switch (fieldName) {
			case 'image':
				setState(
					_.update(_.cloneDeep(state), `fields[${fieldName}]`, () => {
						return file;
					})
				);
				break;
			default:
				break;
		}
	};

	// const handleImageInput = (fieldName, blob) => {
	// 	isChanged.current = true;
	// 	setState(
	// 		_.update(_.cloneDeep(state), `fields[${fieldName}]`, () => {
	// 			return blob;
	// 		})
	// 	);
	// };

	useEffect(() => {
		if (
			brands?.[state.fields.brand]?.ProductCategory?.[
				state?.fields?.category
			]?.unit
		) {
			setDisableUnit(true);
		} else {
			setDisableUnit(false);
		}
	}, [brands[state.fields.brand], state?.fields?.category]);

	const handleInput = (e) => {
		const { fieldName, fieldValue, dataset } = inputHelper(e);
		const newState = {};
		let haveUnit = false;
		// for form validations and error generation

		switch (fieldName) {
			case 'veg':
				newState[fieldName] = fieldValue === '1';
				break;
			case 'productClass':
				newState[fieldName] = state.fields.productClass.map(
					(productClassObject, idx) => {
						if (idx === parseInt(dataset.id, 10)) {
							return {
								...productClassObject,
								isApplied: fieldValue,
							};
						}
						return {
							...productClassObject,
							isApplied: false,
						};
					}
				);
				newState.purchaseUnits = state.fields.purchaseUnits.map(
					(productUnitObject) => {
						return {
							...productUnitObject,
							isApplied: false,
						};
					}
				);
				break;
			case 'category':
				newState.productUnits = state.fields.productUnits.map(
					(productUnitObject) => {
						if (
							productUnitObject.id ===
							brands[state.fields.brand].ProductCategory[
								fieldValue
							]?.unit
						) {
							newState.perUnit =
								productUnitObject?.id === 'floz'
									? 'Gal'
									: 'ltr';
							haveUnit = true;
							return {
								...productUnitObject,
								isApplied: true,
							};
						}
						return {
							...productUnitObject,
							isApplied: false,
						};
					}
				);
				break;
			case 'productUnits':
				newState[fieldName] = state.fields.productUnits.map(
					(productUnitObject, idx) => {
						if (idx === parseInt(dataset.id, 10)) {
							newState.perUnit =
								productUnitObject?.id === 'floz'
									? 'Gal'
									: 'ltr';
							return {
								...productUnitObject,
								isApplied: fieldValue,
							};
						}
						return {
							...productUnitObject,
							isApplied: false,
						};
					}
				);
				break;
			case 'purchaseUnits':
				newState[fieldName] = state.fields.purchaseUnits.map(
					(productUnitObject, idx) => {
						if (idx === parseInt(dataset.id, 10)) {
							return {
								...productUnitObject,
								isApplied: fieldValue,
							};
						}
						return {
							...productUnitObject,
							isApplied: false,
						};
					}
				);
				break;
			default:
				break;
		}
		if (fieldName === 'category') {
			setDisableUnit(haveUnit);
		}
		if (fieldName === 'hsnCode') {
			if (fieldValue.length > 8) {
				notificationServices?.generateNotification({
					type: 'error',
					message:
						'HSN Code length must be either 4 or 6 or 8 digits.',
				});
				setState({
					...state,
					fields: {
						...state.fields,
						[fieldName]: fieldValue.slice(0, 8),
						...newState,
					},
				});
				return;
			}
		}
		setState({
			...state,
			fields: {
				...state.fields,
				[fieldName]: fieldValue,
				...newState,
			},
		});
	};

	const handleEdit = async () => {
		const valid = productConfig?.validator(state, variant, isEdit);
		if (!valid) return;
		const req = {};
		try {
			setLoading(true);
			req.params = {
				productId: id,
			};
			const productData = await productConfig.deserializer(state);
			const variantData = await productConfig.variantDeserlizer(variant);
			req.data = {
				...productData?.data,
				variantData,
				QualityAnalysisEnabled: qualityCheck?.qualityCheckEnabled,
				categoryConfiguration: qualityCheck?.categoryConfiguration,
				minimumSamplingQuantityPercentage: Number(qualityCheck?.minSamplingQuantity) || 5,
				weightCheckRequired: qualityCheck?.weightCheck,
				temperatureCheckRequired: qualityCheck?.temperatureCheck,
				defectConfig: qualityCheck?.defectCheck,
				maxScanCount: qualityCheck?.scanCount,
				parameterChecks: newList,
			};
			const productResponse = await masterMenuServices?.updateProduct(req);
			if(productResponse?.statusCode === 200){
				notificationServices?.generateNotification({
					type: 'success',
					message: 'Product successfully Update!',
				});
				setLoading(false);
				history.push(`${urls.EXTENSION}${urls.ALL_PRODUCTS}`);
				return;
			}
		} catch (error) {
			notificationServices?.generateNotification({
				type: 'error',
				message: error.message || 'An error occurred',
			});
		} finally {
			setLoading(false);
		}
	};
	const handleSave = async (e) => {
		e.preventDefault();
		const valid = productConfig?.validator(state, variant, isEdit);
		if (!valid) return;

		// Add validation for quality parameters
		if (newList.length > 0) {
			const hasInvalidParameter = newList.some(param => {
				// Check range parameters
				if (param.rangeFlag) {
					if ((param.min === null || param.min === undefined || param.min === '') &&
						(param.max === null || param.max === undefined || param.max === '')) {
						notificationServices?.generateNotification({
							type: 'error',
							message: `Please add min or max values for parameter: ${param.name}`,
						});
						return true;
					}
				}
				
				// Check boolean parameters
				if (param.inputType === 'boolean') {
					if (param.expectedValue === null || param.expectedValue === undefined) {
						notificationServices?.generateNotification({
							type: 'error',
							message: `Please add expected value for parameter: ${param.name}`,
						});
						return true;
					}
				}
				return false;
			});

			if (hasInvalidParameter) return;
		}

		try {
			setLoading(true);
			const product = await productConfig.deserializer(state);
			const variantData = await productConfig.variantDeserlizer(state);
			if (isEdit) {
				await handleEdit();
			} else {
				const reqData = {
					...product?.data,
					variantData,
					QualityAnalysisEnabled: qualityCheck?.qualityCheckEnabled,
					categoryConfiguration: qualityCheck?.categoryConfiguration,
					minimumSamplingQuantityPercentage: Number(qualityCheck?.minSamplingQuantity) || 5,
					weightCheckRequired: qualityCheck?.weightCheck,
					temperatureCheckRequired: qualityCheck?.temperatureCheck,
					defectConfig: qualityCheck?.defectCheck,
					maxScanCount: qualityCheck?.scanCount,
					parameterChecks: newList,
				};
				const productResponse = await masterMenuServices?.createProduct(reqData);	
				if (productResponse?.statusCode == 200) {
						notificationServices?.generateNotification({
							type: 'success',
							message: 'Product successfully created!',
						});
						history.push(`${urls.EXTENSION}${urls.ALL_PRODUCTS}`);
				} else {
					throw new Error('Failed to create Product.');
				}
			}
			setNumberOfSubmissions((prev) => prev + 1);
		} catch (error) {
			notificationServices?.generateNotification({
				type: 'error',
				message: error.message || 'An error occurred',
			});
		} finally {
			setLoading(false);
		}
	};

	const generateDefaultOption = (type, brand, category, subcategory) => {
		switch (type) {
			case 'brand':
				if (!brand || brand.length === 0) {
					return (
						<option value={null} selected disabled hidden>
							Select a Brand
						</option>
					);
				}
				break;
			case 'category':
				if (brand) {
					if (!category || category.length === 0) {
						return (
							<option value={null} selected disabled hidden>
								Select a Category
							</option>
						);
					}
				}
				 else {
					return (
						<option value={null} selected disabled hidden>
							Select a Brand First
						</option>
					);
				}
				break;
			case 'subCategory':
				if (category) {
					if (!subcategory || subCategoriesList?.length === 0) {
						return (
							<option value={null} selected disabled hidden>
								Select a Sub Category
							</option>
						);
					}
				}
				return (
					<option value={null} selected disabled hidden>
						Select a Category First
					</option>
				);
			default:
				break;
		}
		return <></>;
	};
	const handleCancel = () => {
		if (isEdit) {
			history.push(`${urls.EXTENSION}${urls.ALL_PRODUCTS}/view/${id}`);
		} else {
			history.push(`${urls.EXTENSION}${urls.ALL_PRODUCTS}`);
		}
	};

	const excludedFields = useMemo(() => {
		let exclude = {};
		const productUnit = state?.fields?.productUnits?.reduce(
			(finalUnit, currUnit) => {
				if (currUnit?.isApplied) {
					return currUnit?.id;
				}
				return finalUnit;
			},
			''
		);

		if (
			productUnit === 'litre' ||
			productUnit === 'ml' ||
			productUnit === 'floz'
		) {
			exclude = {};
		} else {
			exclude.productDensity = true;
		}
		return exclude;
	}, [state?.fields?.productUnits]);
	//  varient
	const handleDelete = (e) => {
		const { dataset } = inputHelper(e);
		if (isEdit) {
			setVariantData(
				_.update(_.cloneDeep(variant), 'fields.variants', (variant) => {
					const newState = variant.filter((variant, idx) => {
						return idx != dataset.id;
					});
					return newState;
				})
			);
		} else {
			setState(
				_.update(_.cloneDeep(state), 'fields.variants', (variant) => {
					const newState = variant.filter((variant, idx) => {
						return idx != dataset.id;
					});
					return newState;
				})
			);
		}
	};

	const getMinMaxUnit = (productUnit, weight, measurementType) => {
		let tempUnit = productUnit;
		if (productUnit === 'count') {
			if (measurementType === 'US') {
				tempUnit = 'oz';
			} else {
				tempUnit = 'gram';
			}
		}
		return getUnitConvertedUnit(weight, tempUnit, true);
	};
	const getPriceUnit = (productUnit) => {
		switch (productUnit) {
			case 'count':
				return 'piece';
			case 'ltr':
				return 'ltr';
			case 'floz':
				return 'gal';
			case 'oz':
				return 'lbs';
			case 'gram':
				return 'kg';
			case 'ml':
				return 'ltr';
			default:
				return productUnit;
		}
	};
	const getNewVariantUnits = () => {
		const updatedVariant = {};
		//   const unit = state?.fields?.productUnits?.find((ele) => ele?.isApplied === true)?.id;
		//   const pUnit = state?.fields?.purchaseUnits?.find((ele) => ele?.isApplied === true)?.id;

		updatedVariant.minUnit = getMinMaxUnit(unit, 1);
		updatedVariant.maxUnit = getMinMaxUnit(unit, 1);
		updatedVariant.containerUnit = getUnitConvertedUnit(0, 'gram', true);
		updatedVariant.costPriceUnit = getPriceUnit(pUnit || unit);
		updatedVariant.transferPriceUnit = getPriceUnit(pUnit || unit);
		updatedVariant.sellingPriceUnit = getPriceUnit(pUnit || unit);
		updatedVariant.packSizeUnit = getPriceUnit('count');
		return updatedVariant;
	};
	useEffect(() => {
		// Skip the first render to prevent unwanted execution
		if (firstRender.current) {
			firstRender.current = false;
		}
		// Only proceed when the unit or pUnit changes (and not on first render)
		const variantUnit = isEdit
		  ? variant?.fields?.variants?.map((ele) => {
			  const units = getNewVariantUnits(ele);
			  return {
					...ele,
					...units,
			  };
			})
		  : state?.fields?.variants.map((ele) => {
			  const units = getNewVariantUnits(ele);
			  return {
					...ele,
					...units,
			  };
			});

		if (isEdit) {
		  // When in edit mode, update using `setVariantData`
		  setVariantData((prevState) => ({
				...prevState,
				fields: {
			  ...prevState.fields,
			  variants: variantUnit,
				},
		  }));
		} else {
		  // When not in edit mode, update using `setState`
		  setState((prevState) => ({
				...prevState,
				fields: {
			  ...prevState.fields,
			  variants: variantUnit,
				},
		  }));
		}
	}, [unit, pUnit, isEdit]);

	const addNewVariant = () => {
		const unit = state?.fields?.productUnits?.find(
			(ele) => ele?.isApplied === true
		)?.id;
		const purchaseUnit = state?.fields?.purchaseUnits?.find(
			(ele) => ele?.isApplied === true
		)?.id;
		if (isEdit) {
			const editCurrentVariants = _.get(variant, 'fields.variants', []);
			if (
				editCurrentVariants.length > 0 &&
				((purchaseUnit == null && unit !== 'count') ||
					(purchaseUnit != null && purchaseUnit !== 'count'))
			) {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Multiple Variants not allowed for this product',
				});
				return;
			}
			setVariantData(
				_.update(
					_.cloneDeep(variant),
					'fields.variants',
					(variants) => {
						const newArr = variants;
						const newUnits = getNewVariantUnits();
						const newV = {
							...productConfig?.newVariant,
							...newUnits,
						};
						if (newArr.length >= 10) {
							notificationServices.generateNotification({
								type: 'error',
								message: "Can't add more than 10 Variants",
							});
						} else if (newArr.length === 0) {
							newV.isDefault = true;
						}
						newArr.push(newV);
						return newArr;
					}
				)
			);
		} else {
			const currentVariants = _.get(state, 'fields.variants', []);

			if (
				currentVariants.length > 0 &&
				((purchaseUnit == null && unit !== 'count') ||
					(purchaseUnit != null && purchaseUnit !== 'count'))
			) {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Multiple Variants not allowed for this product',
				});
				return;
			}
			setState(
				_.update(_.cloneDeep(state), 'fields.variants', (variant) => {
					const newArr = variant;
					const newUnits = getNewVariantUnits(variant);
					const newV = {
						...productConfig?.newVariant,
						...newUnits,
					};
					if (newArr.length >= 10) {
						notificationServices.generateNotification({
							type: 'error',
							message: "Can't add more than 10 Variants",
						});
					} else if (newArr.length === 0) {
						newV.isDefault = true;
					}
					newArr.push(newV);
					return newArr;
				})
			);
		}
	};

	const handleVariantInput = (e) => {
		const { fieldName, fieldValue, dataset } = inputHelper(e);
		const handleScroll = (e) => {
			e.preventDefault();
		};

		// Add scroll prevention when input is focused
		e.target.addEventListener('wheel', handleScroll, { passive: false });
		
		// Remove event listener when input loses focus
		e.target.addEventListener('blur', () => {
			e.target.removeEventListener('wheel', handleScroll);
		});
		if (isEdit) {
			if (fieldName === 'isDefault') {
				// For default variant handling in edit mode
				setVariantData(
					_.update(
						_.cloneDeep(variant),
						'fields.variants',
						(variants) => {
							return variants.map((v, index) => ({
								...v,
								isDefault: index === parseInt(dataset.id, 10)
							}));
						}
					)
				);
				return;
			}
			if (fieldName === 'pieces') {
				const reg = /^[\d,]+$/;
				if (reg.test(fieldValue)) {
					setVariantData(
						_.update(
							_.cloneDeep(variant),
							`fields.variants[${dataset.id}][${fieldName}]`,
							() => {
								if (fieldName === 'isEnabled') return fieldValue;
								return fieldValue;
							}
						)
					);
				} else {
					notificationServices.generateNotification({
						type: 'error',
						message: 'Only Comma and Number values are allowed',
					});
				}
			} else {
				const updatedState = _.cloneDeep(variant);
				_.set(
					updatedState,
					`fields.variants[${dataset.id}][${fieldName}]`,
					fieldValue
				);
				_.set(updatedState, `fields.variants[${dataset.id}]`, {
					...updatedState.fields.variants[dataset.id],
				});
				setVariantData(updatedState);
			}	
			return;
		}
		if (fieldName === 'isDefault') {
			// For default variant handling in edit mode
			setState(
				_.update(
					_.cloneDeep(state),
					'fields.variants',
					(variants) => {
						return variants.map((v, index) => ({
							...v,
							isDefault: index === parseInt(dataset.id, 10)
						}));
					}
				)
			);
			return;
		}
		if (fieldName === 'pieces') {
			const reg = /^[\d,]+$/;
			if (reg.test(fieldValue)) {
				setState(
					_.update(
						_.cloneDeep(state),
						`fields.variants[${dataset.id}][${fieldName}]`,
						() => {
							if (fieldName === 'isEnabled') return fieldValue;
							return fieldValue;
						}	
					)
				);
			} else {
				notificationServices.generateNotification({
					type: 'error',
					message: 'Only Comma and Number values are allowed',
				});
			}
		} else {
			const updatedState = _.cloneDeep(state);
			if (!Array.isArray(updatedState.fields.variants)) {
				updatedState.fields.variants = [];
			  }
			  if (!updatedState.fields.variants[dataset.id]) {
				updatedState.fields.variants[dataset.id] = {};
			  }
			  updatedState.fields.variants[Number(dataset.id)] = {
				...updatedState.fields.variants[Number(dataset.id)],
				[fieldName]: fieldValue,
			  };
			setState(updatedState);
		}
	};
	const handleSaveUnits = (name, value, id) => {
		setState(
			_.update(
				_.cloneDeep(state),
				`fields.variants[${id}][${name}]`,
				() => {
					return value;
				}
			)
		);
	};
	const handleToggle = (value, name, variantIdx) => {
		if (isEdit) {
			setVariantData(
				_.update(
					_.cloneDeep(variant),
					'fields.variants',
					(variants) => {
						const newArr = variants;
						newArr[variantIdx][name] = value;
						return newArr;
					}
				)
			);
		} else {
			setState(
				_.update(_.cloneDeep(state), 'fields.variants', (variants) => {
					const newArr = variants;	
					newArr[variantIdx][name] = value;
					return newArr;
				})
			);
		}
	};
	// const handleSelectedValue = (value, name, id) => {
	// 	setState(
	// 		_.update(_.cloneDeep(state), 'fields.variants', (variants) => {
	// 			const newArr = variants;
	// 			newArr[id][name] = value;
	// 			return newArr;
	// 		})
	// 	);
	// };
	// qualityconfig
	// const handleQualityInput = (e) => {
	// 	const { fieldName, fieldValue, dataset } = inputHelper(e);
	// 	isChanged.current = true;

	// 	setQualityState((prevState) => {
	// 		const newFields = {
	// 			...prevState.fields,
	// 			defectParams: {
	// 				...prevState.fields.defectParams,
	// 			},
	// 		};

	// 		switch (dataset.type) {
	// 			case 'QMS':
	// 			case 'weight':
	// 			case 'temperature':
	// 				newFields[fieldName] = fieldValue;
	// 				break;

	// 			case 'defect':
	// 				if (fieldName === 'defectCheck') {
	// 					newFields.defectCheck = fieldValue;
	// 					if (!fieldValue) {
	// 						newFields.defectParams = {};
	// 					}
	// 				} else {
	// 					newFields.defectParams = {
	// 						...newFields.defectParams,
	// 						[fieldName]:
	// 							fieldValue === 'true' ? true : fieldValue,
	// 					};
	// 				}
	// 				break;

	// 			default:
	// 				break;
	// 		}
	// 		return {
	// 			...prevState,
	// 			fields: newFields,
	// 		};
	// 	});
	// };

	const fetchProductParameters = async() => {	
		try {
			const response = await masterMenuServices.productMasterMenuList();
			if(response?.statusCode === 200){
				return response?.data;
			}
		} catch (error) {
			console.log(error);
		}
		// calling api for getting the product parameters
	};
	const handleOpenModal = async() => {
		const productParameters = await fetchProductParameters();
		toggleProductParametersModal(true, productParameters);
	};

	const handleRemove = (id) => {
		const newSelectedParameters = newList.filter((parameter) => parameter.id !== id);
		setNewList(newSelectedParameters);
	}

	const openImageCropModal = (name, file = null) => {
		// console.log('TESTTTT', name, file);
		toggleImageCrop(true, {
			name,
			file,
		});
	};

	const handleImageSelect = (e) => {
		const { fieldName } = inputHelper(e);
		const file = e.target.files[0];
		if (file) {
			openImageCropModal(fieldName, file);
		}
	};

	const handleImageInput = (fieldName, blob) => {
		setState({
			...state,
			fields: {
				...state.fields,
				[fieldName]: blob,
			},
		});
	};

	const handleOpenAddModal = () => {
		toggleAddQualityParameterModal(true);
	}

	return (
		<div className='root'>
			<AlertModal  setCancle={setCancle}/>
			<BackdropLoader showProgress isLoading={loading} />
			<ImageCropModal
				handleConfirm={handleImageInput}
			/>
			<ProductParametersModal 
				selectedParameters={newList}
				setSelectedParameters={setSelectedParameters}
				handleOpenAddModal={handleOpenAddModal}
				trueFetchProductParameters={trueFetchProductParameters}
				settrueFetchProductParameters={settrueFetchProductParameters}
			/>
			<AddParameterModal
				settrueFetchProductParameters={settrueFetchProductParameters}
			/>
				<CreatePageHeader
					heading={isEdit ? 'Edit Product' : 'Create Product'}
					handleSave={handleSave}
					handleCancel={handleCancel}
					breadcrumbs={[
						...breadcrumbs,
						{
							link: '',
							name: isEdit
								? `Edit ${state.fields.name}`
								: 'Create new',
						},
					]}
					showBreadcrumbs
				/>
				<FormSectionHeader
					tooltip='description for the section'
					sectionName='General Details'
				>
					<ProductDetails
						mandatoryFields={productConfig.mandatoryFields}
						fields={state.fields}
						disableMsg={
							isEdit
								? false
								: state.fields?.variants?.length > 1
									? 'Please Remove Variants to edit fields again'
									: false
						}
						disablebox={
							isEdit
								? variant?.fields?.variants?.length > 1
									? disable
									: false
								: false
						}
						handleFileInput={handleFileInput}
						handleImageSelect={handleImageSelect}
						generateDefaultOption={generateDefaultOption}
						handleInput={handleInput}
						exclude={excludedFields}
						disableUnit={disableUnit}
						subCategoriesList={subCategoriesList}
					/>
				</FormSectionHeader>
				<FormSectionHeader
					tooltip='description for the section'
					sectionName='Varients Details'
				>
					<div className='w-full'>
						<div className='float-right m-3'>
							<Button
								variant='outlined'
								color='primary'
								onClick={addNewVariant}
							>
								Add New Variant
							</Button>
						</div>
						<ProductVariantsTable
							handleDelete={handleDelete}
							// unitprop={variant?.fields}
							variants={isEdit ? variant?.fields?.variants : state?.fields?.variants}
							handleVariantInput={handleVariantInput}
							state={state}
							setState={setState}
							handleSaveUnits={handleSaveUnits}
							handleToggle={handleToggle}
							measurement={measurement}
							// minUnit={minUnit}
							// setMinUnit={setMinUnit}
							// maxUnit={maxUnit}
							// setMaxUnit={setMaxUnit}
							// costPriceUnit={costPriceUnit}
							// setCostPriceUnit={setCostPriceUnit}
							// transferPriceUnit={transferPriceUnit}
							// setTransferPriceUnit={setTransferPriceUnit}
							// sellingPriceUnit={sellingPriceUnit}
							// setSellingPriceUnit={setSellingPriceUnit}
							// containerUnit={containerUnit}
							// setContainerUnit={setContainerUnit}
						/>
					</div>
				</FormSectionHeader>

				{/* <FormSectionHeader
					tooltip='description for the section'
					sectionName='Quality Config'
				>
					<ProductQualityConfig
						fields={qualityState?.fields}
						handleInput={handleQualityInput}
					/>
				</FormSectionHeader> */}
				<div className='flex gap-2 m-[1rem] mt-4 justify-between'>
					<div className='flex item-center'>
						<Label className='text-lg text-gray-600 font-medium'>Quality Analysis Inspection</Label>
					</div>
				{/* <div className='flex items-center gap-2'>
				<Checkbox
					checked={qualityCheck?.categoryConfiguration}
					onChange={() => setQualityCheck((prev) => ({...prev, categoryConfiguration : !prev.categoryConfiguration}))} 
    				size="small"
    				sx={{
						'& .MuiSvgIcon-root': {
							fontSize: '1.2rem',
						},
						'&.Mui-checked': {
            				color: '#000000',
        				},
    				}}
				/>
				<Label className='text-lg text-gray-600 font-medium'>Follow Category Configuration</Label>
				</div> */}
			</div>
				<QualityConfig
					qualityCheck={qualityCheck}
					setQualityCheck={setQualityCheck}
					disableFields={qualityCheck?.qualityCheckEnabled ? false : true}
					pageName='Product'
					unit={isEdit ? unit : state?.fields?.productUnits.find((unit) => unit.isApplied === true)?.id}
				/>
			<div className={`transition-all duration-700 ease-in-out transform origin-top ${
        		qualityCheck?.qualityCheckEnabled ? 'max-h-[500px] scale-y-100 opacity-100' : 'max-h-[0px] scale-y-0 opacity-0'
    		}`}>
				<QualityCheck
					qualityCheck={qualityCheck}
					setQualityCheck={setQualityCheck}
					pageName='Product'
					showEnabled={true}
					setShowError={setShowError}
				/>
				<AddParameterComp
					handleOpenModal={handleOpenModal}
					selectedParameters={newList}
					handleRemove={handleRemove}
					setNewList={setNewList}
					qualityCheck={qualityCheck}
					setQualityCheck={setQualityCheck}
					hideCategoryConfiguration
					handleCategoryConfiguration={handleCategoryConfiguration}
				/>
			</div>
		</div>
	);
};
ProductCreatePage.defaultProps = {
	limit: 10,
};
const mapStateToProps = (state) => {
	const data = state.form.ALL_PRODUCTS.data.VIEW;
	const variantData = state.form.VARIANTS.data.VIEW;
	const { brands } = state.brand;
	const qualityData = state.form.PRODUCT_QUALITY_CONFIG.data.LIST;
	return {
		brands,
		data,
		variantData,
		qualityData,
	};
};
const mapDispatchToProps = {
	saveProduct: formActions.gateway,
	saveVariant: formActions.gateway,
	fetchProduct: formActions.gateway,
	fetchVariant: formActions.gateway,
	toggleImageCrop: modalActions.toggleImageCrop,
	toggleProductParametersModal: modalActions.toggleProductParametersModal,
	toggleAlert: modalActions.toggleAlert,
	toggleAddQualityParameterModal: modalActions.toggleAddQualityParameterModal,
};
ProductCreatePage.defaultProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ProductCreatePage);
