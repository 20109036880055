/* eslint-disable */
import { useEffect, useState } from 'react';
import TableRowProduct from './tableRowproduct';
function TableComponent(props) {
    const {columns, data, handleRemove = () => {}, setNewList = () => {}, isDisabled = false} = props;
    const handleUpdate = (id, key, value) => {
        console.log(id, key, value);
        if ((key === 'min' || key === 'max') && String(value).includes('.')) {
            const decimalRegex = /^\d+(\.\d{0,2})?$/;
            
            if (decimalRegex.test(String(value))) {
                const formattedValue = parseFloat(value);
                
                setNewList((prevList) => {
                    return prevList.map((item) => 
                        item.id === id ? { ...item, [key]: formattedValue } : item
                    );
                });
            }
        } else {
            setNewList((prevList) => {
                return prevList.map((item) => 
                    item.id === id ? { ...item, [key]: value } : item
                );
            });
        }
    };

    return (
        <div>
            <table>
                <thead>
                    <tr>
                        {columns.map((coloumn) => (
                            <th 
                                key={coloumn.name}
                                style={{
                                    width: coloumn?.width ? coloumn.width : ''
                                }}
                            >
                                {coloumn?.icon && coloumn?.icon}
                                {coloumn.name}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data?.map((item) => (
                        <TableRowProduct 
                            columns={columns}
                            rowData={item}
                            handleRemove={handleRemove}
                            handleUpdate={handleUpdate}
                            isDisabled={isDisabled}
                        />
                    ))}
                </tbody>
            </table>

        </div>
    );
}
export default TableComponent;  
