/* eslint-disable */
import { cloneDeep, update } from 'lodash';
// eslint-disable-next-line object-curly-newline
import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
// eslint-disable-next-line object-curly-newline
import { useHistory, useParams } from 'react-router';
import { formActions, modalActions } from '../../../Actions';
// import { DraggableHTabs } from '../../../Components/Draggable';
import { FormSectionHeader } from '../../../Components/Forms';
import { CreatePageHeader } from '../../../Components/Headers';
import { GeneralDetails } from '../../../Components/Forms/Customizations';
import { urls } from '../../../Constants';
// eslint-disable-next-line object-curly-newline
import { inputHelper, isEmpty } from '../../../Helpers';
import cookingRecipeConfig, { addToStages, depthFirstIntermediates, fetchProductsRecipe, findAllIntermediateProducts, findSerializedIntermediateProducts, updateForStageReferences } from './cookingRecipe.config';
import {
	RecipeDetails,
	RecipeStage,
} from '../../../Components/Forms/Cooking/Cooking Recipe';
import styles from './CookingRecipe.module.scss';
import ExportProcessModal from '../../../Components/Modals/ExportProcessModal/ExportProcessModal';
import ProductVariantsModal from '../../../Components/Modals/ProductVariantsModal/ProductVariantsModal';
import cookingRecipesServices from '../../../Services/Form Services/cookingRecipes.services';
import CookingProcessesTabs from '../../../Components/Forms/Cooking/CookingProcessesTabs/CookingProcessesTabs';
import { AlertModal } from '../../../Components/Modals';
import { notificationServices } from '@/Services';
import { cookingConfig } from '@/Pages/Food Products/All Products/cooking.config';
import ProductCookingConfig from '@/Components/Forms/Products/ProductCookingConfig';

const CookingRecipeCreatePage = (props) => {
	const [state, setState] = useState({
		...cookingRecipeConfig.initialState,
	});
	const [activeStage, setActiveStage] = useState(1);
	const [activeProcess, setActiveProcess] = useState(
		state?.fields?.stages?.items?.[1]?.processes?.order?.length ?? -1
	);
	const [numberOfSubmissions, setNumberOfSubmissions] = useState(0);
	const { fields, errors } = state;
	const [cookingState, setCookingState] = useState({
			...cookingConfig?.initialState,
		});

	const activeStageValue = fields.stages.items?.[activeStage];

	const {
		isEdit,
		data,
		fetchRecipe,
		fetchCookingStations,
		saveRecipe,
		breadcrumbs,
		processTypes,
		fetchProcessTypesList,
		productsMap,
		toggleAlert,
		saveProductConfig,
		fetchProductRecipe,
		recipeData,
	} = props;

	const history = useHistory();
	const { id } = useParams();

	useEffect(() => {
		fetchCookingStations({
			entity: 'COOKING_STATIONS',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
		return () => {
			setState({
				...cookingRecipeConfig.initialState,
			});
		}
	}, []);

	useEffect(() => {
		fetchProductRecipe({
			entity: 'COOKING_PRODUCT_CONFIG',
			req: {
				params: {
					id,
				},
			},
			method: 'VIEW',
		});
	}, [id]);

	useEffect(() => {
		if (isEdit) {
			if (isEmpty(data) || (!isEmpty(data) && data?.product?.id !== id)) {
				fetchRecipe({
					entity: 'RECIPES',
					req: {
						params: {
							productId: id,
						},
					},
					method: 'VIEW',
				});
			}
		}
	}, [isEdit, id]);

	useEffect(() => {
		if (processTypes.length === 0) {
			fetchProcessTypesList({
				entity: 'PROCESS_TYPES',
				req: {
					params: {
						id: '',
					},
				},
				method: 'LIST',
			});
		}
	}, [processTypes])
	
	const allIntermediateProducts = useMemo(() => {
		return findAllIntermediateProducts(data);
	}, [data]);

	useEffect(() => {
		setCookingState(cookingConfig.serializer(cookingState, recipeData));
	}, [recipeData]);
	useEffect(() => {
		setState({
			activeTab: 0,
			fields: cloneDeep(cookingRecipeConfig.initialState.fields),
		});
		if (!isEmpty(data) && data.product.id == id) {
			const serializedProductRecipe = cookingRecipeConfig.serializer(
				state,
				data,
				processTypes
			);
			(async () => {
				const stagesArr = cloneDeep(
					serializedProductRecipe?.fields?.stages
				);
				const count = await depthFirstIntermediates(
					allIntermediateProducts,
					{
						...stagesArr,
						stageLevel: 1,
					},
					processTypes
				);
				const { stages, ...remaining } = serializedProductRecipe?.fields;
				stagesArr.items['1'] = {
					...stagesArr.items['1'],
					recipeData: remaining,
				};
				serializedProductRecipe.fields.stages = stagesArr;
				setState(serializedProductRecipe);
			})();
			setActiveStage(1);
			setActiveProcess(
				state.fields.stages.items[1]?.processes.order.length ?? -1
			);
		}
	}, [data, allIntermediateProducts]);

	const handleInput = (e) => {
		const { fieldName, fieldValue, dataset } = inputHelper(e);
		let error = '';
		const newState = {};
		// for form validations and error generation
		switch (fieldName) {
			case 'comboName':
				if (fieldValue.length === 0) {
					error = 'This field is required';
				} else {
					error = '';
				}
				errors[fieldName] = error;
				break;
			case 'veg':
				newState[fieldName] = fieldValue === '1';
				break;
			default:
				break;
		}

		switch (dataset.type) {
			case 'recipe':
				setState(
					update(cloneDeep(state), `fields[${fieldName}]`, () => {
						return fieldValue;
					})
				);
				break;
			case 'stage':
				setState(
					update(
						cloneDeep(state),
						`fields.stages.items[${activeStage}][${fieldName}]`,
						() => {
							return fieldValue;
						}
					)
				);
				break;
			case 'process':
				setState(
					update(
						cloneDeep(state),
						`fields.stages.items[${activeStage}].processes.items[${activeProcess}][${fieldName}]`,
						() => {
							return fieldValue;
						}
					)
				);
				break;
			case 'group':
				switch (fieldName) {
					case 'isApplied':
						setState(
							update(
								cloneDeep(state),
								`fields.stages.items[${activeStage}].processes.items[${activeProcess}].customizations.items[${dataset.parent}].applicableOn[${dataset.id}]`,
								(applicableOnObj) => {
									const newApplicableOn =
										cloneDeep(applicableOnObj);
									newApplicableOn.isApplied = fieldValue;
									return newApplicableOn;
								}
							)
						);
						break;
					case 'type':
						setState(
							update(
								cloneDeep(state),
								`fields.stages.items[${activeStage}].processes.items[${activeProcess}].customizations.items[${dataset.id}]`,
								(groupObj) => {
									// eslint-disable-next-line no-param-reassign
									groupObj[fieldName] = fieldValue;
									if (fieldValue === 'REMOVABLE') {
										// eslint-disable-next-line no-param-reassign
										groupObj.deltaPricing = false;
									}
									return groupObj;
								}
							)
						);
						break;
					default:
						setState(
							update(
								cloneDeep(state),
								`fields.stages.items[${activeStage}].processes.items[${activeProcess}].customizations.items[${dataset.id}][${fieldName}]`,
								() => {
									return fieldValue;
								}
							)
						);
						break;
				}
				break;
			case 'sku':
				if (dataset.variant) {
					setState((prevState) => {
						return update(
							cloneDeep(prevState),
							`fields.stages.items[${activeStage}].processes.items[${activeProcess}].customizations.items[1].skus[${dataset.id}].variants[${dataset.variant}][${fieldName}]`,
							() => {
								return fieldValue;
							}
						);
					});
				} else {
					setState((prevState) => {
						return update(
							cloneDeep(prevState),
							`fields.stages.items[${activeStage}].processes.items[${activeProcess}].customizations.items[1].skus[${dataset.id}][${fieldName}]`,
							() => {
								return fieldValue;
							}
						);
					});
				}
				break;
			default:
				setState({
					...state,
					fields: {
						...state.fields,
						[fieldName]: fieldValue,
						...newState,
					},
					...errors,
				});
		}
	};
	const handleRecipeDetailsInput = (e) => {
		const { fieldName, fieldValue, dataset } = inputHelper(e);
		let error = '';
		const newState = {};
		// for form validations and error generation
		switch (fieldName) {
			case 'comboName':
				if (fieldValue.length === 0) {
					error = 'This field is required';
				} else {
					error = '';
				}
				errors[fieldName] = error;
				break;
			case 'veg':
				newState[fieldName] = fieldValue === '1';
				break;
			default:
				break;
		}

		switch (dataset.type) {
			case 'recipe':
				setState(
					update(cloneDeep(state), `fields[${fieldName}]`, () => {
						return fieldValue;
					})
				);
				break;
			case 'stage':
				setState(
					update(
						cloneDeep(state),
						`fields.stages.items[${activeStage}].recipeData[${fieldName}]`,
						() => {
							return fieldValue;
						}
					)
				);
				break;
			case 'process':
				setState(
					update(
						cloneDeep(state),
						`fields.stages.items[${activeStage}].processes.items[${activeProcess}][${fieldName}]`,
						() => {
							return fieldValue;
						}
					)
				);
				break;
			case 'group':
				switch (fieldName) {
					case 'isApplied':
						setState(
							update(
								cloneDeep(state),
								`fields.stages.items[${activeStage}].processes.items[${activeProcess}].customizations.items[${dataset.parent}].applicableOn[${dataset.id}]`,
								(applicableOnObj) => {
									const newApplicableOn =
										cloneDeep(applicableOnObj);
									newApplicableOn.isApplied = fieldValue;
									return newApplicableOn;
								}
							)
						);
						break;
					case 'type':
						setState(
							update(
								cloneDeep(state),
								`fields.stages.items[${activeStage}].processes.items[${activeProcess}].customizations.items[${dataset.id}]`,
								(groupObj) => {
									// eslint-disable-next-line no-param-reassign
									groupObj[fieldName] = fieldValue;
									if (fieldValue === 'REMOVABLE') {
										// eslint-disable-next-line no-param-reassign
										groupObj.deltaPricing = false;
									}
									return groupObj;
								}
							)
						);
						break;
					default:
						setState(
							update(
								cloneDeep(state),
								`fields.stages.items[${activeStage}].processes.items[${activeProcess}].customizations.items[${dataset.id}][${fieldName}]`,
								() => {
									return fieldValue;
								}
							)
						);
						break;
				}
				break;
			case 'isBulk':
				setState((prevState) => {
					return update(
						cloneDeep(prevState),
						`fields.stages.items[${activeStage}].recipeData.isBulk`,
						() => {
							return fieldValue;
						}
					);
				});
				break;
			case 'sku':
				if (dataset.variant) {
					setState((prevState) => {
						return update(
							cloneDeep(prevState),
							`fields.stages.items[${activeStage}].processes.items[${activeProcess}].customizations.items[1].skus[${dataset.id}].variants[${dataset.variant}][${fieldName}]`,
							() => {
								return fieldValue;
							}
						);
					});
				} else {
					setState((prevState) => {
						return update(
							cloneDeep(prevState),
							`fields.stages.items[${activeStage}].processes.items[${activeProcess}].customizations.items[1].skus[${dataset.id}][${fieldName}]`,
							() => {
								return fieldValue;
							}
						);
					});
				}
				break;
			default:
				setState({
					...state,
					fields: {
						...state.fields,
						[fieldName]: fieldValue,
						...newState,
					},
					...errors,
				});
		}
	};

	const handleBatchSize = (e, variantId) => {
		const newObj = {
			...fields?.stages?.items?.[activeStage]?.recipeData?.batchSize,
			[`${variantId}`]: Number(e?.target?.value),
		};
		setState(
			update(cloneDeep(state), `fields.stages.items[${activeStage}].recipeData.batchSize`, () => {
				return newObj;
			})
		);
	};

	// cooking config
	const handleCookingInput = (e) => {
		const { fieldName, fieldValue } = inputHelper(e);
		setCookingState((prevState) => {
			const newFields = {
				...prevState?.fields,
			};
			newFields[fieldName] = fieldValue;
			return {
				...prevState,
				fields: newFields,
			};
		});
	};


	// const addNewStage = () => {
	// 	let newLen = activeStage;
	// 	let newState = update(
	// 		cloneDeep(state),
	// 		'fields.stages.order',
	// 		(arr) => {
	// 			let len = 1;
	// 			if (arr.length > 0) {
	// 				len = Math.max(...arr) + 1;
	// 			}
	// 			arr.push(len);
	// 			newLen = len;
	// 			return arr;
	// 		}
	// 	);
	// 	newState = update(newState, 'fields.stages.items', (obj) => {
	// 		const newObj = {
	// 			...obj,
	// 		};
	// 		const { newStageObj } = cloneDeep(cookingRecipeConfig);
	// 		newStageObj.id = newLen;
	// 		newStageObj.name = `Stage ${newLen}`;
	// 		newObj[newLen] = newStageObj;
	// 		return newObj;
	// 	});

	// 	setState(newState);
	// 	setActiveStage(newLen);
	// };

	const handleStageReOrdering = (dragIndex, hoverIndex) => {
		const newState = update(
			cloneDeep(state),
			'fields.stages.order',
			(arr) => {
				const dragObj = arr[dragIndex];
				arr.splice(dragIndex, 1);
				arr.splice(hoverIndex, 0, dragObj);
				return arr;
			}
		);
		setState(newState);
	};

	const addDependencies = (currentStage, addedDependencies) => {
		const newState = update(
			cloneDeep(state),
			`fields.stages.items[${currentStage}].dependencies`,
			(arr) => {
				const newArr = [...arr, ...addedDependencies];
				return newArr;
			}
		);
		setState(newState);
	};

	const removeDependency = (currentStage, removedDependency) => {
		const newState = update(
			cloneDeep(state),
			`fields.stages.items[${currentStage}].dependencies`,
			(arr) => {
				const newArr = [...arr];
				newArr.splice(removedDependency, 1);
				return newArr;
			}
		);
		setState(newState);
	};

	const addNewProcess = () => {
		let newLen = activeProcess;
		let newState = update(
			cloneDeep(state),
			`fields.stages.items[${activeStage}].processes.order`,
			(arr) => {
				let len = 1;
				if (arr.length > 0) {
					len = Math.max(...arr) + 1;
				}
				arr.push(len);
				newLen = len;
				return arr;
			}
		);
		newState = update(
			newState,
			`fields.stages.items[${activeStage}].processes.items`,
			(obj) => {
				const newObj = {
					...obj,
				};
				const { newProcessObj } = cloneDeep(cookingRecipeConfig);
				newProcessObj.id = newLen;
				newProcessObj.name = `Step ${newLen}`;
				newProcessObj.customizations.items[1].applicableOn =
					fields.product.variants.map((variant) => {
						return {
							id: variant.id,
							name: variant.displayName,
							size: variant.size,
							isApplied: false,
						};
					});
				newObj[newLen] = newProcessObj;
				return newObj;
			}
		);

		setState(newState);
		setActiveProcess(newLen);
	};

	const handleProcessReOrdering = (dragIndex, hoverIndex) => {
		const newState = update(
			cloneDeep(state),
			`fields.stages.items[${activeStage}].processes.order`,
			(arr) => {
				const dragObj = arr[dragIndex];
				arr.splice(dragIndex, 1);
				arr.splice(hoverIndex, 0, dragObj);
				return arr;
			}
		);
		setState(newState);
	};

	const exportProcess = (stageConfig) => {
		const { from, to, processId } = stageConfig;

		const clonedState = cloneDeep(state);

		setState(
			update(clonedState, 'fields.stages', (stages) => {
				const newStages = stages;
				const fromProcesses = newStages.items[from].processes;
				// get process object
				const processObj = fromProcesses.items[processId];

				// add process to the 'to' stage's processes' items and order
				const toProcesses = newStages.items[to].processes;
				// get max processId
				const maxProcessId =
					toProcesses.order.length > 0
						? Math.max(...toProcesses.order)
						: 0;
				// set the new processId as max + 1
				const newProcessId = maxProcessId + 1;
				processObj.id = newProcessId;
				// add it to items and order
				toProcesses.items[newProcessId] = processObj;
				toProcesses.order.push(newProcessId);

				// remove process from the 'from' stage's processes' items and order
				delete fromProcesses.items[processId];
				fromProcesses.order = fromProcesses.order.filter((pId) => {
					return pId !== parseInt(processId, 10);
				});
				return newStages;
			})
		);
	};

	const addSKUs = async ({ productId, variantId, price }) => {
		const sku = productsMap[productId];
		let clonedState = cloneDeep(state);
		const stagesValuesArr = Object.entries(clonedState?.fields?.stages?.items ?? {});
		if (sku?.skuType === 'INTERMEDIATE') {
			// const intermediateRecipe = await fetchIntermediateRecipe(sku?.id);
			// const intermediatesIntermediateProd =
			// 	findSerializedIntermediateProducts(intermediateRecipe);
			// // console.log(
			// // 	'intermediatesIntermediateProd',
			// // 	intermediatesIntermediateProd
			// // );
			let stagesObj = clonedState.fields.stages;
			// if (!isIntermediateAlreadyAdded) {
			// 	const stagesArr = Object.values(stagesObj?.items);
	
			// 	stagesArr?.splice(Number(activeStage), 0, {
			// 		...intermediateRecipe?.stages?.items?.['1'],
			// 		isIntermediate: true,
			// 		forStage: [Number(activeStage)],
			// 	});
			// 	stagesObj?.order?.push(Number(activeStage));
	
			// 	stagesObj.items = stagesArr?.reduce((acc, ele, index) => {
			// 		acc[index + 1] = ele;
			// 		return acc;
			// 	}, {});
			// 	stagesObj.items = updateForStageReferences(
			// 		stagesObj.items,
			// 		Number(activeStage) + 1,
			// 		1
			// 	);
			// } else {
			// 	stagesObj.items[isIntermediateAlreadyAdded?.[0]] = {
			// 		...stagesObj.items?.[isIntermediateAlreadyAdded?.[0]],
			// 		forStage: [
			// 			...stagesObj.items?.[isIntermediateAlreadyAdded?.[0]]?.forStage,
			// 			Number(activeStage)
			// 		]
			// 	};
			// }
			let newStagesObj = cloneDeep(stagesObj);
			// newStagesObj = {
			// 	...newStagesObj,
			// 	stageLevel: Number(activeStage) + 1,
			// };
			// let itemsAddedCount = await depthFirstIntermediates(
			// 	intermediatesIntermediateProd,
			// 	newStagesObj,
			// 	processTypes,
			// 	Number(activeStage) + 1
			// );
			// console.log('itemsAddedCount', itemsAddedCount);
			newStagesObj = await addToStages({
				productsArr: [
					{
						...sku,
						skuId: sku?.id,
					},
				],
				addToIndex: Number(activeStage) + 1,
				activeStage: Number(activeStage),
				processTypes,
				currentStagesAcc: newStagesObj,
			});
			// newStagesObj.items = updateForStageReferences(
			// 	newStagesObj.items,
			// 	Number(activeStage) + 1,
			// 	itemsAddedCount
			// );
			// const newStageId = clonedState.fields.stages.order.length + 1;

			// const newStage = {
			// 	id: 1,
			// 	stageId: intermediateRecipe?.product?.id,
			// 	name: intermediateRecipe?.product?.productName,
			// 	station: intermediateRecipe?.recipe?.stages?.[0]?.stationId,
			// 	instructions: intermediateRecipe?.recipe?.stages?.[0]?.stageDescription,
			// 	isDependent: intermediateRecipe?.recipe?.stages?.[0]?.stageDependency?.length > 0 ?? false,
			// 	dependencies: intermediateRecipe?.recipe?.stages?.[0]?.stageDependency,
			// 	processes: intermediateRecipe?.recipe?.stages?.[0]?.processes,
			// };

			// let stagesObj = clonedState.fields.stages;
			// const stagesArr = Object.values(stagesObj?.items);

			// stagesArr?.splice(Number(activeStage), 0, {
			// 	...intermediateRecipe?.stages?.items?.['1'],
			// 	isIntermediate: true,
			// 	forStage: activeStage,
			// });

			// stagesObj.items = stagesArr?.reduce((acc, ele, index) => {
			// 	acc[index + 1] = ele;
			// 	return acc;
			// }, {});

			// const newOrder = stagesObj?.order?.length + 1;
			// // stagesObj.items[newOrder] = {
			// // 	...intermediateRecipe?.stages?.items?.['1'],
			// // 	isIntermediate: true,
			// // };
			// stagesObj?.order?.push(newOrder);

			clonedState.fields.stages = newStagesObj;
		}

		const finalObj = {
			id: null,
			skuId: productId,
			variantId,
			isDefault: false,
			unit: sku.unit,
			skuType: sku?.skuType,
			variants: fields.product.variants.map((variant) => ({
				qty: 1,
				price,
				id: variant.id,
			})),
		};

		const skusPath = `fields.stages.items[${activeStage}].processes.items[${activeProcess}].customizations.items[1].skus`;
		clonedState = update(clonedState, skusPath, (skusArr) => {
			skusArr.push(finalObj);
			return skusArr;
		});

		// console.log('clonedState', clonedState);
		setState(clonedState);
	};

	const findAllSubProcess = (stagesState, processOfKey) => {
		const processIds = [];
		const findProcesses = (key) => {
			const keys = Object.keys(stagesState)?.filter((eleKey) => {
				const eleValue = stagesState[eleKey];
				return eleValue?.forStage?.includes(Number(key));
			});
			keys?.forEach((currKey) => {
				if (!processIds?.includes(currKey)) {
					processIds?.push(currKey)
				}
				findProcesses(currKey);
			})
		}
		findProcesses(processOfKey);
		return processIds;
	}

	const findAllSubProcess2 = (stagesState, processOfKey) => {
		const processIds = [];
		const visited = new Set(); // Track visited keys to prevent cycles
		const stack = [processOfKey];

		while (stack.length > 0) {
			const currentKey = stack.pop();
			if (visited.has(currentKey)) {
				continue;
			}
			visited.add(currentKey);

			const keys = Object.keys(stagesState).filter((eleKey) => {
				const eleValue = stagesState[eleKey];
				return eleValue?.forStage?.includes(Number(currentKey));
			});

			keys.forEach((key) => {
				if (!processIds.includes(key)) {
					processIds.push(key);
				}
				stack.push(key); // Add to stack for further processing
			});
		}

		return processIds;
	};


	const handleDelete = (e) => {
		const { dataset } = inputHelper(e);
		switch (dataset.type) {
			case 'stage':
				setState(
					update(cloneDeep(state), 'fields.stages', (stages) => {
						const newState = stages;

						// Remove SKUs associated with the stage being deleted
						stages.items['1'].processes.items = Object.entries(
							stages.items['1'].processes.items
						).reduce((acc, [key, value]) => {
							acc[key] = {
								...value,
								customizations: {
									...value.customizations,
									items: {
										...value.customizations.items,
										['1']: {
											...value.customizations.items['1'],
											skus: value.customizations.items[
												'1'
											].skus.filter(
												(sku) =>
													sku.skuId !==
													stages.items[activeStage]
														?.stageProduct?.id
											),
										},
									},
								},
							};
							return acc;
						}, {});

						delete newState.items[activeStage];
						const subProcesses = findAllSubProcess(newState?.items, activeStage);

						subProcesses.forEach((subProcessKey) => {
							delete newState.items[subProcessKey];
						});

						newState.order = fields.stages.order.filter((stage) => {
							return stage !== activeStage;
						});
						// remove the deleted stage from other stages' dependencies
						for (
							let stageIdx = 0;
							stageIdx < newState.order.length;
							stageIdx++
						) {
							const stageId = newState.order[stageIdx];
							const stage = stages.items[stageId];
							if (stage) {
								stage.dependencies =
									stage?.dependencies?.filter((dep) => {
										return dep !== activeStage;
									});
							}
						}
						// if number of stages after removal is greater than 0
						// set active stage as the first one
						if (newState.order.length > 0) {
							const firstStageId = stages.order[0];
							setActiveStage(firstStageId);
							// if number of processes are greater than 0
							// set active process as the first one
							if (
								newState.items[firstStageId].processes.order
									.length > 0
							) {
								const firstProcessId =
									stages.items[firstStageId].processes
										.order[0];
								setActiveProcess(firstProcessId);
							} else {
								// else set as -1
								setActiveProcess(-1);
							}
						} else {
							// else set as -1
							setActiveStage(-1);
							setActiveProcess(-1);
						}
						return newState;
					})
				);
				break;
			case 'process':
				setState((prevState) => {
					const newState = cloneDeep(prevState);
					const stages = newState.fields.stages;
					const processes = stages.items[activeStage].processes;
					const processItems =
						processes.items[activeProcess].customizations.items?.[1]
							?.skus;

					// Check for items with skuType 'INTERMEDIATE'
					let intermediateSkuIds = [];
					Object.values(processItems).forEach((item) => {
						if (item.skuType === 'INTERMEDIATE') {
							intermediateSkuIds.push(item.skuId);
						}
					});

					// Delete the process
					delete processes.items[activeProcess];
					processes.order = processes.order.filter(
						(process) => process !== activeProcess
					);

					let stagesToCheck = new Set();
					// Find the stage and subprocesses for each INTERMEDIATE skuId
					intermediateSkuIds.forEach((skuId) => {
						const stageWithIntermediateProduct = Object.entries(
							stages.items
						).find(
							([stageKey, stageValue]) =>
								stageValue?.stageProduct?.id === skuId
						);

						if (stageWithIntermediateProduct) {
							const subProcesses = findAllSubProcess2(
								stages.items,
								stageWithIntermediateProduct?.[0]
							);
							subProcesses?.push(
								stageWithIntermediateProduct?.[0]
							);

							const multiForStages = [];
							const deletedStageKey = [];
							subProcesses.forEach((subProcessKey) => {
								const stage = stages.items[subProcessKey];
								if (stage?.forStage?.length === 1) {
									delete stages.items[subProcessKey];
									stages?.order?.pop();
									deletedStageKey.push(Number(subProcessKey));
									stagesToCheck.add(
										stageWithIntermediateProduct[0]
									);
								} else {
									multiForStages.push(subProcessKey);
								}
							});
							if (
								multiForStages.length ||
								deletedStageKey.length
							) {
								multiForStages.forEach((eleKey) => {
									const eleValue =
										newState.fields.stages.items[eleKey];
									if (
										eleValue?.forStage?.includes(
											Number(activeStage)
										)
									) {
										deletedStageKey?.push(
											Number(activeStage)
										);
									}
									newState.fields.stages.items[eleKey] = {
										...eleValue,
										forStage: eleValue?.forStage?.filter(
											(stageKey) => {
												return !deletedStageKey?.includes(
													stageKey
												);
											}
										),
									};
									if (
										!newState.fields.stages.items[eleKey]
											?.forStage?.length
									) {
										delete newState.fields.stages.items[
											eleKey
										];
										newState.fields.stages?.order?.pop();
									}
								});
							}
						}
					});

					// Function to remove stale stages
					const removeStaleStages = () => {
						let staleFound = false;
						stagesToCheck.forEach((stageKey) => {
							const stage = stages.items[stageKey];
							if (!stage) return;

							const validForStages =
								stage.forStage?.filter(
									(forStageKey) => stages.items[forStageKey]
								) || [];

							if (
								validForStages.length !== stage.forStage.length
							) {
								staleFound = true;
							}

							stage.forStage = validForStages;

							if (!validForStages.length) {
								delete stages.items[stageKey];
								stages.order = stages.order.filter(
									(orderKey) => orderKey !== stageKey
								);
							}
						});

						return staleFound;
					};

					// Check and remove stale stages iteratively
					let staleStagesExist;
					do {
						staleStagesExist = removeStaleStages();
					} while (staleStagesExist);

					// Set the active process
					if (processes.order.length > 0) {
						setActiveProcess(processes.order[0]);
					} else {
						setActiveProcess(-1);
					}

					return newState;
				});
				break;
			case 'sku':
				setState((prevState) => {
					const newState = cloneDeep(prevState);
					const stages = newState.fields.stages;
					const sku =
						stages.items?.[activeStage]?.processes?.items?.[
							activeProcess
						]?.customizations?.items?.[1]?.skus?.[dataset.id];

					const stageWithIntermediateProduct = Object.entries(
						stages.items
					).find(
						([stageKey, stageValue]) =>
							stageValue?.stageProduct?.id === sku?.skuId
					);

					if (stageWithIntermediateProduct) {
						const subProcesses = findAllSubProcess(
							stages.items,
							stageWithIntermediateProduct?.[0]
						);
						subProcesses?.push(stageWithIntermediateProduct?.[0]);
						
						const multiForStages = [];
						const deletedStageKey = [];
						subProcesses.forEach((subProcessKey) => {
							const stage = stages.items[subProcessKey];
							if (stage?.forStage?.length === 1) {
								delete stages.items[subProcessKey];
								stages?.order?.pop();
								deletedStageKey.push(Number(subProcessKey));
							} else {
								multiForStages.push(subProcessKey);
							}
						});
						if (multiForStages.length || deletedStageKey.length) {
							multiForStages.forEach((eleKey) => {
								const eleValue =
									newState.fields.stages.items[eleKey];
								if (
									eleValue?.forStage?.includes(
										Number(activeStage)
									)
								) {
									deletedStageKey?.push(Number(activeStage));
								}
								newState.fields.stages.items[eleKey] = {
									...eleValue,
									forStage: eleValue?.forStage?.filter(
										(stageKey) => {
											return !deletedStageKey?.includes(
												stageKey
											);
										}
									),
								};
								if (
									!newState.fields.stages.items[eleKey]
										?.forStage?.length
								) {
									delete newState.fields.stages.items[eleKey];
									newState.fields.stages?.order?.pop();
								}
							});
						}
					}
					stages.items?.[activeStage]?.processes?.items?.[
						activeProcess
					]?.customizations?.items?.[1]?.skus?.splice(dataset.id, 1);
					return newState;
				})
				// setState(
				// 	update(
				// 		cloneDeep(state),
				// 		`fields.stages.items[${activeStage}].processes.items[${activeProcess}].customizations.items[1].skus`,
				// 		(skusArr) => {
				// 			const newState = skusArr;
				// 			console.log('dataset.id', dataset.id);
				// 			newState.splice(dataset.id, 1);
				// 			return newState;
				// 		}
				// 	)
				// );
				break;
			default:
				break;
		}
	};


	const handleAlertConfirm = () => {
		// const isValid = cookingRecipeConfig.validator(state);
		// const keys = Object.keys(state.fields.stages.items);
		// const stagesState = Object.values(state.fields.stages.items)?.filter(
		// 	(stageValue) => {
		// 		return stageValue?.processes?.order?.length && !isEmpty(stageValue?.recipeData?.batchSize ?? {}) && stageValue?.station;
		// 	}
		// );
		const req = {};
		req.data = cookingConfig.deserializer(cookingState).fields;
		req.params = {
			id,
		};
		setNumberOfSubmissions(numberOfSubmissions + 1);
		saveProductConfig({
			entity: 'COOKING_PRODUCT_CONFIG',
			req,
			method: 'UPDATE',
		});
		const stagesState = Object.values(state.fields.stages.items);
		// keys.splice(0, 1);
		const isStagesValid = stagesState.every((stageValue) => {
			const reqStageState = {
				fields: {
					stages: {
						items: {
							1: stageValue,
						},
					},
					...stageValue.recipeData,
				},
			};
			const isStageStateValid =
				cookingRecipeConfig.validator(reqStageState);
			return isStageStateValid;
		});
		const reqArr = stagesState.map((stageValue) => {
			const reqStageState = {
				fields: {
					stages: {
						items: {
							1: stageValue,
						},
					},
					...stageValue.recipeData,
				},
			};
			return {
				data: cookingRecipeConfig.deserializer(reqStageState),
				params: {
					productId: reqStageState.fields.product.id,
				},
			};
		});
		// const req = {};
		if (isStagesValid) {
			setNumberOfSubmissions(numberOfSubmissions + 1);
			// req.data = cookingRecipeConfig.deserializer(state);
			// req.params = {
			// 	productId: fields.product.id,
			// };
			const callArr = reqArr?.map((ele) => {
				return cookingRecipesServices.gateway({
					req: ele,
					method: 'UPDATE',
				});
			}); 
			Promise.all(callArr).then((values) => {
				notificationServices.generateNotification({
					type: 'success',
					message: 'Recipe Successfully saved'
				});
				history.push(`${urls.EXTENSION}${urls.COOKING_RECIPE}`);
			});
		}
	};

	const handleSave = (e) => {
		e.preventDefault();
		toggleAlert(true, {
			heading: 'Are you Sure?',
			handleConfirm: () => {
				handleAlertConfirm();
				toggleAlert(false);
			},
			colorReverse: false,
			cancelText: 'Cancel',
			confirmText: 'Save',
			message: 'Changes in Recipe of Intermediate proudct will update the recipe of All the products using this intermediate.',
		});
	};

	const handleCancel = () => {
		if (isEdit) {
			history.push(`${urls.EXTENSION}${urls.COOKING_RECIPE}/view/${id}`);
		} else {
			history.push(`${urls.EXTENSION}${urls.COOKING_RECIPE}`);
		}
	};

	const productVariantsGroups = useMemo(() => {
		const selectedSkus =
			fields.stages.items[activeStage]?.processes.items[activeProcess]
				?.customizations.items[1].skus ?? [];
		return {
			1: {
				choices: selectedSkus.map((skuObj) => {
					return {
						productId: skuObj.skuId,
						variantId: skuObj.variantId,
					};
				}),
			},
		};
	}, [activeStage, activeProcess, fields.stages.items]);

	return (
		<div className={`${isEdit ? styles.edit : styles.create} root`}>
			<ProductVariantsModal
				groups={productVariantsGroups}
				handleConfirm={addSKUs}
			/>
			<AlertModal />
			<ExportProcessModal handleConfirm={exportProcess} />
			<form onSubmit={handleSave}>
				<CreatePageHeader
					heading='Edit Recipe'
					handleSave={handleSave}
					handleCancel={handleCancel}
					breadcrumbs={[
						...breadcrumbs,
						{
							link: '',
							name: isEdit
								? `Edit ${fields.product.productName}`
								: 'Create new',
						},
					]}
					showBreadcrumbs
				/>
				{/* product details */}
				<FormSectionHeader
					tooltip='Description for the section'
					sectionName='Product Details'
				>
					<GeneralDetails fields={fields} />
				</FormSectionHeader>
				{/* cooking config */}
				<FormSectionHeader
					tooltip='Description for the section'
					sectionName='Cooking Config'
				>
					<ProductCookingConfig
						fields={cookingState?.fields}
						handleInput={handleCookingInput}
						mandatoryFeilds={cookingState?.mandatoryFeilds}
					/>
				</FormSectionHeader>
				{/* recipe details */}
				<FormSectionHeader sectionName='Recipe Details'>
					{/* <RecipeDetails
						fields={fields}
						mandatoryFields={cookingRecipeConfig.mandatoryFields}
						errors={errors}
						handleInput={handleInput}
						handleBatchSize={handleBatchSize}
					/> */}
					<div className='grid grid-cols-4'>
						<div className='col-span-1'>
							<CookingProcessesTabs
								processes={fields.stages.items}
								activeStage={activeStage}
								setActiveStage={setActiveStage}
							/>
						</div>
						<div
							className='border-2 border-black p-3 w-full rounded-xl col-span-3'
							style={{
								background: `${
									activeStageValue?.process?.color ??
									'#132a13'
								}20`,
								borderColor:
									activeStageValue?.process?.color ??
									'#132a13',
							}}
						>
							<RecipeStage
								handleInput={handleInput}
								handleDelete={handleDelete}
								activeStage={activeStage}
								stageDetails={
									fields.stages.items[activeStage] ?? {}
								}
								mandatoryFields={
									cookingRecipeConfig.mandatoryFields
								}
								errors={errors}
								activeProcess={activeProcess}
								setActiveProcess={setActiveProcess}
								handleProcessReOrdering={
									handleProcessReOrdering
								}
								addNewProcess={addNewProcess}
								stages={fields.stages}
								addDependencies={addDependencies}
								removeDependency={removeDependency}
								defaultVariant={fields.product.defaultVariant}
								allVariants={fields.product.variants}
								handleRecipeDetailsInput={
									handleRecipeDetailsInput
								}
								handleBatchSize={handleBatchSize}
								// isDisabled={
								// 	fields.stages.items[activeStage]?.isIntermediate
								// }
							/>
						</div>
					</div>
				</FormSectionHeader>
			</form>
		</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.RECIPES.data.VIEW;
	const skusModalObj = state.modal.skus;
	const productsMap = state.product.products;
	const processTypes = state?.form?.PROCESS_TYPES?.data?.LIST;
	const recipeData=state.form.COOKING_PRODUCT_CONFIG.data.VIEW;
	return {
		data,
		skusModalObj,
		productsMap,
		processTypes,
		recipeData,
	};
};

const mapDispatchToProps = {
	fetchCookingStations: formActions.gateway,
	fetchRecipe: formActions.gateway,
	saveRecipe: formActions.gateway,
	fetchProcessTypesList: formActions.gateway,
	toggleAlert: modalActions.toggleAlert,
	saveProductConfig: formActions.gateway,
	fetchProductRecipe: formActions.gateway,
};

CookingRecipeCreatePage.defaultProps = {
	isEdit: false,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CookingRecipeCreatePage);
