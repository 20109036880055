/* eslint-disable */
import { generateCheckedProps } from '../../../../Helpers';
import styles from './VariantCheckboxes.module.scss';

const VariantCheckboxes = (props) => {
	const { 
		items, 
		fieldName, 
		dataAttrs, 
		isDisabled, 
		errorMessage = '',
		display = false,
	 } = props;

	const handleInput = (e) => {
		if (!isDisabled) {
			props.handleInput(e);
		}
	};

	return (
		<div className={`${styles.root} ${isDisabled ? styles.disabled : ''} ${display ? 'items-start flex flex-col gap-2' : ''}`}>
			{errorMessage?.length > 0 && isDisabled && <span className={styles['error-message']}>{errorMessage}</span>}
			{items.map((item, idx) => {
				const randomKey = `${
					item.name
				}-checkbox-${idx}-${Math.random()}`;
				const isSelected = item.isApplied;
				return (
					<div
						key={randomKey}
						className={`${styles['checkbox-root']} ${
							isSelected ? styles.selected : ''
						} ${display ? 'flex flex-col gap-2' : ''}`}
					>
						<label
							className={styles['checkbox-label']}
							htmlFor={randomKey}
						>
							{item.name}
						</label>
						<input
							className={styles['checkbox-input']}
							id={randomKey}
							type='checkbox'
							name={fieldName}
							value={item.name}
							onChange={handleInput}
							{...dataAttrs}
							data-id={idx}
							{...generateCheckedProps(isSelected)}
						/>
					</div>
				);
			})}
		</div>
	);
};

VariantCheckboxes.defaultProps = {
	dataAttrs: {},
};

export default VariantCheckboxes;
