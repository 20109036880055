import { useEffect } from 'react';
import { connect } from 'react-redux';
import { brandActions } from '../../../../Actions';
import { generateRequiredProps } from '../../../../Helpers';
import styles from './GeneralDetails.module.scss';
import { VariantCheckboxes } from '../../Inputs/VariantCheckboxes';

const GeneralDetails = (props) => {
	const {
		mandatoryFields,
		fields,
		errors,
		handleInput,
		generateDefaultOption,
		brands,
		isEdit,
		unitExist,
	} = props;
	useEffect(() => {
		props.fetchBrands({
			id: '',
			allData: true,
		});
	}, []);

	return (
		<div className={`${styles.root}`}>
			<div
				style={{
					flexBasis: '50%',
				}}
			>
				{/* name */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='productCategoryName'
						data-mandatory={mandatoryFields.name}
					>
						Name
					</label>
					<input
						className='form-value'
						type='text'
						id='productCategoryName'
						name='name'
						onInput={handleInput}
						value={fields.name}
						{...generateRequiredProps(mandatoryFields.name)}
					/>
					<span className='form-error'>{errors.name}</span>
				</div>
				{/* description */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='description'
						data-mandatory={mandatoryFields.description}
					>
						Description
					</label>
					<textarea
						className='form-value'
						type='text'
						id='description'
						name='description'
						onInput={handleInput}
						value={fields.description}
						{...generateRequiredProps(mandatoryFields.description)}
					/>
					<span className='form-error'>{errors.description}</span>
				</div>
				{/* brand name */}
				<div className='form-field row'>
					<label
						className='form-label'
						htmlFor='brand'
						data-mandatory={mandatoryFields.brand}
					>
						Brand
					</label>
					<select
						className='form-value'
						id='brand'
						name='brand'
						onChange={handleInput}
						value={fields.brand}
						{...generateRequiredProps(mandatoryFields.brand)}
					>
						{generateDefaultOption(
							'brand',
							fields.brand,
							fields.category
						)}
						{Object.keys(brands).map((brandId) => {
							const brand = brands[brandId];
							return (
								<option value={brandId}>{brand.name}</option>
							);
						})}
						{/* <option value='1'>Default</option> */}
					</select>
					<span className='form-error'>{errors.brand}</span>
				</div>
				<div
					className='form-field row'
					style={{
						marginTop: '3rem',
					}}
				>
					<label
						className='form-label'
						data-mandatory={mandatoryFields.unit}
					>
						Unit
					</label>
					<VariantCheckboxes
						items={fields.unit}
						fieldName='unit'
						isDisabled={isEdit && unitExist}
						handleInput={handleInput}
					/>
				</div>
			</div>
		</div>
	);
};

GeneralDetails.defaultProps = {
	isDisabled: false,
	mandatoryFields: {},
	errors: {},
	handleInput: () => {},
	generateDefaultOption: () => {},
	generateRequiredProps: () => {
		return {};
	},
};
const mapStateToProps = (state) => {
	const { brands } = state.brand;
	return {
		brands,
	};
};

const mapDispatchToProps = {
	fetchBrands: brandActions.fetchBrands,
};
export default connect(mapStateToProps, mapDispatchToProps)(GeneralDetails);
