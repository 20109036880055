import { lazy } from 'react';
import { SubTabsConfig } from './TabConfig';

const componentMap = {
	orderQty: lazy(() => import('./Qty')),
	fullfilmentPercentage: lazy(() => import('./Fullfilment')),
	containerCount: lazy(() => import('./CambroCount')),
	containerUtilization: lazy(() => import('./CambroUtilization')),
	fillTime: lazy(() => import('./FillTime')),
};

export const subTabs = SubTabsConfig.map((subTab) => ({
	...subTab,
	comp: (props) => {
		const Component = componentMap[subTab.value];
		return <Component {...props} />;
	},
}));
