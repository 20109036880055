import { connect } from 'react-redux';
import { useState } from 'react';
import { modalActions } from '@/Actions';
import ImagesFinder from '@/Components/ImagesFinder/ImagesFinder';
import wastageAnalyticsServices from '@/Services/Analytics Services/wastage-analytics.services';
import { ListTable } from '@/Components/ListTable';
import { AddWastageReasonModal } from '@/Components/Modals/AddWastageReasonModal';

const FIND_FILTERS = [];

const COLMAP = {
	ID: 'id',
	'Store Name': 'storeName',
	'Store Type': 'storeType',
	'Store Sub Type': 'storeSubType',
	'Wastage Reason': 'reason',
};

const TABLE_COLUMNS = [
	{
		title: 'ID',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Store Type',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Store Sub Type',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Wastage Reason',
		config: {
			type: 'DATA',
		},
	},
];

const ACTION_COLUMNS = [
	{
		title: 'Delete',
		config: {
			type: 'delete',
		},
	},
];

const WastageReasons = (props) => {
	const { toggleAddWastageReasonModal } = props;

	const [wastageReasonsList, setWastageReasonsList] = useState([]);
	const [update, setUpdate] = useState(-1);

	const handleAddClick = () => {
		toggleAddWastageReasonModal(true, {
			onSuccessCallback: () => {
				setUpdate(true);
			},
		});
	};

	const handleListFetch = async (filters) => {
		try {
			const response =
				await wastageAnalyticsServices.fetchWastageReasonsList({
					req: {
						params: {
							storeId: filters?.store,
						},
					},
				});
			if (response?.statusCode === 200) {
				setWastageReasonsList(response?.data);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log('error', error);
		}
	};

	const handleDelete = async (reason) => {
		try {
			const response = await wastageAnalyticsServices.deleteWastageReason(
				{
					req: {
						params: {
							reasonId: reason?.id,
						},
					},
				}
			);
			if (response?.statusCode === 200) {
				handleListFetch();
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log('error', error);
		}
	};

	return (
		<div>
			<AddWastageReasonModal />
			<div className='p-2'>
				<ImagesFinder
					pageLabel='Wastage Reasons'
					FILTERS={FIND_FILTERS}
					onSubmit={handleListFetch}
					update={update}
					additionInfo={{
						showAdd: true,
						// showAdd: false,
						addText: 'Reason',
						handleAddClick,
					}}
				/>
			</div>
			<ListTable
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				data={wastageReasonsList}
				actions={[...ACTION_COLUMNS]}
				special={{}}
				handleDelete={handleDelete}
			/>
		</div>
	);
};

const mapStateToProps = () => {};
const mapDispatchToProps = {
	toggleAddWastageReasonModal: modalActions.toggleAddWastageReasonModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(WastageReasons);
