const urls = {
	EXTENSION: '/console2',

	HOMEPAGE: '/homepage',
	NEW_DASHBOARD: '/dashboard',
	DASHBOARD: '/ops-dashboard',
	ASSET: '/asset',
	ASSET_CATEGORY: '/asset/asset-category',
	ASSET_MONITORING: '/asset/asset-monitoring',
	ASSET_IMMOVEABLE: '/asset/asset-immovable',
	ASSET_DASHBOARD: '/asset-dashboard',
	ASSET_LISTING: '/asset/listing',
	REPORTS: '/inventory-reports',
	// ======================
	PROCESS_FLOW: '/process-flow',
	PRODUCT_PROCESS_MAP: '/product-process-map',
	PROCESS_CONFIG: '/process-config',
	// ======================
	AI_DASHBOARD: '/ai-dashboard',
	MY_ACCOUNT: '/account',
	BRANDING: '/account/branding',
	PAYMENT_SETTLEMENTS: '/account/payment-settlements',
	PROMO_CODES: '/account/promo-codes',
	ACCOUNT_SETTINGS: '/account/settings',
	// USER
	USERS: '/account/user',
	USERS_PERMISSIONS: '/account/access/user',

	FINANCIAL: '/financial',

	PETTY_CASH: '/financial/petty',
	UTILITIES_PAGE: '/financial/utilities',
	// Gst
	GST: '/gst',
	GST_DETAILS: '/gst/gstDetails',
	// REVENUE
	REVENUE_PAGE: '/account/revenue',

	MY_MACHINES: '/account/my-machines',
	MY_LICENSES: '/account/my-licenses',
	WASTAGE_REASONS: '/account/wastage/reasons',
	MODULES_CONFIGURATION: '/account/modules/confriguration',
	MY_AUDITS: '/account/my-audits',
	MY_CONTAINERS: '/account/my-containers',
	ADD_CREDITS: '/account/add-credits',
	CHILD_LIST: '/account/child',
	ADD_CHILD: '/account/child/add-child',
	REPORTS_CONFIG: '/account/reports',

	AI_WORKFLOW: '/ai-workflow',
	AI_ACCURACIES: '/ai-workflow/accuracies',
	AI_MODEL_CREATION: '/ai-workflow/ai-model',
	AI_STATS: '/ai-workflow/ai-stats',
	AI_MODEL_HISTORY: '/ai-workflow/model/history',

	REFUNDS: '/refunds',
	REFUNDS_HISTORY: '/refunds/history',
	APPROVE_REFUNDS: '/refunds/approve',
	REQUEST_REFUNDS: '/refunds/request',

	MY_OUTLETS: '/outlets',
	STORE_SETTINGS: '/outlets/settings',
	STORES: '/outlets/stores',
	RIDERS: '/outlets/riders',
	PRINTER_CONFIG: '/outlets/printer-config',
	LIVE_VIEW: '/outlets/live',
	HOLIDAY: '/outlets/holiday',

	BRANDS: '/brands',

	FOOD_PRODUCTS: '/products',
	ACTIVE_PRODUCTS: '/products/active',
	ALL_PRODUCTS: '/products/all',
	CUSTOMIZATIONS: '/products/customizations',
	VARIANTS: '/products/variants',
	VARIANTS_THRESHOLD: '/products/threshold/variants',
	COMBOS: '/products/combos',
	NUTRITIONAL_INFO: '/products/nutritional-info',
	BULK_ORDERING: '/products/bulk',
	SUBSCRIPTION: '/products/subscription',
	PRODUCT_CATEGORY: '/products/category',
	PRODUCT_SUB_CATEGORY: '/products/sub/category',
	PRODUCT_SPECIFICATION_PARAMETRS: '/products/specification/parameters',

	MENUS: '/menus',
	ACTIVE_MENUS: '/menus/active',
	ALL_MENUS: '/menus/all',
	MASTER_MENUS: '/menus/master',
	BULK_MENUS: '/menus/bulk',
	SUBSCRIPTION_MENUS: '/menus/subscription',
	SPECIAL_MENUS: '/menus/special',

	KITCHEN_MANAGEMENT_SYSTEM: '/kitchen',
	QUALITY: '/kitchen/quality',
	QMS_ANNOTATION: '/kitchen/quality/annotation',
	QUALITY_SETTING: '/kitchen/quality/setting',
	PRODUCT_QUALITY_CONFIG: '/kitchen/quality/config',
	LIVE_QC: '/kitchen/quality/live',
	PAST_QC: '/kitchen/quality/past',
	LIVE_IMS: '/kitchen/inventory/live',
	PAST_IMS: '/kitchen/inventory/past',
	INVENTORY: '/kitchen/inventory',
	IMS_ANNOTATION: '/kitchen/inventory/annotation',
	INVENTORY_SETTING: '/kitchen/inventory/setting',
	INVENTORY_SKU: '/kitchen/inventory/inventorysku',
	INVENTORY_CONFIG: '/kitchen/inventory/config',
	INVENTORY_CATEGORY: '/kitchen/inventory/category',

	// COOKING
	COOKING: '/kitchen/cooking',
	COOKING_SETTING: '/kitchen/cooking/setting',
	COOKING_PRODUCT_CONFIG: '/kitchen/cooking/config',
	COOKING_RECIPE: '/kitchen/cooking/recipe',
	COOKING_STATIONS: '/kitchen/cooking/stations',
	DEFECTS: '/kitchen/features',

	FINANCE_MANAGEMENT: '/finance-management',
	FINANCE_MANAGEMENT_MENU: '/finance/management',

	ORDER_OPERATIONS: '/order/operations',

	ANALYTICS: '/analytics',
	STORE_ANALYSIS: '/analytics/store-analysis',
	QUALITY_ANALYSIS_CONTAINER: '/analytics/quality',
	QUALITY_ANALYSIS: '/analytics/quality/analysis',
	QUALITY_ANALYSIS_LIST: '/analytics/quality/analysis/list',
	COOKING_ANALYSIS: '/analytics/cooking/analysis',
	ORDER_HISTORY: '/analytics/order/history',
	ORDER_HISTORY_LIST: '/analytics/order/history/list',

	// ==============
	// =================== ANALYSIS DASHBOARD PAGE LISTING ==========================
	COGS_ANALYSIS_LIST: '/analytics/cogs/analysis/list',
	COGS_HISTORY_LIST: '/analytics/cogs/history/list',

	BOM_ANALYSIS_LIST: '/analytics/bom/analysis/list',
	BOM_HISTORY_LIST: '/analytics/bom/history/list',

	INV_ANALYSIS_LIST: '/analytics/stock/analysis/list',
	INV_HISTORY_LIST: '/analytics/stock/history/list',

	TRANSIT_ANALYTICS: '/analytics/transit',
	TRANSIT_ANALYSIS: '/analytics/transit/summary',
	TRANSIT_ANALYSIS_LIST: '/analytics/transit/analysis/list',
	TRANSIT_HISTORY_LIST: '/analytics/transit/history/list',

	AUDIT_ANALYSIS_LIST: '/analytics/audit/analysis/list',
	AUDIT_HISTORY_LIST: '/analytics/audit/history/list',

	FORECASTING_ANALYTICS: '/analytics/forecasting',
	FORECASTING_ANALYSIS: '/analytics/forecasting/summary',
	FORECASTING_ANALYSIS_LIST: '/analytics/forecasting/analysis/list',
	FORECASTING_HISTORY_LIST: '/analytics/forecasting/history/list',

	// =================== ANALYSIS DASHBOARD PAGE LISTING ==========================

	// =================== subscriptions
	MY_SUBSCRIPTIONS: '/analytics/my-subscriptions',
	MY_SUBSCRIPTIONS_HISTORY: '/analytics/my-subscriptions/history',
	/// ================================
	// =================== production
	AI_PRODUCTION: '/analytics/production',
	AI_PRODUCTION_HISTORY: '/analytics/production/history',
	PRODUCT_IMAGES: '/analytics/production/images',
	// =================== outwarding
	AI_OUTWARDING: '/analytics/ai/outwarding',
	AI_OUTWARDING_HISTORY: '/analytics/ai/outwarding/history',
	AI_OUTWARDING_IMAGES: '/analytics/ai/outwarding/images',
	/// ================================
	// =================== grn
	AI_GRN: '/analytics/ai/grn',
	AI_GRN_HISTORY: '/analytics/ai/grn/history',
	AI_GRN_IMAGES: '/analytics/ai/grn/images',
	/// ================================
	// =================== grn
	AI_DISPATCH: '/analytics/ai/dispatch',
	AI_DISPATCH_HISTORY: '/analytics/ai/dispatch/history',
	AI_DISPATCH_IMAGES: '/analytics/ai/dispatch/images',
	/// ================================
	// =================== Container
	CONTAINER_IMAGES: '/analytics/container/images',
	// =================================
	// =================== Temp Annotation Images Page
	TEMPERATURE_DIGIT_IMAGES: '/analytics/temperature/digit/images',
	// =================================
	COOKING_ANALYSIS_LIST: '/analytics/cooking/analysis/list',
	WORK_ORDER_LIST: '/analytics/cooking/analysis/wo/list',
	INVENTORY_ANALYSIS: '/analytics/inventory/analysis',
	INVENTORY_ANALYSIS_LIST: '/analytics/inventory/analysis/list',
	UPLOAD_PO: '/upload/template',

	IMAGES_FINDER: '/images/find',

	TEMPERATURE_IMAGES: '/analytics/temperature/images',

	CREATE_PURCHASE_ORDER: '/analytics/po/create',
	CREATE_EWAY_BILL: '/analytics/po/EWayBill/generate',
	// SALES ORDER TAB
	SALES_ORDERS: '/analytics/sales',
	INVOICE_LIST: '/analytics/po/invoice',
	CREDIT_NOTE: '/analytics/po/credit/note',
	SALES_RETURN: '/analytics/po/sales/return',
	DELIVERY_CHALLAN: '/analytics/po/delivery/challan',
	PAYMENT_RECEIVED: '/analytics/po/payment/received',

	// SALES ORDER TAB
	EXTERNAL_SALES_ORDERS: '/analytics/external',
	EXTERNAL_SALES_ORDERS_LIST: '/analytics/inventory/sales/list',
	EXTERNAL_SALES_ORDERS_BILL_LIST: '/analytics/po/sales/bill/list',
	EXTERNAL_SALES_ORDERS_PAYMENTS: '/analytics/po/sales/PAYMENT',

	// PURCHASE ORDER TAB
	PURCHASE_ORDERS: '/analytics/po',
	PURCHASE_ORDERS_HISTORY: '/analytics/po/history',
	COGS_ANALYTICS: '/analytics/cogs',
	COGS_ANALYSIS: '/analytics/cogs/summary',
	BOM_ANALYTICS: '/analytics/bom',
	BOM_ANALYSIS: '/analytics/bom/summary',
	AUDIT_ANALYTICS: '/analytics/audit',
	AUDIT_ANALYSIS: '/analytics/audit/summary',

	// ================================
	COMMON_ANALYTICS: '/analytics/common',
	COMMON_ANALYSIS: '/analytics/common/summary',
	COMMON_HISTORY_LIST: '/analytics/common/history/list',
	// +===============================

	// INVENTORY
	INV_ANALYTICS: '/analytics/stock',
	INV_ANALYSIS: '/analytics/stock/summary',

	BILLS: '/analytics/po/bill',
	BILLS_SUMMARY: '/analytics/po/vendor/bills/summary',
	CREDITS: '/analytics/po/credits',
	PAYMENT_MADE: '/analytics/po/payment/made',

	// INTERNAL PURCHASE ORDER TAB
	INTERNAL_PURCHASE_ORDERS: '/analytics/internal/po',
	INTERNAL_PURCHASE_ORDERS_LIST: '/analytics/internal/po/list',
	INTERNAL_PURCHASE_ORDERS_BILLS: '/analytics/po/internal/bill',

	PO_LIST: '/analytics/inventory/list/po',
	VENDOR_ORDER_LIST: '/analytics/list/vendor/orders',
	CAPEX_ORDER_LIST: '/analytics/list/capex/vendor/orders',
	INTERNAL_STORE_ASSET_ORDERS: '/analytics/list/asset/store',
	GRN_LIST: '/analytics/list/grn',
	FMS_ORDERS_LIST: '/analytics/list/fms',
	QUOTE_LIST: '/analytics/list/quote',
	QUOTE_CREATE: '/analytics/create/quote',
	VENDOR_QUOTE: '/analytics/vendor/quote',
	VENDOR_RESPONSE_QUOTE: '/analytics/vendor/response/quote',
	BUYER_ORDER_LIST_PAGE: '/analytics/buyers',
	BUYER_ORDER_LIST: '/analytics/list/buyer/orders',

	FMS_ORDERS: '/analytics/fms/orders',

	INDENT_LIST: '/analytics/inventory/list/indent',
	STOCK_CONSUMPTION: '/analytics/inventory/consumption/stock',

	NUTRITION_ANALYSIS: '/analytics/quality/nutrition',
	NUTRITION_ANALYSIS_LIST: '/analytics/quality/nutrition/list',
	QC_HISTORY_LIST: '/analytics/quality/history/list',
	// INVENTORY_ANALYSIS: '/analytics/inventory-analysis',
	SKUS_INVENTORY_STOCK: '/analytics/inventory/sku-report',
	PROCESS_INVENTORY_STOCK: '/analytics/inventory/process-report',
	INVENTORY_ANALYTICS: '/analytics/inventory',
	STOCK_IN_REPORT: '/analytics/inventory/stock',
	STOCK_OUT_REPORT: '/analytics/inventory/stock-out',
	IMS_SUMMARY: '/analytics/inventory/summary',
	MY_CUSTOMERS: '/analytics/my-customers',
	ADD_CUSTOMERS: '/analytics/my-franchisee',
	DEFECT_ANALYTICS: '/analytics/feature',
	// PRODUCT_ANALYTICS: '/analytics/product',
	APP_OUTWARDING_ANALYTICS: '/analytics/app/outwarding',
	DEFECT_IMAGES: '/analytics/feature/images',
	APP_OUTWARDING_IMAGES: '/analytics/app/outwarding/images',
	COOKING_IMAGES: '/analytics/cooking/images',

	// WASTAGE =======================>
	WASTAGE_HISTORY: 'analytics/wastage',
	WASTAGE_CONTAINER: '/analytics/wastage',
	WASTAGE_IMAGES: '/analytics/wastage/images',
	WASTAGE_COMPONENTS: '/analytics/wastage/components',
	WASTAGE_HISTORY_LIST: '/analytics/wastage/history/list',

	// =================>
	BRAND_TRENDS: '/analytics/brand-trends',

	// TEMP ANNOTATION =======
	TEMP_ANNOTATION: '/temp/annotation',

	// VENDORS
	VENDORS_CONTAINER: '/vendors',
	VENDORS: '/vendors',
	VENDOR_PRODUCT_MAPPING: '/vendor/product/mapping',
	VENDOR_OPENING: '/vendor/opening',

	// Buyer
	BUYER_CONTAINER: '/buyers',
	BUYER: '/buyers',

	// test
	TEST: '/test',

	// PRODUCTION PLANE
	PRODUCTION_PLANS: '/analytics/productionPlans',

	// // PRODUCTION PLANE
	// TRANSFER_ORDERS: '/analytics/transferOrders',

	// CONFIGURATION
	CONFIGURATION: '/configuration',
	CONFIGURATION_PAGE: '/configurations/all',

	// PROCESS CLOSINGS
	LEFT_OVER_INVENTORY: '/left-over-inventory',
	PROCESS_CLOSINGS: '/left-over-inventory/process',
	CREATE_CLOSINGS: '/left-over-inventory/add',
	LEFT_OVER_INVENTORY_HISTORY: '/left-over-inventory/history',
	// order Tracking
	ORDER_TRACKNG: '/order/tracking',

	// CAPEX SERVICE
	SERVICES: '/service',

	// SIMILAR PRODUCT MAPPING
	SIMILAR_PRODUCT: '/similar-products-mapping',

	// LABEL GENERATOR
	LABEL_GENERATOR: '/label/generator',
	LABEL_GENERATOR_LIST: '/label/generator/list',
	LABEL_GENERATOR_CREATE: '/label/generator/create',

	// REGION MAPPING
	REGION_MAPPING: '/regions-mapping',

	// Vendor Contract
	VENDOR_CONTRACT_CREATE: '/vendor/contract/create',
	VENDOR_CONTRACT: '/vendor/contract',

	// Buyer Contract
	BUYER_CONTRACT_CREATE: '/buyer/contract/create',
	BUYER_CONTRACT: '/buyer/contract',

	// NOT ACCESSIBLE PAGE
	NOT_ACCESSIBLE_PAGE: '/nonAccessable',

	// Shift managment
	SHIFT_CREATE: '/shift/create',
	SHIFT: '/shift',

	ASSET_CAPACITY: '/asset/capacity',
	DAY_END_REPORT: '/dayendreport',
	// wastage

	WASTAGE: '/wastage',

	// forecasting
	FORECASTING: '/forecasting',
	// AI PACKAGING
	AI_PACKAGING: '/ai_packaging',
	// Qc logs
	QUALITY_INSPECTION: '/quality_inspection',
	QUALITY_LOGS: '/quality_logs',
	QUALITY_DETAILS: '/quality_details',
	// grn details
	GRN_DETAILS: '/grn_details',
};

export default urls;
