import {
	Add, Create, KeyboardArrowDown
} from '@mui/icons-material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';
import styles from './SidebarItem.module.scss';
import { navigationActions } from '../../../Actions';
import { urls } from '../../../Constants';

function SidebarItem(props) {
	const {
		navItem,
		handleClick,
		isExpandable,
		isExpanded,
		isActive,
		handleEditClick,
		navKey,
		handleGenerableNavClick,
	} = props;
	const dispatch = useDispatch();
	const location = useLocation();
	const renderSideBarItem = () => {
		if (navItem.type === 'NON_LINK') {
			return (
				<div
					className={`${styles['sidebar-item-root']} ${styles['non-link']}`}
				>
					<span className={styles['sidebar-item-title']}>
						{navItem.name}
					</span>
				</div>
			);
		}
		return isExpandable ? (
			<div
				className={`${styles['sidebar-item-root']} 
					${styles.expandable} 
					${isExpanded ? styles.expanded : ''}`}
				onClick={handleClick}
			>
				<span className={styles['sidebar-item-icon']}>
					{navItem.icon}
				</span>
				<div className={styles.title}>
					<span
						href={navItem.href}
						className={styles['sidebar-item-title']}
					>
						{navItem.name}
					</span>
					<div className={styles.requisites}>
						{navItem?.requisites?.map((ele) => {
							return (
								ele?.value && (
									<div className={styles['requisite-value']}>
										<p>
											{ele?.displayName}
											:
										</p>
										<Tooltip title={ele?.value?.name}>
											<p>
												{ele?.value?.name?.length > 18
													// eslint-disable-next-line prefer-template
													? ele?.value?.name?.substring(0, 18) + '...'
													: ele?.value?.name}
											</p>
										</Tooltip>
										<button
											type='button'
											className={styles['edit-btn']}
											onClick={(e) => {
												e?.stopPropagation();
												handleEditClick(navKey, navItem);
											}}
										>
											<Create className={styles.icon} />
										</button>
									</div>
								)
							);
						})}
					</div>
				</div>
				<KeyboardArrowDown className={`${styles['expand-icon']}`} />
			</div>
		) : (
			<Link
				className={`${styles['sidebar-item-root']} ${
					isActive ? styles.active : ''
				}`}
				to={{
					pathname: navItem.href,
					state: {
						prevPath: location?.pathname,
					},
				}}
			>
				<span className={styles['sidebar-item-title']}>
					{navItem.name}
				</span>
				{(navItem?.generatable) && (
					<div
						onClick={(e) => {
							const navLink = (navItem?.href ?? '')?.split(urls.EXTENSION)[1];
							const parentReq = dispatch(navigationActions?.getParentRequisites(navLink));
							const storeReq = parentReq?.find((parentEle) => {
								return parentEle?.type === 'store';
							});
							let redirectLink = navItem?.generateLink;
							if (
								navItem?.name === 'Internal Purchase Orders'
							) {
								if (storeReq?.value?.type === 'BASE') {
									redirectLink =
										navItem?.generateLink?.split('?')[0];
									redirectLink = `${redirectLink}?typeId=3&userOrderType=purchase`;
								}
								if (storeReq?.value?.id === -999) {
									redirectLink =
										navItem?.generateLink?.split('?')[0];
									redirectLink = `${redirectLink}?exclude=1&userOrderType=purchase`;
								}
							}
							if (navItem?.name === 'Store Sale Orders') {
								if (
									storeReq?.value?.type?.includes('WAREHOUSE')
								) {
									redirectLink =
										navItem?.generateLink?.split('?')[0];
									redirectLink = `${redirectLink}?userOrderType=sales&typeId=3`;
								}
								if (storeReq?.value?.id === -999) {
									redirectLink =
										navItem?.generateLink?.split('?')[0];
									redirectLink = `${redirectLink}?userOrderType=sales&typeId=2&storeTypes=BASE`;
								}
							}
							const storeQuery = storeReq?.displayName && storeReq?.value?.id !== -999
								? `&${storeReq?.displayName?.toLowerCase()}Store=${
									storeReq?.value?.id
								}`
								: '';
							e.preventDefault();
							e.stopPropagation();
							handleGenerableNavClick(
								`${redirectLink}${storeQuery}`
							);
						}}
						className={styles['add-icon']}
					>
						<Add />
					</div>
				)}
			</Link>
		);
	};
	return <>{renderSideBarItem()}</>;
}

SidebarItem.propTypes = {
	navItem: PropTypes.shape({
		name: PropTypes.string,
		href: PropTypes.string,
	}).isRequired,
	handleClick: PropTypes.func,
	isExpandable: PropTypes.bool,
	isExpanded: PropTypes.bool,
	isActive: PropTypes.bool,
};

SidebarItem.defaultProps = {
	handleClick: () => {},
	isExpandable: false,
	isExpanded: true,
	isActive: false,
};

export default SidebarItem;
