/* eslint-disable */
import { Label } from '@/Components/ui/label';
import robot from '@/Images/Robot.png';
import { Switch } from '@mui/material';
import MinMaxInput from './minMaxInput';
import { InputNumber, RangeSlider } from 'rsuite';

function QualityCheck(props) {
    const {
        qualityCheck,
        setQualityCheck,
        isDisabled = false,
        setShowError = () => {},
    } = props;
    const handleChange = (e) => {
        setShowError(true);
        setQualityCheck((prevQualityCheck) => ({
            ...prevQualityCheck,
            [e.target.name]: e.target.checked
        }));
    };

    const handleSwitchChange = (e) => {
        const {name, checked} = e.target;
        setShowError(true);
        setQualityCheck((prevQualityCheck) => ({
            ...prevQualityCheck,
            [name]: {
                ...prevQualityCheck[name],
                enabled: checked
            }
        }));
    };
    const handletempminmaxChange = (name, value) => {
        setShowError(true);
        if(name === 'minTemp'){
            value = value < -120 ? -120 : value;
        }
        if(name === 'maxTemp'){
            value = value > 120 ? 120 : value;
        }
        if(name === 'temperatureCheck'){
            const [minTemp, maxTemp] = value;
            setQualityCheck((prevQualityCheck) => ({
                ...prevQualityCheck,
                temperatureCheck: {
                    ...prevQualityCheck.temperatureCheck,
                    minTemp,
                    maxTemp
                }
            }));
        }else{
            setQualityCheck((prevQualityCheck) => ({
                ...prevQualityCheck,
                temperatureCheck: {
                ...prevQualityCheck.temperatureCheck,
                [name]: value
                }
            }));
        }
    };
    const handleDefectCheckChange = (name, value) => {
        setShowError(true);
        setQualityCheck((prevQualityCheck) => ({
            ...prevQualityCheck,
            defectCheck: {
                ...prevQualityCheck.defectCheck,
                [name]: value
            }
        }));
    };

    return (
        <div className='flex flex-col gap-2 w-[98%] p-2 m-[1rem]'>
            <div className='flex items-center gap-3'>
                <img src={robot} alt='robot' className='w-8 h-8' />
                <Label className='text-[16px]'>Quality Checks</Label>
            </div>
            <div className='flex items-center gap-4 w-full'>
                <div className='w-[33%] h-[200px]  bg-white rounded-[8px] border border-[#00000066] p-3'>
                    <div className='w-full flex items-center justify-between mb-4'>
                        <p className='text-lg text-black'>Weight Check</p>
                        <Switch 
                            disabled={isDisabled}
                            size="small" 
                            sx={{
							'& .MuiSwitch-switchBase.Mui-checked': {
								color: '#000000',
							},
							'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
								backgroundColor: '#000000',
							}
						    }}
                            checked={qualityCheck?.weightCheck}
                            onChange={handleChange}
                            name='weightCheck'
                        />
                    </div>
                    <div className='flex items-center gap-2'>
                        <p className='text-gray-500'>Weight based configuration can be done on variant level. 
                            (GRN will be done through AI supervisor if this check is enabled)</p>
                    </div>
                </div>
                <div className='w-[33%] h-[200px]  bg-white rounded-[8px] border border-[#00000066] p-3'>
                    <div className='w-full flex  justify-between mb-4'>
                        <p className='text-lg text-black'>Temperature Check</p>
                        <Switch 
                            disabled={isDisabled}
                            size="small" 
                            sx={{
							    '& .MuiSwitch-switchBase.Mui-checked': {
								color: '#000000',
							    },
							    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
								backgroundColor: '#000000',
							    }
						}}
                        checked={qualityCheck?.temperatureCheck?.enabled}
                        onChange={handleSwitchChange}
                        name='temperatureCheck'
                        />
                    </div>
                    <div
                        className={` ${
                            isDisabled || qualityCheck?.temperatureCheck?.enabled ? "bg-white" : "bg-white-200 opacity-50 pointer-events-none"
                        }`}
                    >
                        <div className='flex flex-col  gap-2'>
                            <div className='flex justify-between gap-2'>
                                <Label className='text-gray-500 m-[6px] text-[12px]'>Min (-120°C)</Label>
                                <Label className='text-gray-500 m-[6px] text-[12px]'>Max (120°C)</Label>
                            </div>
                            <RangeSlider
                                progress
                                className='w-full [&_.rs-slider-progress-bar]:!bg-black 
                                    [&_.rs-slider-handle]:!bg-black 
                                    [&_.rs-slider-handle]:!border-black 
                                    [&_.rs-slider-handle]:!ring-0 
                                    [&_.rs-slider-handle]:!shadow-none'
                                value={[qualityCheck?.temperatureCheck?.minTemp, qualityCheck?.temperatureCheck?.maxTemp]}
                                onChange={(value) => handletempminmaxChange('temperatureCheck', value)}
                                min={-120}
                                max={120}
                                disabled={isDisabled}
                            />
                            <div className='flex items-center gap-2'>
                                <InputNumber
                                    min={-20}
                                    max={120}
                                    value={qualityCheck?.temperatureCheck?.minTemp}
                                    onChange={(value) => handletempminmaxChange('minTemp', value)}
                                    disabled={isDisabled}
                                />
                                <span>to</span>
                                <InputNumber
                                    min={-20}
                                    max={120}
                                    value={qualityCheck?.temperatureCheck?.maxTemp}
                                    onChange={(value) => handletempminmaxChange('maxTemp', value)}
                                    disabled={isDisabled}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='w-[33%] h-[200px] bg-white rounded-md border border-[#00000066] p-3'>
                    <div className='w-full flex  justify-between mb-4'>
                        <p className='text-lg text-black'>Visual Defect Check</p>
                        <Switch 
                            disabled={isDisabled}
                            size="small" 
                            sx={{
							'& .MuiSwitch-switchBase.Mui-checked': {
								color: '#000000',
							},
							'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
								backgroundColor: '#000000',
							}
						}}
                        checked={qualityCheck?.defectCheck?.enabled}
                        onChange={(e) => handleDefectCheckChange('enabled', e.target.checked)}
                        name='defectCheck'
                        />
                    </div>
                    <div
                    className={` ${
                         isDisabled || qualityCheck?.defectCheck?.enabled ? "bg-white" : "bg-white-200 opacity-50 pointer-events-none"
                    }`}>
                        <div className='flex flex-col gap-2'>
                            <div className='flex justify-between gap-2'>
                                <Label className='text-gray-500 m-[6px] text-[12px]'>Area Threshold</Label>
                            <MinMaxInput
                            value={qualityCheck?.defectCheck?.areaThreshold}
                            handleMinMaxChange={handleDefectCheckChange}
                            name='areaThreshold'
                            id='areaThreshold'
                            disabled={isDisabled}
                             />
                        </div>
                        <div className='flex justify-between gap-2'>
                            <Label className='text-gray-500 m-[6px] text-[12px]'>Count Threshold</Label>
                            <MinMaxInput
                            value={qualityCheck?.defectCheck?.countThreshold}
                            handleMinMaxChange={handleDefectCheckChange}
                            name='countThreshold'
                            id='countThreshold'
                            disabled={isDisabled}
                             />
                        </div>
                        <div className='flex justify-between gap-2'>
                            <Label className='text-gray-500 m-[6px] text-[12px]'>Reject If Checks Falied</Label>
                            <select 
                                disabled={isDisabled}
                                className='w-[10rem] h-[30px] text-center border border-gray-200 rounded-md'
                                value={qualityCheck?.defectCheck?.reject}
                                onChange={(e) => handleDefectCheckChange('reject', e.target.value)}
                            >
                                <option value="both">Both </option>
                                <option value="anyone">Anyone</option>
                            </select>
                        </div>
                    </div>
                    </div>
                    </div>
                </div>
        </div>
    );
  };

export default QualityCheck;
