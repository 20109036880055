/* eslint-disable*/
import { connect } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { modalActions } from '../../../Actions';
import { BaseModal } from '../BaseModal';
import { Button } from '@/Components/ui/button';
import masterMenuServices from '@/Services/ProductSpecificationParamServices/productMasterMenu.services';


const ProductParametersModal = (props) => {
    const { 
        show,
        toggleModal,
        data = {},
        selectedParameters = [],
        setSelectedParameters,
        handleOpenAddModal,
        trueFetchProductParameters,
        settrueFetchProductParameters,
    } = props;

    const [list, setList] = useState([]);
    const [listParameters, setListParameters] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
      if(show){
        setListParameters(selectedParameters);
      }
    }, [show]);

    useEffect(() => {
        if (data?.length > 0) {
            setList(data);
        }
    }, [data]);

    
	const fetchProductParameters = async() => {	
		try {
			const response = await masterMenuServices.productMasterMenuList();
			if(response?.statusCode === 200){
				setList(response?.data);
			}
		} catch (error) {
			console.log(error);
		}
		// calling api for getting the product parameters
	};
    useEffect(() => {
        if (trueFetchProductParameters) {
            fetchProductParameters();
            settrueFetchProductParameters(false);
        }
    }, [trueFetchProductParameters]);

    const handleClose = () => {
        setSelectedParameters([]);
        setSearchTerm('');
        toggleModal(false);
        settrueFetchProductParameters(false);
    };

    const handleSave = () => {
        setSelectedParameters(listParameters);
        toggleModal(false);
    };

    const handleCheckboxChange = (item) => {
        const isSelected = listParameters.some(param => param.id === item.id);
        if (isSelected) {
            setListParameters(listParameters.filter(param => param.id !== item.id));
        } else {
            setListParameters([...listParameters, item]);
        }
    };

    const filteredList = list.filter(item => 
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );


    const renderBtns = () => {
        return (
            <div className='w-full flex justify-center gap-2'>
                <Button className='flex-1' variant='secondary' onClick={handleClose}>Cancel</Button>
                <Button className='flex-1 primary-btn' onClick={handleSave}>+ Add</Button>
            </div>
        );
    };

    return (
        <BaseModal
            open={show}
            handleClose={handleClose}
            heading='Product Parameters'
            renderBtns={renderBtns}
            height='400px'
        >
            <div className='p-4'>
                <div className="w-full relative">
                        <div className="absolute left-0 right-0 mt-1 border rounded bg-white z-10">
                            <div className="p-2 border-b">
                                <div className="relative">
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        className="w-full p-2 pr-8 border rounded"
                                    />
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        className="h-5 w-5 absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400" 
                                        fill="none" 
                                        viewBox="0 0 24 24" 
                                        stroke="currentColor"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                    </svg>
                                </div>
                            </div>
                            <div className="max-h-60 overflow-y-auto">
                                {filteredList.map((item) => (
                                    <div key={item.id} className="flex gap-3 items-center p-2 hover:bg-gray-100">
                                        <input
                                            type="checkbox"
                                            id={`param-${item.id}`}
                                            checked={listParameters.some(param => param.id === item.id)}
                                            onChange={() => handleCheckboxChange(item)}
                                            className="mr-2"
                                        />
                                        <label htmlFor={`param-${item.id}`} className="text-left cursor-pointer w-full">
                                            {item.name}
                                        </label>
                                    </div>
                                ))}
                                {filteredList.length === 0 && (
                                    <div className="p-2 text-center text-gray-500">
                                        <Button className='primary-btn' onClick={handleOpenAddModal}>+ Add Quality Parameter </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                </div>
            </div>
        </BaseModal>
    );
};

const mapStateToProps = (state) => {
    const { show, data } = state.modal.productParametersModal;
    return {
        show,
        data,
    };
};

const mapDispatchToProps = {
    toggleModal: modalActions.toggleProductParametersModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductParametersModal);