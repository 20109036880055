import { endpoints } from '@/Constants';
import {
	authHeader,
	handleResponse,
	toQueryStringURL
} from '@/Helpers';

const productMasterMenuList = () => {
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(endpoints.PRODUCT_QUALITY_MASTER_MENU_LIST, requestOptions).then(handleResponse);
};

const addProductMasterMenu = (req) => {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(req),
	};
	return fetch(endpoints.PRODUCT_QUALITY_MASTER_MENU_ADD, requestOptions).then(handleResponse);
};

const updateProductMasterMenu = (req) => {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(req),
	};
	return fetch(endpoints.PRODUCT_QUALITY_MASTER_MENU_UPDATE, requestOptions).then(handleResponse);
};

const deleteProductMasterMenu = (req) => {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(req),
	};
	return fetch(endpoints.PRODUCT_QUALITY_MASTER_MENU_DELETE, requestOptions).then(handleResponse);
};

const createProduct = (req) => {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(req),
	};
	return fetch(endpoints.PRODUCT_CREATION, requestOptions).then(handleResponse);
};

const updateProduct = (req) => {
	const url = toQueryStringURL(endpoints.PRODUCT_UPDATE, req?.params);
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(req?.data),
	};
	return fetch(url, requestOptions).then(handleResponse);
};

const fetchCategoryConfiguration = (req = {}) => {
	const params = {
		...req?.params,
	};
	const url = toQueryStringURL(endpoints.FETCH_CATEGORY_CONFIGURATION, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
};
const fetchProductData = (req = {}) => {
	const params = {
		...req?.params,
	};
	const url = toQueryStringURL(endpoints.FETCH_PRODUCT_DATA, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
};
const masterMenuServices = {
	productMasterMenuList,
	addProductMasterMenu,
	updateProductMasterMenu,
	deleteProductMasterMenu,
	createProduct,
	updateProduct,
	fetchCategoryConfiguration,
	fetchProductData,
};

export default masterMenuServices;
