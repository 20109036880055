/* eslint-disable no-unreachable */
/* eslint-disable no-tabs */
/* eslint-disable no-unused-vars */
/* eslint-disable*/
import { connect } from 'react-redux';
import {
	IconButton, MenuItem, TextField
} from '@mui/material';
import {
	useEffect, useReducer, useState, useMemo, useCallback,
	useRef
} from 'react';
import { ArrowBack } from '@mui/icons-material';
import { useHistory } from 'react-router';
import moment from 'moment';
import styles from './BillsCreatePage.module.scss';
import config from './billsCreate.config';
// eslint-disable-next-line no-unused-vars
import { notificationServices } from '../../../Services';
import { AlertModal } from '../../../Components/Modals';
import { formActions, modalActions } from '../../../Actions';
import BackdropLoader from '../../../Components/Loaders/BackdropLoader';
import {
	addDays,
	classes,
	findPaymentTerm,
	getStateFromGSTIN,
	inputHelper,
	useQuery,
} from '../../../Helpers';
import DatePickerCustom from '../../../Components/DatePickerCustom/DatePickerCustom';
import { POItemSelection } from '../../../Components/POItemSelection';
import CustomInputSelector from '../../../Components/CustomInputSelector/CustomInputSelector';
import { IconInput } from '../../../Components/Forms/Inputs';
import { AddNewVendorModal } from '../../../Components/Modals/AddNewVendorModal';
import { POTotalContainer } from '../../../Components/POTotalContainer';
import { FileUploaderModal } from '../../../Components/Modals/FileUploaderModal';
import { urls } from '../../../Constants';
import inventoryAnalyticsServices from '../../../Services/Analytics Services/inventory-analytics.services';
import { POItemsModal } from '../../../Components/Modals/POItemsModal';
import productProcessMapServices from '../../../Services/Analytics Services/product-process-map.services';
import { paymentTerms } from '../../../Components/POGeneralDetails/POGeneralDetails';
import { Input } from '@/Components/ui/input';
import { Textarea } from '@/Components/ui/textarea';
import { Button } from '@/Components/ui/button';
import GrnModal from '@/Components/Modals/GrnModal/GrnModal';

function BillsCreatePage(props) {
	const {
		fetchStoresList,
		stores,
		fetchProducts,
		fetchVendors,
		vendors,
		toggleAlert,
		toggleAddVendorModal,
		productsList,
		toggleFileUploaderModal,
		togglePOItemsModal,
		toggleGrnModal,
	} = props;
	const [state, dispatch] = useReducer(config.reducer, config.initialState);
	const [productWiseMappingData, setProductWiseMappingData] = useState([]);
	const {
		// fromStore,
		store,
		billDate,
		clientBillId,
		orderId,
		dueDate,
		vendor: selectedVendor,
		invoiceDoc,
		clientPOId,
		packagingCharges,
		deliveryCharges,
		poGrnId,
		advanceBillAmount,
		debitCreditData,
	} = state ?? {};
	const [loading, setLoading] = useState(false);
	const [capexOrderItems, setCapexOrderItems] = useState([]);
	const history = useHistory();
	const params = useQuery();
	const [skuStockInfo, setSkuStockInfo] = useState([
		{
			name: 'Ordered',
		},
		{
			name: 'Order Fulfilled',
		},
	]);
	const poId = Number(params.get('poId'));
	const grnId = Number(params.get('grnId'));
	const billId = Number(params.get('billId'));
	const isAdvance = params.get('isAdvance');
	const isEdit = params.get('isEdit') && params.get('isEdit') === 'true';
	const orderTypeQuery = params.get('orderType');
	const [orderItems, setOrderItems] = useState([]);
	const [rowError, setRowError] = useState({});
	// manage state for the last grns modal values
	// const [uploadedFile, setUploadedFile] = useState({});
	// const creditValue = useRef({
	// 	value: null,
	// });
	const [delta, setDelta] = useState(null);

	useEffect(() => {
		fetchStoresList({
			entity: 'STORES',
			req: {
				params: {
					id: '',
					all: true,
				},
			},
			method: 'LIST',
		});
		fetchStoresList({
			entity: 'FILTERED_STORES',
			req: {
				params: {
					id: '',
					all: true,
				},
			},
			method: 'LIST',
		});
		fetchProducts({
			entity: 'ALL_PRODUCTS',
			req: {
				params: {
					id: '',
					variants: true,
				},
			},
			method: 'LIST',
		});
		fetchVendors({
			entity: 'ALL_VENDORS',
			req: {
				params: {
					id: '',
				},
			},
			method: 'LIST',
		});
	}, []);

	const handleSkuStockInfo = useCallback((data) => {
		const variantCurrentStockQtyMap = {};
		// eslint-disable-next-line no-unused-vars
		Object.entries(data)?.forEach(([key, value]) => {
			value?.currentStock?.forEach((ele) => {
				variantCurrentStockQtyMap[ele?.variantId] = ele?.rawQty;
			});
		});
		setSkuStockInfo((prev) => {
			const isStockExisting = prev?.find((ele) => {
				return ele?.name === 'Stock';
			});
			if (isStockExisting) {
				return prev?.map((ele) => {
					if (ele?.name === 'Stock') {
						return {
							...ele,
							values: variantCurrentStockQtyMap,
						};
					}
					return ele;
				});
			}
			return [
				...prev,
				{
					name: 'Stock',
					bgColor: '#abc7',
					color: 'black',
					showInDropDown: true,
					columnIndexToShowIn: 0,
					values: variantCurrentStockQtyMap,
				},
			];
		});
	}, []);

	const handleQuantities = useCallback((items) => {
		const orderedVariantMap = {};
		const orderedFulfilledMap = {};

		if (items?.length > 0) {
			items?.forEach((ele) => {
				orderedVariantMap[ele?.variantId] = {
					qty: ele?.qty,
					unit: ele?.unit,
				};
				orderedFulfilledMap[ele?.variantId] = {
					qty: ele?.fulfilled,
					unit: ele?.unit,
				};
			});
		}

		const orderedObj = {
			name: 'Ordered',
			// bgColor: '#abc9',
			// color: 'black',
			columnIndexToShowIn: 2,
			values: orderedVariantMap,
		};
		const fulfilledObj = {
			name: 'Order Fulfilled',
			// bgColor: '#abc9',
			// color: 'black',
			columnIndexToShowIn: 2,
			values: orderedFulfilledMap,
		};

		setSkuStockInfo((prev) => {
			return prev?.map((ele) => {
				if (ele?.name === 'Ordered') {
					return orderedObj;
				}
				if (ele?.name === 'Order Fulfilled') {
					return fulfilledObj;
				}
				return ele;
			});
		});
	}, []);

	useEffect(() => {
		if (state?.store?.id) {
			(async () => {
				const response = await inventoryAnalyticsServices.skuStock({
					params: {
						type: 'stock',
						storeId: state?.store?.id,
					},
				});
				if (response?.statusCode === 200) {
					handleSkuStockInfo(response?.data);
				}
			})();
		}
	}, [state?.store?.id]);

	const getUploadedDocBase = async (transactionId) => {
		try {
			const response = await inventoryAnalyticsServices.fetchInvTransDoc({
				req: {
					params: {
						transactionId: transactionId ?? grnId,
					},
				},
			});
			if (response?.statusCode === 200) {
				dispatch({
					type: 'SET_VALUE',
					payload: {
						invoiceDoc: {
							file: response?.data?.base64,
							fileName: 'previous_upload_invoice.pdf',
							changeParentDoc: false,
						},
					},
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log('error', error);
		}
	};

	const fetchGrnData = async (poData) => {
		try {
			const response =
				await inventoryAnalyticsServices.getTransactionRecord({
					params: {
						transactionId: grnId,
					},
				});
			if (response?.statusCode === 200) {
				dispatch({
					type: 'SET_VALUE',
					payload: {
						poGrnId: grnId,
						dueDate: moment(response?.data?.dueDate)?.format(
							'YYYY-MM-DD'
						)
							? new Date(response?.data?.dueDate)
							: new Date(
								addDays(
									response?.data?.updatedAt,
									findPaymentTerm(
										poData?.jsonData?.paymentTerm
									)?.noOfDays ?? 0
								)
							),
						invoiceDate: response?.data?.invoiceDate ?? null,
						invoiceRef: response?.data?.invoiceRef,
						invoiceAmount: response?.data?.invoiceAmount,
						description: response?.data?.description,
						grnDate: moment(
							response?.data?.transactionDate
						)?.format('YYYY-MM-DD'),
						orderDate: moment(poData?.createdAt)?.format(
							'YYYY-MM-DD'
						),
						...(response?.data?.extraData?.additionalCharges && {
							additionalCharges:
								response?.data?.extraData?.additionalCharges ??
								[],
						}),
						...(response?.data?.extraData?.deliveryCharges && {
							deliveryCharges:
								response?.data?.extraData?.deliveryCharges ?? 0,
						}),
						...(response?.data?.extraData?.packagingCharges && {
							packagingCharges:
								response?.data?.extraData?.packagingCharges ??
								0,
						}),
					},
				});
				getUploadedDocBase();
				if (orderTypeQuery === 'CAPEX') {
					setCapexOrderItems(
						response?.data?.items?.map((ele) => {
							let productId = ele?.productId;
							if (ele?.assetId) {
								productId = ele?.assetId;
							}
							if (ele?.serviceId) {
								// eslint-disable-next-line prefer-template
								productId = 'SERVICE_' + ele?.serviceId;
							}
							return {
								...ele,
								itemTransactionId: ele?.id,
								id: productId,
								quantity: ele?.fulfilled,
								variantId: ele?.id,
								tax: ele?.tax ? ele?.tax : ele?.taxPercentage,
								discountPercentage: ele?.discountPercentage
									? ele?.discountPercentage
									: 0,
								variants: ele?.variants
									? ele?.variants
									: [
										{
											// eslint-disable-next-line prefer-template
											id: ele?.id,
											displayName: ele?.assetId
												? 'ASSET'
												: 'SERVICE',
											price: ele?.price,
										},
									],
							};
						})
					);
				}
				setOrderItems(
					response?.data?.items?.map((ele) => {
						let productId = ele?.productId;
						if (ele?.assetId) {
							productId = ele?.assetId;
						}
						if (ele?.serviceId) {
							// eslint-disable-next-line prefer-template
							productId = 'SERVICE_' + ele?.serviceId;
						}
						return {
							...ele,
							qty: ele?.fulfilled,
							variantId: ele?.variantId
								? ele?.variantId
								: ele?.id,
							productId,
						};
					})
				);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const fetchBillData = async () => {
		try {
			const response =
				await inventoryAnalyticsServices.getTransactionRecord({
					params: {
						transactionId: billId,
					},
				});
			if (response?.statusCode === 200) {
				dispatch({
					type: 'SET_VALUE',
					payload: {
						vendor: vendors?.find((ele) => {
							return ele?.id === response?.data?.vendorId;
						}),
						orderAmount: response?.data?.orderAmount,
						store: stores?.find((ele) => {
							return ele?.id === response?.data?.storeId;
						}),
						fromStore: stores?.find((ele) => {
							return ele?.id === response?.data?.fromStore;
						}),
						orderId: response?.data?.orderId,
						dueDate: new Date(response?.data?.dueDate),
						clientPOId: response?.data?.clientOrderId,
						poGrnId: response?.data?.parentRowId,
						grnDate: moment(
							response?.data?.parentTransactionRecord?.createdAt
						)?.format('YYYY-MM-DD'),
						invoiceDate: new Date(response?.data?.invoiceDate),
						invoiceRef: response?.data?.invoiceRef,
						invoiceAmount: response?.data?.invoiceAmount,
						description: response?.data?.description,
						paymentTerm: response?.data?.extraData?.paymentTerm,
						additionalCharges:
							response?.data?.extraData?.additionalCharges ?? [],
						deliveryCharges:
							response?.data?.extraData?.deliveryCharges ?? 0,
						packagingCharges:
							response?.data?.extraData?.packagingCharges ?? 0,
						debitCreditData: response?.data?.extraData?.poClosingData,
					},
				});
				getUploadedDocBase(billId);
				if (orderTypeQuery === 'CAPEX') {
					setCapexOrderItems(
						response?.data?.items?.map((ele) => {
							let productId = ele?.productId;
							if (ele?.assetId) {
								productId = ele?.assetId;
							}
							if (ele?.serviceId) {
								// eslint-disable-next-line prefer-template
								productId = 'SERVICE_' + ele?.serviceId;
							}
							return {
								...ele,
								itemTransactionId: ele?.id,
								id: productId,
								quantity: ele?.qty,
								variantId: ele?.id,
								tax: ele?.tax ? ele?.tax : ele?.taxPercentage,
								discountPercentage: ele?.discountPercentage
									? ele?.discountPercentage
									: 0,
								variants: ele?.variants
									? ele?.variants
									: [
										{
											// eslint-disable-next-line prefer-template
											id: ele?.id,
											displayName: ele?.assetId
												? 'ASSET'
												: 'SERVICE',
											price: ele?.price,
										},
									],
							};
						})
					);
				}
				setOrderItems(
					response?.data?.items?.map((ele) => {
						let productId = ele?.productId;
						if (ele?.assetId) {
							productId = ele?.assetId;
						}
						if (ele?.serviceId) {
							// eslint-disable-next-line prefer-template
							productId = 'SERVICE_' + ele?.serviceId;
						}
						return {
							...ele,
							qty: ele?.qty,
							variantId: ele?.variantId
								? ele?.variantId
								: ele?.id,
							productId,
						};
					})
				);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (billId && vendors?.length > 0 && stores?.length > 0) {
			fetchBillData();
		}
	}, [billId, vendors, stores]);

	useEffect(() => {
		if (poId && vendors?.length > 0 && stores?.length > 0) {
			(async () => {
				try {
					const response = await inventoryAnalyticsServices.fetchPo(
						Number(poId)
					);
					if (response?.statusCode === 200) {
						dispatch({
							type: 'SET_VALUE',
							payload: {
								vendor: vendors?.find((ele) => {
									return ele?.id === response?.data?.vendorId;
								}),
								orderAmount: response?.data?.items?.reduce(
									(acc, curr) => {
										return acc + curr?.finalValue;
									},
									0
								),
								store: stores?.find((ele) => {
									return ele?.id === response?.data?.storeId;
								}),
								fromStore: stores?.find((ele) => {
									return (
										ele?.id === response?.data?.fromStore
									);
								}),
								orderId: response?.data?.id,
								orderDate: moment(
									response?.data?.createdAt
								)?.format('YYYY-MM-DD'),
								dueDate: new Date(
									addDays(
										response?.data?.scheduledAt,
										findPaymentTerm(
											response?.data?.jsonData
												?.paymentTerm
										)?.noOfDays ?? 0,
										'DD/MM/YYYY'
									)
								),
								clientPOId: response?.data?.clientPoId,
								deliveryCharges:
									response?.data?.deliveryCharges ?? 0,
								packagingCharges:
									response?.data?.packagingCharges ?? 0,
								paymentTerm:
									response?.data?.jsonData?.paymentTerm,
								poData: response?.data,
								additionalCharges:
									response?.data?.jsonData
										?.additionalCharges ?? [],
							},
						});
						handleQuantities(response?.data?.items);
						if (!grnId) {
							if (orderTypeQuery === 'CAPEX') {
								setCapexOrderItems(
									response?.data?.items?.map((ele) => {
										let productId = ele?.productId;
										if (ele?.assetId) {
											productId = ele?.assetId;
										}
										if (ele?.serviceId) {
											// eslint-disable-next-line prefer-template
											productId =
												`SERVICE_${ele?.serviceId}`;
										}
										return {
											...ele,
											itemTransactionId: ele?.id,
											id: productId,
											quantity: ele?.fulfilled,
											variantId: ele?.id,
											tax: ele?.tax
												? ele?.tax
												: ele?.taxPercentage,
											discountPercentage:
												ele?.discountPercentage
													? ele?.discountPercentage
													: 0,
											variants: ele?.variants
												? ele?.variants
												: [
													{
														// eslint-disable-next-line prefer-template
														id: ele?.id,
														displayName:
																ele?.assetId
																	? 'ASSET'
																	: 'SERVICE',
														price: ele?.price,
													},
												],
										};
									})
								);
							}
							setOrderItems(
								response?.data?.items?.map((ele) => {
									let productId = ele?.productId;
									if (ele?.assetId) {
										productId = ele?.assetId;
									}
									if (ele?.serviceId) {
										// eslint-disable-next-line prefer-template
										productId = 'SERVICE_' + ele?.serviceId;
									}
									return {
										...ele,
										qty: ele?.fulfilled,
										variantId: ele?.variantId
											? ele?.variantId
											: ele?.id,
										productId,
									};
								})
							);
						} else {
							fetchGrnData(response?.data);
						}
					}
				} catch (error) {
					// eslint-disable-next-line no-console
					console.log(error);
				} finally {
					setLoading(false);
				}
			})();
		}
	}, [poId, grnId, vendors.length, stores.length]);

	useEffect(() => {
		if (state?.store?.id) {
			(async () => {
				const req = {
					data: {
						store: {
							id: store?.id,
						},
						mappingTypes: [6],
						categoryId: null,
						products: null,
					},
				};
				try {
					const response =
						await productProcessMapServices?.fetchProductWiseMappingData(
							req
						);
					if (response?.responseCode === 'SS-001') {
						notificationServices.generateNotification({
							type: 'success',
							message: response?.responseMessage,
						});
						setProductWiseMappingData(
							response?.data?.productwiseMappings
						);
					}
				} catch (error) {
					notificationServices.generateNotification({
						type: 'error',
						message: error?.message,
					});
				}
			})();
		}
	}, [state?.store?.id]);

	// useEffect(() => {
	// if (
	// poId &&
	// grnId &&
	// vendors?.length > 0 &&
	// productsList?.length > 0 &&
	// stores?.length > 0
	// ) {
	// ()();
	// }
	// }, [grnId, vendors.length, productsList.length, stores.length]);

	const calculatedData = useMemo(() => {
		let subTotal = 0;
		let totalTax = 0;
		let totalAmount = 0;
		let totalDiscount = 0;
		let taxValues = {};
		const totalQuantity = state?.items?.length;
		state?.items?.forEach((ele) => {
			// if (ele?.unit === 'gram') {
			subTotal += ele?.subTotal;
			totalAmount += ele?.totalPriceIncTax;
			totalTax += ele?.totalTax;
			totalDiscount += ele?.totalDiscount;
		});
		const pc = Number(packagingCharges) ?? 0;
		const dc = Number(deliveryCharges) ?? 0;
		totalTax += ((pc + dc) * 18) / 100;
		totalAmount += (pc + dc) * (1 + 18 / 100);
		taxValues.SGST = totalTax / 2;
		taxValues.CGST = totalTax / 2;
		if (state?.additionalCharges?.length) {
			state?.additionalCharges?.forEach((ele) => {
				totalTax += Number(ele?.price * ele?.taxPercentage) / 100;
				totalAmount +=
					Number(ele?.price) * Number(1 + ele?.taxPercentage / 100);
			});
		}
		if (
			state?.vendor?.id &&
			state?.vendor?.gstin &&
			state?.store?.gstNumber
		) {
			const vendorState = getStateFromGSTIN(state?.vendor?.gstin);
			const storeState = getStateFromGSTIN(state?.store?.gstNumber);
			if (vendorState !== storeState) {
				taxValues = {
					IGST: totalTax,
				};
			}
		}
		totalAmount=Number(totalAmount.toFixed(2))
		return {
			subTotal,
			totalQuantity,
			totalTax,
			totalAmount,
			taxValues,
			totalDiscount,
		};
	}, [state?.items, packagingCharges, deliveryCharges]);

	async function fetchBillId() {
		if (clientBillId) return;
		try {
			const response = await inventoryAnalyticsServices.fetchPOId();
			if (response?.statusCode === 200) {
				dispatch({
					type: 'SET_VALUE',
					payload: {
						clientBillId: response?.data,
					},
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	}

	useEffect(() => {
		if (!clientBillId) {
			fetchBillId();
		}
	}, [clientBillId]);

	const submitFunction = async (deserializedFinalData) => {
		const { poClosingData, ...deserializedData } = deserializedFinalData || {};
		const req = {
			data: {
				...deserializedData,
				extraData: {
					...deserializedData?.extraData,
					taxValues: calculatedData?.taxValues,
					poClosingData: poClosingData ? {
						...poClosingData,
					} : {},
				},
				transactionStatus: 'APPROVAL_PENDING_MANAGER',
				subTotal: calculatedData?.subTotal,
				totalTaxAmount: calculatedData?.totalTax,
				subType:
					orderTypeQuery === 'CAPEX'
						? 'CAPEX'
						: deserializedData?.subType,
				finalTransactionAmount: isAdvance
					? Number(deserializedData?.finalTransactionAmount)
					: calculatedData?.totalAmount,
				nextFlowLink: `${window?.location?.origin}${urls.EXTENSION}${urls.BILLS}?poId=${deserializedData?.orderId}&clientPOId=${deserializedData?.clientOrderId}`,
			},
		};
		setLoading(true);
		try {
			const response =
				await inventoryAnalyticsServices.createTransactionRecord(req);
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
				dispatch({
					type: 'INIT',
				});
				history.goBack();
			}
		} catch ({ name, message }) {
			notificationServices?.generateNotification({
				type: 'error',
				// eslint-disable-next-line prefer-template
				message,
			});
			// eslint-disable-next-line no-console
		} finally {
			setLoading(false);
			toggleGrnModal(false);
		}
	};
	const saveEditBill = async (deserializedFinalData) => {
		const { poClosingData, ...deserializedData } = deserializedFinalData || {};
		if (!billId) return;
		const req = {
			params: {
				transactionId: billId,
			},
			data: {
				...deserializedData,
				extraData: {
					...deserializedData?.extraData,
					taxValues: calculatedData?.taxValues,
					poClosingData: poClosingData ? {
						...poClosingData,
					} : {},
				},
				transactionStatus: 'APPROVAL_PENDING_MANAGER',
				subTotal: calculatedData?.subTotal,
				totalTaxAmount: calculatedData?.totalTax,
				subType:
					orderTypeQuery === 'CAPEX'
						? 'CAPEX'
						: deserializedData?.subType,
				finalTransactionAmount: isAdvance
					? deserializedData?.finalTransactionAmount
					: calculatedData?.totalAmount,
				nextFlowLink: `${window?.location?.origin}${urls.EXTENSION}${urls.BILLS}?poId=${deserializedData?.orderId}&clientPOId=${deserializedData?.clientOrderId}`,
			},
		};
		setLoading(true);
		try {
			const response =
				await inventoryAnalyticsServices.editTransactionRecord(req);
			if (response?.statusCode === 200) {
				notificationServices.generateNotification({
					type: 'success',
					message: response?.message,
				});
				dispatch({
					type: 'INIT',
				});
				history.goBack();
			}
		} catch ({ name, message }) {
			notificationServices?.generateNotification({
				type: 'error',
				// eslint-disable-next-line prefer-template
				message,
			});
			// eslint-disable-next-line no-console
		} finally {
			setLoading(false);
			toggleGrnModal(false);
		}
	};

	const handleSaveBill = (deserializedData) => {
		if (isEdit) {
			saveEditBill(deserializedData);
			return;
		}
		submitFunction(deserializedData);
	};

	const handleConfirm = () => {
		const deserializedData = config?.deserializer(state, isAdvance);
		const validate = config?.validator(state);
		if (validate?.field) {
			notificationServices?.generateNotification({
				type: 'error',
				// eslint-disable-next-line prefer-template
				message: validate?.displayName + ' is mandatory',
			});
			return;
		}
		if (!isAdvance && !deserializedData?.items?.length) {
			notificationServices?.generateNotification({
				type: 'error',
				message: 'Please add item in the Bill',
			});
			return;
		}
		if (isAdvance && advanceBillAmount <= 0) {
			notificationServices?.generateNotification({
				type: 'error',
				message: 'Please enter Advance Bill Amount',
			});
			return;
		}

		if (!deserializedData?.uploadedDoc) {
			if (isAdvance) {
				toggleAlert(true, {
					heading: 'No invoice attached',
					handleConfirm: () => {
						handleSaveBill(deserializedData);
						toggleAlert(false);
					},
					colorReverse: false,
					width: '30rem',
					cancelText: 'Cancel',
					confirmText: 'Confirm',
					message:
						'Do you still want to generate the bill without invoice?',
				});
			} else {
				toggleAlert(true, {
					heading: 'No invoice attached',
					handleConfirm: () => {
						handleSaveBill(deserializedData);
						toggleAlert(false);
					},
					colorReverse: false,
					width: '30rem',
					cancelText: 'Cancel',
					noteMessage: 'Bill will not be approved without invoice',
					confirmText: 'Confirm',
					message: 'Do you still want to generate bill?',
				});
			}
		} else {
			handleSaveBill(deserializedData);
		}
	};

	const setItemsState = (items) => {
		dispatch({
			type: 'SET_VALUE',
			payload: {
				items,
			},
		});
	};

	const handleKeyPress = (e) => {
		const key = e?.target?.name ?? '';
		if (e.keyCode === 8) {
			// setState(`SET_${key}`, {});
			dispatch({
				type: 'SET_VALUE',
				payload: {
					[key]: {},
				},
			});
		}
	};

	const handleBillingStore = (newToStore) => {
		dispatch({
			type: 'SET_VALUE',
			payload: {
				store: newToStore,
			},
		});
	};
	// const handleFromStore = (newFromStore) => {
	// dispatch({
	// type: 'SET_VALUE',
	// payload: {
	// fromStore: newFromStore,
	// },
	// });
	// };
	const handleVendorSelection = (vendor) => {
		dispatch({
			type: 'SET_VALUE',
			payload: {
				vendor,
			},
		});
	};

	const disabledDate = (rsuiteDate) => {
		return rsuiteDate > new Date();
	};

	const handleVendorInvoiceUpload = (fileData) => {
		dispatch({
			type: 'SET_VALUE',
			payload: {
				invoiceDoc: {
					...fileData,
					changeParentDoc: true,
				},
			},
		});
		toggleFileUploaderModal(false);
	};

	const uploadInvoiceCick = () => {
		toggleFileUploaderModal(true, {
			heading: 'Upload Vendor Invoice',
			selectedFile: '',
			confirmText: 'Upload',
		});
	};

	const isRowError = useMemo(() => {
		let result = false;
		Object?.values(rowError)?.forEach((ele) => {
			Object?.values(ele ?? {})?.forEach((innerEle) => {
				if (innerEle === true) {
					result = true;
				}
			});
		});
		return result;
	}, [rowError]);

	const handleAdditionalCharges = (idx, e) => {
		const { fieldName, fieldValue } = inputHelper(e);
		const newAdditionalCharge = state?.additionalCharges?.map(
			(ele, index) => {
				if (index === idx) {
					return {
						...ele,
						[fieldName]: fieldValue,
					};
				}
				return ele;
			}
		);
		dispatch({
			type: 'SET_VALUE',
			payload: {
				additionalCharges: newAdditionalCharge,
			},
		});
	};
	// call Api to save the To data of the last grn Data (closing po)

	// Use useEffect to update the state
	useEffect(() => {
		// const grnAmount = Math.round(calculatedData?.totalAmount * 100) / 100;
		const grnAmount = calculatedData?.totalAmount;
		// const invoiceAmount = Math.round(state?.invoiceAmount * 100) / 100;
		const invoiceAmount = state?.invoiceAmount;
		if (invoiceAmount && grnAmount) {
			const deltaValue = grnAmount - invoiceAmount;
			setDelta((prevDelta) => {
				return Math.abs(deltaValue);
			});
		}
	}, 	[state, calculatedData?.totalAmount, state?.invoiceAmount]);

	const handleSaveLastGrnData = ({ creditValue = null, uploadedDoc = {} }) => {
		if (!creditValue) {
			notificationServices?.generateNotification({
				type: 'error',
				message: 'Credit/Debit Field Can Not be empty',
			});
			return;
		}
		if (!uploadedDoc?.file) {
			notificationServices?.generateNotification({
				type: 'error',
				message: 'Please Upload Doc',
			});
			return;
		}
		if (Math.abs(Number(creditValue) - delta) < 0.009) {
			notificationServices?.generateNotification({
				type: 'error',
				message: 'Credit/Debit Value is not same',
			});
			return;
		}
		const deserializedData = config?.deserializer(state, isAdvance);
		const finalDeserilizedData = {
			...deserializedData,
			poClosingData: {
				creditDoc: uploadedDoc?.file || null,
				creditValue: Number(creditValue),
				delta,
			},
		};
		handleSaveBill(finalDeserilizedData);
	};
	//  handle confirm last grn

	const handleConfirmLastgrn = () => {
		const deserializedData = config?.deserializer(state, isAdvance);
		const validate = config?.validator(state);
		if (validate?.field) {
			notificationServices?.generateNotification({
				type: 'error',
				// eslint-disable-next-line prefer-template
				message: validate?.displayName + ' is mandatory',
			});
			return;
		}
		if (!isAdvance && !deserializedData?.items?.length) {
			notificationServices?.generateNotification({
				type: 'error',
				message: 'Please add item in the Bill',
			});
			return;
		}
		if (isAdvance && advanceBillAmount <= 0) {
			notificationServices?.generateNotification({
				type: 'error',
				message: 'Please enter Advance Bill Amount',
			});
			return;
		}
		toggleAlert(true, {
			heading: 'Credit/Debit Note Bill Creation',
			nextLineMessage: true,
			extraData: {
				invoiceAmount: Number(state?.invoiceAmount).toFixed(2),
				billAmount: Number(calculatedData?.totalAmount).toFixed(2),
			},
			confirmText: 'Proceed',
			width: 400,
			handleConfirm: () => {
				toggleGrnModal(true, {
					height: 600,
					width: 1000,
					handleConfirm: (creditValue, uploadedDoc) => {
						handleSaveLastGrnData({
							creditValue, uploadedDoc,
						});
					},
					heading: `#${state?.invoiceRef ?? poGrnId} - Invoice Amount mismatch !!`,
					grnId: poGrnId,
					grnDate: state?.grnDate,
					grnAmount: Number(calculatedData?.totalAmount).toFixed(2),
					invoiceAmount: Number(state?.invoiceAmount).toFixed(2),
					gstin: state?.vendor?.gstin,
					vendorName: state?.vendor?.name,
					billingStore: state?.store?.name,
					orderId: state?.clientPOId,
					orderDate: state?.orderDate,
					paymentTerm: state?.paymentTerm,
					debitCreditData,
					delta,
					isEdit,
				});
				toggleAlert(false);
			},
		});
	};
	return (
		<div className={styles.root}>
			<AddNewVendorModal />
			<AlertModal />
			<POItemsModal />
			<BackdropLoader isLoading={loading} showProgress />
			<FileUploaderModal handleConfirm={handleVendorInvoiceUpload} />
			<GrnModal />
			{/* <ListModal
				handleConfirm={selectOutlets}
				selectionBehaviour='SHOW'
				maxSelectLimit={5}
			/> */}
			<div className={styles.header}>
				<div className={styles['page-back']}>
					<IconButton
						onClick={() => {
							history.goBack();
						}}
					>
						<ArrowBack />
					</IconButton>
					<p className={styles['page-name']}>
						{isEdit
							? `Edit Bill BL-${clientBillId}`
							: 'Add New Bill'}
					</p>
				</div>
				<Button
					onClick={Math.abs(delta) > 0.009 ? handleConfirmLastgrn :  handleConfirm}
					className='primary-btn'
					disabled={isRowError}
				>
					Save and Send
				</Button>
			</div>
			<div className={styles.body}>
				{isAdvance && (
					<div className={styles.advance}>
						You are generating a Advance Bill
					</div>
				)}
				{/* {selectedVendor?.id && ( */}
				<div className={classes(styles['vendor-container'])}>
					<div className={styles['label-container']}>
						<label
							className={styles.mandatory}
							htmlFor='vendorSelection'
						>
							Vendor Name*
						</label>
						<CustomInputSelector
							inputName='VENDOR'
							width='300px'
							inputLists={vendors?.map((ele) => {
								return {
									...ele,
									imageUrl: ele?.logo,
								};
							})}
							handleKeyPress={handleKeyPress}
							handleSelection={handleVendorSelection}
							addNewText='Add New Vendor'
							placeholder='Select A Vendor'
							selectedValue={selectedVendor?.name}
							classname={styles['vendor-selector']}
							disabled={!!isEdit || !!grnId}
							handleAddNew={
								() =>
									// eslint-disable-next-line implicit-arrow-linebreak, arrow-body-style
									toggleAddVendorModal(true, {})
								// eslint-disable-next-line react/jsx-curly-newline
							}
						/>
					</div>
					{(selectedVendor?.billingAddress1 ||
						selectedVendor?.billingAddress1 ||
						selectedVendor?.gstin) && (
						<div className={styles['label-container']}>
							<label htmlFor='vendorDetails' />
							<div className={styles['vendor-details']}>
								{selectedVendor?.gstin && (
									// eslint-disable-next-line react/jsx-one-expression-per-line
									<p>GSTIN: {selectedVendor?.gstin}</p>
								)}
								<div className={styles['address-container']}>
									{selectedVendor?.billingAddress1 && (
										<div className={styles.address}>
											<p
												className={
													styles['address-header']
												}
											>
												Billing Address
											</p>
											<p>
												{
													selectedVendor?.billingAddress1
												}
											</p>
											<p>
												{
													selectedVendor?.billingAddress2
												}
											</p>
											{selectedVendor?.billingAddressCity && (
												<p>
													{`${selectedVendor?.billingAddressCity} ,${selectedVendor?.billingAddressState}`}
												</p>
											)}
											<p>
												{
													selectedVendor?.billingAddressPinCode
												}
											</p>
										</div>
									)}
									{selectedVendor?.shippingAddress1 && (
										<div className={styles.address}>
											<p
												className={
													styles['address-header']
												}
											>
												Pickup Address
											</p>
											<p>
												{
													selectedVendor?.shippingAddress1
												}
											</p>
											<p>
												{
													selectedVendor?.shippingAddress2
												}
											</p>
											{selectedVendor?.shippingAddressCity && (
												<p>
													{`${selectedVendor?.shippingAddressCity} ,${selectedVendor?.shippingAddressState}`}
												</p>
											)}
											<p>
												{
													selectedVendor?.shippingAddressPinCode
												}
											</p>
										</div>
									)}
								</div>
							</div>
						</div>
					)}
				</div>
				{/* )} */}
				{/* {(fromStore?.id || !selectedVendor?.id) && (
					<div className={`${styles.tile} form-field row`}>
						<label className={`${styles.mandatory} form-label`}>
							To Store*
						</label>
						<CustomInputSelector
							inputName='fromStore'
							width='300px'
							inputLists={stores?.map((ele) => {
								return {
									...ele,
									imageUrl: ele?.logo,
								};
							})}
							handleKeyPress={handleKeyPress}
							handleSelection={handleFromStore}
							hideAddNew
							placeholder='Select By Store'
							selectedValue={fromStore?.name}
							classname={styles['store-selector']}
						/>
					</div>
				)} */}
				<div className={`${styles.tile} form-field row`}>
					<label
						className={`${styles.mandatory} form-label`}
						// htmlFor={id}
					>
						Billing Store*
					</label>
					<CustomInputSelector
						inputName='store'
						width='300px'
						inputLists={stores?.map((ele) => {
							return {
								...ele,
								imageUrl: ele?.logo,
							};
						})}
						handleRemove={(key) => {
							dispatch({
								type: 'SET_VALUE',
								payload: {
									[key]: {},
								},
							});
						}}
						handleKeyPress={handleKeyPress}
						handleSelection={handleBillingStore}
						hideAddNew
						disabled={!!isEdit || !!grnId}
						placeholder='Select By Store'
						selectedValue={store?.name}
						classname={styles['store-selector']}
					/>
				</div>
				<div className={styles.multi}>
					<div className={`${styles.tile} form-field row`}>
						<label
							className={`${styles.mandatory} form-label`}
							// htmlFor={id}
						>
							Bill ID*
						</label>
						<IconInput
							type='custom'
							iconText='BL-'
							size='extra-large'
							inputType='text'
							value={clientBillId}
							isDisabled={!!isEdit || !!grnId}
							handleInput={(e) => {
								dispatch({
									type: 'SET_VALUE',
									payload: {
										clientBillId: e?.target?.value,
									},
								});
							}}
							style={{
								width: '300px',
							}}
						/>
					</div>
					{/* <div className={}></div> */}
					<div className={`${styles.tile} form-field row`}>
						<label
							className='form-label'
							// htmlFor={id}
						>
							Invoice
						</label>
						<div className={styles['invoice-uplaod']}>
							{invoiceDoc?.file && invoiceDoc?.fileName && (
								<div className={styles.preview}>
									<p>{invoiceDoc?.fileName}</p>
									<div
										onClick={() => {
											togglePOItemsModal(true, {
												heading: 'Invoice Preview',
												items: state?.items?.map(
													(ele) => {
														return {
															...ele,
															qty: ele?.quantity,
															variantId: ele?.id,
															price: ele
																?.selectedVariant
																?.price,
															totalValue:
																ele?.totalPriceIncTax,
														};
													}
												),
												showPdf: true,
												previewFile: invoiceDoc?.file,
												width: '80%',
												height: '80%',
												excludedColumns: [
													'Packaged',
													'Dispatched',
													'Received',
													'Returned Quantity',
													'Fulfilled',
													'From Process',
													'PO Id',
													'Returned',
													'Quantity',
													'Used For',
												],
											});
										}}
										className={styles['preview-btn']}
									>
										Preview
									</div>
									<div
										className={styles['preview-container']}
									>
										<iframe
											className={styles.iframe}
											width='100%'
											height='100%'
											src={invoiceDoc?.file}
											title='PDF Rendered from Base64'
										/>
									</div>
								</div>
							)}
							<Button
								type='button'
								variant={
									invoiceDoc?.file && invoiceDoc?.fileName
										? 'outline'
										: 'secondary'
								}
								color='primary'
								onClick={uploadInvoiceCick}
							>
								{invoiceDoc?.file && invoiceDoc?.fileName
									? 'Update Invoice'
									: 'Upload Invoice'}
							</Button>
						</div>
					</div>
				</div>
				<div className={styles.multi}>
					{!!(clientPOId || orderId) && (
						<div className={`${styles.tile} form-field row`}>
							<label
								className='form-label'
								// htmlFor={id}
							>
								Order ID
							</label>
							<div className={styles['order-id']}>
								{clientPOId ?? orderId}
							</div>
						</div>
					)}
					{!!poGrnId && (
						<div
							className={`${styles.tile} ${styles.grnTile} form-field row `}
						>
							<label
								className='form-label'
								// htmlFor={id}
							>
								GRN ID
							</label>
							<div className={styles['order-id']}>{poGrnId}</div>
						</div>
					)}
				</div>
				<div className={styles.multi}>
					{state?.orderDate && (
						<div className={`${styles.tile} form-field row`}>
							<label
								className='form-label'
								// htmlFor={id}
							>
								Order Date
							</label>
							<div className={styles['order-id']}>
								{state?.orderDate}
							</div>
						</div>
					)}
					{state?.grnDate && (
						<div className={`${styles.tile} form-field row `}>
							<label
								className='form-label'
								// htmlFor={id}
							>
								GRN Date
							</label>
							<div className={styles['order-id']}>
								{state?.grnDate}
							</div>
						</div>
					)}
				</div>
				<div className={styles.multi}>
					<div className={`${styles.tile} form-field row`}>
						<label
							className={`${styles.mandatory} form-label`}
							// htmlFor={id}
						>
							Billing Date*
						</label>
						<DatePickerCustom
							selectedDate={billDate}
							selectDate={(rsuiteDate) => {
								dispatch({
									type: 'SET_VALUE',
									payload: {
										billDate: rsuiteDate,
									},
								});
							}}
							style={{
								width: 300,
							}}
							oneTap
							defaultValue={billDate}
							shouldDisableDate={disabledDate}
						/>
					</div>
					<div className={`${styles.tile} form-field row`}>
						<label
							className='form-label'
							// htmlFor={id}
						>
							Due Date
						</label>
						{billId || grnId || poId ? (
							<div className={styles['order-id']}>
								{moment(dueDate)?.format('YYYY-MM-DD')}
							</div>
						) : (
							<DatePickerCustom
								disabled
								selectedDate={dueDate}
								selectDate={(rsuiteDate) => {
									dispatch({
										type: 'SET_VALUE',
										payload: {
											dueDate: rsuiteDate,
										},
									});
								}}
								style={{
									width: 300,
								}}
								oneTap
								defaultValue={dueDate}
								shouldDisableDate={(dates) => {
									return dates < billDate;
								}}
							/>
						)}
					</div>
				</div>
				{isAdvance && (
					<div className={styles.multi}>
						<div className={styles.tile}>
							<label
								className={styles.mandatory}
								htmlFor='billAmount'
							>
								Bill Amount*
							</label>
							<div>
								{/* ₹ */}
								<TextField
									id='billAmount'
									name='advanceBillAmount'
									value={advanceBillAmount}
									onChange={(e) => {
										if (
											e?.target?.value <=
											state?.poData?.finalValue
										) {
											dispatch({
												type: 'SET_VALUE',
												payload: {
													advanceBillAmount:
														e?.target?.value,
												},
											});
										}
									}}
									type='number'
									step='any'
									placeholder='Enter Bill Amount'
									sx={{
										width: '300px',
										'.MuiInputBase-root': {
											borderRadius: '6px',
										},
										'.MuiInputBase-input': {
											paddingTop: '6px',
											paddingBottom: '6px',
										},
									}}
								/>
							</div>
						</div>
					</div>
				)}
				<div className={styles.tile}>
					<label className={styles.mandatory} htmlFor='paymentTerm'>
						Payment Term*
					</label>
					{state?.poData?.jsonData?.paymentTerm ? (
						<div className={styles['order-id']}>
							{state?.poData?.jsonData?.paymentTerm}
						</div>
					) : (
						<TextField
							select
							id='paymentTerms'
							value={state?.paymentTerm}
							onChange={(e) => {
								dispatch({
									type: 'SET_VALUE',
									payload: {
										paymentTerm: e?.target?.value,
										dueDate: new Date(
											addDays(
												billDate,
												findPaymentTerm(
													e?.target?.value
												)?.noOfDays ?? 0
											)
										),
									},
								});
							}}
							sx={{
								width: 300,
								'.MuiInputBase-root': {
									borderRadius: '4px',
								},
								'.MuiInputBase-input': {
									paddingTop: '6px',
									paddingBottom: '6px',
								},
							}}
						>
							{paymentTerms?.map((ele) => {
								return (
									<MenuItem key={ele} value={ele}>
										{ele}
									</MenuItem>
								);
							})}
						</TextField>
					)}
				</div>
				<div className={styles.multi}>
					<div className={styles.tile}>
						<label
							className={styles.mandatory}
							htmlFor='invoiceRef'
						>
							Invoice Ref. No.*
						</label>
						<Input
							id='invoiceRef'
							value={state?.invoiceRef}
							className='w-[300px]'
							onChange={(e) => {
								dispatch({
									type: 'SET_VALUE',
									payload: {
										invoiceRef: e?.target?.value,
									},
								});
							}}
						/>
					</div>
					<div className={styles.tile}>
						<label
							className={styles.mandatory}
							htmlFor='invoiceDate'
						>
							Invoice Date*
						</label>
						<DatePickerCustom
							selectedDate={state?.invoiceDate}
							selectDate={(rsuiteDate) => {
								dispatch({
									type: 'SET_VALUE',
									payload: {
										invoiceDate: rsuiteDate,
									},
								});
							}}
							style={{
								width: 300,
							}}
							oneTap
							defaultValue={state?.invoiceDate}
							shouldDisableDate={disabledDate}
						/>
					</div>
				</div>
				<div className={styles.multi}>
					<div className={styles.tile}>
						<label
							className={styles.mandatory}
							htmlFor='invoiceAmount'
						>
							Invoice Amount*
						</label>
						<Input
							id='invoiceAmount'
							value={state?.invoiceAmount}
							type='number'
							step='.01'
							className='w-[300px]'
							onChange={(e) => {
								const value = e.target.value;
								if(value === ''){
									dispatch({
										type: 'SET_VALUE',
										payload: { invoiceAmount: value }
									});
								}
								const numValue = Number(value);
								if (!isNaN(numValue) && numValue > 0) {
									dispatch({
										type: 'SET_VALUE',
										payload: { invoiceAmount: value }
									});
								}
							}}
							/>
					</div>
					<div className={styles.tile}>
						<label
							className={styles.mandatory}
							htmlFor='description'
						>
							Notes*
						</label>
						<Textarea
							id='description'
							value={state?.description}
							className='w-[300px]'
							onChange={(e) => {
								dispatch({
									type: 'SET_VALUE',
									payload: {
										description: e?.target?.value,
									},
								});
							}}
						/>
					</div>
				</div>
				{/* {!!state?.poData?.finalValue && (
					<div className={styles.tile}>
						<label htmlFor='paymentTerm'>Total PO Amount</label>
						<p className={styles['order-id']}>
							₹
							{state?.poData?.finalValue}
						</p>
					</div>
				)} */}
			</div>
			{!isAdvance && (
				<div className={styles['items-details-container']}>
					<POItemSelection
						disabled={grnId || isEdit}
						state={state?.items}
						setState={setItemsState}
						defaultSelectedItems={orderItems}
						productsList={
							orderTypeQuery === 'CAPEX'
								? capexOrderItems
								: productsList
						}
						productWiseMappingData={productWiseMappingData}
						skuStockInfo={skuStockInfo}
						rowError={rowError}
						setRowError={setRowError}
						requiredFields={{
							fromStore: true,
							deliverTo: true,
							poId: true,
							date: true,
							expectedDeliveryDate: true,
							paymentTerms: false,
							generateStandardPO: false,
						}}
					/>
					<POTotalContainer
						taxPercentage={18}
						packagingCharges={packagingCharges}
						deliveryCharges={deliveryCharges}
						disableCharges={false}
						handleChange={(type, value) => {
							if (type === 'SET_DELIVERY_CHARGES') {
								dispatch({
									type: 'SET_VALUE',
									payload: {
										deliveryCharges: value,
									},
								});
							} else if (type === 'SET_PACKAGING_CHARGES') {
								dispatch({
									type: 'SET_VALUE',
									payload: {
										packagingCharges: value,
									},
								});
							}
						}}
						showCharges
						calculatedData={calculatedData}
						additionalCharges={state?.additionalCharges}
						handleAdditionalCharges={handleAdditionalCharges}
						showAdditionalCharge
						allowAddAdditional={false}
						allowRemoveAdditional={false}
					/>
				</div>
			)}
		</div>
	);
}

const mapStateToProps = (state) => {
	const { userPermissions } = state.navigation;
	const stores = state?.form?.FILTERED_STORES?.data?.LIST;
	const { LIST: processTypes } = state?.form?.PROCESS_TYPES?.data;
	const { LIST: productsList } = state.form.ALL_PRODUCTS.data;
	const { LIST: vendors } = state.form.ALL_VENDORS.data;
	return {
		userPermissions,
		stores,
		vendors,
		processTypes,
		productsList,
	};
};
// ['PRODUCT', 'INTERMEDIATE']

const mapDispatchToProps = {
	fetchProducts: formActions.gateway,
	toggleListModal: modalActions.toggleListModal,
	fetchStoresList: formActions.gateway,
	fetchVendors: formActions.gateway,
	toggleAlert: modalActions.toggleAlert,
	toggleAddVendorModal: modalActions.toggleAddVendorModal,
	fetchProcessTypesList: formActions.gateway,
	toggleFileUploaderModal: modalActions.toggleFileUploaderModal,
	togglePOItemsModal: modalActions.togglePOItemsModal,
	toggleGrnModal: modalActions.toggleGrnModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(BillsCreatePage);
