const modalConstants = {
	TOGGLE_MODAL: 'TOGGLE_MODAL',

	SHOW_PRODUCTS: 'SHOW_PRODUCTS',
	HIDE_PRODUCTS: 'HIDE_PRODUCTS',

	SHOW_SKUS: 'SHOW_SKUS',
	HIDE_SKUS: 'HIDE_SKUS',

	SHOW_ADDRESS: 'SHOW_ADDRESS',
	HIDE_ADDRESS: 'HIDE_ADDRESS',

	SHOW_ADD_APPLICABLE_STORES: 'SHOW_ADD_STORES',
	HIDE_ADD_APPLICABLE_STORES: 'HIDE_ADD_STORES',

	SHOW_ADD_APPLICABLE_USERS: 'SHOW_ADD_APPLICABLE_USERS',
	HIDE_ADD_APPLICABLE_USERS: 'HIDE_ADD_APPLICABLE_USERS',

	SHOW_ADD_APPLICABLE_PRODUCTS: 'SHOW_ADD-APPLICABLE_PRODUCTS',
	HIDE_ADD_APPLICABLE_PRODUCTS: 'HIDE_ADD_APPLICABLE_PRODUCTS',

	SHOW_ADD_APPLICABLE_PRODUCT_CATEGORIES:
		'SHOW_ADD_APPLICABLE_PRODUCT_CATEGORIES',
	HIDE_ADD_APPLICABLE_PRODUCT_CATEGORIES:
		'HIDE_ADD_APPLICABLE_PRODUCT_CATEGORIES',

	SHOW_ADD_NONAPPLICABLE_PRODUCT_CATEGORIES:
		'SHOW_ADD_NONAPPLICABLE_PRODUCT_CATEGORIES',
	HIDE_ADD_NONAPPLICABLE_PRODUCT_CATEGORIES:
		'HIDE_ADD_NONAPPLICABLE_PRODUCT_CATEGORIES',

	SHOW_ADD_NONAPPLICABLE_PRODUCTS: 'SHOW_ADD_APPLICABLE_PRODUCTS',
	HIDE_ADD_NONAPPLICABLE_PRODUCTS: 'HIDE_ADD_NONAPPLICABLE_PRODUCTS',

	SHOW_ALERT: 'SHOW_ALERT',
	HIDE_ALERT: 'HIDE_ALERT',

	SHOW_FILTER: 'SHOW_FILTER',
	HIDE_FILTER: 'HIDE_FILTER',

	SHOW_BRAND_FILTER: 'SHOW_BRAND_FILTER',
	HIDE_BRAND_FILTER: 'HIDE_BRAND_FILTER',

	SHOW_GEOGRAPHY_FILTER: 'SHOW_GEOGRAPHY_FILTER',
	HIDE_GEOGRAPHY_FILTER: 'HIDE_GEOGRAPHY_FILTER',

	SHOW_ADD_PINCODE: 'SHOW_ADD_PINCODE',
	HIDE_ADD_PINCODE: 'HIDE_ADD_PINCODE',

	SHOW_LIVE_MENU: 'SHOW_LIVE_MENU',
	HIDE_LIVE_MENU: 'HIDE_LIVE_MENU',

	SHOW_MENULIST: 'SHOW_MENULIST',
	HIDE_MENULIST: 'HIDE_MENULIST',

	SHOW_IMAGE_CROP: 'SHOW_IMAGE_CROP',
	HIDE_IMAGE_CROP: 'HIDE_IMAGE_CROP',

	SHOW_SPECIAL_MENU: 'SHOW_SPECIAL_MENU',
	HIDE_SPECIAL_MENU: 'HIDE_SPECIAL_MENU',

	SHOW_IMAGE_MODAL: 'SHOW_IMAGE_MODAL',
	HIDE_IMAGE_MODAL: 'HIDE_IMAGE_MODAL',

	SHOW_MAP_CREATOR_MODAL: 'SHOW_MAP_CREATOR_MODAL',
	HIDE_MAP_CREATOR_MODAL: 'HIDE_MAP_CREATOR_MODAL',

	SHOW_VAN_IMAGE_MODAL: 'SHOW_VAN_IMAGE_MODAL',
	HIDE_VAN_IMAGE_MODAL: 'HIDE_VAN_IMAGE_MODAL',

	SHOW_ALT_ALERT: 'SHOW_ALT_ALERT',
	HIDE_ALT_ALERT: 'HIDE_ALT_ALERT',

	SHOW_LIST_MODAL: 'SHOW_LIST_MODAL',
	HIDE_LIST_MODAL: 'HIDE_LIST_MODAL',

	SHOW_ADD_WITHDRAW_MODAL: 'SHOW_ADD_WITHDRAW_MODAL',
	HIDE_ADD_WITHDRAW_MODAL: 'HIDE_ADD_WITHDRAW_MODAL',

	SHOW_STOCK_LIST_MODAL: 'SHOW_STOCK_LIST_MODAL',
	HIDE_STOCK_LIST_MODAL: 'HIDE_STOCK_LIST_MODAL',

	SHOW_PO_ITEMS_MODAL: 'SHOW_PO_ITEMS_MODAL',
	HIDE_PO_ITEMS_MODAL: 'HIDE_PO_ITEMS_MODAL',

	SHOW_ITEMS_MODAL: 'SHOW_ITEMS_MODAL',
	HIDE_ITEMS_MODAL: 'HIDE_ITEMS_MODAL',

	SHOW_ORDER_ITEMS_MODAL: 'SHOW_ORDER_ITEMS_MODAL',
	HIDE_ORDER_ITEMS_MODAL: 'HIDE_ORDER_ITEMS_MODAL',

	SHOW_COOKING_ITEMS_MODAL: 'SHOW_COOKING_ITEMS_MODAL',
	HIDE_COOKING_ITEMS_MODAL: 'HIDE_COOKING_ITEMS_MODAL',

	SHOW_WORK_ORDER_ITEMS_MODAL: 'SHOW_WORK_ORDER_ITEMS_MODAL',
	HIDE_WORK_ORDER_ITEMS_MODAL: 'HIDE_WORK_ORDER_ITEMS_MODAL',

	SHOW_EDIT_CHILD_MODAL: 'SHOW_EDIT_CHILD_MODAL',
	HIDE_EDIT_CHILD_MODAL: 'HIDE_EDIT_CHILD_MODAL',

	SHOW_CONTAINER_MODAL: 'SHOW_CONTAINER_MODAL',
	HIDE_CONTAINER_MODAL: 'HIDE_CONTAINER_MODAL',

	SHOW_REPORTS_EMAILS_MODAL: 'SHOW_REPORTS_EMAILS_MODAL',
	HIDE_REPORTS_EMAILS_MODAL: 'HIDE_REPORTS_EMAILS_MODAL',

	SHOW_ORDERS_ITEMS_MODAL: 'SHOW_ORDERS_ITEMS_MODAL',
	HIDE_ORDERS_ITEMS_MODAL: 'HIDE_ORDERS_ITEMS_MODAL',

	SHOW_PROCESS_MODAL: 'SHOW_PROCESS_MODAL',
	HIDE_PROCESS_MODAL: 'HIDE_PROCESS_MODAL',

	SHOW_PRODUCTION_PLAN_MODAL: 'SHOW_PRODUCTION_PLAN_MODAL',
	HIDE_PRODUCTION_PLAN_MODAL: 'HIDE_PRODUCTION_PLAN_MODAL',

	SHOW_GENERATE_STANDARD_PO_MODAL: 'SHOW_GENERATE_STANDARD_PO_MODAL',
	HIDE_GENERATE_STANDARD_PO_MODAL: 'HIDE_GENERATE_STANDARD_PO_MODAL',

	SHOW_ADD_NEW_VENDOR_MODAL: 'SHOW_ADD_NEW_VENDOR_MODAL',
	HIDE_ADD_NEW_VENDOR_MODAL: 'HIDE_ADD_NEW_VENDOR_MODAL',

	SHOW_VENDOR_PRODUCT_MAP_MODAL: 'SHOW_VENDOR_PRODUCT_MAP_MODAL',
	HIDE_VENDOR_PRODUCT_MAP_MODAL: 'HIDE_VENDOR_PRODUCT_MAP_MODAL',

	SHOW_VENDOR_ORDER_GRNS_MODAL: 'SHOW_VENDOR_ORDER_GRNS_MODAL',
	HIDE_VENDOR_ORDER_GRNS_MODAL: 'HIDE_VENDOR_ORDER_GRNS_MODAL',

	SHOW_AUDIT_ITEMS_MODAL: 'SHOW_AUDIT_ITEMS_MODAL',
	HIDE_AUDIT_ITEMS_MODAL: 'HIDE_AUDIT_ITEMS_MODAL',

	SHOW_IMPORT_MODAL: 'SHOW_IMPORT_MODAL',
	HIDE_IMPORT_MODAL: 'HIDE_IMPORT_MODAL',

	SHOW_PRODUCTWISE_MAPPING_MODAL: 'SHOW_PRODUCTWISE_MAPPING_MODAL',
	HIDE_PRODUCTWISE_MAPPING_MODAL: 'HIDE_PRODUCTWISE_MAPPING_MODAL',

	SHOW_ADD_LICENSE_MODAL: 'SHOW_ADD_LICENSE_MODAL',
	HIDE_ADD_LICENSE_MODAL: 'HIDE_ADD_LICENSE_MODAL',

	SHOW_CHANGE_PASSWORD_MODAL: 'SHOW_CHANGE_PASSWORD_MODAL',
	HIDE_CHANGE_PASSWORD_MODAL: 'HIDE_CHANGE_PASSWORD_MODAL',

	SHOW_FILE_UPLOADER_MODAL: 'SHOW_FILE_UPLOADER_MODAL',
	HIDE_FILE_UPLOADER_MODAL: 'HIDE_FILE_UPLOADER_MODAL',

	SHOW_PAYMENT_MODAL: 'SHOW_PAYMENT_MODAL',
	HIDE_PAYMENT_MODAL: 'HIDE_PAYMENT_MODAL',

	SHOW_NAV_REQUISITES_MODAL: 'SHOW_NAV_REQUISITES_MODAL',
	HIDE_NAV_REQUISITES_MODAL: 'HIDE_NAV_REQUISITES_MODAL',

	SHOW_TRANSACTIONS_MODAL: 'SHOW_TRANSACTIONS_MODAL',
	HIDE_TRANSACTIONS_MODAL: 'HIDE_TRANSACTIONS_MODAL',

	SHOW_AUDIT_CREATION_MODAL: 'SHOW_AUDIT_CREATION_MODAL',
	HIDE_AUDIT_CREATION_MODAL: 'HIDE_AUDIT_CREATION_MODAL',

	SHOW_SEARCH_MODAL: 'SHOW_SEARCH_MODAL',
	HIDE_SEARCH_MODAL: 'HIDE_SEARCH_MODAL',

	SHOW_BULK_PAYMENT_MODAL: 'SHOW_BULK_PAYMENT_MODAL',
	HIDE_BULK_PAYMENT_MODAL: 'HIDE_BULK_PAYMENT_MODAL',

	SHOW_GROUP_MODAL: 'SHOW_GROUP_MODAL',
	HIDE_GROUP_MODAL: 'HIDE_GROUP_MODAL',

	SHOW_REGION_MENU_MODAL: 'SHOW_REGION_MENU_MODAL',
	HIDE_REGION_MENU_MODAL: 'HIDE_REGION_MENU_MODAL',

	SHOW_DYNAMIC_TABLE_MODAL: 'SHOW_DYNAMIC_TABLE_MODAL',
	HIDE_DYNAMIC_TABLE_MODAL: 'HIDE_DYNAMIC_TABLE_MODAL',

	SHOW_SHIFT_TABLE_MODAL: 'SHOW_SHIFT_TABLE_MODAL',
	HIDE_SHIFT_TABLE_MODAL: 'HIDE_SHIFT_TABLE_MODAL',

	SHOW_ERROR_MODAL: 'SHOW_ERROR_MODAL',
	HIDE_ERROR_MODAL: 'HIDE_ERROR_MODAL',

	SHOW_PAYMENT_DETAILS_MODAL: 'SHOW_PAYMENT_DETAILS_MODAL',
	HIDE_PAYMENT_DETAILS_MODAL: 'HIDE_PAYMENT_DETAILS_MODAL',

	SHOW_LEFT_OVER_ITEMS_MODAL: 'SHOW_LEFT_OVER_ITEMS_MODAL',
	HIDE_LEFT_OVER_ITEMS_MODAL: 'HIDE_LEFT_OVER_ITEMS_MODAL',

	SHOW_ADD_WASTAGE_REASON_MODAL: 'SHOW_ADD_WASTAGE_REASON_MODAL',
	HIDE_ADD_WASTAGE_REASON_MODAL: 'HIDE_ADD_WASTAGE_REASON_MODAL',

	SHOW_EDIT_NODE_MODAL: 'SHOW_EDIT_NODE_MODAL',
	HIDE_EDIT_NODE_MODAL: 'HIDE_EDIT_NODE_MODAL',

	SHOW_ADD_ASSET_CATEGORY_MODAL: 'SHOW_ADD_ASSET_CATEGORY_MODAL',
	HIDE_ADD_ASSET_CATEGORY_MODAL: 'HIDE_ADD_ASSET_CATEGORY_MODAL',

	SHOW_SPECIFICATION_MODAL: 'SHOW_SPECIFICATION_MODAL',
	HIDE_SPECIFICATION_MODAL: 'HIDE_SPECIFICATION_MODAL',

	SHOW_UPLOAD_DATA_MODAL: 'SHOW_UPLOAD_DATA_MODAL',
	HIDE_UPLOAD_DATA_MODAL: 'HIDE_UPLOAD_DATA_MODAL',

	SHOW_ASSET_TRANSIT: 'SHOW_ASSET_TRANSIT',
	HIDE_ASSET_TRANSIT: 'HIDE_ASSET_TRANSIT',

	SHOW_GRN_MODAL: 'SHOW_GRN_MODAL',
	HIDE_GRN_MODAL: 'HIDE_GRN_MODAL',

	SHOW_PRODUCT_PARAMETERS_MODAL: 'SHOW_PRODUCT_PARAMETERS_MODAL',
	HIDE_PRODUCT_PARAMETERS_MODAL: 'HIDE_PRODUCT_PARAMETERS_MODAL',

	SHOW_QUALITY_APPROVAL_MODAL: 'SHOW_QUALITY_APPROVAL_MODAL',
	HIDE_QUALITY_APPROVAL_MODAL: 'HIDE_QUALITY_APPROVAL_MODAL',

	SHOW_ADD_QUALITY_PARAMETER_MODAL: 'SHOW_ADD_QUALITY_PARAMETER_MODAL',
	HIDE_ADD_QUALITY_PARAMETER_MODAL: 'HIDE_ADD_QUALITY_PARAMETER_MODAL',
};

export const modalTypes = {
	PRODUCT_VARIANTS: 'PRODUCT_VARIANTS',
	TIME_SLOTS: 'TIME_SLOTS',
	COOKING_STAGE_DEPS: 'COOKING_STAGE_DEPS',
	EXPORT_COOKING_PROCESS: 'EXPORT_COOKING_PROCESS',
	ANNOTATION_LABELS: 'ANNOTATION_LABELS',
	RETAG: 'RETAG',
	DEFECT: 'DEFECT',
	DEFECT_IMAGE: 'DEFECT_IMAGE',
	// to be implemented
	PRODUCTS: 'PRODUCTS',
	ALERT: 'ALERT',
	SKUS: 'SKUS',
	ADDRESS: 'ADDRESS',
	APPLICABLE_STORES: 'APPLICABLE_STORES',
	APPLICABLE_USERS: 'APPLICABLE_USERS',
	APPLICABLE_PRODUCTS: 'APPLICABLE_PRODUCTS',
	APPLICABLE_PRODUCT_CATEGORIES: 'APPLICABLE_PRODUCT_CATEGORIES',
	NON_APPLICABLE_PRODUCT_CATEGORIES: 'NON_APPLICABLE_PRODUCT_CATEGORIES',
	NON_APPLICABLE_PRODUCTS: 'NON_APPLICABLE_PRODUCTS',
	PINCODES: 'PINCODES',
	LIVE_MENU: 'LIVE_MENU',
	MENU_LIST: 'MENU_LIST',
	GEO_FILTER: 'GEO_FILTER',
	BRAND_FILTER: 'BRAND_FILTER',
	IMAGE_CROP: 'IMAGE_CROP',
	SPECIAL_MENU: 'SPECIAL_MENU',
	IMAGE: 'IMAGE',
	ALT_ALERT: 'ALT_ALERT',
	LIST: 'LIST',
	ADD_WITHDRAW: 'ADD_WITHDRAW',
	UPLOAD_DATA: 'UPLOAD_DATA',
};

export default modalConstants;
