/* eslint-disable no-tabs */
import moment from 'moment';
// import React from 'react';
import { ListTable } from '@/Components/ListTable';
import { convertWeightUnit } from '@/Helpers';
import styles from './ScanWiseTable.module.scss';

const TABLE_COLUMNS = [
	{
		title: 'Activity Date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Opening Stock',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Total Stocked In',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Total Stocked Out',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Closing Stock',
		config: {
			type: 'DATA',
		},
	},
	// {
	// 	title: 'Stocked In (GRN)',
	// 	config: {
	// 		type: 'DATA',
	// 	},
	// },
	// {
	// 	title: 'Stocked In (Non-GRN)',
	// 	config: {
	// 		type: 'DATA',
	// 	},
	// },
	// {
	// 	title: 'Box Open (Stocked Out)',
	// 	config: {
	// 		type: 'DATA',
	// 	},
	// },
	// {
	// 	title: 'Box Open (Stocked In)',
	// 	config: {
	// 		type: 'DATA',
	// 	},
	// },
	// {
	// 	title: 'Audit Stocked Out',
	// 	config: {
	// 		type: 'DATA',
	// 	},
	// },
];

const COLMAP = {
	'Total Stocked In': 'totalStockIn',
	'Total Stocked Out': 'totalStockOut',
	'Opening Stock': 'openingStock',
	'Closing Stock': 'closingStock',
	'Activity Date': 'stockDate',
	// 'Stocked In (GRN)': 'grnStockedIn',
	// 'Stocked In (Non-GRN)': 'nonGrnStockedIn',
	// 'Box Open (Stocked Out)': 'boxOpenedStockedOut',
	// 'Box Open (Stocked In)': 'boxOpenedStockedIn',
	// 'Audit Stocked Out': 'auditStockedOut',
};

const DayWiseTable = (props) => {
	const { data, itemDetails } = props;

	const SPECIAL_ITEMS = {
		'Total Stocked In': (value) => {
			const unit = itemDetails?.unit ?? '';
			return `${convertWeightUnit(value, unit)}`;
		},
		'Total Stocked Out': (value) => {
			const unit = itemDetails?.unit ?? '';
			return `${convertWeightUnit(value, unit)}`;
		},
		// 'Stocked In (GRN)': (value) => {
		// 	const unit = itemDetails?.unit ?? '';
		// 	return `${convertWeightUnit(value, unit)}`;
		// },
		// 'Stocked In (Non-GRN)': (value) => {
		// 	const unit = itemDetails?.unit ?? '';
		// 	return `${convertWeightUnit(value, unit)}`;
		// },
		// 'Box Open (Stocked Out)': (value) => {
		// 	const unit = itemDetails?.unit ?? '';
		// 	return `${convertWeightUnit(value, unit)}`;
		// },
		// 'Box Open (Stocked In)': (value) => {
		// 	const unit = itemDetails?.unit ?? '';
		// 	return `${convertWeightUnit(value, unit)}`;
		// },
		// eslint-disable-next-line no-unused-vars
		'Closing Stock': (value) => {
			const unit = itemDetails?.unit ?? '';
			return `${convertWeightUnit(value, unit)}`;
		},
		'Opening Stock': (value) => {
			const unit = itemDetails?.unit ?? '';
			return `${convertWeightUnit(value, unit)}`;
		},
		// 'Audit Stocked Out': (value) => {
		// 	const unit = itemDetails?.unit ?? '';
		// 	return `${convertWeightUnit(value, unit)}`;
		// },
		'Activity Date': (activityDate) => {
			return (
				<div className='w-[150px]'>
					{moment(activityDate)?.format('YYYY-MM-DD')}
				</div>
			);
		},
	};

	return (
		<div className={styles.root}>
			<ListTable
				// allowExport={false}
				allowCheckList={false}
				// showBtnContainer={false}
				colMap={COLMAP}
				columns={TABLE_COLUMNS}
				data={data}
				special={{
					...SPECIAL_ITEMS,
				}}
				fixedHeaderTop={0}
			/>
		</div>
	);
};

export default DayWiseTable;
