/* eslint-disable no-unused-vars */
/* eslint-disable */
import { connect } from 'react-redux';
import { modalActions } from '../../../Actions';
import { BaseModal } from '../BaseModal';
import { Button } from '@/Components/ui/button';
import { useEffect, useState } from 'react';
import DragDropUploader from '@/Pages/Analytics/Inventory Analytics/UploadPurchaseOrder/DragDropUploader/DragDropUploader';
import { Label } from '@/Components/ui/label';
import { Input } from '@/Components/ui/input';

const GrnModal = (props) => {
	const {
		show,
		toggleGrnModal,
		data,
	} = props;
	const {
		grnId,
		grnAmount,
		invoiceAmount,
		gstin,
		vendorName,
		billingStore,
		orderId,
		orderDate,
		paymentTerm,
		grnDate,
		debitCreditData,
		delta,
		isEdit,
	} = data ?? {};
	const HEADING = `${data.heading}`;
	const [creditNoteValue , setCreditNotevalue] = useState(null);
	const [uploadedCreditFile, setUplodedCreditFile] = useState({});
	const handleClose = () => {
		toggleGrnModal(false);
		if(!isEdit){
			setCreditNotevalue(null);
			setUplodedCreditFile({});
		}
	};

	const handleConfirm = () => {
		if(data?.handleConfirm){
				data?.handleConfirm(creditNoteValue, uploadedCreditFile); 
		}
	};
	useEffect(() => {
		if (isEdit){
			setCreditNotevalue(debitCreditData?.creditValue);
			setUplodedCreditFile({file: debitCreditData?.creditDoc});
		}
	}, [isEdit, debitCreditData]);
	const buttons = () => {
		return (
			<div className='w-full flex gap-2'>
				<Button
					onClick={handleClose}
					variant='outline'
					className='flex-1'
				>
					Cancel
				</Button>
				<Button
					onClick={handleConfirm}
					variant={!data?.colorReverse && 'destructive'}
					className={`flex-1 ${
						data?.colorReverse ? 'bg-uy-color' : ''
					}`}
					// color={data?.colorReverse ? 'error' : 'success'}
				>
					Generate Bill
				</Button>
			</div>
		);
	};
	const uploadSupportDoc = () => {
		setUplodedCreditFile({});
	};
	return (
		<BaseModal
			heading={HEADING}
			height={data?.height ?? 'fit-content'}
			renderBtns={buttons}
			open={show}
			handleClose={handleClose}
			width={data?.width}
		>
			<div className='flex gap-3 w-full h-[480px]'>
				<div className='w-2/5'>
					{uploadedCreditFile?.file ? (
						<div className='flex flex-col gap-3 h-full'>
							<iframe
								width='100%'
								height='100%'
								src={uploadedCreditFile?.file}
								title='PDF Rendered from Base64'
							/>
							<Button
								type='button'
								variant='outline'
								color='primary'
								onClick={uploadSupportDoc}
							>
								Re-Upload Doc
							</Button>
						</div>
					) : (
						<DragDropUploader
							uploadType='pdfFile'
							uploadedFile={uploadedCreditFile}
							setUploadedFile={setUplodedCreditFile}
							fileAcceptable='.pdf, application/pdf'
						/>
					)}
				</div>
				<div className='flex flex-col gap-3 w-3/5'>
					<div className='w-full flex gap-6 bg-[rgba(255,184,3,0.204)] p-4 items-center'>
						{/* Left Column */}
						<div className='text-start space-y-3'>
							<div className='text-sm grid grid-cols-2  gap-1 p-2'>
								<p className='m-0 '>Order Id:</p>{' '}
								<p className='m-0 font-bold'>{orderId}</p>
							</div>
							<div className='text-sm grid grid-cols-2  gap-1 p-2'>
								<p className='m-0 '>GRN Id:</p>{' '}
								<p className='m-0 font-bold'>{grnId}</p>
							</div>
							<div className='text-sm grid grid-cols-2  gap-1 p-2'>
								<p className='m-0'>Vendor Name:</p>{' '}
								<p className='m-0 font-bold'>{vendorName}</p>
							</div>
							<div className='text-sm grid grid-cols-2  gap-1 p-2'>
								<p className='m-0'>Vendor GSTIN:</p>{' '}
								<p className='m-0 font-bold'>{gstin}</p>
							</div>
						</div>

						{/* Right Column */}
						<div className='text-start  space-y-3 '>
							<div className='text-sm grid grid-cols-2  gap-1 p-2'>
								<p className='m-0'>Billing Store:</p>{' '}
								<p className='m-0 font-bold'>{billingStore}</p>
							</div>
							<div className='text-sm grid grid-cols-2  gap-1 p-2'>
								<p className='m-0'>GRN Date:</p>{' '}
								<p className='m-0 font-bold'>{grnDate}</p>
							</div>
							<div className='text-sm grid grid-cols-2  gap-1 p-2'>
								<p className='m-0'>Order Date:</p>{' '}
								<p className='m-0 font-bold'>{orderDate}</p>
							</div>
							<div className='text-sm grid grid-cols-2  gap-1 p-2'>
								<p className='m-0'>Payment Term:</p>{' '}
								<p className='m-0 font-bold'>{paymentTerm}</p>
							</div>
						</div>
					</div>
					<div className='flex justify-between items-center p-4 bg-gray-50 rounded-lg'>
						<div className='flex justify-between  items-center w-full'>
							<div className='flex flex-col flex-1 items-start pl-2'>
								<span className='text-sm text-gray-600'>Vendor Invoice Amount</span>
								<div className='flex items-baseline gap-1'>
									<span className='text-lg font-semibold'>₹ {invoiceAmount}</span>
									<span className='text-xs text-gray-500'>(Inc Tax)</span>
								</div>
							</div>

							<div className='h-12 w-[1px] bg-gray-300'></div>

							<div className='flex flex-1 flex-col items-start pl-2'>
								<span className='text-sm text-gray-600'>Current GRN Amount</span>
								<div className='flex items-baseline gap-1'>
									<span className='text-lg font-semibold'>₹ {grnAmount}</span>
									<span className='text-xs text-gray-500'>(Inc Tax)</span>
								</div>
							</div>
						</div>
					</div>
					{/* input feild */}
					<div className='w-full flex flex-col gap-2 p-4 bg-gray-50 rounded-lg'>
						<div className='flex items-center gap-2'>
							<Label className='text-gray-700 font-medium'>Enter Credit / Debit Note Amount</Label>
							<span className='text-xs text-red-500'>(Amount should match delta {Number(delta).toFixed(2)}₹ value)</span>
						</div>
						<div className='flex gap-2 items-center'>
							<Input
								type='number'
								className='w-[300px]'
								placeholder='Enter amount...'
								value={creditNoteValue}
								onChange={(e) => setCreditNotevalue(e.target.value)}
							/>
							<span className='text-sm text-gray-500'>₹ (inc tax)</span>
						</div>
					</div>
				</div>
			</div>
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.grnModal;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleGrnModal: modalActions.toggleGrnModal,
	toggleFileUploaderModal: modalActions.toggleFileUploaderModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(GrnModal);
