import { endpoints } from '../../Constants';
import { authHeader, toQueryStringURL } from '../../Helpers';
import handleResponse from '../helpers';

const inventoryAnalyticsServices = {
	gateway,
	liveIMS,
	fetchInventoryAnalysisGraph,
	uploadPurchaseOrder,
	fetchOrdersType,
	fetchWorkOrderItems,
	changeOrderStatus,
	changeIndentOrderStatus,
	combinePurchaseOrder,
	fetchSalesOrderStores,
	fetchPurchaseOrder,
	generateProductionPlan,
	fetchProductionPlan,
	addVendorPO,
	editVendorPO,
	generateGrn,
	fetchStandardPO,
	fetchPoGrns,
	fetchPo,
	fetchPOId,
	fetchProductionPlans,
	fetchInvTransRecord,
	fetchInvTransDoc,
	fetchS3Doc,
	fetchPoPdfDoc,
	skuStock,
	approveInvoiceAndBill,
	createTransactionRecord,
	createCreditNote,
	updateInventoryTransaction,
	getTransactionRecord,
	fetchCategoryList,
	getVendorDetails,
	uploadVendorInvoice,
	fetchWoPdfZip,
	editTransactionRecord,
	scanWiseSkuTrail,
	dayWiseSkuTrail,
	fetchDayEndReport,
};

function gateway({ req, method }) {
	switch (method) {
		case 'STOCK_IN_LIST':
			return stockInList(req);
		case 'STOCK_OUT_LIST':
			return stockOutList(req);
		case 'SKU_STOCK':
			return skuStock(req);
		case 'CHART_DATA':
			return fetchInventoryAnalysisGraph({
				req,
			});
		case 'PO_LIST':
		case 'VENDOR_ORDERS_LIST':
		case 'FMS_ORDERS_LIST':
			return fetchPoList({
				req,
			});
		case 'INDENT_LIST':
			return fetchIndentList({
				req,
			});
		case 'WORK_ORDER_LIST':
			return fetchWorkOrderList({
				req,
			});
		default:
			break;
	}
	return new Promise();
}

function fetchDayEndReport(req) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.DAY_END_REPORT, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}
function stockInList(req) {
	const { params = {} } = req;

	const url = toQueryStringURL(endpoints.INVENTORY_STOCK_IN_LIST, params);

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	// return mockIMSList(requestOptions, url);
	return fetch(url, requestOptions).then(handleResponse);
}

function stockOutList(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(endpoints.INVENTORY_STOCK_OUT_LIST, requestOptions).then(
		handleResponse
	);
}

function skuStock(req) {
	const { params = {} } = req;
	const url = toQueryStringURL(endpoints.SKU_STOCK, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function scanWiseSkuTrail(req, type = null) {
	const { params = {} } = req;
	const url = toQueryStringURL(type === 'PROCESS_REPORT' ? endpoints.PROCESS_SKU_TRAIL_SCAN_WISE : endpoints.SKU_TRAIL_SCAN_WISE, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}
function dayWiseSkuTrail(req, type = null) {
	const { params = {} } = req;
	const url = toQueryStringURL(type === 'PROCESS_REPORT' ? endpoints.PROCESS_SKU_TRAIL_DAY_WISE : endpoints.SKU_TRAIL_DAY_WISE, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function liveIMS(req) {
	const { params } = req;

	const url = toQueryStringURL(endpoints.LIVE_IMS, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};

	return fetch(url, requestOptions).then(handleResponse);
}

function fetchInventoryAnalysisGraph({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};

	return fetch(endpoints.INVENTORY_ANALYTICS, requestOptions).then(
		handleResponse
	);
}

function fetchPoList({ req }) {
	const params = {
		...req.params,
	};
	const url = toQueryStringURL(endpoints.PO_LIST, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};

	return fetch(url, requestOptions).then(handleResponse);
}
function fetchPo(poId) {
	const params = {
		poId,
	};
	const url = toQueryStringURL(endpoints.GET_PO, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};

	return fetch(url, requestOptions).then(handleResponse);
}
function fetchPOId() {
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};

	return fetch(endpoints.TEMP_PO_ID, requestOptions).then(handleResponse);
}
function fetchPurchaseOrder(req) {
	const params = {
		...req.params,
	};
	const url = toQueryStringURL(endpoints.SINGLE_PURCHASE_ORDER, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};

	return fetch(url, requestOptions).then(handleResponse);
}
function fetchProductionPlans(req) {
	const params = {
		...req.params,
	};
	const url = toQueryStringURL(endpoints.SINGLE_PURCHASE_ORDER, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};

	return fetch(url, requestOptions).then(handleResponse);
}
function addVendorPO(req) {
	const params = {
		...req?.params,
	};
	const url = toQueryStringURL(endpoints.ADD_VENDOR_PO, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	return fetch(url, requestOptions).then(handleResponse);
}
function editVendorPO(req) {
	const params = {
		...req.params,
	};
	const url = toQueryStringURL(endpoints.EDIT_PO, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	return fetch(url, requestOptions).then(handleResponse);
}
function generateProductionPlan(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	return fetch(endpoints.GENERATE_PRODUCTION_PLAN, requestOptions).then(
		handleResponse
	);
}
function fetchProductionPlan(req) {
	// eslint-disable-next-line no-unused-vars
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	return fetch(endpoints.INITIATE_PRODUCTION_PLAN, requestOptions).then(
		handleResponse
	);
}

function fetchStandardPO(req) {
	const { params = {} } = req ?? {};
	const url = toQueryStringURL(endpoints.GENERATE_STANDARD_PO, params);
	// eslint-disable-next-line no-unused-vars
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};

	return fetch(url, requestOptions).then(handleResponse);
}
function fetchPoGrns(req) {
	const { params = {} } = req ?? {};
	const url = toQueryStringURL(endpoints.FETCH_PO_GRNS, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};

	return fetch(url, requestOptions).then(handleResponse);
	// return mockResponse(url, requestOptions);
}
// eslint-disable-next-line no-unused-vars
function mockResponse() {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve({
				statusCode: 200,
				message: 'FETCHED',
				data: [
					{
						id: 1,
						grnId: 'UY-GRN-868',
						startTime: new Date('2023-11-01 13:44')?.toString(),
						endTime: new Date()?.toString(),
						items: [
							{
								brandId: 23,
								categoryIcon: '',
								categoryId: 135,
								categoryName: 'Temporary Category',
								cookingBatches: [],
								createdAt: 'Tue, 10 Oct 2023 16:08:57 GMT',
								customerId: 5,
								discountedValue: 0,
								finalValue: 120,
								fromProcess: null,
								fulfilled: 1400,
								id: 16167,
								idealWeight: 0,
								imageCheckRequired: null,
								isCookingEnabled: null,
								maxTemp: 100,
								maxWeight: 1050,
								minTemp: 0,
								minWeight: 1000,
								packaged: 0,
								packagingCharges: 0,
								poId: 868,
								productCost: 10,
								productId: 1492,
								productImageUrl:
									'https://resoeepublic.s3.ap-south-1.amazonaws.com/dummny+icon.png',
								productName: 'butterscotch pastry',
								productTaxValue: 0,
								qty: 12000,
								statusCode: 0,
								storeFulfilled: 0,
								taxPercentage: 0,
								tempCheckRequired: false,
								toProcess: null,
								tonnage: 12000,
								totalValue: 120,
								unit: 'gram',
								updatedAt: 'Tue, 10 Oct 2023 16:11:33 GMT',
								variantId: 1916,
								variantName: 'FULL',
								weightCheckRequired: false,
							},
							{
								brandId: 23,
								categoryIcon: '',
								categoryId: 58,
								categoryName: 'Food Product',
								cookingBatches: [],
								createdAt: 'Sat, 14 Oct 2023 12:52:45 GMT',
								customerId: 5,
								discountedValue: 0,
								finalValue: 0,
								fromProcess: null,
								fulfilled: 0,
								id: 16193,
								idealWeight: 0,
								imageCheckRequired: null,
								isCookingEnabled: null,
								maxTemp: 110,
								maxWeight: 10000,
								minTemp: 0,
								minWeight: 0,
								packaged: 0,
								packagingCharges: 0,
								poId: 872,
								productCost: 0,
								productId: 1479,
								productImageUrl:
									'https://resoee.s3.amazonaws.com/5/ProductCategory/AK%20Blackforest%20Base-14-09-23_13%3A58%3A08.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20231102%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20231102T053601Z&X-Amz-Expires=172800&X-Amz-SignedHeaders=host&X-Amz-Signature=a362b9e7911a0eef00d3e37e5ec273e57ef20b6e6f9a73dfc216e7cb8d90ddba',
								productName: 'AK Blackforest Base',
								productTaxValue: 0,
								qty: 1,
								statusCode: 100,
								storeFulfilled: 0,
								taxPercentage: 0,
								tempCheckRequired: false,
								toProcess: null,
								tonnage: 0,
								totalValue: 0,
								unit: 'count',
								updatedAt: 'Sat, 14 Oct 2023 12:52:45 GMT',
								variantId: 1892,
								variantName: '1Kg',
								weightCheckRequired: false,
							},
						],
					},
				],
			});
		}, 1500);
	});
}

function combinePurchaseOrder({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	return fetch(endpoints.PO_COMBINE, requestOptions);
}

function fetchIndentList({ req }) {
	const params = {
		...req.params,
	};
	// eslint-disable-next-line no-unused-vars
	const url = toQueryStringURL(endpoints.INDENT_LIST, params);
	// eslint-disable-next-line no-unused-vars
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};

	return fetch(url, requestOptions).then(handleResponse);
	// return {
	// message: 'Fetched successfully',
	// status: 'Success',
	// statusCode: 200,
	// data: [
	// {
	// clientIndentId: 'Indent-123',
	// scheduledAt: '30-08-2023',
	// storeName: 'Pluckk bangalore base kitchen',
	// indentStoreName: 'Pluckk bangalore base kitchen',
	// orderStatus: 'UPLOADED',
	// },
	// ],
	// };
}

function fetchWorkOrderList({ req }) {
	const params = {
		...req.params,
	};
	const url = toQueryStringURL(endpoints.WORK_ORDER_LIST, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req.data,
		}),
	};
	return fetch(url, requestOptions).then(handleResponse);
	// return {
	// message: 'Updated successfully',
	// status: 'Success',
	// statusCode: 200,
	// data: [
	// {
	// assetQrGenerated: true,
	// clientPoId: 'QWERTY2',
	// createdAt: 'Thu, 25 May 2023 15:18:27 GMT',
	// customerId: 5,
	// deliveryChallanURL: null,
	// deliveryCharges: 0,
	// fromStore: 34,
	// fromStoreName: 'Bakingo Bangalore Base Kitchen',
	// grnURL: null,
	// id: 245,
	// itemPackagingCharges: 0,
	// orderStatus: 'TRANSIT',
	// orderValue: 0,
	// packagingCharges: 0,
	// paymentStatus: null,
	// paymentTxnId: null,
	// paymentTxnToken: null,
	// paymentType: null,
	// paymentterminalstatusDate: null,
	// scheduledAt: '26/05/2023',
	// storeId: 17,
	// storeName: 'Sri Kumaran Public School - ICSE',
	// taxCharges: 0,
	// totalDiscount: 0,
	// updatedAt: 'Fri, 26 May 2023 12:50:22 GMT',
	// vendorId: null,
	// vendorName: 'Test',
	// },
	// ],
	// };
}

function fetchWorkOrderItems() {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve({
				message: 'Updated successfully',
				status: 'Success',
				statusCode: 200,
				data: [
					{
						id: 3654,
						productId: 884,
						productName: 'Butterscotch Cake',
						qty: 60,
						variantId: 1231,
						variantName: '1kg',
						duration: '1 hour',
						batches: [
							{
								displayName: 'ButterScotch Cake Batch 1',
								startedAt: '2023-05-26 17:50:20',
								completedAt: '2023-05-26 18:10:15',
								recipeId: 11,
								status: 'progress',
								duration: '30 mins',
								qty: 30,
							},
							{
								displayName: 'ButterScotch Cake Batch 2',
								startedAt: '2023-05-26 17:50:20',
								completedAt: '2023-05-26 18:10:15',
								recipeId: 12,
								status: 'completed',
								duration: '30 mins',
								qty: 30,
							},
						],
						status: 'progress',
					},
					{
						id: 3655,
						productId: 884,
						productName: 'Butterscotch Cake',
						qty: 30,
						variantId: 1231,
						variantName: '1kg',
						batches: [
							{
								displayName: 'ButterScotch Cake Batch 1',
								startedAt: '2023-05-26 17:50:20',
								completedAt: '2023-05-26 18:10:15',
								recipeId: 14,
								status: 'progress',
								duration: '30 mins',
								qty: 15,
							},
							{
								displayName: 'ButterScotch Cake Batch 1',
								startedAt: '2023-05-26 17:50:20',
								completedAt: '2023-05-26 18:10:15',
								recipeId: 15,
								status: 'progress',
								duration: '30 mins',
								qty: 15,
							},
						],
						status: 'Completed',
					},
				],
			});
		}, 2000);
	});
}

function uploadPurchaseOrder({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			// 'Content-Type': 'application/json',
		},
		body: req?.formData,
	};

	return fetch(endpoints.INVENTORY_PO_UPLOAD, requestOptions).then(
		handleResponse
	);
}
function createTransactionRecord(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	return fetch(endpoints.CREATE_INVENTORY_TRANSACTION_RECORD, requestOptions).then(handleResponse);
}
function editTransactionRecord(req) {
	const { params = {} } = req;

	const url = toQueryStringURL(
		endpoints.EDIT_INVENTORY_TRANSACTION_RECORD,
		params
	);

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	return fetch(url, requestOptions).then(handleResponse);
}
function updateInventoryTransaction(req) {
	const params = {
		...req?.params,
	};
	const url = toQueryStringURL(endpoints.UPDATE_INVENTORY_TRANSACTION_RECORD, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	return fetch(url, requestOptions).then(handleResponse);
}
function getVendorDetails(req) {
	const params = {
		...req?.params,
	};
	const url = toQueryStringURL(
		endpoints.VENDOR_DETAILS_INVOICE_UPLOAD,
		params
	);
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	return fetch(url, requestOptions).then(handleResponse);
}
function uploadVendorInvoice(req) {
	const params = {
		...req?.params,
	};
	const url = toQueryStringURL(
		endpoints.UPLOAD_VENDOR_INVOICE,
		params
	);
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	return fetch(url, requestOptions).then(handleResponse);
}
function createCreditNote(req) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	return fetch(endpoints.CREATE_CREDIT_NOTE, requestOptions).then(handleResponse);
}
function approveInvoiceAndBill({ req }) {
	const params = {
		...req.params,
	};
	const url = toQueryStringURL(endpoints.APPROVE_INVOICE_AND_BILL, params);
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	return fetch(url, requestOptions).then(handleResponse);
}

function fetchOrdersType() {
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};

	return fetch(endpoints.FETCH_UPLOAD_TYPES, requestOptions).then(
		handleResponse
	);
}

function fetchSalesOrderStores() {
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};

	return fetch(endpoints.FETCH_SALES_ORDER_STORES, requestOptions).then(
		handleResponse
	);
}

function fetchInvTransRecord({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	return fetch(endpoints.FETCH_INVENTORY_TRANSACTION_RECORD, requestOptions).then(
		handleResponse
	);
}
function fetchCategoryList({ req }) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...req?.data,
		}),
	};

	return fetch(endpoints.FETCH_CATEGORY_LIST, requestOptions).then(
		handleResponse
	);
}

function fetchWoPdfZip(req) {
	const url = endpoints.FETCH_WO_PDF_ZIP;
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			clientId: 5,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(req),
	};

	return fetch(url, requestOptions).then(handleResponse);
}
function fetchPoPdfDoc({ req }) {
	const params = {
		...req?.params,
	};
	const url = toQueryStringURL(endpoints.FETCH_PO_PDF_DOC, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};

	return fetch(url, requestOptions).then(
		handleResponse
	);
}
function fetchInvTransDoc({ req }) {
	const params = {
		...req?.params,
	};
	const url = toQueryStringURL(endpoints.FETCH_INV_TRANS_DOC, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};

	return fetch(url, requestOptions).then(
		(res) => { return res.json(); }
	);
}
function fetchS3Doc(data) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			...data,
		}),
	};

	return fetch(endpoints.FETCH_S3_DOC, requestOptions).then(
		(res) => { return res.json(); }
	);
}
function getTransactionRecord(req) {
	const params = {
		...req?.params,
	};
	const url = toQueryStringURL(endpoints.GET_TRANSACTION_RECORD, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};

	return fetch(url, requestOptions).then(
		handleResponse
	);
}

function changeOrderStatus(req) {
	const params = {
		...req.params,
	};
	const url = toQueryStringURL(endpoints.PO_CHANGE_STATUS, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
		// body: JSON.stringify({
		// ...req.data,
		// }),
	};

	return fetch(url, requestOptions).then(handleResponse);
}
function generateGrn(req) {
	const params = {
		...req.params,
	};
	const url = toQueryStringURL(endpoints.GENERATE_GRN, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};

	return fetch(url, requestOptions).then(handleResponse);
}

function changeIndentOrderStatus(req) {
	const params = {
		...req.params,
	};
	const url = toQueryStringURL(endpoints.INDENT_CHANGE_STATUS, params);
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
		},
	};

	return fetch(url, requestOptions).then(handleResponse);
}

// function mockIMSList() {
// const mockResponse = {
// data: [
// {
// annotated: null,
// brand: 88,
// brandImage: null,
// categoryName: 'ABC',
// expiryTime: '02-06-2022',
// id: 903,
// identification: [
// {
// height: null,
// label: 'Product not identifiable',
// labelId: null,
// width: null,
// x: null,
// y: null,
// },
// ],
// isExpired: true,
// isStockedOut: false,
// isTempinRange: null,
// isVeg: false,
// isWeightInRange: false,
// maxTemp: 1100,
// maxWeight: 1100,
// minTemp: 900,
// minWeight: 900,
// qrCode: '310422191535',
// referenceImage:
// 'https://resoee.s3.amazonaws.com/6/ProductCategory/Achari%20Chaap-20-04-22_18%3A43%3A45.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220722%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220722T050358Z&X-Amz-Expires=172800&X-Amz-SignedHeaders=host&X-Amz-Signature=943f47dd65e073e6e64f4ae7fabc11f666a0820219d4c370980029e400667883',
// result: 'FAILED',
// skuId: 476,
// skuImage:
// 'https://resoee.s3.amazonaws.com/6/Inventory/210422191535-21-04-22_19%3A15%3A35.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIXPQPFCFMBNVR5AA%2F20220722%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220722T050358Z&X-Amz-Expires=172800&X-Amz-SignedHeaders=host&X-Amz-Signature=15794fde821b10010fe928540d2d1fba7a2e2f3411d50921b3d9b410f06fa1f6',
// skuName: 'Boiled Rice',
// stockedInAt: '21-04-2022 19:15',
// stockedOutAt: null,
// store: 36,
// temp: 25,
// tempCheckRequired: false,
// updatedStockIn: '0.92 kgs',
// updatedStockOut: '-',
// validation: false,
// variantId: 916,
// variantName: 'FULL',
// weight: 1000000,
// weightCheckRequired: false,
// temperature: {
// sensorValues: [
// {
// x: 150,
// y: 240,
// temperature: 21.2,
// width: 50,
// height: 50,
// },
// {
// x: 250,
// y: 240,
// temperature: 30.2,
// width: 50,
// height: 50,
// },
// {
// x: 350,
// y: 240,
// temperature: 45.2,
// width: 50,
// height: 50,
// },
// {
// x: 450,
// y: 240,
// temperature: 10.2,
// width: 50,
// height: 50,
// },
// {
// x: 590,
// y: 430,
// temperature: 10.2,
// width: 50,
// height: 50,
// },
// ],
// },
// },
// ],
// };

// return new Promise((resolve) => {
// setTimeout(() => {
// resolve(mockResponse);
// }, 1000);
// });
// }

export default inventoryAnalyticsServices;
