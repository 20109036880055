/* eslint-disable */
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { formActions, modalActions } from '../../../Actions';
import { FormSectionHeader } from '../../../Components/Forms';
import { GeneralDetails } from '../../../Components/Forms/ProductCategory/GeneralDetails';
import { CreatePageHeader } from '../../../Components/Headers';
import { ImageCropModal } from '../../../Components/Modals/ImageCropModal';
import { urls } from '../../../Constants';
import { inputHelper, isEmpty } from '../../../Helpers';
// eslint-disable-next-line object-curly-newline
import ProductsConfig from './ProductCategory.config';
import { useAuth } from '../../../Components/Auth/Login/auth';
import { notificationServices } from '../../../Services';
import DragDropImage from '@/Components/DragDropImage/dragDropImage';
import ProductParametersModal from '@/Components/Modals/ProductParametersModal/productParametersModal';
import QualityCheck from '@/Components/AddProductParameters/qualityCheck';
import AddParameterComp from '@/Components/AddProductParameters/addParameterComp';
import { Label } from '@/Components/ui/label';
import { Switch } from '@mui/material';
import masterMenuServices from '@/Services/ProductSpecificationParamServices/productMasterMenu.services';
import QualityConfig from '@/Components/AddProductParameters/qualityConfig';
import productCategoryServcies from '@/Services/Form Services/productCategoryServcies';
import AddParameterModal from '@/Components/Modals/ProductParametersModal/AddParameterModal';


const ProductCreatePage = (props) => {
	const { appConfig = {} } = useAuth();
	const { measurement } = appConfig?.configs ?? {};
	const {
		isEdit,
		saveProductCategory,
		data,
		fetchProductCategory,
		breadcrumbs,
		toggleImageCrop,
		toggleProductParametersModal,
		toggleAddParameterModal,
	} = props;

	const disabledUnits =
		measurement === 'US' ? ['gram', 'litre', 'ml'] : ['oz', 'floz'];
	const [state, setState] = useState({
		...ProductsConfig.initialState,
		fields: {
			...ProductsConfig.initialState?.fields,
			unit: ProductsConfig.initialState?.fields?.unit?.filter(
				(ele) => {
					return !disabledUnits?.includes(ele?.id);
				}
			),
		},
	});
	const [productCategoryData, setProductCategoryData] = useState({});
	const [numberOfSubmissions, setNumberOfSubmissions] = useState(0);
	const [unitExist, setUnitExist] = useState(true);
	const [selectedParameters, setSelectedParameters] = useState([]);
	const [newList, setNewList] = useState([]);
	const [trueFetchProductParameters, settrueFetchProductParameters] = useState(false);
	const [qualityCheck, setQualityCheck] = useState({
		qualityCheckEnabled : true,
		categoryConfiguration : false,
		minSamplingQuantity: 1,
		weightCheck: false,
		temperatureCheck: {
			enabled : false,
			minTemp: 0,
			maxTemp: 0,
		},
		defectCheck: {
			enabled : false,
			areaThreshold: 0,
			countThreshold: 0,
			reject: 'both',
		},
	});

    useEffect(() => {
        setNewList((prevList) => {
            const mergedList = [...prevList, ...selectedParameters];
            const uniqueList = mergedList.filter((item, index, self) =>
                index === self.findIndex((t) => t.id === item.id)
            );
    
            return uniqueList;
        });
    }, [selectedParameters]);

	const ProductCategoryId = Number(useParams().id);
	const history = useHistory();
	const { fields, errors } = state;

	const fetchProductCategoryData = async () => {
		try{
			const response = await productCategoryServcies.viewProductCategory({
			req: {
				params: { id: ProductCategoryId },
			},
		});
		if(response?.statusCode === 200){
			setProductCategoryData(response?.data);
		}else{
			throw new Error('Failed to fetch Product Category data.');
		}
		}catch(error){
		notificationServices.generateNotification({
			type: 'error',
			message: error?.message,
		});
		}
	};
	useEffect(() => {
		if (isEdit) {
			fetchProductCategoryData();
			// if (!isEmpty(data) && data.id === ProductCategoryId) {
			// 	setUnitExist(!!data?.unit);
			// 	setState(ProductsConfig.serializer(state, {
			// 		...data, measurement,
			// 	}));
			// } else {
			// 	fetchProductCategory({
			// 		entity: 'PRODUCT_CATEGORY',
			// 		req: {
			// 			params: {
			// 				id: ProductCategoryId,
			// 			},
			// 		},
			// 		method: 'VIEW',
			// 	});
			// }
		}
	}, []);

	useEffect(() => {
		const newState = {};
		if (isEdit) {
			if (!isEmpty(productCategoryData) && productCategoryData.id === ProductCategoryId) {
				setUnitExist(!!productCategoryData?.unit);
				setState(ProductsConfig.serializer(state, {
					...productCategoryData, measurement,
				}));
			newState.qualityCheckEnabled = productCategoryData?.QualityAnalysisEnabled;
			newState.minSamplingQuantity = productCategoryData?.minimumSamplingQuantityPercentage;
			newState.maxScanCount = productCategoryData?.maxScanCount;
			setQualityCheck(newState);
			setNewList(productCategoryData?.qualityParameters);
		}
	}
	}, [productCategoryData]);

	const generateDefaultOption = (type, brand) => {
		switch (type) {
			case 'brand':
				if (!brand || brand.length === 0) {
					return (
						<option value='' selected disabled hidden>
							Select a Brand
						</option>
					);
				}
				break;
			default:
				break;
		}
		return <></>;
	};
	const handleInput = (e) => {
		const { fieldName, fieldValue, dataset } = inputHelper(e);
		let error = '';
		const newState = {};
		switch (fieldName) {
			case 'Name':
				if (fieldValue.length === 0) {
					error = 'This field is required';
				} else {
					error = '';
				}
				errors[fieldName] = error;
				break;
			case 'unit':
				newState[fieldName] = state.fields.unit.map(
					(productUnitObject, idx) => {
						if (idx === parseInt(dataset.id, 10)) {
							newState.perUnit = productUnitObject?.id === 'floz' ? 'Gal' : 'ltr';
							return {
								...productUnitObject,
								isApplied: fieldValue,
							};
						}
						return {
							...productUnitObject,
							isApplied: false,
						};
					}
				);
				break;
			default:
				break;
		}
		setState({
			...state,
			fields: {
				...state.fields,
				[fieldName]: fieldValue,
				...newState,
			},
			...errors,
		});
	};	

	const handleEdit = async () => {
		const req = {};
		const productCategory = ProductsConfig.deserializer(state);
		try{
		req.params = {
			id: ProductCategoryId,
		}
		req.data = {
			...productCategory,
			QualityAnalysisEnabled : qualityCheck?.qualityCheckEnabled,
			minimumSamplingQuantityPercentage : qualityCheck?.minSamplingQuantity,
			parameterChecks:  newList,
		}
		const response = await productCategoryServcies.updateProductCategory({req});
		if(response?.statusCode === 200){
			notificationServices.generateNotification({
				type: 'success',
				message: 'Product Category successfully updated!',
			});
			history.push(`${urls.EXTENSION}${urls.PRODUCT_CATEGORY}`);
		}else{
			throw new Error('Failed to update Product Category.');
		}
		}catch(error){
			notificationServices.generateNotification({
				type: 'error',
				message: error?.message,
			});
		}
	};

	const handleSave = async (e) => {
		e.preventDefault();
		const req = {};
		try{
		const productCategory = ProductsConfig.deserializer(state);
		if (!productCategory.unit || productCategory.unit === '') {
			notificationServices.generateNotification({
				type: 'error',
				message: 'No unit selected',
			});
			return;
		}

		if (newList.length > 0) {
			const hasInvalidParameter = newList.some(param => {
				// Check range parameters
				if (param.rangeFlag) {
					if ((param.min === null || param.min === undefined || param.min === '') &&
						(param.max === null || param.max === undefined || param.max === '')) {
						notificationServices?.generateNotification({
							type: 'error',
							message: `Please add min or max values for parameter: ${param.name}`,
						});
						return true;
					}
				}
				
				// Check boolean parameters
				if (param.inputType === 'boolean') {
					if (param.expectedValue === null || param.expectedValue === undefined) {
						notificationServices?.generateNotification({
							type: 'error',
							message: `Please add expected value for parameter: ${param.name}`,
						});
						return true;
					}
				}
				return false;
			});

			if (hasInvalidParameter) return;
		};

		req.data = {
			...productCategory,
			QualityAnalysisEnabled : qualityCheck?.qualityCheckEnabled,
			categoryConfiguration : qualityCheck?.categoryConfiguration,
			minimumSamplingQuantityPercentage : qualityCheck?.minSamplingQuantity,
			parameterChecks:  newList,
		}
		setNumberOfSubmissions(numberOfSubmissions + 1);
		if (isEdit) {
			await handleEdit();
			// req.params = {
			// 	id: ProductCategoryId,
			// };
			// saveProductCategory({
			// 	entity: 'PRODUCT_CATEGORY',
			// 	req,
			// 	method: 'UPDATE',
			// 	redirect: `${urls.EXTENSION}${urls.PRODUCT_CATEGORY}`,
			// });
		} else {
			const response = await productCategoryServcies.createProductCategory({req});
			if(response?.statusCode === 200){
				notificationServices.generateNotification({
					type: 'success',
					message: 'Product Category successfully created!',
				});
				history.push(`${urls.EXTENSION}${urls.PRODUCT_CATEGORY}`);
			}else{
				throw new Error('Failed to create Product Category.');
			}
		}
		}catch(error){
		notificationServices.generateNotification({
			type: 'error',
			message: error?.message,
		});
		}
	};
	const handleCancel = () => {
		if (isEdit) {
			history.push(
				`${urls.EXTENSION}${urls.PRODUCT_CATEGORY}/view/${ProductCategoryId}`
			);
		} else {
			history.push(`${urls.EXTENSION}${urls.PRODUCT_CATEGORY}`);
		}
	};

	const openImageCropModal = (name, file = null) => {
		// console.log('TESTTTT', name, file);
		toggleImageCrop(true, {
			name,
			file,
		});
	};

	const handleImageSelect = (e) => {
		const { fieldName } = inputHelper(e);
		const file = e.target.files[0];
		// console.log('FILEEE', file);
		if (file) {
			openImageCropModal(fieldName, file);
		}
	};

	const handleLogoInput = (fieldName, blob) => {
		toggleImageCrop(false, {});
		setState({
			...state,
			fields: {
				...state.fields,
				[fieldName]: blob,
			},
			...errors,
		});
	};
	// function for open the product parameter modal
	const fetchProductParameters = async() => {	
		// calling api for getting the product parameters
		const response = await masterMenuServices.productMasterMenuList();
		if(response?.statusCode === 200){
			return response?.data;
		}
	};
	const handleOpenModal = async () => {
		const productParameters = await fetchProductParameters();
		toggleProductParametersModal(true, productParameters);
	};
	const handleRemove = (id) => {
		const newSelectedParameters = newList.filter((parameter) => parameter.id !== id);
		setNewList(newSelectedParameters);
	}
	const handleOpenAddModal = () => {
		toggleAddParameterModal(true);
	};

	return (
		<div className='root'>
			<ImageCropModal
				handleConfirm={handleLogoInput}
			/>
			<ProductParametersModal 
				selectedParameters={newList}
				setSelectedParameters={setSelectedParameters}
				handleOpenAddModal={handleOpenAddModal}
				trueFetchProductParameters={trueFetchProductParameters}
				settrueFetchProductParameters={settrueFetchProductParameters}
			/>
			<AddParameterModal
				settrueFetchProductParameters={settrueFetchProductParameters}
			/>
				<CreatePageHeader
					heading={
						isEdit
							? 'Edit Product Category'
							: 'Create Product Category'
					}
					handleCancel={handleCancel}
					handleSave={handleSave}
					breadcrumbs={[
						...breadcrumbs,
						{
							link: '',
							name: isEdit
								? `Edit ${state.fields.name}`
								: 'Create New',
						},
					]}
					showBreadcrumbs
				/>
				{/* Product details */}
				<FormSectionHeader
					tooltip='description for the section'
					sectionName='General Details'
				>
					<div className='flex gap-8'>
						{fields.categoryImage ? (
							<div className='flex flex-col gap-2 w-[20%]'>
							<div className='w-full h-52'>
								<img src={fields.categoryImage} alt='category' />
							</div>
								<label htmlFor='categoryImageEdit' className='px-4 py-2 bg-blue-400 text-white text-center  rounded-md cursor-pointer hover:bg-primary/90'>Edit Image</label>
								<input
									type='file'
									id='categoryImageEdit'
									className='hidden'
									accept='image/png, image/jpg, image/jpeg, image/webp'
									name='categoryImage'
									onChange={(e) => handleImageSelect(e)}
									style={{
										opacity: 0,
										width: 1,
										height: 1,
									}}
								/>
							</div>
						) : (
						<div className='w-[20%] h-52'>
							<DragDropImage 
								name='categoryImage'
								handleImageUpload={handleImageSelect}
							/>
						</div>
						)}
						<div className='w-[80%]'>
							<GeneralDetails
								mandatoryFields={ProductsConfig.mandatoryFields}
								fields={fields}
								errors={errors}
								handleInput={handleInput}
								isEdit={isEdit}
								generateDefaultOption={generateDefaultOption}
								unitExist={unitExist}
							/>
						</div>
					</div>
				</FormSectionHeader>
			<div className='flex items-center gap-2 m-[1rem] mt-4'>
				<Label className='text-lg text-gray-600 font-medium'>Quality Analysis Inspection</Label>
			</div>
				<QualityConfig
					qualityCheck={qualityCheck}
					setQualityCheck={setQualityCheck}
					disableFields={qualityCheck?.qualityCheckEnabled ? false : true}
					hideFeilds
				/>
				<div className={`transition-all duration-700 ease-in-out transform origin-top ${
        		  qualityCheck?.qualityCheckEnabled ? 'max-h-[500px] scale-y-100 opacity-100' : 'max-h-[0px] scale-y-0 opacity-0'
    			}`}>
				{/* <QualityCheck
					qualityCheck={qualityCheck}
					setQualityCheck={setQualityCheck}
				/> */}
				<AddParameterComp
					handleOpenModal={handleOpenModal}
					selectedParameters={newList}
					handleRemove={handleRemove}
					setNewList={setNewList}
				/>
				</div>
			</div>
	);
};

const mapStateToProps = (state) => {
	const data = state.form.PRODUCT_CATEGORY.data.VIEW;
	return {
		data,
	};
};
const mapDispatchToProps = {
	fetchProductCategory: formActions.gateway,
	saveProductCategory: formActions.gateway,
	deleteProductCategory: formActions.gateway,
	toggleImageCrop: modalActions.toggleImageCrop,
	toggleProductParametersModal: modalActions.toggleProductParametersModal,
	toggleAddParameterModal: modalActions.toggleAddQualityParameterModal,
};
ProductCreatePage.defaultProps = {
	isEdit: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCreatePage);
