/* eslint-disable no-tabs */
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import moment from 'moment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import styles from './PaymentsMade.module.scss';
// import ListTable from '../../../../Components/ListTable/ListTable';
import { BackdropLoader } from '../../Components/Loaders';
import ListTable from '../../Components/ListTable/ListTable';
import ImageModal from '../../Components/Modals/ImageModal/ImageModal';
// import { ListModal } from '../../../Components/Modals';
import { modalActions } from '../../Actions';
// import { notificationServices } from '../../Services';
import ImagesFinder from '../../Components/ImagesFinder/ImagesFinder';
import { POItemsModal } from '../../Components/Modals/POItemsModal';
// import { urls } from '../../Constants';
import inventoryAnalyticsServices from '../../Services/Analytics Services/inventory-analytics.services';
import { notificationServices } from '../../Services';
import { AlertModal } from '../../Components/Modals';
import PaymentDetailsModal from '../../Components/Modals/PaymentDetailsModal/PaymentDetailsModal';
import { addCommas, useQuery } from '../../Helpers';

const TABLE_COLUMNS = [
	{
		title: 'id',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Vendor',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Payment ID',
		config: {
			type: 'DATA',
		},
	},
	// {
	// 	title: 'Order id',
	// 	config: {
	// 		type: 'DATA',
	// 	},
	// },
	{
		title: 'Bills Paid',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Status',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Recorded By',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Payment date',
		config: {
			type: 'DATA',
		},
	},
	{
		title: 'Paid Amount',
		config: {
			type: 'DATA',
		},
	},
];

const SPECIAL_COLUMNS = [
	{
		title: 'View',
		config: {
			type: 'VIEW',
		},
	},
	// {
	// title: 'Cancel Payment',
	// config: {
	// type: 'DATA',
	// },
	// },
];

const COLMAP = {
	id: 'id',
	Vendor: 'vendorName',
	'Payment ID': 'clientTransactionId',
	// 'Order id': 'parentTransactionRecord.orderId',
	'Bills Paid': 'extraData',
	// 'Invoice id': 'parentTransactionRecord.clientTransactionId',
	Status: 'transactionStatus',
	'Recorded By': 'l1ActionByUserDisplayName',
	'Payment date': 'transactionDate',
	'Paid Amount': 'finalTransactionAmount',
};

const FIND_FILTERS = ['vendor', 'status', 'dateRangeOnly'];

const statusList = [
	{
		id: 'CANCELLED',
		title: 'CANCELLED',
	},
	{
		id: 'PAID',
		title: 'PAID',
	},
];

function PaymentsMade(props) {
	const {
		// eslint-disable-next-line no-unused-vars
		togglePOItemsModal,
		paymentPageType,
		// navState,
		toggleAlert,
		togglePaymentDetailsModal,
	} = props;
	const params = useQuery();
	let billId = params.get('billId')
		? Number(params.get('billId'))
		: undefined;
	let clientTransactionId = params.get('clientTransactionId');
	const history = useHistory();
	const [isloading, setIsloading] = useState(false);
	const [paymentReceivesList, setPaymentReceivesList] = useState([]);
	const isPaymentReceived = paymentPageType === 'paymentReceived';
	const finderFilters = isPaymentReceived ? ['vendor'] : [];
	const [update, setUpdate] = useState(-1);
	const [externalFilters, setExternalFilters] = useState([]);

	const handleListFetch = async (requestOptions) => {
		// setInvoicesList(dummyData);
		setIsloading(true);
		try {
			const responseData =
				await inventoryAnalyticsServices.fetchInvTransRecord({
					req: {
						data: {
							filters: {
								// ...(isPaymentReceived && {
								// 	storeId: requestOptions?.store,
								// 	fromStore: navSelectedStore?.value?.id,
								// }),
								...(!isPaymentReceived && {
									storeId: requestOptions?.store,
									fromStore: requestOptions?.store,
								}),
								status: requestOptions?.status,
								dateRangeFilter: {
									from: moment(
										requestOptions?.dateRange?.from
									).format('YYYY-MM-DD'),
									to: moment(
										requestOptions?.dateRange?.to
									).format('YYYY-MM-DD'),
								},
								vendors: requestOptions?.vendors,
							},
							type: 'PAYMENTS',
						},
					},
				});
			if (responseData?.statusCode === 200) {
				setPaymentReceivesList(responseData?.data);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setIsloading(false);
		}
	};

	useEffect(() => {
		if (billId) {
			setUpdate((prev) => {
				return prev + 1;
			});
			const billFilterExternal = {
				id: billId,
				type: 'billId',
				title: `Bill Id: ${clientTransactionId}`,
				removable: true,
				handleRemove: () => {
					params.delete('billid');
					params.delete('clientTransactionId');
					billId = null;
					clientTransactionId = null;
					setUpdate((prev) => {
						return prev + 1;
					});
					setExternalFilters((prev) => {
						return prev?.filter((ele) => {
							return ele?.type !== 'billId';
						});
					});
					history.replace({
						search: params.toString(),
					});
				},
			};
			setExternalFilters((prev) => {
				const exists = prev?.find((ele) => {
					return ele?.type === 'orderId';
				});
				if (exists) {
					if (exists?.id !== billId) {
						return prev?.map((ele) => {
							if (ele?.type === 'billId') {
								return billFilterExternal;
							}
							return ele;
						});
					}
					return prev;
				}
				return [...prev, billFilterExternal];
			});
		}
	}, [billId]);

	const handleCancelPayment = async (item) => {
		setIsloading(true);
		try {
			const responseData =
				await inventoryAnalyticsServices.updateInventoryTransaction({
					params: {
						transactionId: item?.id,
					},
					data: {
						status: 'CANCELLED',
						type: 'PAYMENTS',
					},
				});
			if (responseData?.statusCode === 200) {
				setUpdate((prev) => {
					return prev + 1;
				});
				notificationServices?.generateNotification({
					type: 'success',
					message: responseData?.message,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			setIsloading(false);
		}
	};

	const SPECIAL_ITEMS = {
		'Paid Amount': (amount) => {
			return `₹${addCommas(amount)}`;
		},
		'Bills Paid': (extraData) => {
			return Object?.keys(extraData?.associatedPayments?.onBills ?? {})?.length;
		},
		'Cancel Payment': (id) => {
			const item = paymentReceivesList?.find((ele) => {
				return ele?.id === id;
			});
			if (item?.transactionStatus === 'CANCELLED') {
				return '-';
			}
			return (
				<Button
					onClick={() => {
						toggleAlert(true, {
							heading: `Cancel Payment ${item?.clientTransactionId}`,
							handleConfirm: () => {
								handleCancelPayment(item);
								toggleAlert(false);
							},
							colorReverse: false,
							cancelText: 'No',
							confirmText: 'Yes',
							message: `Are you sure you want to Cancel Payment: ${
								item?.clientTransactionId || ''
							}?`,
							width: '28rem',
						});
					}}
					variant='outlined'
					color='error'
				>
					Cancel
				</Button>
			);
		},
	};

	const handleViewClick = async (item, isView) => {
		try {
			const response =
				await inventoryAnalyticsServices.getTransactionRecord({
					params: {
						transactionId: isView ? item?.id : billId,
					},
				});
			if (response?.statusCode === 200) {
				if (billId && !isView) {
					setPaymentReceivesList(response?.data?.PAYMENT);
				} else {
					togglePaymentDetailsModal(true, {
						rowData: response?.data,
						disabled: true,
						heading: `Payment Details of Payment #${response?.data?.clientTransactionId}`,
						paymentType: 'grnBillDues',
					});
				}
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		} finally {
			// setLoading(false);
		}
	};

	const handleFetchData = (requestOptions) => {
		if (billId) {
			handleViewClick();
		} else {
			handleListFetch(requestOptions);
		}
	};

	return (
		<div className={styles.root}>
			{/* <ListModal handleConfirm={iAmClicked} singleSelectable /> */}
			<BackdropLoader showProgress isLoading={isloading} />
			<ImageModal />
			<POItemsModal />
			<AlertModal />
			<PaymentDetailsModal />
			<div className={styles.header}>
				<ImagesFinder
					pageLabel={
						isPaymentReceived
							? 'Payments Received'
							: 'Payments Made'
					}
					FILTERS={FIND_FILTERS?.filter((ele) => {
						return !finderFilters?.includes(ele);
					})}
					statusList={statusList}
					update={update}
					onSubmit={handleFetchData}
					externalFilters={externalFilters}
				/>
			</div>
			<ListTable
				colMap={COLMAP}
				columns={TABLE_COLUMNS?.filter((ele) => {
					if (isPaymentReceived) {
						return (
							ele?.title !== 'Vendor' &&
							ele?.title !== 'Bill id' &&
							ele?.title !== 'Billing Store'
						);
					}
					return (
						ele?.title !== 'Invoice id' &&
						ele?.title !== 'From Outlet'
					);
				})}
				data={paymentReceivesList}
				// data={dummyData}
				actions={[...SPECIAL_COLUMNS]}
				special={SPECIAL_ITEMS}
				handleViewClick={(item) => {
					handleViewClick(item, true);
				}}
			/>
		</div>
	);
}
const mapStateToProps = (state) => {
	const { navState } = state.navigation;
	const { userPermissions } = state.navigation;
	return {
		navState,
		userPermissions,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleListModal,
	toggleAlert: modalActions.toggleAlert,
	togglePOItemsModal: modalActions.togglePOItemsModal,
	togglePaymentDetailsModal: modalActions.togglePaymentDetailsModal,
	togglePaymentModal: modalActions.togglePaymentModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsMade);
