/* eslint-disable */
import { connect } from 'react-redux';
import { modalActions } from '../../../Actions';
import { BaseModal } from '../BaseModal';
import styles from './ImageModal.module.scss';
import { Eye } from 'lucide-react';
import { useState } from 'react';
const ImageModal = (props) => {
	const { show, toggleModal, data, children } = props;
	const [hoveredImage, setHoveredImage] = useState(null);

	const handleClose = () => {
		toggleModal(false);
	};

	const handleImageClick = (imgSrc) => {
		const previewContainer = document.createElement('div');

		previewContainer.className = 'preview-overlay';
		previewContainer.style.position = 'fixed';
		previewContainer.style.top = '0';
		previewContainer.style.left = '0';
		previewContainer.style.width = '100%';
		previewContainer.style.height = '100%';
		previewContainer.style.backgroundColor = 'rgba(0,0,0,0.8)';
		previewContainer.style.display = 'flex';
		previewContainer.style.justifyContent = 'center';
		previewContainer.style.alignItems = 'center';
		previewContainer.style.zIndex = '99999';

		const previewImage = document.createElement('img');
		previewImage.src = imgSrc;
		previewImage.style.maxHeight = '80%';
		previewImage.style.maxWidth = '80%';

		previewContainer.addEventListener('click', () => {
			document.body.removeChild(previewContainer);
		});

		previewContainer.appendChild(previewImage);
		document.body.appendChild(previewContainer);
	};

	const { image, heading } = data;

	return (
		// eslint-disable-next-line react/jsx-boolean-value
		<BaseModal
			heading={heading}
			open={show}
			handleClose={handleClose}
			renderBtns={() => {}}
			width={data?.width}
			height={data?.height}
		>
			{Array.isArray(image) && image?.length > 0 ? (
					<div className='flex justify-start items-center gap-4'>
						{image?.map((imgSrc, index) => (
							<div 
								className='relative group' 
								key={index} 
								onMouseEnter={() => setHoveredImage(imgSrc)}
								onMouseLeave={() => setHoveredImage(null)}
						>
							{/* Image */}
							<img src={imgSrc} alt='' className='w-[200px] h-[200px] m-2 rounded-lg shadow-md' />

							{/* Hover Preview Icon */}
							{hoveredImage === imgSrc && (
								<div 
									className='absolute inset-0 flex items-center justify-center bg-black bg-opacity-50  cursor-pointer'
									onClick={() => handleImageClick(imgSrc)} // Open modal on click
								>
									<Eye className='text-white w-8 h-8' />
								</div>
							)}
						</div>
					))}
					</div>
			) : (
				<div className={`${styles.root}`}>
					<img
						src={image}
					alt=''
					style={{	
						display: data?.centerImage ? 'block' : 'block',
						margin: data?.centerImage ? '0 auto' : 0,
					}}
					/>
				</div>
			)}
			{children}
		</BaseModal>
	);
};

const mapStateToProps = (state) => {
	const { show, data } = state.modal.imageModal;
	return {
		show,
		data,
	};
};

const mapDispatchToProps = {
	toggleModal: modalActions.toggleImageModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageModal);
